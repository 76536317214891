import { useCallback } from "react";
import Alert from "react-bootstrap/Alert";

import { ContactSupportOnError } from "../shared/errors/ContactSupport";
import { ILogs } from "../shared/types/log.types";
import { TCell } from "../shared/types/lot.types";
import {
  IMappingStep,
  OPTIONNAL_STEP_ENUM,
  TLotsMappings,
} from "../shared/types/lots.types";

import { ExcelImportSteps } from "./ExcelImportSteps";
import { ExcelImportTable } from "./ExcelImportTable";

export interface ILotsStepMapperProps {
  selectedStepIndex: number;
  setSelectedStepIndex: (stepIndex: number) => void;
  setMappings: React.Dispatch<React.SetStateAction<TLotsMappings>>;
  sheet: TCell[][];
  currentStep: IMappingStep;
  mappings: TLotsMappings;
  sheetMappingStartIndex: number;
  lastValidStepIndex: number;
  submitError?: any;
  isFrame?: Boolean;
  logs?: ILogs;
}

export function getLastValidStepIndex(
  selectedIndex: number,
  mappings: any[],
  getStepIndex: (index: number, mappingStartIndex: number) => number,
  sheetMappingStartIndex: number,
  testStepCompleted: (
    table: TLotsMappings,
    steps: IMappingStep[],
    index: number
  ) => boolean,
  mappingSteps: any[]
) {
  return Math.max(
    getStepIndex(selectedIndex, sheetMappingStartIndex),
    (mappingSteps.findIndex(
      (step, index) => !testStepCompleted(mappings, mappingSteps, index)
    ) + 1 || mappings.length + 1) - 1
  );
}

export function ExcelImportStepMapper({
  selectedStepIndex,
  setSelectedStepIndex,
  setMappings,
  sheet,
  currentStep,
  mappings,
  lastValidStepIndex,
  submitError,
  isFrame,
  logs,
}: ILotsStepMapperProps) {
  const onTableData = useCallback(
    (data: number[] | undefined) =>
      setMappings((table) => {
        table[selectedStepIndex] = data;
        return [...table];
      }),
    [setMappings, selectedStepIndex]
  );
  return (
    <>
      <ExcelImportSteps
        selectedIndex={selectedStepIndex}
        enabledIndex={lastValidStepIndex}
        selectIndex={(index) => setSelectedStepIndex(index)}
        logs={logs}
      />
      <hr />
      <ExcelImportTable
        onData={onTableData}
        sheet={sheet}
        allowMultipleSelections={currentStep.allowMultipleSelections}
        instructions={currentStep.instructions}
        optionalStepVariant={
          currentStep.optionalStep === OPTIONNAL_STEP_ENUM.FRAME_ONLY
        }
        columnMode={currentStep.columnMode}
        reverseMode={currentStep.reverseMode}
        selection={mappings[selectedStepIndex]}
        isFrame={isFrame}
      />
      {Boolean(submitError) && (
        <Alert variant="danger">
          <ContactSupportOnError
            error={submitError}
            i18nKey="ExcelImportStepMapper.unknown import error"
          />
        </Alert>
      )}
    </>
  );
}
