import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { UserContext } from "../account";
import { GuardedButton } from "../account/GuardedButton";
import { LotEditionModal } from "../lots/LotEditionModal";
import { LotWithConventionalAllotments } from "../shared/ConventionalAllotments";
import { useModal } from "../shared/DefaultModal";
import { StatusBadge } from "../shared/StatusBadge";
import styles from "../shared/_exports.module.scss";
import { formatCurrency, useTranslation } from "../shared/i18n";
import { AddCircledIcon, EuroIcon, TendersIcon } from "../shared/icons";
import { isUserAdmin } from "../shared/types/account.types";
import { ILot } from "../shared/types/lot.types";
import { IOperation, STATUS_ENUM } from "../shared/types/operation.types";
import { useTendersQuery } from "../shared/useSharedQueries";
import { ifTest } from "../shared/utils";

import { LotClosureModal } from "./closure/LotClosureModal";

function getLotClosureButtonText(lotClosureIsReadOnly: boolean, lot: ILot) {
  if (lotClosureIsReadOnly && lot.selected) {
    return "view lot closure information";
  } else if (lot.selected) {
    return "edit lot closure";
  } else {
    return "close lot";
  }
}

export function LotHeader({
  lot,
  operation,
}: {
  lot: ILot;
  operation: IOperation;
}) {
  const { t } = useTranslation("LotHeader");
  const { show, handleClose, handleShow } = useModal("LotEditionModal");
  const {
    show: showLotClosure,
    handleClose: handleCloseLotClosure,
    handleShow: handleShowLotClosure,
  } = useModal("LotClosureModal");
  const { user } = useContext(UserContext);

  const tendersQuery = useTendersQuery(lot.id);
  const companies = tendersQuery.data;

  const userIsAdmin = isUserAdmin(user);
  const lotClosureIsReadOnly =
    !userIsAdmin && (Boolean(lot.selected) || !companies?.length);
  const lotClosureModalEnabled = !lotClosureIsReadOnly || Boolean(lot.selected);

  const lotClosureButtonText = getLotClosureButtonText(
    lotClosureIsReadOnly,
    lot
  );

  return (
    <>
      <LotEditionModal
        show={show}
        handleClose={handleClose}
        defaultValues={lot}
      />
      <LotClosureModal
        readOnly={lotClosureIsReadOnly}
        show={showLotClosure}
        handleClose={handleCloseLotClosure}
        defaultValues={lot}
        key={String(showLotClosure)}
        requiredSelectedCompany={!userIsAdmin}
      />

      <Row
        as="header"
        className="bg-light mb-4 rounded-4 p-md-4 m-md-4 m-2 p-2"
      >
        <Col>
          <Row>
            <Col>
              <h4 data-test={ifTest("lot-title")}>
                <LotWithConventionalAllotments lot={lot} />
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>{operation.name}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <StatusBadge
                status={
                  lot.selected ? STATUS_ENUM.ARCHIVED : STATUS_ENUM.IN_PROGRESS
                }
                className="my-3"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <GuardedButton
                variant="outline-secondary"
                className="rounded align-items-center"
                onClick={handleShow}
                data-test={ifTest("openLotEdition")}
              >
                <AddCircledIcon
                  fill={styles.black}
                  className="me-2 flex-shrink-0"
                />
                {t("modify lot")}
              </GuardedButton>
            </Col>
          </Row>
        </Col>
        <Col sm="auto" className="align-self-center">
          <h6 className="mt-3 mb-4">{t("lot features")}</h6>
          <Row xs="auto">
            <Col className="me-3">
              <Row>
                <Col xs="auto" className="pe-0">
                  <div className="p-2 rounded-circle bg-black lh-1">
                    <EuroIcon size={16} />
                  </div>
                </Col>
                <Col as="dl">
                  <dt>{t("lot estimation")}</dt>
                  <dd>{formatCurrency(lot.estimation_amount)}</dd>
                </Col>
              </Row>
            </Col>
            <Col className="me-3">
              <Row>
                <Col xs="auto" className="pe-0">
                  <div className="p-2 rounded-circle bg-black lh-1">
                    <TendersIcon size={16} />
                  </div>
                </Col>
                <Col as="dl">
                  <dt>{t("lot reference")}</dt>
                  <dd>{lot.reference_number}</dd>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col sm="auto">
              <GuardedButton
                variant="outline-secondary"
                className="rounded d-flex align-items-center justify-content-center fw-bolder"
                onClick={handleShowLotClosure}
                data-test={ifTest("openLotClosure")}
                allowed={lotClosureModalEnabled}
              >
                {t(lotClosureButtonText)}
              </GuardedButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
