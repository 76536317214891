import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function DownloadIcon({
  fill = styles?.black,
  className = "",
  size = 24,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        d="M2 12.666h12V14H2v-1.334Zm6.667-3.885 4.047-4.048.943.943L8 11.333 2.343 5.676l.943-.943L7.333 8.78V1.333h1.334v7.448Z"
        fill={fill}
      />
    </svg>
  );
}
