import {
  API_BASE_URL,
  FETCH_METHODS_ENUM,
  authRequestJSON,
  requestJSON,
} from "../api/fetch";
import {
  IToken,
  ITokenAccess,
  IUser,
  IUserCreationDTO,
  IUserDTO,
} from "../shared/types/account.types";

export async function createUser({
  email,
  password,
  first_name,
  last_name,
}: Partial<IUserCreationDTO>) {
  return requestJSON(`${API_BASE_URL}/user/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password, first_name, last_name }),
  });
}

export async function login(userEmail: string, userPassword: string) {
  return requestJSON<IToken>(`${API_BASE_URL}/token/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: userEmail,
      password: userPassword,
    }),
  });
}

export async function refresh(refreshToken: string) {
  return requestJSON<ITokenAccess>(`${API_BASE_URL}/token/refresh/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh: refreshToken,
    }),
  });
}

export async function getUser(userId: string, token: string) {
  return requestJSON<IUserDTO>(`${API_BASE_URL}/user/${userId}/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function patchUser(userId: string, user: Partial<IUser>) {
  return authRequestJSON<IUser>(`user/${userId}/`, {
    method: FETCH_METHODS_ENUM.PATCH,
    body: JSON.stringify(user),
  });
}
