import i18next from "i18next";

import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from "../utils";

const currencyFormatters: { [language: string]: Intl.NumberFormat } = {};
const currencyFormatter = {
  format(amount: number, language = i18next.language) {
    if (currencyFormatters[language] === undefined) {
      currencyFormatters[language] = Intl.NumberFormat(language, {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 2,
      });
    }
    return currencyFormatters[language].format(amount);
  },
};

export function formatCurrency(
  amount?: number,
  placeholder = DEFAULT_EMPTY_VALUE_PLACEHOLDER
) {
  return amount === undefined || isNaN(amount)
    ? placeholder
    : currencyFormatter.format(amount);
}

export type TSignDisplay =
  | "always"
  | "auto"
  | "exceptZero"
  | "never"
  | undefined;
const percentageFormatters: {
  [language: string]: { [signDisplay: string]: Intl.NumberFormat };
} = {};
const percentageFormatter = {
  format(
    ratio: number,
    signDisplay: TSignDisplay = "exceptZero",
    language = i18next.language
  ) {
    if (percentageFormatters[language] === undefined) {
      percentageFormatters[language] = {};
    }
    if (percentageFormatters[language][signDisplay] === undefined) {
      percentageFormatters[language][signDisplay] = new Intl.NumberFormat(
        language,
        {
          signDisplay,
          maximumFractionDigits: 0,
          style: "percent",
        }
      );
    }
    return percentageFormatters[language][signDisplay].format(ratio);
  },
};

export function formatPercentage(
  ratio?: number,
  signDisplay?: TSignDisplay,
  placeholder = DEFAULT_EMPTY_VALUE_PLACEHOLDER
) {
  return ratio === undefined || isNaN(ratio)
    ? placeholder
    : percentageFormatter.format(ratio, signDisplay);
}

export function formatFileSize(size: number) {
  return isNaN(size)
    ? DEFAULT_EMPTY_VALUE_PLACEHOLDER
    : `${Math.round(size / 1024)} Ko`;
}
const numberFormatters: { [language: string]: Intl.NumberFormat } = {};
const numberFormatter = {
  format(number: number, language = i18next.language) {
    if (numberFormatters[language] === undefined) {
      numberFormatters[language] = new Intl.NumberFormat(language, {
        style: "decimal",
        maximumFractionDigits: 2,
      });
    }
    return numberFormatters[language].format(number);
  },
};

export function formatNumber(
  number?: number,
  placeholder = DEFAULT_EMPTY_VALUE_PLACEHOLDER
) {
  return number === undefined || isNaN(number)
    ? placeholder
    : numberFormatter.format(number);
}

const numberCompactFormatters: { [language: string]: Intl.NumberFormat } = {};
const numberCompactFormatter = {
  format(number: number, language = i18next.language) {
    if (numberCompactFormatters[language] === undefined) {
      numberCompactFormatters[language] = new Intl.NumberFormat(language, {
        style: "decimal",
        maximumFractionDigits: 2,
        notation: "compact",
      });
    }
    return numberCompactFormatters[language].format(number);
  },
};

export function formatNumberCompact(
  number?: number,
  placeholder = DEFAULT_EMPTY_VALUE_PLACEHOLDER
) {
  return number === undefined || isNaN(number)
    ? placeholder
    : numberCompactFormatter.format(number);
}
