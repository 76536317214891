import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { parseISO } from "date-fns";
import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useSearchParams } from "react-router-dom";

import { getSetSearchParamsCallbackWithLastUpdatedSearchParams } from "../api/APIUtils";
import { createRowType } from "../api/fetchRowTypes";
import { useModal } from "../shared/DefaultModal";
import colors from "../shared/_exports.module.scss";
import { IBackErrors } from "../shared/errors";
import { DEFAULT_COL_WIDTH } from "../shared/excel/ExportTableToExcel";
import { Header } from "../shared/header";
import { useTranslation } from "../shared/i18n";
import { formatTolerant } from "../shared/i18n/dateFormatter";
import { AddCircledIcon } from "../shared/icons";
import { CheckBox } from "../shared/inputs";
import { AllotmentSelect } from "../shared/inputs/AllotmentSelect";
import {
  IRowType,
  ROWTYPE_USAGE_ENUM,
  TCONSTRUCTION_INDICATOR,
} from "../shared/types/rowType.types";
import {
  QUERY_KEYS_ENUM,
  useRowTypeOrganizationQuery,
  useRowTypesQuery,
} from "../shared/useSharedQueries";
import { doArraysHaveDifferentValues } from "../shared/utils";

import { CSVRowType } from "./CSVRowType";
import { ClassificationTable, onRowTypeChange } from "./ClassificationTable";
import { ConstructionIndicatorSelect } from "./ConstructionIndicatorSelect";
import styles from "./MappingAdmin.module.scss";
import { ALLOTMENT_TYPE_SEARCH_PARAM } from "./MappingToolPage";
import { MappingUsageSelect } from "./MappingUsageSelect";
import { RowTypeModal } from "./RowTypeModal";
import { IRowTypeRow } from "./mapping.types";

export function MappingAdmin() {
  const { t } = useTranslation("classificationTable");
  const [searchParams, setSearchParams] = useSearchParams();

  const [allotment_type, setAllotment_type] = useState<string | undefined>(
    searchParams.get(ALLOTMENT_TYPE_SEARCH_PARAM) || undefined
  );
  const rowTypes = useRowTypesQuery(allotment_type, true, undefined, true);

  const organization = useRowTypeOrganizationQuery();

  const {
    show: showRowTypeModal,
    handleClose: handleCloseRowTypeModal,
    handleShow: handleShowRowTypeModal,
  } = useModal("CompareRowTypeModal");

  const queryClient = useQueryClient();

  const rowTypeCreation = useMutation({
    mutationFn: createRowType,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.ROW_TYPES, allotment_type],
      });
    },
  });

  const adminColumns: ColumnDef<IRowTypeRow>[] = useMemo(
    () => [
      {
        header: t("usage"),
        accessorKey: "usage",
        // join for excel export
        accessorFn: (originalRow) => originalRow.usage?.join(","),
        id: "usage",
        cell: (cellContext) => (
          <MappingUsageSelect
            className="text-body"
            value={
              cellContext.getValue<string>()?.split(",") as ROWTYPE_USAGE_ENUM[]
            }
            onChange={(v) => {
              const value = v as ROWTYPE_USAGE_ENUM[];
              if (
                doArraysHaveDifferentValues(
                  cellContext.getValue<string>()?.split(","),
                  value
                )
              ) {
                onRowTypeChange(
                  cellContext,
                  queryClient,
                  allotment_type,
                  organization!,
                  { usage: value }
                );
              }
            }}
          />
        ),
        meta: {
          className: styles["usage-column"],
          excel: { width: DEFAULT_COL_WIDTH },
        },
      },
      {
        header: t("construction_index"),
        accessorKey: "construction_index",
        id: "construction_index",
        cell: (cellContext) => (
          <ConstructionIndicatorSelect
            className="text-body"
            value={cellContext.getValue<TCONSTRUCTION_INDICATOR>()}
            onChange={(value) => {
              if (
                cellContext.getValue() !== value &&
                (Boolean(cellContext.getValue()) || Boolean(value))
              ) {
                onRowTypeChange(
                  cellContext,
                  queryClient,
                  allotment_type,
                  organization!,
                  { construction_index: value as TCONSTRUCTION_INDICATOR }
                );
              }
            }}
            closeMenuOnSelect
          />
        ),
        meta: {
          className: styles["construction-index-column"],
          excel: { width: DEFAULT_COL_WIDTH },
        },
      },
      {
        header: t("original_author"),
        accessorKey: "original_author",
        id: "original_author",
        meta: {
          excel: { width: DEFAULT_COL_WIDTH },
        },
      },
      {
        header: t("creation_date"),
        accessorKey: "creation_date",
        id: "creation_date",
        cell: (cellContext) =>
          cellContext.getValue<Date>()
            ? formatTolerant(parseISO(cellContext.getValue<string>()), "Pp")
            : "-",
        meta: {
          excel: { width: DEFAULT_COL_WIDTH },
        },
      },
      {
        header: t("admin_approval"),
        accessorKey: "admin_approval",
        id: "admin_approval",
        cell: (cellContext) => (
          <CheckBox
            checked={cellContext.row.original.admin_approval}
            onChange={(event) => {
              if (cellContext.getValue() !== event.target.checked) {
                onRowTypeChange(
                  cellContext,
                  queryClient,
                  allotment_type,
                  organization!,
                  { admin_approval: event.target.checked }
                );
              }
            }}
          />
        ),
        meta: {
          excel: { width: DEFAULT_COL_WIDTH },
        },
      },
    ],
    [allotment_type, organization, queryClient, t]
  );

  return (
    <>
      <Header variant="dark" />
      <Container className="ms-5">
        <Row className="mt-3">
          <Col>
            <AllotmentSelect
              value={allotment_type}
              onChange={(value) => {
                setAllotment_type(value as string);
                setSearchParams(
                  getSetSearchParamsCallbackWithLastUpdatedSearchParams(
                    (searchParams) => {
                      searchParams.set(
                        ALLOTMENT_TYPE_SEARCH_PARAM,
                        allotment_type!
                      );
                      return searchParams;
                    }
                  ),
                  { replace: true }
                );
              }}
              multi={false}
              closeMenuOnSelect
            />
          </Col>
          <Col>
            <Button
              variant="outline-secondary"
              className="rounded me-3"
              onClick={handleShowRowTypeModal}
            >
              <AddCircledIcon fill={colors.black} className="me-2" />{" "}
              {t("add RowType")}
            </Button>

            <RowTypeModal
              key={String(showRowTypeModal)}
              show={showRowTypeModal}
              handleClose={async (rowType) => {
                if (rowType) {
                  await rowTypeCreation.mutateAsync(rowType);
                }
                handleCloseRowTypeModal();
              }}
              backErrors={rowTypeCreation.error as IBackErrors<IRowType>}
              isSubmitting={rowTypeCreation.isLoading}
              value={{ allotment_type: allotment_type! }}
            />
          </Col>
        </Row>
        <Row>
          <CSVRowType allotment_type={allotment_type} />
        </Row>
        <Row className="mt-3">
          <ClassificationTable
            row_types={rowTypes.data}
            isLoading={rowTypes.isLoading}
            enableEdit
            additionalColumns={adminColumns}
            enableDownload
            allotment_type={allotment_type}
          />
        </Row>
      </Container>
    </>
  );
}
