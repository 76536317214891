import React from "react";

import { useEnumTranslation, useTranslation } from "../shared/i18n";
import { Select } from "../shared/inputs";
import { CONSTRUCTION_INDICAOTR } from "../shared/types/rowType.types";

import { IConstructionIndicatorSelectProps } from "./mapping.types";

export const ConstructionIndicatorSelect = React.forwardRef<
  HTMLInputElement,
  IConstructionIndicatorSelectProps
>(function ConstructionIndicatorSelect(
  { onChange, onBlur, value, closeMenuOnSelect = false, className },
  ref
) {
  const { tEnum } = useEnumTranslation();
  const { t } = useTranslation("ConstructionIndicatorSelect");

  const constructionIndicatorOptions = CONSTRUCTION_INDICAOTR.map(
    ({ value, options }) => ({
      option: value,
      label: `${value} : ${tEnum(value)}`,
      options: options.map((value) => ({
        value,
        label: `${value} : ${tEnum(value)}`,
      })),
    })
  );

  return (
    <Select
      className={className}
      options={constructionIndicatorOptions}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      ref={ref}
      searchable
      closeMenuOnSelect={closeMenuOnSelect}
      autoFocus
      placeholder={t("select construction indicator")}
    />
  );
});
