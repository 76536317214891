import { QueryClient, useQueryClient } from "@tanstack/react-query";
import {
  Cell,
  CellContext,
  ColumnDef,
  ExpandedState,
  Row as ReactRow,
  Table as ReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { Alignment } from "exceljs";
import {
  MutableRefObject,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { BiLinkExternal } from "react-icons/bi";

import { createRowType, updateRowType } from "../api/fetchRowTypes";
import { ICompareDifferenceSettings } from "../compare/Compare.types";
import styles from "../compare/CompareTable/CompareTable.module.scss";
import { getRowDepth } from "../compare/CompareTable/CompareTableUtils";
import { applyRowMeta } from "../compare/CompareTable/useCompareTable";
import {
  ITableRow,
  MAX_SUPPORTED_DEPTH,
  TABLE_COLUMN_DATA_TYPE_ENUM,
} from "../compare/Table.types";
import variables from "../shared/_exports.module.scss";
import {
  DEFAULT_COL_WIDTH,
  DESIGNATION_COL_WIDTH,
  ExportTableToExcel,
} from "../shared/excel/ExportTableToExcel";
import { useTranslation } from "../shared/i18n";
import { Select } from "../shared/inputs";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { Table } from "../shared/table";
import { ExpandCell } from "../shared/table/ExpandCell";
import { ExpandHeader } from "../shared/table/ExpandHeader";
import { IPriceNode } from "../shared/types/priceNode.types";
import {
  IRowTypeInstruction,
  ROW_TYPE_INSTRUCTIONS_ENUM,
} from "../shared/types/rowInstruction.types";
import { IRowType, units } from "../shared/types/rowType.types";
import {
  QUERY_KEYS_ENUM,
  useRowTypeOrganizationQuery,
} from "../shared/useSharedQueries";
import { accessNested, isDevelopmentEnv, setNested } from "../shared/utils";

import classificationStyles from "./ClassificationTable.module.scss";
import {
  ALLOTMENT_TYPE_SEARCH_PARAM,
  STANDARD_QUANTITY_COLUMN_ID,
} from "./MappingToolPage";
import {
  IClassification,
  IInitialInstructions,
  IInstructions,
  IMappingPriceNode,
  IRowTypeObject,
  IRowTypeRow,
} from "./mapping.types";

interface IClassificationTableProps {
  row_types?: IRowType[];
  activeOption?: string | undefined;
  onRowClick?: (row: ReactRow<IRowTypeRow>) => void;
  enableEdit?: boolean;
  isLoading: boolean;
  additionalColumns?: ColumnDef<IRowTypeRow>[];
  enableDownload?: boolean;
  enablePopupLink?: boolean;
  enableDisplayRows?: boolean;
  displayBase?: boolean;
  rows?: IPriceNode[];
  allotment_type?: string;
  instructions?: IInstructions;
  initialInstructions?: IInitialInstructions;
  selectedDocumentAccessor?: string;
  tableRef?: MutableRefObject<ReactTable<IRowTypeRow> | undefined>;
}

export function ClassificationTable({
  row_types,
  activeOption,
  onRowClick,
  enableEdit,
  isLoading,
  additionalColumns,
  enableDownload,
  enablePopupLink,
  enableDisplayRows,
  displayBase,
  rows,
  allotment_type,
  instructions,
  initialInstructions,
  selectedDocumentAccessor,
  tableRef,
}: IClassificationTableProps) {
  /**
   * This circumvents the traditional React way of doing things.
   * It's a workaround to improve performance by avoiding rerenders and directly modifying the DOM.
   *
   * Ideally this should get refactored into a more React way of doing things.
   * Due to time constraints and the possibly large refactor it has been relegated to the PE-197 ticket.
   */
  const previousActiveOption = useRef<string>();
  useEffect(() => {
    if (previousActiveOption.current) {
      // remove the active className form the previous active row
      document
        .getElementById(`classification-${previousActiveOption.current}`)
        ?.classList.remove(classificationStyles.active);
    }

    if (activeOption) {
      const activeRowElement = document.getElementById(
        `classification-${activeOption}`
      );

      // scroll the active row into view
      activeRowElement?.scrollIntoView({ block: "center", inline: "nearest" });

      // add the active className to the active row
      activeRowElement?.classList.add(classificationStyles.active);
    }

    previousActiveOption.current = activeOption;
  }, [activeOption]);

  const [expanded, setExpanded] = useState<ExpandedState>(true);
  const [displayRows, setDisplayRows] = useState<boolean>(true);
  const { t } = useTranslation("classificationTable");

  const organization = useRowTypeOrganizationQuery();

  const classificationDict = useMemo(
    () => listRowTypesToObjectMapper(row_types),
    [row_types]
  );
  const classificationDictInstructed = useMemo(
    () =>
      applyClassificationInstructions(
        classificationDict,
        selectedDocumentAccessor
          ? [
              ...(accessNested(
                initialInstructions ?? {},
                selectedDocumentAccessor
              ) ?? []),
              ...Object.values(
                accessNested(instructions ?? {}, selectedDocumentAccessor) ?? {}
              ),
            ]
          : []
      ),
    [
      classificationDict,
      instructions,
      initialInstructions,
      selectedDocumentAccessor,
    ]
  );
  const classificationDictRows = useMemo(
    () =>
      displayRows
        ? insertRowsIntoDict(classificationDictInstructed, rows)
        : classificationDictInstructed,
    [displayRows, classificationDictInstructed, rows]
  );
  //add documentRows to classificationDict
  const { classificationRows, maxDepth } = useMemo(
    () => rowTypesObjectToRowsMapper<IRowTypeRow>(classificationDictRows),
    [classificationDictRows]
  );
  const classificationRowsMeta = useMemo(
    () =>
      applyRowMeta(
        classificationRows,
        maxDepth + 1,
        {} as ICompareDifferenceSettings,
        [],
        0
      ),
    [classificationRows, maxDepth]
  );
  const isCompletelyExpanded = expanded === true;

  let table: ReactTable<IRowTypeRow> | undefined = undefined;

  const queryClient = useQueryClient();

  const rowTypeColumns: ColumnDef<IRowTypeRow>[] = useMemo(() => {
    const columns: ColumnDef<IRowTypeRow>[] = [
      {
        header: () => (
          <div className={classificationStyles["ref-header"]}>
            <ExpandHeader
              value={t("ref")}
              isCompletelyExpanded={isCompletelyExpanded}
              setExpanded={setExpanded}
            />
            <Row className="position-absolute end-0 top-0 me-1 mt-1">
              {enableDisplayRows && (
                <Col className="p-0 pe-2 align-self-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{t("displayRows")}</Tooltip>}
                  >
                    <Form.Switch
                      checked={displayRows}
                      onChange={(e) => {
                        setDisplayRows(e.target.checked);
                      }}
                    />
                  </OverlayTrigger>
                </Col>
              )}
              {enablePopupLink && (
                <Col className="p-0 pe-2 align-self-center">
                  <Nav.Link
                    href={`/${PATH_NAMES_ENUM.MAPPING}/${PATH_NAMES_ENUM.CLASSIFICATION_TABLE}?${ALLOTMENT_TYPE_SEARCH_PARAM}=${allotment_type}`}
                    target="_blank"
                  >
                    <BiLinkExternal size="24" />
                  </Nav.Link>
                </Col>
              )}
              {enableDownload && (
                <Col className="p-0 ">
                  <ExportTableToExcel
                    table={table!}
                    isLoading={isLoading}
                    fileName={`classification_${allotment_type}`}
                    sheetName={t("classification")}
                    mapCell={(cell: Cell<any, any>) => cell.getValue() ?? ""}
                    getFontColor={(cell: Cell<any, unknown>) =>
                      cell.row.original.admin_approval === false
                        ? variables["warning"]
                        : !(
                            cell.row.original.description ??
                            (cell.row.original as unknown as IPriceNode)
                              .designation
                          )
                        ? variables["uncertainty"]
                        : (cell.row.original as unknown as IMappingPriceNode)
                            .unavailable
                        ? variables["gray400"]
                        : (cell.row.original as unknown as IPriceNode)
                            .designation
                        ? variables["cyan"]
                        : undefined
                    }
                    getCellAlignment={getCellAlignment}
                  />
                </Col>
              )}
            </Row>
          </div>
        ),
        id: "ref",
        accessorFn: (originalRow: IRowTypeObject) =>
          originalRow.base
            ? `${displayBase ? `${originalRow.base} | ` : ""}${
                (originalRow.row_number ?? -1) + 1
              }`
            : originalRow.ref,
        cell: (cellContext) => (
          <>
            <ExpandCell cellContext={cellContext} />
            <span className={classificationStyles.offset}>
              {cellContext.getValue<string>()}
            </span>
          </>
        ),
        meta: {
          className: classNames(
            classificationStyles["ref-cell"],
            "vertical-align-middle"
          ),
          excel: { header: t("ref"), width: DEFAULT_COL_WIDTH * 4 },
        },
      },
      {
        header: t("description"),
        accessorFn: (originalRow) =>
          originalRow.description ??
          (originalRow as unknown as IPriceNode).designation,
        id: "description",
        cell: (cellContext) =>
          enableEdit ? (
            <pre
              contentEditable={enableEdit}
              suppressContentEditableWarning
              className={styles["line-offset"]}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onBlur={({ target: { innerText } }) => {
                // only update if modified
                if (innerText !== cellContext.getValue()) {
                  cellContext.row.original.description = innerText;
                  onRowTypeChange(
                    cellContext,
                    queryClient,
                    allotment_type,
                    organization!,
                    { description: innerText, admin_approval: false }
                  );
                }
              }}
              onKeyDown={(event) => {
                const { key, currentTarget, shiftKey } = event;
                if (key === "Enter" && !shiftKey) {
                  event.preventDefault();
                  currentTarget.blur();
                }
                key !== "Escape" && event.stopPropagation();
              }}
            >
              {cellContext.getValue<string>()}
            </pre>
          ) : (
            <div
              className={classNames(
                styles["line-offset"],
                classificationStyles["line-offset"]
              )}
            >
              {cellContext.getValue<string>()}
            </div>
          ),
        meta: {
          className: styles["designation-cell"],
          excel: { width: DESIGNATION_COL_WIDTH },
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.REFERENCE,
        },
      },
      {
        header: t("unit"),
        accessorKey: "unit",
        id: "unit",
        cell: (cellContext) =>
          enableEdit ? (
            <>
              <Select
                className="text-body"
                searchable
                searchValueAsOption
                options={[
                  {
                    value: cellContext.getValue<string>(),
                    label: cellContext.getValue<string>(),
                  },
                  ...units,
                ]}
                onChange={(value) => {
                  if (value !== undefined && value !== cellContext.getValue()) {
                    if (cellContext.row.original.id) {
                      updateRowType({
                        id: cellContext.row.original.id,
                        unit: value as string,
                      });
                    } else {
                      createRowType({
                        ref: cellContext.row.original.ref,
                        admin_approval: false,
                        creation_date: new Date(),
                        unit: value as string,
                        organization: organization!,
                      } as IRowType);
                    }
                  }
                }}
                value={cellContext.getValue<string>()}
              />
            </>
          ) : (
            <span contentEditable={enableEdit} suppressContentEditableWarning>
              {cellContext.getValue<string>()}
            </span>
          ),
        meta: {
          excel: { width: DEFAULT_COL_WIDTH },
        },
      },
    ];
    if (additionalColumns) {
      columns.push(...additionalColumns);
    }

    return columns;
  }, [
    t,
    additionalColumns,
    isCompletelyExpanded,
    enableDisplayRows,
    displayRows,
    enablePopupLink,
    allotment_type,
    enableDownload,
    table,
    isLoading,
    displayBase,
    enableEdit,
    queryClient,
    organization,
  ]);

  table = useReactTable<IRowTypeRow>({
    state: {
      expanded,
      columnVisibility: { [STANDARD_QUANTITY_COLUMN_ID]: displayRows },
    },
    onExpandedChange: setExpanded,
    columns: rowTypeColumns,
    data: classificationRowsMeta,
    debugTable: isDevelopmentEnv,
    getRowId: (originalRow: IRowTypeRow) => `classification-${originalRow.ref}`,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row: IRowTypeRow) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    enableSorting: false,
    meta: {
      // extend the maxDepth by 1 if the document rows are displayed
      maxDepth: maxDepth + (displayRows ? 1 : 0),
    },
  });

  if (tableRef) {
    tableRef.current = table;
  }

  return (
    <ClassificationTableMemo
      table={table}
      activeOption={activeOption}
      onRowClick={onRowClick}
      isLoading={isLoading}
      enableEdit={enableEdit}
      classificationRowsMeta={classificationRowsMeta}
    />
  );
}

const ClassificationTableMemo = memo(
  ({
    table,
    onRowClick,
    isLoading,
    enableEdit,
    activeOption,
    // used to bust the memo cache to rerender on rows change
    classificationRowsMeta,
  }: {
    table: ReactTable<IRowTypeRow>;
    onRowClick?: (row: ReactRow<IRowTypeRow>) => void;
    isLoading: boolean;
    enableEdit?: boolean;
    activeOption?: string;
    classificationRowsMeta: IRowTypeRow[];
  }) => {
    return (
      <Table
        table={table!}
        className={classNames(
          styles.table,
          classificationStyles.classification
        )}
        getRowClassName={(row) => {
          const isCategory = Boolean(row.subRows?.length);
          return classNames(
            isCategory && styles["category-row"],
            row.original.ref === activeOption && classificationStyles.active,
            styles[
              `level-${Math.min(
                row.original.meta?.level ?? 0,
                MAX_SUPPORTED_DEPTH
              )}`
            ],
            styles[`depth-${getRowDepth(table!, row)}`],
            row.original.admin_approval === false &&
              classificationStyles.unapproved,
            !(
              row.original.description ??
              (row.original as unknown as IPriceNode).designation
            ) && classificationStyles.missing,
            (row.original as unknown as IMappingPriceNode).unavailable &&
              classificationStyles["unavailable"],
            (row.original as unknown as IPriceNode).designation &&
              classificationStyles["document-row"]
          );
        }}
        onRowClick={onRowClick}
        getCellClassName={(cell) =>
          classNames(
            styles["last-cell"],
            cellIsSelect(cell, enableEdit) && "py-0"
          )
        }
        noPagination
        stickyHeaders
        isLoading={isLoading}
      />
    );
  }
);

function applyClassificationInstructions(
  classification: IClassification,
  instructions?: IRowTypeInstruction[]
) {
  if (!instructions) {
    return classification;
  }

  instructions = instructions.filter(
    (instruction) =>
      [ROW_TYPE_INSTRUCTIONS_ENUM.SET_CLASSIFICATION_QUANTITY].includes(
        instruction.type
      ) &&
      instruction.detail &&
      instruction.detail.type
  );
  instructions.forEach((instruction) => {
    setNested(
      classification,
      instruction
        .detail!.type!.split(".")
        .map((ref: string) => `children.${ref}`)
        .join("."),
      {
        ...instruction.detail,
      },
      true
    );
  });
  return classification;
}

export async function onRowTypeChange(
  cellContext: CellContext<IRowTypeRow, unknown>,
  queryClient: QueryClient,
  allotment_type: string | undefined,
  organization: string,
  rowType: Partial<IRowType>
) {
  if (cellContext.row.original.id) {
    await updateRowType({
      id: cellContext.row.original.id,
      ...rowType,
    });
    queryClient.setQueryData(
      [QUERY_KEYS_ENUM.ROW_TYPES, allotment_type],
      (oldRowTypes?: IRowType[]) =>
        oldRowTypes?.map((oldRowType) => {
          if (oldRowType.id === cellContext.row.original.id) {
            return { ...oldRowType, ...rowType };
          } else {
            return oldRowType;
          }
        })
    );
  } else {
    const newRowType: IRowType = {
      ...cellContext.row.original,
      ...rowType,
      admin_approval: true,
      organization: organization,
    };
    await createRowType(newRowType);
    queryClient.setQueryData(
      [QUERY_KEYS_ENUM.ROW_TYPES, allotment_type],
      (old?: IRowType[]) => [...(old ?? []), newRowType]
    );
  }
}

/** checks if the provided cell is a Select cell */
function cellIsSelect(cell: Cell<any, any>, enableEdit?: boolean) {
  return enableEdit && cell.id.includes("unit");
}

/**
 * map a list of row types to a nested dict, with ref getting split on "."
 * @param row_types list of row types
 * @returns nested dict with ref as key
 * @example
 * listRowTypesToObjectMapper([{ref:"GOE.DES",...}])
 * // returns {"GOE":{children:[{"DES":...}]}}
 */
export function listRowTypesToObjectMapper(row_types?: IRowType[]) {
  const classification: IClassification = { children: {} };
  row_types?.forEach(
    (row_type) =>
      setNested(
        classification,
        row_type.ref
          .split(".")
          .map((ref) => `children.${ref}`)
          .join("."),
        row_type,
        true
      ),
    true
  );
  return classification;
}

function insertRowsIntoDict(dict: IClassification, rows?: IMappingPriceNode[]) {
  // copy to avoid overriding base dict
  dict = JSON.parse(JSON.stringify(dict));
  rows?.forEach((row) => {
    setNested(
      dict,
      row.type
        ?.split(".")
        .map((ref: string) => `children.${ref}`)
        .join(".") + `.children.${row.base}|${row.row_number}`,
      { ...row, children: undefined },
      true
    );
  });

  return computeClassificationPrices(dict);
}

function computeClassificationPrices(dict: IClassification) {
  Object.values(dict.children).forEach(computeClassificationRowPriceRecursive);
  return dict;
}

function computeClassificationRowPriceRecursive(
  row: IRowTypeObject
): number | undefined {
  row.price =
    row.price ??
    (Object.values(row.children ?? {})
      // only add up available children
      .filter((child) => !(child as unknown as IMappingPriceNode).unavailable)
      .map(computeClassificationRowPriceRecursive)
      .reduce((a: number, b?: number) => a + (b ?? 0), 0) ||
      undefined);
  return row.price;
}

interface IMetaTableRow<T>
  extends ITableRow<IMetaTableRow<T>>,
    Partial<IRowTypeObject> {
  meta?: any;
  ref: string;
}
export function rowTypesObjectToRowsMapper<T extends IMetaTableRow<T>>(
  classification: IClassification | IRowTypeObject,
  classificationRows: T[] = [],
  depth = 0,
  parentRef?: string
) {
  let maxDepth = depth;
  Object.entries(classification.children ?? {})
    .sort(
      ([, a]: [string, IRowTypeObject], [, b]: [string, IRowTypeObject]) =>
        // make sure document rows come up first
        -Number(a.childs !== undefined) + Number(b.childs !== undefined)
    )
    .forEach(([ref, row]) => {
      const computedRef = `${parentRef ? `${parentRef}.` : ""}${ref}`;
      const newRow = {
        ...row,
        ref: row.ref ?? computedRef,
        meta: { level: depth },
      } as T; // cast to T to avoid the following TS error:
      // `is assignable to the constraint of type 'T', but 'T' could be instantiated with a different subtype of constraint`

      classificationRows.push(newRow);
      if (row.children) {
        newRow.subRows = [];
        maxDepth = Math.max(
          maxDepth,
          rowTypesObjectToRowsMapper(
            row,
            newRow.subRows,
            depth + 1,
            computedRef
          ).maxDepth
        );
      }
    });
  return { classificationRows, maxDepth };
}

export function getCellAlignment<T>(
  cell: Cell<T, unknown>,
  level?: number,
  maxLevel: number = MAX_SUPPORTED_DEPTH
): Partial<Alignment> | undefined {
  if (
    level !== undefined &&
    cell.column.columnDef.meta?.dataType ===
      TABLE_COLUMN_DATA_TYPE_ENUM.REFERENCE
  ) {
    return {
      indent: maxLevel - level,
      horizontal: "left",
      readingOrder: "ltr",
    };
  }
}
