import { exists as i18nexists } from "i18next";
import { useCallback } from "react";
import {
  UseTranslationOptions,
  useTranslation as useI18nTranslation,
} from "react-i18next";

export type TTranslateFunction = (
  key: string,
  options?: (UseTranslationOptions<undefined> & Record<string, any>) | undefined
) => string;

/**
 * Hook wrapping the original i18n one to reference component only once
 * t will act as the original t method for this component
 */
export function useTranslation(componentName: string) {
  const { t: translate } = useI18nTranslation();
  const t = useCallback<TTranslateFunction>(
    (
      key: string,
      options?: UseTranslationOptions<undefined> & Record<string, any>
    ) => translate(`${componentName}.${key}`, options ?? {}),
    [componentName, translate]
  );
  return { t };
}

/**
 * Hook wrapping the original i18n exists to reference component only once
 * exists will act as the original exists method for this component
 */
export function useExists(componentName: string) {
  const exists = useCallback(
    (key: string) => i18nexists(`${componentName}.${key}`),
    [componentName]
  );
  return { exists };
}

export function useEnumTranslation() {
  const { t: translate } = useI18nTranslation();
  const tEnum = useCallback(
    (key: string, options?: UseTranslationOptions<undefined>) => {
      key = key.replace(/\./g, "__");
      return translate(`enum.${key}`, options ?? {});
    },
    [translate]
  );
  return { tEnum };
}
