import { SortingState } from "@tanstack/react-table";

import { IColumnFilters, isMinMaxValues } from "../operations/OperationsTable";
import { TOperationCreationFormInputs } from "../shared/OperationModal";
import {
  IGeometry,
  locationToLocationDTOMapper,
} from "../shared/types/location.types";
import {
  IOperation,
  IOperationDTO,
  operationDTOToOperationMapper,
} from "../shared/types/operation.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";

import { toISODate } from "./APIUtils";
import { FETCH_METHODS_ENUM, authRequestJSON } from "./fetch";

export interface IFetchOperationsOptions {
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
  globalFilter: string | null;
  columnFilters: IColumnFilters;
}

const operationsFetchResultToOperationsQuery = ({
  num_pages,
  count,
  page_number,
  results,
}: IPaginatedQueryResults<IOperationDTO>): IPaginatedQueryResults<IOperation> => ({
  num_pages,
  page_number,
  count,
  results: results.map(operationDTOToOperationMapper),
});

export async function fetchOperations({
  pageIndex,
  pageSize,
  sorting,
  globalFilter,
  columnFilters,
}: IFetchOperationsOptions) {
  const searchParams: Record<string, string | string[]> = {
    page_size: pageSize.toString(),
    page: (pageIndex + 1).toString(), // Backend start indexing at 1
  };
  if (globalFilter) {
    searchParams.search = globalFilter;
  }

  if (columnFilters) {
    Object.entries(columnFilters).forEach(([key, value]) => {
      if (isMinMaxValues(value)) {
        if (value.min !== undefined) {
          searchParams[`${key}_gte`] = String(value.min);
        }
        if (value.max !== undefined) {
          searchParams[`${key}_lte`] = String(value.max);
        }
      } else if (value) {
        searchParams[key] = value;
      }
    });
  }

  const sortBy = sorting.find(() => true);
  if (sortBy !== undefined) {
    searchParams.ordering = `${sortBy.desc ? "-" : ""}${sortBy.id}`;
  }

  const result = await authRequestJSON<IPaginatedQueryResults<IOperationDTO>>(
    `project/?${new URLSearchParams(searchParams as Record<string, string>)}`
  );
  return operationsFetchResultToOperationsQuery(result);
}

export async function fetchOperation(id: string) {
  const operation = await authRequestJSON<IOperationDTO>(`project/${id}/`);

  // fetch the location, type, building_category & floor_area from the unique Work
  return operationDTOToOperationMapper(operation);
}

export async function createOperation({
  name,
  reference_code,
  permit_date,
  tender_date,
  location_osm_id,
  location_name,
  location_housenumber,
  location_street,
  location_postcode,
  location_city,
  location_district,
  location_state,
  location_country,
  location_country_code,
  location_geometry,
  status,
  contract_type,
  floor_area,
  work_type,
  building_category,
  original_author,
  organization,
}: TOperationCreationFormInputs & { organization: string }) {
  const project = await authRequestJSON<IOperation>(`project/`, {
    method: FETCH_METHODS_ENUM.POST,
    body: JSON.stringify({
      name,
      reference_code,
      permit_date: toISODate(permit_date),
      tender_date: toISODate(tender_date),
      status,
      contract_type,
      organization,
      original_author,
    }),
  });
  await authRequestJSON<any>(`work/`, {
    method: FETCH_METHODS_ENUM.POST,
    body: JSON.stringify({
      name,
      type: work_type,
      project: project.id,
      building_category,
      floor_area,
      location_osm_id,
      location_name,
      location_housenumber,
      location_street,
      location_postcode,
      location_city,
      location_district,
      location_state,
      location_country,
      location_country_code,
      location_geometry,
    }),
  });
  return project.id;
}

export async function updateOperation({
  name,
  reference_code,
  permit_date,
  tender_date,
  location_osm_id,
  location_name,
  location_housenumber,
  location_street,
  location_postcode,
  location_city,
  location_district,
  location_state,
  location_country,
  location_country_code,
  location_geometry,
  location_type,
  status,
  contract_type,
  floor_area,
  work_type,
  building_category,
  id,
}: TOperationCreationFormInputs) {
  await authRequestJSON<IOperation>(`project/${id}/`, {
    method: FETCH_METHODS_ENUM.PATCH,
    body: JSON.stringify({
      name,
      reference_code,
      permit_date: toISODate(permit_date),
      tender_date: toISODate(tender_date),
      status,
      contract_type,
    }),
  });
  const works = await authRequestJSON<any>(
    `work/?${new URLSearchParams({ project: id! })}`
  );
  await Promise.all(
    works.results.map((work: any) =>
      authRequestJSON<any>(`work/${work.id}/`, {
        method: FETCH_METHODS_ENUM.PATCH,
        body: JSON.stringify({
          type: work_type,
          building_category,
          floor_area,
          ...locationToLocationDTOMapper({
            location_osm_id,
            location_name: location_name ?? undefined,
            location_housenumber,
            location_street,
            location_postcode,
            location_city,
            location_district,
            location_state,
            location_country,
            location_country_code,
            location_type,
            location_geometry: location_geometry as IGeometry,
          }),
        }),
      })
    )
  );
}
