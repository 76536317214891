import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function SearchIcon({ fill = styles.white, className = "", size = 43 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 43 43"
      className={className}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M26.355 25.083h1.416l8.94 8.959-2.67 2.67-8.958-8.941v-1.416l-.483-.501a11.595 11.595 0 0 1-7.58 2.813c-6.431 0-11.645-5.214-11.645-11.646S10.589 5.375 17.021 5.375s11.646 5.214 11.646 11.646c0 2.884-1.057 5.536-2.813 7.579l.501.483ZM8.958 17.021a8.052 8.052 0 0 0 8.063 8.062 8.052 8.052 0 0 0 8.062-8.062 8.052 8.052 0 0 0-8.062-8.063 8.052 8.052 0 0 0-8.063 8.063Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
