import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn,
} from "react-hook-form";

import { useTranslation } from "../../shared/i18n";
import { Select } from "../../shared/inputs";
import { IPriceEstimate } from "../../shared/types/estimate.types";
import { ITender } from "../../shared/types/tender.types";
import { COMPARE_DIFFERENCE_BASES_ENUM } from "../Compare.types";

import { TCompareSettingsFormInputs } from "./CompareSettingsModal";

export function NumberDifferenceBaseInput({
  field: { onChange, onBlur, value, name, ref },
  estimate,
  tenders,
}: {
  field: ControllerRenderProps<
    TCompareSettingsFormInputs,
    "difference.number_difference.base"
  >;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TCompareSettingsFormInputs>;
  estimate?: IPriceEstimate;
  tenders: ITender[];
}) {
  const { t } = useTranslation("NumberDifferenceBaseInput");
  const isComparingFromTender = !Object.values(
    COMPARE_DIFFERENCE_BASES_ENUM
  ).includes(value as COMPARE_DIFFERENCE_BASES_ENUM);
  const hasTenders = tenders.length > 0;
  const [selectedTenderId, setSelectedTenderId] = useState<string | undefined>(
    tenders.find((tender) => tender)?.id
  );
  if (!selectedTenderId && hasTenders) {
    setSelectedTenderId(tenders.find((tender) => tender)?.id);
  }
  const tendersOptions = tenders.map((tender) => ({
    value: tender.id,
    label: (
      <>
        <strong className="me-2">{tender.company_name}</strong>
        {tender.version && t("version", { version: tender.version })}
      </>
    ),
  }));
  return (
    <Form.Group controlId={name}>
      <Form.Label>{t("choose number comparison base")}</Form.Label>
      <Row className="mb-3">
        <Col>
          <Form.Check
            inline
            disabled={!estimate}
            label={t("base estimate")}
            name={name}
            type="radio"
            id={`${name}-${COMPARE_DIFFERENCE_BASES_ENUM.ESTIMATE}`}
            onBlur={onBlur}
            ref={ref}
            checked={value === COMPARE_DIFFERENCE_BASES_ENUM.ESTIMATE}
            onChange={() => onChange(COMPARE_DIFFERENCE_BASES_ENUM.ESTIMATE)}
          />
        </Col>
        <Col>
          <Form.Check
            inline
            label={t("base average")}
            name={name}
            type="radio"
            id={`${name}-${COMPARE_DIFFERENCE_BASES_ENUM.AVERAGE}`}
            onBlur={onBlur}
            checked={value === COMPARE_DIFFERENCE_BASES_ENUM.AVERAGE}
            onChange={() => onChange(COMPARE_DIFFERENCE_BASES_ENUM.AVERAGE)}
          />
        </Col>
        <Col>
          <Form.Check
            inline
            label={t("base tender")}
            name={name}
            type="radio"
            onBlur={onBlur}
            checked={isComparingFromTender}
            onChange={() => onChange(selectedTenderId)}
            disabled={!hasTenders}
          />
          <Select
            disabled={!hasTenders}
            options={tendersOptions}
            value={selectedTenderId}
            onChange={(id) => {
              setSelectedTenderId(id as string);
              onChange(id as string);
            }}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}
