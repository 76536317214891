import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Control } from "react-hook-form";
import * as yup from "yup";

import { useTranslation } from "../../../shared/i18n";
import {
  ControlledInput,
  FoundationTypeSelect,
  ProtectionSelect,
} from "../../../shared/inputs";
import { getUndefinedSafeSelectOnChange } from "../../../shared/inputs/input";
import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  LOT_CLOSURE_FOUNDATION_TYPE_ENUM,
  LOT_CLOSURE_PROTECTION_ENUM,
} from "../../../shared/types/lot.types";

import { yupRequiredWhenConventionalAllotmentIncludes } from "./utils";

function yupRequiredWhen<S>(schema: yup.Schema<S>) {
  return yupRequiredWhenConventionalAllotmentIncludes(
    schema,
    CONVENTIONAL_ALLOTMENTS_ENUM.FOUNDATIONS
  );
}

export const schema = yup.object({
  foundation_protection: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_PROTECTION_ENUM))
  ),
  foundation_type: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_FOUNDATION_TYPE_ENUM))
  ),
});

export type TFormInputs = yup.InferType<typeof schema>;

export function FoundationAllotment({
  control,
}: {
  control: Control<TFormInputs>;
}) {
  const { t } = useTranslation("LotClosureModal");

  return (
    <>
      <h6 className="my-4">{t("foundation title")}</h6>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("foundation protection")}
          name="foundation_protection"
          schema={schema}
          control={control}
          render={({ field }) => (
            <ProtectionSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("foundation type")}
          name="foundation_type"
          schema={schema}
          control={control}
          render={({ field }) => (
            <FoundationTypeSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
      </Row>
    </>
  );
}
