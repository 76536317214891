import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { isUserAdmin } from "../shared/types/account.types";
import { IOperation } from "../shared/types/operation.types";
import { IOrganization } from "../shared/types/organization.types";
import { useCurrentLot, useOperationQuery } from "../shared/useSharedQueries";

import { UserContext } from "./UserContext";

export function canCreateNewOperation(
  organizations: IOrganization[] | undefined
) {
  // must at least be able to write on an organization
  return Boolean(organizations?.some(({ rights: { can_write } }) => can_write));
}

export function canEditOperation(operation: IOperation | undefined) {
  return Boolean(operation?.rights.can_write);
}

export function useCanEditOperation() {
  const { operationId } = useParams();
  const operationQuery = useOperationQuery(operationId);
  return canEditOperation(operationQuery.data);
}

/**
 * Checks if edit enabled
 * disabled on closed lots (aka. having a selected) since they aren't editable anymore, except by admin user
 * also checks if user can edit the Operation
 *
 * @param allowed override on canEditOperation
 */
export function useCanEditCurrentLot(allowed?: boolean) {
  const lot = useCurrentLot();

  const { user } = useContext(UserContext);

  const editDisabled = !isUserAdmin(user) && Boolean(lot?.selected);

  const canEditOperation = useCanEditOperation();

  const result = useMemo(
    () => ({
      editDisabled,
      canEdit:
        !editDisabled &&
        (allowed || (allowed === undefined && canEditOperation)),
    }),
    [allowed, canEditOperation, editDisabled]
  );

  return result;
}
