import classNames from "classnames";
import React from "react";

import { TriangleDirections, TriangleIcon } from "../../icons";

export interface IDropDownCheckBoxButtonProps {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  size?: number;
}
export function DropDownCheckBoxButton({
  checked,
  onChange,
  className,
  size = 42,
}: IDropDownCheckBoxButtonProps) {
  return (
    <label className={classNames(className)} role="button">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="d-none"
      />
      <TriangleIcon
        size={size}
        direction={checked ? TriangleDirections.UP : TriangleDirections.DOWN}
      />
    </label>
  );
}
