import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Control, useForm } from "react-hook-form";
import * as yup from "yup";

import styles from "../../shared/DefaultModal.module.scss";
import { useTranslation, yupLocale } from "../../shared/i18n";
import { ArrowSmallRightIcon } from "../../shared/icons";
import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  ILot,
  LOT_CLOSURE_THERMAL_INSULATION_ENUM,
} from "../../shared/types/lot.types";
import { useOperationQuery } from "../../shared/useSharedQueries";
import { cancelEvent } from "../../shared/utils";

import {
  BlanketAllotment,
  TFormInputs as TBlanketFormInput,
  schema as blanket_schema,
} from "./Allotments/BlanketAllotment";
import {
  CommonAllotment,
  schema as common_schema,
} from "./Allotments/CommonAllotment";
import {
  EarthworkAllotment,
  TFormInputs as TEarthworkFormInput,
  schema as earthwork_schema,
} from "./Allotments/EarthworkAllotment";
import {
  ExteriorWoodFurnishingsAllotment,
  TFormInputs as TExteriorWoodFurnishingsFormInput,
  schema as wood_furnishings_schema,
} from "./Allotments/ExteriorWoodFurnishingsAllotment";
import {
  FacadeAllotment,
  TFormInputs as TFacadeFormInput,
  schema as facade_schema,
} from "./Allotments/FacadeAllotment";
import {
  FireSafetySystemAllotment,
  TFormInputs as TFireSafetySystemFormInput,
  schema as fireSafety_schema,
} from "./Allotments/FireSafetySystemAllotment";
import {
  FoundationAllotment,
  TFormInputs as TFoundationFormInput,
  schema as foundation_schema,
} from "./Allotments/FoundationAllotment";
import {
  HVACAllotment,
  TFormInputs as THVACFormInput,
  schema as hvac_allotment,
} from "./Allotments/HVACAllotment";
import {
  HighVoltageAllotmentAllotment,
  TFormInputs as THighVoltageFormInput,
  schema as highVoltage_schema,
} from "./Allotments/HighVoltageAllotment";
import {
  LowVoltageAllotmentAllotment,
  TFormInputs as TLowVoltageFormInput,
  schema as lowVoltage_schema,
} from "./Allotments/LowVoltageAllotment";
import {
  PartitionLiningAllotment,
  TFormInputs as TPartitionLiningFormInput,
  schema as partitionLining_schema,
} from "./Allotments/PartitionLiningAllotment";
import {
  SealingAllotment,
  TFormInputs as TSealingFormInput,
  schema as sealing_schema,
} from "./Allotments/SealingAllotment";
import {
  StructuralWorkAllotment,
  TFormInputs as TStructuralWorkFormInput,
  schema as structuralWork_schema,
} from "./Allotments/StructuralWorkAllotment";

yup.setLocale(yupLocale);

const schema = common_schema
  .concat(structuralWork_schema)
  .concat(earthwork_schema)
  .concat(foundation_schema)
  .concat(facade_schema)
  .concat(sealing_schema)
  .concat(blanket_schema)
  .concat(wood_furnishings_schema)
  .concat(hvac_allotment)
  .concat(highVoltage_schema)
  .concat(lowVoltage_schema)
  .concat(fireSafety_schema)
  .concat(partitionLining_schema);

type TConventionalAllotmentFormInputs = yup.InferType<typeof schema>;

/**
 * dictionary of fields present in each Allotment Form
 */
const ConventionalAllotmentFields: Partial<{
  [T in CONVENTIONAL_ALLOTMENTS_ENUM]: string[];
}> = {
  [CONVENTIONAL_ALLOTMENTS_ENUM.STRUCTURAL_WORK]: Object.keys(
    structuralWork_schema.fields
  ),
  [CONVENTIONAL_ALLOTMENTS_ENUM.EARTHWORK]: Object.keys(
    earthwork_schema.fields
  ),
  [CONVENTIONAL_ALLOTMENTS_ENUM.FOUNDATIONS]: Object.keys(
    foundation_schema.fields
  ),
  [CONVENTIONAL_ALLOTMENTS_ENUM.FACADES]: Object.keys(facade_schema.fields),
  [CONVENTIONAL_ALLOTMENTS_ENUM.SEALING]: Object.keys(sealing_schema.fields),
  [CONVENTIONAL_ALLOTMENTS_ENUM.BLANKET]: Object.keys(blanket_schema.fields),
  [CONVENTIONAL_ALLOTMENTS_ENUM.EXTERIOR_WOOD_FURNISHINGS]: Object.keys(
    wood_furnishings_schema.fields
  ),
  [CONVENTIONAL_ALLOTMENTS_ENUM.HVAC]: Object.keys(hvac_allotment.fields),
  [CONVENTIONAL_ALLOTMENTS_ENUM.HIGH_VOLTAGE_ELECTRICAL_SYSTEMS]: Object.keys(
    highVoltage_schema.fields
  ),
  [CONVENTIONAL_ALLOTMENTS_ENUM.LOW_VOLTAGE_ELECTRICAL_SYSTEMS]: Object.keys(
    lowVoltage_schema.fields
  ),
  [CONVENTIONAL_ALLOTMENTS_ENUM.FIRE_SAFETY_SYSTEM]: Object.keys(
    fireSafety_schema.fields
  ),
  [CONVENTIONAL_ALLOTMENTS_ENUM.PARTITIONS_LININGS]: Object.keys(
    partitionLining_schema.fields
  ),
};

export function ConventionalAllotmentForm({
  lot: defaultValues,
  onSubmit,
  onClose,
  readOnly,
}: {
  lot: ILot;
  onSubmit: (lot: Partial<ILot>) => void;
  onClose: () => void;
  readOnly?: boolean;
}) {
  const { t } = useTranslation("LotClosureModal");

  const {
    handleSubmit,
    control,
    watch,
    unregister,
    formState: { isValid },
  } = useForm<TConventionalAllotmentFormInputs>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const operationQuery = useOperationQuery(defaultValues.project);
  const operation = operationQuery.data;

  const conventional_allotments = watch("conventional_allotments");

  const unregisterUnusedFieldAndSubmit = handleSubmit((v) => {
    // unregister fields from not selected conventional allotments to clean up
    // those fields are only cleaned up on submit to allowing miss-clicks on conventional allotment selection
    // otherwise the user might have to reenter all the fields on an accidental deselection
    Object.values(CONVENTIONAL_ALLOTMENTS_ENUM)
      .filter((v) => !conventional_allotments.includes(v))
      .forEach((lot) => {
        const fields = ConventionalAllotmentFields[lot];
        if (fields) {
          unregister(fields as any);
          for (const field of fields) {
            delete (v as any)[field];
          }
        }
      });
    onSubmit(v);
  });

  const onFormSubmit = readOnly
    ? (e: any) => {
        cancelEvent(e);
        onClose();
      }
    : unregisterUnusedFieldAndSubmit;

  return (
    <>
      <Modal.Header className="border-bottom-0 px-4 mx-2 pb-4 justify-content-md-center">
        <Modal.Title
          className={classNames("text-center", styles["wrap-balance"])}
        >
          {t("close title")}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <fieldset disabled={readOnly}>
          <CommonAllotment control={control} />
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.STRUCTURAL_WORK
          ) && (
            <StructuralWorkAllotment
              // TConventionalAllotmentFormInputs is a superset of TStructuralWorkFormInput so it's viable to cast it down
              // to keep StructuralWorkAllotment reusable in other forms
              control={control as unknown as Control<TStructuralWorkFormInput>}
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.EARTHWORK
          ) && (
            <EarthworkAllotment
              // TConventionalAllotmentFormInputs is a superset of TEarthworkFormInput so it's viable to cast it down
              // to keep EarthworkAllotment reusable in other forms
              control={control as unknown as Control<TEarthworkFormInput>}
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.FOUNDATIONS
          ) && (
            <FoundationAllotment
              control={control as unknown as Control<TFoundationFormInput>}
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.FACADES
          ) && (
            <FacadeAllotment
              control={control as unknown as Control<TFacadeFormInput>}
              exterior_insulation_required={operation?.thermal_insulation?.includes(
                LOT_CLOSURE_THERMAL_INSULATION_ENUM.exterior_insulation
              )}
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.SEALING
          ) && (
            <SealingAllotment
              control={control as unknown as Control<TSealingFormInput>}
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.BLANKET
          ) && (
            <BlanketAllotment
              control={control as unknown as Control<TBlanketFormInput>}
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.EXTERIOR_WOOD_FURNISHINGS
          ) && (
            <ExteriorWoodFurnishingsAllotment
              control={
                control as unknown as Control<TExteriorWoodFurnishingsFormInput>
              }
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.HVAC
          ) && (
            <HVACAllotment
              control={control as unknown as Control<THVACFormInput>}
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.HIGH_VOLTAGE_ELECTRICAL_SYSTEMS
          ) && (
            <HighVoltageAllotmentAllotment
              control={control as unknown as Control<THighVoltageFormInput>}
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.LOW_VOLTAGE_ELECTRICAL_SYSTEMS
          ) && (
            <LowVoltageAllotmentAllotment
              control={control as unknown as Control<TLowVoltageFormInput>}
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.FIRE_SAFETY_SYSTEM
          ) && (
            <FireSafetySystemAllotment
              control={
                control as unknown as Control<TFireSafetySystemFormInput>
              }
            />
          )}
          {conventional_allotments?.includes(
            CONVENTIONAL_ALLOTMENTS_ENUM.PARTITIONS_LININGS
          ) && (
            <PartitionLiningAllotment
              control={control as unknown as Control<TPartitionLiningFormInput>}
              partition_walls_insulation_required={operation?.thermal_insulation?.includes(
                LOT_CLOSURE_THERMAL_INSULATION_ENUM.interior_insulation
              )}
            />
          )}
        </fieldset>
        <Row className="justify-content-center mt-4 mb-5">
          <Col sm="auto">
            <Button
              type="submit"
              size="lg"
              variant="primary"
              disabled={!readOnly && !isValid}
              className="rectangular ms-4"
            >
              {t("next")}
              <ArrowSmallRightIcon className="ms-2" />
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
