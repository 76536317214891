import React, { ReactNode, useState } from "react";
import Button from "react-bootstrap/Button";
import { FormGroupProps } from "react-bootstrap/FormGroup";
import InputGroup from "react-bootstrap/InputGroup";
import {
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";

import { EyeCrossedIcon, EyeIcon, LockIcon } from "../shared/icons";
import { Input } from "../shared/inputs/input";

export function PasswordInput<T extends FieldValues, Y>({
  ...props
}: {
  register: UseFormRegister<T>;
  name: Path<T>;
  label: ReactNode;
  required?: boolean;
  error?: FieldError;
  placeholder?: string;
} & FormGroupProps &
  Y) {
  const [lockId] = useState(`lock-icon-${props.name}`);
  const [eyeId] = useState(`eye-icon-${props.name}`);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const togglePasswordVisibility = () =>
    setPasswordVisibility((visibility) => !visibility);
  return (
    <Input
      formControlProps={{ "aria-describedby": lockId }}
      autoComplete="current-password"
      {...props}
      type={passwordVisibility ? "text" : "password"}
      inputGroupBefore={
        <InputGroup.Text id={lockId}>
          <LockIcon />
        </InputGroup.Text>
      }
      inputGroupAfter={
        <InputGroup.Text id={eyeId}>
          <Button
            variant="text"
            className="p-0"
            onClick={togglePasswordVisibility}
          >
            {passwordVisibility ? <EyeIcon /> : <EyeCrossedIcon />}
          </Button>
        </InputGroup.Text>
      }
    />
  );
}
