import { isAfter } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { getSetSearchParamsCallbackWithLastUpdatedSearchParams } from "../api/APIUtils";
import { getLatestEstimate } from "../api/fetchEstimates";
import { ICompany } from "../shared/types/company.types";
import {
  COMPANY_SORT_MODE,
  DEFAULT_COMPANY_SORT_MODE,
  ITender,
  companySortFunctionBySortMode,
} from "../shared/types/tender.types";
import {
  useEstimateQuery,
  useEstimatesQuery,
  useTendersQuery,
} from "../shared/useSharedQueries";

const emptyTenders: ITender[] = [];

export const ESTIMATE_SEARCH_PARAM = "estimate";
export const TENDERS_SEARCH_PARAM = "tenders";
export const TENDERS_COMPANY_SORT_MODE_SEARCH_PARAM = "tenders-company-sort";

export function useEstimateAndSortedTendersFromSearchParamsOrInit(
  lotId: string | undefined
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const requestedEstimateId = searchParams.get(ESTIMATE_SEARCH_PARAM);
  const stringifiedTenderIds = searchParams.get(TENDERS_SEARCH_PARAM);
  const requestedTenderIds = useMemo(
    () => stringifiedTenderIds?.split(","),
    [stringifiedTenderIds]
  );

  const setEstimate = useCallback(
    (estimateId?: string) =>
      setSearchParams(
        getSetSearchParamsCallbackWithLastUpdatedSearchParams(
          (searchParams) => {
            estimateId
              ? searchParams.set(ESTIMATE_SEARCH_PARAM, estimateId)
              : searchParams.delete(ESTIMATE_SEARCH_PARAM);
            return searchParams;
          }
        ),
        { replace: true }
      ),
    [setSearchParams]
  );

  const setTenders = useCallback(
    (tenderIds: string[]) =>
      setSearchParams(
        getSetSearchParamsCallbackWithLastUpdatedSearchParams(
          (searchParams) => {
            searchParams.set(TENDERS_SEARCH_PARAM, tenderIds.join(","));
            return searchParams;
          }
        ),
        { replace: true }
      ),
    [setSearchParams]
  );

  // we need to init this page with the latest versions of available tenders and estimate if none was requested by user
  // we snapshot searchParams at page load to know if we should init with default values if absent
  const [[initialRequestedEstimateId, initialRequestedTendersIds]] = useState([
    requestedEstimateId,
    requestedTenderIds,
  ]);
  // try to get latestEstimate if none is present in searchParams
  const shouldFetchLatestEstimate = Boolean(
    lotId && !initialRequestedEstimateId
  );
  const latestEstimateQuery = useEstimatesQuery(lotId, {
    select: getLatestEstimate,
    enabled: shouldFetchLatestEstimate,
  });
  const latestEstimate = latestEstimateQuery.data;
  const latestEstimateId = latestEstimate?.id;
  useEffect(() => {
    if (latestEstimateId && !requestedEstimateId) {
      setEstimate(latestEstimateId);
    }
  }, [latestEstimateId, requestedEstimateId, setEstimate]);

  // try to get latest tender version of each company if none was present in searchParams
  const shouldFetchLatestTenderVersions =
    Boolean(lotId) &&
    (!initialRequestedTendersIds ||
      (!initialRequestedEstimateId && initialRequestedTendersIds.length === 1));
  const select = (companies: ICompany[]) => {
    const latestVersions =
      companies
        ?.filter(({ tenders }) => tenders.length > 0)
        .map(({ tenders }) =>
          tenders.reduce((latestTender, tender) =>
            isAfter(latestTender.upload_date, tender.upload_date)
              ? latestTender
              : tender
          )
        ) ?? emptyTenders;
    return latestVersions;
  };
  const latestTenderVersionsQuery = useTendersQuery(lotId, {
    select,
    enabled: shouldFetchLatestTenderVersions,
  });

  const latestTenderVersions = latestTenderVersionsQuery?.data;
  useEffect(() => {
    const latestTenderVersionIds = latestTenderVersions?.map(({ id }) => id);
    if (latestTenderVersionIds && !requestedTenderIds) {
      setTenders(latestTenderVersionIds);
    }
  }, [latestTenderVersions, requestedTenderIds, setTenders]);

  const estimateQuery = useEstimateQuery(requestedEstimateId);

  const setTendersCompanySortMode = useCallback(
    (companySortMode?: COMPANY_SORT_MODE) =>
      setSearchParams(
        getSetSearchParamsCallbackWithLastUpdatedSearchParams(
          (searchParams) => {
            searchParams.set(
              TENDERS_COMPANY_SORT_MODE_SEARCH_PARAM,
              companySortMode ?? DEFAULT_COMPANY_SORT_MODE
            );
            return searchParams;
          }
        ),
        { replace: true }
      ),
    [setSearchParams]
  );
  const tendersCompanySortModeSearchParam = searchParams.get(
    TENDERS_COMPANY_SORT_MODE_SEARCH_PARAM
  ) as COMPANY_SORT_MODE;
  const tendersCompanySortMode = Object.values(COMPANY_SORT_MODE).includes(
    tendersCompanySortModeSearchParam ?? ""
  )
    ? tendersCompanySortModeSearchParam
    : DEFAULT_COMPANY_SORT_MODE;
  const companySortFn = companySortFunctionBySortMode[tendersCompanySortMode];

  const selectRequestedTenders = useCallback(
    (companies: ICompany[]) => {
      const requestedVersions = companySortFn(companies).reduce(
        (versions, company) =>
          versions.concat(
            company.tenders.filter((tender) =>
              requestedTenderIds?.includes(tender.id)
            )
          ),
        [] as ITender[]
      );
      return requestedVersions;
    },
    [requestedTenderIds, companySortFn]
  );

  const tendersQuery = useTendersQuery(lotId, {
    select: selectRequestedTenders,
  });

  const isLoading =
    (shouldFetchLatestEstimate && latestEstimateQuery.isLoading) ||
    (shouldFetchLatestTenderVersions && latestTenderVersionsQuery.isLoading) ||
    estimateQuery.isLoading ||
    tendersQuery.isLoading;

  const isInitialLoading =
    (shouldFetchLatestEstimate && latestEstimateQuery.isInitialLoading) ||
    (shouldFetchLatestTenderVersions &&
      latestTenderVersionsQuery.isInitialLoading) ||
    estimateQuery.isInitialLoading ||
    tendersQuery.isInitialLoading;

  return {
    estimate: estimateQuery.data,
    tenders: tendersQuery.data ?? [],
    tendersCompanySortMode,
    isLoading,
    isInitialLoading,
    setEstimate,
    setTenders,
    setTendersCompanySortMode,
  };
}
