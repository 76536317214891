import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function TendersIcon({
  fill = styles.white,
  className = "",
  size = 36,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={className}
      height={applyFontRatioToPxSize(size)}
      width={applyFontRatioToPxSize(size)}
      viewBox="0 0 36 36"
    >
      <path
        fill={fill}
        d="m16.566.559 16.44 2.35 2.347 16.44-15.265 15.266a1.661 1.661 0 0 1-2.348 0L1.299 18.174a1.66 1.66 0 0 1 0-2.348L16.566.559Zm4.696 14.092a3.32 3.32 0 1 0 4.696-4.696 3.32 3.32 0 0 0-4.696 4.696Z"
      />
    </svg>
  );
}
