import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function SavedComparisonsIcon({
  fill = styles.white,
  className = "",
  size = 36,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="-2 0 36 36"
      fill="none"
      className={className}
    >
      <path
        fill={fill}
        d="M2.228.417H29.56a1.708 1.708 0 0 1 1.709 1.708v32.93a.853.853 0 0 1-1.203.78L15.895 29.51 1.722 35.834a.854.854 0 0 1-1.202-.779V2.125A1.708 1.708 0 0 1 2.228.417Zm25.625 30.69V3.834H3.936v27.273l11.959-5.337 11.958 5.337ZM15.894 20.063l-5.02 2.64.958-5.59-4.06-3.96 5.613-.817 2.51-5.086 2.51 5.086 5.613.817-4.061 3.96.957 5.59-5.02-2.64Z"
      />
    </svg>
  );
}
