import { CellContext, ExpandedState } from "@tanstack/react-table";

import { IPriceAverageRow } from "../shared/types/averagePrice.types";
import { DIFFERENCE_DISPLAY_MODE_ENUM } from "../shared/visualization/Difference";

import { IPriceAverageDifference } from "./PriceAverageTable.types";

export function getValueNumberDifference(
  cellContext: CellContext<IPriceAverageRow, any>,
  numberDifferenceSettings?: IPriceAverageDifference
) {
  const reference = cellContext.row.getValue<number | undefined>("average");
  const price = cellContext.cell.getValue<number | undefined>();
  if (reference === undefined || price === undefined) {
    return undefined;
  }
  if (numberDifferenceSettings?.mode === DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT) {
    return (price - reference) / reference;
  } else {
    return price - reference;
  }
}

/**
 * Add to expanded the ids of all ancestors of any line having prices
 * @param expanded The current expanded state to overwrite, gets modified in place
 * @param rows The available rows
 * @param getRowId Callback to get a row's id
 * @returns
 */
export function getExpandedContainsNestedPrices(
  expanded: ExpandedState,
  rows: IPriceAverageRow[],
  getRowId: (row: IPriceAverageRow) => string
) {
  const expandedRecord =
    typeof expanded == "boolean" ? ({} as Record<string, boolean>) : expanded;

  const rowsWithPrices = rows.filter((row) =>
    Object.values(row.tenders ?? {}).some((price) => price != null)
  );
  rowsWithPrices.forEach((row) => {
    const rowId = getRowId(row);
    expandAllAncestorsAndItself(expandedRecord, rowId);
  });

  // recursively call getExpandedContainsNestedPrices on all subRows
  rows.forEach(
    (row) =>
      row.subRows?.length &&
      getExpandedContainsNestedPrices(expandedRecord, row.subRows, getRowId)
  );

  return expandedRecord;
}

/**
 * iterate over all ancestors to expand them
 * @param expandedRecord gets modified in place
 * @param rowId
 */
function expandAllAncestorsAndItself(
  expandedRecord: Record<string, boolean>,
  rowId: string
) {
  // split the id into its parts, and iterate over all ancestors by adding the parts back together
  const ancestorRowIdSplits = rowId.split(".").slice(0, -1);
  ancestorRowIdSplits.reduce((previousId, currentSplit) => {
    const currentId = previousId
      ? `${previousId}.${currentSplit}`
      : currentSplit;
    expandedRecord[currentId] = true;
    return currentId;
  }, "");
}
