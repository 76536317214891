import { Cell, Table } from "@tanstack/react-table";
import { Alignment } from "exceljs";

import { DIFFERENCE_POSITION_ENUM } from "../compare/Compare.types";
import { TCellComponentValue } from "../compare/CompareTable/CompareTable.types";
import { getNumberDifferencePositionFromThreshold } from "../compare/CompareTable/CompareTableUtils";
import {
  MAX_SUPPORTED_DEPTH,
  TABLE_COLUMN_DATA_TYPE_ENUM,
} from "../compare/Table.types";
import variables from "../shared/_exports.module.scss";
import { IPriceAverageRow } from "../shared/types/averagePrice.types";

import { getValueNumberDifference } from "./PriceAverageUtils";

/** returns the font color for a tender cell depending on its differencePosition*/
export function getTenderCellFontColor(
  cell: Cell<IPriceAverageRow, unknown>,
  table: Table<IPriceAverageRow>
) {
  const columnDataType = cell.column.columnDef.meta?.dataType;
  if (Object.values(TABLE_COLUMN_DATA_TYPE_ENUM).includes(columnDataType!)) {
    const numberDifferenceSettings =
      table.options.meta?.priceAverageSettings?.number_difference;
    const difference = getValueNumberDifference(
      cell.getContext(),
      numberDifferenceSettings
    );
    if (
      numberDifferenceSettings !== undefined &&
      difference !== undefined &&
      !isNaN(difference)
    ) {
      const differencePosition = getNumberDifferencePositionFromThreshold(
        difference,
        numberDifferenceSettings
      );
      if (differencePosition === DIFFERENCE_POSITION_ENUM.ABOVE) {
        return variables["danger"];
      } else if (differencePosition === DIFFERENCE_POSITION_ENUM.BELOW) {
        return variables["info"];
      }
    }
  }
  return undefined;
}

export function mapCell(cell: Cell<IPriceAverageRow, unknown>) {
  return cell.getValue<TCellComponentValue>()?.value ?? cell.getValue() ?? "";
}

export function getCellAlignment<T>(
  cell: Cell<T, unknown>,
  level?: number
): Partial<Alignment> | undefined {
  if (
    level !== undefined &&
    cell.column.columnDef.meta?.dataType ===
      TABLE_COLUMN_DATA_TYPE_ENUM.DESIGNATION
  ) {
    return {
      // indent is 0 based, level is 1
      indent: MAX_SUPPORTED_DEPTH - level - 1,
      horizontal: "left",
      readingOrder: "ltr",
    };
  }
}
