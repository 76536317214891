import classNames from "classnames";
import { ReactElement } from "react";
import Nav from "react-bootstrap/Nav";
import { Trans, useTranslation } from "react-i18next";

import { truncate } from "../utils";

// https://saturncloud.io/blog/what-is-the-maximum-length-of-a-url-in-different-browsers/#maximum-url-length-in-different-browsers
const MAX_URL_LENGTH = 2048;

export function ContactSupportOnError({
  error,
  i18nKey,
  className,
  values,
}: {
  error: any;
  i18nKey: string;
  className?: string;
  values?: any;
}) {
  const { t } = useTranslation();

  const errorDisplay = `${
    error.message ?? error.statusText ?? JSON.stringify(error)
  }${error.stack ? `\n\n${error.stack}` : ""}${
    error.parameters ? `\n\n${error.parameters}` : ""
  }`;
  const message = encodeURI(
    `${t("ErrorPage.message", {
      url: window.location,
      error: errorDisplay,
    })}`
  );
  return (
    <Trans
      t={t}
      i18nKey={i18nKey}
      components={{
        contactSupportLink: (
          <ContactSupportLink message={message} className={className} />
        ),
      }}
      values={values}
    />
  );
}

export function ContactSupportLink({
  children,
  message,
  className,
}: {
  children?: string | ReactElement | ReactElement[];
  message?: string;
  className?: string;
}) {
  return (
    <Nav className={classNames("d-inline-block", className)}>
      <Nav.Link
        className={"px-0"}
        href={truncate(
          `${window.REACT_APP_CONTACT_URL}${
            message ? `&message=${message}` : ""
          }`,
          MAX_URL_LENGTH
        )}
        target="_blank"
        disabled={!Boolean(window.REACT_APP_CONTACT_URL)}
      >
        {children}
      </Nav.Link>
    </Nav>
  );
}
