import React from "react";

import styles from "../_exports.module.scss";
import { ifTest } from "../utils";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function HalfStarIcon({
  fill = styles.white,
  className = "",
  size = 16,
}) {
  return (
    <svg
      data-test={ifTest("half-star-icon")}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize((size * 17) / 16)}
      viewBox="0 0 16 17"
      className={className}
    >
      <path
        fill={fill}
        d="m8 11.144 2.831 1.585-.633-3.182 2.382-2.204-3.222-.382L8 4.015v7.129Zm0 1.528-4.702 2.632 1.05-5.285L.39 6.36l5.351-.635L8 .832l2.257 4.893 5.351.635-3.956 3.659 1.05 5.285L8 12.672Z"
      />
    </svg>
  );
}
