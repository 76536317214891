import classNames from "classnames";
import React from "react";

import variables from "../_exports.module.scss";
import { ifTest } from "../utils";

import styles from "./Triangle.module.scss";
import { applyFontRatioToPxSize } from "./iconSizeUtil";

export enum TRIANGLE_DIRECTIONS_ENUM {
  UP = "up",
  DOWN = "down",
  RIGHT = "right",
  LEFT = "left",
}

export function TriangleIcon({
  className = "",
  fill = variables.black,
  size = 32,
  direction = TRIANGLE_DIRECTIONS_ENUM.UP,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.triangle, className, styles[direction])}
      data-test={ifTest("triangle")}
    >
      <g clipPath="url(#triangle-icon)">
        <path
          d="M16.707 21.126a1 1 0 0 1-1.414 0L9.707 15.54c-.63-.63-.184-1.707.707-1.707h11.172c.89 0 1.337 1.077.707 1.707l-5.586 5.586Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="triangle-icon">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
