export interface IRightsDTO {
  can_read: number;
  can_write: number;
  can_admin: number;
}

export interface IRights {
  can_read: boolean;
  can_write: boolean;
  can_admin: boolean;
}

export interface IAuthorizableObjectDTO {
  rights: IRightsDTO;
}

export interface IAuthorizableObject {
  rights: IRights;
}

export function rightsDTOToRightMapper(rights?: IRightsDTO) {
  const { can_admin, can_read, can_write } = rights ?? {};

  return {
    can_admin: Boolean(can_admin),
    can_read: Boolean(can_read),
    can_write: Boolean(can_write),
  };
}
