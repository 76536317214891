import { ReactElement, useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { UserContext, redirectToLogin } from "./UserContext";

export function UserGuard({ children }: { children?: ReactElement }) {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) redirectToLogin(navigate, location);
  }, [user, location, navigate]);

  return user ? (
    <>
      {children}
      <Outlet />
    </>
  ) : null;
}
