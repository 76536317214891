import { formatCurrency, formatNumber, formatPercentage } from "../i18n";
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from "../utils";

import { TREND_ENUM, TriangleTrend } from "./TriangleTrend";

export enum DIFFERENCE_DISPLAY_MODE_ENUM {
  ABSOLUTE = "ABSOLUTE",
  PERCENT = "PERCENT",
}

export enum DIFFERENCE_MODE_ENUM {
  PERCENT = "%",
  EURO = "€",
  PLAIN = "",
}
export interface IDifference {
  /** number to compare: comparee - comparator */
  comparee?: number;
  /** number used to compare against comparee: comparee - comparator */
  comparator?: number;
  mode: DIFFERENCE_MODE_ENUM;
  showTrend?: boolean;
  className?: string;
}

export function Difference({
  comparee,
  comparator,
  mode,
  showTrend,
  className,
}: IDifference) {
  const difference = getDifference(comparee, comparator, mode);
  const trend = showTrend && getTrend(comparee, comparator);
  return (
    <span className={className}>
      {trend && <TriangleTrend direction={trend} />}
      {difference}
    </span>
  );
}
function getTrend(
  comparee?: number,
  comparator?: number
): TREND_ENUM | undefined {
  if (comparee === undefined || comparator === undefined) return undefined;
  const difference = comparee - comparator;
  return difference > 0 ? TREND_ENUM.UP : TREND_ENUM.DOWN;
}
export function getDifference(
  /** number to compare: comparee - comparator */
  comparee: number | undefined,
  /** number used to compare against comparee: comparee - comparator */
  comparator: number | undefined,
  mode: DIFFERENCE_MODE_ENUM
): string {
  if (
    comparator === undefined ||
    comparee === undefined ||
    // can't divide by 0
    (mode === DIFFERENCE_MODE_ENUM.PERCENT && comparator === 0)
  ) {
    return DEFAULT_EMPTY_VALUE_PLACEHOLDER;
  }
  const difference = comparee - comparator;
  if (isNaN(difference)) return DEFAULT_EMPTY_VALUE_PLACEHOLDER;
  switch (mode) {
    case DIFFERENCE_MODE_ENUM.PERCENT:
      if (comparator === 0) return DEFAULT_EMPTY_VALUE_PLACEHOLDER;
      const ratio = difference / comparator;
      return formatPercentage(ratio);
    case DIFFERENCE_MODE_ENUM.EURO:
      return formatCurrency(difference);
    case DIFFERENCE_MODE_ENUM.PLAIN:
    default:
      return formatNumber(difference);
  }
}
