import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { UserContext } from "../account";
import { fetchCompanies, fetchCompany } from "../api/fetchCompanies";
import { fetchComparison, fetchComparisons } from "../api/fetchComparison";
import { fetchAttachments } from "../api/fetchDocuments";
import {
  fetchEstimate,
  fetchEstimateData,
  fetchEstimateRowTypeMapping,
  fetchEstimates,
} from "../api/fetchEstimates";
import {
  fetchLot,
  fetchLots,
  fetchLotsFiltered,
  fetchLotsMappedTendersFiltered,
  fetchLotsSelected,
} from "../api/fetchLots";
import {
  IFetchOperationsOptions,
  fetchOperation,
  fetchOperations,
} from "../api/fetchOperations";
import { fetchOrganizations } from "../api/fetchOrganizations";
import {
  IPriceAveragePanelFilters,
  fetchConstructionIndicatorMultipliers,
  fetchPriceAveragePanel,
  fetchPriceAveragePanels,
} from "../api/fetchPriceAverage";
import { fetchRowTypes } from "../api/fetchRowTypes";
import {
  fetchTender,
  fetchTenderData,
  fetchTenderRowTypeMapping,
  fetchTenders,
  fetchTendersPercentage,
} from "../api/fetchTenders";
import { fetchWorks } from "../api/fetchWorks";

import {
  IPriceAveragePanel,
  TConstructionIndicatorMultipliers,
} from "./types/averagePrice.types";
import { ICompany, ICompanyDTO } from "./types/company.types";
import { IComparisonDTO } from "./types/comparison.types";
import { IDocument } from "./types/document.types";
import { IPriceEstimate } from "./types/estimate.types";
import { ILot, ILotMappedTenders, ILotSelected } from "./types/lot.types";
import { IRowTypeMapping } from "./types/mapping.types";
import { IOperation } from "./types/operation.types";
import { IOrganization } from "./types/organization.types";
import { IPaginatedQueryResults } from "./types/paginatedQueryResult.types";
import { IPriceNode } from "./types/priceNode.types";
import { IRowType, ROWTYPE_USAGE_ENUM } from "./types/rowType.types";
import { ITender, ITenderPercentage } from "./types/tender.types";
import { IWork } from "./types/work.types";

export enum QUERY_KEYS_ENUM {
  LOT = "allotment",
  LOTS = "allotments",
  LOTS_SELECTED = "allotments-selected",
  LOTS_MAPPED_TENDERS = "allotments-mapped-tenders",
  COMPARISON = "comparison",
  COMPARISONS = "comparisons",
  TENDER = "tender",
  TENDER_MAPPED_PRICE_AVERAGES = "tender-mapped-price-averages",
  TENDERS = "tenders",
  TENDERS_MAPPED = "tenders-mapped",
  ESTIMATE = "estimate",
  ESTIMATES = "estimates",
  OPERATION = "operation",
  OPERATIONS = "operations",
  ESTIMATE_DATA = "estimate-data",
  TENDER_DATA = "tender-data",
  ESTIMATE_COMMENT = "estimate-comment",
  TENDER_COMMENT = "tender-comment",
  COMPANY = "company",
  COMPANIES = "companies",
  ATTACHMENTS = "attachments",
  ORGANIZATIONS = "organizations",
  WORKS = "works",
  ROW_TYPES = "row-types",
  ESTIMATE_ROW_TYPE_MAPPING = "estimate-row-type-mapping",
  TENDER_ROW_TYPE_MAPPING = "tender-row-type-mapping",
  PRICE_AVERAGE_PANELS = "price-average-panels",
  CONSTRUCTION_INDICATOR_MULTIPLIERS = "construction-index-multipliers",
}

export function useCurrentLot() {
  const { lotId } = useParams();
  const lotQuery = useQuery({
    queryKey: [QUERY_KEYS_ENUM.LOT, lotId],
    queryFn: () => fetchLot(lotId!),
    enabled: Boolean(lotId),
  });
  return lotQuery.data;
}

export function useLotsQuery<SelectType = ILot[]>(
  operationId?: string,
  options?: Omit<
    UseQueryOptions<ILot[], unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  >
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.LOTS, operationId!],
    queryFn: () => fetchLots({ operationId: operationId! }),
  });
}

export function useLotsFilteredQuery<SelectType = ILot[]>(
  filters?: any,
  options?: Omit<
    UseQueryOptions<ILot[], unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  >
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.LOTS, JSON.stringify(filters)],
    queryFn: () => fetchLotsFiltered(filters),
  });
}

export function useFilteredLotsWithMappedTendersQuery<
  SelectType = ILotMappedTenders[]
>(
  filters?: any,
  options?: Omit<
    UseQueryOptions<ILotMappedTenders[], unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  >
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.LOTS_MAPPED_TENDERS, JSON.stringify(filters)],
    queryFn: () => fetchLotsMappedTendersFiltered(filters),
  });
}

export function useTendersMappedQuery<
  SelectType = IPaginatedQueryResults<ITenderPercentage>
>(
  filters?: any,
  options?: Omit<
    UseQueryOptions<
      IPaginatedQueryResults<ITenderPercentage>,
      unknown,
      SelectType,
      string[]
    >,
    "queryKey" | "queryFn"
  >
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.TENDERS_MAPPED, JSON.stringify(filters)],
    queryFn: () => fetchTendersPercentage(filters),
  });
}

export function useLotsSelectedQuery<
  SelectType = IPaginatedQueryResults<ILotSelected>
>(
  filters?: any,
  options?: Omit<
    UseQueryOptions<
      IPaginatedQueryResults<ILotSelected>,
      unknown,
      SelectType,
      string[]
    >,
    "queryKey" | "queryFn"
  >
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.LOTS_SELECTED, JSON.stringify(filters)],
    queryFn: () => fetchLotsSelected(filters),
  });
}

export function useComparisonQuery<SelectType = IComparisonDTO>(
  comparisonId?: string | null,
  options: Omit<
    UseQueryOptions<IComparisonDTO, unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.COMPARISON, comparisonId!],
    queryFn: () => fetchComparison(comparisonId!),
    enabled: Boolean(comparisonId) && options.enabled !== false,
  });
}

export function useComparisonsQuery<
  SelectType = IPaginatedQueryResults<IComparisonDTO>
>(
  lotId?: string,
  options: Omit<
    UseQueryOptions<
      IPaginatedQueryResults<IComparisonDTO>,
      unknown,
      SelectType,
      string[]
    >,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.COMPARISONS, lotId!],
    queryFn: () => fetchComparisons(lotId!),
    enabled: Boolean(lotId) && options.enabled !== false,
  });
}

export function usePriceAveragePanelsQuery<SelectType = IPriceAveragePanel[]>(
  filters?: IPriceAveragePanelFilters,
  options: Omit<
    UseQueryOptions<
      IPriceAveragePanel[],
      unknown,
      SelectType,
      QUERY_KEYS_ENUM[]
    >,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS],
    queryFn: () => fetchPriceAveragePanels(filters),
  });
}

export function usePriceAveragePanelQuery<SelectType = IPriceAveragePanel>(
  priceAveragePanelId?: string,
  options: Omit<
    UseQueryOptions<IPriceAveragePanel, unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS, priceAveragePanelId!],
    queryFn: () => fetchPriceAveragePanel(priceAveragePanelId!),
    enabled: Boolean(priceAveragePanelId) && options.enabled !== false,
  });
}

export function useConstructionIndicatorMultipliersQuery<
  SelectType = TConstructionIndicatorMultipliers
>(
  options: Omit<
    UseQueryOptions<
      TConstructionIndicatorMultipliers,
      unknown,
      SelectType,
      QUERY_KEYS_ENUM[]
    >,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.CONSTRUCTION_INDICATOR_MULTIPLIERS],
    queryFn: () => fetchConstructionIndicatorMultipliers(),
  });
}

export function useTendersQuery<SelectType = ICompany[]>(
  lotId?: string,
  options: Omit<
    UseQueryOptions<ICompany[], unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.TENDERS, lotId!],
    queryFn: () => fetchTenders(lotId!),
    enabled: Boolean(lotId) && options.enabled !== false,
  });
}

export function useEstimateQuery<SelectType = IPriceEstimate>(
  estimateId?: string | null,
  options: Omit<
    UseQueryOptions<IPriceEstimate, unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.ESTIMATE, estimateId!],
    queryFn: () => fetchEstimate(estimateId!),
    enabled: Boolean(estimateId) && options.enabled !== false,
  });
}

export function useEstimatesQuery<
  SelectType = IPaginatedQueryResults<IPriceEstimate>
>(
  lotId?: string,
  options: Omit<
    UseQueryOptions<
      IPaginatedQueryResults<IPriceEstimate>,
      unknown,
      SelectType,
      string[]
    >,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.ESTIMATES, lotId!],
    queryFn: () => fetchEstimates(lotId!),
    enabled: lotId !== undefined && options.enabled !== false,
  });
}

export function useOperationQuery<SelectType = IOperation>(
  operationId?: string,
  options: Omit<
    UseQueryOptions<IOperation, unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.OPERATION, operationId!],
    queryFn: () => fetchOperation(operationId!),
    enabled: Boolean(operationId) && options.enabled !== false,
  });
}

export function useOperationsQuery<
  SelectType = IPaginatedQueryResults<IOperation>
>(
  fetchOperationsOptions: IFetchOperationsOptions,
  key: string,
  options: Omit<
    UseQueryOptions<
      IPaginatedQueryResults<IOperation>,
      unknown,
      SelectType,
      string[]
    >,
    "queryKey" | "queryFn" | "keepPreviousData"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.OPERATIONS, key],
    queryFn: () => fetchOperations(fetchOperationsOptions),
    keepPreviousData: true,
  });
}

export function useEstimateDataQuery<SelectType = IPriceNode>(
  estimateId?: string,
  options: Omit<
    UseQueryOptions<IPriceNode, unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.ESTIMATE_DATA, estimateId!],
    queryFn: () => fetchEstimateData(estimateId!),
    enabled: Boolean(estimateId) && options.enabled !== false,
  });
}

export function useCompanyQuery<SelectType = ICompanyDTO>(
  companyId?: string,
  options: Omit<
    UseQueryOptions<ICompanyDTO, unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.COMPANY, companyId!],
    queryFn: () => fetchCompany({ id: companyId! }),
    enabled: Boolean(companyId) && options.enabled !== false,
  });
}

export function useCompaniesQuery<SelectType = ICompanyDTO[]>(
  options: Omit<
    UseQueryOptions<ICompanyDTO[], unknown, SelectType, QUERY_KEYS_ENUM[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.COMPANIES],
    queryFn: () => fetchCompanies(),
  });
}

export function useAttachmentsQuery<SelectType = IDocument[]>(
  lotId?: string,
  options: Omit<
    UseQueryOptions<IDocument[], unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.ATTACHMENTS, lotId!],
    queryFn: () => fetchAttachments({ lotId: lotId! }),
    enabled: Boolean(lotId) && options.enabled !== false,
  });
}

export function useDocumentQuery<SelectType = ITender | IPriceEstimate>(
  documentId?: string,
  isTender?: boolean,
  options: Omit<
    UseQueryOptions<ITender | IPriceEstimate, unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [
      isTender ? QUERY_KEYS_ENUM.TENDER : QUERY_KEYS_ENUM.ESTIMATE,
      documentId!,
    ],
    queryFn: () =>
      isTender ? fetchTender(documentId!) : fetchEstimate(documentId!),
    enabled: Boolean(documentId) && options.enabled !== false,
  });
}

export function useDocumentDataQuery<SelectType = IPriceNode>(
  documentId?: string,
  isTender?: boolean,
  options: Omit<
    UseQueryOptions<IPriceNode, unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [
      isTender ? QUERY_KEYS_ENUM.TENDER_DATA : QUERY_KEYS_ENUM.ESTIMATE_DATA,
      documentId!,
    ],
    queryFn: () =>
      isTender ? fetchTenderData(documentId!) : fetchEstimateData(documentId!),
    enabled: Boolean(documentId) && options.enabled !== false,
  });
}

export function useOrganizationsQuery<SelectType = IOrganization[]>(
  options: Omit<
    UseQueryOptions<IOrganization[], unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  const { user } = useContext(UserContext);
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.ORGANIZATIONS, user?.id!],
    queryFn: () => fetchOrganizations(),
    enabled: Boolean(user?.id) && options.enabled !== false,
  });
}

export function useRowTypeOrganizationQuery() {
  return useOrganizationsQuery({
    select: (organizations) =>
      organizations?.find(
        ({ name }) => name === "RowTypePermissionsVirtualOrganization"
      )?.id,
  }).data;
}

export function useWorksQuery<SelectType = IWork[]>(
  operationId?: string,
  options: Omit<
    UseQueryOptions<IWork[], unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.WORKS, operationId!],
    queryFn: () => fetchWorks({ operationId: operationId! }),
    enabled: Boolean(operationId) && options.enabled !== false,
  });
}

export function useRowTypesQuery<SelectType = IRowType[]>(
  allotment_type?: string,
  required?: boolean,
  use?: ROWTYPE_USAGE_ENUM,
  admin?: boolean,
  options: Omit<
    UseQueryOptions<
      IRowType[],
      unknown,
      SelectType,
      [QUERY_KEYS_ENUM, string?, ROWTYPE_USAGE_ENUM?, boolean?]
    >,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS_ENUM.ROW_TYPES, allotment_type, use, admin],
    queryFn: () => fetchRowTypes(allotment_type, use, admin),
    enabled: !required || Boolean(allotment_type),
  });
}

export function useDocumentRowTypeMappingDataQuery<
  SelectType = IRowTypeMapping
>(
  documentId?: string,
  isTender?: boolean,
  options: Omit<
    UseQueryOptions<IRowTypeMapping, unknown, SelectType, string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [
      isTender
        ? QUERY_KEYS_ENUM.TENDER_ROW_TYPE_MAPPING
        : QUERY_KEYS_ENUM.ESTIMATE_ROW_TYPE_MAPPING,
      documentId!,
    ],
    queryFn: () =>
      isTender
        ? fetchTenderRowTypeMapping(documentId!)
        : fetchEstimateRowTypeMapping(documentId!),
    enabled: Boolean(documentId) && options.enabled !== false,
  });
}
