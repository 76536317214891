import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { Cell, Header, Row, Table } from "@tanstack/react-table";
import { Alignment, CellValue, Fill, Workbook } from "exceljs";
import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { UserContext, getUserNameOrEmail } from "../../account/UserContext";
import { VARIANT_AND_OPTIONS_SPACER_ID } from "../../compare/CompareTable/useCompareTable";
import {
  ITableRow,
  MAX_SUPPORTED_DEPTH,
  TABLE_COLUMN_DATA_TYPE_ENUM,
} from "../../compare/Table.types";
import { CUSTOM_ACTION_DIMENSIONS_ENUM } from "../../matomo";
import colors from "../_exports.module.scss";
import variables from "../_exports.module.scss";
import { formatTolerant } from "../i18n/dateFormatter";
import { ExcelIcon } from "../icons";
import { colorHEXToARGB, downloadFileFromBlob, ifTest } from "../utils";

const cellFont = {
  name: "Montserrat",
  family: 2,
  size: 12,
  color: { argb: colorHEXToARGB(colors.black) },
};

const headerCellFont = {
  ...cellFont,
  size: 14,
  color: { argb: colorHEXToARGB(colors.white) },
  bold: true,
};

const headerCellFill: Fill = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: colorHEXToARGB(colors.primary) },
};

export const DEFAULT_COL_WIDTH = 18;
export const QUANTITY_COL_WIDTH = 8;
export const DIFFERENCE_COL_WIDTH = 14;
export const UNIT_COL_WIDTH = QUANTITY_COL_WIDTH;
export const DESIGNATION_COL_WIDTH = 116;
export const DESCRIPTION_COL_WIDTH = 90;
export const UNIT_PRICE_COL_WIDTH = 14;
const DEFAULT_CELL_ALIGNMENT: Partial<Alignment> = {
  vertical: "middle",
};
export const DEFAULT_ROW_HEIGHT = 21;

function filterOutSkippedColumn(
  headerOrCell: Header<any, any> | Cell<any, any>
) {
  return headerOrCell.column.columnDef.meta?.excel?.skip !== true;
}

export function ExportTableToExcel<T extends ITableRow<T>>({
  table,
  isLoading,
  fileName,
  sheetName,
  getFontColor,
  getCellColor,
  getCellAlignment,
  mapCell,
  fontSize = 12,
  headerFontSize = 14,
  rowHeight = DEFAULT_ROW_HEIGHT,
}: {
  table: Table<T>;
  isLoading: boolean;
  fileName: string;
  sheetName: string;
  getFontColor?: (
    cell: Cell<T, unknown>,
    table: Table<T>
  ) => string | undefined;
  getCellColor?: (cell: Cell<T, unknown>, row: Row<T>) => string | undefined;
  getCellAlignment?: <T>(
    cell: Cell<T, unknown>,
    level?: number,
    maxLevel?: number
  ) => Partial<Alignment> | undefined;
  mapCell: (
    cell: Cell<T, unknown>,
    cellIndex: number,
    rowIndex: number
  ) => CellValue;
  fontSize?: number;
  headerFontSize?: number;
  rowHeight?: number;
}) {
  const { user } = useContext(UserContext);
  const { trackEvent } = useMatomo();

  const [isDownloading, setIsDownloading] = useState(false);
  const onExport = async () => {
    try {
      setIsDownloading(true);
      const workbook = new Workbook();
      const userName = getUserNameOrEmail(user);
      workbook.creator = userName;
      workbook.lastModifiedBy = userName;
      workbook.created = new Date();
      const headerGroup = table.getHeaderGroups();
      const [firstHeaderGroup, secondHeaderGroup] = headerGroup;
      const sheet = workbook.addWorksheet(sheetName, {
        properties: {
          defaultRowHeight: rowHeight,
          defaultColWidth: DEFAULT_COL_WIDTH,
        },
      });

      // define columns
      sheet.columns = (secondHeaderGroup ?? firstHeaderGroup).headers
        .filter(filterOutSkippedColumn)
        .map((header) => ({
          header:
            header.column.columnDef.meta?.excel?.header ??
            (header.column.columnDef.header as string),
          key: header.id,
          style: {
            font: { ...cellFont, size: fontSize },
            ...(header.column.columnDef.meta?.excel?.style ?? {}),
          },
          width:
            header.column.columnDef.meta?.excel?.width ?? DEFAULT_COL_WIDTH,
        }));

      // create first merged header group row
      let currentGroupColIndex = 1;
      const headerGroupRowIndex = 1;
      sheet.getRow(headerGroupRowIndex).height = rowHeight;
      const lastColumnFromHeaderGroupIndexes: number[] = [];
      firstHeaderGroup.headers
        .filter(filterOutSkippedColumn)
        .forEach((header) => {
          const headerColSpan =
            header.subHeaders.filter(filterOutSkippedColumn).length || 1;
          const lastColumnFromHeaderGroupIndex =
            currentGroupColIndex + headerColSpan - 1;
          if (secondHeaderGroup) {
            sheet.mergeCells(
              headerGroupRowIndex,
              currentGroupColIndex,
              headerGroupRowIndex,
              lastColumnFromHeaderGroupIndex
            );
            lastColumnFromHeaderGroupIndexes.push(
              lastColumnFromHeaderGroupIndex
            );
          }
          const headerCell = sheet.getCell(
            headerGroupRowIndex,
            currentGroupColIndex
          );
          headerCell.value =
            header.column.columnDef.meta?.excel?.header ??
            (header.column.columnDef.header as string);
          if (headerCell.value) {
            headerCell.style = {
              fill: headerCellFill,
              font: { ...headerCellFont, size: headerFontSize },
            };
          }
          currentGroupColIndex += headerColSpan;
        });

      // create second header row
      if (secondHeaderGroup) {
        const subHeaderRowIndex = headerGroupRowIndex + 1;
        sheet.getRow(subHeaderRowIndex).height = rowHeight;
        secondHeaderGroup.headers
          .filter(filterOutSkippedColumn)
          .forEach((header, index) => {
            const headerCell = sheet.getCell(subHeaderRowIndex, index + 1);
            headerCell.value =
              header.column.columnDef.meta?.excel?.header ??
              (header.column.columnDef.header as string);
            headerCell.style = {
              fill: headerCellFill,
              font: { ...headerCellFont, size: headerFontSize },
              alignment: { ...DEFAULT_CELL_ALIGNMENT },
            };
          });
      }

      // Add rows
      table.getRowModel().rows.forEach((row) => {
        const argbBorderColor = colorHEXToARGB(variables.gray400);
        const tableCells = row.getVisibleCells().filter(filterOutSkippedColumn);
        const newExcelRowNumber = (sheet.lastRow?.number ?? 0) + 1;
        const excelRow = sheet.addRow(
          tableCells.map((cell, cellIndex) =>
            mapCell(cell, cellIndex, newExcelRowNumber)
          )
        );
        const level = row.original.meta?.level;
        let rowColor: string;
        if (level !== undefined) {
          rowColor = colorHEXToARGB(
            variables[`level-${Math.min(level, MAX_SUPPORTED_DEPTH)}`]
          );
        }
        excelRow.height = rowHeight;
        excelRow.eachCell((cell, index) => {
          const tableCell = tableCells[index - 1];
          let cellColor: string | undefined = getCellColor?.(tableCell, row);
          if (cellColor) {
            cellColor = colorHEXToARGB(cellColor);
          }
          cell.style = {
            font: { ...cellFont, size: fontSize },
          };
          cell.style.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: cellColor ?? rowColor },
          };

          const fontColor = getFontColor?.(tableCell, table);

          // make a copy of the cell style to not change the style for all cells
          if (fontColor || level !== undefined) {
            cell.font = { ...cell.font };
          }

          if (fontColor) {
            cell.font.color = { argb: colorHEXToARGB(fontColor) };
          }
          // top level rows have bottom border
          if (level === table.options.meta?.maxDepth) {
            cell.style.border = {
              top: { style: "medium", color: { argb: argbBorderColor } },
            };
            // and a bold designation
            if (
              tableCell.column.columnDef.meta?.dataType ===
              TABLE_COLUMN_DATA_TYPE_ENUM.DESIGNATION
            ) {
              cell.font.bold = true;
            }
          }
          cell.style.alignment = {
            ...DEFAULT_CELL_ALIGNMENT,
            ...(getCellAlignment?.(
              tableCell,
              level,
              table.options.meta?.maxDepth
            ) ?? {}),
          };
        });
        if ((row.original as any).id !== VARIANT_AND_OPTIONS_SPACER_ID) {
          lastColumnFromHeaderGroupIndexes.forEach((index) => {
            const cell = excelRow.getCell(index);
            cell.style.border = {
              ...cell.style.border,
              right: { style: "medium", color: { argb: argbBorderColor } },
            };
          });
        }
      });

      // export to file
      const blob = new Blob(
        [await workbook.xlsx.writeBuffer({ useStyles: true })],
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }
      );
      downloadFileFromBlob(
        blob,
        `${formatTolerant(new Date(), "yyMMddHHmm")}_${fileName}.xlsx`
      );
      trackEvent({
        category: "table",
        action: "download",
        name: "DownloadTable",
        customDimensions: [
          {
            id: CUSTOM_ACTION_DIMENSIONS_ENUM.DATA,
            value: window.location.href,
          },
        ],
      });
    } finally {
      setIsDownloading(false);
    }
  };
  return (
    <Button
      size="lg"
      className="rectangular p-3"
      variant="excel"
      disabled={isLoading}
      onClick={onExport}
      data-test={ifTest("exportExcelButton")}
    >
      {isDownloading ? (
        <Spinner variant="light" />
      ) : (
        <ExcelIcon fill={colors.white} />
      )}
    </Button>
  );
}
