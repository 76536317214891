import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { TTranslateFunction } from "./i18n";

/**
 * Component to ask if data should be overwritten or saved as a new object
 */
export function OverwriteOrSaveAsNew({
  t,
  isLoading,
  setShouldAskToSaveOnOrNew,
}: {
  t: TTranslateFunction;
  isLoading: boolean;
  setShouldAskToSaveOnOrNew: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      <Row className="mb-4 text-center">
        <Col>{t("should save on or as")}</Col>
      </Row>
      <Row className="my-5">
        <Col className="mx-3 text-end">
          <Button
            size="lg"
            variant="primary"
            type="submit"
            className="text-center rectangular"
            disabled={isLoading}
          >
            {t("overwrite")}
            {isLoading && <Spinner size="sm" className="ms-2" />}
          </Button>
        </Col>
        <Col className="mx-3 text-start">
          <Button
            size="lg"
            variant="dark"
            className="text-center rectangular"
            onClick={() => setShouldAskToSaveOnOrNew(false)}
          >
            {t("save as")}
          </Button>
        </Col>
      </Row>
    </>
  );
}
