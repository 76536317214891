import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Control } from "react-hook-form";
import * as yup from "yup";

import { useTranslation } from "../../../shared/i18n";
import {
  ControlledInput,
  FloorSelect,
  ProtectionSelect,
} from "../../../shared/inputs";
import { FacadeSelect } from "../../../shared/inputs/FacadeSelect";
import { FrameSelect } from "../../../shared/inputs/FrameSelect";
import { getUndefinedSafeSelectOnChange } from "../../../shared/inputs/input";
import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  LOT_CLOSURE_FACADE_TYPE_ENUM,
  LOT_CLOSURE_FLOOR_ENUM,
  LOT_CLOSURE_FRAME_ENUM,
  LOT_CLOSURE_PROTECTION_ENUM,
} from "../../../shared/types/lot.types";

import { yupRequiredWhenConventionalAllotmentIncludes } from "./utils";

function yupRequiredWhen<
  S,
  ModifierSchema extends yup.Schema | yup.ArraySchema<any, any>
>(
  schema: yup.Schema<S>,
  includesModifier?: (field: ModifierSchema) => ModifierSchema
) {
  return yupRequiredWhenConventionalAllotmentIncludes(
    schema,
    CONVENTIONAL_ALLOTMENTS_ENUM.STRUCTURAL_WORK,
    includesModifier
  );
}

export const schema = yup.object({
  interior_frame: yupRequiredWhen(
    yup.array(
      yup.string().required().oneOf(Object.values(LOT_CLOSURE_FRAME_ENUM))
    ),
    (field: yup.ArraySchema<any, any>) => field.min(1)
  ),
  peripheral_frame: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_FRAME_ENUM))
  ),
  intermediary_floor: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_FLOOR_ENUM))
  ),
  structure_protection: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_PROTECTION_ENUM))
  ),
  facade_type: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_FACADE_TYPE_ENUM))
  ),
});

export type TFormInputs = yup.InferType<typeof schema>;

export function StructuralWorkAllotment({
  control,
}: {
  control: Control<TFormInputs>;
}) {
  const { t } = useTranslation("LotClosureModal");

  return (
    <>
      <h6 className="my-4">{t("structural work title")}</h6>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("interior frame")}
          name="interior_frame"
          schema={schema}
          control={control}
          render={({ field }) => (
            <FrameSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
              multi
            />
          )}
        />
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("peripheral frame")}
          name="peripheral_frame"
          schema={schema}
          control={control}
          render={({ field }) => <FrameSelect {...field} />}
        />
      </Row>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("intermediary floor")}
          name="intermediary_floor"
          schema={schema}
          control={control}
          render={({ field }) => <FloorSelect {...field} />}
        />
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("structure protection")}
          name="structure_protection"
          schema={schema}
          control={control}
          render={({ field }) => <ProtectionSelect {...field} />}
        />
      </Row>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("facade type")}
          name="facade_type"
          schema={schema}
          control={control}
          render={({ field }) => <FacadeSelect {...field} />}
        />
      </Row>
    </>
  );
}
