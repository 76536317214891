import { useState } from "react";
import {
  FieldError,
  FieldValues,
  Path,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";

import { useHasChanged } from "../useHasChanged";

export interface IBackErrors<T> {
  type: string;
  errors: [
    { code: string; detail: string; attr: Path<T> | "non_field_errors" }
  ];
}

export const BACK_ERROR_PREFIX = "BackError";

/**
 * Inject errors from Django API into a react-hook-form useForm instance
 * @param backErrors an "errors" object returned by Django API
 * @param setError react-hook-form useForm setError method
 * @param clearErrors react-hook-form useForm clearErrors method
 */
export function useBackFieldErrors<T extends FieldValues>(
  backErrors: IBackErrors<T> | undefined,
  setError: UseFormSetError<T>,
  clearErrors: UseFormClearErrors<T>
) {
  const [previousBackErrors, setPreviousBackErrors] = useState<
    ("root" | Path<T>)[]
  >([]);
  const backErrorsHasChanged = useHasChanged(backErrors);
  if (backErrorsHasChanged) {
    clearErrors(previousBackErrors as Path<T>[]);
    const newErrorFieldNames = [
      ...new Set(
        backErrors?.errors?.map((error) => {
          const message: string = `${BACK_ERROR_PREFIX}.${error.detail}`;
          const fieldError: FieldError = {
            message,
            type: "typeError",
          };
          if (error.attr === "non_field_errors") {
            setError("root", fieldError);
            return "root";
          } else {
            setError(error.attr, fieldError);
            return error.attr;
          }
        })
      ),
    ];
    setPreviousBackErrors(newErrorFieldNames);
  }
}
