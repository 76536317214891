import React, { useMemo } from "react";
import { Noop } from "react-hook-form";

import { useEnumTranslation } from "../i18n";
import { WORK_TYPE_ENUM } from "../types/operation.types";

import { Select, TValue } from "./select";

const workTypeList = Object.values(WORK_TYPE_ENUM);
export type TWorkTypeSelectProps =
  | IWorkTypeSelectMultiProps
  | IWorkTypeSelectSingleProps;

export interface IWorkTypeSelectBaseProps {
  onBlur?: Noop;
  clearable?: boolean;
  hideValueOnSearch?: boolean;
}

export interface IWorkTypeSelectMultiProps extends IWorkTypeSelectBaseProps {
  onChange?(value?: TValue[]): void;
  value?: WORK_TYPE_ENUM[];
  multi: true;
}

export interface IWorkTypeSelectSingleProps extends IWorkTypeSelectBaseProps {
  onChange?(value?: TValue): void;
  value?: WORK_TYPE_ENUM;
  multi?: false;
}

export const WorkTypeSelect = React.forwardRef<
  HTMLInputElement,
  TWorkTypeSelectProps
>(function WorkTypeSelect(
  {
    onChange,
    onBlur,
    value,
    clearable,
    hideValueOnSearch = false,
    multi = false,
  },
  ref
) {
  const { tEnum } = useEnumTranslation();
  const workTypeOptions = useMemo(
    () => workTypeList.map((value) => ({ value, label: tEnum(value) })),
    [tEnum]
  );
  return (
    <Select
      options={workTypeOptions}
      onChange={onChange as any}
      onBlur={onBlur}
      value={value as any}
      ref={ref}
      clearable={clearable}
      searchable
      hideValueOnSearch={hideValueOnSearch}
      multi={multi}
    />
  );
});
