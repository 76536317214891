import classNames from "classnames";
import React, { ReactElement } from "react";
import Card from "react-bootstrap/Card";

export function ChartsCard({
  className = "",
  children,
}: {
  className?: string;
  children: ReactElement | ReactElement[];
}) {
  return (
    <Card className="mx-1 w-auto border-0 shadow-sm p-3">
      <Card.Body className={classNames("w-100", className)}>
        {children}
      </Card.Body>
    </Card>
  );
}
