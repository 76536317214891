import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { useRouteError } from "react-router";
import { useNavigate } from "react-router-dom";

import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { Brand } from "../shared/Brand";
import variables from "../shared/_exports.module.scss";
import { ContactSupportOnError } from "../shared/errors/ContactSupport";
import { useTranslation } from "../shared/i18n";
import { useTrackPageView } from "../shared/useTrackPageView";

export const ErrorPage = () => {
  const error: any = useRouteError();

  useTrackPageView(
    "Error",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.ERROR,
        value: error,
      },
    ],
    [error]
  );

  const { t } = useTranslation("ErrorPage");
  const navigate = useNavigate();
  console.error(error);
  const errorDisplay = `${
    error.message ?? error.statusText ?? JSON.stringify(error)
  }\n\n${error.stack}`;
  return (
    <Container fluid className="bg-dark min-h-100">
      <Brand logoColor="white" nameColor={variables.info} className="pt-3" />
      <Card className="text-center card-md mx-auto mt-5">
        <Card.Header as="h1" className="px-5 bg-white border-bottom-0">
          {t("error")}
        </Card.Header>
        <Card.Body>
          <p>{t(error.statusText ?? "unknown")}</p>
          <details>
            <summary>{t("display")}</summary>
            <code>
              <pre>{errorDisplay}</pre>
            </code>
          </details>
        </Card.Body>
        <Card.Footer className="p-4 bg-white border-top-0">
          <Button onClick={() => navigate(-1)}>{t("back")}</Button>
          <ContactSupportOnError
            error={error}
            i18nKey="ErrorPage.contact"
            className="d-block text-start"
          />
        </Card.Footer>
      </Card>
    </Container>
  );
};
