import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

import { uploadFile } from "../api/fetchDocuments";
import {
  createPriceEstimate,
  createPriceEstimateData,
  updatePriceEstimate,
} from "../api/fetchEstimates";
import {
  createAllotmentAttachment,
  createLot,
  deleteAllotment,
  updateAllotment,
} from "../api/fetchLots";
import { mapLogs } from "../compare/PriceNodeEditModal/PriceNodeEditTable";
import { IBackErrors } from "../shared/errors";
import { useTranslation } from "../shared/i18n";
import { CircleQuestionIcon } from "../shared/icons";
import { ACCEPT_EXCEL_FILE_FORMAT, ImportFilesArea } from "../shared/inputs";
import { ConfirmationModal } from "../shared/modals";
import {
  IDocument,
  TDocumentDataWithTotal,
} from "../shared/types/document.types";
import { FILE_TYPE_ENUM, IPriceEstimate } from "../shared/types/estimate.types";
import { ILogs } from "../shared/types/log.types";
import { ILot, TCell } from "../shared/types/lot.types";
import {
  IStep,
  MODAL_SIZE_ENUM,
  TLotsMappings,
  getStepIndex,
  isStepCompleted,
  mappingSteps,
} from "../shared/types/lots.types";
import { mapStepMappingsToSingleMapping } from "../shared/types/mapping.types";
import { QUERY_KEYS_ENUM, useWorksQuery } from "../shared/useSharedQueries";
import { ifTest } from "../shared/utils";

import {
  ExcelImportStepMapper,
  getLastValidStepIndex,
} from "./ExcelImportStepMapper";
import { ILotsForm, LotsForm, TLotsFormInputs, isFormValid } from "./LotsForm";
import { LotsModalContent } from "./LotsModalContent";
import { SelectTab } from "./SelectTab";

export interface ILotMappingModalParameters {
  show: boolean;
  handleClose: (
    save: { lotId: string; estimate: IPriceEstimate } | void
  ) => void;
  operationId: string;
  lotId?: string;
  priceEstimateId?: string;
  estimation?: number;
  isFrame?: boolean;
  defaultFiles?: File[];
  stepsToSkip?: number[];
  isEditing?: boolean;
  forceShowOnlyFrameToggle?: boolean;
}

export function LotMappingModal({
  show,
  handleClose,
  operationId,
  lotId: initialLotId,
  priceEstimateId,
  estimation,
  isFrame,
  defaultFiles,
  stepsToSkip,
  isEditing,
  forceShowOnlyFrameToggle,
}: ILotMappingModalParameters) {
  const { t } = useTranslation("LotMappingModal");
  const [lotId, setLotId] = useState(initialLotId);
  const [files, setFiles] = useState<File[] | undefined>(defaultFiles);
  const [documents, setDocuments] = useState<File[]>();
  const [tab, setTab] = useState<string>();
  const [sheets, setSheets] = useState<{
    sheets: { [key: string]: () => TCell[][] };
    rawSheets: { [key: string]: () => any };
  }>({ sheets: {}, rawSheets: {} });
  const [mappings, setMappings] = useState<TLotsMappings>([[]]);
  const [updatedPriceEstimateData, setUpdatedPriceEstimateData] =
    useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOnlyFrame, setIsOnlyFrame] = useState(isFrame);
  const [lotsForm, setLotsForm] = useState<ILotsForm>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationOverrideModal, setShowConfirmationOverrideModal] =
    useState(Boolean(priceEstimateId));
  const [percentage, setPercentage] = useState<number | undefined>(undefined);

  const queryClient = useQueryClient();

  const [priceEstimate, setPriceEstimate] = useState<IPriceEstimate>({
    id: priceEstimateId!,
  } as IPriceEstimate);

  const worksQuery = useWorksQuery(operationId);
  const [work] = worksQuery.data ?? [];

  const priceEstimateDataCreation = useMutation({
    mutationFn: createPriceEstimateData,
  });
  const priceEstimateCreation = useMutation({
    mutationFn: createPriceEstimate,
  });
  const priceEstimateEdition = useMutation({
    mutationFn: updatePriceEstimate,
  });
  const priceEstimateFileUpload = useMutation({
    mutationFn: (priceEstimate: IPriceEstimate) =>
      priceEstimate !== undefined && files?.length
        ? uploadFile(priceEstimate, files![0], setPercentage)
        : Promise.reject("no estimate or file"),
  });

  const allotmentAttachmentCreation = useMutation({
    mutationFn: ({
      document,
      file,
    }: {
      document: Partial<IDocument>;
      file: File;
    }) => createAllotmentAttachment(document, file, setPercentage),
    onSuccess: () => {
      setPercentage(undefined);
    },
    onError: () => {
      setPercentage(undefined);
    },
  });

  const lotCreation = useMutation({
    mutationFn: createLot,
    onSuccess: async (lot: ILot) => {
      setLotId(lot.id);
    },
  });

  const lotEdition = useMutation({
    mutationFn: updateAllotment,
  });

  const lotDeletion = useMutation({
    mutationFn: deleteAllotment,
  });

  const lotSubmit = useCallback(async () => {
    if (!lotId) {
      await lotCreation.mutateAsync({ ...lotsForm!, operationId });
    } else {
      await lotEdition.mutateAsync({ ...lotsForm!, id: lotId });
    }
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS_ENUM.LOTS, operationId],
    });
  }, [lotCreation, lotEdition, lotsForm, lotId, operationId, queryClient]);

  const [mappingLogs, setMappingLogs] = useState<ILogs>();

  const [submitError, setSubmitError] = useState<any>();

  const isSubmitting =
    priceEstimateFileUpload.isLoading ||
    priceEstimateDataCreation.isLoading ||
    allotmentAttachmentCreation.isLoading ||
    priceEstimateCreation.isLoading ||
    priceEstimateEdition.isLoading ||
    allotmentAttachmentCreation.isLoading ||
    lotCreation.isLoading ||
    lotEdition.isLoading ||
    lotDeletion.isLoading;

  const submitStep = useCallback(async () => {
    const edition = priceEstimate?.id !== undefined;
    // set raw_data only once on edition
    if (edition && !updatedPriceEstimateData && priceEstimate.id) {
      await priceEstimateDataCreation.mutateAsync({
        id: priceEstimate.id,
        raw_data: sheets.rawSheets[tab!](),
      });
      setUpdatedPriceEstimateData(true);
    }
    const mutation = edition ? priceEstimateEdition : priceEstimateCreation;

    const mapping = mapStepMappingsToSingleMapping(mappings, tab!, work?.id);
    // abort if the start line isn't valid
    if (!(mapping.ranges[0].start >= 0)) {
      return;
    }
    const estimate = await mutation.mutateAsync({
      id: priceEstimate.id,
      upload_date: new Date(),
      file_name: files?.[0].name,
      mapping,
      allotment: lotId,
      file_type: isOnlyFrame ? FILE_TYPE_ENUM.FRAME : FILE_TYPE_ENUM.ESTIMATE,
      setPercentage,
    });
    setPriceEstimate(estimate);
    setMappingLogs(mapLogs(estimate as TDocumentDataWithTotal)[1]);
    // set raw_data only once on creation
    if (!edition && !updatedPriceEstimateData) {
      await priceEstimateDataCreation.mutateAsync({
        id: estimate.id,
        raw_data: sheets.rawSheets[tab!](),
      });
      setUpdatedPriceEstimateData(true);
    }
  }, [
    updatedPriceEstimateData,
    priceEstimateEdition,
    priceEstimateCreation,
    priceEstimate,
    files,
    mappings,
    tab,
    work?.id,
    lotId,
    isOnlyFrame,
    priceEstimateDataCreation,
    sheets.rawSheets,
  ]);

  const submit = useCallback(async () => {
    const updatedPriceEstimate = await priceEstimateEdition.mutateAsync({
      id: priceEstimate.id,
      mapping: mapStepMappingsToSingleMapping(mappings, tab!, work?.id, true),
    });
    setMappingLogs(mapLogs(updatedPriceEstimate as TDocumentDataWithTotal)[1]);
    console.info(
      `Import logs for estimate ${priceEstimate}: `,
      updatedPriceEstimate.import_logs
    );
    const documentsToUpload: Promise<any>[] = [];
    if (files?.length) {
      documentsToUpload.push(
        priceEstimateFileUpload.mutateAsync(priceEstimate)
      );
    }
    if (documents !== undefined) {
      documentsToUpload.push(
        ...documents.map((doc) =>
          allotmentAttachmentCreation.mutateAsync({
            document: {
              allotment: lotId,
              upload_date: new Date(),
            },
            file: doc,
          })
        )
      );
    }
    await Promise.all(documentsToUpload).then(
      () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS_ENUM.LOTS, operationId],
        });
        handleClose({ lotId: lotId!, estimate: updatedPriceEstimate });
      },
      (error) => {
        console.error(error);
        setSubmitError(error);
      }
    );
  }, [
    allotmentAttachmentCreation,
    documents,
    files?.length,
    handleClose,
    lotId,
    mappings,
    operationId,
    priceEstimate,
    priceEstimateEdition,
    priceEstimateFileUpload,
    queryClient,
    tab,
    work?.id,
  ]);

  const handleCancel = useCallback(async () => {
    if (lotId && !isEditing) {
      await lotDeletion.mutateAsync(lotId);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.LOTS, operationId],
      });
    }
    handleClose();
  }, [lotId, isEditing, handleClose, lotDeletion, queryClient, operationId]);

  // sheetMappingStartIndex impacted by the steps to skip
  const sheetMappingStartIndex =
    4 - (stepsToSkip?.reduce((p, c) => (c < 4 ? p + 1 : p), 0) ?? 0);

  const checkIfDirty = useCallback(() => {
    return files && files.length > 0;
  }, [files]);

  const currentMappingStep =
    mappingSteps[getStepIndex(selectedIndex, sheetMappingStartIndex)];

  const steps: IStep[] = useMemo(() => {
    const steps = [
      {
        component: (
          <LotsForm
            onData={setLotsForm}
            defaultValues={lotsForm}
            onSubmit={async () => {
              await lotSubmit();
              setSelectedIndex((index) => index + 1);
            }}
            backErrors={
              (lotCreation.error ??
                lotEdition.error) as IBackErrors<TLotsFormInputs>
            }
          />
        ),
        beforeNextStep: async () => {
          await lotSubmit();
          return true;
        },
        isCompleted: () => isFormValid(lotsForm),
        modalSize: MODAL_SIZE_ENUM.LG,
      },
      {
        component: (
          <>
            {(!initialLotId || forceShowOnlyFrameToggle) && (
              <Alert
                variant="info"
                className="text-center d-flex justify-content-between align-items-center px-5"
              >
                <CircleQuestionIcon />
                <span className="mx-5 text-dark">{t("only frame")}</span>
                <Form.Check
                  type="switch"
                  className="d-inline switch-lg"
                  checked={isOnlyFrame}
                  onChange={(e) => setIsOnlyFrame(e.target.checked)}
                  data-test={ifTest("check-frame")}
                />
              </Alert>
            )}
            <ImportFilesArea
              disabled={percentage !== undefined && percentage < 100}
              initialData={files}
              onData={setFiles}
              buttonText={t(
                isOnlyFrame ? "import frame" : "import detailed MOE"
              )}
              percentage={percentage! < 100 ? percentage : undefined}
              areaText={t("or drag file here")}
              enableFileEdit={false}
              accept={ACCEPT_EXCEL_FILE_FORMAT}
            />
          </>
        ),
        isCompleted: () => Boolean(Object.entries(sheets.sheets).length),
        modalSize: MODAL_SIZE_ENUM.LG,
        hidePrevious: true,
      },
      {
        component: (
          <SelectTab
            onData={setTab}
            initialData={tab}
            tabs={Object.keys(sheets.sheets)}
          />
        ),
        isCompleted: () => !!tab,
        modalSize: MODAL_SIZE_ENUM.LG,
      },
      {
        component: (
          <>
            <Alert
              variant="info"
              className="text-center d-flex justify-content-between align-items-center px-5"
            >
              <CircleQuestionIcon />
              <span className="mx-5 text-dark">
                {t("additional documents like CCTP")}
              </span>
            </Alert>
            <ImportFilesArea
              multiple
              initialData={documents}
              onData={setDocuments}
              buttonText={t("select documents")}
              areaText={t("or drag document here")}
              enableFileEdit={false}
            />
          </>
        ),
        isCompleted: () => true,
        isOptional: () => !documents?.length,
        modalSize: MODAL_SIZE_ENUM.LG,
        footerHasSpace: () => !documents?.length,
      },
      {
        component: (
          <ExcelImportStepMapper
            selectedStepIndex={getStepIndex(
              selectedIndex,
              sheetMappingStartIndex
            )}
            setSelectedStepIndex={(index) =>
              setSelectedIndex(index + sheetMappingStartIndex)
            }
            setMappings={(maps) => {
              setMappings(maps);
              submitStep();
            }}
            sheet={tab ? sheets.sheets[tab]?.() : []}
            currentStep={currentMappingStep}
            mappings={mappings}
            sheetMappingStartIndex={sheetMappingStartIndex}
            lastValidStepIndex={getLastValidStepIndex(
              selectedIndex,
              mappings,
              getStepIndex,
              sheetMappingStartIndex,
              isStepCompleted,
              mappingSteps
            )}
            submitError={submitError}
            isFrame={isOnlyFrame}
            logs={mappingLogs}
          />
        ),
        isCompleted: (selectedIndex: number) =>
          isStepCompleted(
            mappings,
            mappingSteps,
            getStepIndex(selectedIndex, sheetMappingStartIndex),
            isOnlyFrame
          ),
        modalSize: MODAL_SIZE_ENUM.XL,
      },
    ];

    // filter out the steps to skip
    if (stepsToSkip) {
      // remove elements in place by index from end to start
      stepsToSkip.sort((a, b) => b - a);
      stepsToSkip.forEach((step) => steps.splice(step, 1));
    }
    return steps;
  }, [
    lotsForm,
    lotCreation.error,
    lotEdition.error,
    initialLotId,
    forceShowOnlyFrameToggle,
    t,
    isOnlyFrame,
    percentage,
    files,
    tab,
    sheets.sheets,
    documents,
    selectedIndex,
    sheetMappingStartIndex,
    currentMappingStep,
    mappings,
    submitError,
    mappingLogs,
    stepsToSkip,
    lotSubmit,
    submitStep,
  ]);
  return (
    <>
      <ConfirmationModal
        title={t("Close and lose steps?")}
        show={showConfirmationModal}
        handleClose={(confirm) =>
          confirm ? handleCancel() : setShowConfirmationModal(false)
        }
      />
      <ConfirmationModal
        title={t("Override previous mapping?")}
        show={showConfirmationOverrideModal}
        handleClose={(confirm) =>
          confirm ? submit() : setShowConfirmationOverrideModal(false)
        }
        isSubmitting={isSubmitting}
      />
      <LotsModalContent
        show={show}
        handleClose={() =>
          checkIfDirty() ? setShowConfirmationModal(true) : handleCancel()
        }
        steps={steps}
        selectedIndex={selectedIndex}
        sheetMappingStartIndex={sheetMappingStartIndex}
        files={files}
        setSheets={setSheets}
        setTab={setTab}
        setSelectedIndex={setSelectedIndex}
        mappingSteps={mappingSteps}
        title={t(isEditing ? "title edit" : "title create")}
        onSubmit={submit}
        isLoading={isSubmitting || worksQuery.isLoading}
      />
    </>
  );
}
