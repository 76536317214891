import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { DefaultModal } from "../shared/DefaultModal";
import { IBackErrors, RootError, useBackFieldErrors } from "../shared/errors";
import { useTranslation, yupLocale } from "../shared/i18n";
import {
  ControlledInput,
  Input,
  RequiredAsterisk,
  Select,
  getInputProps,
} from "../shared/inputs";
import { AllotmentSelect } from "../shared/inputs/AllotmentSelect";
import {
  CONSTRUCTION_INDICATOR_VALUES,
  IRowType,
  ROWTYPE_USAGE_ENUM,
  units,
} from "../shared/types/rowType.types";
import { useRowTypeOrganizationQuery } from "../shared/useSharedQueries";
import { ifTest } from "../shared/utils";

import { ConstructionIndicatorSelect } from "./ConstructionIndicatorSelect";
import { MappingUsageSelect } from "./MappingUsageSelect";

export interface IRowTypeModal {
  show?: boolean;
  value?: Partial<IRowType>;
  handleClose: (value?: IRowType) => void;
  isSubmitting?: boolean;
}

export const ROW_TYPE_RE = /^([^.]{3})(\.[^.]{3})*$/;

yup.setLocale(yupLocale);
const schema = yup.object({
  id: yup.string().max(128),
  ref: yup.string().max(128).matches(ROW_TYPE_RE).required(),
  description: yup.string().max(128).required(),
  unit: yup.string().max(128).required(),
  organization: yup.string().max(128).required(),
  creation_date: yup.date().required(),
  admin_approval: yup.boolean().required(),
  original_author: yup.string().optional(),
  allotment_type: yup.string().required(),
  usage: yup
    .array()
    .of(yup.string().required().oneOf(Object.values(ROWTYPE_USAGE_ENUM)))
    .required(),
  construction_index: yup
    .string()
    .required()
    .oneOf(CONSTRUCTION_INDICATOR_VALUES),
});

export function RowTypeModal({
  show = true,
  handleClose,
  value,
  isSubmitting = false,
  backErrors,
}: IRowTypeModal & { backErrors?: IBackErrors<IRowType> }) {
  const organization = useRowTypeOrganizationQuery();

  const {
    handleSubmit,
    register,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<IRowType>({
    defaultValues: {
      creation_date: new Date(),
      admin_approval: false,
      ...value,
      organization,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const { t } = useTranslation("RowTypeModal");

  useBackFieldErrors(backErrors, setError, clearErrors);

  return (
    <DefaultModal
      title={t("title")}
      show={show}
      handleClose={() => handleClose(undefined)}
      size="lg"
    >
      <Form
        onSubmit={handleSubmit(
          (values) => !isSubmitting && handleClose(values)
        )}
      >
        <Row className="pb-3">
          <Input
            as={Col}
            disabled={isSubmitting}
            autoFocus
            {...getInputProps("ref", register, schema, errors)}
            label={t("ref")}
            data-test={ifTest("input-ref")}
          />
          <ControlledInput
            as={Col}
            xs={4}
            label={t("allotment_type")}
            name="allotment_type"
            schema={schema}
            control={control}
            render={({ field }) => <AllotmentSelect {...field} multi={false} />}
          />
        </Row>
        <Row className="pb-3">
          <Input
            as={Col}
            disabled={isSubmitting}
            {...getInputProps("description", register, schema, errors)}
            label={t("description")}
            data-test={ifTest("input-description")}
          />
          <Form.Group as={Col} xs={4}>
            <Form.Label>
              {t("unit")}
              <RequiredAsterisk />
            </Form.Label>
            <Controller
              name="unit"
              control={control}
              render={({ field }) => (
                <Select
                  searchable
                  searchValueAsOption
                  options={units}
                  {...field}
                  placeholder={t("select unit")}
                />
              )}
            />
          </Form.Group>
        </Row>
        <Row>
          <ControlledInput
            as={Col}
            label={t("construction_index")}
            name="construction_index"
            schema={schema}
            control={control}
            render={({ field }) => <ConstructionIndicatorSelect {...field} />}
          />
          <ControlledInput
            as={Col}
            xs={4}
            label={t("usage")}
            name="usage"
            schema={schema}
            control={control}
            render={({ field }) => <MappingUsageSelect {...field} />}
          />
        </Row>
        <RootError errors={errors} />
        <Row className="my-4 justify-content-center">
          <Col xs="auto">
            <Button size="lg" type="submit" disabled={isSubmitting}>
              {t("save")}
              {isSubmitting && <Spinner size="sm" />}
            </Button>
          </Col>
        </Row>
      </Form>
    </DefaultModal>
  );
}
