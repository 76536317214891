import { parseISO } from "date-fns";
import { CellErrorValue, CellValue } from "exceljs";

import { ICompany } from "./company.types";

export interface ILotPrice {
  company_name: string;
  estimation_amount: number;
  id: string;
}

export function ILotDTOToILotMapper(lot: ILotDTO): ILot {
  return {
    ...lot,
    closure_date: lot.closure_date ? parseISO(lot.closure_date) : undefined,
  };
}

export function ILotSelectedDTOToILotSelectedMapper(
  lot: ILotSelectedDTO
): ILotSelected {
  return {
    ...lot,
    closure_date: lot.closure_date ? parseISO(lot.closure_date) : undefined,
  };
}

export interface ILotClosureDTO extends Omit<ILotClosure, "closure_date"> {
  closure_date?: string;
}
export interface ILotClosure {
  closure_date?: Date;
  selected_price_amount: number;
  discount?: number | null;
  selected_discounted_price_amount?: number | null;
  complementary_informations?: string;
  interior_frame: LOT_CLOSURE_FRAME_ENUM[];
  peripheral_frame: LOT_CLOSURE_FRAME_ENUM;
  intermediary_floor: LOT_CLOSURE_FLOOR_ENUM;
  structure_protection: LOT_CLOSURE_PROTECTION_ENUM;
  facade_type: LOT_CLOSURE_FACADE_TYPE_ENUM;
  earthwork: LOT_CLOSURE_EARTHWORK_ENUM[];
  foundation_protection: LOT_CLOSURE_PROTECTION_ENUM;
  foundation_type: LOT_CLOSURE_FOUNDATION_TYPE_ENUM;
  cladding_type: LOT_CLOSURE_CLADDING_TYPE_ENUM;
  exterior_insulation: LOT_CLOSURE_EXTERIOR_INSULATION_ENUM;
  waterproofing_protection: LOT_CLOSURE_PROTECTION_ENUM;
  coverage_type: LOT_CLOSURE_COVERAGE_TYPE_ENUM;
  exterior_joineries: LOT_CLOSURE_EXTERIOR_JOINERIES_ENUM;
  heating: LOT_CLOSURE_HEATING_ENUM;
  cooling: LOT_CLOSURE_COOLING_ENUM;
  ventilation: LOT_CLOSURE_VENTILATION_ENUM;
  safety_power_supply: LOT_CLOSURE_SAFETY_POWER_SUPPLY_ENUM;
  electrical_connection: LOT_CLOSURE_ELECTRICAL_CONNECTION_ENUM;
  energy_storage: LOT_CLOSURE_ENERGY_STORAGE_ENUM;
  voice_data_video_material: LOT_CLOSURE_VOICE_DATA_VIDEO_MATERIAL_ENUM;
  voice_data_video_network: LOT_CLOSURE_VOICE_DATA_VIDEO_NETWORK_ENUM;
  fire_safety_system: LOT_CLOSURE_FIRE_SAFETY_SYSTEM_ENUM;
  partition_walls_insulation: LOT_CLOSURE_PARTITION_WALLS_INSULATION_ENUM;
}

export interface ILotMappedTenders {
  id: string;
  name: string;
  project_name: string;
  pricebreakdowns: {
    id: string;
    upload_date: string;
    company: string;
    company_name: string;
  }[];
}

export interface ILotSelectedDTO {
  project: string;
  project__name: string;
  selected__company__name: string;
  selected: string;
  selected__row_type_mapping_percentage?: number;
  id: string;
  name: string;
  closure_date?: string;
}

export interface ILotSelected extends Omit<ILotSelectedDTO, "closure_date"> {
  closure_date?: Date;
}

export interface ILotDTO extends Omit<ILot, "closure_date"> {
  closure_date?: string;
}
export interface ILot extends Partial<ILotClosure> {
  id: string;
  name?: string;
  conventional_allotments: CONVENTIONAL_ALLOTMENTS_ENUM[];
  project_name: string;
  reference_number: string;
  project: string;
  estimation_amount?: number;
  min_price_breakdowns: ILotPrice[];
  max_price_breakdowns: ILotPrice[];
  // set to null to delete value in backend
  best?: string | null;
  best_price_breakdown?: ILotPrice;
  avg_estimation_amount: number;
  // set to null to delete value in backend
  selected?: string | null;
  selected_company_name?: string;
  selected_price_amount?: number;
}

export type TCell = CellValue | { error: CellErrorValue };

export function getIsBestPriceBreakdown(
  bestLotPrice: ILotPrice | undefined,
  company: ICompany | undefined
) {
  return (
    bestLotPrice !== undefined &&
    bestLotPrice !== null &&
    company?.name === bestLotPrice?.company_name &&
    company?.tenders.some(({ id }) => bestLotPrice?.id === id)
  );
}

export enum CONVENTIONAL_ALLOTMENTS_ENUM {
  STRUCTURAL_WORK = "STRUCTURAL_WORK",
  DEMOLITION = "DEMOLITION",
  CLEANING_WORK = "CLEANING_WORK",
  ASBESTOS_REMOVAL = "ASBESTOS_REMOVAL",
  EARTHWORK = "EARTHWORK",
  FOUNDATIONS = "FOUNDATIONS",
  SITE_INSTALLATION = "SITE_INSTALLATION",
  ROADS_MISCELLANEOUS_NETWORKS = "ROADS_MISCELLANEOUS_NETWORKS",
  GREEN_SPACES = "GREEN_SPACES",
  FACADES = "FACADES",
  SEALING = "SEALING",
  WOOD_FRAME = "WOOD_FRAME",
  METAL_FRAME = "METAL_FRAME",
  BLANKET = "BLANKET",
  EXTERIOR_WOOD_FURNISHINGS = "EXTERIOR_WOOD_FURNISHINGS",
  HVAC = "HVAC",
  PLUMBING = "PLUMBING",
  HIGH_VOLTAGE_ELECTRICAL_SYSTEMS = "HIGH_VOLTAGE_ELECTRICAL_SYSTEMS",
  LOW_VOLTAGE_ELECTRICAL_SYSTEMS = "LOW_VOLTAGE_ELECTRICAL_SYSTEMS",
  FIRE_SAFETY_SYSTEM = "FIRE_SAFETY_SYSTEM",
  TECHNICAL_BUILDING_MANAGEMENT = "TECHNICAL_BUILDING_MANAGEMENT",
  ELEVATING_DEVICES = "ELEVATING_DEVICES",
  GEOTHERMAL = "GEOTHERMAL",
  SPRINKLER = "SPRINKLER",
  TRANSFORMER = "TRANSFORMER",
  DOCK_EQUIPMENT = "DOCK_EQUIPMENT",
  KITCHEN = "KITCHEN",
  INDUSTRIAL_DOORS = "INDUSTRIAL_DOORS",
  LOCKSMITH_METALWORK = "LOCKSMITH_METALWORK",
  PARTITIONS_LININGS = "PARTITIONS_LININGS",
  FALSE_CEILINGS = "FALSE_CEILINGS",
  INTERIOR_JOINERY = "INTERIOR_JOINERY",
  HARD_FLOOR_COVERINGS = "HARD_FLOOR_COVERINGS",
  FLEXIBLE_FLOOR_COVERINGS = "FLEXIBLE_FLOOR_COVERINGS",
  PAINT = "PAINT",
  LAYOUT = "LAYOUT",
  CLEANING = "CLEANING",
  FLOOR_RESIN = "FLOOR_RESIN",
}

export enum LOT_CLOSURE_THERMAL_INSULATION_ENUM {
  exterior_insulation = "exterior_insulation",
  interior_insulation = "interior_insulation",
}

export enum LOT_CLOSURE_FRAME_ENUM {
  CLT_walls = "CLT_walls",
  wooden_frame_walls = "wooden_frame_walls",

  concrete_frame_walls = "concrete_frame_walls",
  wooden_beam_to_column = "wooden_beam_to_column",
}

export enum LOT_CLOSURE_PROTECTION_ENUM {
  tubbing = "tubbing",
  inapplicable = "inapplicable",
}
export enum LOT_CLOSURE_FACADE_TYPE_ENUM {
  concrete = "concrete",
  wooden_frame = "wooden_frame",
  building_block = "building_block",
}

export enum LOT_CLOSURE_FLOOR_ENUM {
  collaborative_slabs = "collaborative_slabs",
  solid_slab = "solid_slab",
  CLT_floors = "CLT_floors",
  wood_concrete_composite = "wood_concrete_composite",
  joist_flooring_block = "joist_flooring_block",
}
export enum LOT_CLOSURE_EARTHWORK_ENUM {
  tubbing = "tubbing",
  polluted_grounds = "polluted_grounds",
  inapplicable = "inapplicable",
}

export enum LOT_CLOSURE_FOUNDATION_TYPE_ENUM {
  deep = "deep",
  special = "special",
  superficial = "superficial",
}

export enum LOT_CLOSURE_CLADDING_TYPE_ENUM {
  steel = "steel",
  wood = "wood",
  metal_tile = "metal_tile",
  manufactured_panel = "manufactured_panel",
  zinc = "zinc",
  inapplicable = "inapplicable",
}

export enum LOT_CLOSURE_EXTERIOR_INSULATION_ENUM {
  biosourced = "biosourced",
  mineral_wool = "mineral_wool",
  petrochemistry = "petrochemistry",
  distributed = "distributed",
  inapplicable = "inapplicable",
}

export enum LOT_CLOSURE_COVERAGE_TYPE_ENUM {
  wooden_shingle = "wooden_shingle",
  ribbed_sheet_steel = "ribbed_sheet_steel",
  concrete_tile = "concrete_tile",
  zinc = "zinc",
}

export enum LOT_CLOSURE_EXTERIOR_JOINERIES_ENUM {
  wood = "wood",
  aluminium = "aluminium",
  wood_aluminium = "wood_aluminium",
  PVC = "PVC",
}

export enum LOT_CLOSURE_HEATING_ENUM {
  biomass = "biomass",
  gas = "gas",
  air_source_heat_pump = "air_source_heat_pump",
  geothermal_heat_pump = "geothermal_heat_pump",
}

export enum LOT_CLOSURE_COOLING_ENUM {
  inapplicable = "inapplicable",
  air_conveyor = "air_conveyor",
  water_to_water_chiller = "water_to_water_chiller",
}

export enum LOT_CLOSURE_VENTILATION_ENUM {
  centralized_double_flow = "centralized_double_flow",
  decentralized_double_flow = "decentralized_double_flow",
  single_flow = "single_flow",
  inversed_mechanical_ventilation = "inversed_mechanical_ventilation",
}

export enum LOT_CLOSURE_SAFETY_POWER_SUPPLY_ENUM {
  flywheel = "flywheel",
  inapplicable = "inapplicable",
}

export enum LOT_CLOSURE_ELECTRICAL_CONNECTION_ENUM {
  low_voltage = "low_voltage",
  transformer_station = "transformer_station",
  existing_wiring = "existing_wiring",
}

export enum LOT_CLOSURE_ENERGY_STORAGE_ENUM {
  electric_battery = "electric_battery",
  inapplicable = "inapplicable",
}

export enum LOT_CLOSURE_VOICE_DATA_VIDEO_MATERIAL_ENUM {
  wireless_connector = "wireless_connector",
  inapplicable = "inapplicable",
}

export enum LOT_CLOSURE_VOICE_DATA_VIDEO_NETWORK_ENUM {
  copper_cabling_7a = "copper_cabling_7a",
  copper_cabling_8a = "copper_cabling_8a",
  optical_fiber = "optical_fiber",
  inapplicable = "inapplicable",
}

export enum LOT_CLOSURE_FIRE_SAFETY_SYSTEM_ENUM {
  alarm_2a = "alarm_2a",
  alarm_2b = "alarm_2b",
  category_A_system = "category_A_system",
  category_B_system = "category_B_system",
  other = "other",
}

export enum LOT_CLOSURE_PARTITION_WALLS_INSULATION_ENUM {
  biosourced = "biosourced",
  mineral_wool = "mineral_wool",
  petrochemistry = "petrochemistry",
  distributed = "distributed",
}
