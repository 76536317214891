import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function LockIcon({ className = "", fill = styles.teal900, size = 25 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 25 25"
      fill="none"
      className={className}
    >
      <g clipPath="url(#lock-icon)">
        <path
          fill={fill}
          d="M19.5 10.053h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-16a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h1v-1a7 7 0 0 1 14 0v1Zm-2 0v-1a5 5 0 0 0-10 0v1h10Zm-6 4v4h2v-4h-2Z"
        />
      </g>
      <defs>
        <clipPath id="lock-icon">
          <path fill="#fff" d="M.5.053h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
