export enum APP_ENV_ENUM {
  development = "development",
  staging = "staging",
  test = "test",
  prod = "prod",
  production = "production",
}

// create type since enum doesn't play along with ts-check in public/config.js
export type TAppEnv = `${APP_ENV_ENUM}`;

declare global {
  interface Window {
    REACT_APP_API_URL: string;
    REACT_APP_MATOMO_URL: string;
    REACT_APP_ADDRESS_API_URL: string;
    REACT_APP_CONTACT_URL: string;
    REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION: string;
    REACT_APP_QUERY_CACHE_TIME_MS: number;
    REACT_APP_QUERY_STALE_TIME_MS: number;
    REACT_APP_ENV: TAppEnv;
    REACT_APP_RELEASE: string;
  }
}

export {};
