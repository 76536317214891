import classNames from "classnames";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import styles from "../../shared/DefaultModal.module.scss";
import { useTranslation } from "../../shared/i18n";

export function ConfirmClosure({
  onSubmit,
  isLoading,
}: {
  onSubmit: () => void;
  isLoading: boolean;
}) {
  const { t } = useTranslation("LotClosureModal");
  return (
    <>
      <Modal.Header className="border-bottom-0 px-4 mx-2 pb-4 justify-content-md-center">
        <Modal.Title
          className={classNames("text-center", styles["wrap-balance"])}
        >
          {t("confirm closure title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">{t("confirm closure detail")}</p>
        <Row className="justify-content-center mt-4 mb-5">
          <Col sm="auto">
            <Button
              size="lg"
              variant="primary"
              onClick={onSubmit}
              className="rectangular ms-4 d-flex align-items-center"
              disabled={isLoading}
            >
              {t("confirm closure action")}
              {isLoading && <Spinner className="ms-2" />}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
}
