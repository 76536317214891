import { useEffect, useState } from "react";

import { useTendersTable } from "../shared/table/useTendersTable/useTendersTable";
import { ICompany } from "../shared/types/company.types";
import { ITender } from "../shared/types/tender.types";
import { useHasChanged } from "../shared/useHasChanged";
import { doArraysHaveDifferentValues } from "../shared/utils";

/**
 * Automatically selects latest version
 */
export function useTendersTableWithAutoSelectedLastVersion({
  companies,
  onEditTenderVersion,
  onEditTender,
  onDeleteTender,
  onAddNewTender,
  estimation,
  lowestEstimation,
}: {
  companies: ICompany[];
  onEditTenderVersion?: (tender: ITender) => void;
  onEditTender?: (company: ICompany) => void;
  onDeleteTender?: (tender: ITender) => void;
  onAddNewTender?: (company: ICompany) => void;
  estimation?: number;
  lowestEstimation?: number;
}) {
  const [controlledSelectedTenderIds, setControlledSelectedTenderIds] =
    useState(companies.map(({ tenders }) => tenders[0].id));

  const { table, onRowClick, selectedTenderIds } = useTendersTable({
    companies,
    estimation,
    lowestEstimation,
    canEdit: true,
    onEditTenderVersion,
    onEditTender,
    onDeleteTender,
    onAddNewTender,
    controlledSelectedTenderIds,
  });

  useAutoSelectAddedTenderVersionInTendersTable(
    selectedTenderIds,
    controlledSelectedTenderIds,
    setControlledSelectedTenderIds,
    companies
  );

  return { table, onRowClick, selectedTenderIds };
}

function useAutoSelectAddedTenderVersionInTendersTable(
  selectedTenderIds: string[],
  controlledSelectedTenderIds: string[],
  setControlledSelectedTenderIds: React.Dispatch<
    React.SetStateAction<string[]>
  >,
  companies: ICompany[]
) {
  const [seenTenderIds, setSeenTenderIds] = useState(
    companies.flatMap(({ tenders }) => tenders.map((tender) => tender.id))
  );

  // update controlledSelectedTenderIds based on selectedTenderIds changing
  useEffect(() => {
    if (
      doArraysHaveDifferentValues(
        selectedTenderIds,
        controlledSelectedTenderIds
      )
    ) {
      setControlledSelectedTenderIds(selectedTenderIds);
    }
  }, [
    selectedTenderIds,
    controlledSelectedTenderIds,
    setControlledSelectedTenderIds,
  ]);

  // update controlledSelectedTenderIds to include latest added tender
  const companiesHasChanged = useHasChanged(companies);
  if (companiesHasChanged) {
    setControlledSelectedTenderIds((ids) =>
      ids.concat(
        companies
          .map(({ tenders }) => tenders[0].id)
          .filter((id) => !seenTenderIds.includes(id))
      )
    );
    setSeenTenderIds(
      companies.flatMap(({ tenders }) => tenders.map((tender) => tender.id))
    );
  }
}
