import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from "react-hook-form";

import { useTranslation } from "../../shared/i18n";
import { DIFFERENCE_DISPLAY_MODE_ENUM } from "../../shared/visualization/Difference";

export function NumberDifferenceAbsoluteRelative<T extends FieldValues>({
  field: { onChange, onBlur, value, name, ref },
}: {
  field: ControllerRenderProps<T>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<T>;
}) {
  const { t } = useTranslation("NumberDifferenceBaseInput");
  return (
    <Form.Group controlId={name}>
      <Form.Label>{t("choose number difference display mode")}</Form.Label>
      <Row className="mb-3">
        <Col>
          <Form.Check
            inline
            label={t("percent")}
            name={name}
            type="radio"
            id={`${name}-${DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT}`}
            onBlur={onBlur}
            ref={ref}
            checked={value === DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT}
            onChange={() => onChange(DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT)}
          />
        </Col>
        <Col>
          <Form.Check
            inline
            label={t("absolute")}
            name={name}
            type="radio"
            id={`${name}-${DIFFERENCE_DISPLAY_MODE_ENUM.ABSOLUTE}`}
            onBlur={onBlur}
            checked={value === DIFFERENCE_DISPLAY_MODE_ENUM.ABSOLUTE}
            onChange={() => onChange(DIFFERENCE_DISPLAY_MODE_ENUM.ABSOLUTE)}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}
