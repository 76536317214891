import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enTranslations from "../i18n.en.json";
import frTranslations from "../i18n.fr.json";
import { isProductionEnv } from "../shared/utils";

export enum SUPPORTED_LANGUAGES_ENUM {
  FR = "fr",
  EN = "en",
  DEBUG = "debug",
}
const defaultLanguage = SUPPORTED_LANGUAGES_ENUM.FR;
export const supportedLanguages = [
  { code: SUPPORTED_LANGUAGES_ENUM.FR, title: "FR", name: "Francais" },
  { code: SUPPORTED_LANGUAGES_ENUM.EN, title: "EN", name: "English" },
  ...(isProductionEnv
    ? []
    : [{ code: SUPPORTED_LANGUAGES_ENUM.DEBUG, title: "🔧", name: "debug" }]),
];

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      fr: frTranslations,
      en: enTranslations,
      debug: {},
    },
    fallbackLng: isProductionEnv ? defaultLanguage : undefined,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      convertDetectedLanguage: (lng) => lng.split("-").shift()!,
    },
  });

export { i18n };
