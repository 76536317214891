import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";

import { fetchEstimate, updatePriceEstimate } from "../../api/fetchEstimates";
import { fetchTender, updateTender } from "../../api/fetchTenders";
import { IComment } from "../../shared/types/comment.types";
import { IDocumentData } from "../../shared/types/document.types";
import { IPriceEstimate } from "../../shared/types/estimate.types";
import { isTender } from "../../shared/types/tender.types";
import { useHasChanged } from "../../shared/useHasChanged";
import { QUERY_KEYS_ENUM } from "../../shared/useSharedQueries";

import { TDataByRowNumberByRowDocumentIdByColDocumentId } from "./CompareTable.types";

const COMMENT_QUERY_REFETCH_INTERVAL_MS = 1000 * 60;

/**
 *
 * @param document to update
 * @param comment to update on document
 * @returns a Partial<IDocumentData> with its id and updated list of comments
 */
function getDocumentPatchWithUpdatedComment(
  { id, comments }: IDocumentData,
  comment: IComment
): Partial<IDocumentData> {
  if (!comment.message) {
    // empty message, remove comment from document
    return {
      id,
      comments: comments?.filter(
        ({ row_number }) => comment.row_number !== row_number
      ),
    };
  }
  if (comments?.find(({ row_number }) => comment.row_number === row_number)) {
    // modify existing comment
    return {
      id,
      comments: comments?.map((existingComment) =>
        comment.row_number === existingComment.row_number
          ? comment
          : existingComment
      ),
    };
  }
  // add new comment
  return {
    id,
    comments: [...(comments ?? []), comment],
  };
}

/*
  Check for comment update by comparing their row_number and price_estimate fields that should grant unique id
  Add message at the end to check if they have changed
**/
function commentToUIDMapper(comment?: IComment) {
  return `${comment?.row_number}.${comment?.price_estimate}.${comment?.message}`;
}

export function useCompareTableComments(
  base: IPriceEstimate | undefined,
  compareDocuments: IDocumentData[],
  enabled?: boolean
) {
  const [
    expandedCommentColumnsByDocumentIds,
    setExpandedCommentColumnsByDocumentIds,
  ] = useState<Record<string, boolean>>({});

  const queryClient = useQueryClient();

  // we here use a special _COMMENT key to separate comment fetch and update from our documents queries
  // this enables us to update comments without having to reprocess the entire table
  // finally we also initialize data with our already at-hand document to avoid doing an initial network fetch
  const estimateCommentsQuery = useQuery({
    queryKey: [QUERY_KEYS_ENUM.ESTIMATE_COMMENT, base?.allotment, base?.id],
    queryFn: () => fetchEstimate(base?.id!),
    enabled: Boolean(enabled && base?.id),
    initialData: base,
    refetchInterval: COMMENT_QUERY_REFETCH_INTERVAL_MS,
  });

  const documentsCommentsQueries = useQueries({
    queries:
      compareDocuments.map((document) => ({
        queryKey: [
          isTender(document)
            ? QUERY_KEYS_ENUM.TENDER_COMMENT
            : QUERY_KEYS_ENUM.ESTIMATE_COMMENT,
          document.allotment,
          document.id,
        ],
        queryFn: () =>
          (isTender(document) ? fetchTender : fetchEstimate)(document.id),
        enabled: Boolean(enabled && document.id),
        initialData: document,
        refetchInterval: COMMENT_QUERY_REFETCH_INTERVAL_MS,
      })) ?? [],
  });

  const [documents, setDocuments] = useState<IDocumentData[]>([]);

  const haveEstimateCommentsChanged = useHasChanged(
    estimateCommentsQuery.status,
    ...(estimateCommentsQuery.data?.comments ?? []).map(commentToUIDMapper)
  );
  const hasTenderCommentsChanged = useHasChanged(
    ...documentsCommentsQueries.map(({ status }) => status),
    ...documentsCommentsQueries
      .map(({ data }) => data)
      .flatMap((document) => document?.comments)
      .map(commentToUIDMapper)
  );
  if (haveEstimateCommentsChanged || hasTenderCommentsChanged) {
    setDocuments(
      [
        estimateCommentsQuery.data,
        ...documentsCommentsQueries.map(({ data }) => data),
      ].filter((document) => document) as IDocumentData[]
    );
  }

  const commentByRowNumberByRowDocumentIdByColDocumentId = useMemo<
    TDataByRowNumberByRowDocumentIdByColDocumentId<string>
  >(
    () =>
      documents
        .filter((document) => document)
        .reduce(
          (dict, document) => ({
            ...dict,
            [document.id]: (document.comments ?? []).reduce((dict, comment) => {
              // We need to create a dictionary indexing comments by the row number of the document that possesses the line it was put on
              // the document which holds the row where the comment was made is the same of the column if this line exist on it
              // otherwise it should be the estimate id as we can't store comment made for other tenders (yet)
              const rowDocumentId = comment.price_estimate
                ? comment.price_estimate
                : document.id;
              const existingEntry: Record<number, string> =
                dict[rowDocumentId] ?? {};
              return {
                ...dict,
                [rowDocumentId]: {
                  ...existingEntry,
                  [comment.row_number]: comment.message,
                },
              };
            }, {} as Record<string, Record<number, string>>),
          }),
          {} as TDataByRowNumberByRowDocumentIdByColDocumentId<string>
        ),
    [documents]
  );

  const invalidateCommentQueries = (document: IDocumentData) => {
    queryClient.invalidateQueries([
      QUERY_KEYS_ENUM.ESTIMATE_COMMENT,
      document.allotment,
    ]);
    queryClient.invalidateQueries([
      QUERY_KEYS_ENUM.TENDER_COMMENT,
      document.allotment,
    ]);
  };
  const updateEstimateCommentMutation = useMutation({
    mutationFn: updatePriceEstimate,
    onSuccess: invalidateCommentQueries,
  });

  const updateTenderCommentMutation = useMutation({
    mutationFn: updateTender,
    onSuccess: invalidateCommentQueries,
  });

  const updateComment = useCallback(
    (documentId: string, comment: IComment) => {
      if (documentId === estimateCommentsQuery.data?.id) {
        //comment is on estimate
        updateEstimateCommentMutation.mutate(
          getDocumentPatchWithUpdatedComment(
            estimateCommentsQuery.data,
            comment
          )
        );
      } else {
        const document = documentsCommentsQueries.find(
          ({ data }) => data?.id === documentId
        )?.data;
        if (!document) {
          throw new Error(
            `no document found for this comment's documentId ${documentId}`
          );
        }
        updateTenderCommentMutation.mutate(
          getDocumentPatchWithUpdatedComment(document, comment)
        );
      }
    },
    [
      estimateCommentsQuery.data,
      documentsCommentsQueries,
      updateEstimateCommentMutation,
      updateTenderCommentMutation,
    ]
  );

  return {
    updateComment,
    commentByRowNumberByRowDocumentIdByColDocumentId,
    setExpandedCommentColumnsByDocumentIds,
    expandedCommentColumnsByDocumentIds,
  };
}
