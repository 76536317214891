import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";

import { deletePriceAveragePanel } from "../api/fetchPriceAverage";
import colors from "../shared/_exports.module.scss";
import { useTranslation } from "../shared/i18n";
import { formatTolerant } from "../shared/i18n/dateFormatter";
import { PenIcon, TrashIcon } from "../shared/icons";
import { ConfirmationModal } from "../shared/modals";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { Table } from "../shared/table";
import { IPriceAveragePanel } from "../shared/types/averagePrice.types";
import { QUERY_KEYS_ENUM } from "../shared/useSharedQueries";
import { ifTest, isDevelopmentEnv } from "../shared/utils";

import { PriceAveragePanelEditModal } from "./PriceAveragePanelModal";

const emptyData: IPriceAveragePanel[] = [];

export function PriceAveragePanelsTable({
  averagePricePanels,
  isLoading,
}: {
  averagePricePanels: IPriceAveragePanel[];
  isLoading: boolean;
}) {
  const { t } = useTranslation("PriceAveragePanelsTable");

  const [priceAveragePanelToEdit, setPriceAveragePanelToEdit] =
    useState<IPriceAveragePanel>();
  const [priceAveragePanelToDelete, setPriceAveragePanelToDelete] =
    useState<IPriceAveragePanel>();

  const PriceAveragePanelsColumns: ColumnDef<IPriceAveragePanel>[] = useMemo(
    () => [
      {
        header: t("name"),
        accessorKey: "name",
      },
      {
        header: t("description"),
        accessorKey: "description",
      },
      {
        header: t("creation_date"),
        accessorKey: "creation_date",
        cell: (rowData) => {
          const formattedDate = formatTolerant(
            new Date(rowData.row.original.creation_date!),
            "dd/MM/yyyy"
          );
          return (
            <Row>
              <Col>{formattedDate}</Col>
              <Col sm="auto">
                <Button
                  className="me-4"
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPriceAveragePanelToEdit(rowData.row.original);
                  }}
                  data-test={ifTest("edit-panel-button")}
                >
                  <PenIcon fill={colors.black} size={20} />
                </Button>
                <Button
                  className="me-4"
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPriceAveragePanelToDelete(rowData.row.original);
                  }}
                  data-test={ifTest("delete-panel-button")}
                >
                  <TrashIcon size={20} />
                </Button>
              </Col>
            </Row>
          );
        },
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const PriceAveragePanelDeletion = useMutation({
    mutationFn: deletePriceAveragePanel,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS],
      });
      setPriceAveragePanelToDelete(undefined);
    },
    onError: (e) => {
      console.error(e);
    },
  });

  const table = useReactTable<IPriceAveragePanel>({
    columns: PriceAveragePanelsColumns,
    data: averagePricePanels ?? emptyData,
    filterFns: {},
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: isDevelopmentEnv,
  });

  const navigate = useNavigate();

  return (
    <Row>
      <PriceAveragePanelEditModal
        show={Boolean(priceAveragePanelToEdit)}
        priceAveragePanel={priceAveragePanelToEdit}
        handleClose={() => setPriceAveragePanelToEdit(undefined)}
      />
      <ConfirmationModal
        title={t("delete PriceAveragePanel", {
          name: priceAveragePanelToDelete?.name,
        })}
        show={Boolean(priceAveragePanelToDelete)}
        handleClose={(confirm: boolean | void) => {
          confirm
            ? PriceAveragePanelDeletion.mutate(priceAveragePanelToDelete!.id)
            : setPriceAveragePanelToDelete(undefined);
        }}
        isSubmitting={PriceAveragePanelDeletion.isLoading}
      />
      <Table
        table={table}
        compact
        stickyHeaders
        responsive={false}
        onRowClick={({ original }) => {
          const { id } = original;
          navigate(`/${PATH_NAMES_ENUM.PRICE_AVERAGE}/${id}`);
        }}
        isLoading={isLoading}
        footerClassName="mb-n5 bg-transparent position-absolute bottom-0 start-50 translate-middle-x"
      />
    </Row>
  );
}
