import classNames from "classnames";
import React, { ReactElement } from "react";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { OverlayChildren } from "react-bootstrap/esm/Overlay";
import {
  NavLink as RouterNavLink,
  useMatch,
  useSearchParams,
} from "react-router-dom";

import styles from "../_exports.module.scss";
import { ifTest } from "../utils";

export interface INav {
  Icon: ({ fill, size }: { fill?: string; size?: number }) => ReactElement;
  path: string;
  back?: boolean;
  overlay: OverlayChildren;
  disabled?: boolean;
  id?: string;
}

export function NavLink({ Icon, path, overlay, back, disabled, id }: INav) {
  const isActive = Boolean(
    useMatch({ path, end: false }) && !disabled && !back
  );
  const [searchParam] = useSearchParams();
  return (
    <OverlayTrigger placement="bottom" overlay={overlay}>
      {({ ref, ...triggerHandler }) => (
        <Nav.Link
          as={RouterNavLink}
          to={{
            pathname: path,
            // use current searchParam if we are already on the correct page
            search: isActive ? `?${searchParam}` : undefined,
          }}
          replace={isActive}
          data-test={ifTest(id)}
          ref={ref}
          {...triggerHandler}
          className={classNames(
            "border border-2 mx-3 d-flex justify-content-between align-items-center",
            back ? "rounded-circle my-auto p-2" : "rounded-3 p-3",
            isActive ? "border-info" : "border-white",
            { "opacity-50": disabled }
          )}
          disabled={disabled}
        >
          <Icon
            fill={isActive ? styles.info : styles.white}
            size={back ? undefined : 25}
          />
        </Nav.Link>
      )}
    </OverlayTrigger>
  );
}
