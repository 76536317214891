import React from "react";
import Form from "react-bootstrap/Form";

import { ifTest } from "../../utils";

export interface ICheckBoxProps {
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  className?: string;
  id?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  isInvalid?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  name?: string;
}
export const CheckBox = React.forwardRef<HTMLInputElement, ICheckBoxProps>(
  function CheckBox(
    {
      checked,
      onChange,
      className,
      onClick,
      id,
      disabled,
      label,
      isInvalid,
      onBlur,
      name,
    }: ICheckBoxProps,
    ref
  ) {
    return (
      <Form.Check
        className={className}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        readOnly={!onChange}
        id={id}
        data-test={ifTest("checkbox")}
        disabled={disabled}
        label={label}
        isInvalid={isInvalid}
        onBlur={onBlur}
        name={name}
        ref={ref}
      />
    );
  }
);
