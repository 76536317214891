import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function CrownIcon({
  className = "",
  fill = styles.blue300,
  size = 25,
}) {
  return (
    <svg
      className={className}
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.606 15.24h13.809l1.017-4.151h.038a1.915 1.915 0 0 0 1.906-1.914A1.908 1.908 0 0 0 20.47 7.27a1.913 1.913 0 0 0-1.733 2.712l-2.185 1.575c-.226.158-.384.105-.505-.076l-2.599-3.842c.573-.331.965-.957.965-1.665a1.91 1.91 0 1 0-2.855 1.665l-2.6 3.842c-.12.174-.256.226-.474.068L6.269 9.951c.105-.234.166-.497.166-.776 0-1.04-.86-1.906-1.906-1.906a1.915 1.915 0 0 0-1.914 1.906c0 1.055.859 1.914 1.914 1.914h.052l1.025 4.15Zm.286 1.197.249 1.002c.309 1.243.987 1.876 2.283 1.876h8.166c1.296 0 1.974-.618 2.275-1.876l.249-1.002H5.892Z"
        fill={fill}
      />
    </svg>
  );
}
