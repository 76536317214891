import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function BackArrowIcon({
  className = "",
  fill = styles.primary,
  size = 16,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fill={fill}
        d="m3.828 9 5.364 5.364-1.414 1.414L0 8 7.778.222l1.414 1.414L3.828 7H16v2H3.828Z"
      />
    </svg>
  );
}
