import { RowData } from "@tanstack/react-table";

import { DIFFERENCE_DISPLAY_MODE_ENUM } from "../shared/visualization/Difference";

export enum PriceAverageMode {
  PRICE_AVERAGE = "PRICE_AVERAGE",
  PRICE_MEDIAN = "PRICE_MEDIAN",
  PRICE_MIN = "PRICE_MIN",
  PRICE_MAX = "PRICE_MAX",
}

export interface IPriceAverageDifference extends IThresholds {
  active: boolean;
  mode: DIFFERENCE_DISPLAY_MODE_ENUM;
}

export interface IThresholds {
  /** range from 0 to 100% */
  positive_threshold: number;
  negative_threshold: number;
}

export interface IPriceAverageSettings {
  number_difference: IPriceAverageDifference;
  mode: PriceAverageMode;
}

export interface IPriceAverageTableMeta {
  priceAverageSettings: IPriceAverageSettings;
  maxDepth?: number;
}

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData>
    extends Partial<IPriceAverageTableMeta> {}
}
