import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import ToggleButton from "react-bootstrap/ToggleButton";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { DefaultModal } from "../../shared/DefaultModal";
import { useTranslation, yupLocale } from "../../shared/i18n";
import {
  ControlledInput,
  Input,
  Switch,
  getInputProps,
} from "../../shared/inputs";
import { yupNumber } from "../../shared/inputs/input";
import { IPriceEstimate } from "../../shared/types/estimate.types";
import {
  COMPANY_SORT_MODE,
  DEFAULT_COMPANY_SORT_MODE,
  ITender,
} from "../../shared/types/tender.types";
import { DIFFERENCE_DISPLAY_MODE_ENUM } from "../../shared/visualization/Difference";
import { ICompareDifferenceSettings } from "../Compare.types";
import { defaultCompareDifferenceSettings } from "../CompareDifference";
import { TABLE_COLUMN_DATA_TYPE_ENUM } from "../Table.types";

import { NumberDifferenceAbsoluteRelative } from "./NumberDifferenceAbsoluteRelative";
import { NumberDifferenceBaseInput } from "./NumberDifferenceBaseInput";
import { TenderSelectTable } from "./TenderSelectTable";

enum COMPARE_SETTING_SECTIONS_ENUM {
  DISPLAY = "display",
  DIFFERENCE = "difference",
}

const columnDataTypeWithConfigurableVisibilityInButtonGroup = Object.freeze([
  TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY,
  TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE,
  TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
  TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS,
  TABLE_COLUMN_DATA_TYPE_ENUM.DIFFERENCE,
  TABLE_COLUMN_DATA_TYPE_ENUM.LOGS,
]);

yup.setLocale(yupLocale);
const schema = yup.object({
  display: yup
    .object({
      tenders: yup.array().of(yup.string().required()).min(0).required(),
      tendersCompanySortMode: yup
        .mixed<COMPANY_SORT_MODE>()
        .oneOf(Object.values(COMPANY_SORT_MODE))
        .required(),
      columns: yup
        .array()
        .of(
          yup
            .mixed<TABLE_COLUMN_DATA_TYPE_ENUM>()
            .oneOf(Object.values(TABLE_COLUMN_DATA_TYPE_ENUM))
            .required()
        )
        .required()
        .test(
          "at-least-one-column",
          "formValidation.At least one column is required",
          function (columns) {
            return (
              columns.filter(
                (col) => col !== TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE
              ).length > 0
            );
          }
        ),
    })
    .required(),
  difference: yup.object({
    number_difference: yup
      .object({
        active: yup.boolean().required(),
        mode: yup
          .string()
          .required()
          .oneOf(Object.values(DIFFERENCE_DISPLAY_MODE_ENUM)),
        base: yup.string().required(),
        positive_threshold: yupNumber().min(0).integer().required(),
        negative_threshold: yupNumber().min(0).integer().required(),
      })
      .required(),
    line_difference_active: yup.boolean().required(),
  }),
});

export type TCompareSettingsFormInputs = yup.InferType<typeof schema>;

export function CompareSettingsModal({
  show,
  handleClose,
  estimate,
  tenders,
  tendersCompanySortMode,
  differenceSettings = defaultCompareDifferenceSettings,
  columnDisplaySettings,
  onSubmit,
}: {
  show: boolean;
  handleClose: (save: boolean | void) => void;
  estimate?: IPriceEstimate;
  tenders: ITender[];
  tendersCompanySortMode?: COMPANY_SORT_MODE;
  differenceSettings?: ICompareDifferenceSettings;
  columnDisplaySettings: TABLE_COLUMN_DATA_TYPE_ENUM[];
  onSubmit(formInputs: TCompareSettingsFormInputs): void;
}) {
  const { t } = useTranslation("CompareSettingsModal");

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TCompareSettingsFormInputs>({
    resolver: yupResolver(schema),
    values: {
      display: {
        tenders: tenders?.map(({ id }) => id),
        tendersCompanySortMode:
          tendersCompanySortMode ?? DEFAULT_COMPANY_SORT_MODE,
        columns: columnDisplaySettings,
      },
      difference: differenceSettings,
    },
    mode: "onSubmit",
  });
  const isNumberDifferenceActive = watch("difference.number_difference.active");

  return (
    <DefaultModal
      show={show}
      title={t("title")}
      handleClose={handleClose}
      staticBackdrop
      size="lg"
    >
      <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
        <Tab.Container
          id="compare-settings-tabs"
          defaultActiveKey={COMPARE_SETTING_SECTIONS_ENUM.DISPLAY}
        >
          <Row>
            <Col sm="auto" className="border-end">
              <Nav variant="pills" className="flex-column">
                <Nav.Item className="mb-2 text-center">
                  <Nav.Link eventKey={COMPARE_SETTING_SECTIONS_ENUM.DISPLAY}>
                    {t("display settings")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mb-2 text-center">
                  <Nav.Link eventKey={COMPARE_SETTING_SECTIONS_ENUM.DIFFERENCE}>
                    {t("difference settings")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col>
              <Tab.Content>
                <Tab.Pane
                  eventKey={COMPARE_SETTING_SECTIONS_ENUM.DISPLAY}
                  className="px-4"
                >
                  <Row className="mb-3">
                    <Col xs="auto">
                      <ControlledInput
                        name="display.columns"
                        className="mb-3"
                        label={t("display columns")}
                        schema={schema}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <ButtonGroup className="mb-2 d-block">
                            {columnDataTypeWithConfigurableVisibilityInButtonGroup.map(
                              (column: TABLE_COLUMN_DATA_TYPE_ENUM) => (
                                <ToggleButton
                                  className="px-3"
                                  id={`column-${column}-toggle-check`}
                                  key={`column-${column}-toggle-check`}
                                  type="checkbox"
                                  variant="outline-primary"
                                  inputRef={ref}
                                  onBlur={onBlur}
                                  checked={value.includes(column)}
                                  value={column}
                                  onChange={(e) =>
                                    onChange(
                                      e.currentTarget.checked
                                        ? [...new Set(value.concat(column))]
                                        : value.filter((col) => col !== column)
                                    )
                                  }
                                >
                                  {t(`${column}`)}
                                </ToggleButton>
                              )
                            )}
                          </ButtonGroup>
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <Controller
                        name="display.columns"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.Group>
                            <Form.Check
                              onChange={(e) =>
                                // ESTIMATE is part of display.columns, insert or remove it based on the checkbox value
                                onChange(
                                  e.currentTarget.checked
                                    ? [
                                        ...new Set(
                                          value.concat(
                                            TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE
                                          )
                                        ),
                                      ]
                                    : value.filter(
                                        (col) =>
                                          col !==
                                          TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE
                                      )
                                )
                              }
                              label={
                                <label htmlFor="display-estimate" role="button">
                                  {t("display estimate")}
                                </label>
                              }
                              // ESTIMATE is displayed as a checkbox, so test if it is included in display.columns
                              checked={value.includes(
                                TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE
                              )}
                              id="display-estimate"
                            />
                          </Form.Group>
                        )}
                      />
                    </Col>
                  </Row>
                  {tendersCompanySortMode && (
                    <Row className="mb-4">
                      <Col xs="auto">
                        <ControlledInput
                          name="display.tendersCompanySortMode"
                          className="mb-3"
                          label={t("tenders company sort mode")}
                          schema={schema}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <ButtonGroup className="mb-2 d-block">
                              {Object.values(COMPANY_SORT_MODE).map(
                                (companySortMode) => (
                                  <ToggleButton
                                    className="px-3"
                                    id={`company-sort-mode-${companySortMode}-toggle-check`}
                                    key={`company-sort-mode-${companySortMode}-toggle-check`}
                                    type="checkbox"
                                    variant="outline-primary"
                                    inputRef={ref}
                                    onBlur={onBlur}
                                    checked={value === companySortMode}
                                    value={companySortMode}
                                    onChange={() => onChange(companySortMode)}
                                  >
                                    {t(companySortMode)}
                                  </ToggleButton>
                                )
                              )}
                            </ButtonGroup>
                          )}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-4">
                    <ControlledInput
                      name="display.tenders"
                      as={Col}
                      className="mb-3"
                      label={t("display columns")}
                      schema={schema}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TenderSelectTable
                          onChange={onChange}
                          controlledSelectedTenderIds={value}
                        />
                      )}
                    />
                  </Row>
                </Tab.Pane>
                <Tab.Pane
                  eventKey={COMPARE_SETTING_SECTIONS_ENUM.DIFFERENCE}
                  className="px-4"
                >
                  <Row className="mb-3 pb-3 border-bottom">
                    <Col>
                      <h6>{t("number_difference")}</h6>
                      <Row className="mb-3">
                        <Col>
                          <Switch
                            control={control}
                            name="difference.number_difference.active"
                            label={t("activate")}
                          />
                        </Col>
                      </Row>
                      <fieldset disabled={!isNumberDifferenceActive}>
                        <Controller
                          name="difference.number_difference.mode"
                          control={control}
                          render={(controllerProps) => (
                            <NumberDifferenceAbsoluteRelative
                              // cast to any since field is a subset and TS complains that '"display"' is not assignable to type '"difference.number_difference.mode"'
                              {...(controllerProps as any)}
                            />
                          )}
                        />
                        <Controller
                          name="difference.number_difference.base"
                          control={control}
                          render={(controllerProps) => (
                            <NumberDifferenceBaseInput
                              {...controllerProps}
                              estimate={estimate}
                              tenders={tenders}
                            />
                          )}
                        />
                        <Row className="mb-3">
                          <Input
                            as={Col}
                            xs="auto"
                            {...getInputProps(
                              "difference.number_difference.negative_threshold",
                              register,
                              schema,
                              errors
                            )}
                            label={t("set negative threshold")}
                            tooltip={t("negative threshold tooltip")}
                            placeholder={String(
                              defaultCompareDifferenceSettings.number_difference
                                .negative_threshold
                            )}
                            inputGroupBefore={
                              <InputGroup.Text>-</InputGroup.Text>
                            }
                            inputGroupAfter={
                              <InputGroup.Text>%</InputGroup.Text>
                            }
                          />
                          <Input
                            as={Col}
                            xs="auto"
                            {...getInputProps(
                              "difference.number_difference.positive_threshold",
                              register,
                              schema,
                              errors
                            )}
                            label={t("set positive threshold")}
                            tooltip={t("positive threshold tooltip")}
                            placeholder={String(
                              defaultCompareDifferenceSettings.number_difference
                                .positive_threshold
                            )}
                            inputGroupBefore={
                              <InputGroup.Text>+</InputGroup.Text>
                            }
                            inputGroupAfter={
                              <InputGroup.Text>%</InputGroup.Text>
                            }
                          />
                        </Row>
                      </fieldset>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6>{t("line_difference")}</h6>
                      <Row className="mb-3">
                        <Col>
                          <Switch
                            control={control}
                            name="difference.line_difference_active"
                            label={t("activate")}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <Row className="my-4 justify-content-center">
          <Col xs="auto">
            <Button size="lg" type="submit">
              {t("validate")}
            </Button>
          </Col>
        </Row>
      </Form>
    </DefaultModal>
  );
}
