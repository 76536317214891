import { Dispatch, SetStateAction, useId } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineEye } from "react-icons/ai";

import { useTranslation } from "../shared/i18n";
import { CheckBox } from "../shared/inputs";

import { ILotChartData } from "./chart.types";

export function LotsChartDataFilterDropdown({
  lotsChartData,
  visibleLotsChartData,
  setVisibleLotsChartData,
}: {
  lotsChartData: ILotChartData[];
  visibleLotsChartData: ILotChartData[];
  setVisibleLotsChartData: Dispatch<SetStateAction<ILotChartData[]>>;
}) {
  const { t } = useTranslation("LotsChartDataFilterDropdown");
  const { t: tShared } = useTranslation("shared");
  const dropdownId = useId();
  return (
    <Dropdown
      className="d-inline-block me-3"
      autoClose="outside"
      id={`lots-chart-data-filter-dropdown-${dropdownId}`}
    >
      <Dropdown.Toggle variant="text" className="fw-bold align-top">
        <AiOutlineEye className="me-2 fs-5" />
        {t("chart display settings")}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {lotsChartData.map((lot) => (
          <Dropdown.Item
            key={`${lot.reference_number}${lot.name}`}
            as={Button}
            variant="text"
            onClick={() => {
              setVisibleLotsChartData((visibleLotsChartData) =>
                visibleLotsChartData.includes(lot)
                  ? visibleLotsChartData.filter(
                      (visibleLot) => visibleLot !== lot
                    )
                  : visibleLotsChartData.concat(lot)
              );
            }}
            className="px-3 py-2"
          >
            <CheckBox
              checked={visibleLotsChartData.includes(lot)}
              className="me-2 d-inline-block"
            />

            {t("allotment number", { number: lot.reference_number })}
            {lot.name && (
              <>
                {tShared("colon")}
                {lot.name}
              </>
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
