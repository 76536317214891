import classnames from "classnames";
import React, { ReactElement, useCallback, useEffect, useState } from "react";

import { useHasChanged } from "../../useHasChanged";

import { Option } from "./Option";
import { OptionGroup } from "./OptionGroup";
import styles from "./_select.module.scss";
import {
  IOption,
  TOptions,
  TValue,
  getLabelAsString,
  isOptionGroup,
  isOptionGroups,
} from "./select.types";

const menuMaxHeight = +styles.menuMaxHeightRem.slice(0, -3);
const optionHeight = +styles.optionHeight.slice(0, -3);

export interface IMenuProps {
  options: TOptions;
  selectableOptions?: IOption[];
  onSelect: (option: IOption) => void;
  onDeselect: (option: IOption) => void;
  onFocus: React.FocusEventHandler<HTMLDivElement>;
  onFocusOption?: (option?: TValue) => void;
  searchMessage: string | ReactElement;
  formatOptionLabel?: (option: IOption) => React.ReactNode;
  multi: boolean;
  selection: IOption[];
  showSelectionInMenu: boolean;
  horizontal?: boolean;
}
export const Menu = React.forwardRef<HTMLDivElement, IMenuProps>(function Menu(
  {
    options = [],
    selectableOptions = [],
    onSelect = () => {},
    onDeselect = () => {},
    searchMessage = "",
    formatOptionLabel,
    multi,
    selection = [],
    showSelectionInMenu,
    onFocus,
    onFocusOption,
    horizontal,
  }: IMenuProps,
  ref
) {
  const hasOptionsChanged = useHasChanged(...options);

  const [scrollableMenu, setScrollableMenu] = useState(false);
  if (hasOptionsChanged) {
    setScrollableMenu(options.length * optionHeight > menuMaxHeight);
  }

  const [focusedOption, setFocusedOptionState] = useState<IOption>();
  const setFocusedOption = useCallback(
    (
      option: IOption | ((option?: IOption) => IOption | undefined),
      focusOption = true
    ) => {
      setFocusedOptionState(option);
      // since option can be a callback, make sure to get the updated value from the state
      if (onFocusOption && focusOption) {
        setFocusedOptionState((value) => {
          onFocusOption(value?.value);
          return value;
        });
      }
    },
    [onFocusOption]
  );

  if (hasOptionsChanged && !isOptionGroups(options)) {
    // don't update if there's already one
    setFocusedOption(
      (option) => (option ? option : options.length ? options[0] : undefined),
      false
    );
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key } = event;
      if (["ArrowUp", "ArrowDown"].includes(key)) {
        event.stopPropagation();
        key === "ArrowUp" &&
          setFocusedOption((focusedOption) =>
            getPreviousOption(focusedOption, selectableOptions, selection)
          );
        key === "ArrowDown" &&
          setFocusedOption((focusedOption) =>
            getNextOption(focusedOption, selectableOptions, selection)
          );
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectableOptions, selection, setFocusedOption]);

  useEffect(() => {
    const handleKeyDown = ({ key }: KeyboardEvent) => {
      if (key === "Enter" && focusedOption) {
        selectableOptions.includes(focusedOption) && onSelect(focusedOption);
        selection.includes(focusedOption) && onDeselect(focusedOption);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [focusedOption, onSelect, onDeselect, selectableOptions, selection]);

  const hasSelectionChanged = useHasChanged(selection);

  if (hasOptionsChanged || hasSelectionChanged) {
    if (selection.length) {
      setFocusedOption((option) => option ?? selection[0], false);
    } else if (selectableOptions.length) {
      setFocusedOption((option) => option ?? selectableOptions[0], false);
    }
  }

  return (
    <div className="select-menu" ref={ref} onFocus={onFocus}>
      <ul
        className={classnames("select-options", {
          scroll: scrollableMenu,
          horizontal: horizontal,
        })}
      >
        {options.length ? (
          options.map((option) =>
            isOptionGroup(option) ? (
              <OptionGroup
                key={getLabelAsString(option.label)}
                optionGroup={option}
                formatOptionLabel={formatOptionLabel}
                onSelect={onSelect}
                onDeselect={onDeselect}
                onMouseEnter={setFocusedOption}
                multi={multi}
                selection={selection}
                focusedOption={focusedOption}
              />
            ) : (
              <Option
                key={option.value}
                option={option}
                formatOptionLabel={formatOptionLabel}
                className={classnames({
                  focus: focusedOption === option,
                })}
                onSelect={onSelect}
                onDeselect={onDeselect}
                onMouseEnter={() => setFocusedOption(option)}
                onFocus={() => setFocusedOption(option)}
                multi={multi}
                selection={selection}
              />
            )
          )
        ) : (
          <li className="select-search-message">{searchMessage}</li>
        )}
      </ul>
    </div>
  );
});

function getPreviousOption(
  focusedOption: IOption | undefined,
  options: IOption[] = [],
  selection: IOption[] = []
) {
  if (!focusedOption || (!options.length && !selection.length)) {
    return undefined;
  }
  if (focusedOption && selection.includes(focusedOption)) {
    return selection[0] !== focusedOption
      ? selection[selection.indexOf(focusedOption) - 1]
      : focusedOption;
  }

  if (selection.length && options[0] === focusedOption) {
    return selection[selection.length - 1];
  }
  return options[0] !== focusedOption
    ? options[options.indexOf(focusedOption) - 1]
    : focusedOption;
}

function getNextOption(
  focusedOption: IOption | undefined,
  options: IOption[],
  selection: IOption[]
) {
  if (!focusedOption || (!options.length && !selection.length)) {
    return undefined;
  }
  if (options.length && selection[selection.length - 1] === focusedOption) {
    return options[0];
  }
  if (selection.includes(focusedOption)) {
    return selection[selection.indexOf(focusedOption) + 1];
  }
  return options[options.length - 1] !== focusedOption
    ? options[options.indexOf(focusedOption) + 1]
    : focusedOption;
}
