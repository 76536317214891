import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/esm/helpers";

export function ButtonWithTooltip<As extends React.ElementType = "button">({
  tooltip,
  ...props
}: { tooltip: string } & React.PropsWithChildren<
  ReplaceProps<As, BsPrefixProps<As> & ButtonProps>
>) {
  const button = (
    <Button
      // cast any to get out of react bootstrap typescript hell
      {...(props as any)}
    />
  );

  return (
    <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
      {
        // for disabled button see https://react-bootstrap.netlify.app/docs/components/overlays/#disabled-elements
        props.disabled ? (
          <span
            className={
              // use display class from button if available
              props.className
                ?.split(" ")
                .find((className: string) => className.startsWith("d-")) ??
              "d-inline-block"
            }
          >
            {button}
          </span>
        ) : (
          button
        )
      }
    </OverlayTrigger>
  );
}
