import { DocumentExplorer } from ".";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { useEnumTranslation } from "../shared/i18n";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { useLotsQuery } from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";

export function OperationDocumentsPage() {
  const navigate = useNavigate();
  const { operationId } = useParams();
  const [searchParams] = useSearchParams();
  const { tEnum } = useEnumTranslation();

  useTrackPageView(
    "OperationDocuments",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
    ],
    [operationId]
  );

  const lotsQuery = useLotsQuery(operationId);

  const lots = lotsQuery?.data?.map((lot) => ({
    id: lot.id,
    file_name: `${lot.conventional_allotments
      .map((item) => tEnum(item))
      .join("/")}
      ${Boolean(lot.name) ? `(${lot.name})` : ""}`,
  }));

  const isLoading =
    lotsQuery.isLoading || (lotsQuery.isFetching && !lotsQuery.isPreviousData);

  return (
    <DocumentExplorer
      isLoading={isLoading}
      onFolderClick={(id: string, event) =>
        event.ctrlKey
          ? window.open(
              `./${PATH_NAMES_ENUM.LOTS}/${id}/${
                PATH_NAMES_ENUM.DOCUMENTS
              }?${searchParams.toString()}`
            )
          : navigate({
              pathname: `../${PATH_NAMES_ENUM.LOTS}/${id}/${PATH_NAMES_ENUM.DOCUMENTS}`,
              search: searchParams.toString(),
            })
      }
      documents={lots}
    />
  );
}
