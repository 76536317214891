import { Select } from ".";
import React from "react";
import { ControllerRenderProps } from "react-hook-form";

import { useEnumTranslation, useTranslation } from "../i18n";
import { LOT_CLOSURE_COOLING_ENUM } from "../types/lot.types";

export const CoolingSelect = React.forwardRef<
  HTMLInputElement,
  ControllerRenderProps & {
    placeholder?: string;
  }
>(function CoolingSelect({ value, ...field }, ref) {
  const { tEnum } = useEnumTranslation();
  const { t } = useTranslation("CoolingSelect");

  const options = Object.values(LOT_CLOSURE_COOLING_ENUM).map((value) => ({
    value,
    label: tEnum(value),
  }));

  return (
    <Select
      {...field}
      value={value}
      ref={ref}
      options={options}
      searchable
      placeholder={t("select cooling")}
    />
  );
});
