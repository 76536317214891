import { WorkTypeSelect } from "../../shared/inputs";
import { WORK_TYPE_ENUM } from "../../shared/types/operation.types";

import { IFilterProps } from "./../OperationsFilters";

export function WorkTypeFilter({ filterKey, onChange }: IFilterProps) {
  return (
    <WorkTypeSelect
      clearable
      onChange={(selectedValue?: WORK_TYPE_ENUM[]) =>
        onChange?.(filterKey, selectedValue)
      }
      hideValueOnSearch
      multi
    />
  );
}
