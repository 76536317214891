import { SUPPORTED_LANGUAGES_ENUM } from "../../host/i18n";

export interface IUserCreationDTO {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
}

/**
 * roles/permission each user has
 */
export interface IUserPermission {
  // Can this account access the application. Unselect this instead of deleting accounts to revoke access.
  is_active: boolean;
  // Is this user master admin and can edit restricted fields (ie. user emails and permissions).
  is_master: boolean;
  // Can this user log into the django admin site.
  is_staff: boolean;
  // Does this user have all permissions without explicitly assigning them.
  is_superuser: boolean;
}

export interface IUser extends IUserPermission {
  id: string;
  last_name: string;
  first_name: string;
  email: string;
  token: string;
  language?: SUPPORTED_LANGUAGES_ENUM;
}
export interface IToken {
  access: string;
  refresh: string;
}
export interface ITokenAccess {
  access: string;
}
export interface IUserDTO extends IUserPermission {
  email: string;
  first_name: string;
  last_name: string;
}

export function isUserAdmin(user?: IUser) {
  return user?.is_superuser || user?.is_staff;
}
