import { useParams } from "react-router-dom";

import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { useCompanyQuery, useTendersQuery } from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";

import { DocumentExplorer } from "./DocumentExplorer";

export function CompanyDocumentsPage() {
  const { companyId, operationId, lotId } = useParams();

  useTrackPageView(
    "Lot",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
      {
        id: CUSTOM_DIMENSIONS_ENUM.LOT_ID,
        value: String(lotId),
      },
      {
        id: CUSTOM_DIMENSIONS_ENUM.COMPANY_ID,
        value: String(companyId),
      },
    ],
    [operationId, lotId, companyId]
  );

  const companyQuery = useCompanyQuery(companyId);
  const company = companyQuery.data;

  const tendersQuery = useTendersQuery(lotId);

  const documents = tendersQuery.data?.find(
    (company) => company.id === companyId
  )?.tenders;

  const isLoading =
    companyQuery.isLoading ||
    (companyQuery.isFetching && !companyQuery.isPreviousData) ||
    tendersQuery.isLoading ||
    (tendersQuery.isFetching && !tendersQuery.isPreviousData);

  return (
    <DocumentExplorer
      isLoading={isLoading}
      documents={documents}
      companyName={company?.name}
    />
  );
}
