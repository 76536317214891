import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { GuardedButton } from "../account/GuardedButton";
import { canCreateNewOperation } from "../account/guards";
import { useModal } from "../shared/DefaultModal";
import { OperationModal } from "../shared/OperationModal";
import { Footer } from "../shared/footer";
import { Header } from "../shared/header";
import { useTranslation } from "../shared/i18n";
import { AddCircledIcon } from "../shared/icons";
import { useOrganizationsQuery } from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";
import { ifTest } from "../shared/utils";

import { OperationsTable } from "./OperationsTable";

export function OperationsPage() {
  useTrackPageView("Operations");

  const { t } = useTranslation("OperationsPage");
  const { show, handleClose, handleShow } = useModal("OperationCreationModal");

  const organizationsQuery = useOrganizationsQuery();

  return (
    <div className="min-h-100 d-flex flex-column">
      <Header />
      <Container fluid className="bg-light flex-grow-1 pb-1">
        <Card className="mb-5 w-auto border-0 mx-md-4">
          <Card.Body className="pb-0 px-md-4 px-0">
            <Row className="mb-4">
              <Col className="mr-auto">
                <h4>{t("title")}</h4>
              </Col>
              <Col sm="auto">
                <GuardedButton
                  allowed={canCreateNewOperation(organizationsQuery.data)}
                  size="lg"
                  onClick={handleShow}
                  data-test={ifTest("openOperationCreationModal")}
                >
                  <AddCircledIcon className="mb-1 me-1" /> {t("addOne")}
                </GuardedButton>
                <OperationModal show={show} onHide={handleClose} />
              </Col>
            </Row>
            <OperationsTable />
          </Card.Body>
        </Card>
      </Container>
      <Footer className="bg-light" />
    </div>
  );
}
