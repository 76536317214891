import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  ILotSelected,
} from "../shared/types/lot.types";
import { IMappingLotTenderPercentage } from "../shared/types/mapping.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";
import { useLotsSelectedQuery } from "../shared/useSharedQueries";

import { MappingTendersTable } from "./MappingTendersTable";

/** dictionary to convert frontend sorting keys to backend field names */
const BACKEND_LOT_FIELD_NAME_BY_FRONTEND_LOT_SORTING_KEY_DICT: {
  [key: string]: string;
} = {
  tender: "selected",
  project: "project",
  project_name: "project__name",
  allotment: "id",
  allotment_name: "name",
  company_name: "selected__company__name",
  closure_date: "closure_date",
  percentage: "selected__row_type_mapping_percentage",
};

/** map ILotSelected to IMappingLotTenderPercentage in query*/
function ILotSelectedToIMappingLotTenderPercentageMapper(
  lots: IPaginatedQueryResults<ILotSelected>
): IPaginatedQueryResults<IMappingLotTenderPercentage> {
  return {
    ...lots,
    results: lots.results.map((lot) => ({
      tender: lot.selected,
      project: lot.project,
      project_name: lot.project__name,
      allotment: lot.id,
      allotment_name: lot.name,
      company_name: lot.selected__company__name,
      closure_date: lot.closure_date,
      percentage: lot.selected__row_type_mapping_percentage,
    })),
  };
}

export function MappingLotsSelectedTable({
  conventionalAllotment,
}: {
  conventionalAllotment: CONVENTIONAL_ALLOTMENTS_ENUM;
}) {
  return (
    <MappingTendersTable
      conventionalAllotment={conventionalAllotment!}
      query={useLotsSelectedQuery}
      select={ILotSelectedToIMappingLotTenderPercentageMapper}
      mapSearchParamsToBackendFieldNameDict={
        BACKEND_LOT_FIELD_NAME_BY_FRONTEND_LOT_SORTING_KEY_DICT
      }
    />
  );
}
