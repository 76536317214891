import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function UserIcon({
  color = styles?.primary,
  className = "",
  size = 24,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#user-icon)">
        <path
          d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2Zm-8-9A6 6 0 1 1 12 .998 6 6 0 0 1 12 13Zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="user-icon">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
