import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Control } from "react-hook-form";
import * as yup from "yup";

import { useTranslation } from "../../../shared/i18n";
import {
  CladdingTypeSelect,
  ControlledInput,
  ExteriorInsulationSelect,
} from "../../../shared/inputs";
import {
  getUndefinedSafeSelectOnChange,
  yupRequiredWhenIncludes,
} from "../../../shared/inputs/input";
import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  LOT_CLOSURE_CLADDING_TYPE_ENUM,
  LOT_CLOSURE_EXTERIOR_INSULATION_ENUM,
  LOT_CLOSURE_THERMAL_INSULATION_ENUM,
} from "../../../shared/types/lot.types";

import { yupRequiredWhenConventionalAllotmentIncludes } from "./utils";

function yupRequiredWhen<S>(schema: yup.Schema<S>) {
  return yupRequiredWhenConventionalAllotmentIncludes(
    schema,
    CONVENTIONAL_ALLOTMENTS_ENUM.FACADES
  );
}

export const schema = yup.object({
  cladding_type: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_CLADDING_TYPE_ENUM))
  ),
  exterior_insulation: yupRequiredWhenIncludes(
    yup.string().oneOf(Object.values(LOT_CLOSURE_EXTERIOR_INSULATION_ENUM)),
    {
      conventional_allotments: CONVENTIONAL_ALLOTMENTS_ENUM.FACADES,
      thermal_insulation:
        LOT_CLOSURE_THERMAL_INSULATION_ENUM.exterior_insulation,
    }
  ),
});

export type TFormInputs = yup.InferType<typeof schema>;

export function FacadeAllotment({
  control,
  exterior_insulation_required,
}: {
  control: Control<TFormInputs>;
  exterior_insulation_required: boolean | undefined;
}) {
  const { t } = useTranslation("LotClosureModal");

  return (
    <>
      <h6 className="my-4">{t("facade title")}</h6>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          required
          label={t("cladding type")}
          name="cladding_type"
          schema={schema}
          control={control}
          render={({ field }) => (
            <CladdingTypeSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
        <ControlledInput
          as={Col}
          required={exterior_insulation_required}
          label={t("exterior insulation")}
          name="exterior_insulation"
          schema={schema}
          control={control}
          render={({ field }) => (
            <ExteriorInsulationSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
      </Row>
    </>
  );
}
