import { yupResolver } from "@hookform/resolvers/yup";
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { FieldErrors, UseFormRegister, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { toISODate } from "../api/APIUtils";
import { createOrUpdateComparison } from "../api/fetchComparison";
import { DefaultModal } from "../shared/DefaultModal";
import { OverwriteOrSaveAsNew } from "../shared/OverwriteOrSaveAsNew";
import { IBackErrors, RootError, useBackFieldErrors } from "../shared/errors";
import { useTranslation, yupLocale } from "../shared/i18n";
import { Input, getInputProps } from "../shared/inputs";
import { IComparisonDTO } from "../shared/types/comparison.types";
import { IPriceEstimate } from "../shared/types/estimate.types";
import { COMPANY_SORT_MODE, ITender } from "../shared/types/tender.types";
import { useHasChanged } from "../shared/useHasChanged";
import { QUERY_KEYS_ENUM } from "../shared/useSharedQueries";
import { ifTest } from "../shared/utils";

import { ICompareDifferenceSettings } from "./Compare.types";
import { defaultCompareDifferenceSettings } from "./CompareDifference";
import { TABLE_COLUMN_DATA_TYPE_ENUM } from "./Table.types";

export interface ISavingForm {
  name: string;
  description: string;
}

yup.setLocale(yupLocale);

const schema = yup.object({
  name: yup.string().required(),
  description: yup.string(),
});

export interface ISaveComparisonModalProps {
  show: boolean;
  handleClose: (comparison?: IComparisonDTO) => void;
  estimate?: IPriceEstimate;
  tenders: ITender[];
  tendersCompanySortMode?: COMPANY_SORT_MODE;
  comparison?: IComparisonDTO;
  differenceSettings?: ICompareDifferenceSettings;
  columnDisplaySettings: TABLE_COLUMN_DATA_TYPE_ENUM[];
}

export type TSavingFormInputs = yup.InferType<typeof schema>;

function ComparisonForm({
  register,
  errors,
  comparisonCreation,
}: {
  register: UseFormRegister<any>;
  errors: FieldErrors<TSavingFormInputs>;
  comparisonCreation: UseMutationResult<
    IComparisonDTO,
    unknown,
    Partial<IComparisonDTO>,
    unknown
  >;
}) {
  const { t } = useTranslation("SaveComparisonModal");
  return (
    <>
      <Row className="mb-3">
        <Input
          as={Col}
          {...getInputProps("name", register, schema, errors)}
          label={t("name")}
        />
      </Row>
      <Row className="mb-5">
        <Input
          as={Col}
          {...getInputProps("description", register, schema, errors)}
          label={t("description")}
        />
      </Row>
      <RootError errors={errors} />
      <Row>
        <Col className="text-center mb-3 px-4">
          <Button
            size="lg"
            variant="dark"
            type="submit"
            className="mx-4 text-center"
            disabled={comparisonCreation.isLoading}
            data-test={ifTest("save-comparison-modal")}
          >
            {t("save")}
            {comparisonCreation.isLoading && (
              <Spinner size="sm" className="ms-2" />
            )}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export function SaveComparisonModal({
  show,
  handleClose,
  estimate,
  tenders,
  tendersCompanySortMode,
  comparison,
  differenceSettings = defaultCompareDifferenceSettings,
  columnDisplaySettings,
  backErrors,
}: ISaveComparisonModalProps & {
  backErrors?: IBackErrors<TSavingFormInputs>;
}) {
  const { t } = useTranslation("SaveComparisonModal");
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TSavingFormInputs>({
    resolver: yupResolver(schema),
    values: comparison,
    mode: "onSubmit",
  });
  const onClose = (comparison?: IComparisonDTO) => {
    setShouldAskToSaveOnOrNewComparison(Boolean(comparison?.id));
    handleClose(comparison);
  };
  const { lotId } = useParams();
  useBackFieldErrors(backErrors, setError, clearErrors);
  const queryClient = useQueryClient();
  const comparisonCreation = useMutation({
    mutationFn: createOrUpdateComparison,
    onSuccess: (comparison) => {
      queryClient.invalidateQueries([QUERY_KEYS_ENUM.COMPARISONS, lotId]);
      onClose(comparison);
      setShouldAskToSaveOnOrNewComparison(Boolean(comparison?.id));
    },
  });
  const [
    shouldAskToSaveOnOrNewComparison,
    setShouldAskToSaveOnOrNewComparison,
  ] = useState(false);
  const comparisonIdHasChanged = useHasChanged(comparison?.id);
  if (comparisonIdHasChanged) {
    setShouldAskToSaveOnOrNewComparison(Boolean(comparison?.id));
  }

  return (
    <DefaultModal
      show={show}
      title={t("title")}
      handleClose={() => onClose()}
      staticBackdrop
    >
      <Form
        onSubmit={handleSubmit((formInputs) => {
          const { name, description } = formInputs;
          const savedComparison = {
            id: shouldAskToSaveOnOrNewComparison ? comparison?.id : undefined,
            name: shouldAskToSaveOnOrNewComparison ? undefined : name,
            description: shouldAskToSaveOnOrNewComparison
              ? undefined
              : description,
            creation_date: toISODate(new Date()),
            allotment: lotId,
            price_estimate: estimate?.id,
            price_breakdowns: tenders.map(({ id }) => id),
            settings: {
              columns: columnDisplaySettings,
              difference: differenceSettings,
              price_breakdowns_sort_mode: tendersCompanySortMode,
            },
          };

          comparisonCreation.mutate(savedComparison);
        })}
        className="pb-4"
      >
        {shouldAskToSaveOnOrNewComparison ? (
          <OverwriteOrSaveAsNew
            t={t}
            isLoading={comparisonCreation.isLoading}
            setShouldAskToSaveOnOrNew={setShouldAskToSaveOnOrNewComparison}
          />
        ) : (
          <ComparisonForm
            register={register}
            errors={errors}
            comparisonCreation={comparisonCreation}
          />
        )}
      </Form>
    </DefaultModal>
  );
}

export interface ISavedComparisonEditModalProps {
  show: boolean;
  handleClose: (comparison?: IComparisonDTO) => void;
  comparison?: IComparisonDTO;
}

export function SavedComparisonEditModal({
  show,
  handleClose,
  comparison,
  backErrors,
}: ISavedComparisonEditModalProps & {
  backErrors?: IBackErrors<TSavingFormInputs>;
}) {
  const { t } = useTranslation("SavedComparisonEditModal");
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TSavingFormInputs>({
    resolver: yupResolver(schema),
    values: comparison,
    mode: "onSubmit",
  });
  const onClose = (comparison?: IComparisonDTO) => {
    handleClose(comparison);
  };
  const { lotId } = useParams();
  useBackFieldErrors(backErrors, setError, clearErrors);
  const queryClient = useQueryClient();
  const comparisonCreation = useMutation({
    mutationFn: createOrUpdateComparison,
    onSuccess: (comparison) => {
      queryClient.invalidateQueries(["comparisons", lotId]);
      onClose(comparison);
    },
  });

  return (
    <DefaultModal
      show={show}
      title={t("title")}
      handleClose={() => onClose()}
      staticBackdrop
    >
      <Form
        onSubmit={handleSubmit((formInputs) => {
          const { name, description } = formInputs;
          const savedComparison = {
            id: comparison?.id,
            name,
            description,
          };
          comparisonCreation.mutate(savedComparison);
        })}
        className="pb-4"
      >
        <ComparisonForm
          register={register}
          errors={errors}
          comparisonCreation={comparisonCreation}
        />
      </Form>
    </DefaultModal>
  );
}
