import { CellContext } from "@tanstack/react-table";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { ICompareRow } from "../compare/CompareTable/CompareTable.types";
import { useTranslation } from "../shared/i18n";
import { IOption } from "../shared/inputs/select";
import { IPriceEstimate } from "../shared/types/estimate.types";
import { IAttribution } from "../shared/types/priceNode.types";
import { IQuantityStd } from "../shared/types/rowInstruction.types";
import { ITender } from "../shared/types/tender.types";
import { accessNested } from "../shared/utils";

import { splitCellId } from "./MappingUtils";
import { StandardCell, cellRowToStandardOption } from "./StandardCell";
import { IInstructions, IMappingPriceNode } from "./mapping.types";

const accessorKeyBase = "quantity";
const accessorKeyStd = "quantity_std";

export function StandardQuantityCell({
  props,
  estimate,
  tenders,
  setInstructions,
}: {
  props: CellContext<ICompareRow, IAttribution>;
  estimate: IPriceEstimate | undefined;
  tenders: ITender[];
  setInstructions?: Dispatch<SetStateAction<IInstructions>>;
}) {
  const [, rerender] = useState({});

  const accessor = splitCellId(props.cell.id);
  const cell = accessNested(props.row.original, accessor);

  const cellRow_number = cell?.row_number;
  const stdValue = cell?.quantity_std;
  const baseValue = cell?.quantity;
  const formula = stdValue?.[0]?.formula;

  const options = useMemo(() => {
    const options = [];
    // create option for the formula to be displayed
    if (formula) {
      options.push({
        value: formula,
        label: formula,
      });
    }
    options.push(
      ...props.row.subRows
        .map((row) => {
          const cell = accessNested(row.original, accessor);
          if (
            !cell ||
            (cell?.[accessorKeyStd] ?? cell?.[accessorKeyBase]) === undefined
          ) {
            return undefined;
          }
          return cellRowToStandardOption(
            row.original,
            accessorKeyStd,
            cell[accessorKeyStd],
            cell[accessorKeyBase],
            cellRow_number
          );
        })
        .filter((v): v is IOption => v !== undefined)
    );

    return options;
  }, [formula, props.row.subRows, accessor, cellRow_number]);

  const onChange = useCallback(
    (v: IQuantityStd) => {
      if (cell) {
        cell.quantity_std = v;
        rerender({});
      }
    },
    [cell]
  );

  const [type, id] = accessor.split(".");
  const document =
    type === "estimate" ? estimate : tenders.find((tender) => tender.id === id);
  const work_id = document?.mapping?.works?.[0].work_id;

  return (
    <StandardCell
      props={props}
      work_id={work_id!}
      setInstructions={setInstructions}
      accessorKeyStd={accessorKeyStd}
      placeholder="standard-quantity"
      cellRow_number={cellRow_number}
      stdValue={stdValue}
      baseValue={baseValue}
      onChange={onChange}
      options={options.length >= (formula ? 2 : 1) ? options : []}
      instructionAccessor={accessor}
    />
  );
}

export function StandardQuantityCellClassification({
  props,
  setInstructions,
  work_id,
  baseAccessor,
}: {
  props: CellContext<any, IAttribution>;
  setInstructions?: Dispatch<SetStateAction<IInstructions>>;
  work_id?: string;
  baseAccessor?: string;
}) {
  const [, rerender] = useState({});
  const { t } = useTranslation("MappingToolPage");

  const cell: IMappingPriceNode = props.row.original;
  const instructionAccessor =
    props.row.original.childs !== undefined
      ? props.row.original.id
      : `${baseAccessor}.${props.row.original.ref.replaceAll(".", "_")}`;

  const stdValue = cell?.quantity_std;
  const baseValue = cell?.quantity;
  const formula = stdValue?.[0]?.formula;

  const options = useMemo(() => {
    const options = [];
    // create option for the formula to be displayed
    if (formula) {
      options.push({
        value: formula,
        label: formula,
      });
    }
    //add options for document rows
    if (cell.childs) {
      options.push(
        ...cell.childs
          .map((cell) => {
            const cellRow_number = cell.row_number ?? cell.type;
            if (
              !cell ||
              (cell?.[accessorKeyStd] ?? cell?.[accessorKeyBase]) === undefined
            ) {
              return undefined;
            }
            return cellRowToStandardOption(
              cell as unknown as ICompareRow,
              accessorKeyStd,
              cell[accessorKeyStd],
              cell[accessorKeyBase],
              cellRow_number
            );
          })
          .filter((v): v is IOption => v !== undefined)
      );
    }
    // add options for classification rows
    else if (cell.children && cell.unit) {
      options.push(
        ...Object.values(cell.children)
          .filter((cell) => cell.quantity ?? cell.quantity_std)
          .map((cell) => {
            const cellRow_number = cell.row_number ?? cell.type;
            return cellRowToStandardOption(
              cell as unknown as ICompareRow,
              accessorKeyStd,
              cell[accessorKeyStd],
              cell[accessorKeyBase],
              cellRow_number
            );
          })
      );
    }

    return options;
  }, [formula, cell]);

  const onChange = useCallback(
    (v: IQuantityStd[]) => {
      if (cell) {
        cell.quantity_std = v;
        rerender({});
      }
    },
    [cell]
  );
  const tooltipMessageOnEmptyPlaceholder = !props.row.original.unit
    ? t("quantity setting unavailable due to missing unit")
    : t("quantity setting unavailable, no children are available");

  const cellRow_number = cell.row_number ?? cell.type;
  return (
    <StandardCell
      props={props}
      work_id={work_id!}
      setInstructions={setInstructions}
      accessorKeyStd={accessorKeyStd}
      placeholder="standard-quantity"
      cellRow_number={cellRow_number}
      stdValue={stdValue}
      baseValue={baseValue}
      onChange={onChange}
      options={options.length >= (formula ? 2 : 1) ? options : []}
      instructionAccessor={instructionAccessor}
      tooltipMessageOnEmptyPlaceholder={tooltipMessageOnEmptyPlaceholder}
    />
  );
}
