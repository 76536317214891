import classNames from "classnames";
import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { MdFormatIndentIncrease, MdMoveDown, MdMoveUp } from "react-icons/md";

import { useTranslation } from "../../shared/i18n";
import { ROW_INSTRUCTIONS_ENUM } from "../../shared/types/rowInstruction.types";

import styles from "./MoveActionContextMenu.module.scss";

enum MOVE_ACTION_TYPES_ENUM {
  BEFORE = "BEFORE",
  AFTER = "AFTER",
  PARENT = "PARENT",
}
function getRowInstructionTypeFromMoveActionType(
  action: MOVE_ACTION_TYPES_ENUM,
  targetRowHasTender: boolean
): ROW_INSTRUCTIONS_ENUM {
  if (targetRowHasTender) {
    switch (action) {
      case MOVE_ACTION_TYPES_ENUM.BEFORE:
        return ROW_INSTRUCTIONS_ENUM.MOVE_INTRINSIC_BEFORE;
      case MOVE_ACTION_TYPES_ENUM.AFTER:
        return ROW_INSTRUCTIONS_ENUM.MOVE_INTRINSIC_AFTER;
      case MOVE_ACTION_TYPES_ENUM.PARENT:
        return ROW_INSTRUCTIONS_ENUM.PARENT_INTRINSIC;
    }
  } else {
    switch (action) {
      case MOVE_ACTION_TYPES_ENUM.BEFORE:
        return ROW_INSTRUCTIONS_ENUM.MOVE_EXTERNAL_BEFORE;
      case MOVE_ACTION_TYPES_ENUM.AFTER:
        return ROW_INSTRUCTIONS_ENUM.MOVE_EXTERNAL_AFTER;
      case MOVE_ACTION_TYPES_ENUM.PARENT:
        return ROW_INSTRUCTIONS_ENUM.PARENT_EXTERNAL;
    }
  }
}
const moveActionList = [
  {
    action: MOVE_ACTION_TYPES_ENUM.BEFORE,
    Icon: MdMoveUp,
  },
  {
    action: MOVE_ACTION_TYPES_ENUM.PARENT,
    Icon: MdFormatIndentIncrease,
  },
  {
    action: MOVE_ACTION_TYPES_ENUM.AFTER,
    Icon: MdMoveDown,
  },
];

export function MoveActionContextMenu({
  coordinates,
  onClose,
  onActionClick,
  targetRowHasTender,
}: {
  coordinates: { x: number; y: number } | undefined;
  onClose(): void;
  onActionClick(action: ROW_INSTRUCTIONS_ENUM): void;
  targetRowHasTender: boolean;
}) {
  const { t } = useTranslation("MoveActionContextMenu");
  return coordinates !== undefined ? (
    <div
      className={classNames(
        "overflow-hidden min-vh-100 min-vw-100 bg-black bg-opacity-50 position-fixed top-0 start-0",
        styles["move-action-context-menu"]
      )}
      onClick={onClose}
    >
      <ListGroup
        className={classNames(
          "position-fixed  translate-middle-y",
          styles["move-action-context-menu"]
        )}
        style={{
          top: coordinates.y,
          left: coordinates.x,
        }}
      >
        {moveActionList.map(({ action, Icon }) => (
          <ListGroup.Item
            key={action}
            action
            onClick={(event) => {
              onActionClick(
                getRowInstructionTypeFromMoveActionType(
                  action,
                  targetRowHasTender
                )
              );
            }}
          >
            <Icon className="me-2" />
            {t(action)}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  ) : null;
}
