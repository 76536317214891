import { useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { DocumentExplorer } from "../documents";
import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { useTranslation } from "../shared/i18n";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { useTendersQuery } from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";

export function LotDocumentsPage() {
  const { t } = useTranslation("DocumentsPage");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { operationId, lotId } = useParams();

  useTrackPageView(
    "LotDocuments",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
      {
        id: CUSTOM_DIMENSIONS_ENUM.LOT_ID,
        value: String(lotId),
      },
    ],
    [operationId, lotId]
  );

  const tendersQuery = useTendersQuery(lotId);

  const isLoading =
    tendersQuery.isLoading ||
    (tendersQuery.isFetching && !tendersQuery.isPreviousData);

  const companies = tendersQuery.data;

  const documents = useMemo(() => {
    return [
      { file_name: t("MOE"), id: "MOE" },
      ...(companies?.map((company) => ({
        file_name: company.name,
        id: company.id,
      })) || []),
    ];
  }, [companies, t]);

  return (
    <DocumentExplorer
      onFolderClick={(id: string, event) =>
        event.ctrlKey
          ? window.open(
              `./${PATH_NAMES_ENUM.DOCUMENTS}/${id}?${searchParams.toString()}`
            )
          : navigate({ pathname: id, search: searchParams.toString() })
      }
      isLoading={isLoading}
      documents={documents}
    />
  );
}
