import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { DocumentExplorer } from "../documents";
import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { useTranslation } from "../shared/i18n";
import { IDocument, getVersions } from "../shared/types/document.types";
import {
  useAttachmentsQuery,
  useEstimatesQuery,
} from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";

export function MOEDocumentsPage() {
  const { t } = useTranslation("DocumentsPage");

  const { operationId, lotId } = useParams();

  useTrackPageView(
    "MOEDocuments",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
      {
        id: CUSTOM_DIMENSIONS_ENUM.LOT_ID,
        value: String(lotId),
      },
    ],
    [operationId, lotId]
  );

  const attachments = useAttachmentsQuery(lotId).data;

  const priceEstimatesQuery = useEstimatesQuery(lotId);
  const documents = priceEstimatesQuery.data?.results;

  const documentsVersioned = useMemo(() => {
    return [
      ...getVersions(documents as IDocument[] | undefined),
      ...getVersions(attachments),
    ];
  }, [documents, attachments]);

  const isLoading =
    priceEstimatesQuery.isLoading ||
    (priceEstimatesQuery.isFetching && !priceEstimatesQuery.isPreviousData);

  return (
    <DocumentExplorer
      isLoading={isLoading}
      documents={documentsVersioned}
      companyName={t("MOE")}
    />
  );
}
