import {
  ILocation,
  ILocationDTO,
  locationDTOToLocationMapper,
} from "./location.types";
import { BUILDING_CATEGORY, WORK_TYPE_ENUM } from "./operation.types";

export interface IWorkDTO extends ILocationDTO {
  id: string;
  name: string;
  type: WORK_TYPE_ENUM;
  building_category: BUILDING_CATEGORY;
  floor_area: string;
}

export interface IWork extends ILocation {
  id: string;
  name: string;
  type: WORK_TYPE_ENUM;
  building_category: BUILDING_CATEGORY;
  floor_area: string;
}

export function workDTOtoWorkMapper(work: IWorkDTO): IWork {
  const { id, name, type, building_category, floor_area, ...location } = work;
  return {
    id,
    name,
    type,
    building_category,
    floor_area,
    ...locationDTOToLocationMapper(location),
  };
}
