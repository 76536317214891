import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Accept } from "react-dropzone";

import variables from "../../_exports.module.scss";
import { useTranslation } from "../../i18n";
import { AddCircledIcon, ProgressCircleIcon } from "../../icons";
import { usePrevious } from "../../usePrevious";
import { cancelEvent } from "../../utils";

import { FileCard } from "./FileCard";
import { ImportFiles } from "./ImportFiles";
import styles from "./ImportFilesArea.module.scss";

export interface IImportFilesButtonProps {
  multiple?: boolean;
  onlyShowButton?: boolean;
  onData?: Function;
  onDrop?: Function;
  onRemove?: (file: File) => void;
  className?: string;
  initialData?: File[];
  buttonText: string;
  canEdit?: boolean;
  accept?: Accept;
  disabled?: boolean;
  percentage?: number;
}

export function ImportFilesButton({
  multiple = false,
  onlyShowButton = false,
  onData,
  onDrop,
  onRemove,
  className,
  initialData,
  buttonText,
  canEdit,
  accept,
  disabled,
  percentage,
}: IImportFilesButtonProps) {
  const { t } = useTranslation("ImportFilesArea");
  const [files, setFiles] = useState<File[] | undefined>(initialData || []);
  const [dragActive, setDragActive] = useState<boolean>(false);
  // use Ref in order to change value instantly
  const fileToEdit = useRef<File>();

  const prevFiles = usePrevious(files);

  const removeFile = (file: File) => {
    onRemove?.(file);
    setFiles((files) => files?.filter((f) => f !== file));
  };

  useEffect(() => {
    files !== initialData && files !== prevFiles && onData?.(files);
  }, [initialData, onData, files, prevFiles]);

  const replaceFile = useCallback((file: File) => {
    setFiles([file]);
  }, []);

  const onDropHandler = useCallback(
    async (acceptedFiles: File[]) => {
      if (onDrop) {
        acceptedFiles = await onDrop?.(acceptedFiles);
      }
      if (fileToEdit.current) {
        const file = acceptedFiles[0];
        setFiles((files) => {
          const index = files?.findIndex((f: File) => f === fileToEdit.current);
          fileToEdit.current = undefined;
          if (index === undefined || !files?.length) {
            return [file];
          } else if (index === -1) {
            return [...files, file];
          } else {
            files[index] = file;
            return [...files];
          }
        });
      } else {
        setFiles((files) =>
          multiple
            ? [...(files || []), ...acceptedFiles]
            : acceptedFiles.length
            ? [acceptedFiles[0]]
            : []
        );
      }
    },
    [multiple, setFiles, fileToEdit, onDrop]
  );

  return (
    <>
      <ImportFiles
        multiple
        accept={accept}
        onDrop={onDropHandler}
        onDrag={setDragActive}
        onFileDialogCancel={() => (fileToEdit.current = undefined)}
        disabled={disabled}
      >
        <div
          className={classNames(className, "py-1", {
            [styles.animatedBorder]: dragActive,
          })}
        >
          <Container className="px-0">
            {!onlyShowButton && multiple && (
              <DisplayFileList
                files={files}
                canEdit={canEdit}
                removeFile={removeFile}
                editFile={(file) => (fileToEdit.current = file)}
              />
            )}
            <Row className="justify-content-center">
              {!onlyShowButton && !multiple && files?.length ? (
                <Col>
                  <FileCard
                    file={files[0]}
                    canEdit={canEdit}
                    onDelete={removeFile}
                    onEdit={replaceFile}
                    canDelete={false}
                    iconColSpan={2}
                    nameColSpan={8}
                  />
                </Col>
              ) : (
                <Col className="ms-1 pe-5">
                  <Button
                    variant="outline-blue"
                    className="w-100"
                    disabled={disabled}
                  >
                    {percentage !== undefined ? (
                      <>
                        <Row className="justify-content-center align-items-center">
                          <Col xs="auto">
                            <ProgressCircleIcon
                              percentage={percentage}
                              size={24}
                            />
                          </Col>
                          <Col>
                            {t("downloadingFiles")} {Math.floor(percentage)}%
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <AddCircledIcon
                          className="me-3"
                          fill={variables.blue400}
                        />
                        {buttonText}
                      </>
                    )}
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </ImportFiles>
    </>
  );
}

export interface IDisplayFileListProps {
  files?: File[];
  removeFile?: (file: File) => void;
  editFile?: (file: File) => void;
  canEdit?: boolean;
}

export function DisplayFileList({
  files,
  removeFile,
  editFile,
  canEdit,
}: IDisplayFileListProps) {
  return (
    <>
      <Row>
        <Col
          onClick={(e) =>
            // stop the event from going to the dropzone and trigger the file Dialog
            editFile && cancelEvent(e)
          }
        >
          {files?.map((file, index) => (
            <FileCard
              file={file}
              key={file.name + index}
              onDelete={removeFile}
              onEdit={editFile}
              canEdit={canEdit}
              iconColSpan={2}
              nameColSpan={6}
            />
          ))}
        </Col>
      </Row>
      {Boolean(files?.length) && (
        <Row>
          <hr className="my-2" />
        </Row>
      )}
    </>
  );
}
