import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { DefaultModal } from "../DefaultModal";
import { useTranslation } from "../i18n";

export interface IConfirmationModal {
  show?: boolean;
  title: string;
  confirm?: string;
  handleClose: (confirm: boolean | void) => void;
  isSubmitting?: boolean;
}

export function ConfirmationModal({
  show = true,
  handleClose,
  title,
  confirm,
  isSubmitting,
}: IConfirmationModal) {
  const { t } = useTranslation("ConfirmationModal");
  return (
    <DefaultModal
      title={title}
      show={show}
      handleClose={() => handleClose(false)}
      footer={
        <Button onClick={() => handleClose(true)} disabled={isSubmitting}>
          {confirm ?? t("confirm")}
          {isSubmitting && <Spinner size="sm" className="ms-2" />}
        </Button>
      }
    />
  );
}
