import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { RootError } from "../shared/errors";
import { IBackErrors, useBackFieldErrors } from "../shared/errors";
import { useTranslation, yupLocale } from "../shared/i18n";
import { Input, getInputProps } from "../shared/inputs";
import { INPUT_TYPES_ENUM } from "../shared/inputs/input";

export interface ITenderFormProps {
  defaultValues?: TTenderFormInputs;
  onData: Function;
  onSubmit: SubmitHandler<TTenderFormInputs>;
  hideRequiredMention?: boolean;
}

export type TTenderFormInputs = yup.InferType<typeof schema>;

export function isFormValid(tenderForm?: TTenderFormInputs) {
  return schema.isValidSync(tenderForm);
}

yup.setLocale(yupLocale);
const schema = yup.object({
  name: yup.string().max(128).required(),
  email: yup.string().email(),
  location_city: yup.string().required(),
  location_postcode: yup.string().required(),
});

export function TenderForm({
  onData,
  onSubmit,
  defaultValues,
  hideRequiredMention,
  backErrors,
}: ITenderFormProps & { backErrors?: IBackErrors<TTenderFormInputs> }) {
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TTenderFormInputs>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const { t } = useTranslation("TenderForm");
  useBackFieldErrors(backErrors, setError, clearErrors);

  return (
    <>
      {!hideRequiredMention && (
        <div className="text-info mb-4">{t("required fields*")}</div>
      )}
      <Form
        onChange={() => onData(getValues())}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row className="mb-4">
          <Input
            as={Col}
            {...getInputProps("name", register, schema, errors)}
            label={t("company name")}
            placeholder={t("company name")}
          />
          <Input
            as={Col}
            {...getInputProps("email", register, schema, errors)}
            label={t("email")}
            placeholder={t("email")}
            type={INPUT_TYPES_ENUM.EMAIL}
          />
        </Row>
        <Row className="mb-4">
          <Input
            as={Col}
            {...getInputProps("location_city", register, schema, errors)}
            label={t("tender city")}
            placeholder={t("tender city")}
          />
          <Input
            as={Col}
            {...getInputProps("location_postcode", register, schema, errors)}
            label={t("postCode")}
            placeholder={t("postCode")}
          />
        </Row>
        <RootError errors={errors} />
        <Button type="submit" className="d-none"></Button>
      </Form>
    </>
  );
}
