import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { useQueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { ReactElement } from "react";

export function PersistentQueryClientProvider({
  children,
}: {
  children?: ReactElement;
}) {
  const queryClient = useQueryClient();

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  return (
    <>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        {children}
      </PersistQueryClientProvider>
    </>
  );
}
