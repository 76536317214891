import { Select } from ".";
import React from "react";
import { ControllerRenderProps } from "react-hook-form";

import { useEnumTranslation, useTranslation } from "../i18n";
import { LOT_CLOSURE_VOICE_DATA_VIDEO_NETWORK_ENUM } from "../types/lot.types";

export const VoiceVideoDataNetworkSelect = React.forwardRef<
  HTMLInputElement,
  ControllerRenderProps & {
    placeholder?: string;
  }
>(function VoiceVideoDataNetworkSelect({ value, ...field }, ref) {
  const { tEnum } = useEnumTranslation();
  const { t } = useTranslation("VoiceVideoDataNetworkSelect");

  const options = Object.values(LOT_CLOSURE_VOICE_DATA_VIDEO_NETWORK_ENUM).map(
    (value) => ({
      value,
      label: tEnum(value),
    })
  );

  return (
    <Select
      {...field}
      value={value}
      ref={ref}
      options={options}
      searchable
      placeholder={t("select voice video data network")}
    />
  );
});
