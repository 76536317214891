import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { getSetSearchParamsCallbackWithLastUpdatedSearchParams } from "../api/APIUtils";
import { useComparisonQuery } from "../shared/useSharedQueries";

export const SAVED_COMPARISON_SEARCH_PARAM = "comparison";

export function useSavedComparison() {
  const [searchParams, setSearchParams] = useSearchParams();

  const savedComparisonId = searchParams.get(SAVED_COMPARISON_SEARCH_PARAM);

  const savedComparisonQuery = useComparisonQuery(savedComparisonId);

  const setSavedComparison = useCallback(
    (comparisonId: string) =>
      setSearchParams(
        getSetSearchParamsCallbackWithLastUpdatedSearchParams(
          (searchParams) => {
            searchParams.set(SAVED_COMPARISON_SEARCH_PARAM, comparisonId);
            return searchParams;
          }
        ),
        { replace: true }
      ),
    [setSearchParams]
  );

  return {
    savedComparison: savedComparisonQuery.data,
    setSavedComparison,
    isLoading: Boolean(savedComparisonId && savedComparisonQuery.isLoading),
  };
}
