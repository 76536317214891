import classnames from "classnames";
import React, { ReactNode } from "react";

import { CheckBox } from "../checkbox";

import { IOption } from "./select.types";

export interface IOptionProps {
  option: IOption;
  formatOptionLabel?: (option: IOption) => ReactNode;
  onSelect: (option: IOption) => void;
  onDeselect: (option: IOption) => void;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onMouseEnter: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onFocus?: (event: React.FocusEvent<HTMLLIElement>) => void;
  multi: boolean;
  selection: IOption[];
  className: string;
}
export function Option({
  option,
  formatOptionLabel,
  onSelect = () => {},
  onDeselect = () => {},
  onClick = () => {},
  multi,
  selection,
  className,
  ...props
}: IOptionProps) {
  const selected = selection.some(({ value }) => value === option.value);
  const onToggleSelect = () =>
    selected ? onDeselect(option) : onSelect(option);
  return (
    <li
      {...props}
      role="button"
      key={option.value}
      className={classnames("select-option", { selected }, className, {
        disabled: option.disabled,
      })}
      onClick={(event) => {
        if (!option.disabled) {
          event.stopPropagation();
          onClick(event);
          onToggleSelect();
        }
      }}
      tabIndex={0}
    >
      <div className="w-100 h-100">
        {multi && (
          <CheckBox
            className="me-2 d-inline"
            checked={Boolean(selected)}
            disabled={option.disabled}
          />
        )}
        {formatOptionLabel
          ? formatOptionLabel(option)
          : option.label || option.value}
      </div>
    </li>
  );
}
