import {
  Cell,
  CellErrorValue,
  CellFormulaValue,
  CellRichTextValue,
  CellSharedFormulaValue,
  FillPattern,
  RichText,
  Style,
  Workbook,
} from "exceljs";

import { TCell } from "../types/lot.types";

function extractRichText(richText?: any[]) {
  if (!richText) return undefined;
  return richText.map((txt) => txt.text).join(" ");
}

function extractFormula(cell: CellFormulaValue) {
  return cell?.formula
    ? extractError(cell.result) ?? cell.result ?? `=${cell.formula}`
    : undefined;
}

function extractSharedFormula(cell: CellSharedFormulaValue) {
  return cell?.sharedFormula
    ? extractError(cell.result) ?? cell.result ?? `=${cell.sharedFormula}`
    : undefined;
}

function extractError(cell: { error: CellErrorValue } | any) {
  return cell?.error ? cell.error : undefined;
}

// Transform a Cell Object to its string representation
function cellToString(cell: Cell) {
  return (
    extractError((cell.value as CellFormulaValue)?.result) ??
    extractFormula(cell.value as CellFormulaValue) ??
    extractRichText((cell.value as CellRichTextValue)?.richText) ??
    extractSharedFormula(cell.value as CellSharedFormulaValue) ??
    cell.value?.toString()
  );
}

type ITextCell = RichText & { fill?: any; numFmt?: string };

function cellToJSON(cell: Cell) {
  if (
    (cell.value as CellFormulaValue)?.result !== undefined ||
    (cell.value as CellFormulaValue)?.formula !== undefined ||
    (cell.value as CellSharedFormulaValue)?.sharedFormula !== undefined
  ) {
    return cell.value;
  } else if ((cell.value as CellRichTextValue)?.richText !== undefined) {
    applyIndentToRichText(cell, (cell.value as CellRichTextValue)?.richText);
    return cell.value;
  } else {
    if (!isNaN(cell.value as number)) {
      return cell.value;
    }
    if (cell.font || cell.fill || cell.numFmt) {
      const richText: ITextCell[] = [{ text: String(cell.value) }];
      if (cell.font) {
        richText[0].font = cell.font;
      }
      if (cell.fill && (cell.fill as FillPattern).pattern !== "none") {
        richText[0].fill = cell.fill;
      }
      if (cell.numFmt) {
        richText[0].numFmt = cell.numFmt;
      }
      if (richText[0].font || richText[0].fill || richText[0].numFmt) {
        applyIndentToRichText(cell, richText);
        return { richText };
      }
    } else {
      return cell.value;
    }
  }
}

/** the indent has significance for some cells, so we add it to richText[0].style.alignment.indent */
function applyIndentToRichText(
  cell: Cell,
  richText: (RichText & { style?: Partial<Style> })[]
) {
  // only add indent if it is != 0
  if (cell.style.alignment?.indent) {
    richText[0].style = { alignment: { indent: cell.style.alignment.indent } };
  }
}

interface IPrivateWorksheet {
  _rows: {
    _cells: any[];
  }[];
}

/**
 * Retrieve the last non empty row index.
 * A row is considered non empty if any of its cells has a value.
 */
function getLastNonEmptyRowIndex(sheet: IPrivateWorksheet) {
  return sheet._rows.findLastIndex((row) => {
    return row?._cells?.some((cell) => cell?.value != null);
  });
}

export async function loadExcel(fileData: Buffer) {
  var workbook = new Workbook();
  await workbook.xlsx.load(fileData);
  const sheets: { [key: string]: () => TCell[][] } = {};
  const rawSheets: { [key: string]: () => any[][] } = {};
  let maxColumnNumber = 0;

  workbook.eachSheet((sheet) => {
    let rawRows: any[][];
    let rows: TCell[][];
    const compute = (): true => {
      if (rawRows) return true;
      const numberOfRows = Math.max(
        0,
        getLastNonEmptyRowIndex(sheet as unknown as IPrivateWorksheet)
      );
      rawRows = Array(numberOfRows).fill([]);
      rows = Array(numberOfRows).fill([]);
      sheet.eachRow((row, rowIndex) => {
        const rawCells: any[] = [];
        const cells: TCell[] = [];
        row.eachCell((cell, colNumber: number) => {
          if (!cell.model.master) {
            cells[colNumber - 1] = cellToString(cell);
            rawCells[colNumber - 1] = cellToJSON(cell);
          } else {
            cells[colNumber - 1] = null;
            rawCells[colNumber - 1] = null;
          }
          maxColumnNumber = Math.max(maxColumnNumber, colNumber);
        });
        rows[rowIndex - 1] = cells;
        rawRows[rowIndex - 1] = rawCells;
      });
      // make sure there is at least one row
      if (rows.length) {
        // make sure first line has the right length for headers
        for (let i = 0; i < maxColumnNumber; i++) {
          rows[0][i] = rows[0][i] || undefined;
          rawRows[0][i] = rawRows[0][i] || undefined;
        }
      }
      return true;
    };
    // parsing of the sheet has been postponed
    sheets[sheet.name] = () => compute() && rows;
    rawSheets[sheet.name] = () => compute() && rawRows;
  });
  console.timeEnd("loader");
  return { sheets, rawSheets };
}
