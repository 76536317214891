import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React, {
  ReactElement,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { decodeToken, isExpired } from "react-jwt";
import { Location, useLocation, useNavigate } from "react-router-dom";

import {
  login as APILogin,
  getUser,
  patchUser,
  refresh,
} from "../api/fetchUser";
import { SUPPORTED_LANGUAGES_ENUM, i18n } from "../host/i18n";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { IUser } from "../shared/types/account.types";

export const SEARCH_PARAM_REDIRECT = "redirect";

export interface IDecodedToken {
  exp: number;
  jti: "string";
  token_type: "access" | "refresh";
  user_id: string;
}

export const STORAGE_KEY_USER = "user";
export const STORAGE_KEY_ACCESS = "access";
export const STORAGE_KEY_REFRESH = "refresh";

export interface IUserContext {
  user: IUser | undefined;
  login: (email: string, password: string) => void;
  setToken: (token: string, refresh: string | null) => void;
  logout: () => void;
  changeLanguage: (language: SUPPORTED_LANGUAGES_ENUM) => void;
}

export const UserContext = createContext<IUserContext>({
  user: undefined,
  login: () => {},
  setToken: () => {},
  logout: () => {},
  changeLanguage: (language: string) => {},
});

export const getUserNameOrEmail = (user?: IUser) =>
  user?.first_name || user?.last_name
    ? `${user?.first_name ?? ""} ${user?.last_name ?? ""}`
    : user?.email ?? "";

export function redirectToLogin(navigate: Function, location: Location) {
  navigate(
    `/${PATH_NAMES_ENUM.LOGIN}?${SEARCH_PARAM_REDIRECT}=${encodeURIComponent(
      `${location.pathname}${location.search}${location.hash}`
    )}`
  );
}

const REFRESH_MARGIN_MS = 60000;
const watchToken = (token: IDecodedToken, callback: Function): number => {
  const refreshTime = token.exp * 1000 - Date.now() - REFRESH_MARGIN_MS;
  return setTimeout(callback, refreshTime);
};

export function UserProvider({ children }: { children?: ReactElement }) {
  const { trackEvent, pushInstruction } = useMatomo();

  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<IUser | undefined>();
  const token = useMemo<IDecodedToken | null>(() => {
    return decodeToken(user?.token || "");
  }, [user]);

  const applyUser = useCallback((user?: IUser) => {
    if (user) {
      localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(user));
    } else {
      localStorage.removeItem(STORAGE_KEY_USER);
    }
    setUser(user);
  }, []);

  const applyToken = useCallback(
    (token: { access: string; refresh?: string | null }) => {
      localStorage.setItem(STORAGE_KEY_ACCESS, token.access);
      if (token.refresh) {
        localStorage.setItem(STORAGE_KEY_REFRESH, token.refresh);
      }
      if (user) applyUser({ ...user, token: token.access });
    },
    [user, applyUser]
  );

  const logout = useCallback(() => {
    applyUser(undefined);
    trackEvent({ category: "user", action: "logout", name: "UserLogout" });
    localStorage.removeItem(STORAGE_KEY_USER);
    localStorage.removeItem(STORAGE_KEY_ACCESS);
    localStorage.removeItem(STORAGE_KEY_REFRESH);
    pushInstruction("setUserId", undefined);
    redirectToLogin(navigate, location);
    // clean cache from user's queries
  }, [applyUser, location, navigate, trackEvent, pushInstruction]);

  const changeLanguage = useCallback(
    (language: SUPPORTED_LANGUAGES_ENUM) => {
      i18n.changeLanguage(language);
      document.documentElement.setAttribute("lang", language);
      if (user) {
        user.language = language;
        if (language !== SUPPORTED_LANGUAGES_ENUM.DEBUG) {
          patchUser(user.id, { language });
        }
      }
    },
    [user]
  );

  const refreshAccessToken = useCallback(async () => {
    const refreshToken = localStorage.getItem(STORAGE_KEY_REFRESH);
    if (refreshToken && !isExpired(refreshToken)) {
      const token = await refresh(refreshToken);
      applyToken(token);
    } else {
      logout();
    }
  }, [applyToken, logout]);

  useEffect(() => {
    if (token) {
      const timeoutID = watchToken(token, refreshAccessToken);
      return () => clearTimeout(timeoutID);
    }
  }, [token, refreshAccessToken]);

  function loadUser() {
    const userJSON = localStorage.getItem(STORAGE_KEY_USER);
    if (userJSON) {
      try {
        const user = JSON.parse(userJSON);
        i18n.changeLanguage(user.language);
        document.documentElement.setAttribute("lang", user.language);
        return user;
      } catch (error) {
        console.error(error);
        return null;
      }
    } else {
      return null;
    }
  }
  useEffect(() => {
    setUser(loadUser());
  }, []);

  const login = useCallback(
    async (email: string, password: string) => {
      const token = await APILogin(email, password);
      applyToken(token);

      const decodedToken = decodeToken(token.access) as IDecodedToken;
      pushInstruction("setUserId", decodedToken.user_id);
      const user = await getUser(decodedToken.user_id, token.access);
      applyUser({
        ...user,
        id: decodedToken.user_id,
        token: token.access,
      });
    },
    [applyToken, applyUser, pushInstruction]
  );

  const setToken = useCallback(
    async (access: string, refresh: string | null) => {
      const token = {
        access,
        refresh,
      };
      applyToken(token);

      const decodedToken = decodeToken(token.access) as IDecodedToken;
      const user = await getUser(decodedToken.user_id, token.access);
      applyUser({
        ...user,
        id: decodedToken.user_id,
        token: token.access,
      });
    },
    [applyToken, applyUser]
  );

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        setToken,
        logout,
        changeLanguage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
