import classNames from "classnames";
import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { useTranslation } from "../shared/i18n";
import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
  CrossedCircleIcon,
} from "../shared/icons";
import { ifTest } from "../shared/utils";

export interface ILotsModalFooterProps {
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  /** callback to execute on click of next step button
   * @returns Promise<boolean>: true continue to next step, false cancel and stay
   */
  beforeNextStep?: () => Promise<boolean>;
  nextEnabled: boolean;
  documents?: File[];
  footerHasSpace?: boolean;
  optional?: boolean;
  isLoading?: boolean;
  hidePrevious?: boolean;
  isLastStep?: boolean;
  goNext(): void;
}

export function LotsModalFooter({
  selectedIndex,
  setSelectedIndex,
  beforeNextStep,
  nextEnabled,
  footerHasSpace,
  optional,
  isLoading = false,
  hidePrevious = false,
  isLastStep = false,
  goNext,
}: ILotsModalFooterProps) {
  const { t } = useTranslation("LotsModalFooter");

  return (
    <div
      className={classNames(
        footerHasSpace ? "justify-content-between" : "justify-content-center",
        "d-flex w-100 gap-2"
      )}
    >
      {selectedIndex > 0 && !hidePrevious && (
        <Button
          data-test={ifTest("previous-button")}
          size="lg"
          variant="light"
          onClick={() => setSelectedIndex((index) => index - 1)}
          className="rectangular transparent"
        >
          <ArrowSmallLeftIcon className="me-2" />
          {t("previous")}
        </Button>
      )}
      {!optional ? (
        <Button
          data-test={ifTest("next-button")}
          size="lg"
          variant="primary"
          onClick={goNext}
          className="rectangular"
          disabled={!nextEnabled || isLoading}
        >
          {t(isLastStep ? "next" : "finish")}
          {isLoading ? (
            <Spinner size="sm" className="ms-2" />
          ) : (
            <ArrowSmallRightIcon className="ms-2" />
          )}
        </Button>
      ) : (
        <Button
          data-test={ifTest("next-button")}
          variant="text"
          onClick={async () => {
            if (beforeNextStep && !(await beforeNextStep())) {
              return;
            }
            setSelectedIndex((index) => index + 1);
          }}
        >
          <div className="my-3">{t("you don't have those documents?")}</div>
          <div className="my-2">
            <b>
              <u>{t("skip this step")}</u>
            </b>
            <CrossedCircleIcon className="mx-2" />
          </div>
        </Button>
      )}
    </div>
  );
}
