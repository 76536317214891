import { ICompany } from "../shared/types/company.types";
import { ILotPrice } from "../shared/types/lot.types";

export interface ILotChartData {
  id: string;
  name?: string;
  conventional_allotments: string[];
  reference_number: string;
  estimation_amount?: number;
  avg_estimation_amount: number;
  companies?: ICompany[];
  min_price_breakdowns?: ILotPrice[];
  best_price_breakdown?: ILotPrice;
}

export enum DATA_KEYS_ENUM {
  MOE = "estimation_amount",
  BEST = "best_price_breakdown_estimation_amount",
  MIN = "min_price_breakdown_estimation_amount",
  AVERAGE = "avg_estimation_amount",
}
