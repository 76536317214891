import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link, useParams } from "react-router-dom";

import { getLatestEstimate } from "../api/fetchEstimates";
import { deleteTender } from "../api/fetchTenders";
import {
  ESTIMATE_SEARCH_PARAM,
  TENDERS_SEARCH_PARAM,
} from "../compare/useEstimateAndSortedTendersFromSearchParamsOrInit";
import { useTranslation } from "../shared/i18n";
import { CompareIcon } from "../shared/icons";
import { ConfirmationModal } from "../shared/modals";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { Table } from "../shared/table";
import { ICompany, ICompanyDTO } from "../shared/types/company.types";
import { ITender } from "../shared/types/tender.types";
import { QUERY_KEYS_ENUM, useEstimatesQuery } from "../shared/useSharedQueries";

import { TenderCreationModal } from "./TenderCreationModal";
import { TenderEditionModal } from "./TenderEditionModal";
import { TenderVersionEditionModal } from "./TenderVersionEditionModal";
import { useTendersTableWithAutoSelectedLastVersion } from "./useTendersTableWithAutoSelectedLastVersion";

export interface ITendersTableProps {
  companies: ICompany[];
  estimation?: number;
  lowestEstimation?: number;
  isLoading?: boolean;
}

export function TendersTable({
  companies,
  estimation,
  lowestEstimation,
  isLoading,
}: ITendersTableProps) {
  const { t } = useTranslation("TendersTable");
  const { operationId, lotId } = useParams();

  const [addedTenderCompany, setAddedTenderCompany] = useState<ICompany>();
  const [editedTenderCompany, setEditedTenderCompany] = useState<ICompany>();
  const [versionEditedTender, setVersionEditedTender] = useState<ITender>();
  const [deletedTender, setDeletedTender] = useState<ITender>();

  const queryClient = useQueryClient();

  const tenderDeletion = useMutation({
    mutationFn: deleteTender,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.TENDERS, lotId],
      });
      setDeletedTender(undefined);
    },
  });

  const { table, onRowClick, selectedTenderIds } =
    useTendersTableWithAutoSelectedLastVersion({
      companies,
      estimation,
      lowestEstimation,
      onEditTenderVersion: setVersionEditedTender,
      onEditTender: setEditedTenderCompany,
      onDeleteTender: setDeletedTender,
      onAddNewTender: setAddedTenderCompany,
    });

  const latestEstimateQuery = useEstimatesQuery(lotId, {
    select: getLatestEstimate,
  });
  const latestEstimate = latestEstimateQuery.data;
  const latestEstimateId = latestEstimate?.id;
  const params: Record<string, string> = {
    [TENDERS_SEARCH_PARAM]: selectedTenderIds.toString(),
  };
  if (latestEstimateId !== undefined) {
    params[ESTIMATE_SEARCH_PARAM] = latestEstimateId;
  }

  const compareButton = (
    <Button size="lg">
      <CompareIcon className="mb-1 me-1" /> {t("compare tenders")}
    </Button>
  );
  return (
    <>
      <ConfirmationModal
        title={t("delete tender", {
          company: deletedTender?.company_name,
          version: deletedTender?.version,
        })}
        show={Boolean(deletedTender)}
        handleClose={(confirm: boolean | void) => {
          confirm
            ? tenderDeletion.mutate(deletedTender!.id)
            : setDeletedTender(undefined);
        }}
        isSubmitting={tenderDeletion.isLoading}
      />
      {Boolean(addedTenderCompany) && (
        <TenderCreationModal
          lot={lotId!}
          show
          handleClose={() => setAddedTenderCompany(undefined)}
          company={{ id: addedTenderCompany!.id } as ICompanyDTO}
          operationId={operationId!}
        />
      )}
      {Boolean(versionEditedTender) && (
        <TenderVersionEditionModal
          show
          handleClose={() => setVersionEditedTender(undefined)}
          tender={versionEditedTender!}
          lotId={lotId!}
        />
      )}
      {Boolean(editedTenderCompany) && (
        <TenderEditionModal
          show
          handleClose={() => setEditedTenderCompany(undefined)}
          company={editedTenderCompany!}
          lotId={lotId!}
        />
      )}
      <Row>
        <Table
          table={table}
          defaultTheme
          onRowClick={onRowClick}
          isLoading={isLoading}
          hover
          responsive
        />
      </Row>
      <Row>
        <Col className="text-center">
          <Link
            to={`../${PATH_NAMES_ENUM.COMPARE}?${new URLSearchParams(params)}`}
          >
            {compareButton}
          </Link>
        </Col>
      </Row>
    </>
  );
}
