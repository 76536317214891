import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Table } from "../../shared/table";
import { useTendersTable } from "../../shared/table/useTendersTable/useTendersTable";
import { ICompany } from "../../shared/types/company.types";
import { useTendersQuery } from "../../shared/useSharedQueries";
import { doArraysHaveDifferentValues } from "../../shared/utils";

const emptyCompanies: ICompany[] = [];
export function TenderSelectTable({
  onChange,
  controlledSelectedTenderIds,
}: {
  onChange: (tenderIds: string[]) => void;
  controlledSelectedTenderIds: string[];
}) {
  const { lotId } = useParams();
  const tendersQuery = useTendersQuery(lotId);
  const companies = tendersQuery.data ?? emptyCompanies;

  const { table, onRowClick, selectedTenderIds } = useTendersTable({
    companies,
    controlledSelectedTenderIds,
    disableExport: true,
  });

  useEffect(() => {
    if (
      tendersQuery.isFetched &&
      doArraysHaveDifferentValues(
        selectedTenderIds,
        controlledSelectedTenderIds
      )
    ) {
      onChange(selectedTenderIds);
    }
  }, [
    onChange,
    selectedTenderIds,
    controlledSelectedTenderIds,
    tendersQuery.isFetched,
  ]);

  return (
    <Table
      table={table}
      onRowClick={onRowClick}
      isLoading={tendersQuery.isLoading}
      defaultTheme
      hover
      responsive
    />
  );
}
