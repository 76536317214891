import { compareDesc, isBefore, parseISO } from "date-fns";

import { toISODate } from "../../api/APIUtils";

import { ICompany } from "./company.types";
import { IDocument, IDocumentDTO, IDocumentData } from "./document.types";
import { GEO_FEATURE_TYPES_ENUM } from "./location.types";
import {
  IMappedPriceAverage,
  mappingDTOToMappingMapper,
  mappingToMappingDTOMapper,
} from "./mapping.types";

export interface ITenderPercentageDTO {
  id: string;
  allotment__project__name: string;
  company__name: string;
  allotment__project: string;
  row_type_mapping_percentage?: number;
  allotment: string;
  allotment__name: string;
  allotment__closure_date?: string;
}

export interface ITenderPercentage
  extends Omit<ITenderPercentageDTO, "allotment__closure_date"> {
  allotment__closure_date?: Date;
}

export interface ITenderDTO extends IDocumentDTO {
  company: string;
  company_name: string;
  estimation_amount: number;
}

export interface ITender extends IDocumentData {
  company: string;
  company_name: string;
  estimation_amount: number;
}

export enum COMPANY_SORT_MODE {
  ESTIMATION_AMOUNT = "sortCompaniesFromEstimationAmount",
  NAME_ASC = "sortCompaniesByNameAsc",
  FIRST_UPLOADED_TENDER = "sortCompaniesFromFirstUploadedTender",
}

export const DEFAULT_COMPANY_SORT_MODE = COMPANY_SORT_MODE.ESTIMATION_AMOUNT;

export const companySortFunctionBySortMode = Object.freeze({
  [COMPANY_SORT_MODE.ESTIMATION_AMOUNT]: sortCompaniesFromEstimationAmount,
  [COMPANY_SORT_MODE.NAME_ASC]: sortCompaniesByNameAsc,
  [COMPANY_SORT_MODE.FIRST_UPLOADED_TENDER]:
    sortCompaniesFromFirstUploadedTender,
});

export const defaultCompanySortFn =
  companySortFunctionBySortMode[DEFAULT_COMPANY_SORT_MODE];

export function isTender(document: IDocument): document is ITender {
  return (document as ITender)?.company !== undefined;
}

export function areTenders(documents: IDocument[]): documents is ITender[] {
  return documents.every((document) => isTender(document));
}

export function tenderDTOToTenderMapper(tender: ITenderDTO): ITender {
  return {
    ...tender,
    upload_date: parseISO(tender.upload_date),
    estimation_amount: Number(tender.estimation_amount),
    mapping: mappingDTOToMappingMapper(tender.mapping),
  };
}

export function partialTenderToTenderDTOMapper(
  tender: Partial<ITender>
): Partial<ITenderDTO> {
  return {
    ...tender,
    upload_date: toISODate(tender.upload_date),
    estimation_amount: tender.estimation_amount,
    mapping: mappingToMappingDTOMapper(tender.mapping),
  };
}

export function tendersDTOtoCompaniesMapper(
  tendersDTO: ITenderDTO[]
): ICompany[] {
  const tenders = tendersDTO.map(tenderDTOToTenderMapper);
  return tendersToCompaniesMapper(tenders);
}

export function tendersToCompaniesMapper(tenders: ITender[]): ICompany[] {
  const companies: { [x: string]: ICompany } = {};
  tenders.forEach((tender) => {
    addTenderToCompanyOrCreateCompany(companies, tender);
  });

  // sort tenders for each company by upload_date
  // set the company estimation_amount using the latest tender
  // sort companies by ascending estimation_amount (cheapest to most expensive)
  const companyList = defaultCompanySortFn(
    Object.values(companies).map(
      sortCompanyTendersAndAddEstimationAmountFromLastUploaded
    )
  );

  return companyList;
}

function addTenderToCompanyOrCreateCompany(
  companies: { [x: string]: ICompany },
  tender: ITender
) {
  if (!companies[tender.company]) {
    companies[tender.company] = {
      id: tender.company,
      name: tender.company_name,
      tenders: [] as ITender[],
      estimation_amount: 0,
      location_country: "",
      location_country_code: "",
      location_name: "",
      location_osm_id: -1,
      location_type: GEO_FEATURE_TYPES_ENUM.COUNTRY,
    };
  }
  companies[tender.company].tenders.push(tender);
}

function sortCompanyTendersAndAddEstimationAmountFromLastUploaded(
  company: ICompany
) {
  company.tenders.sort((a, b) => compareDesc(a.upload_date, b.upload_date));
  company.tenders.forEach((tender, index) => {
    tender.version = tender.version ?? company.tenders.length - index;
  });
  company.estimation_amount = Number(company.tenders[0].estimation_amount);
  return company;
}

export function sortCompaniesFromEstimationAmount(companies: ICompany[]) {
  return companies.sort(
    (companyA, companyB) =>
      companyA.estimation_amount - companyB.estimation_amount
  );
}

export function sortCompaniesByNameAsc(companies: ICompany[]) {
  return companies.sort((companyA, companyB) =>
    companyA.name.localeCompare(companyB.name)
  );
}

function getCompanyEarliestTenderUploadDate(company: ICompany): Date {
  return company.tenders.reduce((earliestTender, tender) =>
    isBefore(earliestTender.upload_date, tender.upload_date)
      ? earliestTender
      : tender
  ).upload_date;
}
export function sortCompaniesFromFirstUploadedTender(companies: ICompany[]) {
  return companies.sort((companyA, companyB) =>
    isBefore(
      getCompanyEarliestTenderUploadDate(companyA),
      getCompanyEarliestTenderUploadDate(companyB)
    )
      ? -1
      : 1
  );
}

export type TMappedPriceAverages = IMappedPriceAverage[];

export interface IMappedTenderDTO {
  id: string;
  allotment_name: string;
  mapped_price_averages?: TMappedPriceAverages;
  row_type_mapping_percentage?: number;
  closure_date?: string;
  price_multiplier?: string;
  project: string;
  project_name: string;
  allotment: string;
}

export interface IMappedTender {
  id: string;
  allotment_name: string;
  company_name?: string;
  mapped_price_averages?: TMappedPriceAverages;
  row_type_mapping_percentage?: number;
  closure_date?: Date;
  /** Multiplier resulting from a discount. Has to get applied to all mapped prices. */
  price_multiplier?: number;
  project: string;
  project_name: string;
  allotment: string;
  ignored?: boolean;
}

export function tendersPercentageDTOToTendersPercentageMapper(
  tenderMappedDTO: ITenderPercentageDTO
): ITenderPercentage {
  return {
    ...tenderMappedDTO,
    allotment__closure_date: tenderMappedDTO.allotment__closure_date
      ? parseISO(tenderMappedDTO.allotment__closure_date)
      : undefined,
  };
}

export function mappedTenderDTOtoMappedTenderMapper(
  mappedTenderDTO: IMappedTenderDTO
): IMappedTender {
  return {
    ...mappedTenderDTO,
    price_multiplier: mappedTenderDTO.price_multiplier
      ? Number(mappedTenderDTO.price_multiplier)
      : undefined,
    closure_date: mappedTenderDTO.closure_date
      ? parseISO(mappedTenderDTO.closure_date)
      : undefined,
  };
}
