import classnames from "classnames";
import classNames from "classnames";
import React, {
  FocusEventHandler,
  ReactNode,
  useId,
  useRef,
  useState,
} from "react";
import Button from "react-bootstrap/Button";

import { useTranslation } from "../../i18n";
import { CloseCircledIcon, DropDownArrowIcon } from "../../icons";
import { useHasChanged } from "../../useHasChanged";

import { SelectTextInput } from "./SelectTextInput";
import { IOption, getLabelAsString } from "./select.types";

export interface IControlProps {
  placeholder: ReactNode;
  onClear: () => void;
  selection: IOption[];
  searchedValue: string;
  onInput: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  formatControlOption?: (option: IOption) => React.ReactNode;
  formatControlOptions?: (options: IOption[]) => React.ReactNode;
  prefixIcon: ReactNode;
  onDeselect: (option: IOption) => void;
  multi: boolean;
  autoFocus: boolean;
  clearable: boolean;
  searchable: boolean;
  iconColor?: string;
  disabled: boolean;
  hideValueOnSearch: boolean;
  isInvalid: boolean;
  compact?: boolean;
}
export const Control = React.forwardRef<HTMLInputElement, IControlProps>(
  function Control(
    {
      placeholder,
      onClear,
      selection,
      searchedValue,
      onInput,
      onBlur,
      showMenu,
      setShowMenu,
      formatControlOption,
      formatControlOptions,
      prefixIcon: PrefixIcon,
      onDeselect,
      multi,
      autoFocus,
      clearable,
      searchable,
      iconColor,
      disabled,
      hideValueOnSearch,
      isInvalid,
      compact,
    }: IControlProps,
    ref
  ) {
    const { t } = useTranslation("Select");
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    ref = searchInputRef;

    const uid = useId();

    const [hideValue, setHideValue] = useState(false);
    const hasSearchedValue = Boolean(searchedValue);
    if (useHasChanged(hasSearchedValue) && hideValueOnSearch) {
      setHideValue(hasSearchedValue);
    }

    const [trimOptionsAtIndex, setTrimOptionsAtIndex] = useState(-1);
    const controlContentRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);

    const selectionHasChanged = useHasChanged(...selection);
    if (
      selectionHasChanged &&
      multi &&
      optionsRef.current &&
      controlContentRef.current
    ) {
      // In multi selected value case we need to trim displayed values if their total width exceeds Control width
      setTrimOptionsAtIndex((index) => {
        if (index >= 0) {
          // Release trim if a sufficient value count has been removed
          return selection.length <= index ? -1 : index;
        } else if (optionsRef.current && controlContentRef.current) {
          const optionsWidth = optionsRef.current.getBoundingClientRect().width;
          const controlWidth =
            controlContentRef.current.getBoundingClientRect().width;
          // if there isn't enough space to display all options we take the average option count before breaking,
          // minus two as a safe margin and we take that value or 1 if it's too low
          return selection.length && optionsWidth >= controlWidth
            ? Math.max(
                selection.length -
                  2 -
                  ((optionsRef.current.getBoundingClientRect().width -
                    controlContentRef.current.getBoundingClientRect().width) *
                    selection.length) /
                    optionsRef.current.getBoundingClientRect().width,
                1
              )
            : -1;
        } else {
          return -1;
        }
      });
    }
    const displayedSelection =
      trimOptionsAtIndex >= 0
        ? selection.slice(0, trimOptionsAtIndex)
        : selection;

    const autoFocusHasChanged = useHasChanged(autoFocus);
    if (autoFocusHasChanged && autoFocus && searchInputRef.current) {
      searchInputRef.current.focus();
    }

    return (
      <div
        role={searchable ? "" : "button"}
        className={classNames(
          "select-control form-control",
          isInvalid && "is-invalid"
        )}
        onClick={() => {
          if (!disabled && !window.getSelection()?.toString()) {
            searchable &&
              searchInputRef.current &&
              searchInputRef.current.focus();
            setShowMenu(true);
          }
        }}
      >
        <div
          className="select-control-content"
          ref={controlContentRef}
          onFocus={() => setShowMenu(true)}
          aria-invalid={isInvalid}
        >
          <div className="d-inline-block w-100 h-100">
            {PrefixIcon}
            <div
              className="w-100 h-100 position-relative d-inline-block align-top"
              ref={optionsRef}
            >
              {!hideValue &&
                displayedSelection.length > 0 &&
                (formatControlOptions ? (
                  <label htmlFor={uid} className="select-option">
                    {formatControlOptions(selection)}
                  </label>
                ) : (
                  displayedSelection.map((selected) => (
                    <label
                      htmlFor={uid}
                      className="select-option"
                      key={selected.value}
                      title={
                        compact
                          ? selected.label && getLabelAsString(selected.label)
                          : undefined
                      }
                    >
                      {formatControlOption
                        ? formatControlOption(selected)
                        : selected.label || selected.value}
                    </label>
                  ))
                ))}
              {trimOptionsAtIndex >= 0 && !formatControlOptions && (
                <span className="mr-2">...</span>
              )}
              {searchable && (
                <SelectTextInput
                  ref={searchInputRef}
                  value={searchedValue}
                  onChange={(value: string) => {
                    onInput(value);
                    setShowMenu(true);
                  }}
                  onKeyDown={({ target, key }) =>
                    trimOptionsAtIndex < 0 &&
                    !(target as HTMLInputElement).value &&
                    key === "Backspace" &&
                    onDeselect(selection[selection.length - 1])
                  }
                  onFocus={() => {
                    setShowMenu(true);
                  }}
                  onBlur={(event) => {
                    hideValueOnSearch && setHideValue(false);
                    onBlur && onBlur(event);
                  }}
                  disabled={disabled}
                  className={classnames({
                    "position-absolute": hideValueOnSearch && !hideValue,
                    "force-show": showMenu,
                  })}
                />
              )}
              {!displayedSelection.length && !searchedValue && (
                <span
                  className={classnames("select-placeholder", {
                    "position-absolute": searchable,
                  })}
                >
                  {placeholder || t("placeholder")}
                </span>
              )}
            </div>
          </div>
        </div>
        {clearable && (searchedValue || selection.length) ? (
          <Button
            variant="link"
            className="select-control-right-icon"
            onClick={onClear}
            disabled={disabled}
            tabIndex={-1}
          >
            <CloseCircledIcon fill={iconColor} />
          </Button>
        ) : (
          <Button
            variant="link"
            className="select-control-right-icon"
            onClick={(event) => {
              event.stopPropagation();
              setShowMenu((showMenu) => !showMenu);
            }}
            disabled={disabled}
            tabIndex={-1}
          >
            <DropDownArrowIcon
              className={classnames(
                showMenu ? "open" : "closed",
                "drop-down-icon"
              )}
              fill={iconColor}
            />
          </Button>
        )}
      </div>
    );
  }
);
