import classNames from "classnames";
import React from "react";

import styles from "../_exports.module.scss";

import { AddCircledIcon } from "./AddCircled";
import circleStyles from "./CloseCircled.module.scss";

export function CloseCircledIcon({
  className = "",
  fill = styles.black,
  size = 21,
}) {
  return (
    <AddCircledIcon
      className={classNames(className, circleStyles.CloseCircled)}
      fill={fill}
      size={size}
    />
  );
}
