import classnames from "classnames";
import React from "react";

import { Option } from "./Option";
import { IOption, IOptionGroup } from "./select.types";

export interface IOptionGroupProps {
  optionGroup: IOptionGroup;
  selection: IOption[];
  onSelect: (option: IOption) => void;
  onDeselect: (option: IOption) => void;
  formatOptionLabel?: (option: IOption) => React.ReactNode;
  multi: boolean;
  focusedOption?: IOption;
  onMouseEnter: (option: IOption) => void;
}

export function OptionGroup({
  optionGroup,
  selection = [],
  formatOptionLabel,
  onSelect = () => {},
  onDeselect = () => {},
  multi,
  focusedOption,
  onMouseEnter = () => {},
  ...props
}: IOptionGroupProps) {
  return (
    <div {...props}>
      <div className="ps-3 py-3 fw-bold border-bottom">{optionGroup.label}</div>
      <div>
        {optionGroup.options &&
          optionGroup.options.length &&
          optionGroup.options.map((option) => {
            return (
              <Option
                key={option.value}
                option={option}
                formatOptionLabel={formatOptionLabel}
                className={classnames({
                  focus: focusedOption === option,
                })}
                selection={selection}
                onSelect={onSelect}
                onDeselect={onDeselect}
                onMouseEnter={() => onMouseEnter(option)}
                multi={multi}
              />
            );
          })}
      </div>
    </div>
  );
}
