import { IComparisonDTO } from "../shared/types/comparison.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";

import { HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE } from "./APIUtils";
import { FETCH_METHODS_ENUM, authRequest, authRequestJSON } from "./fetch";

const COMPARISON_BASE_URL = "price-comparison";
export async function createOrUpdateComparison({
  id,
  price_estimate,
  price_breakdowns,
  settings,
  name,
  description,
  ...nonUpdatableKeys
}: Partial<IComparisonDTO>) {
  const shouldUpdateComparison = id !== undefined;
  const comparisonToSend = {
    price_estimate,
    price_breakdowns,
    settings,
    name,
    description,
    ...(shouldUpdateComparison ? {} : nonUpdatableKeys),
  };
  return await authRequestJSON<IComparisonDTO>(
    `${COMPARISON_BASE_URL}/${shouldUpdateComparison ? `${id}/` : ""}`,
    {
      method: shouldUpdateComparison
        ? FETCH_METHODS_ENUM.PATCH
        : FETCH_METHODS_ENUM.POST,
      body: JSON.stringify(comparisonToSend),
    }
  );
}

export async function fetchComparisons(lotId: string) {
  const query = new URLSearchParams({
    allotment: lotId,
    page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
  });
  return authRequestJSON<IPaginatedQueryResults<IComparisonDTO>>(
    `${COMPARISON_BASE_URL}/?${query}`
  );
}

export async function fetchComparison(id: string) {
  return await authRequestJSON<IComparisonDTO>(`${COMPARISON_BASE_URL}/${id}/`);
}

export function deleteComparison(id: string) {
  return authRequest(`${COMPARISON_BASE_URL}/${id}/`, {
    method: FETCH_METHODS_ENUM.DELETE,
  });
}
