import React from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";

import { MatomoProviderInstance } from ".././matomo";
import { ClassificationPage } from "../Mapping/ClassificationPage";
import { MappingAdmin } from "../Mapping/MappingAdmin";
import { MappingLotsPage } from "../Mapping/MappingLotsPage";
import { MappingTendersPage } from "../Mapping/MappingTendersPage";
import { MappingToolPage } from "../Mapping/MappingToolPage";
import { PriceAveragePage } from "../PriceAverage/PriceAveragePage";
import { PriceAveragePanelsPage } from "../PriceAverage/PriceAveragePanelsPage";
import { LogInPage, UserGuard } from "../account";
import { UserProvider } from "../account/UserContext";
import { UserOrganizationGuard } from "../account/UserOrganizationGuard";
import { PersistentQueryClientProvider } from "../api/PersistenQueryClientProvider";
import { QueryClientProvider } from "../api/QueryClientProvider";
import { API_BASE_URL } from "../api/fetch";
import { OperationChartsPage } from "../charts";
import { ComparePage } from "../compare";
import { SavedComparisonsPage } from "../compare/SavedComparisonsPage";
import {
  CompanyDocumentsPage,
  LotDocumentsPage,
  MOEDocumentsPage,
  OperationDocumentsPage,
} from "../documents";
import { LotPage } from "../lot";
import { LotsPage } from "../lots";
import { OperationPage } from "../operation";
import { OperationsPage } from "../operations";
import { devToolsRoutes } from "../shared/dev/devToolsRoutes";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { TendersPage } from "../tenders";

import { ErrorPage } from "./ErrorPage";
import "./i18n";

export function App() {
  const router = createBrowserRouter([
    /**developer tools not available in production mode */
    ...devToolsRoutes,
    {
      path: `/${PATH_NAMES_ENUM.ADMIN}`,
      loader: () => redirect(`${API_BASE_URL}/${PATH_NAMES_ENUM.ADMIN}`),
    },
    {
      path: "/",
      element: (
        <UserProvider>
          <QueryClientProvider />
        </UserProvider>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: `/${PATH_NAMES_ENUM.LOGIN}`,
          element: <LogInPage />,
        },
        {
          path: "/",
          element: <UserGuard />,
          children: [
            {
              path: "/",
              element: <OperationsPage />,
            },
            {
              path: `/${PATH_NAMES_ENUM.MAPPING}`,
              element: (
                <UserOrganizationGuard
                  organizationName={
                    window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION
                  }
                />
              ),
              children: [
                {
                  path: PATH_NAMES_ENUM.ADMIN_ROW_TYPE,
                  element: (
                    <UserOrganizationGuard
                      organizationName={
                        window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION
                      }
                      organizationRights={{ can_admin: true }}
                      children={<MappingAdmin />}
                    />
                  ),
                },
                {
                  path: `${PATH_NAMES_ENUM.OPERATIONS}/:operationId/${PATH_NAMES_ENUM.LOTS}/:lotId`,
                  element: (
                    // Persist MappingToolPage queries to get faster cold starts
                    // The main query requiring cache is `row-types`
                    <PersistentQueryClientProvider>
                      <MappingToolPage />
                    </PersistentQueryClientProvider>
                  ),
                },
                {
                  path: `${PATH_NAMES_ENUM.CLASSIFICATION_TABLE}`,
                  element: <ClassificationPage />,
                },

                {
                  path: "",
                  element: <MappingLotsPage />,
                },
                {
                  path: ":conventionalAllotment",
                  element: <MappingTendersPage />,
                },
              ],
            },
            {
              path: `/${PATH_NAMES_ENUM.PRICE_AVERAGE}`,
              element: <PriceAveragePanelsPage />,
            },
            {
              path: `/${PATH_NAMES_ENUM.PRICE_AVERAGE}/:priceAveragePanelId`,
              element: <PriceAveragePage />,
            },
            {
              path: `/${PATH_NAMES_ENUM.OPERATIONS}`,
              element: <Navigate to="/" replace />,
            },
            {
              path: `/${PATH_NAMES_ENUM.OPERATIONS}/:operationId/${PATH_NAMES_ENUM.LOTS}/:lotId`,
              element: <LotPage />,
              children: [
                {
                  path: "",
                  element: <Navigate to={PATH_NAMES_ENUM.OFFERS} replace />,
                },
                {
                  path: PATH_NAMES_ENUM.OFFERS,
                  element: <TendersPage />,
                },
                {
                  path: PATH_NAMES_ENUM.COMPARE,
                  element: <ComparePage />,
                },
                {
                  path: PATH_NAMES_ENUM.SAVED_COMPARISONS,
                  element: <SavedComparisonsPage />,
                },

                {
                  path: `${PATH_NAMES_ENUM.DOCUMENTS}/${PATH_NAMES_ENUM.MOE}`,
                  element: <MOEDocumentsPage />,
                },
                {
                  path: `${PATH_NAMES_ENUM.DOCUMENTS}/:companyId`,
                  element: <CompanyDocumentsPage />,
                },
                {
                  path: PATH_NAMES_ENUM.DOCUMENTS,
                  element: <LotDocumentsPage />,
                },
              ],
            },
            {
              path: `${PATH_NAMES_ENUM.OPERATIONS}/:operationId`,
              element: <OperationPage />,
              children: [
                {
                  path: "",
                  element: <Navigate to={PATH_NAMES_ENUM.LOTS} replace />,
                },
                {
                  path: PATH_NAMES_ENUM.LOTS,
                  element: <LotsPage />,
                },
                {
                  path: PATH_NAMES_ENUM.DOCUMENTS,
                  element: <OperationDocumentsPage />,
                },
                {
                  path: PATH_NAMES_ENUM.CHARTS,
                  element: <OperationChartsPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return (
    <MatomoProviderInstance>
      <RouterProvider router={router} />
    </MatomoProviderInstance>
  );
}
