import { useQueryClient } from "@tanstack/react-query";
import React, { useMemo } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { canEditOperation } from "../account/guards";
import { LotMappingModal } from "../lots";
import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { Footer } from "../shared/footer";
import { Header, INav } from "../shared/header";
import { useTranslation } from "../shared/i18n";
import {
  BackArrowIcon,
  CompareIcon,
  DocumentsIcon,
  SavedComparisonsIcon,
  TendersIcon,
} from "../shared/icons";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import {
  QUERY_KEYS_ENUM,
  useCurrentLot,
  useEstimatesQuery,
  useOperationQuery,
} from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";

import { LotHeader } from "./LotHeader";

export function LotPage() {
  const { operationId, lotId } = useParams();

  useTrackPageView(
    "Lot",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
      {
        id: CUSTOM_DIMENSIONS_ENUM.LOT_ID,
        value: String(lotId),
      },
    ],
    [operationId, lotId]
  );

  const priceEstimatesQuery = useEstimatesQuery(lotId);

  const invalidLot =
    priceEstimatesQuery.isLoading ||
    !(priceEstimatesQuery.data?.results.length ?? -1 > 0);

  const { t } = useTranslation("LotPage");
  const operationBasePath = `/${PATH_NAMES_ENUM.OPERATIONS}/${operationId}`;
  const lotBasePath = `${operationBasePath}/${PATH_NAMES_ENUM.LOTS}/${lotId}`;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const lotScopeNavList: INav[] = useMemo(
    () => [
      {
        Icon: BackArrowIcon,
        path: operationBasePath,
        back: true,
        overlay: <Tooltip>{t("returnOperation")}</Tooltip>,
        id: "navReturnOperation",
      },
      {
        Icon: TendersIcon,
        path: `${lotBasePath}/${PATH_NAMES_ENUM.OFFERS}`,
        overlay: <Tooltip>{t("lotTenders")}</Tooltip>,
        id: "navLotTenders",
      },
      {
        Icon: CompareIcon,
        path: `${lotBasePath}/${PATH_NAMES_ENUM.COMPARE}`,
        overlay: <Tooltip>{t("lotCompare")}</Tooltip>,
        id: "navLotCompare",
        disabled: invalidLot,
      },
      {
        Icon: SavedComparisonsIcon,
        path: `${lotBasePath}/${PATH_NAMES_ENUM.SAVED_COMPARISONS}`,
        overlay: <Tooltip>{t("lotSavedComparisons")}</Tooltip>,
        id: "navLotSavedComparisons",
      },
      {
        Icon: DocumentsIcon,
        path: `${lotBasePath}/${PATH_NAMES_ENUM.DOCUMENTS}`,
        overlay: <Tooltip>{t("lotDocuments")}</Tooltip>,
        id: "navLotDocuments",
      },
    ],
    [t, operationBasePath, lotBasePath, invalidLot]
  );

  const operationQuery = useOperationQuery(operationId);
  const operation = operationQuery.data;

  const lot = useCurrentLot();

  const location = useLocation();
  const isOnComparePage =
    location.pathname.split("/").pop() === PATH_NAMES_ENUM.COMPARE;
  const displayHeader = !isOnComparePage;

  // redirect to PATH_NAMES_ENUM.OFFERS if no estimate or frame exists for this lot
  if (isOnComparePage && !priceEstimatesQuery.isLoading && invalidLot) {
    navigate(`${lotBasePath}/${PATH_NAMES_ENUM.OFFERS}`, { replace: true });
  }

  const isLoaded = !(
    operationQuery.isLoading ||
    priceEstimatesQuery.isLoading ||
    operation === undefined ||
    lot === undefined
  );
  const hasWritingRightsOnLot = canEditOperation(operation);
  return (
    <>
      <Header variant="dark" nav={lotScopeNavList} />
      {isLoaded ? (
        <>
          {displayHeader && <LotHeader lot={lot!} operation={operation} />}
          {/* show mapping flow if lot is invalid */}
          <LotMappingModal
            show={invalidLot && hasWritingRightsOnLot}
            handleClose={(save) => {
              if (save) {
                queryClient.invalidateQueries({
                  queryKey: [QUERY_KEYS_ENUM.ESTIMATES, lotId],
                });
              } else {
                navigate(operationBasePath);
              }
            }}
            operationId={operationId!}
            lotId={lotId}
            stepsToSkip={[0]}
            isEditing
            forceShowOnlyFrameToggle
          />
          <Outlet />
        </>
      ) : (
        <Container>
          <Row className="my-4">
            <Col className="text-center">
              <Spinner />
            </Col>
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}
