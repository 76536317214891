import classNames from "classnames";
import React, { useMemo } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";

import { IBackErrors } from "../shared/errors";
import { useTranslation } from "../shared/i18n";
import { Select } from "../shared/inputs/select";
import { useCompaniesQuery } from "../shared/useSharedQueries";

import styles from "./CompanySelectionCreation.module.scss";
import { TTenderFormInputs, TenderForm } from "./TenderForm";

export enum EVENT_KEY_ENUM {
  EXISTING = "existing",
  CREATE = "create",
}

export function CompanySelectionCreation({
  activeKey,
  setActiveKey,
  setSelectedCompany,
  selectedCompany,
  setTenderForm,
  tenderForm,
  submit,
  errors,
}: {
  activeKey: string | null;
  setActiveKey: React.Dispatch<string | null>;
  setSelectedCompany: React.Dispatch<string | undefined>;
  selectedCompany: string;
  setTenderForm: (form: TTenderFormInputs) => void;
  tenderForm?: TTenderFormInputs;
  submit: () => void;
  errors: IBackErrors<TTenderFormInputs>;
}) {
  const { t } = useTranslation("CompanyForm");

  const companiesQuery = useCompaniesQuery();

  const companies = companiesQuery?.data;
  const companyOptions = useMemo(() => {
    return (
      companies?.map((company) => ({
        value: company.id,
        label: (
          <>
            <b>{company.name}</b>
            <span className="float-end">
              {company.location_name} {company.location_housenumber}{" "}
              {company.location_street} {company.location_postcode}{" "}
              {company.location_city}
            </span>
          </>
        ),
      })) ?? []
    );
  }, [companies]);

  return (
    <Tab.Container
      id="lots-tabs"
      activeKey={activeKey!}
      onSelect={setActiveKey}
    >
      <Nav as={Row} className="mb-3 align-items-end">
        <Nav.Item
          as={Col}
          className={classNames(
            { [styles.border]: activeKey === EVENT_KEY_ENUM.EXISTING },
            styles.borderHover,
            "d-flex justify-content-center align-items-center text-center p-1 fw-bold"
          )}
        >
          <Nav.Link eventKey={EVENT_KEY_ENUM.EXISTING} className="w-100">
            {t("choose existing company")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item
          as={Col}
          className={classNames(
            { [styles.border]: activeKey === EVENT_KEY_ENUM.CREATE },
            styles.borderHover,
            "d-flex justify-content-center align-items-center text-center pb-2"
          )}
        >
          <Nav.Link eventKey={EVENT_KEY_ENUM.CREATE} className="w-100">
            {t("create new company")}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey={EVENT_KEY_ENUM.EXISTING}>
          <Row className={styles.select}>
            <Col>
              {companiesQuery.isLoading ? (
                <Spinner className="mx-auto d-block mt-3" />
              ) : (
                <Select
                  placeholder={t("Select a company")}
                  options={companyOptions}
                  onChange={(company) => {
                    setSelectedCompany(company as unknown as string);
                  }}
                  value={selectedCompany}
                  className={classNames("d-block", styles.select)}
                  searchable
                />
              )}
            </Col>
          </Row>
        </Tab.Pane>
        <Tab.Pane eventKey={EVENT_KEY_ENUM.CREATE}>
          <TenderForm
            onData={setTenderForm}
            defaultValues={tenderForm}
            onSubmit={submit}
            backErrors={errors}
          />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
}
