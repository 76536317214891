import {
  Cell,
  Table as ReactTable,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { LotWithConventionalAllotments } from "../shared/ConventionalAllotments";
import colors from "../shared/_exports.module.scss";
import { ExportTableToExcel } from "../shared/excel/ExportTableToExcel";
import { formatCurrency, useTranslation } from "../shared/i18n";
import { CrownIcon } from "../shared/icons";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { Table } from "../shared/table";
import { ILot } from "../shared/types/lot.types";
import { isDevelopmentEnv } from "../shared/utils";

import styles from "./LotsPage.module.scss";

const emptyData: any[] = [];
const columnHelper = createColumnHelper<ILot>();

const LARGE_COL_WIDTH = 30;

export function SelectedLotsTable({
  lots,
  isLoading,
  operationId,
}: {
  lots?: ILot[];
  isLoading?: boolean;
  operationId: string;
}) {
  const { t } = useTranslation("SelectedLotsTable");
  const navigate = useNavigate();

  let table: ReactTable<ILot> | undefined = undefined;

  const selectedLotsColumns = useMemo(
    () => [
      columnHelper.accessor("reference_number", {
        header: () => (
          <>
            <span className="me-5">{t("reference_number")}</span>
            <ExportTableToExcel
              table={table as any}
              isLoading={Boolean(isLoading)}
              fileName={`${t("title")}_${operationId}`}
              sheetName={t("title")}
              mapCell={(cell: Cell<any, any>) => cell.getValue() ?? ""}
              getCellColor={() => colors.white}
              fontSize={10}
              headerFontSize={10}
              rowHeight={18}
            />
          </>
        ),
        meta: {
          excel: {
            header: t("reference_number"),
          },
        },
      }),
      columnHelper.accessor("name", {
        header: t("name"),
        cell: (props) => (
          <LotWithConventionalAllotments lot={props.row.original} />
        ),
      }),
      columnHelper.accessor("estimation_amount", {
        header: t("estimation"),
        cell: (props) => formatCurrency(props.getValue()),
        meta: {
          excel: {
            width: LARGE_COL_WIDTH,
          },
        },
      }),
      columnHelper.accessor("selected_company_name", {
        header: t("selected"),
        id: "selected-company",
        cell: (props) => (
          <span className={styles.borderBottom}>
            <b>{props.getValue()}</b>
            <CrownIcon className={styles.crownMargins} />
          </span>
        ),
        meta: {
          excel: {
            width: LARGE_COL_WIDTH,
          },
        },
      }),
      columnHelper.accessor("selected_price_amount", {
        header: t("amount"),
        id: "selected-value",
        cell: (props) => formatCurrency(props.getValue()),
        meta: {
          excel: {
            width: LARGE_COL_WIDTH,
          },
        },
      }),
    ],
    [isLoading, operationId, t, table]
  );

  table = useReactTable<ILot>({
    enableSorting: false,
    columns: selectedLotsColumns,
    data: lots ?? emptyData,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: isDevelopmentEnv,
  });

  return (
    <Table
      table={table}
      className={classNames(styles["lots-table"], styles.hideFooter)}
      onRowClick={(row, _, event) =>
        event.ctrlKey
          ? window.open(`./${PATH_NAMES_ENUM.LOTS}/${row.original.id}`)
          : navigate(`${row.original.id}`)
      }
      isLoading={isLoading}
      defaultTheme
      hover
      responsive
    />
  );
}
