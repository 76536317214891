import { RefObject, useCallback, useEffect, useState } from "react";

import { useHasChanged } from "../shared/useHasChanged";
import { useWindowWidth } from "../shared/utils";

export const MIN_BAR_WIDTH = 60;
export const MAX_BAR_WIDTH = 120;

export function useDynamicChartBarWidth({
  maxBarCountPerTick,
  tickCount,
  barChartWrapperRef,
}: {
  maxBarCountPerTick: number;
  tickCount: number;
  barChartWrapperRef: RefObject<HTMLElement>;
}) {
  const [barWidth, setBarWidth] = useState(MIN_BAR_WIDTH);

  const setDynamicBarWidth = useCallback(() => {
    const chartWrapperWidth =
      barChartWrapperRef.current?.getBoundingClientRect().width;
    const availableBarWidthInWrapper =
      (chartWrapperWidth ?? 0) / (tickCount * maxBarCountPerTick || 1);

    const shouldWeEnlargeLotsChartBarWidthFromDefault =
      availableBarWidthInWrapper > MIN_BAR_WIDTH;

    if (shouldWeEnlargeLotsChartBarWidthFromDefault) {
      setBarWidth(Math.min(availableBarWidthInWrapper, MAX_BAR_WIDTH));
    } else {
      setBarWidth(MIN_BAR_WIDTH);
    }
  }, [barChartWrapperRef, maxBarCountPerTick, tickCount]);

  const hasWindowWidthChanged = useHasChanged(useWindowWidth());

  // update barWidth on window width change
  if (hasWindowWidthChanged) {
    setDynamicBarWidth();
  }

  // update barWidth on first render
  // delay execution after all elements are rendered
  useEffect(() => {
    setDynamicBarWidth();
  }, [setDynamicBarWidth]);

  return barWidth;
}
