import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function CircleQuestionIcon({
  className = "",
  fill = styles.blue300,
  size = 49,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#CircleQuestionClipPath)">
        <path
          d="M24.4987 44.9173C13.2226 44.9173 4.08203 35.7768 4.08203 24.5007C4.08203 13.2245 13.2226 4.08398 24.4987 4.08398C35.7748 4.08398 44.9154 13.2245 44.9154 24.5007C44.9154 35.7768 35.7748 44.9173 24.4987 44.9173ZM22.457 30.6257V34.709H26.5404V30.6257H22.457ZM26.5404 27.2671C28.1812 26.7726 29.5898 25.7051 30.5097 24.2592C31.4296 22.8133 31.7995 21.0851 31.5521 19.3893C31.3047 17.6935 30.4565 16.143 29.1619 15.0201C27.8673 13.8972 26.2124 13.2767 24.4987 13.2715C22.8467 13.2714 21.2456 13.8435 19.9678 14.8905C18.69 15.9376 17.8143 17.3949 17.4897 19.0147L21.4954 19.8171C21.6091 19.2483 21.8819 18.7236 22.2822 18.3038C22.6825 17.8841 23.1937 17.5866 23.7564 17.4461C24.3191 17.3055 24.9102 17.3277 25.4608 17.5099C26.0114 17.6922 26.4989 18.027 26.8667 18.4755C27.2344 18.924 27.4673 19.4677 27.5381 20.0434C27.6089 20.619 27.5148 21.203 27.2667 21.7272C27.0187 22.2515 26.6268 22.6945 26.1368 23.0048C25.6468 23.315 25.0787 23.4798 24.4987 23.4798C23.9572 23.4798 23.4379 23.6949 23.055 24.0778C22.6721 24.4607 22.457 24.98 22.457 25.5215V28.584H26.5404V27.2671Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="CircleQuestionClipPath">
          <rect width="49" height="49" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
