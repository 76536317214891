import { useCallback, useRef } from "react";

/**
 * Debounce factory using timeout
 * @param callback function to debounce
 * @param delay in ms
 * @returns debounced function to be called
 */
export function useDebounce<T>(
  callback: (...args: any) => T,
  delay: number = 500
) {
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  return useCallback(
    (...args: any) => {
      clearTimeout(timeoutIdRef.current);

      return new Promise((resolve) => {
        timeoutIdRef.current = setTimeout(async () => {
          const result = await callback?.(...args);
          resolve(result);
        }, delay);
      }) as T;
    },
    [callback, delay]
  );
}
