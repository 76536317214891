import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/esm/helpers";

import { ButtonWithTooltip } from "../shared/ButtonWithTooltip";
import { useTranslation } from "../shared/i18n";

import { useCanEditCurrentLot } from "./guards";

/**
 * A Button that'll be disabled with an info Tooltip
 * if its optional allowed prop is false or user doesn't
 * have writing rights on current Operation
 */
export function GuardedButton<As extends React.ElementType = "button">({
  allowed,
  ...props
}: { allowed?: boolean } & React.PropsWithChildren<
  ReplaceProps<As, BsPrefixProps<As> & ButtonProps>
>) {
  const { t } = useTranslation("GuardedButton");

  const { canEdit, editDisabled } = useCanEditCurrentLot(allowed);

  if (canEdit) {
    return <Button {...(props as any)} />;
  }
  return (
    <ButtonWithTooltip
      {...(props as any)}
      disabled
      tooltip={t(
        editDisabled
          ? "edition is not enabled"
          : "you don't have rights to do this action"
      )}
    />
  );
}
