import React from "react";

import styles from "../_exports.module.scss";
import { ifTest } from "../utils";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function DocumentsIcon({
  fill = styles.white,
  className = "",
  size = 34,
}) {
  return (
    <svg
      data-test={ifTest("documents-icon")}
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 -3 34 34"
      fill="none"
      className={className}
    >
      <path
        fill={fill}
        d="M30.643 4H17.31L13.977.667h-10C2.144.667.66 2.167.66 4L.644 24c0 1.834 1.5 3.334 3.333 3.334h26.666c1.834 0 3.334-1.5 3.334-3.334V7.334C33.977 5.5 32.477 4 30.644 4Z"
      />
    </svg>
  );
}
