import React from "react";

import styles from "../_exports.module.scss";
import { ifTest } from "../utils";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function GraphIcon({ fill = styles.white, className = "", size = 35 }) {
  return (
    <svg
      data-test={ifTest("graph-icon")}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize((size * 24) / 25)}
      viewBox="0 0 25 24"
      className={className}
    >
      <path
        fill={fill}
        d="M9.4 18h-5v-8h5v8Zm6 0h-5V6h5v12Zm6 0h-5V2h5v16Zm1 4h-19v-2h19v2Z"
      />
    </svg>
  );
}
