import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import colors from "../../shared/_exports.module.scss";
import { useTranslation } from "../i18n";
import { TriangleDirections, TriangleIcon } from "../icons";

export function ExpandHeader({
  value,
  isCompletelyExpanded,
  setExpanded,
}: {
  value: any;
  isCompletelyExpanded: boolean;
  setExpanded: (value: any) => void;
}) {
  const { t } = useTranslation("useCompareTable");
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {t(isCompletelyExpanded ? "Contract the tree" : "Expand the tree")}
          </Tooltip>
        }
      >
        <Button
          onClick={() =>
            isCompletelyExpanded ? setExpanded({}) : setExpanded(true)
          }
          variant="text"
          className="me-3 p-0 ms-n2"
        >
          <TriangleIcon
            size={32}
            direction={
              isCompletelyExpanded
                ? TriangleDirections.DOWN
                : TriangleDirections.RIGHT
            }
            fill={colors.white}
          />
        </Button>
      </OverlayTrigger>
      {value}
    </>
  );
}
