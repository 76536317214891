import classNames from "classnames";
import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { Brand } from "../Brand";
import { UserMenu } from "../UserMenu";
import styles from "../_exports.module.scss";

import { INav, NavLink } from "./NavLink";

export function Header({
  variant = "light",
  nav,
}: {
  variant?: "light" | "dark";
  nav?: INav[];
}) {
  const isVariantDark = variant === "dark";
  return (
    <Navbar
      className={classNames(
        "justify-content-between align-items-center py-2 px-3 z-index-dropdown",
        isVariantDark ? "text-bg-dark" : "bg-light"
      )}
    >
      <Brand
        logoColor={isVariantDark ? styles.white : undefined}
        className="my-1"
        variant={variant}
      />
      {nav != null && (
        <Nav className="overflow-auto">
          {nav.map((nav) => (
            <NavLink key={nav.path} {...nav} />
          ))}
        </Nav>
      )}
      <UserMenu variant={variant} />
    </Navbar>
  );
}
