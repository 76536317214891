import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { createCompany } from "../api/fetchCompanies";
import { deleteTender, updateTender } from "../api/fetchTenders";
import { DefaultModal, useModal } from "../shared/DefaultModal";
import { IBackErrors } from "../shared/errors";
import { useTranslation } from "../shared/i18n";
import { CheckedCircleIcon } from "../shared/icons";
import { ConfirmationModal } from "../shared/modals";
import { ICompany } from "../shared/types/company.types";
import { QUERY_KEYS_ENUM } from "../shared/useSharedQueries";

import {
  CompanySelectionCreation,
  EVENT_KEY_ENUM,
} from "./CompanySelectionCreation";
import styles from "./TenderEditionModal.module.scss";
import { TTenderFormInputs, isFormValid } from "./TenderForm";

export interface ITenderEditionModalParameters {
  show: boolean;
  handleClose: (save: boolean | void) => void;
  company: ICompany;
  lotId: string;
}

export function TenderEditionModal({
  show,
  handleClose,
  company,
  lotId,
}: ITenderEditionModalParameters) {
  const { t } = useTranslation("TenderEditionModal");
  const [tenderForm, setTenderForm] = useState<TTenderFormInputs>();
  const {
    show: showDeletion,
    handleClose: handleCloseDeletion,
    handleShow: handleShowDeletion,
  } = useModal("TenderDeletionModal");
  const [activeKey, setActiveKey] = useState<string | null>(
    EVENT_KEY_ENUM.EXISTING
  );
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(
    company.id
  );
  const queryClient = useQueryClient();

  const tenderEdition = useMutation({
    mutationFn: updateTender,
  });

  const tenderDeletion = useMutation({
    mutationFn: deleteTender,
  });

  const companyCreation = useMutation({
    mutationFn: createCompany,
  });

  const submitCompany = useCallback(async () => {
    let id: string;
    if (activeKey === EVENT_KEY_ENUM.EXISTING) {
      id = selectedCompany!;
    } else {
      if (!isFormValid(tenderForm)) {
        return;
      }
      const company = await companyCreation.mutateAsync({
        // location_street: tenderForm,
        location_postcode: tenderForm!.location_postcode!,
        location_city: tenderForm!.location_city!,
        // location_district: ,
        // location_country: ,
        name: tenderForm!.name!,
      });
      id = company.id;
    }
    if (id) {
      await Promise.all(
        company.tenders.map((tender) =>
          tenderEdition.mutateAsync({ company: id, id: tender.id })
        )
      );
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.TENDERS, lotId],
      });
      handleClose();
    }
  }, [
    tenderForm,
    companyCreation,
    activeKey,
    tenderEdition,
    selectedCompany,
    handleClose,
    company.tenders,
    lotId,
    queryClient,
  ]);

  const isSubmitting = companyCreation.isLoading || tenderEdition.isLoading;

  return (
    <>
      <ConfirmationModal
        title={t("delete tender")}
        show={showDeletion}
        handleClose={async (confirm: boolean | void) => {
          if (!confirm) {
            handleCloseDeletion();
          } else {
            await Promise.all(
              company.tenders.map((tender) =>
                tenderDeletion.mutateAsync(tender.id)
              )
            );
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS_ENUM.TENDERS, lotId],
            });
            handleClose();
          }
        }}
        isSubmitting={tenderDeletion.isLoading}
      />
      <DefaultModal
        show={show}
        title={t("title")}
        handleClose={handleClose}
        staticBackdrop
        size="lg"
        footer={
          <>
            <Button
              size="lg"
              onClick={handleShowDeletion}
              className={classNames("rectangular ms-5", styles.gray)}
              disabled={isSubmitting}
            >
              {t("delete tender")}
            </Button>
            <Button
              size="lg"
              variant="primary"
              onClick={submitCompany}
              className="rectangular ms-4"
              disabled={
                isSubmitting ||
                (activeKey === EVENT_KEY_ENUM.CREATE
                  ? !isFormValid(tenderForm)
                  : !selectedCompany)
              }
            >
              {t("save changes")}
              {isSubmitting ? (
                <Spinner size="sm" />
              ) : (
                <CheckedCircleIcon className="ms-2" />
              )}
            </Button>
          </>
        }
      >
        <CompanySelectionCreation
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          setSelectedCompany={setSelectedCompany}
          selectedCompany={selectedCompany!}
          setTenderForm={setTenderForm}
          tenderForm={tenderForm}
          submit={submitCompany}
          errors={companyCreation.error as IBackErrors<TTenderFormInputs>}
        />
      </DefaultModal>
    </>
  );
}
