import { StatusSelect } from "../../shared/inputs/StatusSelect";

import { IFilterProps } from "./../OperationsFilters";

export function StatusFilter({ filterKey, onChange }: IFilterProps) {
  return (
    <StatusSelect
      clearable
      onChange={(selectedValue: string) => onChange?.(filterKey, selectedValue)}
    />
  );
}
