import { parseISO } from "date-fns";

import { toISODate } from "../../api/APIUtils";

import { IDocument, IDocumentDTO, IDocumentData } from "./document.types";
import {
  mappingDTOToMappingMapper,
  mappingToMappingDTOMapper,
} from "./mapping.types";

export enum FILE_TYPE_ENUM {
  ESTIMATE = "ESTIMATE",
  FRAME = "FRAME",
}

export interface IPriceEstimateDTO extends IDocumentDTO {
  raw_data: any[][];
  file_type: FILE_TYPE_ENUM;
}

export interface IPriceEstimate extends IDocumentData {}

export type TPriceEstimateWithTotal = IPriceEstimate & {
  estimation_amount: number;
};

export function isPriceEstimate(
  document: IDocument
): document is IPriceEstimate {
  return Object.values(FILE_TYPE_ENUM).includes(
    (document as IPriceEstimate).file_type!
  );
}

export function priceEstimateDTOToPriceEstimateMapper(
  priceEstimateDTO: IPriceEstimateDTO
): IPriceEstimate {
  return {
    ...priceEstimateDTO,
    upload_date: parseISO(priceEstimateDTO.upload_date),
    mapping: mappingDTOToMappingMapper(priceEstimateDTO.mapping),
  };
}

export function partialPriceEstimateToPriceEstimateDTOMapper(
  priceEstimateDTO: Partial<IPriceEstimate>
): Partial<IPriceEstimateDTO> {
  return {
    ...priceEstimateDTO,
    upload_date: toISODate(priceEstimateDTO.upload_date),
    mapping: mappingToMappingDTOMapper(priceEstimateDTO.mapping),
  };
}

export function isMOE(estimate: IPriceEstimate) {
  return estimate.file_type === FILE_TYPE_ENUM.ESTIMATE;
}
