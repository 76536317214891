import classNames from "classnames";
import React from "react";
import Popover from "react-bootstrap/Popover";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { TooltipProps } from "recharts/types";

import colors from "../shared/_exports.module.scss";
import { useTranslation } from "../shared/i18n";
import {
  formatCurrency,
  formatNumberCompact,
} from "../shared/i18n/numberFormat";
import { SquareIcon, StarIcon, applyFontRatioToPxSize } from "../shared/icons";
import { ICompany } from "../shared/types/company.types";
import { getIsBestPriceBreakdown } from "../shared/types/lot.types";
import { truncate } from "../shared/utils";

import styles from "./LotsBarChart.module.scss";
import { DATA_KEYS_ENUM, ILotChartData } from "./chart.types";
import { MIN_BAR_WIDTH } from "./useDynamicChartBarWidth";

const barFillAndColorByIndex = [
  { fill: colors.black, label: colors.white },
  { fill: colors.green, label: colors.white },
  { fill: colors.blue300, label: colors.white },
  { fill: colors.blue, label: colors.white },
  { fill: colors.blue700, label: colors.white },
  { fill: colors.teal300, label: colors.black },
  { fill: colors.teal500, label: colors.white },
  { fill: colors.teal700, label: colors.white },
  { fill: colors.teal900, label: colors.white },
];

const STAR_ICON_SIZE = 30;

function getBarDataKey(barIndex: number) {
  switch (barIndex) {
    case 0:
      return DATA_KEYS_ENUM.MOE;
    case 1:
      return DATA_KEYS_ENUM.AVERAGE;
    default:
      return `companies.${barIndex - 2}.estimation_amount`;
  }
}

function getCompanyByBarIndex(
  barIndex: number,
  companies: ICompany[] | undefined
) {
  // we offset the index to exclude MOE and average bars
  const companyIndex = barIndex - 2;
  return companyIndex < (companies?.length ?? 0)
    ? companies?.[companyIndex]
    : undefined;
}

function formatPriceAxisTick(value: number) {
  return formatNumberCompact(value);
}

const APPROXIMATIVE_CHAR_WIDTH = 7;
const APPROXIMATIVE_BOLD_CHAR_WIDTH = 8;
const BAR_BOTTOM_LABEL_MARGIN = 2;
function getDisplayableBarLabel(name: string, barHeight: number): string {
  // approximation
  const barHeightInCharLength =
    (barHeight - BAR_BOTTOM_LABEL_MARGIN) / APPROXIMATIVE_CHAR_WIDTH;
  if (barHeightInCharLength < 4) {
    return "";
  }
  return truncate(name, barHeightInCharLength - 2);
}

export function LotsBarChart({
  data,
  maxBarCount,
  barWidth = MIN_BAR_WIDTH,
}: {
  data: ILotChartData[];
  maxBarCount: number;
  barWidth?: number;
}) {
  const { t } = useTranslation("LotsBarChart");
  const { t: tEnum } = useTranslation("enum");
  const { t: tShared } = useTranslation("shared");

  const lotAxisTickWidth = maxBarCount * barWidth;

  const renderLotAxisTick = ({
    x,
    y,
    payload: { value },
  }: {
    x: number;
    y: number;
    height: number;
    width: number;
    payload: { value: string };
  }) => {
    const lot = data?.find(({ id }) => id === value);
    if (!lot) return <text />;

    const maxCharacterLength = Math.floor(
      lotAxisTickWidth / APPROXIMATIVE_CHAR_WIDTH
    );
    const maxBoldCharacterLength = Math.floor(
      lotAxisTickWidth / APPROXIMATIVE_BOLD_CHAR_WIDTH
    );
    return (
      <text x={x} y={y} className="text-center" textAnchor="middle">
        <tspan x={x} dy="0.6em">
          {t("allotment number", { number: lot.reference_number })}
        </tspan>

        <tspan x={x} dy="1em" className="fw-bold">
          {
            // only display the two first conventional allotments from the list
            truncate(
              lot.conventional_allotments
                .slice(0, 2)
                .map((name) => tEnum(name))
                .join(", "),
              maxBoldCharacterLength
            )
          }
        </tspan>

        {lot.name && (
          <tspan x={x} dy="1em">
            ({truncate(lot.name, maxCharacterLength)})
          </tspan>
        )}
      </text>
    );
  };

  const getBarName = (barIndex: number, companies: ICompany[] | undefined) => {
    switch (barIndex) {
      case 0:
        return t("MOE");
      case 1:
        return t("average");
      default:
        return getCompanyByBarIndex(barIndex, companies)?.name ?? "";
    }
  };

  const renderLotBarLabel =
    (barIndex: number) =>
    ({
      x,
      y,
      height,
      width,
      id,
    }: {
      x: number;
      y: number;
      height: number;
      width: number;
      index: number;
      id: string;
    }) => {
      const lot = data?.find((lot) => id === lot.id);
      if (!lot) return <text />;
      const barName = getBarName(barIndex, lot.companies);

      const label = getDisplayableBarLabel(barName, height);

      const company = getCompanyByBarIndex(barIndex, lot.companies);

      const bestLotPrice = data?.find(
        (lot) => id === lot.id
      )?.best_price_breakdown;
      const isBest = getIsBestPriceBreakdown(bestLotPrice, company);

      const bottomY = y + height;
      const middleX = x + width / 2;
      return (
        <>
          <text
            x={middleX}
            y={bottomY}
            dx={1}
            textAnchor="left"
            fill="white"
            className={classNames(styles.rotate90deg)}
          >
            {label}
          </text>
          {isBest && (
            <StarIcon
              x={middleX - (applyFontRatioToPxSize(STAR_ICON_SIZE / 2) ?? 0)}
              // put the star atop the Bar
              y={y - 25}
              size={STAR_ICON_SIZE}
            />
          )}
        </>
      );
    };

  const renderPriceAxisLabel = ({
    viewBox: { x, y, width },
  }: {
    viewBox: {
      x: number;
      y: number;
      height: number;
      width: number;
    };
  }) => {
    return (
      // 7 and 8 are approximate margins to put the euro sign atop the axis
      <text x={x + width - 7} y={y - 8} className="fw-bold">
        €
      </text>
    );
  };

  const renderTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<string, string>) => {
    if (!active) return undefined;
    const lot = data?.find((lot) => lot.id === label);
    return lot ? (
      <Popover
        placement="right"
        id={`popover-chart-${lot.name}`}
        arrowProps={{
          style: {
            display: "none",
          },
        }}
        style={{
          position: "relative",
          inset: "0px auto auto 0px",
          margin: 0,
        }}
      >
        <Popover.Header as="h3" className="bg-info bg-opacity-10 border-0">
          {t("allotment number", { number: lot.reference_number })}
        </Popover.Header>
        <Popover.Body>
          <div>
            <strong className="me-1">
              {lot.conventional_allotments
                .map((name) => tEnum(name))
                .join(", ")}
            </strong>
            ({lot.name})
          </div>

          <ul className="list-unstyled mt-3">
            {payload?.map(({ color, value, payload: { companies } }, index) => {
              const barName = getBarName(index, companies);
              const estimation_amount = Number(value);
              return (
                <li key={index} style={{ color }}>
                  <strong>{barName}</strong>
                  {tShared("colon")}
                  {formatCurrency(estimation_amount)}
                </li>
              );
            })}
          </ul>
        </Popover.Body>
      </Popover>
    ) : null;
  };

  return (
    <BarChart
      width={(data?.length ?? 0) * lotAxisTickWidth}
      height={400}
      data={data}
      margin={{
        top: 20,
        right: 10,
        left: 10,
        bottom: 35,
      }}
    >
      <CartesianGrid strokeDasharray="3 0" vertical={false} />
      <XAxis
        dataKey="id"
        tick={renderLotAxisTick}
        // https://github.com/recharts/recharts/issues/1330#issuecomment-396612198
        interval={0}
      />
      <YAxis label={renderPriceAxisLabel} tickFormatter={formatPriceAxisTick} />
      <Tooltip content={renderTooltip} />
      {[...Array(maxBarCount)].map((_, index) => (
        <Bar
          key={index}
          dataKey={getBarDataKey(index)}
          fill={
            barFillAndColorByIndex[index % barFillAndColorByIndex.length].fill
          }
          label={renderLotBarLabel(index)}
          unit="€"
        />
      ))}
    </BarChart>
  );
}

export function LotsBarChartLegend() {
  const { t } = useTranslation("LotsBarChartLegend");
  return (
    <>
      <h6 className="fs-7">{t("title")}</h6>
      <ul className="list-unstyled">
        <li>
          <SquareIcon size={28} fill={colors.blue300} className="me-3" />
          {t("min estimation")}
        </li>
        <li>
          <StarIcon size={30} className="me-2 d-inline-block align-bottom" />{" "}
          {t("best estimation")}
        </li>
      </ul>
    </>
  );
}
