import { SortingState } from "@tanstack/react-table";

import {
  IDocument,
  IDocumentDTO,
  documentDTOToDocumentMapper,
} from "../shared/types/document.types";
import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  ILot,
  ILotDTO,
  ILotDTOToILotMapper,
  ILotMappedTenders,
  ILotSelected,
  ILotSelectedDTOToILotSelectedMapper,
} from "../shared/types/lot.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";
import { IRights } from "../shared/types/right.types";

import {
  HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
  toISODate,
} from "./APIUtils";
import {
  FETCH_METHODS_ENUM,
  authRequest,
  authRequestJSON,
  authRequestPaginatedJSON,
} from "./fetch";
import { uploadFile } from "./fetchDocuments";

export interface IFetchLotsOptions {
  operationId: string;
}

export interface IFetchLotsResult {
  results: ILotDTO[];
}

export async function fetchLots({ operationId }: IFetchLotsOptions) {
  const result = await authRequestJSON<IFetchLotsResult>(
    `allotment/?${new URLSearchParams({
      project: operationId,
      page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
    })}`
  );
  return result.results.map(ILotDTOToILotMapper);
}

export async function fetchLotsFiltered(filters: any) {
  const result = await authRequestJSON<IFetchLotsResult>(
    `allotment/?${new URLSearchParams({
      ...filters,
      page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
    })}`
  );
  return result.results.map(ILotDTOToILotMapper);
}

export async function fetchLotsMappedTendersFiltered(filters: any) {
  const result = await authRequestJSON<ILotMappedTenders[]>(
    `allotment/get-all-mapped-tenders/?${new URLSearchParams({
      ...filters,
      page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
    })}`
  );
  return result;
}

export interface IFetchLotsSelectedOptions {
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
  conventional_allotments: CONVENTIONAL_ALLOTMENTS_ENUM;
}

export async function fetchLotsSelected(
  options: IFetchLotsSelectedOptions
): Promise<IPaginatedQueryResults<ILotSelected>> {
  return authRequestPaginatedJSON(
    options,
    "allotment-selected",
    ILotSelectedDTOToILotSelectedMapper
  );
}

export async function fetchLot(id: string) {
  return ILotDTOToILotMapper(
    await authRequestJSON<ILotDTO>(`allotment/${id}/`)
  );
}

export interface ILotCreation {
  name?: string;
  conventional_allotments: string[];
  operationId: string;
  reference_number: string;
  estimation_amount?: number | null;
}

export async function createLot({
  name,
  conventional_allotments,
  reference_number,
  operationId,
  estimation_amount,
}: ILotCreation) {
  const result = await authRequestJSON<ILotDTO>(`allotment/`, {
    method: FETCH_METHODS_ENUM.POST,
    body: JSON.stringify({
      name,
      conventional_allotments,
      reference_number,
      project: operationId,
      estimation_amount,
    }),
  });
  return ILotDTOToILotMapper(result);
}

export async function createAllotmentAttachment(
  { allotment, upload_date }: Partial<IDocument>,
  file: File,
  percentageCallback: (percentage: number) => void
) {
  if (!allotment) {
    throw new Error("Missing allotment id to create allotment attachment");
  }
  if (!upload_date) {
    throw new Error("Missing upload_date to create allotment attachment");
  }

  const documentDTO = await authRequestJSON<IDocumentDTO>(
    `allotment-attachment/`,
    {
      method: FETCH_METHODS_ENUM.POST,
      body: JSON.stringify({
        allotment,
        upload_date: toISODate(upload_date),
        file_name: file.name,
      }),
    }
  );
  const document = documentDTOToDocumentMapper(documentDTO);
  await uploadFile(document, file, percentageCallback);
  return document;
}

// TUpdateLot is a partial Lot with id being required, and additional fields for the update
// this allows a partial PATCH while keeping the typing benefits from ILot
export type TUpdateLot = Partial<Omit<ILot, "estimation_amount">> &
  Pick<ILot, "id"> & {
    estimation_amount?: number | null;
    discount_amount?: number;
    rights?: IRights;
    selected_company?: string;
    no_estimation_amount?: boolean;
  };

export async function updateAllotment({
  // the following fields can't get updated
  project,
  min_price_breakdowns,
  max_price_breakdowns,
  best_price_breakdown,
  avg_estimation_amount,
  selected_company_name,
  rights,
  project_name,
  discount_amount,
  selected_company,
  no_estimation_amount,
  closure_date,
  // fields used for an update, id is used as the identifier
  id,
  ...fieldsToUpdate
}: TUpdateLot) {
  const result = await authRequestJSON<ILot>(`allotment/${id}/`, {
    method: FETCH_METHODS_ENUM.PATCH,
    body: JSON.stringify(fieldsToUpdate),
  });
  return result;
}

export async function deleteAllotment(id: string) {
  return authRequest(`allotment/${id}/`, {
    method: FETCH_METHODS_ENUM.DELETE,
  });
}

export async function deleteAllotmentAttachment(id: string) {
  return authRequest(`allotment-attachment/${id}/`, {
    method: FETCH_METHODS_ENUM.DELETE,
  });
}
