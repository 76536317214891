import React, { useMemo } from "react";
import { Noop } from "react-hook-form";

import { useEnumTranslation } from "../i18n";
import { STATUS_ENUM } from "../types/operation.types";

import { Select, TValue } from "./select";

const statusList = Object.values(STATUS_ENUM);
export interface IStatusSelectProps {
  onChange?(value?: TValue | TValue[]): void;
  onBlur?: Noop;
  value?: STATUS_ENUM;
  clearable?: boolean;
}

export const StatusSelect = React.forwardRef<
  HTMLInputElement,
  IStatusSelectProps
>(function StatusSelect({ onChange, onBlur, value, clearable }, ref) {
  const { tEnum } = useEnumTranslation();
  const statusOptions = useMemo(
    () => statusList.map((status) => ({ value: status, label: tEnum(status) })),
    [tEnum]
  );
  return (
    <Select
      options={statusOptions}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      ref={ref}
      clearable={clearable}
      searchable
      hideValueOnSearch
    />
  );
});
