import classNames from "classnames";
import { useId, useState } from "react";
import Form from "react-bootstrap/Form";
import Popover from "react-bootstrap/Popover";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  PieLabel,
  Tooltip,
  TooltipProps,
} from "recharts";

import colors from "../shared/_exports.module.scss";
import {
  formatCurrency,
  formatPercentage,
  useTranslation,
} from "../shared/i18n";
import { SquareIcon } from "../shared/icons";
import { truncate } from "../shared/utils";

import { ChartsCard } from "./ChartsCard";
import styles from "./OperationEstimationPieChart.module.scss";
import { DATA_KEYS_ENUM, ILotChartData } from "./chart.types";
import { useOperationLotsChartData } from "./useOperationLotsChartData";

interface IEstimationPieChartData {
  id: string;
  name?: string;
  conventional_allotments: string[];
  reference_number: string;
  estimation_amount?: number;
  min_price_breakdown_estimation_amount?: number;
  best_price_breakdown_estimation_amount?: number;
}

function filterLotsChartToEstimationPieChartData(
  data: ILotChartData[]
): IEstimationPieChartData[] {
  return data.map(
    ({
      id,
      name,
      reference_number,
      estimation_amount,
      min_price_breakdowns,
      best_price_breakdown,
      conventional_allotments,
    }) => ({
      id,
      name,
      conventional_allotments,
      reference_number,
      [DATA_KEYS_ENUM.MOE]: estimation_amount,
      [DATA_KEYS_ENUM.BEST]: best_price_breakdown?.estimation_amount,
      [DATA_KEYS_ENUM.MIN]:
        min_price_breakdowns?.find(Boolean)?.estimation_amount,
    })
  );
}

const cellColorsByIndex = [
  { fill: colors.blue300, label: colors.white },
  { fill: colors.blue, label: colors.white },
  { fill: colors.blue700, label: colors.white },
  { fill: colors.teal300, label: colors.black },
  { fill: colors.teal500, label: colors.white },
  { fill: colors.teal700, label: colors.white },
  { fill: colors.teal900, label: colors.white },
  { fill: colors.blue200, label: colors.white },
  { fill: colors.blue400, label: colors.white },
  { fill: colors.teal800, label: colors.white },
];

const RADIAN = Math.PI / 180;
const MIN_PERCENT_VALUE_TO_DISPLAY = 0.05;
const LABEL_OFFSET_MARGIN = 15;
const renderPercentageLabel: PieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  if (percent < MIN_PERCENT_VALUE_TO_DISPLAY) return <text />;
  const isIndexOdd = Boolean(index % 2);
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  // avoid label overlap by applying an offset from its center
  const radiusOffset = LABEL_OFFSET_MARGIN * (isIndexOdd ? -1 : 1);
  const distanceFromPieCenter = radius + radiusOffset;
  const x = cx + distanceFromPieCenter * Math.cos(-midAngle * RADIAN);
  const y = cy + distanceFromPieCenter * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill={cellColorsByIndex[index % cellColorsByIndex.length]?.label}
      textAnchor="middle"
      dominantBaseline="central"
      fontWeight="bold"
    >
      {formatPercentage(percent, "never")}
    </text>
  );
};

const ARBITRARY_LABEL_MAX_CHARACTER_LENGTH = 50;

export function OperationEstimationPieChart() {
  const { t } = useTranslation("OperationEstimationPieChart");
  const { t: tEnum } = useTranslation("enum");
  const componentId = useId();
  const { lotsChartData } = useOperationLotsChartData<IEstimationPieChartData>(
    filterLotsChartToEstimationPieChartData
  );
  const [dataKey, setDataKey] = useState(DATA_KEYS_ENUM.MOE);

  const renderTooltip = ({ active, payload }: TooltipProps<number, string>) => {
    if (!active) return undefined;
    const [{ name: id, value }] = payload ?? [{}];
    const lotChartData = lotsChartData.find(
      (lotChartData) => id === lotChartData.id
    );
    return lotChartData ? (
      <Popover
        placement="right"
        id={`popover-pie-chart-${componentId}`}
        arrowProps={{
          style: {
            display: "none",
          },
        }}
        style={{ position: "relative", inset: "0px auto auto 0px", margin: 0 }}
      >
        <Popover.Header as="h3" className="bg-info bg-opacity-10 border-0">
          {t("allotment number", { number: lotChartData.reference_number })}
        </Popover.Header>
        <Popover.Body>
          <div className="mb-3">
            <strong className="me-1">
              {lotChartData.conventional_allotments
                .map((name) => tEnum(name))
                .join(", ")}
            </strong>
            ({lotChartData.name})
          </div>
          <strong>{formatCurrency(value)}</strong>
        </Popover.Body>
      </Popover>
    ) : null;
  };

  return (
    <ChartsCard className="ms-n3 me-n3 pe-0">
      <h6 className="mb-3">{t("title")}</h6>
      <Form className="mb-3">
        {[
          {
            label: t("select MOE"),
            id: "radio-select-pm",
            value: DATA_KEYS_ENUM.MOE,
            disabled: lotsChartData.every(
              (lotChartData) => !lotChartData[DATA_KEYS_ENUM.MOE]
            ),
          },
          {
            label: t("select min"),
            id: "radio-select-min",
            value: DATA_KEYS_ENUM.MIN,
          },
          {
            label: t("select best"),
            id: "radio-select-best",
            value: DATA_KEYS_ENUM.BEST,
            disabled: lotsChartData.every(
              (lotChartData) => !lotChartData[DATA_KEYS_ENUM.BEST]
            ),
          },
        ].map(({ label, id, value, disabled }) => (
          <Form.Check
            inline
            label={label}
            name="filterPieChart"
            type="radio"
            value={value}
            id={id}
            key={id}
            onChange={({ target: { value } }) =>
              setDataKey(value as DATA_KEYS_ENUM)
            }
            checked={dataKey === value}
            disabled={disabled}
          />
        ))}
      </Form>
      <PieChart
        width={730}
        height={465}
        className={classNames("ms-n5 me-5 w-100", styles["pie-chart"])}
      >
        <Pie
          data={lotsChartData}
          dataKey={dataKey}
          nameKey="id"
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderPercentageLabel}
        >
          {lotsChartData.map((lot, index) => {
            const { fill } =
              cellColorsByIndex[index % cellColorsByIndex.length];
            return <Cell key={lot.id} fill={fill} />;
          })}
        </Pie>
        <Tooltip content={renderTooltip} />
        <Legend
          align="right"
          verticalAlign="middle"
          layout="vertical"
          height={400}
          content={({ payload }) => (
            <ul className="list-unstyled overflow-auto h-100">
              {payload?.map(({ color, payload }) => {
                const lotChartData = lotsChartData.find(
                  // weird rechart typing but does contains lotChartData merged in its sub payload
                  ({ id }) => id === (payload as any).id
                );
                if (!lotChartData) {
                  return null;
                }
                const displayedName =
                  lotChartData.conventional_allotments.length ||
                  lotChartData.name ? (
                    <>
                      <span className="me-2">
                        {t("allotment number", {
                          number: lotChartData.reference_number,
                        })}
                        ,
                      </span>
                      <strong>
                        {lotChartData.conventional_allotments.length &&
                          truncate(
                            lotChartData.conventional_allotments
                              .map((name) => tEnum(name))
                              .join(", "),
                            ARBITRARY_LABEL_MAX_CHARACTER_LENGTH
                          )}
                      </strong>
                      {lotChartData.name &&
                        ` (${truncate(
                          lotChartData.name,
                          ARBITRARY_LABEL_MAX_CHARACTER_LENGTH
                        )})`}
                    </>
                  ) : (
                    t("allotment number", {
                      number: lotChartData.reference_number,
                    })
                  );
                return (
                  <li key={lotChartData.id} className="mb-2">
                    <SquareIcon fill={color} className="me-2" />
                    {displayedName}
                  </li>
                );
              })}
            </ul>
          )}
        />
      </PieChart>
    </ChartsCard>
  );
}
