import classNames from "classnames";
import React from "react";

import styles from "../_exports.module.scss";
import { ifTest } from "../utils";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function SquareIcon({
  fill = styles.gray400,
  className = "",
  size = 24,
  ...props
}) {
  return (
    <div
      data-test={ifTest("square-icon")}
      style={{
        width: applyFontRatioToPxSize(size),
        height: applyFontRatioToPxSize((size * 24) / 25),
        backgroundColor: fill,
      }}
      className={classNames("rounded-2 d-inline-block align-middle", className)}
      {...props}
    ></div>
  );
}
