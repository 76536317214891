import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function DropDownArrowIcon({
  className = "",
  fill = styles.black,
  size = 24,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize((size / 24) * 25)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#drop-down-arrow)">
        <path
          fill={fill}
          d="M12.707 15.793a1 1 0 0 1-1.414 0l-3.586-3.586c-.63-.63-.184-1.707.707-1.707h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586Z"
        />
      </g>
      <defs>
        <clipPath id="drop-down-arrow">
          <path fill="transparent" d="M0 .5h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
