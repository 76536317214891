import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function ListCircleIcon({
  className = "",
  fill = styles.blue300,
  size = 30,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize((size / 30) * 31)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x=".75" width="30" height="30" rx="15" fill={fill} />
      <g clipPath="url(#ListCircleIconClipPath)">
        <path
          d="M12.417 9h9.208v1.5h-9.208V9Zm-2.48 1.875c-.281 0-.552-.118-.75-.33a1.16 1.16 0 0 1-.312-.795c0-.298.112-.585.311-.796.2-.21.47-.329.752-.329.281 0 .551.119.75.33.2.21.312.497.312.795 0 .298-.112.585-.311.796-.2.21-.47.329-.752.329Zm0 5.25c-.281 0-.552-.119-.75-.33A1.16 1.16 0 0 1 8.874 15c0-.298.112-.585.311-.796.2-.21.47-.329.752-.329.281 0 .551.118.75.33.2.21.312.497.312.795 0 .298-.112.585-.311.796-.2.21-.47.329-.752.329Zm0 5.175c-.281 0-.552-.119-.75-.33a1.16 1.16 0 0 1-.312-.795c0-.298.112-.585.311-.796.2-.21.47-.329.752-.329.281 0 .551.119.75.33.2.21.312.497.312.795 0 .298-.112.584-.311.796-.2.21-.47.329-.752.329Zm2.48-7.05h9.208v1.5h-9.208v-1.5Zm0 5.25h9.208V21h-9.208v-1.5Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="ListCircleIconClipPath">
          <path fill="#fff" transform="translate(6.75 6)" d="M0 0h17v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
