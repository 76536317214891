export enum PATH_NAMES_ENUM {
  ADMIN = "admin",
  LOGIN = "login",
  ACCOUNT_CREATION = "account-creation",
  OPERATIONS = "operations",
  LOTS = "lots",
  OFFERS = "offers",
  COMPARE = "compare",
  SAVED_COMPARISONS = "saved-comparisons",
  DOCUMENTS = "documents",
  MOE = "MOE",
  ACCOUNTS = "accounts",
  STANDARD_DOCUMENT = "standard-document",
  MAPPING = "mapping",
  ADMIN_ROW_TYPE = "admin-row-type",
  CLASSIFICATION_TABLE = "classification-table",
  CHARTS = "charts",
  PRICE_AVERAGE = "price-average",
}

export enum DEV_PATH_NAMES_ENUM {
  DEV = "dev",
  DUMP_RAW_EXCEL = "dump-raw-excel",
}
