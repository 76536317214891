import { yupResolver } from "@hookform/resolvers/yup";
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { FieldErrors, UseFormRegister, useForm } from "react-hook-form";
import * as yup from "yup";

import { createOrUpdatePriceAveragePanel } from "../api/fetchPriceAverage";
import { DefaultModal } from "../shared/DefaultModal";
import { OverwriteOrSaveAsNew } from "../shared/OverwriteOrSaveAsNew";
import { IBackErrors, RootError, useBackFieldErrors } from "../shared/errors";
import { useTranslation, yupLocale } from "../shared/i18n";
import { Input, getInputProps } from "../shared/inputs";
import {
  IPriceAveragePanel,
  IPriceAveragePanelDTO,
  priceAveragePanelToPriceAveragePanelDTOMapper,
} from "../shared/types/averagePrice.types";
import { useHasChanged } from "../shared/useHasChanged";
import { QUERY_KEYS_ENUM } from "../shared/useSharedQueries";
import { ifTest } from "../shared/utils";

import { defaultPriceAverageSettings } from "./PriceAverageSettingsModal";

yup.setLocale(yupLocale);

const schema = yup.object({
  name: yup.string().required(),
  description: yup.string(),
});

export interface IPriceAveragePanelModalProps {
  show: boolean;
  handleClose: (priceAveragePanel?: IPriceAveragePanelDTO) => void;
  priceAveragePanel?: IPriceAveragePanel;
}

export type TSavingFormInputs = yup.InferType<typeof schema>;

function PriceAveragePanelForm({
  register,
  errors,
  priceAveragePanelCreation,
}: {
  register: UseFormRegister<any>;
  errors: FieldErrors<TSavingFormInputs>;
  priceAveragePanelCreation: UseMutationResult<
    IPriceAveragePanelDTO,
    unknown,
    Partial<IPriceAveragePanelDTO>,
    unknown
  >;
}) {
  const { t } = useTranslation("PriceAveragePanelModal");
  return (
    <>
      <Row className="mb-3">
        <Input
          as={Col}
          {...getInputProps("name", register, schema, errors)}
          label={t("name")}
        />
      </Row>
      <Row className="mb-5">
        <Input
          as={Col}
          {...getInputProps("description", register, schema, errors)}
          label={t("description")}
        />
      </Row>
      <RootError errors={errors} />
      <Row>
        <Col className="text-center mb-3 px-4">
          <Button
            size="lg"
            variant="dark"
            type="submit"
            className="mx-4 text-center"
            disabled={priceAveragePanelCreation.isLoading}
            data-test={ifTest("price-average-panel-modal")}
          >
            {t("save")}
            {priceAveragePanelCreation.isLoading && (
              <Spinner size="sm" className="ms-2" />
            )}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export function PriceAveragePanelModal({
  show,
  handleClose,
  priceAveragePanel,
  backErrors,
}: IPriceAveragePanelModalProps & {
  backErrors?: IBackErrors<TSavingFormInputs>;
}) {
  const { t } = useTranslation("PriceAveragePanelModal");
  const [isExistingPriceAveragePanel, setIsExistingPriceAveragePanel] =
    useState(false);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TSavingFormInputs>({
    resolver: yupResolver(schema),
    values: priceAveragePanel,
    mode: "onSubmit",
  });
  const onClose = (priceAveragePanel?: IPriceAveragePanelDTO) => {
    setIsExistingPriceAveragePanel(Boolean(priceAveragePanel?.id));
    handleClose(priceAveragePanel);
  };
  useBackFieldErrors(backErrors, setError, clearErrors);
  const queryClient = useQueryClient();
  const priceAveragePanelCreation = useMutation({
    mutationFn: createOrUpdatePriceAveragePanel,
    onSuccess: (priceAveragePanel) => {
      queryClient.invalidateQueries([QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS]);
      onClose(priceAveragePanel);
      setIsExistingPriceAveragePanel(Boolean(priceAveragePanel?.id));
    },
  });
  const priceAveragePanelIdHasChanged = useHasChanged(priceAveragePanel?.id);
  if (priceAveragePanelIdHasChanged) {
    setIsExistingPriceAveragePanel(Boolean(priceAveragePanel?.id));
  }

  return (
    <DefaultModal
      show={show}
      title={t("title")}
      handleClose={() => onClose()}
      staticBackdrop
    >
      <Form
        onSubmit={handleSubmit((formInputs) => {
          const { name, description } = formInputs;
          const newPriceAveragePanel = {
            id: isExistingPriceAveragePanel ? priceAveragePanel?.id : undefined,
            name: isExistingPriceAveragePanel ? undefined : name,
            description: isExistingPriceAveragePanel ? undefined : description,
          };

          priceAveragePanelCreation.mutate(newPriceAveragePanel);
        })}
        className="pb-4"
      >
        {isExistingPriceAveragePanel ? (
          <>
            <Row className="mb-4 text-center">
              <Col>{t("should save on or as")}</Col>
            </Row>
            <Row className="my-5">
              <Col className="mx-3 text-end">
                <Button
                  size="lg"
                  variant="primary"
                  type="submit"
                  className="text-center rectangular"
                  disabled={priceAveragePanelCreation.isLoading}
                >
                  {t("overwrite")}
                  {priceAveragePanelCreation.isLoading && (
                    <Spinner size="sm" className="ms-2" />
                  )}
                </Button>
              </Col>
              <Col className="mx-3 text-start">
                <Button
                  size="lg"
                  variant="dark"
                  className="text-center rectangular"
                  onClick={() => setIsExistingPriceAveragePanel(false)}
                >
                  {t("save as")}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <PriceAveragePanelForm
            register={register}
            errors={errors}
            priceAveragePanelCreation={priceAveragePanelCreation}
          />
        )}
      </Form>
    </DefaultModal>
  );
}

export interface IpriceAveragePanelEditModalProps {
  show: boolean;
  handleClose: (priceAveragePanel?: IPriceAveragePanelDTO) => void;
  priceAveragePanel?: IPriceAveragePanel;
  askWhenOverwriting?: boolean;
}

export function PriceAveragePanelEditModal({
  show,
  handleClose,
  priceAveragePanel,
  backErrors,
  askWhenOverwriting = false,
}: IpriceAveragePanelEditModalProps & {
  backErrors?: IBackErrors<TSavingFormInputs>;
}) {
  const { t } = useTranslation("priceAveragePanelEditModal");
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TSavingFormInputs>({
    resolver: yupResolver(schema),
    values: priceAveragePanel,
    mode: "onSubmit",
  });
  const onClose = (priceAveragePanel?: IPriceAveragePanelDTO) => {
    handleClose(priceAveragePanel);
  };
  useBackFieldErrors(backErrors, setError, clearErrors);
  const queryClient = useQueryClient();
  const priceAveragePanelCreation = useMutation({
    mutationFn: createOrUpdatePriceAveragePanel,
    onSuccess: (priceAveragePanel) => {
      queryClient.invalidateQueries([QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS]);
      onClose(priceAveragePanel);
    },
  });

  const [shouldAskToSaveOnOrNewPanel, setShouldAskToSaveOnOrNewPanel] =
    useState(askWhenOverwriting);

  return (
    <DefaultModal
      show={show}
      title={t("title")}
      handleClose={() => onClose()}
      staticBackdrop
    >
      <Form
        onSubmit={handleSubmit((formInputs) => {
          const { name, description } = formInputs;
          const newPriceAveragePanel: Partial<IPriceAveragePanel> = {
            id: shouldAskToSaveOnOrNewPanel ? priceAveragePanel?.id : undefined,
            ignored_classifications: {},
            settings: defaultPriceAverageSettings,
            ...(priceAveragePanel ?? {}),
            name,
            description,
          };
          priceAveragePanelCreation.mutate(
            priceAveragePanelToPriceAveragePanelDTOMapper(newPriceAveragePanel)
          );
          if (priceAveragePanel?.id) {
            queryClient.setQueryData(
              [QUERY_KEYS_ENUM.PRICE_AVERAGE_PANELS, priceAveragePanel.id],
              newPriceAveragePanel
            );
          }
        })}
        className="pb-4"
      >
        {shouldAskToSaveOnOrNewPanel ? (
          <OverwriteOrSaveAsNew
            t={t}
            isLoading={priceAveragePanelCreation.isLoading}
            setShouldAskToSaveOnOrNew={setShouldAskToSaveOnOrNewPanel}
          />
        ) : (
          <PriceAveragePanelForm
            register={register}
            errors={errors}
            priceAveragePanelCreation={priceAveragePanelCreation}
          />
        )}
      </Form>
    </DefaultModal>
  );
}
