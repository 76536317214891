import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Control } from "react-hook-form";
import * as yup from "yup";

import { useTranslation } from "../../../shared/i18n";
import {
  ControlledInput,
  CoolingSelect,
  HeatingSelect,
  VentilationSelect,
} from "../../../shared/inputs";
import { getUndefinedSafeSelectOnChange } from "../../../shared/inputs/input";
import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  LOT_CLOSURE_COOLING_ENUM,
  LOT_CLOSURE_HEATING_ENUM,
  LOT_CLOSURE_VENTILATION_ENUM,
} from "../../../shared/types/lot.types";

import { yupRequiredWhenConventionalAllotmentIncludes } from "./utils";

function yupRequiredWhen<S>(schema: yup.Schema<S>) {
  return yupRequiredWhenConventionalAllotmentIncludes(
    schema,
    CONVENTIONAL_ALLOTMENTS_ENUM.HVAC
  );
}

export const schema = yup.object({
  heating: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_HEATING_ENUM))
  ),
  cooling: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_COOLING_ENUM))
  ),
  ventilation: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_VENTILATION_ENUM))
  ),
});

export type TFormInputs = yup.InferType<typeof schema>;

export function HVACAllotment({ control }: { control: Control<TFormInputs> }) {
  const { t } = useTranslation("LotClosureModal");

  return (
    <>
      <h6 className="my-4">{t("hvac title")}</h6>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("heating")}
          name="heating"
          schema={schema}
          control={control}
          render={({ field }) => (
            <HeatingSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("cooling")}
          name="cooling"
          schema={schema}
          control={control}
          render={({ field }) => (
            <CoolingSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
      </Row>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("ventilation")}
          name="ventilation"
          schema={schema}
          control={control}
          render={({ field }) => (
            <VentilationSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
      </Row>
    </>
  );
}
