import * as yup from "yup";

import { yupRequiredWhenIncludes } from "../../../shared/inputs/input";
import { CONVENTIONAL_ALLOTMENTS_ENUM } from "../../../shared/types/lot.types";

export function yupRequiredWhenConventionalAllotmentIncludes<
  Schema,
  ModifierSchema extends yup.Schema | yup.ArraySchema<any, any>
>(
  schema: yup.Schema<Schema>,
  value: CONVENTIONAL_ALLOTMENTS_ENUM,
  includesModifier?: (field: ModifierSchema) => ModifierSchema
) {
  return yupRequiredWhenIncludes(
    schema,
    { conventional_allotments: value },
    includesModifier
  );
}
