import { formatTolerant } from "../shared/i18n/dateFormatter";

export const toISODate = (date?: Date | null) =>
  date != null ? formatTolerant(date, "yyyy-MM-dd'T'HH:mm:ss'Z'") : undefined;

export const decimalStringToNumber = (decimal: string | undefined) =>
  decimal === undefined ? decimal : Number(decimal);

export const HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE = "100000";

export const getSetSearchParamsCallbackWithLastUpdatedSearchParams = (
  callback: (searchParams: URLSearchParams) => URLSearchParams
) => {
  // workaround https://github.com/remix-run/react-router/issues/9757#issuecomment-1358336279
  const searchParams = new URLSearchParams(window.location.search);
  return callback(searchParams);
};
