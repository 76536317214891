import classNames from "classnames";
import React, { Fragment, ReactNode } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form";

import colors from "../../shared/_exports.module.scss";
import { useTranslation } from "../../shared/i18n";
import { CloseCircledIcon } from "../../shared/icons";

interface IMinMaxFieldProps {
  filterKey: string;
  unit?: string;
  type: string;
  step?: number;
  label?: ReactNode;
  keys?: [string, string];
  keysLabels?: [string, string];
  register: (name: any, options?: RegisterOptions) => UseFormRegisterReturn;
  watch: (name: any) => any;
  handleClear?: () => void;
  handleOnBlur?: () => void;
}

export function MinMaxField({
  filterKey,
  unit,
  type,
  step,
  label,
  // key suffixes: => `${filterKey}_${keys[0|1]}`
  keys = ["gte", "lte"],
  keysLabels,
  register,
  handleClear,
  handleOnBlur,
  watch,
}: IMinMaxFieldProps) {
  const { t } = useTranslation("MinMaxField");

  const min = watch(`${filterKey}_${keys[0]}`);
  const max = watch(`${filterKey}_${keys[1]}`);

  const showClearIcon =
    Boolean(handleClear) && (min !== undefined || max !== undefined);

  return (
    <>
      {label !== undefined && <Form.Label className="mb-0">{label}</Form.Label>}
      <InputGroup className="mb-3">
        {keys.map((fieldName, index) => {
          const registered = register(`${filterKey}_${fieldName}`);
          return (
            <Fragment key={fieldName}>
              <InputGroup.Text className="bg-light">
                {keysLabels?.[index] ?? t(fieldName)}
              </InputGroup.Text>
              <Form.Control
                className="number-input-no-arrows"
                min={fieldName === keys[0] ? 0 : min ?? 0}
                max={fieldName === keys[1] ? undefined : max}
                {...registered}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleOnBlur?.();
                  }
                }}
                onBlur={(e) => {
                  registered.onBlur?.(e);
                  handleOnBlur?.();
                }}
                step={step}
                type={type}
              />
              {(unit !== undefined || index === 0) && (
                <InputGroup.Text
                  className={classNames(
                    "bg-light",
                    unit === undefined && "p-0"
                  )}
                >
                  {unit}
                </InputGroup.Text>
              )}
            </Fragment>
          );
        })}
        {showClearIcon && (
          <Button size="sm" variant="outline-input" onClick={handleClear}>
            <CloseCircledIcon fill={colors.secondary} />
          </Button>
        )}
      </InputGroup>
    </>
  );
}
