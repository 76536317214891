import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getSetSearchParamsCallbackWithLastUpdatedSearchParams } from "../../api/APIUtils";
import {
  FILE_TYPE_ENUM,
  IPriceEstimate,
} from "../../shared/types/estimate.types";
import { DIFFERENCE_DISPLAY_MODE_ENUM } from "../../shared/visualization/Difference";
import {
  COMPARE_DIFFERENCE_BASES_ENUM,
  ICompareDifferenceSettings,
} from "../Compare.types";

const NUMBER_DIFFERENCE_ACTIVE_SEARCH_PARAM = "number_difference_active";
const NUMBER_DIFFERENCE_MODE_SEARCH_PARAM = "number_difference_mode";
const NUMBER_DIFFERENCE_BASE_SEARCH_PARAM = "number_difference_base";
const NUMBER_DIFFERENCE_POSITIVE_THRESHOLD_SEARCH_PARAM =
  "number_difference_positive_threshold";
const NUMBER_DIFFERENCE_NEGATIVE_THRESHOLD_SEARCH_PARAM =
  "number_difference_negative_threshold";
const LINE_DIFFERENCE_ACTIVE_SEARCH_PARAM = "line_difference_active";

function getBooleanFromSearchParam(searchParam?: string | null) {
  return searchParam === "true";
}

function getNumberFromSearchParam(searchParam?: string | null) {
  if (searchParam) {
    const searchParamAsNumber = Number(searchParam);
    if (!isNaN(searchParamAsNumber)) {
      return searchParamAsNumber;
    }
  }
  return undefined;
}

function getDifferenceDisplayModeFromSearchParam(
  searchParam?: string | null
): DIFFERENCE_DISPLAY_MODE_ENUM {
  if (
    Object.values(DIFFERENCE_DISPLAY_MODE_ENUM).some(
      (base: string) => base === searchParam
    )
  ) {
    return searchParam as DIFFERENCE_DISPLAY_MODE_ENUM;
  }
  return DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT;
}

function getCompareDifferenceBasesOrStringFromSearchParam(
  searchParam?: string | null
): string | COMPARE_DIFFERENCE_BASES_ENUM | undefined {
  if (
    Object.values(COMPARE_DIFFERENCE_BASES_ENUM).some(
      (base: string) => base === searchParam
    )
  ) {
    return searchParam as COMPARE_DIFFERENCE_BASES_ENUM;
  }
  return searchParam ?? undefined;
}
export function setSearchParamsFromCompareDifferenceSettings(
  searchParams: URLSearchParams,
  compareDifferenceSettings: ICompareDifferenceSettings
) {
  compareDifferenceSettings.number_difference.active
    ? searchParams.set(NUMBER_DIFFERENCE_ACTIVE_SEARCH_PARAM, "true")
    : searchParams.delete(NUMBER_DIFFERENCE_ACTIVE_SEARCH_PARAM);

  searchParams.set(
    NUMBER_DIFFERENCE_MODE_SEARCH_PARAM,
    compareDifferenceSettings.number_difference.mode
  );

  searchParams.set(
    NUMBER_DIFFERENCE_BASE_SEARCH_PARAM,
    compareDifferenceSettings.number_difference.base
  );

  searchParams.set(
    NUMBER_DIFFERENCE_POSITIVE_THRESHOLD_SEARCH_PARAM,
    String(compareDifferenceSettings.number_difference.positive_threshold)
  );

  searchParams.set(
    NUMBER_DIFFERENCE_NEGATIVE_THRESHOLD_SEARCH_PARAM,
    String(compareDifferenceSettings.number_difference.negative_threshold)
  );

  compareDifferenceSettings.line_difference_active
    ? searchParams.set(LINE_DIFFERENCE_ACTIVE_SEARCH_PARAM, "true")
    : searchParams.delete(LINE_DIFFERENCE_ACTIVE_SEARCH_PARAM);
  return searchParams;
}

export function useCompareDifferenceSettings(
  isInitialLoading: boolean,
  estimate?: IPriceEstimate
): [
  ICompareDifferenceSettings | undefined,
  (compareDifferenceSettings: ICompareDifferenceSettings) => void
] {
  // we use searchParams as source of truth to keep the compareDifference state
  const [searchParams, setSearchParams] = useSearchParams();
  const number_difference_active = getBooleanFromSearchParam(
    searchParams.get(NUMBER_DIFFERENCE_ACTIVE_SEARCH_PARAM)
  );
  const number_difference_mode = getDifferenceDisplayModeFromSearchParam(
    searchParams.get(NUMBER_DIFFERENCE_MODE_SEARCH_PARAM)
  );
  const number_difference_base =
    getCompareDifferenceBasesOrStringFromSearchParam(
      searchParams.get(NUMBER_DIFFERENCE_BASE_SEARCH_PARAM)
    );
  const number_difference_positive_threshold = getNumberFromSearchParam(
    searchParams.get(NUMBER_DIFFERENCE_POSITIVE_THRESHOLD_SEARCH_PARAM)
  );
  const number_difference_negative_threshold = getNumberFromSearchParam(
    searchParams.get(NUMBER_DIFFERENCE_NEGATIVE_THRESHOLD_SEARCH_PARAM)
  );
  const line_difference_active = getBooleanFromSearchParam(
    searchParams.get(LINE_DIFFERENCE_ACTIVE_SEARCH_PARAM)
  );
  // those should always have a value if correctly initialized
  const hasCompareDifferenceSettingsInSearchParams =
    number_difference_base !== undefined &&
    number_difference_positive_threshold !== undefined &&
    number_difference_negative_threshold !== undefined;

  // create a stable ICompareDifferenceSettings object from all those searchParams
  const compareDifferenceSettings = useMemo<
    ICompareDifferenceSettings | undefined
  >(
    () =>
      hasCompareDifferenceSettingsInSearchParams
        ? {
            number_difference: {
              active: number_difference_active,
              mode: number_difference_mode,
              base: number_difference_base,
              positive_threshold: number_difference_positive_threshold,
              negative_threshold: number_difference_negative_threshold,
            },
            line_difference_active: line_difference_active,
          }
        : undefined,
    [
      hasCompareDifferenceSettingsInSearchParams,
      line_difference_active,
      number_difference_mode,
      number_difference_active,
      number_difference_base,
      number_difference_negative_threshold,
      number_difference_positive_threshold,
    ]
  );

  const setCompareDifferenceSettings = useCallback(
    (compareDifferenceSettings: ICompareDifferenceSettings) =>
      setSearchParams(
        getSetSearchParamsCallbackWithLastUpdatedSearchParams((searchParams) =>
          setSearchParamsFromCompareDifferenceSettings(
            searchParams,
            compareDifferenceSettings
          )
        ),
        { replace: true }
      ),
    [setSearchParams]
  );

  useEffect(() => {
    if (
      !isInitialLoading &&
      Boolean(!compareDifferenceSettings) &&
      Boolean(estimate)
    ) {
      // init compare difference settings based on tender's average if estimate is a frame and estimate if not
      const initialDifferenceBase =
        estimate?.file_type === FILE_TYPE_ENUM.FRAME
          ? COMPARE_DIFFERENCE_BASES_ENUM.AVERAGE
          : COMPARE_DIFFERENCE_BASES_ENUM.ESTIMATE;
      setCompareDifferenceSettings({
        ...defaultCompareDifferenceSettings,
        number_difference: {
          ...defaultCompareDifferenceSettings.number_difference,
          base: initialDifferenceBase,
        },
      });
    }
  }, [
    compareDifferenceSettings,
    estimate,
    isInitialLoading,
    setCompareDifferenceSettings,
  ]);

  return [compareDifferenceSettings, setCompareDifferenceSettings];
}

export const defaultCompareDifferenceSettings = {
  number_difference: {
    active: true,
    mode: DIFFERENCE_DISPLAY_MODE_ENUM.PERCENT,
    base: COMPARE_DIFFERENCE_BASES_ENUM.ESTIMATE,
    positive_threshold: 15,
    negative_threshold: 15,
  },
  line_difference_active: true,
};
