import classNames from "classnames";
import React, { ReactElement, ReactNode } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FormGroupProps } from "react-bootstrap/FormGroup";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/esm/helpers";
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
  UseFormStateReturn,
} from "react-hook-form";
import * as yup from "yup";
import {
  SchemaDescription,
  SchemaFieldDescription,
  SchemaObjectDescription,
} from "yup";

import { FormControlError } from "../errors/FormControlError";
import { CircleQuestionSmallIcon } from "../icons";

import { RequiredAsterisk } from "./RequiredAsterisk";
import { CheckBox } from "./checkbox";
import styles from "./input.module.scss";

// only inferable values from yup field's type for now
export enum INPUT_TYPES_ENUM {
  TEXT = "text",
  EMAIL = "email",
  NUMBER = "number",
  DATE = "date",
  CHECKBOX = "checkbox",
}

type TBaseInputProps<
  T extends FieldValues,
  As extends React.ElementType = "div",
  TName = FieldPath<T>
> = {
  name: TName;
  label?: ReactNode;
  tooltip?: ReactNode;
} & React.PropsWithChildren<
  ReplaceProps<As, BsPrefixProps<As> & FormGroupProps>
>;

export type TInputProps<
  T extends FieldValues,
  As extends React.ElementType
> = TBaseInputProps<T, As> & {
  register: UseFormRegister<T>;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  error?: FieldError;
  inputGroupBefore?: ReactNode;
  inputGroupAfter?: ReactNode;
  autoFocus?: boolean;
  autoComplete?: string;
  formControlProps?: FormControlProps;
};

export type TControlledInputProps<
  T extends FieldValues,
  As extends React.ElementType,
  TName extends FieldPath<T> = FieldPath<T>
> = TBaseInputProps<T, As, TName> & {
  control?: Control<T>;
  render(props: {
    field: ControllerRenderProps<T, TName>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<T>;
  }): ReactElement;
  schema: yup.ObjectSchema<T>;
};

/**
 * custom yup.number which allows for spaces and comas and normalizes it using normalizeNumber
 * @returns yup.number().transform(transformNumber)
 */
export function yupNumber() {
  return yup.number().transform(transformNumber);
}

/**
 * custom yup helper to make a field required if the dependencies contain the provided values
 * @param schema
 * @param dependencies key:value dictionary of the dependencies
 * @param includesModifier callback to apply additional modifiers if the dependencies contain the provided values
 * @returns
 */
export function yupRequiredWhenIncludes<
  Schema,
  Type extends yup.AnyObject,
  ModifierSchema extends yup.Schema | yup.ArraySchema<any, any>
>(
  schema: yup.Schema<Schema, Type>,
  dependencies: { [dependency: string]: any },
  includesModifier?: (field: ModifierSchema) => ModifierSchema
) {
  return schema.when(Object.keys(dependencies), {
    is: (...vs: Type[]) =>
      vs.every((v, index) => v?.includes(Object.values(dependencies)[index])),
    then: (field: yup.Schema<Schema>) =>
      (includesModifier ?? ((f) => f))(field.nonNullable()).required(),
    otherwise: (field: yup.Schema<Schema>) => field.nullable(),
  });
}

/**
 * transform normalized number to Number
 * @param value
 * @returns Number(normalizeNumber(value))
 */
function transformNumber(value?: string | null) {
  if (value === undefined || value === null) return value;
  return Number(normalizeNumber(value));
}

/**
 * custom yup.number which allows for spaces and comas and normalizes it using normalizeNumber
 * empty values return null
 * @returns yup.number().optional().nullable().transform(transformNumberOptional)
 */
export function yupNumberOptional() {
  return yup.number().optional().nullable().transform(transformNumberOptional);
}

/**
 * custom yupNumberOptional which also tests for >= 0
 * empty values return null
 * @returns yupNumberOptional().test(v => v === undefined || v === null || v >= 0);
 */
export function yupNumberOptionalNotNegative() {
  return yupNumberOptional().test(
    (v) => v === undefined || v === null || v >= 0
  );
}

/**
 * transform normalized number to Number, doesn't convert empty to 0
 * @param value
 * @returns null or Number(normalizeNumber(value))
 */
function transformNumberOptional(value: string | number) {
  if (
    value === "" ||
    value === null ||
    (typeof value === "number" && isNaN(value))
  ) {
    return null;
  }
  return Number(normalizeNumber(value));
}

/**
 * normalize number by removing any non number chars [^0-9,.-], replacing "," by "." and only leaving the last "."
 * @param value
 * @returns string
 */
export function normalizeNumber(value?: string | number | null) {
  return (
    String(value)
      // remove non allowed characters
      .replace(/[^0-9,.-]/g, "")
      // keep only one decimal separator at the end
      .split(/[.,]/)
      .reduce(
        (value, part, partIndex, array) =>
          `${value}${partIndex === array.length - 1 ? "." : ""}${part}`
      )
  );
}

export function Input<T extends FieldValues, As extends React.ElementType>({
  register,
  name,
  type = "text",
  label,
  placeholder,
  required = false,
  disabled = false,
  readOnly = false,
  autoFocus = false,
  autoComplete = undefined,
  tooltip,
  error,
  inputGroupBefore,
  inputGroupAfter,
  formControlProps,
  ...props
}: TInputProps<T, As>) {
  const isInvalid = Boolean(error);
  const registerOption: RegisterOptions = { required };

  if (type === INPUT_TYPES_ENUM.DATE) {
    registerOption.valueAsDate = true;
  } else if (type === INPUT_TYPES_ENUM.NUMBER) {
    // FireFox allows spaces in number input so we use text
    type = INPUT_TYPES_ENUM.TEXT;
    registerOption.onChange = ({ target }) => {
      target.value = normalizeNumber(target.value);
    };
    registerOption.setValueAs = (value: string) =>
      value ? transformNumber(value) : null;
  }
  const labelNode = label ? (
    <>
      {label}
      {required && <RequiredAsterisk />}
      {tooltip && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${name}`}>{tooltip}</Tooltip>}
        >
          <Button variant="transparent" className="py-0">
            <CircleQuestionSmallIcon />
          </Button>
        </OverlayTrigger>
      )}
    </>
  ) : null;

  const isLabelNodeEmbeddedInControl = type === INPUT_TYPES_ENUM.CHECKBOX;

  const formControlNode =
    type === INPUT_TYPES_ENUM.CHECKBOX ? (
      <CheckBox
        {...formControlProps}
        {...register(name as Path<T>, {
          required,
          ...registerOption,
        })}
        {...{ type, placeholder, disabled, readOnly }}
        label={labelNode}
        isInvalid={isInvalid}
        className={styles.checkbox}
      />
    ) : (
      <Form.Control
        {...formControlProps}
        {...register(name as Path<T>, {
          required,
          ...registerOption,
        })}
        {...{ type, placeholder, disabled, readOnly }}
        isInvalid={isInvalid}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
      />
    );
  const hasInputGroup = inputGroupBefore || inputGroupAfter;

  return (
    <Form.Group
      // cast any to get out of react bootstrap typescript hell
      {...(props as any)}
      controlId={name}
    >
      {!isLabelNodeEmbeddedInControl && labelNode && (
        <Form.Label>{labelNode}</Form.Label>
      )}
      {hasInputGroup ? (
        <InputGroup className={classNames({ "is-invalid": isInvalid })}>
          {inputGroupBefore}
          {formControlNode}
          {inputGroupAfter}
        </InputGroup>
      ) : (
        formControlNode
      )}
      <FormControlError error={error} />
    </Form.Group>
  );
}

export function ControlledInput<
  T extends FieldValues,
  TName extends FieldPath<T>,
  As extends React.ElementType
>({
  name,
  label,
  control,
  tooltip,
  render,
  schema,
  required,
  ...props
}: TControlledInputProps<T, As, TName>) {
  required = required ?? isFieldRequired(name, schema);
  return (
    <Form.Group
      // cast any to get out of react bootstrap typescript hell
      {...(props as any)}
      controlId={name}
    >
      <Form.Label>
        {label}
        {required && <RequiredAsterisk />}
        {tooltip && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${name}`}>{tooltip}</Tooltip>}
          >
            <Button variant="transparent" className="py-0">
              <CircleQuestionSmallIcon />
            </Button>
          </OverlayTrigger>
        )}
      </Form.Label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <>
            {render(props)}
            <FormControlError error={props.fieldState.error} />
          </>
        )}
      />
    </Form.Group>
  );
}

export function getInputProps<T extends FieldValues>(
  name: Path<T>,
  register: UseFormRegister<T>,
  schema: yup.ObjectSchema<T>,
  errors: FieldErrors<T>
): {
  name: Path<T>;
  register: UseFormRegister<T>;
  error: FieldError | undefined;
  required: boolean;
  type: INPUT_TYPES_ENUM;
} {
  return {
    name,
    register,
    error: errors[name] as FieldError | undefined,
    required: isFieldRequired(name, schema),
    type: getFieldType(name, schema),
  };
}

function isFieldRequired<T extends yup.Maybe<yup.AnyObject>>(
  field: string,
  schema: yup.ObjectSchema<T>
) {
  const describedField = getNestedFieldFromSchema(
    schema.describe(),
    field
  ) as SchemaDescription;
  return (
    describedField?.optional === false ||
    describedField?.tests?.findIndex(({ name }) => name === "required") >= 0
  );
}

function getFieldType<T extends yup.Maybe<yup.AnyObject>>(
  field: string,
  schema: yup.ObjectSchema<T>
) {
  const describedField = getNestedFieldFromSchema(schema.describe(), field);
  switch (describedField?.type) {
    case "number":
      return INPUT_TYPES_ENUM.NUMBER;
    case "boolean":
      return INPUT_TYPES_ENUM.CHECKBOX;
    case "date":
      return INPUT_TYPES_ENUM.DATE;
    case "string":
    default:
      return INPUT_TYPES_ENUM.TEXT;
  }
}
function isSchemaObjectDescription(
  schemaDescription: SchemaFieldDescription
): schemaDescription is SchemaObjectDescription {
  return Boolean((schemaDescription as SchemaObjectDescription).fields);
}
// from https://stackoverflow.com/a/56924775/4973023
export function getNestedFieldFromSchema(
  schemaDescription: SchemaFieldDescription,
  path: string
): SchemaFieldDescription {
  const [currentKey, ...otherKeys] = path.split(".");
  if (currentKey && isSchemaObjectDescription(schemaDescription)) {
    return getNestedFieldFromSchema(
      schemaDescription.fields[currentKey],
      otherKeys.join(".")
    );
  } else {
    return schemaDescription;
  }
}

/**
 * Helper function for selects and yup.
 *
 * replace undefined by "", otherwise yup overwrites the undefined value by the defaultValue
 */
export function getUndefinedSafeSelectOnChange<T>(field: {
  onChange(v?: T | ""): void;
}) {
  return (v?: T) => field.onChange(v ?? "");
}
