import classNames from "classnames";
import FormLabel from "react-bootstrap/FormLabel";
import InputGroup from "react-bootstrap/InputGroup";

import { EuroIcon } from "../../icons";

import styles from "./EuroIconInputGroup.module.scss";

export function EuroIconInputGroup({ id }: { id?: string }) {
  return (
    <InputGroup.Text
      id={id}
      as={FormLabel}
      className={classNames(styles.euro, "mb-0 py-0")}
    >
      <EuroIcon />
    </InputGroup.Text>
  );
}
