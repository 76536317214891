import classNames from "classnames";
import { CSSProperties } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { formatPercentage } from "../i18n";

import styles from "./Percentage.module.scss";

export function Percentage({
  percentage,
  tooltip,
  style,
}: {
  percentage: number;
  tooltip?: string;
  style?: CSSProperties;
}) {
  const percentageLevel = getPercentageLevel(percentage);
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="bottom"
      overlay={<Tooltip>{tooltip}</Tooltip>}
    >
      <span
        style={style}
        className={classNames(
          styles[`percentage-${percentageLevel}`],
          "position-relative"
        )}
      >
        {formatPercentage(percentage, "never")}
      </span>
    </OverlayTrigger>
  );
}

export enum PERCENTAGE_LEVEL_ENUM {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export function getPercentageLevel(percentage: number) {
  if (percentage < 0.25) {
    return PERCENTAGE_LEVEL_ENUM.LOW;
  } else if (percentage < 0.75) {
    return PERCENTAGE_LEVEL_ENUM.MEDIUM;
  } else {
    return PERCENTAGE_LEVEL_ENUM.HIGH;
  }
}
