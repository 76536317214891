import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import colors from "../../shared/_exports.module.scss";
import { useTranslation } from "../../shared/i18n";
import { CloseCircledIcon } from "../../shared/icons";
import { INPUT_TYPES_ENUM } from "../../shared/inputs/input";
import { IFilterProps } from "../OperationsFilters";
import { IAreaFilterValue } from "../OperationsTable";

export function AreaFilter({ filterKey, onChange }: IFilterProps) {
  const [filterValue, setFilterValue] = useState<IAreaFilterValue>({
    min: undefined,
    max: undefined,
  });
  const [showClearIcon, setShowClearIcon] = useState<boolean>(false);

  const { t } = useTranslation("AreaFilter");

  const handleInputChange = (inputName: string, inputValue?: number) => {
    setFilterValue((value) => ({ ...value, [inputName]: inputValue }));
  };

  const handleClear = () => {
    setFilterValue({ min: undefined, max: undefined });
    setShowClearIcon(false);
    onChange?.(filterKey, { min: undefined, max: undefined });
  };

  const handleOnBlur = () => {
    onChange?.(filterKey, filterValue);
    setShowClearIcon(
      filterValue.min !== undefined || filterValue.max !== undefined
    );
  };

  return (
    <InputGroup className="mb-3">
      {Object.entries(filterValue).map(([fieldName, value]) => (
        <Fragment key={fieldName}>
          <InputGroup.Text className="bg-light">{t(fieldName)}</InputGroup.Text>
          <Form.Control
            type={INPUT_TYPES_ENUM.NUMBER}
            className="number-input-no-arrows"
            name={fieldName}
            value={value ?? ""}
            min={fieldName === "min" ? 0 : filterValue.min ?? 0}
            max={fieldName === "max" ? undefined : filterValue.max}
            step={10}
            onChange={({ target: { value, name } }) => {
              handleInputChange(name, value ? Number(value) : undefined);
            }}
            onBlur={handleOnBlur}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleOnBlur();
              }
            }}
          />
          <InputGroup.Text className="bg-light">{t("unit")}</InputGroup.Text>
        </Fragment>
      ))}
      {showClearIcon && (
        <Button size="sm" variant="outline-input" onClick={handleClear}>
          <CloseCircledIcon fill={colors.secondary} />
        </Button>
      )}
    </InputGroup>
  );
}
