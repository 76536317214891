import classNames from "classnames";
import i18next from "i18next";
import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

import { UserContext, getUserNameOrEmail } from "../account/UserContext";
import { supportedLanguages } from "../host/i18n";
import { PATH_NAMES_ENUM } from "../shared/pathNames";

import styles from "./UserMenu.module.scss";
import globalStyles from "./_exports.module.scss";
import { ContactSupportLink } from "./errors/ContactSupport";
import { useTranslation } from "./i18n";
import { LogoutIcon, UserIcon } from "./icons";
import { ifTest } from "./utils";

export function UserMenu({
  variant = "light",
}: {
  variant?: "light" | "dark";
}) {
  const { t } = useTranslation("UserMenu");
  const { user, logout, changeLanguage } = useContext(UserContext);
  const isVariantDark = variant === "dark";

  const title = getUserNameOrEmail(user);
  const initials = `${user?.first_name?.slice(0, 1) ?? ""}${
    user?.last_name?.slice(0, 1) ?? ""
  }`;
  return (
    <Nav className={styles.dropdown}>
      <Dropdown>
        <Dropdown.Toggle
          className={classNames(
            "mt-0 py-0 d-flex align-items-center justify-content-center",
            { "text-white": isVariantDark }
          )}
          variant="text"
        >
          <div
            data-test={ifTest("user-menu")}
            className={classNames(
              "rounded-circle p-4 d-inline-flex w-0 h-0 align-items-center justify-content-center text-light fw-bold me-3",
              styles.initials
            )}
          >
            {initials}
          </div>
          <span className="d-none d-md-block">{title}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-2">
          <Dropdown.ItemText className="text-nowrap d-block d-md-none">
            {title}
          </Dropdown.ItemText>
          <Dropdown.Divider className="mx-3 my-0 d-block d-md-none" />
          <Dropdown.Item
            className="px-3"
            as={Link}
            to={`/${PATH_NAMES_ENUM.ACCOUNTS}`}
          >
            <UserIcon color={globalStyles.dark} />
            <span className="mx-2 text-dark">{t("my account")}</span>
          </Dropdown.Item>
          <Dropdown.Divider className="mx-3 my-0" />
          <Dropdown.ItemText className="px-3 text-center">
            <ContactSupportLink>{t("contact")}</ContactSupportLink>
          </Dropdown.ItemText>
          <Dropdown.Divider className="mx-3 my-0" />
          {supportedLanguages.length > 1 && (
            <>
              <Dropdown.ItemText
                className={classNames(
                  styles.languages,
                  "d-flex justify-content-around align-items-center my-2"
                )}
              >
                {supportedLanguages.map((language, index) => (
                  <Form.Check
                    key={language.code}
                    className={classNames(
                      "flex-grow-1 text-center",
                      styles.language
                    )}
                    label={language.title}
                    title={language.name}
                    name="language"
                    id={`language-${language.code}`}
                    type="radio"
                    checked={language.code === i18next.language}
                    onChange={() => changeLanguage(language.code)}
                  />
                ))}
              </Dropdown.ItemText>
              <Dropdown.Divider className="mx-3 my-0" />
            </>
          )}
          <Dropdown.Item className="px-0" onClick={logout}>
            <Button
              variant="text"
              className={classNames("dropdown-item text-center", styles.button)}
              onClick={logout}
              data-test={ifTest("logout")}
            >
              <LogoutIcon fill={globalStyles.dark} />
              <span className="mx-2 text-dark">{t("logout")}</span>
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  );
}
