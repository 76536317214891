import classNames from "classnames";
import React from "react";

import styles from "../_exports.module.scss";

import progressCircleStyles from "./ProgressCircle.module.scss";
import { applyFontRatioToPxSize } from "./iconSizeUtil";

// radius of circle with perimeter = 100
const r = 100 / Math.PI / 2;

export function ProgressCircleIcon({
  className = "",
  stroke = styles.blue300,
  size = 24,
  percentage = 0,
  width = 4,
}) {
  return (
    <svg
      viewBox={`0 0 ${35 + width} ${35 + width}`}
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      className={classNames(className, progressCircleStyles.animated)}
    >
      <path
        d={`M${17.5 + width / 2} ${width / 2}
      a ${r} ${r} 0 0 1 0 ${r * 2}
      a ${r} ${r} 0 0 1 0 -${r * 2}`}
        fill="none"
        strokeWidth={width}
        stroke={stroke}
        strokeOpacity="0.25"
      />
      <path
        d={`M${17.5 + width / 2} ${width / 2}
        a ${r} ${r} 0 0 1 0 ${r * 2}
        a ${r} ${r} 0 0 1 0 -${r * 2}`}
        fill="none"
        strokeWidth={width}
        strokeDasharray={`${percentage}, 100`}
        stroke={stroke}
      />
    </svg>
  );
}
