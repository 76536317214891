import React, { FormEventHandler } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import * as yup from "yup";

import { useTranslation, yupLocale } from "../shared/i18n";
import { EuroIconInputGroup, Input, getInputProps } from "../shared/inputs";
import { yupNumber } from "../shared/inputs/input";

yup.setLocale(yupLocale);
export const schema = yup.object({
  estimation_amount: yupNumber().positive().required(),
});

export type TVersionFormInputs = yup.InferType<typeof schema>;

export function TenderAmountForm({
  register,
  errors,
  handleSubmit,
}: {
  register: UseFormRegister<TVersionFormInputs>;
  errors: FieldErrors;
  handleSubmit: FormEventHandler<HTMLFormElement>;
}) {
  const { t } = useTranslation("TenderAmountForm");

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="mb-5">
        <Input
          as={Col}
          {...getInputProps(
            "estimation_amount",
            register as unknown as UseFormRegister<FieldValues>,
            schema,
            errors
          )}
          label={t("estimation_amount")}
          placeholder={t("estimation_amount")}
          inputGroupAfter={<EuroIconInputGroup id="euro-icon" />}
        />
      </Row>
    </Form>
  );
}
