import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";

import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { useTranslation } from "../shared/i18n";
import { useComparisonsQuery } from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";

import { SavedComparisonsTable } from "./SavedComparisonsTable";

export function SavedComparisonsPage() {
  const { t } = useTranslation("SavedComparisonsPage");
  const { operationId, lotId } = useParams();

  useTrackPageView(
    "SavedComparisonsPage",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
      {
        id: CUSTOM_DIMENSIONS_ENUM.LOT_ID,
        value: String(lotId),
      },
    ],
    [operationId, lotId]
  );

  const comparisonsQuery = useComparisonsQuery(lotId);

  const comparisons = comparisonsQuery.data?.results;

  return (
    <Container fluid className="pb-4 p-0">
      <Card className="mb-5 w-auto border-0 mx-0 mx-md-4">
        <Card.Body className="p-md-4">
          {comparisonsQuery.isLoading ? (
            <Row>
              <Col className="text-center">
                <Spinner />
              </Col>
            </Row>
          ) : comparisons?.length ? (
            <>
              <Row className="mb-4">
                <Col>
                  <h4>{t("saved comparisons")}</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SavedComparisonsTable />
                </Col>
              </Row>
            </>
          ) : (
            <Row className="align-content-center">
              <Col className="text-center mt-5">{t("no comparison")}</Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
