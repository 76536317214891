import { useEffect, useState } from "react";

import { APP_ENV_ENUM } from "../config.types";

export function cancelEvent(e: React.SyntheticEvent) {
  e.preventDefault();
  e.stopPropagation();
}

/** group elements of an array using the callback to determine the key */
export function groupBy<T>(
  array?: T[] | null,
  callback?: (element: T) => string
) {
  if (!array || !callback) {
    return {};
  }
  const categories: { [key: string]: T[] } = {};
  array.forEach((element) => {
    const category = callback(element);
    categories[category] = categories[category] ?? [];
    categories[category].push(element);
  });
  return categories;
}

const env = window.REACT_APP_ENV;
export const isDevelopmentEnv = env === APP_ENV_ENUM.development;
export const isStagingEnv = env === APP_ENV_ENUM.staging;
export const isTestEnv = env === APP_ENV_ENUM.test;
export const isProductionEnv =
  env === APP_ENV_ENUM.prod || env === APP_ENV_ENUM.production;
export function ifTest(v: any) {
  return isProductionEnv ? undefined : v;
}

/** returns true if the two arrays have different values */
export function doArraysHaveDifferentValues(
  firstArray: any[],
  secondArray: any[]
) {
  return (
    firstArray.length !== secondArray.length ||
    firstArray.some(
      (v, index) =>
        !(
          v === secondArray[index] ||
          (Object.is(v, NaN) && Object.is(secondArray[index], NaN))
        )
    )
  );
}

export function randomId() {
  return crypto?.randomUUID?.() ?? Math.floor(Math.random() * 1000000000);
}

export const alphabet = [..."abcdefghijklmnopqrstuvwxyz"];

export function getAlphabetIdFromIndex(index: number): string {
  if (index < alphabet.length) {
    return alphabet[index];
  } else {
    return `${getAlphabetIdFromIndex(
      Math.floor(index / alphabet.length - 1)
    )}${getAlphabetIdFromIndex(index % alphabet.length)}`;
  }
}

export function downloadFileFromBlob(blob: Blob, filename: string) {
  // Create a new anchor element
  const link = window.document.createElement("a");

  // Set the anchor's href to the new Blob object
  link.href = URL.createObjectURL(blob);

  // Set the download attribute to the desired filename
  link.download = filename;

  // Add the anchor element to the DOM
  window.document.body.appendChild(link);

  // Simulate a click on the anchor to start the download
  link.click();

  // Remove the anchor element from the DOM
  window.document.body.removeChild(link);
}

export const DEFAULT_EMPTY_VALUE_PLACEHOLDER = "-";

export function useWindowWidth() {
  useRerenderOnResize();
  return window.innerWidth;
}

export function useRerenderOnResize() {
  const [, setRerender] = useState({});
  useEffect(() => {
    const handleResize = () => setRerender({});
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
}

export function useElementAvailableHeightOnScreen(
  element: HTMLElement | null,
  bottomMargin = 15
) {
  useRerenderOnResize();
  return (
    window.innerHeight -
    (element?.getBoundingClientRect().top ?? 0) -
    bottomMargin
  );
}

export function truncate(str: string, maxLength: number) {
  return str.length > maxLength ? str.slice(0, maxLength - 1) + "…" : str;
}

export function accessNested<T = any>(obj: Object, key: string) {
  return key.split(".").reduce((a: any, b: string) => a?.[b], obj) as T;
}

export function setNested<T = any>(
  obj: Object,
  key: string,
  value: T,
  integratePrevious = false
) {
  const parent = key
    .split(".")
    .slice(0, -1)
    .reduce((a: any, b: string) => (a[b] = a[b] ?? {}), obj);
  const lastKey = key.split(".").pop()!;
  if (integratePrevious && typeof value === "object") {
    parent[lastKey] = { ...(parent[lastKey] ?? {}), ...value };
  } else {
    parent[lastKey] = value;
  }
}

/**
 * converts hex color to ARGB
 * example: #123abc => FF123ABC
 */
export function colorHEXToARGB(hex: string) {
  return hex.replace("#", "").toUpperCase().slice(-6).padStart(8, "FF");
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

/**
 * Tests if smaller is less than bigger, returns true if any parameter is undefined|null
 * @param smaller
 * @param bigger
 * @returns
 */
export function testLessThanOrOptional<T>(
  smaller?: T | null,
  bigger?: T | null
) {
  return smaller == null || bigger == null || smaller < bigger;
}

/** calculate the median of a sorted array */
export function getMedianSorted(values: number[]) {
  const sorted = values.sort((a, b) => a - b);
  return getMedian(sorted);
}

/** calculate the median of an array */
export function getMedian(values: number[]) {
  const middleIndex = Math.floor(values.length / 2);
  return values.length % 2 !== 0
    ? values[middleIndex]
    : (values[middleIndex - 1] + values[middleIndex]) / 2;
}

/** get quartiles of an array */
export function getQuartiles(values: number[]) {
  const sorted = values.sort((a, b) => a - b);

  const lowerHalf = sorted.slice(0, Math.floor(sorted.length / 2));
  const lowerQuartile = getMedian(lowerHalf);

  const median = getMedian(sorted);

  const upperHalf = sorted.slice(Math.ceil(sorted.length / 2));
  const upperQuartile = getMedian(upperHalf);

  return { lowerQuartile, median, upperQuartile };
}

/** normalize string */
export function normalizeString(value: string) {
  return value
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase();
}
