import React, { useEffect, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useTranslation } from "../shared/i18n";
import { CircleQuestionSmallIcon, CogsIcon } from "../shared/icons";
import { Select } from "../shared/inputs/select";
import { usePrevious } from "../shared/usePrevious";

export interface ISelectTabProps {
  tabs: string[];
  onData: (data: string | undefined) => void;
  initialData?: string;
  miniature?: boolean;
}

export function SelectTab({
  tabs,
  onData,
  initialData,
  miniature = false,
}: ISelectTabProps) {
  const { t } = useTranslation("SelectTab");
  const [tab, setTab] = useState<string | undefined>(initialData);
  const prevTab = usePrevious(tab);

  useEffect(() => {
    tab !== prevTab && onData(tab);
  }, [onData, tab, prevTab]);

  const tabOptions = useMemo(
    () => tabs.map((value) => ({ value, label: value })),
    [tabs]
  );

  return (
    <Col className="d-flex flex-column align-items-center justify-content-center pb-5">
      <div>
        <div className="fw-bold my-1">
          {t("tab to analyse")}
          <span className="text-info">*</span>

          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("tab selection tooltip")}</Tooltip>}
          >
            <span className="mx-2">
              <CircleQuestionSmallIcon />
            </span>
          </OverlayTrigger>
        </div>

        <Select
          key={tabs.join("-")}
          options={tabOptions}
          onChange={setTab as any}
          placeholder={t("select a tab")}
          value={tab}
          autoFocus
        />
      </div>
      {!miniature && (
        <>
          <div className="my-5">
            <CogsIcon />
          </div>
          <span className="text-center">{t("We will convert")}</span>
        </>
      )}
    </Col>
  );
}
