import React, { useCallback, useState } from "react";

import { requestJSON } from "../../api/fetch";
import {
  GEO_FEATURE_TYPES_ENUM,
  IGeoFeature,
  TGeoFeatureProperties,
  isCounty,
  isDistrict,
  isHouse,
  isLocality,
  isStreet,
} from "../types/location.types";
import { useDebounce } from "../useDebounce";
import { useIsFirstRender } from "../useIsFirstRender";

import { IOption, Select, TValue } from "./select";

export function AddressSelect({
  layers,
  onChange,
  defaultValue,
}: {
  layers?: GEO_FEATURE_TYPES_ENUM[];
  onChange(feature?: IGeoFeature): void;
  defaultValue?: { properties: TGeoFeatureProperties };
}) {
  const [userLocation, setUserLocation] = useState<GeolocationCoordinates>();
  if (useIsFirstRender()) {
    navigator.geolocation?.getCurrentPosition(({ coords }) =>
      setUserLocation(coords)
    );
  }
  const loadOptions = useCallback(
    async (value: TValue) => {
      const queryParams = new URLSearchParams({
        q: String(value),
        lang: "fr",
        limit: "50",
      });
      if (layers) {
        layers.forEach((layer) => queryParams.append("layer", layer));
      }
      if (userLocation) {
        queryParams.set("lat", String(userLocation.latitude));
        queryParams.set("lon", String(userLocation.longitude));
      }
      const geoJSONResponse = await requestJSON<{
        features: IGeoFeature[];
        type: string;
      }>(`${window.REACT_APP_ADDRESS_API_URL}?${queryParams}`);
      return Object.values(
        // strip out duplicate osm_ids
        geoJSONResponse.features.reduce<Record<string, IGeoFeature>>(
          (featureById, feature) => ({
            ...featureById,
            [feature.properties.osm_id]: feature,
          }),
          {}
        )
      ).map(geoFeatureToOptionMapper);
    },
    [layers, userLocation]
  );

  const loadOptionsDebounce = useDebounce(loadOptions);

  return (
    <Select
      loadOptions={loadOptionsDebounce}
      onChange={(selection, selectionOption) => {
        onChange((selectionOption as IOption | undefined)?.meta);
      }}
      clearable
      defaultValue={
        defaultValue
          ? defaultValue.properties.osm_id ??
            Object.values(defaultValue.properties).join(" ")
          : undefined
      }
      defaultOptions={
        defaultValue ? [geoFeatureToOptionMapper(defaultValue)] : undefined
      }
    />
  );
}

function geoFeatureToOptionMapper(feature: {
  properties: TGeoFeatureProperties;
}) {
  return {
    value:
      feature.properties.osm_id ?? Object.values(feature.properties).join(" "),
    label: <GeoFeatureLabel {...feature} />,
    meta: feature,
  };
}

function GeoFeatureLabel({
  properties,
}: {
  properties: TGeoFeatureProperties;
}) {
  return (
    <>
      <b className="me-1">{properties.name}</b>
      {isCounty(properties) &&
        properties.postcode &&
        `${properties.postcode}, `}
      {(isDistrict(properties) ||
        isLocality(properties) ||
        isStreet(properties)) &&
        `${properties.postcode ?? ""} ${properties.city ?? ""}, `}
      {isHouse(properties) &&
        `${properties.housenumber ?? ""} ${properties.street ?? ""}, ${
          properties.postcode ?? ""
        } ${properties.city ?? ""}, `}
      {properties.country}
    </>
  );
}
