import { TCell } from "../shared/types/lot.types";
import {
  IMappedPriceAverage,
  IRowTypeMapping,
  IRowTypeMappingDTO,
  rowTypeMappingDTOToRowTypeMappingMapper,
  rowTypeMappingToRowTypeMappingDTOMapper,
} from "../shared/types/mapping.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";
import { IPriceNode } from "../shared/types/priceNode.types";
import {
  IMappedTenderDTO,
  ITender,
  ITenderDTO,
  ITenderPercentage,
  TMappedPriceAverages,
  mappedTenderDTOtoMappedTenderMapper,
  partialTenderToTenderDTOMapper,
  tenderDTOToTenderMapper,
  tendersDTOtoCompaniesMapper,
  tendersPercentageDTOToTendersPercentageMapper,
} from "../shared/types/tender.types";

import { HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE } from "./APIUtils";
import {
  FETCH_METHODS_ENUM,
  authRequest,
  authRequestJSON,
  authRequestPaginatedJSON,
} from "./fetch";
import { IFetchLotsSelectedOptions } from "./fetchLots";

export interface IFetchTendersResult {
  results: ITenderDTO[];
}

const PRICE_BREAKDOWN_BASE_URL = "price-breakdown";
const MAPPED_PRICE_BREAKDOWN_BASE_URL = "price-breakdown";

export async function fetchTenders(lotId: string, searchParams?: object) {
  const query = lotId
    ? `?${new URLSearchParams({
        ...(searchParams ?? {}),
        allotment: lotId,
        page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
      })}`
    : "";
  const result = await authRequestJSON<IFetchTendersResult>(
    `${PRICE_BREAKDOWN_BASE_URL}/${query}`
  );

  return tendersDTOtoCompaniesMapper(result.results);
}

export async function fetchTender(id: string) {
  return tenderDTOToTenderMapper(
    await authRequestJSON<ITenderDTO>(`${PRICE_BREAKDOWN_BASE_URL}/${id}/`)
  );
}

export async function fetchTendersPercentage(
  options: IFetchLotsSelectedOptions
): Promise<IPaginatedQueryResults<ITenderPercentage>> {
  return authRequestPaginatedJSON(
    options,
    "price-breakdown-mapped-percentage",
    tendersPercentageDTOToTendersPercentageMapper
  );
}

export async function fetchMappedTender(id: string) {
  const result = await authRequestJSON<IMappedTenderDTO>(
    `${MAPPED_PRICE_BREAKDOWN_BASE_URL}/${id}/`
  );
  return mappedTenderDTOtoMappedTenderMapper(result);
}

export async function createTender({
  allotment,
  company,
  estimation_amount,
  upload_date,
  mapping,
  file_name,
}: Partial<ITender>) {
  const tenderDTO = await authRequestJSON<ITenderDTO>(
    `${PRICE_BREAKDOWN_BASE_URL}/`,
    {
      method: FETCH_METHODS_ENUM.POST,
      body: JSON.stringify(
        partialTenderToTenderDTOMapper({
          allotment,
          company,
          estimation_amount,
          upload_date,
          mapping,
          file_name,
        })
      ),
    }
  );

  return tenderDTOToTenderMapper(tenderDTO);
}

export async function updateTender({
  company,
  file_name,
  estimation_amount,
  upload_date,
  mapping,
  id,
  comments,
}: Partial<ITender>) {
  const result = await authRequestJSON<ITenderDTO>(
    `${PRICE_BREAKDOWN_BASE_URL}/${id}/`,
    {
      method: FETCH_METHODS_ENUM.PATCH,
      body: JSON.stringify(
        partialTenderToTenderDTOMapper({
          company,
          file_name,
          estimation_amount,
          upload_date,
          mapping,
          comments,
        })
      ),
    }
  );

  return tenderDTOToTenderMapper(result);
}

export async function deleteTender(id: string) {
  await authRequest(`${PRICE_BREAKDOWN_BASE_URL}/${id}/`, {
    method: FETCH_METHODS_ENUM.DELETE,
  });
}

export async function createTenderData({
  id,
  raw_data,
}: {
  id: string;
  raw_data: TCell[][];
}) {
  await authRequest(`${PRICE_BREAKDOWN_BASE_URL}/${id}/raw-data/`, {
    method: FETCH_METHODS_ENUM.PUT,
    body: JSON.stringify(raw_data),
  });
}

export async function fetchTenderData(id: string) {
  return await authRequestJSON<IPriceNode>(
    `${PRICE_BREAKDOWN_BASE_URL}/${id}/data/`
  );
}

export const getTenderBlobAPIUrl = ({ id }: ITender) =>
  `${PRICE_BREAKDOWN_BASE_URL}/${id}/blob/`;

export async function fetchTenderRowTypeMapping(id: string) {
  return rowTypeMappingDTOToRowTypeMappingMapper(
    await authRequestJSON<IRowTypeMappingDTO | undefined>(
      `${PRICE_BREAKDOWN_BASE_URL}/${id}/row-type-mapping/`
    )
  );
}

export async function updateTenderRowTypeMapping({
  id,
  row_type_mapping,
}: {
  id: string;
  row_type_mapping: IRowTypeMapping;
}) {
  return await authRequest(
    `${PRICE_BREAKDOWN_BASE_URL}/${id}/row-type-mapping/`,
    {
      method: FETCH_METHODS_ENUM.PATCH,
      body: JSON.stringify(
        rowTypeMappingToRowTypeMappingDTOMapper(row_type_mapping)
      ),
    }
  );
}

export async function fetchTenderMappedPriceAverages(id: string) {
  return await authRequestJSON<TMappedPriceAverages>(
    `${PRICE_BREAKDOWN_BASE_URL}/${id}/mapped-price-averages/`
  );
}

export async function updateTenderMappedPriceAverages({
  id,
  mapped_price_averages,
  row_type_mapping_percentage,
}: {
  id: string;
  mapped_price_averages: IMappedPriceAverage[];
  row_type_mapping_percentage: number;
}) {
  return await authRequest(
    `${PRICE_BREAKDOWN_BASE_URL}/${id}/mapped-price-averages/`,
    {
      method: FETCH_METHODS_ENUM.PATCH,
      body: JSON.stringify({
        mapped_price_averages,
        row_type_mapping_percentage,
      }),
    }
  );
}
