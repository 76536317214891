import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { ReactNode, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

import { UserContext } from "../account/UserContext";
import { ErrorCause } from "../shared/errors";
import { useHasChanged } from "../shared/useHasChanged";
import { isDevelopmentEnv } from "../shared/utils";

const QUERY_CACHE_TIME_MS =
  window.REACT_APP_QUERY_CACHE_TIME_MS ?? 1000 * 60 * 10;
const QUERY_STALE_TIME_MS =
  window.REACT_APP_QUERY_STALE_TIME_MS ?? 1000 * 60 * 5;

export function QueryClientProvider({ children }: { children?: ReactNode }) {
  const { user, logout } = useContext(UserContext);
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          cacheTime: QUERY_CACHE_TIME_MS,
          staleTime: QUERY_STALE_TIME_MS,
        },
      },
      queryCache: new QueryCache({
        onError: (error) => {
          if (
            error instanceof Error &&
            error.cause === ErrorCause.TOKEN_EXPIRED
          ) {
            logout();
          } else {
            console.error(error);
          }
        },
      }),
      mutationCache: new MutationCache({
        onError: (error) => {
          if (
            error instanceof Error &&
            error.cause === ErrorCause.TOKEN_EXPIRED
          ) {
            logout();
          } else {
            console.error(error);
          }
        },
      }),
    })
  );

  // wipe cache on logout
  const userHasChanged = useHasChanged(user);
  if (!user && userHasChanged) {
    queryClient.cancelQueries();
    queryClient.removeQueries();
  }
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
      <Outlet />
      {isDevelopmentEnv && <ReactQueryDevtools initialIsOpen={false} />}
    </ReactQueryClientProvider>
  );
}
