import classNames from "classnames";

import variables from "../_exports.module.scss";

import { BackArrowIcon } from "./BackArrow";
import styles from "./FrontArrow.module.scss";

export function FrontArrowIcon({ fill = variables.primary, className = "" }) {
  return (
    <BackArrowIcon
      fill={fill}
      className={classNames(className, styles["front-arrow"])}
    />
  );
}
