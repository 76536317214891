import React, { useMemo } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import { Outlet, useParams } from "react-router-dom";

import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { Footer } from "../shared/footer";
import { Header, INav } from "../shared/header";
import { useTranslation } from "../shared/i18n";
import {
  BackArrowIcon,
  DocumentsIcon,
  GraphIcon,
  LotsIcon,
} from "../shared/icons";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { useOperationQuery } from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";

import { OperationHeader } from "./OperationHeader";

export function OperationPage() {
  const { t } = useTranslation("OperationPage");
  const { operationId } = useParams();

  useTrackPageView(
    "Operation",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
    ],
    [operationId]
  );

  const operationBasePath = `/${PATH_NAMES_ENUM.OPERATIONS}/${operationId}`;
  const operationScopeNavList: INav[] = useMemo(
    () => [
      {
        Icon: BackArrowIcon,
        path: "/",
        back: true,
        overlay: <Tooltip>{t("returnOperations")}</Tooltip>,
        id: "navReturnOperations",
      },
      {
        Icon: LotsIcon,
        path: `${operationBasePath}/${PATH_NAMES_ENUM.LOTS}`,
        overlay: <Tooltip>{t("lots")}</Tooltip>,
        id: "navLots",
      },
      {
        Icon: DocumentsIcon,
        path: `${operationBasePath}/${PATH_NAMES_ENUM.DOCUMENTS}`,
        overlay: <Tooltip>{t("operationDocuments")}</Tooltip>,
        id: "navOperationDocuments",
      },
      {
        Icon: GraphIcon,
        path: `${operationBasePath}/${PATH_NAMES_ENUM.CHARTS}`,
        overlay: <Tooltip>{t("operationCharts")}</Tooltip>,
        id: "navOperationCharts",
      },
    ],
    [t, operationBasePath]
  );
  const operationQuery = useOperationQuery(operationId);
  const operation = operationQuery.data;
  return (
    <>
      <Header variant="dark" nav={operationScopeNavList} />
      {!operationQuery.isLoading &&
      operation !== undefined &&
      operation !== null ? (
        <>
          <OperationHeader operation={operation} />
          <Outlet />
        </>
      ) : (
        <Container>
          <Row className="mt-4">
            <Col className="text-center">
              <Spinner />
            </Col>
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}
