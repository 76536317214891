import { isAfter } from "date-fns";

import {
  IPriceEstimate,
  IPriceEstimateDTO,
  partialPriceEstimateToPriceEstimateDTOMapper,
  priceEstimateDTOToPriceEstimateMapper,
} from "../shared/types/estimate.types";
import { TCell } from "../shared/types/lot.types";
import {
  IRowTypeMapping,
  IRowTypeMappingDTO,
  rowTypeMappingDTOToRowTypeMappingMapper,
  rowTypeMappingToRowTypeMappingDTOMapper,
} from "../shared/types/mapping.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";
import { IPriceNode } from "../shared/types/priceNode.types";

import { HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE } from "./APIUtils";
import { FETCH_METHODS_ENUM, authRequest, authRequestJSON } from "./fetch";

const PRICE_ESTIMATE_BASE_URL = "price-estimate";

export async function fetchEstimates(
  lotId: string
): Promise<IPaginatedQueryResults<IPriceEstimate>> {
  const query = lotId
    ? `?${new URLSearchParams({
        allotment: lotId,
        page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
      })}`
    : "";
  const response = await authRequestJSON<
    IPaginatedQueryResults<IPriceEstimateDTO>
  >(`${PRICE_ESTIMATE_BASE_URL}/${query}`);
  return {
    ...response,
    results: response.results.map(priceEstimateDTOToPriceEstimateMapper),
  };
}

export async function fetchEstimate(id: string) {
  return priceEstimateDTOToPriceEstimateMapper(
    await authRequestJSON<IPriceEstimateDTO>(
      `${PRICE_ESTIMATE_BASE_URL}/${id}/`
    )
  );
}

export async function fetchEstimateData(id: string) {
  return await authRequestJSON<IPriceNode>(
    `${PRICE_ESTIMATE_BASE_URL}/${id}/data/`
  );
}

export function getLatestEstimate(
  data: IPaginatedQueryResults<IPriceEstimate>
): IPriceEstimate | undefined {
  const estimates = data.results;
  const latestEstimate = estimates?.length
    ? estimates.reduce((latestEstimate, estimate) =>
        isAfter(latestEstimate.upload_date, estimate.upload_date)
          ? latestEstimate
          : estimate
      )
    : undefined;
  return latestEstimate;
}

export async function createPriceEstimate({
  upload_date,
  file_name,
  mapping,
  allotment,
  file_type,
}: Partial<IPriceEstimate> & {
  file?: File;
  setPercentage?: (percentage: number) => void;
}) {
  const priceEstimate = await priceEstimateDTOToPriceEstimateMapper(
    await authRequestJSON<IPriceEstimateDTO>(`${PRICE_ESTIMATE_BASE_URL}/`, {
      method: FETCH_METHODS_ENUM.POST,
      body: JSON.stringify(
        partialPriceEstimateToPriceEstimateDTOMapper({
          upload_date,
          file_name,
          mapping,
          allotment,
          file_type,
        })
      ),
    })
  );
  return priceEstimate;
}

export async function updatePriceEstimate({
  upload_date,
  file_name,
  mapping,
  id,
  file_type,
  comments,
}: Partial<IPriceEstimate> & {
  file?: File;
  setPercentage?: (percentage: number) => void;
}) {
  const priceEstimate = await priceEstimateDTOToPriceEstimateMapper(
    await authRequestJSON<IPriceEstimateDTO>(
      `${PRICE_ESTIMATE_BASE_URL}/${id}/`,
      {
        method: FETCH_METHODS_ENUM.PATCH,
        body: JSON.stringify(
          partialPriceEstimateToPriceEstimateDTOMapper({
            upload_date,
            file_name,
            mapping,
            file_type,
            comments,
          })
        ),
      }
    )
  );
  return priceEstimate;
}

export async function createPriceEstimateData({
  id,
  raw_data,
}: {
  id: string;
  raw_data: TCell[][];
}) {
  await authRequest(`${PRICE_ESTIMATE_BASE_URL}/${id}/raw-data/`, {
    method: FETCH_METHODS_ENUM.PUT,
    body: JSON.stringify(raw_data),
  });
}

export const getEstimateBlobAPIUrl = ({ id }: IPriceEstimate) =>
  `${PRICE_ESTIMATE_BASE_URL}/${id}/blob/`;

export async function fetchEstimateRowTypeMapping(id: string) {
  return rowTypeMappingDTOToRowTypeMappingMapper(
    await authRequestJSON<IRowTypeMappingDTO | undefined>(
      `${PRICE_ESTIMATE_BASE_URL}/${id}/row-type-mapping/`
    )
  );
}

export async function updateEstimateRowTypeMapping({
  id,
  row_type_mapping,
}: {
  id: string;
  row_type_mapping: IRowTypeMapping;
}) {
  return await authRequest(
    `${PRICE_ESTIMATE_BASE_URL}/${id}/row-type-mapping/`,
    {
      method: FETCH_METHODS_ENUM.PATCH,
      body: JSON.stringify(
        rowTypeMappingToRowTypeMappingDTOMapper(row_type_mapping)
      ),
    }
  );
}
