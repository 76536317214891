import { TLotsMappings } from "./lots.types";
import {
  IRowInstruction,
  IRowInstructionDTO,
  IRowTypeInstruction,
  IRowTypeInstructionDTO,
  rowInstructionDTOToRowInstructionMapper,
  rowInstructionToRowInstructionDTOMapper,
  rowTypeInstructionDTOToRowTypeInstructionMapper,
  rowTypeInstructionToRowTypeInstructionDTOMapper,
} from "./rowInstruction.types";

export enum MAPPING_STEP_ENUM {
  INITIAL = "INITIAL",
  HEADER_LINE_SELECTED = "HEADER_LINE_SELECTED",
  NOMENCLATURE_COLUMN_SELECTED = "NOMENCLATURE_COLUMN_SELECTED",
  DESIGNATION_COLUMN_SELECTED = "DESIGNATION_COLUMN_SELECTED",
  UNIT_COLUMN_SELECTED = "UNIT_COLUMN_SELECTED",
  QUANTITY_COLUMN_SELECTED = "QUANTITY_COLUMN_SELECTED",
  UNIT_PRICE_COLUMN_SELECTED = "UNIT_PRICE_COLUMN_SELECTED",
  TOTAL_PRICE_COLUMN_SELECTED = "TOTAL_PRICE_COLUMN_SELECTED",
  VARIANT_OR_OPTION_RANGES_SELECTED = "VARIANT_OR_OPTION_RANGES_SELECTED",
  VALIDATED = "VALIDATED",
  COMPUTED = "COMPUTED",
}

export enum MAPPING_COLUMNS_ENUM {
  NOMENCLATURE = "NOMENCLATURE",
  DESIGNATION = "DESIGNATION",
  UNIT = "UNIT",
  QUANTITY = "QUANTITY",
  UNIT_PRICE = "UNIT_PRICE",
  TOTAL_PRICE = "TOTAL_PRICE",
}

export interface IMappingDTO extends Omit<IMapping, "row_instructions"> {
  row_instructions: IRowInstructionDTO[];
}

export interface IMapping {
  designation_columns?: number[];
  sheet: string;
  nomenclature_column?: number;
  unit_column?: number;
  version?: number;
  step: MAPPING_STEP_ENUM;
  works?: {
    work_id: string;
    quantity_column?: number;
    unit_price_column?: number;
    total_price_column?: number;
  }[];
  ranges: { start: number; end?: number }[];
  variant_or_option_ranges?: { start: number; end?: number }[];
  row_instructions: IRowInstruction[];
}

export interface IRowTypeMappingDTO {
  row_type_instructions?: IRowTypeInstructionDTO[];
}

export interface IRowTypeMapping {
  row_type_instructions?: IRowTypeInstruction[];
}

export interface IMappedPriceAverage {
  ref: string;
  // total price
  price: number;
  // quantity of the row
  quantity: number;
}

export function mappingDTOToMappingMapper(
  mapping?: IMappingDTO
): IMapping | undefined {
  return mapping
    ? {
        ...mapping,
        row_instructions: mapping.row_instructions?.map(
          rowInstructionDTOToRowInstructionMapper
        ),
      }
    : mapping;
}
export function mappingToMappingDTOMapper(
  mapping?: IMapping
): IMappingDTO | undefined {
  return mapping
    ? {
        ...mapping,
        row_instructions: mapping.row_instructions?.map(
          rowInstructionToRowInstructionDTOMapper
        ),
      }
    : mapping;
}

export function rowTypeMappingDTOToRowTypeMappingMapper(
  row_type_mapping?: IRowTypeMappingDTO
): IRowTypeMapping {
  return {
    ...row_type_mapping,
    row_type_instructions: row_type_mapping?.row_type_instructions?.map(
      rowTypeInstructionDTOToRowTypeInstructionMapper
    ),
  };
}
export function rowTypeMappingToRowTypeMappingDTOMapper(
  row_type_mapping: IRowTypeMapping
): IRowTypeMappingDTO {
  return {
    ...row_type_mapping,
    row_type_instructions: row_type_mapping.row_type_instructions?.map(
      rowTypeInstructionToRowTypeInstructionDTOMapper
    ),
  };
}

/**
 * map the selected rows and columns from the user, and
 * @returns the backend mapping Object
 */
export function mapStepMappingsToSingleMapping(
  mappings: TLotsMappings,
  tab: string,
  work_id?: string,
  validated?: boolean
): IMapping {
  return {
    // single range for now, start after header line
    // +1 to not include the header
    ranges: [
      {
        start: mappings[0]![0] + 1,
        end: isNaN(mappings[1]?.[0]!) ? undefined : mappings[1]![0] + 1,
      },
    ],
    //TODO: correctly handle ranges
    variant_or_option_ranges:
      mappings[8]?.[0] !== undefined
        ? [
            {
              start: mappings[8]![0],
              end: isNaN(mappings[9]?.[0]!) ? undefined : mappings[9]![0],
            },
          ]
        : undefined,
    sheet: tab,
    step: validated
      ? MAPPING_STEP_ENUM.VALIDATED
      : MAPPING_STEP_ENUM.VARIANT_OR_OPTION_RANGES_SELECTED,
    nomenclature_column: mappings[2]?.[0],
    designation_columns: mappings[3]?.map((map) => map),
    unit_column: mappings[4]?.[0],
    version: 1,
    works: work_id
      ? [
          {
            work_id,
            quantity_column: mappings[5]?.[0],
            unit_price_column: mappings[6]?.[0],
            total_price_column: mappings[7]?.[0],
          },
        ]
      : [],
    row_instructions: [],
  };
}

export interface IMappingLotTenderPercentage {
  tender: string;
  project: string;
  project_name: string;
  allotment: string;
  allotment_name: string;
  company_name: string;
  closure_date?: Date;
  percentage?: number;
}
