import { useQueries } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { fetchTenders } from "../api/fetchTenders";
import { useDebounce } from "../shared/useDebounce";
import { useHasChanged } from "../shared/useHasChanged";
import { QUERY_KEYS_ENUM, useLotsQuery } from "../shared/useSharedQueries";

import { ILotChartData } from "./chart.types";

const defaultFilterLots = (data: ILotChartData[]) => data;
export function useOperationLotsChartData<FilteredType = ILotChartData>(
  filterLots: (data: ILotChartData[]) => FilteredType[] = defaultFilterLots as (
    data: ILotChartData[]
  ) => FilteredType[]
) {
  const { operationId } = useParams();
  const [lotsChartData, setLotsChartData] = useState<FilteredType[]>([]);

  const lotsQuery = useLotsQuery(operationId);
  const lots = lotsQuery.data;

  const isLotsQueryLoading =
    lotsQuery.isLoading || (lotsQuery.isFetching && !lotsQuery.isPreviousData);

  const tendersQueries = useQueries({
    queries:
      lots?.map((lot) => ({
        queryKey: [QUERY_KEYS_ENUM.TENDERS, lot.id],
        queryFn: () => fetchTenders(lot.id),
        enabled: Boolean(lot.id),
      })) ?? [],
  });
  const hasLoadingChanged = useHasChanged(
    isLotsQueryLoading,
    ...tendersQueries.map(({ isLoading }) => isLoading)
  );
  const isLoading = [
    isLotsQueryLoading,
    ...tendersQueries.map(({ isLoading }) => isLoading),
  ].some((isLoading) => isLoading);
  const initChartsCallback = useDebounce(
    useCallback(() => {
      const lotsChartData =
        lots
          ?.map(
            (
              {
                id,
                estimation_amount,
                avg_estimation_amount,
                name,
                conventional_allotments,
                reference_number,
                selected,
                min_price_breakdowns,
                best_price_breakdown,
              },
              index
            ) => ({
              id,
              estimation_amount,
              avg_estimation_amount,
              name,
              conventional_allotments,
              reference_number,
              companies: tendersQueries[index].data?.slice(0),
              selected,
              min_price_breakdowns,
              best_price_breakdown,
            })
          )
          // only display lot charts if they have a PM or at least one tender
          .filter(
            (lotChartData) =>
              lotChartData.estimation_amount || lotChartData.companies?.length
          ) ?? [];
      const filteredLotsChartData = filterLots(lotsChartData);
      setLotsChartData(filteredLotsChartData);
    }, [filterLots, lots, tendersQueries]),
    1000
  );

  if (hasLoadingChanged) {
    initChartsCallback();
  }

  return { lotsChartData, isLoading };
}
