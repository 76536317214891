import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";

import { isDevelopmentEnv, isProductionEnv } from "./shared/utils";

export function initSentry() {
  if (isDevelopmentEnv) {
    return;
  }
  Sentry.init({
    dsn: "https://1867563b627a4857af4fb5831144df67@o4504951115087872.ingest.sentry.io/4505080484528128",
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
      new CaptureConsole({
        levels: ["error"],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: isProductionEnv ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: isProductionEnv ? 0.01 : 0.0, // This sets the sample rate at 1%. We set it to 0 in development to not waste our very limited quota.
    replaysOnErrorSampleRate: isProductionEnv ? 0.1 : 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.  We set it to 0 in development to not waste our very limited quota.
    // Environment: distinguish between test and production
    environment: window.REACT_APP_ENV,
    release: window.REACT_APP_RELEASE,
    // only send Error to sentry to avoid sending reports on validation Errors from backend
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error && error instanceof Error) {
        return event;
      } else {
        console.debug("dropping sentry error report: ", event, hint);
        return null;
      }
    },
  });
}
