import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Control } from "react-hook-form";
import * as yup from "yup";

import { useTranslation } from "../../../shared/i18n";
import { ControlledInput, EarthworkSelect } from "../../../shared/inputs";
import { getUndefinedSafeSelectOnChange } from "../../../shared/inputs/input";
import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  LOT_CLOSURE_EARTHWORK_ENUM,
} from "../../../shared/types/lot.types";

import { yupRequiredWhenConventionalAllotmentIncludes } from "./utils";

function yupRequiredWhen<
  S,
  ModifierSchema extends yup.Schema | yup.ArraySchema<any, any>
>(
  schema: yup.Schema<S>,
  includesModifier?: (field: ModifierSchema) => ModifierSchema
) {
  return yupRequiredWhenConventionalAllotmentIncludes(
    schema,
    CONVENTIONAL_ALLOTMENTS_ENUM.EARTHWORK,
    includesModifier
  );
}

export const schema = yup.object({
  earthwork: yupRequiredWhen(
    yup.array(
      yup.string().required().oneOf(Object.values(LOT_CLOSURE_EARTHWORK_ENUM))
    ),
    (field: yup.ArraySchema<any, any>) => field.min(1)
  ),
});

export type TFormInputs = yup.InferType<typeof schema>;

export function EarthworkAllotment({
  control,
}: {
  control: Control<TFormInputs>;
}) {
  const { t } = useTranslation("LotClosureModal");

  return (
    <>
      <h6 className="my-4">{t("earthwork title")}</h6>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("earthwork")}
          name="earthwork"
          schema={schema}
          control={control}
          render={({ field }) => (
            <EarthworkSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
              multi
            />
          )}
        />
      </Row>
    </>
  );
}
