import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function LotsIcon({ fill = styles.white, className = "", size = 34 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize((size / 34) * 35)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 35 34"
      fill="none"
      className={className}
    >
      <g clipPath="url(#lots-icon)">
        <path
          fill={fill}
          d="M4.644 18.417h11.333V4.25H4.644v14.167Zm0 11.333h11.333v-8.5H4.644v8.5Zm14.166 0h11.334V15.583H18.81V29.75Zm0-25.5v8.5h11.334v-8.5H18.81Z"
        />
      </g>
      <defs>
        <clipPath id="lots-icon">
          <path fill="#fff" d="M.394 0h34v34h-34z" />
        </clipPath>
      </defs>
    </svg>
  );
}
