import { CONVENTIONAL_ALLOTMENTS_ENUM } from "../shared/types/lot.types";
import { IMappingLotTenderPercentage } from "../shared/types/mapping.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";
import { ITenderPercentage } from "../shared/types/tender.types";
import { useTendersMappedQuery } from "../shared/useSharedQueries";

import { MappingTendersTable } from "./MappingTendersTable";

/** dictionary to convert frontend sorting keys to backend field names */
const BACKEND_TENDER_FIELD_NAME_BY_FRONTEND_TENDER_SORTI: {
  [key: string]: string;
} = {
  tender: "id",
  project: "allotment__project",
  project_name: "allotment__project__name",
  allotment: "allotment",
  allotment_name: "allotment__name",
  company_name: "company__name",
  closure_date: "allotment__closure_date",
  percentage: "row_type_mapping_percentage",
};

/** map ITenderPercentage to IMappingLotTenderPercentage in query*/
function ITenderPercentageToIMappingLotTenderPercentageMapper(
  lots: IPaginatedQueryResults<ITenderPercentage>
): IPaginatedQueryResults<IMappingLotTenderPercentage> {
  return {
    ...lots,
    results: lots.results.map((lot) => ({
      tender: lot.id,
      project: lot.allotment__project,
      project_name: lot.allotment__project__name,
      allotment: lot.id,
      allotment_name: lot.allotment__name,
      company_name: lot.company__name,
      closure_date: lot.allotment__closure_date,
      percentage: lot.row_type_mapping_percentage,
    })),
  };
}

export function MappingTendersMappedTable({
  conventionalAllotment,
}: {
  conventionalAllotment: CONVENTIONAL_ALLOTMENTS_ENUM;
}) {
  return (
    <MappingTendersTable
      conventionalAllotment={conventionalAllotment}
      query={useTendersMappedQuery}
      select={ITenderPercentageToIMappingLotTenderPercentageMapper}
      mapSearchParamsToBackendFieldNameDict={
        BACKEND_TENDER_FIELD_NAME_BY_FRONTEND_TENDER_SORTI
      }
    />
  );
}
