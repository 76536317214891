import { CellContext, Row } from "@tanstack/react-table";
import React, { useMemo } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { BoxPlotChart, TBoxPlot } from "../charts/BoxPlot";
import { formatCurrency } from "../shared/i18n";
import { IPriceAverageRow } from "../shared/types/averagePrice.types";
import { useHoverableTooltip } from "../shared/useHoverableTooltip";
import {
  DEFAULT_EMPTY_VALUE_PLACEHOLDER,
  getQuartiles,
  ifTest,
} from "../shared/utils";

import { isTenderCellIgnored } from "./PriceAverageTable";

export function PriceAverageCell({
  getValue,
  row,
}: CellContext<IPriceAverageRow, any>) {
  const value = getValue<number>();
  const isValueValid = value !== undefined && !isNaN(value);
  const { target, show, eventHandlers } = useHoverableTooltip();

  const cellContent = (
    <div
      ref={target}
      className="justify-content-center d-flex flex-column h-100"
      data-test={ifTest("average-cell")}
      {...eventHandlers}
    >
      {formatCurrency(value, DEFAULT_EMPTY_VALUE_PLACEHOLDER)}
    </div>
  );
  return isValueValid ? (
    <OverlayTrigger
      show={show}
      overlay={
        <Popover className="ps-0 pb-0 p-4" {...eventHandlers}>
          <BoxPlotChartData row={row} />
        </Popover>
      }
      placement="right"
    >
      {cellContent}
    </OverlayTrigger>
  ) : (
    cellContent
  );
}

function BoxPlotChartData({ row }: { row: Row<IPriceAverageRow> }) {
  const boxPlot: TBoxPlot = useMemo(() => {
    const cells = row.getAllCells();

    const tenderCells = cells.slice(5);
    const activeTenderCells = tenderCells.filter(
      (cell) => !isTenderCellIgnored(cell)
    );
    const tenders = activeTenderCells
      .map((c) => ({
        value: c.getValue<number>(),
        // add non breaking spaces for the -dy on the LabelList
        label: `${"\u00A0".repeat(5)}${c.column.columnDef.meta?.excel?.header}`,
      }))
      .filter((v) => v.value !== undefined);

    const { lowerQuartile, median, upperQuartile } = getQuartiles(
      tenders.map((t) => t.value)
    );

    return {
      min: Math.min(...tenders.map((t) => t.value)),
      max: Math.max(...tenders.map((t) => t.value)),
      lowerQuartile,
      upperQuartile,
      average: tenders.reduce((a, b) => a + b.value, 0) / tenders.length,
      median,
      dataSet: tenders,
    };
  }, [row]);
  return <BoxPlotChart boxPlot={boxPlot} />;
}
