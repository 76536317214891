import React from "react";

import { DEV_PATH_NAMES_ENUM } from "../pathNames";
import { isProductionEnv } from "../utils";

import { DumpRawExcel } from "./DumpRawExcel";

/**developer tools not availaible in production mode */
export const devToolsRoutes = !isProductionEnv
  ? [
      {
        path: `/${DEV_PATH_NAMES_ENUM.DEV}`,
        children: [
          {
            path: DEV_PATH_NAMES_ENUM.DUMP_RAW_EXCEL,
            element: <DumpRawExcel />,
          },
        ],
      },
    ]
  : [];
