import {
  IAuthorizableObject,
  IAuthorizableObjectDTO,
  rightsDTOToRightMapper,
} from "./right.types";

export interface IOrganizationDTO extends IAuthorizableObjectDTO {
  id: string;
  name: string;
  is_member?: string;
  comment?: string;
  control_affiliations?: boolean;
  log_activity?: boolean;
  timezone?: string;
}

export interface IOrganization extends IAuthorizableObject {
  id: string;
  name: string;
  is_member?: string;
  comment?: string;
  control_affiliations?: boolean;
  log_activity?: boolean;
  timezone?: string;
}

export function organizationDTOToOrganizationMapper(
  organization: IOrganizationDTO
): IOrganization {
  return {
    ...organization,
    rights: rightsDTOToRightMapper(organization.rights),
  };
}
