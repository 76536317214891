import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function CogIcon({ className = "", fill = styles.black, size = 20 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M17.499 10c0 .34-.03.66-.07.98l2.11 1.65c.19.15.24.42.12.64l-2 3.46c-.12.22-.38.31-.61.22l-2.49-1c-.52.39-1.08.73-1.69.98l-.38 2.65c-.03.24-.24.42-.49.42h-4c-.25 0-.46-.18-.49-.42l-.38-2.65c-.61-.25-1.17-.58-1.69-.98l-2.49 1c-.22.08-.49 0-.61-.22l-2-3.46a.505.505 0 0 1 .12-.64l2.11-1.65a7.93 7.93 0 0 1-.07-.98c0-.33.03-.66.07-.98L.459 7.37a.493.493 0 0 1-.12-.64l2-3.46c.12-.22.38-.31.61-.22l2.49 1c.52-.39 1.08-.73 1.69-.98l.38-2.65c.03-.24.24-.42.49-.42h4c.25 0 .46.18.49.42l.38 2.65c.61.25 1.17.58 1.69.98l2.49-1c.22-.08.49 0 .61.22l2 3.46c.12.22.07.49-.12.64l-2.11 1.65c.04.32.07.64.07.98Zm-11 0c0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5-3.5 1.57-3.5 3.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function CogsIcon({ className = "", fill = styles.white }) {
  return (
    <svg
      width={applyFontRatioToPxSize(389)}
      height={applyFontRatioToPxSize(210)}
      viewBox="0 0 389 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_124_89187)">
        <path
          d="M72.2829 12.1348C40.3726 12.1348 14.5 37.9988 14.5 69.8987C14.5 101.799 40.3726 127.663 72.2829 127.663C104.193 127.663 130.066 101.799 130.066 69.8987C130.066 37.9988 104.193 12.1348 72.2829 12.1348ZM72.2829 120.007C44.6003 120.007 22.158 97.5722 22.158 69.8987C22.158 42.2251 44.5876 19.7902 72.2829 19.7902C99.9782 19.7902 122.408 42.2251 122.408 69.8987C122.408 97.5722 99.9656 120.007 72.2829 120.007Z"
          fill="#F5BABA"
          stroke="#EB7676"
          strokeWidth="0.05"
          strokeMiterlimit="10"
        />
        <path
          d="M41.2573 8.28964L22.5703 17.3848L29.3413 31.2873L48.0283 22.1922L41.2573 8.28964Z"
          fill="#F5BABA"
        />
        <path
          d="M10.7965 34.4229L3.66406 53.9385L18.1926 59.2448L25.3251 39.7292L10.7965 34.4229Z"
          fill="#F5BABA"
        />
        <path
          d="M0.497209 76.1529L5.57812 96.2998L20.5767 92.5198L15.4958 72.3729L0.497209 76.1529Z"
          fill="#F5BABA"
        />
        <path
          d="M14.4948 112.964L29.1914 127.656L40.1288 116.722L25.4322 102.031L14.4948 112.964Z"
          fill="#F5BABA"
        />
        <path
          d="M45.9846 135.081L66.2344 139.762L69.7191 124.696L49.4693 120.016L45.9846 135.081Z"
          fill="#F5BABA"
        />
        <path
          d="M88.1837 139.718L107.73 132.656L102.473 118.114L82.9264 125.176L88.1837 139.718Z"
          fill="#F5BABA"
        />
        <path
          d="M121.965 117.46L135.129 101.382L123.159 91.588L109.995 107.666L121.965 117.46Z"
          fill="#F5BABA"
        />
        <path
          d="M140.711 80.2871V59.5098H125.243V80.2871H140.711Z"
          fill="#F5BABA"
        />
        <path
          d="M137.138 41.5825L126.746 23.5889L113.351 31.3202L123.743 49.3139L137.138 41.5825Z"
          fill="#F5BABA"
        />
        <path
          d="M115.486 11.1439L96.543 2.59375L90.1777 16.6866L109.12 25.2367L115.486 11.1439Z"
          fill="#F5BABA"
        />
        <path
          d="M77.9406 0.010064L57.2109 1.51367L58.3303 16.9359L79.0599 15.4323L77.9406 0.010064Z"
          fill="#F5BABA"
        />
        <path
          d="M217.416 70.3916C205.619 70.3916 196.062 79.9451 196.062 91.7383C196.062 103.532 205.619 113.085 217.416 113.085C229.213 113.085 238.77 103.532 238.77 91.7383C238.77 79.9451 229.213 70.3916 217.416 70.3916ZM217.416 110.251C207.189 110.251 198.898 101.962 198.898 91.7383C198.898 81.5142 207.189 73.226 217.416 73.226C227.644 73.226 235.935 81.5142 235.935 91.7383C235.935 101.962 227.644 110.251 217.416 110.251Z"
          fill="#F5BABA"
          stroke="#EB7676"
          strokeWidth="0.02"
          strokeMiterlimit="10"
        />
        <path
          d="M205.951 68.9376L199.043 72.2998L201.547 77.4422L208.456 74.08L205.951 68.9376Z"
          fill="#F5BABA"
        />
        <path
          d="M194.699 78.6265L192.062 85.8408L197.436 87.8036L200.073 80.5892L194.699 78.6265Z"
          fill="#F5BABA"
        />
        <path
          d="M190.903 94.0347L192.781 101.482L198.329 100.084L196.451 92.6365L190.903 94.0347Z"
          fill="#F5BABA"
        />
        <path
          d="M196.055 107.649L201.488 113.08L205.534 109.036L200.101 103.605L196.055 107.649Z"
          fill="#F5BABA"
        />
        <path
          d="M207.694 115.823L215.18 117.554L216.469 111.981L208.983 110.251L207.694 115.823Z"
          fill="#F5BABA"
        />
        <path
          d="M223.294 117.529L230.52 114.918L228.575 109.539L221.349 112.15L223.294 117.529Z"
          fill="#F5BABA"
        />
        <path
          d="M235.762 109.296L240.629 103.353L236.202 99.7299L231.335 105.674L235.762 109.296Z"
          fill="#F5BABA"
        />
        <path
          d="M242.695 95.5733V87.8926H236.974V95.5733H242.695Z"
          fill="#F5BABA"
        />
        <path
          d="M241.389 81.2709L237.547 74.6191L232.592 77.4789L236.434 84.1306L241.389 81.2709Z"
          fill="#F5BABA"
        />
        <path
          d="M233.385 70.024L226.383 66.8633L224.028 72.076L231.031 75.2368L233.385 70.024Z"
          fill="#F5BABA"
        />
        <path
          d="M219.507 65.8992L211.844 66.4551L212.258 72.1595L219.921 71.6037L219.507 65.8992Z"
          fill="#F5BABA"
        />
        <path
          d="M213.037 124.095C201.24 124.095 191.684 133.648 191.684 145.441C191.684 157.235 201.24 166.788 213.037 166.788C224.834 166.788 234.391 157.235 234.391 145.441C234.391 133.648 224.834 124.095 213.037 124.095ZM213.037 163.954C202.81 163.954 194.519 155.666 194.519 145.441C194.519 135.217 202.81 126.929 213.037 126.929C223.265 126.929 231.556 135.217 231.556 145.441C231.556 155.666 223.265 163.954 213.037 163.954Z"
          fill="#F5BABA"
          stroke="#EB7676"
          strokeWidth="0.02"
          strokeMiterlimit="10"
        />
        <path
          d="M201.584 122.654L194.676 126.017L197.18 131.159L204.088 127.797L201.584 122.654Z"
          fill="#F5BABA"
        />
        <path
          d="M190.332 132.338L187.695 139.553L193.069 141.515L195.706 134.301L190.332 132.338Z"
          fill="#F5BABA"
        />
        <path
          d="M186.532 147.759L188.41 155.207L193.958 153.809L192.08 146.361L186.532 147.759Z"
          fill="#F5BABA"
        />
        <path
          d="M191.688 161.351L197.121 166.782L201.167 162.738L195.734 157.307L191.688 161.351Z"
          fill="#F5BABA"
        />
        <path
          d="M203.303 169.536L210.789 171.267L212.078 165.694L204.592 163.964L203.303 169.536Z"
          fill="#F5BABA"
        />
        <path
          d="M218.911 171.237L226.137 168.626L224.192 163.247L216.966 165.858L218.911 171.237Z"
          fill="#F5BABA"
        />
        <path
          d="M231.399 163.011L236.266 157.067L231.838 153.445L226.972 159.388L231.399 163.011Z"
          fill="#F5BABA"
        />
        <path
          d="M238.328 149.288V141.607H232.607V149.288H238.328Z"
          fill="#F5BABA"
        />
        <path
          d="M237.014 134.978L233.172 128.326L228.217 131.186L232.059 137.838L237.014 134.978Z"
          fill="#F5BABA"
        />
        <path
          d="M229.003 123.733L222 120.572L219.646 125.785L226.648 128.946L229.003 123.733Z"
          fill="#F5BABA"
        />
        <path
          d="M215.128 119.612L207.465 120.168L207.879 125.872L215.542 125.317L215.128 119.612Z"
          fill="#F5BABA"
        />
        <path
          d="M177.835 9.89551C160.279 9.89551 146.039 24.1309 146.039 41.6815C146.039 59.2321 160.279 73.4674 177.835 73.4674C195.392 73.4674 209.632 59.2321 209.632 41.6815C209.632 24.1309 195.392 9.89551 177.835 9.89551ZM177.835 69.2538C162.595 69.2538 150.254 56.9038 150.254 41.6815C150.254 26.4591 162.608 14.1092 177.835 14.1092C193.063 14.1092 205.417 26.4591 205.417 41.6815C205.417 56.9038 193.063 69.2538 177.835 69.2538Z"
          fill="#1D1D1B"
          stroke="#EB7676"
          strokeWidth="0.03"
          strokeMiterlimit="10"
        />
        <path
          d="M160.765 7.76027L150.477 12.7676L154.206 20.4242L164.494 15.4169L160.765 7.76027Z"
          fill="#1D1D1B"
        />
        <path
          d="M144.009 22.1473L140.082 32.8916L148.083 35.814L152.01 25.0697L144.009 22.1473Z"
          fill="#1D1D1B"
        />
        <path
          d="M138.351 45.0976L141.148 56.1895L149.409 54.1076L146.611 43.0158L138.351 45.0976Z"
          fill="#1D1D1B"
        />
        <path
          d="M146.038 65.3714L154.129 73.46L160.153 67.4383L152.061 59.3498L146.038 65.3714Z"
          fill="#1D1D1B"
        />
        <path
          d="M163.363 77.5538L174.512 80.1309L176.431 71.8339L165.282 69.2568L163.363 77.5538Z"
          fill="#1D1D1B"
        />
        <path
          d="M186.582 80.0872L197.344 76.1992L194.448 68.1903L183.687 72.0782L186.582 80.0872Z"
          fill="#1D1D1B"
        />
        <path
          d="M205.174 67.8372L212.422 58.9854L205.83 53.5915L198.582 62.4434L205.174 67.8372Z"
          fill="#1D1D1B"
        />
        <path
          d="M215.504 47.3881V35.9492H206.985V47.3881H215.504Z"
          fill="#1D1D1B"
        />
        <path
          d="M213.53 26.0968L207.809 16.1904L200.431 20.4484L206.153 30.3548L213.53 26.0968Z"
          fill="#1D1D1B"
        />
        <path
          d="M201.62 9.35083L191.191 4.64355L187.686 12.405L198.115 17.1123L201.62 9.35083Z"
          fill="#1D1D1B"
        />
        <path
          d="M180.952 3.22297L169.539 4.05078L170.156 12.5444L181.568 11.7166L180.952 3.22297Z"
          fill="#1D1D1B"
        />
        <path
          d="M279.304 115.123C261.748 115.123 247.508 129.358 247.508 146.909C247.508 164.46 261.748 178.695 279.304 178.695C296.861 178.695 311.101 164.46 311.101 146.909C311.101 129.358 296.861 115.123 279.304 115.123ZM279.304 174.481C264.064 174.481 251.723 162.131 251.723 146.909C251.723 131.687 264.077 119.337 279.304 119.337C294.532 119.337 306.886 131.687 306.886 146.909C306.886 162.131 294.532 174.481 279.304 174.481Z"
          fill="#1D1D1B"
          stroke="#EB7676"
          strokeWidth="0.03"
          strokeMiterlimit="10"
        />
        <path
          d="M262.237 112.975L251.949 117.982L255.678 125.639L265.966 120.632L262.237 112.975Z"
          fill="#1D1D1B"
        />
        <path
          d="M245.474 127.37L241.547 138.114L249.548 141.037L253.475 130.292L245.474 127.37Z"
          fill="#1D1D1B"
        />
        <path
          d="M239.82 150.329L242.617 161.421L250.877 159.339L248.08 148.247L239.82 150.329Z"
          fill="#1D1D1B"
        />
        <path
          d="M247.506 170.593L255.598 178.682L261.621 172.66L253.53 164.571L247.506 170.593Z"
          fill="#1D1D1B"
        />
        <path
          d="M264.812 182.793L275.961 185.37L277.88 177.073L266.732 174.496L264.812 182.793Z"
          fill="#1D1D1B"
        />
        <path
          d="M288.047 185.311L298.809 181.423L295.913 173.414L285.152 177.302L288.047 185.311Z"
          fill="#1D1D1B"
        />
        <path
          d="M306.635 173.065L313.883 164.213L307.291 158.819L300.043 167.671L306.635 173.065Z"
          fill="#1D1D1B"
        />
        <path
          d="M316.973 152.616V141.177H308.454V152.616H316.973Z"
          fill="#1D1D1B"
        />
        <path
          d="M314.999 131.326L309.277 121.42L301.9 125.678L307.621 135.584L314.999 131.326Z"
          fill="#1D1D1B"
        />
        <path
          d="M303.073 114.578L292.645 109.871L289.139 117.633L299.568 122.34L303.073 114.578Z"
          fill="#1D1D1B"
        />
        <path
          d="M282.417 108.452L271.004 109.28L271.62 117.774L283.033 116.946L282.417 108.452Z"
          fill="#1D1D1B"
        />
        <path
          d="M275.808 25.2432C258.252 25.2432 244.012 39.4785 244.012 57.0291C244.012 74.5797 258.252 88.8151 275.808 88.8151C293.365 88.8151 307.605 74.5797 307.605 57.0291C307.605 39.4785 293.365 25.2432 275.808 25.2432ZM275.808 84.6014C260.568 84.6014 248.227 72.2515 248.227 57.0291C248.227 41.8068 260.581 29.4568 275.808 29.4568C291.035 29.4568 303.39 41.8068 303.39 57.0291C303.39 72.2515 291.035 84.6014 275.808 84.6014Z"
          fill="#1D1D1B"
          stroke="#EB7676"
          strokeWidth="0.03"
          strokeMiterlimit="10"
        />
        <path
          d="M258.737 23.0894L248.449 28.0967L252.178 35.7533L262.466 30.746L258.737 23.0894Z"
          fill="#1D1D1B"
        />
        <path
          d="M241.985 37.4872L238.059 48.2314L246.06 51.1538L249.987 40.4096L241.985 37.4872Z"
          fill="#1D1D1B"
        />
        <path
          d="M236.324 60.4501L239.121 71.542L247.381 69.4602L244.584 58.3683L236.324 60.4501Z"
          fill="#1D1D1B"
        />
        <path
          d="M244.003 80.723L252.094 88.8115L258.117 82.7899L250.026 74.7013L244.003 80.723Z"
          fill="#1D1D1B"
        />
        <path
          d="M261.324 92.9073L272.473 95.4844L274.392 87.1874L263.243 84.6103L261.324 92.9073Z"
          fill="#1D1D1B"
        />
        <path
          d="M284.547 95.4358L295.309 91.5479L292.413 83.5389L281.652 87.4269L284.547 95.4358Z"
          fill="#1D1D1B"
        />
        <path
          d="M303.143 83.1888L310.391 74.3369L303.799 68.9431L296.551 77.795L303.143 83.1888Z"
          fill="#1D1D1B"
        />
        <path
          d="M313.477 62.7495V51.3105H304.958V62.7495H313.477Z"
          fill="#1D1D1B"
        />
        <path
          d="M311.495 41.4523L305.773 31.5459L298.396 35.8039L304.117 45.7102L311.495 41.4523Z"
          fill="#1D1D1B"
        />
        <path
          d="M299.581 24.7073L289.152 20L285.647 27.7614L296.076 32.4687L299.581 24.7073Z"
          fill="#1D1D1B"
        />
        <path
          d="M278.928 18.5677L267.516 19.3955L268.132 27.8891L279.545 27.0613L278.928 18.5677Z"
          fill="#1D1D1B"
        />
        <path
          d="M137.469 119.665C120.609 124.575 110.925 142.227 115.849 159.081C120.761 175.936 138.418 185.616 155.278 180.694C172.139 175.772 181.822 158.132 176.898 141.278C171.987 124.423 154.329 114.743 137.469 119.665ZM154.089 176.657C139.469 180.922 124.153 172.52 119.887 157.905C115.621 143.29 124.026 127.979 138.646 123.714C153.266 119.45 168.582 127.852 172.847 142.467C177.113 157.082 168.708 172.393 154.089 176.657Z"
          fill="#BDEA6F"
          stroke="#EB7676"
          strokeWidth="0.03"
          strokeMiterlimit="10"
        />
        <path
          d="M120.487 122.421L112.012 130.106L117.735 136.414L126.21 128.729L120.487 122.421Z"
          fill="#BDEA6F"
        />
        <path
          d="M108.42 140.905L107.66 152.318L116.16 152.884L116.92 141.471L108.42 140.905Z"
          fill="#BDEA6F"
        />
        <path
          d="M109.419 164.531L115.211 174.396L122.558 170.086L116.766 160.221L109.419 164.531Z"
          fill="#BDEA6F"
        />
        <path
          d="M122.486 181.83L132.52 187.33L136.616 179.863L126.583 174.363L122.486 181.83Z"
          fill="#BDEA6F"
        />
        <path
          d="M142.509 188.677L153.934 188.03L153.452 179.528L142.028 180.174L142.509 188.677Z"
          fill="#BDEA6F"
        />
        <path
          d="M165.535 184.607L174.777 177.862L169.755 170.984L160.513 177.729L165.535 184.607Z"
          fill="#BDEA6F"
        />
        <path
          d="M179.94 167.648L184.418 157.121L176.579 153.788L172.1 164.315L179.94 167.648Z"
          fill="#BDEA6F"
        />
        <path
          d="M184.134 145.133L180.93 134.151L172.752 136.536L175.956 147.517L184.134 145.133Z"
          fill="#BDEA6F"
        />
        <path
          d="M176.279 125.238L168.012 117.329L162.122 123.482L170.389 131.39L176.279 125.238Z"
          fill="#BDEA6F"
        />
        <path
          d="M160.147 112.495L148.816 110.896L147.625 119.328L158.955 120.928L160.147 112.495Z"
          fill="#BDEA6F"
        />
        <path
          d="M138.584 112.384L127.859 116.373L130.83 124.354L141.554 120.365L138.584 112.384Z"
          fill="#BDEA6F"
        />
        <path
          d="M336.313 59.928C319.452 64.8376 309.769 82.4895 314.693 99.3441C319.604 116.199 337.262 125.879 354.122 120.957C370.982 116.034 380.666 98.3951 375.742 81.5404C370.831 64.6858 353.173 55.0057 336.313 59.928ZM352.932 116.92C338.313 121.184 322.997 112.782 318.731 98.1673C314.465 83.5524 322.87 68.2415 337.49 63.9772C352.11 59.7129 367.426 68.1149 371.691 82.7299C375.957 97.3448 367.552 112.656 352.932 116.92Z"
          fill="#BDEA6F"
          stroke="#EB7676"
          strokeWidth="0.03"
          strokeMiterlimit="10"
        />
        <path
          d="M319.339 62.6859L310.863 70.3711L316.587 76.6787L325.062 68.9935L319.339 62.6859Z"
          fill="#BDEA6F"
        />
        <path
          d="M307.264 81.1684L306.504 92.582L315.004 93.1479L315.764 81.7343L307.264 81.1684Z"
          fill="#BDEA6F"
        />
        <path
          d="M308.267 104.791L314.059 114.656L321.405 110.346L315.613 100.48L308.267 104.791Z"
          fill="#BDEA6F"
        />
        <path
          d="M321.303 122.093L331.336 127.593L335.432 120.126L325.399 114.626L321.303 122.093Z"
          fill="#BDEA6F"
        />
        <path
          d="M341.349 128.956L352.773 128.31L352.292 119.807L340.868 120.454L341.349 128.956Z"
          fill="#BDEA6F"
        />
        <path
          d="M364.36 124.875L373.602 118.131L368.579 111.253L359.337 117.997L364.36 124.875Z"
          fill="#BDEA6F"
        />
        <path
          d="M378.772 107.918L383.25 97.3916L375.411 94.0587L370.932 104.585L378.772 107.918Z"
          fill="#BDEA6F"
        />
        <path
          d="M382.966 85.3964L379.762 74.415L371.584 76.7995L374.788 87.7808L382.966 85.3964Z"
          fill="#BDEA6F"
        />
        <path
          d="M375.115 65.4937L366.848 57.585L360.958 63.7376L369.225 71.6463L375.115 65.4937Z"
          fill="#BDEA6F"
        />
        <path
          d="M358.986 52.7649L347.656 51.165L346.465 59.5972L357.795 61.1971L358.986 52.7649Z"
          fill="#BDEA6F"
        />
        <path
          d="M337.427 52.6485L326.703 56.6377L329.674 64.619L340.398 60.6298L337.427 52.6485Z"
          fill="#BDEA6F"
        />
        <path
          d="M359.869 130.928C349.59 133.927 343.692 144.682 346.692 154.957C349.692 165.232 360.451 171.129 370.729 168.13C381.007 165.131 386.906 154.375 383.906 144.1C380.906 133.826 370.147 127.929 359.869 130.928ZM370.008 165.662C361.096 168.256 351.755 163.144 349.16 154.236C346.565 145.328 351.679 135.989 360.59 133.395C369.501 130.801 378.843 135.913 381.438 144.822C384.032 153.73 378.919 163.068 370.008 165.662Z"
          fill="#BDEA6F"
          stroke="#EB7676"
          strokeWidth="0.02"
          strokeMiterlimit="10"
        />
        <path
          d="M349.533 132.628L344.367 137.312L347.854 141.155L353.02 136.471L349.533 132.628Z"
          fill="#BDEA6F"
        />
        <path
          d="M342.17 143.882L341.707 150.839L346.885 151.184L347.349 144.227L342.17 143.882Z"
          fill="#BDEA6F"
        />
        <path
          d="M342.778 158.286L346.309 164.299L350.784 161.673L347.254 155.66L342.778 158.286Z"
          fill="#BDEA6F"
        />
        <path
          d="M350.736 168.826L356.852 172.179L359.347 167.63L353.232 164.277L350.736 168.826Z"
          fill="#BDEA6F"
        />
        <path
          d="M362.943 173.014L369.906 172.62L369.613 167.44L362.65 167.834L362.943 173.014Z"
          fill="#BDEA6F"
        />
        <path
          d="M376.984 170.515L382.617 166.404L379.557 162.214L373.924 166.325L376.984 170.515Z"
          fill="#BDEA6F"
        />
        <path
          d="M385.755 160.185L388.484 153.769L383.709 151.738L380.979 158.154L385.755 160.185Z"
          fill="#BDEA6F"
        />
        <path
          d="M388.316 146.45L386.363 139.757L381.381 141.209L383.334 147.903L388.316 146.45Z"
          fill="#BDEA6F"
        />
        <path
          d="M383.527 134.32L378.488 129.5L374.9 133.248L379.939 138.069L383.527 134.32Z"
          fill="#BDEA6F"
        />
        <path
          d="M373.695 126.565L366.789 125.59L366.063 130.727L372.969 131.702L373.695 126.565Z"
          fill="#BDEA6F"
        />
        <path
          d="M360.544 126.491L354.008 128.923L355.818 133.785L362.354 131.354L360.544 126.491Z"
          fill="#BDEA6F"
        />
        <path
          d="M326.603 167.94C316.325 170.939 310.426 181.694 313.426 191.969C316.426 202.244 327.185 208.14 337.463 205.141C347.742 202.142 353.64 191.387 350.64 181.112C347.64 170.837 336.881 164.941 326.603 167.94ZM336.742 202.674C327.831 205.268 318.489 200.156 315.894 191.248C313.3 182.339 318.413 173.001 327.325 170.407C336.236 167.813 345.577 172.925 348.172 181.833C350.767 190.741 345.653 200.08 336.742 202.674Z"
          fill="#BDEA6F"
          stroke="#EB7676"
          strokeWidth="0.02"
          strokeMiterlimit="10"
        />
        <path
          d="M316.264 169.629L311.098 174.313L314.584 178.156L319.75 173.472L316.264 169.629Z"
          fill="#BDEA6F"
        />
        <path
          d="M308.893 180.901L308.43 187.857L313.608 188.202L314.071 181.245L308.893 180.901Z"
          fill="#BDEA6F"
        />
        <path
          d="M309.524 195.289L313.055 201.302L317.53 198.676L314 192.663L309.524 195.289Z"
          fill="#BDEA6F"
        />
        <path
          d="M317.467 205.838L323.582 209.19L326.077 204.642L319.962 201.289L317.467 205.838Z"
          fill="#BDEA6F"
        />
        <path
          d="M329.673 210.041L336.637 209.646L336.343 204.467L329.38 204.861L329.673 210.041Z"
          fill="#BDEA6F"
        />
        <path
          d="M343.718 207.527L349.352 203.416L346.292 199.226L340.658 203.337L343.718 207.527Z"
          fill="#BDEA6F"
        />
        <path
          d="M352.501 197.194L355.23 190.778L350.455 188.748L347.725 195.164L352.501 197.194Z"
          fill="#BDEA6F"
        />
        <path
          d="M355.054 183.476L353.102 176.783L348.119 178.236L350.072 184.929L355.054 183.476Z"
          fill="#BDEA6F"
        />
        <path
          d="M350.273 171.336L345.234 166.516L341.646 170.264L346.685 175.084L350.273 171.336Z"
          fill="#BDEA6F"
        />
        <path
          d="M340.414 163.584L333.508 162.608L332.782 167.745L339.688 168.721L340.414 163.584Z"
          fill="#BDEA6F"
        />
        <path
          d="M327.287 163.514L320.75 165.945L322.56 170.808L329.096 168.376L327.287 163.514Z"
          fill="#BDEA6F"
        />
      </g>
      <defs>
        <clipPath id="clip0_124_89187">
          <rect
            width="388"
            height="210"
            fill={fill}
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
