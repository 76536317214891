import * as yup from "yup";

export const yupLocale: yup.LocaleObject = {
  mixed: {
    default: "formValidation.invalid",
    required: "formValidation.required",
    notType: ({ type }) => {
      return `formValidation.mustBe.${type}`;
    },
    notNull: "formValidation.notNull",
  },
  string: {
    max: ({ max }) => ({ message: "formValidation.maximum", params: { max } }),
  },
  number: {
    positive: "formValidation.positive",
    integer: "formValidation.integer",
  },
  date: {},
  boolean: {},
  object: {},
  array: {
    min: ({ path, min }) => ({
      message: "formValidation.array.min",
      params: { path, min },
    }),
    max: ({ path, max }) => ({
      message: "formValidation.array.max",
      params: { path, max },
    }),
    length: ({ path, length }) => ({
      message: "formValidation.array.length",
      params: { path, length },
    }),
  },
};
