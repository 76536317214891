import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Control } from "react-hook-form";
import * as yup from "yup";

import { useTranslation } from "../../../shared/i18n";
import {
  ControlledInput,
  ElectricalConnectionSelect,
  EnergyStorageSelect,
  SafetyPowerSupplySelect,
} from "../../../shared/inputs";
import { getUndefinedSafeSelectOnChange } from "../../../shared/inputs/input";
import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  LOT_CLOSURE_ELECTRICAL_CONNECTION_ENUM,
  LOT_CLOSURE_ENERGY_STORAGE_ENUM,
  LOT_CLOSURE_SAFETY_POWER_SUPPLY_ENUM,
} from "../../../shared/types/lot.types";

import { yupRequiredWhenConventionalAllotmentIncludes } from "./utils";

function yupRequiredWhen<S>(schema: yup.Schema<S>) {
  return yupRequiredWhenConventionalAllotmentIncludes(
    schema,
    CONVENTIONAL_ALLOTMENTS_ENUM.HIGH_VOLTAGE_ELECTRICAL_SYSTEMS
  );
}

export const schema = yup.object({
  safety_power_supply: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_SAFETY_POWER_SUPPLY_ENUM))
  ),
  electrical_connection: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_ELECTRICAL_CONNECTION_ENUM))
  ),
  energy_storage: yupRequiredWhen(
    yup.string().oneOf(Object.values(LOT_CLOSURE_ENERGY_STORAGE_ENUM))
  ),
});

export type TFormInputs = yup.InferType<typeof schema>;

export function HighVoltageAllotmentAllotment({
  control,
}: {
  control: Control<TFormInputs>;
}) {
  const { t } = useTranslation("LotClosureModal");

  return (
    <>
      <h6 className="my-4">{t("high voltage title")}</h6>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("safety power supply")}
          name="safety_power_supply"
          schema={schema}
          control={control}
          render={({ field }) => (
            <SafetyPowerSupplySelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("electrical connection")}
          name="electrical_connection"
          schema={schema}
          control={control}
          render={({ field }) => (
            <ElectricalConnectionSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
      </Row>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required
          label={t("energy storage")}
          name="energy_storage"
          schema={schema}
          control={control}
          render={({ field }) => (
            <EnergyStorageSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
      </Row>
    </>
  );
}
