import {
  Cell,
  Header,
  Row,
  Table as TableInstance,
  flexRender,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { MouseEventHandler, ReactNode } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/Spinner";
import BSTable from "react-bootstrap/Table";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

import { useTranslation } from "../i18n";
import { BackArrowIcon, FrontArrowIcon } from "../icons";
import { ifTest } from "../utils";

import styles from "./Table.module.scss";

export interface ITableProps<T> {
  table: TableInstance<T>;
  maxPages?: number;
  stickyHeaders?: boolean;
  hover?: boolean;
  responsive?: boolean;
  /** compact mode, reducing paddings/margins, no bold cell values */
  compact?: boolean;
  /** adds vertical borders to the cells */
  verticalBorders?: boolean;
  className?: string;
  footerClassName?: string;
  /** adds a column at start with the line count */
  lineCountColumn?: boolean;
  /** offset to add to the row index */
  lineCountOffset?: number;
  onMouseLeave?: MouseEventHandler<HTMLTableElement>;
  onRowClick?: (
    row: Row<T>,
    index: number,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;
  onCellClick?: (
    cell: Cell<T, unknown>,
    columnIndex: number,
    row: Row<T>,
    rowIndex: number,
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
  ) => void;
  onColumnHeaderClick?: (header: Header<T, unknown>, index: number) => void;
  footer?: ReactNode;
  tableClassName?: string;
  onColumnHeaderMouseOver?: (header: Header<T, unknown>, index: number) => void;
  onCellMouseOver?: (
    cell: Cell<T, unknown>,
    columnIndex: number,
    row: Row<T>,
    rowIndex: number
  ) => void;
  getColumnHeaderClassName?: (
    header: Header<T, unknown>,
    index: number
  ) => string | null | undefined;
  getRowClassName?: (row: Row<T>, index: number) => string | null | undefined;
  getCellClassName?: (
    cell: Cell<T, unknown>,
    columnIndex: number,
    row: Row<T>,
    rowIndex: number
  ) => string | null | undefined;
  isLoading?: boolean;
  defaultTheme?: boolean;
  noPagination?: boolean;
  disableColumnSizing?: boolean;
}

export interface ITablePropsWithForwardRef
  extends React.FC<ITableProps<unknown>> {
  <T>(props: ITableProps<T>): ReturnType<React.FC<ITableProps<T>>>;
}

export const Table: ITablePropsWithForwardRef = React.forwardRef(function Table<
  T
>(
  {
    table,
    stickyHeaders,
    hover,
    responsive,
    verticalBorders,
    compact,
    className,
    footerClassName,
    lineCountColumn = false,
    lineCountOffset = 0,
    onMouseLeave,
    onRowClick,
    onCellClick,
    onColumnHeaderClick,
    onColumnHeaderMouseOver,
    onCellMouseOver,
    getColumnHeaderClassName,
    getRowClassName,
    getCellClassName,
    isLoading = false,
    defaultTheme = false,
    noPagination = false,
    disableColumnSizing = false,
  }: ITableProps<T>,
  ref?: React.Ref<HTMLTableElement>
) {
  const {
    nextPage,
    previousPage,
    setPageSize,
    setPageIndex,
    getCanPreviousPage,
    getCanNextPage,
  } = table;
  const {
    pagination: { pageIndex, pageSize },
  } = table.getState();
  const pageCount = table.getPageCount();
  const displayedRangeValues = computePaginationRange(pageIndex, pageCount);
  const { t } = useTranslation("Table");
  const hasFooter = table.options.columns.find((columnDef) => columnDef.footer);
  const hasRows = table.getRowModel().rows.length > 0;
  const colLeafCount = table.getAllLeafColumns().length;
  return (
    <>
      <BSTable
        ref={ref}
        className={classNames(
          styles.table,
          {
            [styles["sticky-headers"]]: stickyHeaders,
            [styles["line-count-column"]]: lineCountColumn,
            [styles["vertical-borders"]]: verticalBorders,
            [styles.compact]: compact,
            [styles.hover]: hover,
            [styles.default]: defaultTheme,
          },
          className
        )}
        onMouseLeave={onMouseLeave}
        responsive={responsive}
      >
        <thead data-test={ifTest("table-header")}>
          {table.getHeaderGroups().map((headerGroup, index) => (
            <tr data-test={ifTest("table-header-row")} key={headerGroup.id}>
              {lineCountColumn ? (
                <th
                  data-test={ifTest("table-header-cell")}
                  className="border-top-0 border-bottom-0 bg-dark text-white align-middle"
                  style={{
                    width: disableColumnSizing ? undefined : 50,
                  }}
                ></th>
              ) : null}
              {headerGroup.headers.map((header, columnIndex) => {
                const canSort = header.column.getCanSort();
                const isSortedDesc = header.column.getIsSorted() === "desc";
                const isSortedAsc = header.column.getIsSorted() === "asc";
                return (
                  <th
                    data-test={ifTest("table-header-cell")}
                    key={header.id}
                    colSpan={header.colSpan !== 1 ? header.colSpan : undefined}
                    className={classNames(
                      defaultTheme &&
                        "border-top-0 border-bottom-0 bg-dark text-white align-middle text-nowrap",
                      getColumnHeaderClassName?.(header, columnIndex),
                      header.column.columnDef.meta?.className
                    )}
                    style={{
                      width: disableColumnSizing ? undefined : header.getSize(),
                    }}
                    role={canSort || onColumnHeaderClick ? "button" : undefined}
                    onClick={() => {
                      canSort && header.column.toggleSorting();
                      onColumnHeaderClick?.(header, columnIndex);
                    }}
                    onMouseOver={() =>
                      onColumnHeaderMouseOver?.(header, columnIndex)
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {canSort && (
                      <div className="d-inline-flex flex-column align-middle ms-3">
                        <Button
                          size="sm"
                          variant="text"
                          onClick={(e) => {
                            e.stopPropagation();
                            isSortedAsc
                              ? header.column.clearSorting()
                              : header.column.toggleSorting(false);
                          }}
                          className={isSortedAsc ? "text-info" : "text-white"}
                        >
                          <FaCaretUp size={16} />
                        </Button>
                        <Button
                          size="sm"
                          variant="text"
                          onClick={(e) => {
                            e.stopPropagation();
                            isSortedDesc
                              ? header.column.clearSorting()
                              : header.column.toggleSorting(true);
                          }}
                          className={isSortedDesc ? "text-info" : "text-white"}
                        >
                          <FaCaretDown size={16} />
                        </Button>
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody>
          {
            // For each row
            table.getRowModel().rows.map((row, rowIndex) => (
              // Apply the row props
              <tr
                data-test={ifTest("table-body-row")}
                key={row.id}
                id={row.id}
                role={onRowClick || row.getCanExpand() ? "button" : undefined}
                onClick={(event) => {
                  onRowClick?.(row, rowIndex, event);
                  !onRowClick && row.getCanExpand() && row.toggleExpanded();
                }}
                className={classNames(getRowClassName?.(row, rowIndex))}
              >
                {lineCountColumn ? (
                  <td
                    data-test={ifTest("table-body-cell")}
                    className="border-top-0 py-1 pr-2 align-middle mw-25"
                  >
                    {rowIndex + 1 + lineCountOffset}
                  </td>
                ) : null}
                {
                  // Loop over the rows cells
                  row.getVisibleCells().map((cell, columnIndex) => (
                    <td
                      data-test={ifTest("table-body-cell")}
                      className={classNames(
                        getCellClassName?.(cell, columnIndex, row, rowIndex),
                        defaultTheme && "border-top-0 py-1 pr-2 align-middle",
                        cell.column.columnDef.meta?.className
                      )}
                      key={cell.id}
                      onClick={(event) => {
                        onCellClick?.(cell, columnIndex, row, rowIndex, event);
                      }}
                      onMouseOver={() =>
                        onCellMouseOver?.(cell, columnIndex, row, rowIndex)
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))
                }
              </tr>
            ))
          }
          {isLoading && (
            <tr>
              <td colSpan={colLeafCount} className="pt-5">
                <div className="position-absolute top-0 start-0 d-flex h-100 w-100 justify-content-center align-items-center bg-white bg-opacity-50 min-h-100">
                  <Spinner variant="dark" className="m-3" />
                </div>
              </td>
            </tr>
          )}
          {!isLoading && !hasRows && (
            <tr>
              <td colSpan={colLeafCount} className="p-5 text-center">
                {t("no row")}
              </td>
            </tr>
          )}
        </tbody>
        {hasFooter && (
          <tfoot>
            {table.getFooterGroups().map((group) => (
              <tr key={group.id}>
                {group.headers.map((column: any) => (
                  <td key={column.id}>
                    {flexRender(
                      column.column.columnDef.Footer,
                      column.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </BSTable>
      {!noPagination && pageCount > 1 && (
        <footer
          className={classNames(
            styles["table-footer"],
            "text-center mb-2",
            footerClassName
          )}
        >
          <ButtonGroup>
            <Button
              variant="link"
              value="-1"
              onClick={(event) => {
                previousPage();
                event.preventDefault();
              }}
              disabled={!getCanPreviousPage()}
            >
              <BackArrowIcon />
            </Button>
            {displayedRangeValues.map((value, idx) => (
              <Button
                key={idx}
                variant="link"
                name="radio"
                className="p-2"
                disabled={value === "..."}
                onClick={() => typeof value !== "string" && setPageIndex(value)}
              >
                <span
                  className={classNames(
                    "d-inline-flex text-center align-items-center justify-content-center rounded-circle",
                    { "border-primary": value === pageIndex }
                  )}
                >
                  {/* value can be "..." */}
                  {typeof value === "string" ? value : value + 1}
                </span>
              </Button>
            ))}
            <Button
              variant="link"
              onClick={(event) => {
                nextPage();
                event.preventDefault();
              }}
              disabled={!getCanNextPage()}
            >
              <FrontArrowIcon />
            </Button>
          </ButtonGroup>
          <select
            className="float-right d-none"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t("per page", { count: pageSize })}
              </option>
            ))}
          </select>
        </footer>
      )}
    </>
  );
});

function computePaginationRange(pageIndex: number, pageCount: number) {
  // We always display the same number of items - otherwise the "next" button would move from under the mouse when clicking on it...
  // Number of items: at worst we have 2 header items + RANGE_LENGTH + 1 + RANGE_LENGTH + 2 end items. => 5+RANGE_LENGTH*2
  // Beware: 0-based index, as all indices should.

  const RANGE_LENGTH = 2; // How many pages are displayed surrounding the currently selected page
  const displayedRangeValues: (number | string)[] = [];
  if (pageCount === -1) {
    return displayedRangeValues;
  }
  if (pageCount <= 2 * RANGE_LENGTH + 5) {
    // In this case display all.
    displayedRangeValues.push(...Array(pageCount).keys());
  } else {
    // In this case we display (S is SELECTED) 1..45S78...END, or 12S34..END or 1..5467S9END

    // Add start of the range (if selected index is not too near)
    if (pageIndex > RANGE_LENGTH) {
      displayedRangeValues.push(0);
    }
    if (pageIndex > RANGE_LENGTH + 1) {
      displayedRangeValues.push("...");
    }

    // Add all values surrounding the selected index, with a padding in order to get the right total.
    for (
      let idx = Math.max(
        0,
        pageIndex < pageCount - 2 - RANGE_LENGTH
          ? pageIndex - RANGE_LENGTH
          : pageCount - 3 - 2 * RANGE_LENGTH
      );
      idx <
      Math.min(
        pageCount,
        pageIndex +
          RANGE_LENGTH +
          1 +
          (pageIndex > RANGE_LENGTH + 2 ? 0 : RANGE_LENGTH - pageIndex + 2)
      );
      idx++
    ) {
      displayedRangeValues.push(idx);
    }

    // Add end of range (if selected index is not too near the end)
    if (pageIndex < pageCount - 2 - RANGE_LENGTH) {
      displayedRangeValues.push("...");
    }
    if (pageIndex < pageCount - 1 - RANGE_LENGTH) {
      displayedRangeValues.push(pageCount - 1);
    }
  }

  return displayedRangeValues;
}
