import { parseISO } from "date-fns";

import { toISODate } from "../../api/APIUtils";

export enum ROWTYPE_USAGE_ENUM {
  "DPGF" = "DPGF",
  "CCTP" = "CCTP",
}

export enum BT_OPTIONS_ENUM {
  "BT01" = "BT01",
  "BT02" = "BT02",
  "BT03" = "BT03",
  "BT06" = "BT06",
  "BT07" = "BT07",
  "BT08" = "BT08",
  "BT09" = "BT09",
  "BT10" = "BT10",
  "BT11" = "BT11",
  "BT12" = "BT12",
  "BT14" = "BT14",
  "BT16b" = "BT16b",
  "BT18a" = "BT18a",
  "BT19b" = "BT19b",
  "BT26" = "BT26",
  "BT27" = "BT27",
  "BT28" = "BT28",
  "BT30" = "BT30",
  "BT32" = "BT32",
  "BT33" = "BT33",
  "BT34" = "BT34",
  "BT35" = "BT35",
  "BT38" = "BT38",
  "BT40" = "BT40",
  "BT41" = "BT41",
  "BT42" = "BT42",
  "BT43" = "BT43",
  "BT45" = "BT45",
  "BT46" = "BT46",
  "BT47" = "BT47",
  "BT48" = "BT48",
  "BT49" = "BT49",
  "BT50" = "BT50",
  "BT51" = "BT51",
  "BT52" = "BT52",
  "BT53" = "BT53",
  "BT54" = "BT54",
}

export enum TP_OPTIONS_ENUM {
  "TP01" = "TP01",
  "TP02" = "TP02",
  "TP03a" = "TP03a",
  "TP03b" = "TP03b",
  "TP04" = "TP04",
  "TP05a" = "TP05a",
  "TP05b" = "TP05b",
  "TP06a" = "TP06a",
  "TP06b" = "TP06b",
  "TP07b" = "TP07b",
  "TP08" = "TP08",
  "TP09" = "TP09",
  "TP10a" = "TP10a",
  "TP10b" = "TP10b",
  "TP10c" = "TP10c",
  "TP10d" = "TP10d",
  "TP11" = "TP11",
  "TP12a" = "TP12a",
  "TP12b" = "TP12b",
  "TP12c" = "TP12c",
  "TP12d" = "TP12d",
  "TP13" = "TP13",
  "TP14" = "TP14",
}

export type TCONSTRUCTION_INDICATOR_OPTIONS = BT_OPTIONS_ENUM | TP_OPTIONS_ENUM;

export const BT_OPTIONS_VALUES = Object.values(BT_OPTIONS_ENUM);
export const TP_OPTIONS_VALUES = Object.values(TP_OPTIONS_ENUM);
export const CONSTRUCTION_INDICATOR_VALUES = [
  ...BT_OPTIONS_VALUES,
  ...TP_OPTIONS_VALUES,
];

export const CONSTRUCTION_INDICAOTR = [
  {
    value: "BT",
    options: BT_OPTIONS_VALUES,
  },
  {
    value: "TP",
    options: TP_OPTIONS_VALUES,
  },
];

export type TCONSTRUCTION_INDICATOR = BT_OPTIONS_ENUM | TP_OPTIONS_ENUM;

export interface IRowType {
  id?: string;
  ref: string;
  description: string;
  unit: string;
  organization: string;
  original_author?: string;
  creation_date?: Date;
  admin_approval: boolean;
  allotment_type: string;
  usage: ROWTYPE_USAGE_ENUM[];
  construction_index: TCONSTRUCTION_INDICATOR;
}

export function RowTypeDTOToRowTypeMapper(rowTypeDTO: IRowTypeDTO): IRowType {
  return {
    ...rowTypeDTO,
    creation_date: rowTypeDTO.creation_date
      ? parseISO(rowTypeDTO.creation_date)
      : undefined,
  };
}

export function RowTypeToRowTypeDTOMapper(
  rowType: Partial<IRowType>
): Partial<IRowTypeDTO> {
  return {
    ...rowType,
    creation_date: rowType.creation_date
      ? toISODate(rowType.creation_date)
      : undefined,
  };
}

export interface IRowTypeDTO {
  id?: string;
  ref: string;
  description: string;
  unit: string;
  organization: string;
  original_author: string;
  creation_date?: string;
  admin_approval: boolean;
  allotment_type: string;
  usage: ROWTYPE_USAGE_ENUM[];
  construction_index: TCONSTRUCTION_INDICATOR;
}

export const units = ["ml", "m²", "m3", "kg", "t", "L", "kW", "W", "bar"].map(
  (unit) => ({
    value: unit,
    label: unit,
  })
);
