import {
  ATTACHMENT_TYPE_ENUM,
  IDocument,
  IDocumentDTO,
  documentDTOToDocumentMapper,
} from "../shared/types/document.types";
import { isPriceEstimate } from "../shared/types/estimate.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";
import { isTender } from "../shared/types/tender.types";
import { downloadFileFromBlob, isDevelopmentEnv } from "../shared/utils";

import { HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE } from "./APIUtils";
import {
  API_BASE_URL,
  FETCH_METHODS_ENUM,
  authRequest,
  authRequestJSON,
  request,
  requestBlob,
} from "./fetch";
import { getEstimateBlobAPIUrl } from "./fetchEstimates";
import { getTenderBlobAPIUrl } from "./fetchTenders";

const ATTACHMENT_SUFFIX_BASE_URL = "-attachment";
const getAttachmentType = (isPriceBreakdown: boolean) =>
  isPriceBreakdown
    ? ATTACHMENT_TYPE_ENUM.PRICE_BREAKDOWN
    : ATTACHMENT_TYPE_ENUM.ALLOTMENT;
const getAttachmentBaseUrl = (attachmentType: ATTACHMENT_TYPE_ENUM) =>
  `${attachmentType}${ATTACHMENT_SUFFIX_BASE_URL}`;

export async function fetchAttachments({
  lotId,
  attachmentType = ATTACHMENT_TYPE_ENUM.ALLOTMENT,
}: {
  lotId: string;
  attachmentType?: ATTACHMENT_TYPE_ENUM;
}) {
  const result = await authRequestJSON<IPaginatedQueryResults<IDocumentDTO>>(
    `${getAttachmentBaseUrl(attachmentType)}/?${new URLSearchParams({
      allotment: lotId,
      page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
    })}`
  );
  return result.results.map(documentDTOToDocumentMapper);
}

export async function updateAttachment({
  file_name,
  id,
  price_breakdown,
}: IDocument) {
  await authRequestJSON<IDocument>(
    `${getAttachmentBaseUrl(
      getAttachmentType(Boolean(price_breakdown))
    )}/${id}/`,
    {
      method: FETCH_METHODS_ENUM.PATCH,
      body: JSON.stringify({
        file_name,
      }),
    }
  );
}

/**
 * Uploads a file to the provided URL and reports upload progress
 * @param param0 {file, url}
 * @param percentageCallback : callback to pass progress events to
 */
export async function uploadFile(
  document: IDocument,
  file: File,
  percentageCallback?: (percent: number) => void
) {
  const fetchMethod = FETCH_METHODS_ENUM.PUT;
  const blobUrl = await getDocumentBlobUrl(document, fetchMethod);
  if (isDevelopmentEnv && !Boolean(blobUrl)) return;
  // use XMLHttpRequest to get progress reports
  let request = new XMLHttpRequest();
  request.open(fetchMethod, blobUrl!);
  request.setRequestHeader("Content-Type", file.type);
  request.setRequestHeader("x-ms-blob-type", "BlockBlob");

  // upload progress event
  request.upload.addEventListener("progress", function (e) {
    // upload progress as percentage
    let percent_completed = (e.loaded / e.total) * 100;
    percentageCallback?.(percent_completed);
  });

  request.send(file);

  return new Promise((resolve, reject) => {
    request.onload = function () {
      if (request.status >= 200 && request.status < 300) {
        resolve(request.response);
      } else {
        reject(request.statusText);
      }
    };
    request.onerror = () => {
      reject(request.statusText);
    };
  });
}

/** returns the blob size for a given url */
export async function getFileSize(document: IDocument) {
  try {
    const blobUrl = await getDocumentBlobUrl(document);
    if (isDevelopmentEnv && !Boolean(blobUrl)) return;
    const response = await request(blobUrl!, {
      method: FETCH_METHODS_ENUM.HEAD,
    });
    return response.headers.get("content-length");
  } catch (error) {
    if (!isDevelopmentEnv) {
      console.error(error);
    }
    return undefined;
  }
}

/**
 * API returns the real blob url inside the Location Header
 */
export async function downloadBlob(document: IDocument) {
  const blobUrl = await getDocumentBlobUrl(document);
  if (isDevelopmentEnv && !Boolean(blobUrl)) return;
  const blob = await requestBlob(blobUrl!);
  // Create a new Blob object with the downloaded data
  const newBlob = new Blob([blob], {
    type: "application/octet-stream",
  });
  downloadFileFromBlob(newBlob, document.file_name);
}

function getDocumentBlobAPIUrl(document: IDocument) {
  if (isPriceEstimate(document)) {
    return getEstimateBlobAPIUrl(document);
  }
  if (isTender(document)) {
    return getTenderBlobAPIUrl(document);
  }
  const { price_breakdown, id } = document;
  return `${getAttachmentBaseUrl(
    getAttachmentType(Boolean(price_breakdown))
  )}/${id}/blob/`;
}

export async function getDocumentBlobUrl(
  document: IDocument,
  method?: FETCH_METHODS_ENUM
) {
  const blobAPIUrl = getDocumentBlobAPIUrl(document);
  const { headers } = await authRequest(blobAPIUrl, {
    method,
  });
  const blobLocation = headers.get("Location");
  if (isDevelopmentEnv && !Boolean(blobLocation)) {
    console.warn(
      "Location header return by document /blob API was empty, can't get Azure Blob url from it"
    );
  } else if (blobLocation === null) {
    throw new Error(
      "Location header return by document /blob API was empty, can't get Azure Blob url from it",
      { cause: document }
    );
  }
  return blobLocation;
}
/**
 * Use on href if full URL is needed
 * @param document
 * @returns
 */
export const getDocumentFullBlobAPIUrl = (document: IDocument) =>
  `${API_BASE_URL}/${getDocumentBlobAPIUrl(document)}`;
