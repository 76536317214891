import { yupResolver } from "@hookform/resolvers/yup";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { createUser } from "../api/fetchUser";
import { Brand } from "../shared/Brand";
import variables from "../shared/_exports.module.scss";
import { IBackErrors, RootError, useBackFieldErrors } from "../shared/errors";
import { useTranslation, yupLocale } from "../shared/i18n";
import { Input, getInputProps } from "../shared/inputs";
import { INPUT_TYPES_ENUM } from "../shared/inputs/input";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { useTrackPageView } from "../shared/useTrackPageView";

import { PasswordInput } from "./PasswordInput";

export interface ICreationUserForm {
  email: string;
  password: string;
  confirmation: string;
  first_name: string;
  last_name: string;
}

export interface ICreationUserFormProps {
  defaultValues?: ICreationUserForm;
}

export type TCreationUserFormInputs = yup.InferType<typeof schema>;

export function isFormValid(CreateForm?: ICreationUserForm) {
  return schema.isValidSync(CreateForm);
}

yup.setLocale(yupLocale);

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
  confirmation: yup
    .string()
    .required("AccountCreationPage.password confirm required")
    .oneOf([yup.ref("password")], "AccountCreationPage.passwords should match"),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
});

export function AccountCreationPage({
  defaultValues,
  backErrors,
}: ICreationUserFormProps & {
  backErrors?: IBackErrors<TCreationUserFormInputs>;
}) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TCreationUserFormInputs>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  useBackFieldErrors(backErrors, setError, clearErrors);
  const { t } = useTranslation("AccountCreationPage");
  const navigate = useNavigate();

  useTrackPageView("AccountCreation");
  const { trackEvent } = useMatomo();

  const userCreation = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      trackEvent({
        category: "user",
        action: "account-creation",
        name: "AccountCreation",
      });
      navigate(`/${PATH_NAMES_ENUM.LOGIN}`);
    },
  });

  return (
    <Container fluid className="bg-dark min-h-100">
      <Brand logoColor="white" nameColor={variables.info} className="pt-3" />
      <Card className="mt-5 mx-auto card-md">
        <Card.Body className="pt-5 pb-4">
          <h3 className="text-center mb-4">{t("title")}</h3>
          <Row className="justify-content-md-center">
            <Form
              onSubmit={handleSubmit((formInputs) => {
                const { email, password, first_name, last_name } = formInputs;
                const credentials = { email, password, first_name, last_name };
                userCreation.mutate(credentials);
              })}
            >
              <Row className="mb-3 mx-2">
                <Col>
                  <Input
                    as={Col}
                    {...getInputProps("first_name", register, schema, errors)}
                    label={t("first_name")}
                  />
                </Col>
                <Col>
                  <Input
                    as={Col}
                    {...getInputProps("last_name", register, schema, errors)}
                    label={t("last_name")}
                  />
                </Col>
              </Row>
              <Row className="mb-3 mx-2">
                <Input
                  as={Col}
                  {...getInputProps("email", register, schema, errors)}
                  label={t("email")}
                  type={INPUT_TYPES_ENUM.EMAIL}
                  autoFocus
                  autoComplete="username"
                />
              </Row>
              <Row className="mb-3 mx-2">
                <PasswordInput
                  as={Col}
                  label={t("password")}
                  {...getInputProps("password", register, schema, errors)}
                  autoComplete="new-password"
                />
                <PasswordInput
                  as={Col}
                  label={t("confirmation")}
                  {...getInputProps("confirmation", register, schema, errors)}
                  autoComplete="new-password"
                />
              </Row>
              <RootError errors={errors} />
              <Row>
                <Col className="text-center">
                  <Button
                    size="lg"
                    variant="dark"
                    type="submit"
                    className="my-3 text-center w-50"
                  >
                    {t("create")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
