import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ExpandedState } from "@tanstack/react-table";
import React, { useCallback, useState } from "react";

import { updatePriceEstimate } from "../../api/fetchEstimates";
import { updateTender } from "../../api/fetchTenders";
import { DefaultModal } from "../../shared/DefaultModal";
import { useTranslation } from "../../shared/i18n";
import { RequiredAsterisk } from "../../shared/inputs";
import { ConfirmationModal } from "../../shared/modals";
import {
  IDocumentData,
  TDocumentDataWithTotal,
} from "../../shared/types/document.types";
import { IPriceEstimate } from "../../shared/types/estimate.types";
import { MAPPING_STEP_ENUM } from "../../shared/types/mapping.types";
import { IRowInstruction } from "../../shared/types/rowInstruction.types";
import { ITender, areTenders } from "../../shared/types/tender.types";
import { useHasChanged } from "../../shared/useHasChanged";
import { QUERY_KEYS_ENUM } from "../../shared/useSharedQueries";
import { TABLE_COLUMN_DATA_TYPE_ENUM } from "../Table.types";

import { PriceNodeEditTable } from "./PriceNodeEditTable";
import { TenderSelect } from "./TenderSelect";

export function PriceNodeEditModal({
  show,
  onClose,
  base,
  documents,
  compareColumnDisplaySettings,
  initialExpanded,
}: {
  show: boolean;
  onClose: (save: boolean | void) => void;
  base?: IPriceEstimate;
  documents: TDocumentDataWithTotal[];
  compareColumnDisplaySettings: TABLE_COLUMN_DATA_TYPE_ENUM[];
  initialExpanded: ExpandedState;
}) {
  const [editedDocument, setEditedDocument] =
    useState<TDocumentDataWithTotal>();
  const [priceNodeEditionIsDirty, setPriceNodeEditionIsDirty] = useState(false);
  const tendersLengthAsChanged = useHasChanged(documents?.length);
  if (tendersLengthAsChanged) {
    setEditedDocument(documents.length === 1 ? documents[0] : undefined);
  }
  const showHasChanged = useHasChanged(show);
  if (showHasChanged && show) {
    setEditedDocument(documents.length === 1 ? documents[0] : undefined);
    setPriceNodeEditionIsDirty(false);
  }
  const { t } = useTranslation("PriceNodeEditModal");

  const showDocumentSelect = editedDocument === undefined;
  const documentsAreTenders = areTenders(documents);

  const updateDocumentRowInstructions = useCallback(
    (row_instructions?: IRowInstruction[]) => {
      const update = documentsAreTenders ? updateTender : updatePriceEstimate;
      return update({
        id: editedDocument?.id,
        company: (editedDocument as ITender)?.company,
        mapping: {
          ...editedDocument?.mapping,
          step: MAPPING_STEP_ENUM.COMPUTED,
          row_instructions,
        },
      } as ITender);
    },
    [documentsAreTenders, editedDocument]
  );
  const queryClient = useQueryClient();
  const rowInstructionsMutation = useMutation({
    mutationFn: updateDocumentRowInstructions,
    onSuccess: (document: IDocumentData) => {
      queryClient.invalidateQueries([
        documentsAreTenders ? QUERY_KEYS_ENUM.TENDER : QUERY_KEYS_ENUM.ESTIMATE,
        document.id,
      ]);
      queryClient.invalidateQueries([
        documentsAreTenders
          ? QUERY_KEYS_ENUM.TENDER_DATA
          : QUERY_KEYS_ENUM.ESTIMATE_DATA,
        document.id,
      ]);
      setPriceNodeEditionIsDirty(true);
    },
    onError: (e) => {
      console.error(e);
    },
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleClose = (save: boolean | void = true) => {
    onClose(save);
  };
  const handleCancel = async () => {
    setShowConfirmationModal(false);
    if (editedDocument && priceNodeEditionIsDirty) {
      await rowInstructionsMutation.mutateAsync(
        editedDocument?.mapping?.row_instructions
      );
    }
    handleClose(false);
  };
  return (
    <DefaultModal
      show={show}
      handleClose={() =>
        editedDocument ? setShowConfirmationModal(true) : handleCancel()
      }
      staticBackdrop
      size={showDocumentSelect ? undefined : "xl"}
      onExited={() => setEditedDocument(undefined)}
    >
      <>
        <ConfirmationModal
          title={t("cancel ?")}
          confirm={t("confirm cancel")}
          show={showConfirmationModal}
          handleClose={(confirm) =>
            confirm ? handleCancel() : setShowConfirmationModal(false)
          }
        />
        <h4 className="text-center mt-5 px-4 mx-2 pt-4 ">
          {t(documentsAreTenders ? "tender title" : "estimate title")}
        </h4>
        {showDocumentSelect ? (
          documentsAreTenders && (
            <div className="mx-5 mb-5 mt-3">
              <h5>
                {t("select tender")}
                <RequiredAsterisk />
              </h5>
              <TenderSelect tenders={documents} onSelect={setEditedDocument} />
            </div>
          )
        ) : (
          <PriceNodeEditTable
            documentId={editedDocument.id}
            isTender={documentsAreTenders}
            base={base}
            compareColumnDisplaySettings={compareColumnDisplaySettings}
            rowInstructionsMutation={rowInstructionsMutation}
            onSave={handleClose}
            initialExpanded={
              typeof initialExpanded === "boolean"
                ? initialExpanded
                : Object.assign(initialExpanded)
            }
          />
        )}
      </>
    </DefaultModal>
  );
}
