import { useContext } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../account";
import { useModal } from "../shared/DefaultModal";
import { Footer } from "../shared/footer";
import { Header } from "../shared/header";
import { useTranslation } from "../shared/i18n";
import { AddCircledIcon } from "../shared/icons";
import { usePriceAveragePanelsQuery } from "../shared/useSharedQueries";
import { ifTest } from "../shared/utils";

import { PriceAveragePanelEditModal } from "./PriceAveragePanelModal";
import { PriceAveragePanelsTable } from "./PriceAveragePanelsTable";

export function PriceAveragePanelsPage() {
  const { t } = useTranslation("PriceAveragePanelsPage");

  const { show, handleClose, handleShow } = useModal("PriceAveragePanelEdit");

  const { user } = useContext(UserContext);
  const averagePricePanelsQuery = usePriceAveragePanelsQuery({
    original_author: user?.id,
  });

  const averagePricePanels = averagePricePanelsQuery.data;

  const navigate = useNavigate();

  return (
    <div className="min-h-100 d-flex flex-column">
      <Header />
      <PriceAveragePanelEditModal
        show={show}
        handleClose={(panel) => {
          if (!panel) {
            handleClose();
          } else {
            navigate(panel.id);
          }
        }}
      />
      <Container fluid className="pb-4 p-0">
        <Card className="mb-5 w-auto border-0 mx-0 mx-md-4">
          <Card.Body className="p-md-4">
            <Row className="mb-4">
              <Col>
                <h4>{t("priceAveragePanels")}</h4>
              </Col>
              <Col sm="auto" className="text-end">
                <Button
                  onClick={handleShow}
                  size="lg"
                  data-test={ifTest("create-panel")}
                >
                  <AddCircledIcon className="mb-1 me-1" /> {t("addOne")}
                </Button>
              </Col>
            </Row>
            {averagePricePanelsQuery.isLoading ? (
              <Row>
                <Col className="text-center">
                  <Spinner />
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <h6>{t("my price averages")}</h6>
                </Row>
                {averagePricePanels?.length ? (
                  <Row>
                    <Col>
                      <PriceAveragePanelsTable
                        averagePricePanels={averagePricePanels}
                        isLoading={
                          averagePricePanelsQuery.isLoading ||
                          (averagePricePanelsQuery.isFetching &&
                            !averagePricePanelsQuery.isPreviousData)
                        }
                      />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row className="align-content-center">
                      <Col className="text-center mt-5">
                        {t("no priceAveragePanels")}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center">
                        <Button onClick={handleShow} size="lg" className="mt-5">
                          <AddCircledIcon className="mb-1 me-1" />
                          {t("addOne")}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Container>
      <Footer />
    </div>
  );
}
