import { Cell, Table as ReactTable } from "@tanstack/react-table";
import classNames from "classnames";
import { Dispatch, SetStateAction, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { Trans } from "react-i18next";

import styles from "../compare/CompareTable/CompareTable.module.scss";
import { getRowDepth } from "../compare/CompareTable/CompareTableUtils";
import {
  MAX_SUPPORTED_DEPTH,
  TABLE_COLUMN_DATA_TYPE_ENUM,
} from "../compare/Table.types";
import { useTranslation } from "../shared/i18n";
import { Table } from "../shared/table";
import {
  IPriceAveragePanel,
  IPriceAverageRow,
} from "../shared/types/averagePrice.types";
import { IMappedTender } from "../shared/types/tender.types";
import { cancelEvent, copyToClipboard } from "../shared/utils";

import priceAverageTableStyles from "./PriceAverageTable.module.scss";

export const IGNORE_ENTIRE_TENDER = "EVERYTHING";

const TOAST_FADE_DELAY_MS = 10000;
const TOAST_FADE_SHORT_DELAY_MS = 3000;

interface IPriceAverageTableProps {
  table: ReactTable<IPriceAverageRow>;
  isLoading: boolean;
  priceAveragePanel?: IPriceAveragePanel;
  tenders?: (IMappedTender | undefined)[];
  addOrRemoveTenderReferenceCodeFromCalculation: (
    tenderId: string,
    referenceCode: string,
    add?: boolean
  ) => boolean | undefined;
}

interface ISelectedTenderAveragePriceCell {
  tenderId: string;
  rowIndex: number;
  add?: boolean;
}
interface IToastCounter {
  value: number;
  message?: string;
}

export function PriceAverageTable({
  table,
  isLoading,
  tenders,
  addOrRemoveTenderReferenceCodeFromCalculation,
}: IPriceAverageTableProps) {
  const { t } = useTranslation("PriceAverageTable");

  const [toastCounter, setToastCounter] = useState<IToastCounter>({ value: 0 });
  const [selectedAveragePriceCellIndex, setSelectedAveragePriceCellIndex] =
    useState<number>();
  const [selectedTenderAveragePriceCell, setSelectedTenderAveragePriceCell] =
    useState<ISelectedTenderAveragePriceCell>();

  return (
    <div className="mx-3 overflow-auto">
      <Toast show={toastCounter.value > 0}>
        <Toast.Body>
          <Trans
            t={t as any}
            i18nKey={toastCounter.message}
            components={{
              strong: <strong />,
            }}
          />
        </Toast.Body>
      </Toast>
      <Table
        disableColumnSizing
        table={table}
        className={classNames(styles.table, priceAverageTableStyles.table)}
        getRowClassName={(row) => {
          const isCategory = Boolean(row.subRows?.length);
          return classNames(
            isCategory && styles["category-row"],
            styles[
              `level-${Math.min(
                row.original.meta?.level ?? 0,
                MAX_SUPPORTED_DEPTH
              )}`
            ],
            styles[`depth-${getRowDepth(table, row)}`],
            priceAverageTableStyles[
              `level-${Math.min(
                row.original.meta?.level ?? 0,
                MAX_SUPPORTED_DEPTH
              )}`
            ]
          );
        }}
        onCellClick={(cell, _, __, rowIndex, event) => {
          if (
            cell.column.columnDef.meta?.dataType ===
              TABLE_COLUMN_DATA_TYPE_ENUM.TENDER_PRICE_AVERAGE &&
            tenders
              ?.filter(Boolean)
              .find((tender) => cell.column.columnDef.id?.includes(tender!.id))
              ?.ignored !== true
          ) {
            tenderCellClick(
              cell,
              rowIndex,
              event,
              table,
              selectedTenderAveragePriceCell,
              setSelectedTenderAveragePriceCell,
              addOrRemoveTenderReferenceCodeFromCalculation,
              setToastCounter
            );
          } else if (
            cell.column.columnDef.meta?.dataType ===
            TABLE_COLUMN_DATA_TYPE_ENUM.PRICE_AVERAGE
          ) {
            priceAverageCellClick(
              cell,
              rowIndex,
              event,
              table,
              selectedAveragePriceCellIndex,
              setSelectedAveragePriceCellIndex,
              setToastCounter
            );
          }
        }}
        getCellClassName={(cell, _, __, rowIndex) => {
          return classNames({
            [priceAverageTableStyles["ignored-cell"]]:
              cell.column.columnDef.meta?.dataType ===
                TABLE_COLUMN_DATA_TYPE_ENUM.TENDER_PRICE_AVERAGE &&
              isTenderCellIgnored(cell),
            [priceAverageTableStyles["selected-cell"]]:
              cell.column.columnDef.meta?.dataType ===
              TABLE_COLUMN_DATA_TYPE_ENUM.TENDER_PRICE_AVERAGE,
            [priceAverageTableStyles["selected-cell"]]:
              cell.column.columnDef.meta?.dataType ===
                TABLE_COLUMN_DATA_TYPE_ENUM.PRICE_AVERAGE &&
              rowIndex === selectedAveragePriceCellIndex,
            [priceAverageTableStyles[
              `selected-tender-cell-${selectedTenderAveragePriceCell?.add}`
            ]]:
              cell.column.columnDef.meta?.dataType ===
                TABLE_COLUMN_DATA_TYPE_ENUM.TENDER_PRICE_AVERAGE &&
              rowIndex === selectedTenderAveragePriceCell?.rowIndex &&
              cell.column.columnDef.id?.includes(
                selectedTenderAveragePriceCell?.tenderId
              ),
          });
        }}
        noPagination
        stickyHeaders
        isLoading={isLoading}
      />
    </div>
  );
}

function tenderCellClick(
  cell: Cell<IPriceAverageRow, unknown>,
  rowIndex: number,
  event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
  table: ReactTable<IPriceAverageRow>,
  selectedTenderAveragePriceCellIndex:
    | ISelectedTenderAveragePriceCell
    | undefined,
  setSelectedTenderAveragePriceCell: Dispatch<
    SetStateAction<ISelectedTenderAveragePriceCell | undefined>
  >,
  addOrRemoveTenderReferenceCodeFromCalculation: (
    tenderId: string,
    ref: string,
    add?: boolean | undefined
  ) => boolean | undefined,
  setToastCounter: Dispatch<SetStateAction<IToastCounter>>
) {
  // shift click allows to select a range of cells so handle the event even on empty cells
  if (cell.getValue() || event.shiftKey) {
    cancelEvent(event);
    const tenderId = cell.column.columnDef.meta?.tenderId;
    const sameTender =
      selectedTenderAveragePriceCellIndex?.tenderId === tenderId;
    // add or remove the the clicked cell from price calculation and make it the only selected cell
    if (cell.getValue() && (!event.shiftKey || !sameTender)) {
      if (tenderId === undefined) {
        console.error("invalid tenderId: ", tenderId);
        return;
      }
      const ref = cell.row.original.ref;
      const addedTenderReferenceCode =
        addOrRemoveTenderReferenceCodeFromCalculation(tenderId, ref);
      setSelectedTenderAveragePriceCell({
        tenderId,
        rowIndex,
        add: addedTenderReferenceCode,
      });
      setToastCounter((counter) => ({
        message: `toggled ${addedTenderReferenceCode}`,
        value: counter.value + 1,
      }));
      setTimeout(() => {
        setToastCounter((counter) => ({
          ...counter,
          value: counter.value - 1,
        }));
        setSelectedTenderAveragePriceCell((tender) =>
          tender?.rowIndex === rowIndex ? undefined : tender
        );
      }, TOAST_FADE_DELAY_MS);
    }
    // add or remove the range of cells on the same tender from price calculation and reset cell selection
    else if (
      event.shiftKey &&
      sameTender &&
      selectedTenderAveragePriceCellIndex
    ) {
      // we'll select from the previous selected cell's RowIndex to the current clicked cell's rowIndex
      const rangeMinRowIndex = Math.min(
        selectedTenderAveragePriceCellIndex.rowIndex,
        rowIndex
      );
      const rangeMaxRowIndex = Math.max(
        selectedTenderAveragePriceCellIndex.rowIndex,
        rowIndex
      );
      table
        .getRowModel()
        .rows.slice(rangeMinRowIndex, rangeMaxRowIndex + 1)
        .forEach(
          (row, index) =>
            index !==
              selectedTenderAveragePriceCellIndex.rowIndex - rangeMinRowIndex &&
            row.getValue(`tenders.${tenderId}`) &&
            addOrRemoveTenderReferenceCodeFromCalculation(
              tenderId!,
              row.original.ref,
              selectedTenderAveragePriceCellIndex.add
            )
        );
      setSelectedTenderAveragePriceCell(undefined);
      setToastCounter((counter) => ({
        message: `toggled range ${selectedTenderAveragePriceCellIndex.add}`,
        value: counter.value + 1,
      }));
      setTimeout(() => {
        setToastCounter((counter) => ({
          ...counter,
          value: counter.value - 1,
        }));
        setSelectedTenderAveragePriceCell((tender) =>
          tender?.rowIndex === rowIndex ? undefined : tender
        );
      }, TOAST_FADE_SHORT_DELAY_MS);
    }
  }
}

/**
 * selects a single or a range of PriceAverage Cells
 * copies the values to the clipboard
 *
 * displays a toast to highlight the action
 */
function priceAverageCellClick(
  cell: Cell<IPriceAverageRow, unknown>,
  rowIndex: number,
  event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
  table: ReactTable<IPriceAverageRow>,
  selectedAveragePriceCellIndex: number | undefined,
  setSelectedAveragePriceCellIndex: Dispatch<
    SetStateAction<number | undefined>
  >,
  setToastCounter: Dispatch<SetStateAction<IToastCounter>>
) {
  cancelEvent(event);
  // handle range selection
  if (event.shiftKey) {
    if (selectedAveragePriceCellIndex !== undefined) {
      copyToClipboard(
        table
          .getRowModel()
          .rows.slice(
            Math.min(selectedAveragePriceCellIndex, rowIndex),
            Math.max(selectedAveragePriceCellIndex, rowIndex) + 1
          )
          .map((row) => row.getValue("average"))
          .join("\n")
      );
      setSelectedAveragePriceCellIndex(undefined);

      // display toast message
      setToastCounter((counter) => ({
        message: "copied range",
        value: counter.value + 1,
      }));
      setTimeout(
        () =>
          setToastCounter((counter) => ({
            ...counter,
            value: counter.value - 1,
          })),
        TOAST_FADE_SHORT_DELAY_MS
      );
    }
  }
  // single cell selection
  else {
    setSelectedAveragePriceCellIndex(rowIndex);
    copyToClipboard(cell.getValue<string>());

    // display toast message
    setToastCounter((counter) => ({
      message: "copied",
      value: counter.value + 1,
    }));
    setTimeout(() => {
      setToastCounter((counter) => ({
        ...counter,
        value: counter.value - 1,
      }));
      setSelectedAveragePriceCellIndex((index) =>
        index === rowIndex ? undefined : index
      );
    }, TOAST_FADE_DELAY_MS);
  }
}

/** check if tender cell or the entire tender is ignored */
export function isTenderCellIgnored(cell: Cell<IPriceAverageRow, unknown>) {
  const priceAveragePanel =
    cell.getContext().table.options.meta?.priceAveragePanel;
  const tenderId = cell.column.id.split(".")[1];
  return (
    priceAveragePanel?.ignored_classifications[tenderId]?.includes(
      IGNORE_ENTIRE_TENDER
    ) === true ||
    priceAveragePanel?.ignored_classifications[tenderId]?.includes(
      cell.row.original.ref
    ) === true
  );
}
