import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";

import { DefaultModal } from "../shared/DefaultModal";
import { loadExcel } from "../shared/excel/loader";
import { useTranslation } from "../shared/i18n";
import { FilesContext } from "../shared/inputs";
import { TCell } from "../shared/types/lot.types";
import { IMappingStep, IStep } from "../shared/types/lots.types";

import { LotsModalFooter } from "./LotsModalFooter";

export interface ILotsContentProps {
  show: boolean;
  handleClose: (save: boolean | void) => void;
  steps: IStep[];
  selectedIndex: number;
  sheetMappingStartIndex: number;
  files?: File[];
  setSheets: React.Dispatch<
    React.SetStateAction<{
      sheets: { [key: string]: () => TCell[][] };
      rawSheets: { [key: string]: () => any[][] };
    }>
  >;
  setTab: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  mappingSteps: IMappingStep[];
  title: string;
  onSubmit: () => void;
  isLoading?: boolean;
}

export function LotsModalContent({
  show,
  handleClose,
  steps,
  selectedIndex,
  sheetMappingStartIndex,
  files,
  setSheets,
  setTab,
  setSelectedIndex,
  mappingSteps,
  title,
  onSubmit,
  isLoading,
}: ILotsContentProps) {
  const { t } = useTranslation("loadExcel");
  const currentStep = steps[Math.min(selectedIndex, steps.length - 1)];
  const [isProcessingFiles, setIsProcessingFiles] = useState(false);
  const [loadError, setLoadError] = useState<any>();
  const [processError, setProcessError] = useState<any>();

  useEffect(() => {
    setLoadError(undefined);
    setProcessError(undefined);
    setSheets({ sheets: {}, rawSheets: {} });
    if (files && files.length) {
      const reader = new FileReader();
      reader.onload = async function () {
        const fileData = reader.result as Buffer;
        try {
          const { sheets, rawSheets } = await loadExcel(fileData);
          setSheets({ sheets, rawSheets });
          if (Object.keys(sheets).length === 1) {
            // if only one tab is present, select it by default
            setTab(Object.keys(sheets)[0]);
          }
        } catch (error: any) {
          console.error(error);
          setProcessError(error);
        } finally {
          setIsProcessingFiles(false);
        }
      };
      reader.onerror = (error) => {
        setIsProcessingFiles(false);
        console.error(error);
        setLoadError(error);
      };
      setIsProcessingFiles(true);
      reader.readAsBinaryString(files[0]);
      // reset selected tab
      setTab(undefined);
    }
  }, [files, setSheets, setTab]);

  const nextEnabled = Boolean(currentStep?.isCompleted(selectedIndex));
  const isLastStep =
    selectedIndex < sheetMappingStartIndex + mappingSteps.length - 1;
  const goNext = async () => {
    // execute callback if present, and stay on current step if return value is false
    if (
      nextEnabled &&
      !isLoading &&
      (!currentStep.beforeNextStep || (await currentStep.beforeNextStep()))
    ) {
      // go to the next step, or call submit if this is the last step
      isLastStep ? setSelectedIndex((index) => index + 1) : onSubmit();
    }
  };

  return (
    <>
      <DefaultModal
        show={show}
        title={title}
        handleClose={handleClose}
        staticBackdrop
        onKeyUp={(event: KeyboardEvent) => {
          if (event.key === "Enter") {
            event.stopPropagation();
            goNext();
          }
        }}
        size={currentStep.modalSize}
        footer={
          <>
            {loadError && (
              <Alert variant="danger">
                {t("loadError", { message: loadError.message })}
              </Alert>
            )}
            {processError && (
              <Alert variant="danger">
                {t("processError", { message: processError.message })}
              </Alert>
            )}
            <LotsModalFooter
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              beforeNextStep={currentStep.beforeNextStep}
              nextEnabled={nextEnabled}
              footerHasSpace={currentStep.footerHasSpace?.()}
              optional={currentStep.isOptional?.()}
              isLoading={isProcessingFiles || isLoading}
              hidePrevious={currentStep.hidePrevious}
              isLastStep={isLastStep}
              goNext={goNext}
            />
          </>
        }
      >
        <FilesContext.Provider
          value={{
            isProcessingFiles,
          }}
        >
          {currentStep.component}
        </FilesContext.Provider>
      </DefaultModal>
    </>
  );
}
