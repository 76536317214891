import classNames from "classnames";
import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

import { ContactSupportLink } from "../errors/ContactSupport";
import { useTranslation } from "../i18n";

export function Footer({ className }: { className?: string }) {
  const { t } = useTranslation("Footer");
  return (
    <Navbar
      as="footer"
      className={classNames(className, "position-sticky top-100")}
    >
      <Container>
        <ContactSupportLink>{t("contact")}</ContactSupportLink>
      </Container>
    </Navbar>
  );
}
