import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { GuardedButton } from "../account/GuardedButton";
import { useModal } from "../shared/DefaultModal";
import { EnumCategoryListView, EnumListView } from "../shared/EnumListView";
import { OperationModal } from "../shared/OperationModal";
import { StatusBadge } from "../shared/StatusBadge";
import styles from "../shared/_exports.module.scss";
import { useTranslation } from "../shared/i18n";
import { formatTolerant } from "../shared/i18n/dateFormatter";
import { HalfStarIcon, PenIcon, TendersIcon } from "../shared/icons";
import { IOperation } from "../shared/types/operation.types";
import { useWorksQuery } from "../shared/useSharedQueries";
import { ifTest } from "../shared/utils";

export function OperationHeader({ operation }: { operation: IOperation }) {
  const { t } = useTranslation("OperationHeader");
  const { show, handleClose, handleShow } = useModal("OperationEditionModal");

  const worksQuery = useWorksQuery(operation?.id);
  const [work] = worksQuery.data ?? [];

  return (
    <Row as="header" className="bg-light mb-4 rounded-4 p-md-4 m-md-4 m-0 p-2">
      {show && (
        <OperationModal show onHide={handleClose} operation={operation} />
      )}
      <Col sm={5}>
        <h4 data-test={ifTest("operation-title")}>{operation.name}</h4>
        <StatusBadge status={operation.status} className="mb-3" />
        <div className="mb-3">
          <EnumCategoryListView
            list={operation.building_categories}
            translationKey={"BuildingCategorySelect"}
          />
        </div>
        <Row xs="auto">
          <Col>
            <GuardedButton
              variant="outline-secondary"
              className="rounded d-flex align-items-center"
              onClick={handleShow}
            >
              <PenIcon fill={styles.black} className="me-2 flex-shrink-0" />
              {t("modify operation")}
            </GuardedButton>
          </Col>
        </Row>
      </Col>
      <Col>
        <h6 className="mt-3 mb-4">{t("operation features")}</h6>
        <Row xs="auto">
          <Col className="pe-3" md={4}>
            <Row>
              <Col xs="auto" className="pe-0">
                <div className="p-2 rounded-circle bg-black lh-1">
                  <TendersIcon size={16} />
                </div>
              </Col>
              <Col as="dl">
                <dt>{t("project reference")}</dt>
                <dd>{operation.reference_code}</dd>
              </Col>
            </Row>
          </Col>
          <Col className="pe-3" md={3}>
            <Row>
              <Col xs="auto" className="pe-0">
                <div className="p-2 rounded-circle bg-black lh-1">
                  <HalfStarIcon size={16} />
                </div>
              </Col>
              <Col as="dl">
                <dt>{t("location")}</dt>
                <dd>
                  {work?.location_postcode ??
                    work?.location_city ??
                    work?.location_name}
                </dd>
              </Col>
            </Row>
          </Col>
          <Col className="pe-3" md={4}>
            <Row>
              <Col xs="auto" className="pe-0">
                <div className="p-2 rounded-circle bg-black lh-1">
                  <HalfStarIcon size={16} />
                </div>
              </Col>
              <Col as="dl">
                <dt>{t("floor area")}</dt>
                <dd>{operation.floor_area} m²</dd>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row xs="auto">
          <Col className="pe-3" md={4}>
            <Row>
              <Col xs="auto" className="pe-0">
                <div className="p-2 rounded-circle bg-black lh-1">
                  <HalfStarIcon size={16} />
                </div>
              </Col>
              <Col as="dl">
                <dt>{t("tender date")}</dt>
                <dd>{formatTolerant(operation.tender_date, "yyyy")}</dd>
              </Col>
            </Row>
          </Col>
          <Col className="pe-3" md={3}>
            <Row>
              <Col xs="auto" className="pe-0">
                <div className="p-2 rounded-circle bg-black lh-1">
                  <HalfStarIcon size={16} />
                </div>
              </Col>
              <Col as="dl">
                <dt>{t("work type")}</dt>
                <dd>
                  <EnumListView list={operation.work_type} />
                </dd>
              </Col>
            </Row>
          </Col>

          {operation.permit_date && (
            <Col className="pe-3" md={4}>
              <Row>
                <Col xs="auto" className="pe-0">
                  <div className="p-2 rounded-circle bg-black lh-1">
                    <HalfStarIcon size={16} />
                  </div>
                </Col>
                <Col as="dl">
                  <dt>{t("permit date")}</dt>
                  <dd>{formatTolerant(operation.permit_date, "yyyy")}</dd>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}
