import {
  ColumnDef,
  ExpandedState,
  GroupColumnDef,
  Table,
  VisibilityState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useMemo, useState } from "react";

import { useCanEditCurrentLot } from "../../account/guards";
import {
  DESIGNATION_COL_WIDTH,
  UNIT_COL_WIDTH,
} from "../../shared/excel/ExportTableToExcel";
import { useTranslation } from "../../shared/i18n";
import { ExpandHeader } from "../../shared/table/ExpandHeader";
import { IDocumentData } from "../../shared/types/document.types";
import { IPriceEstimate, isMOE } from "../../shared/types/estimate.types";
import { IPriceNode } from "../../shared/types/priceNode.types";
import { isTender } from "../../shared/types/tender.types";
import {
  DEFAULT_EMPTY_VALUE_PLACEHOLDER,
  getAlphabetIdFromIndex,
  isDevelopmentEnv,
} from "../../shared/utils";
import { ICompareDifferenceSettings } from "../Compare.types";
import { defaultCompareDifferenceSettings } from "../CompareDifference";
import { ITableRow, TABLE_COLUMN_DATA_TYPE_ENUM } from "../Table.types";
import {
  compareColumnHidableDataTypes,
  defaultCompareColumnDisplaySettings,
} from "../useCompareColumnDisplaySettings";

import styles from "./CompareTable.module.scss";
import {
  EDIT_ENABLED,
  ICompareRow,
  ICompareTableMeta,
} from "./CompareTable.types";
import { DesignationCell, UnitCell } from "./CompareTableCells";
import {
  IRowsDict,
  addPriceNodeToCompareRowsAndGetTotalPrice,
  getComparedEstimateColumns,
  getComparedTenderColumns,
  getFullNomenclature,
  getLineDifferenceElementId,
  getRootVariantOrOptionNodes,
  mapAdditionalEstimateColumns,
  mapAdditionalTenderColumns,
  mapDocumentRowsDict,
} from "./CompareTableUtils";
import { useCompareTableCells } from "./useCompareTableCells";
import { useCompareTableComments } from "./useCompareTableComments";

export const NOMENCLATURE_COLUMN_ID = "nomenclature";
export const DESIGNATION_COLUMN_ID = "designation";

const ESTIMATE_GENERATED_NOMENCLATURE_PREFIX = "#";
export const TOTAL_ID = "globalTotal";
export const VARIANT_AND_OPTIONS_SPACER_ID = "variantsAndOptionsSpacer";
export const VARIANT_AND_OPTIONS_ID = "variantsAndOptions";

const HEADING_IDS = [
  TOTAL_ID,
  VARIANT_AND_OPTIONS_ID,
  VARIANT_AND_OPTIONS_SPACER_ID,
];

export function isHeadingId(id: string) {
  return HEADING_IDS.includes(id);
}

const CHARACTER_WIDTH = 1;

interface IRowWithSub {
  subRows?: IRowWithSub[];
  nomenclature?: string;
}
/** returns the maximum nesting depth, 1 indexed, as well as max nomenclature length */
function getMaxDepth(rows: IRowWithSub[]): {
  maxDepth: number;
  maxNomenclatureLength: number;
} {
  return rows.reduce(
    ({ maxDepth, maxNomenclatureLength }, row) => {
      const {
        maxDepth: childrenMaxDepth,
        maxNomenclatureLength: childrenMaxNomenclatureLength,
      } = row.subRows
        ? getMaxDepth(row.subRows)
        : { maxDepth: 0, maxNomenclatureLength: 0 };

      return {
        maxDepth: Math.max(maxDepth, childrenMaxDepth + 1),
        maxNomenclatureLength: Math.max(
          maxNomenclatureLength,
          childrenMaxNomenclatureLength + (row.nomenclature?.length ?? 1) + 1
        ),
      };
    },
    { maxDepth: 0, maxNomenclatureLength: 0 }
  );
}

export function applyRowMeta<T extends ITableRow<T>>(
  rows: T[],
  maxDepth: number,
  compareDifferenceSettings: ICompareDifferenceSettings,
  tenderIds: string[],
  depth: number
) {
  rows.forEach((row) => {
    const level = maxDepth - depth;
    row.meta = row.meta ?? {};
    row.meta.level = level;
    const lineDifferenceElementId =
      compareDifferenceSettings.line_difference_active &&
      getLineDifferenceElementId(row as unknown as ICompareRow, tenderIds);
    if (lineDifferenceElementId) {
      row.meta.lineDifferenceElementId = lineDifferenceElementId;
    }
    if (row.subRows?.length) {
      applyRowMeta(
        row.subRows,
        maxDepth,
        compareDifferenceSettings,
        tenderIds,
        depth + 1
      );
    }
  });
  return rows;
}

/**
 * retrieves nested rows up to the specified level
 * @param rows
 * @param level
 * @returns
 */
export function getNestedRowsUpToDepth<T extends { subRows?: T[] }>(
  rows: T[],
  level: number
) {
  const allRows: T[] = [];
  if (level > 0) {
    for (let row of rows) {
      allRows.push(row);
      allRows.push(...getNestedRowsUpToDepth(row.subRows ?? [], level - 1));
    }
  }
  return allRows;
}

/**
 * Gets the ExpandedState to expand rows up to the provided depth
 * @param expanded The current expanded state to overwrite
 * @param rows The available rows
 * @param depth The depth up to which to expand
 * @param getRowId Callback to get a row's id
 * @returns
 */
export function getExpandedUpToDepth<T extends { subRows?: T[] }>(
  expanded: ExpandedState,
  rows: T[],
  depth: number,
  getRowId: (row: T) => string
) {
  return getNestedRowsUpToDepth(rows, depth).reduce(
    (previous, current) => {
      previous[getRowId(current)] = true;
      return previous;
    },
    typeof expanded == "boolean" ? ({} as Record<string, boolean>) : expanded
  );
}

export function useCompareTable({
  baseEstimate,
  comparedDocuments,
  compareDifferenceSettings = defaultCompareDifferenceSettings,
  compareColumnDisplaySettings = defaultCompareColumnDisplaySettings,
  initialExpanded = {},
  additionalCommonColumns,
  additionalDocumentColumns,
  additionalDocumentTopColumn,
  toolsHeader,
  editEnabled,
  keepVariantOrOption = false,
}: {
  baseEstimate?: IPriceEstimate;
  comparedDocuments: IDocumentData[];
  compareDifferenceSettings?: ICompareDifferenceSettings;
  compareColumnDisplaySettings?: TABLE_COLUMN_DATA_TYPE_ENUM[];
  initialExpanded?: ExpandedState;
  additionalCommonColumns?: ColumnDef<ICompareRow>[];
  additionalDocumentColumns?: ColumnDef<ICompareRow>[];
  additionalDocumentTopColumn?: {
    header: string;
    id?: string;
    columns: ColumnDef<ICompareRow>[];
  };
  toolsHeader?: JSX.Element;
  editEnabled?: EDIT_ENABLED;
  keepVariantOrOption?: boolean;
}): Table<ICompareRow> {
  const { t } = useTranslation("useCompareTable");

  const [expanded, setExpanded] = useState<ExpandedState>(initialExpanded);

  const displayEstimate = compareColumnDisplaySettings.includes(
    TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE
  );

  const { data, maxDepth, maxNomenclatureLength } = useMemo<{
    data: ICompareRow[];
    maxDepth: number;
    maxNomenclatureLength: number;
  }>(() => {
    let rows: ICompareRow[] = [];
    let variantOrOptionRows: ICompareRow[] = [];
    if (baseEstimate?.data !== undefined) {
      addPriceNodeToCompareRowsAndGetTotalPrice(
        {
          total_price: baseEstimate.data.total_price,
          designation: { component: <b>{t("Total")}</b>, value: t("Total") },
          id: TOTAL_ID,
          attributions: undefined,
          row_number: -1,
          nomenclature: " ",
          logs: baseEstimate.data.logs,
        },
        rows,
        ESTIMATE_GENERATED_NOMENCLATURE_PREFIX
      );
      baseEstimate.data.children?.forEach((node) =>
        addPriceNodeToCompareRowsAndGetTotalPrice(
          node,
          rows,
          ESTIMATE_GENERATED_NOMENCLATURE_PREFIX
        )
      );

      if (keepVariantOrOption) {
        const variantOrOptionNodes: IPriceNode[] = getRootVariantOrOptionNodes(
          baseEstimate.data,
          [],
          ""
        );
        const variantOrOptionTotal = variantOrOptionNodes.reduce(
          (total, node) => total + (node.total_price ?? 0),
          0
        );

        variantOrOptionRows.push({
          id: VARIANT_AND_OPTIONS_SPACER_ID,
          tenders: {},
          designation: "",
        });

        addPriceNodeToCompareRowsAndGetTotalPrice(
          {
            total_price: variantOrOptionTotal,
            designation: {
              component: <b>{t("Variants and Options")}</b>,
              value: t("Variants and Options"),
            },
            id: VARIANT_AND_OPTIONS_ID,
            attributions: undefined,
            row_number: -1,
            nomenclature: " ",
            children: variantOrOptionNodes,
          },
          variantOrOptionRows,
          ESTIMATE_GENERATED_NOMENCLATURE_PREFIX,
          undefined,
          undefined,
          undefined,
          true
        );
      }
    }
    comparedDocuments.forEach((document, index) => {
      const nodeList = document.data?.children?.filter((node) => node);
      const documentId = document.id;
      // prefix unknown nomenclature with a letter if Tender or a special symbol if estimate
      const nomenclatureDocumentPrefixId = isTender(document)
        ? getAlphabetIdFromIndex(index)
        : ESTIMATE_GENERATED_NOMENCLATURE_PREFIX;
      const extraTenderRowsBuffer: ICompareRow[] = [];
      const extraTenderVariantOrOptionRowsBuffer: ICompareRow[] = [];
      addPriceNodeToCompareRowsAndGetTotalPrice(
        {
          total_price: document.data?.total_price,
          designation: t("Total"),
          id: TOTAL_ID,
          attributions: undefined,
          row_number: -1,
          nomenclature: "",
          logs: document.data?.logs,
        },
        rows,
        nomenclatureDocumentPrefixId,
        documentId,
        extraTenderRowsBuffer,
        isTender(document),
        false
      );
      nodeList?.forEach((node) =>
        addPriceNodeToCompareRowsAndGetTotalPrice(
          node,
          rows,
          nomenclatureDocumentPrefixId,
          documentId,
          extraTenderRowsBuffer,
          isTender(document),
          false
        )
      );
      if (keepVariantOrOption && document.data) {
        const variantOrOptionNodes: IPriceNode[] = getRootVariantOrOptionNodes(
          document.data,
          [],
          ""
        );
        const variantOrOptionTotal = variantOrOptionNodes.reduce(
          (total, node) => total + (node.total_price ?? 0),
          0
        );
        addPriceNodeToCompareRowsAndGetTotalPrice(
          {
            total_price: variantOrOptionTotal,
            designation: {
              component: <b>{t("Variants and Options")}</b>,
              value: t("Variants and Options"),
            },
            id: VARIANT_AND_OPTIONS_ID,
            attributions: undefined,
            row_number: -1,
            nomenclature: " ",
            children: variantOrOptionNodes,
          },
          variantOrOptionRows,
          nomenclatureDocumentPrefixId,
          documentId,
          extraTenderVariantOrOptionRowsBuffer,
          isTender(document),
          true
        );
      }
      if (extraTenderRowsBuffer.length > 0) {
        rows.push(...extraTenderRowsBuffer);
        variantOrOptionRows.push(...extraTenderVariantOrOptionRowsBuffer);
      }
    });
    // remove estimate only rows if estimate doesn't get displayed
    if (baseEstimate && !displayEstimate) {
      rows = rows.filter((row) => Object.entries(row.tenders).length);
      if (variantOrOptionRows.length >= 2) {
        variantOrOptionRows[1].subRows = variantOrOptionRows[1].subRows?.filter(
          (row) => isHeadingId(row.id) || Object.entries(row.tenders).length
        );
      }
    }
    const { maxDepth, maxNomenclatureLength } = getMaxDepth(rows);
    return {
      data: [...rows, ...variantOrOptionRows],
      maxDepth,
      maxNomenclatureLength,
    };
  }, [
    baseEstimate,
    comparedDocuments,
    displayEstimate,
    t,
    keepVariantOrOption,
  ]);

  const tenderIds = useMemo(
    () => comparedDocuments.map((tender) => tender.id),
    [comparedDocuments]
  );

  const dataWithClassNames = useMemo(() => {
    applyRowMeta(data, maxDepth, compareDifferenceSettings, tenderIds, 0);
    return data;
  }, [data, maxDepth, compareDifferenceSettings, tenderIds]);

  const rowByDocumentIdByRowNumber = useMemo(() => {
    const dict: { [id: string]: IRowsDict } = { estimate: {} };
    comparedDocuments.forEach((document) => (dict[document.id] = {}));
    dataWithClassNames.forEach((doc) => mapDocumentRowsDict(doc, dict, []));
    return dict;
  }, [dataWithClassNames, comparedDocuments]);

  const compareColumns: GroupColumnDef<ICompareRow>[] = useMemo(() => {
    const isCompletelyExpanded = expanded === true;

    const columns: GroupColumnDef<ICompareRow>[] = [
      {
        header: (toolsHeader as any) ?? "",
        id: "description",
        columns: [
          {
            header: t("nomenclature"),
            accessorKey: "nomenclature",
            id: NOMENCLATURE_COLUMN_ID,
            cell: getFullNomenclature,
            meta: {
              className: styles["nomenclature-cell"],
              dataType: TABLE_COLUMN_DATA_TYPE_ENUM.NOMENCLATURE,
              excel: {
                width: maxNomenclatureLength * CHARACTER_WIDTH,
                style: { numFmt: "text" },
              },
            },
          },
          {
            header: () => (
              <ExpandHeader
                value={t("designation")}
                isCompletelyExpanded={isCompletelyExpanded}
                setExpanded={setExpanded}
              />
            ),
            id: DESIGNATION_COLUMN_ID,
            accessorFn: (original) =>
              original.displayed_designation ?? original.designation,
            cell: DesignationCell,
            meta: {
              className: classNames(
                styles["designation-cell"],
                "position-relative vertical-align-middle"
              ),
              dataType: TABLE_COLUMN_DATA_TYPE_ENUM.DESIGNATION,
              excel: { width: DESIGNATION_COL_WIDTH, header: t("designation") },
            },
          },
          {
            header: t("unit"),
            accessorFn: (originalRow) => {
              const rowHasEstimate = Boolean(originalRow.estimate);
              const estimateUnit = originalRow.estimate?.unit;
              const firstTenderUnit = Object.values(originalRow.tenders).find(
                (tender) => tender
              )?.unit;

              // show estimate's unit or first available tender's one
              const unitToDisplay =
                (rowHasEstimate ? estimateUnit : firstTenderUnit)?.trim() ||
                DEFAULT_EMPTY_VALUE_PLACEHOLDER;

              return unitToDisplay;
            },
            cell: UnitCell,
            meta: {
              dataType: TABLE_COLUMN_DATA_TYPE_ENUM.UNIT,
              className: classNames(
                "text-end",
                styles["unit-cell"],
                !additionalCommonColumns?.length && styles["last-cell"]
              ),
              excel: {
                width: UNIT_COL_WIDTH,
                style: {
                  font: { bold: true },
                },
              },
            },
          },
          ...(additionalCommonColumns ?? []),
        ],
      },
    ];
    if (
      baseEstimate &&
      compareColumnDisplaySettings.includes(
        TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE
      )
    ) {
      if (additionalDocumentTopColumn) {
        columns.push({
          id: `${baseEstimate.id}-additional`,
          ...additionalDocumentTopColumn,
          header: `${t("estimate")} | ${additionalDocumentTopColumn.header}`,
          columns: additionalDocumentTopColumn.columns.map(
            mapAdditionalEstimateColumns
          ),
          meta: {
            dataType: TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE,
          },
        });
      }
      columns.push({
        header: t("estimate"),
        id: baseEstimate.id,
        columns: getComparedEstimateColumns(
          baseEstimate,
          rowByDocumentIdByRowNumber.estimate,
          compareColumnDisplaySettings,
          t,
          additionalDocumentColumns
        ),
        meta: {
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE,
          work_id: baseEstimate.mapping?.works?.[0].work_id,
        },
      });
    }
    columns.push(
      ...comparedDocuments.flatMap((tender, index) => {
        const tenderColumns = [];
        const tenderAlphabetId = getAlphabetIdFromIndex(index);
        let header: string;
        if (isTender(tender)) {
          header = `${tenderAlphabetId}. ${tender.company_name ?? ""}${
            tender.allotment_name ? `(${tender.allotment_name})` : ""
          } ${
            tender.version !== undefined
              ? t("document version prefix", { version: tender.version })
              : ""
          }`;
        } else {
          header = t(isMOE(tender) ? "estimate MOE" : "frame MOE");
        }

        if (additionalDocumentTopColumn) {
          tenderColumns.push({
            id: `${tender.id}-additional`,
            ...additionalDocumentTopColumn,
            header: `${header} | ${additionalDocumentTopColumn.header}`,
            columns: additionalDocumentTopColumn.columns.map((column) =>
              mapAdditionalTenderColumns(column, tender)
            ),
          });
        }

        tenderColumns.push({
          header,
          id: tender.id,
          meta: {
            work_id: tender.mapping?.works?.[0].work_id,
            dataType: TABLE_COLUMN_DATA_TYPE_ENUM.TENDER,
          },
          columns: getComparedTenderColumns(
            tender,
            compareDifferenceSettings.number_difference,
            rowByDocumentIdByRowNumber[tender.id],
            rowByDocumentIdByRowNumber.estimate,
            compareColumnDisplaySettings,
            t,
            additionalDocumentColumns
          ),
        });
        return tenderColumns;
      })
    );

    return columns;
  }, [
    expanded,
    toolsHeader,
    t,
    maxNomenclatureLength,
    additionalCommonColumns,
    baseEstimate,
    compareColumnDisplaySettings,
    comparedDocuments,
    additionalDocumentTopColumn,
    rowByDocumentIdByRowNumber,
    additionalDocumentColumns,
    compareDifferenceSettings.number_difference,
  ]);

  const enableComments = compareColumnDisplaySettings.includes(
    TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS
  );
  const {
    updateComment,
    commentByRowNumberByRowDocumentIdByColDocumentId,
    setExpandedCommentColumnsByDocumentIds,
    expandedCommentColumnsByDocumentIds,
  } = useCompareTableComments(baseEstimate, comparedDocuments, enableComments);
  const rowTypeByRowNumberByRowDocumentIdByColDocumentId = useMemo(
    () => ({}),
    []
  );

  const { updateQuantity, updateUnitPrice, updateDesignation } =
    useCompareTableCells(baseEstimate, comparedDocuments);

  // edit only enabled when lot isn't closed or user is admin
  const { canEdit } = useCanEditCurrentLot();

  if (!canEdit) {
    editEnabled = undefined;
  }

  const tableMeta = useMemo<ICompareTableMeta>(
    () => ({
      compareDifferenceSettings,
      tenderIds,
      maxDepth,
      estimateId: baseEstimate?.id,
      updateComment,
      updateQuantity,
      updateUnitPrice,
      updateDesignation,
      commentByRowNumberByRowDocumentIdByColDocumentId,
      rowTypeQuantityStdByRowNumberByRowDocumentIdByColDocumentId:
        rowTypeByRowNumberByRowDocumentIdByColDocumentId,
      setExpandedCommentColumnsByDocumentIds,
      expandedCommentColumnsByDocumentIds,
      editEnabled,
    }),
    [
      compareDifferenceSettings,
      tenderIds,
      maxDepth,
      baseEstimate?.id,
      updateComment,
      updateQuantity,
      updateUnitPrice,
      updateDesignation,
      commentByRowNumberByRowDocumentIdByColDocumentId,
      setExpandedCommentColumnsByDocumentIds,
      expandedCommentColumnsByDocumentIds,
      rowTypeByRowNumberByRowDocumentIdByColDocumentId,
      editEnabled,
    ]
  );

  const columnVisibility = useMemo<VisibilityState>(
    () =>
      // get all columns, group header or not
      compareColumns
        .flatMap((column) =>
          column.columns ? [column, ...column.columns] : [column]
        )
        .reduce(
          (columnVisibility, column) =>
            // column must have a hidable dataType to be included in the state (displayed by default)
            column.id &&
            column.meta?.dataType &&
            compareColumnHidableDataTypes.includes(column.meta?.dataType)
              ? {
                  ...columnVisibility,
                  [column.id]: compareColumnDisplaySettings.includes(
                    column.meta?.dataType
                  ),
                }
              : columnVisibility,
          {}
        ),
    [compareColumns, compareColumnDisplaySettings]
  );

  const table = useReactTable<ICompareRow>({
    state: {
      expanded,
      // hide estimate columns if required
      columnVisibility,
    },
    onExpandedChange: setExpanded,
    columns: compareColumns,
    data: dataWithClassNames,
    debugTable: isDevelopmentEnv,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row: any) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    meta: tableMeta,
    enableSorting: false,
  });

  return table;
}
