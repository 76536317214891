import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useEnumTranslation, useTranslation } from "./i18n";

export function ListView({ list }: { list?: string[] }) {
  const { tEnum } = useEnumTranslation();
  const listOfUniqueItems = [...new Set(list ?? [])];
  switch (listOfUniqueItems.length) {
    case 0:
      return null;
    case 1:
      return <>{listOfUniqueItems[0]}</>;
    default:
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <ul className="text-start ps-2 mb-0">
                {listOfUniqueItems.map((enu) => (
                  <li key={enu}>{enu}</li>
                ))}
              </ul>
            </Tooltip>
          }
        >
          <span>{tEnum("multiples")}</span>
        </OverlayTrigger>
      );
  }
}

export function EnumListView({ list }: { list?: string[] }) {
  const { tEnum } = useEnumTranslation();
  const enumList = [...new Set(list ?? [])].map((enu) => tEnum(enu));
  return <ListView list={enumList} />;
}

export function EnumCategoryListView({
  list,
  translationKey,
}: {
  list?: string[];
  translationKey: string;
}) {
  const { t } = useTranslation(translationKey);
  const { tEnum } = useEnumTranslation();
  const enumListWithPrefixedCategory = [...new Set(list ?? [])].map(
    (enu) => `${t(enu.split(".")[0])} : ${tEnum(enu)}`
  );
  return <ListView list={enumListWithPrefixedCategory} />;
}
