import {
  IOrganization,
  IOrganizationDTO,
  organizationDTOToOrganizationMapper,
} from "../shared/types/organization.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";

import { HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE } from "./APIUtils";
import { authRequestJSON } from "./fetch";

export async function fetchOrganizations(): Promise<IOrganization[]> {
  const query = new URLSearchParams({
    page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
  });
  return (
    await authRequestJSON<IPaginatedQueryResults<IOrganizationDTO>>(
      `organization/?${query}`
    )
  )?.results.map(organizationDTOToOrganizationMapper);
}
