import React from "react";

import styles from "../_exports.module.scss";
import { ifTest } from "../utils";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function StarIcon({
  fill = styles.gray400,
  stroke = "",
  className = "",
  size = 24,
  ...props
}) {
  return (
    <svg
      data-test={ifTest("star-icon")}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize((size * 24) / 25)}
      viewBox="0 0 25 24"
      className={className}
      {...props}
    >
      <path
        fill={fill}
        stroke={stroke}
        d="m12.096 17.27 6.18 3.73-1.64-7.03 5.46-4.73-7.19-.61L12.096 2l-2.81 6.63-7.19.61 5.46 4.73L5.916 21l6.18-3.73Z"
      />
    </svg>
  );
}
