import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { formatFileSize } from "../../i18n";
import { FileIcon, PenIcon, TrashIcon } from "../../icons";
import { cancelEvent } from "../../utils";

export interface IFileCardProps {
  file: File;
  canEdit?: boolean;
  canDelete?: boolean;
  iconColSpan?: number;
  nameColSpan?: number;
  onDelete?: (file: File) => void;
  onEdit?: (file: File) => void;
}

export function FileCard({
  file,
  canEdit,
  canDelete = true,
  iconColSpan = 1,
  nameColSpan = 7,
  onDelete,
  onEdit,
}: IFileCardProps) {
  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center" xs={iconColSpan}>
          <FileIcon fileName={file.name} />
        </Col>
        <Col xs={nameColSpan + Number(canDelete) + Number(canEdit)}>
          {file.name}
          <br />
          {formatFileSize(file.size)}
        </Col>
        {canEdit && (
          <Col xs={1} className="d-flex justify-content-center px-0">
            <Button
              variant="text"
              className="px-0"
              onClick={() => {
                onEdit?.(file);
              }}
            >
              <PenIcon />
            </Button>
          </Col>
        )}
        {canDelete && (
          <Col xs={1} className="d-flex justify-content-center px-0">
            <Button
              variant="text"
              className="px-0"
              onClick={(e) => {
                // stop the event from going to the dropzone and trigger the file Dialog
                cancelEvent(e);
                onDelete?.(file);
              }}
            >
              <TrashIcon />
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
}
