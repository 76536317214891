import classnames from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Spinner from "react-bootstrap/Spinner";
import { CSSTransition } from "react-transition-group";

import { useTranslation } from "../../i18n";
import { useHasChanged } from "../../useHasChanged";
import { ifTest } from "../../utils";

import { Control } from "./Control";
import { Menu } from "./Menu";
import "./Select.scss";
import {
  ACTION_TYPES_ENUM,
  IOption,
  TOptions,
  TSelectProps,
  TValue,
  getLabelAsString,
  isOptionGroups,
} from "./select.types";
import { TUseSelectConfig, useSelect } from "./useSelect";

/**
 *
 * @param options list of options/optionsGroup to filter from
 * @param searchedValue string to match with option's value or label
 * @returns options list filtered with all matching elements or groups
 */
function filteredBySearchValueOptions(
  options: TOptions,
  searchedValue: string
): TOptions {
  const lowerCasedSearchedValue = searchedValue.toLowerCase();

  const isMatchingSearchedValue = (value?: string | number) =>
    Boolean(value?.toString().toLowerCase().includes(lowerCasedSearchedValue));

  const isOptionMatching = ({ label, value }: IOption): boolean =>
    // searched value has to match option's value or label
    isMatchingSearchedValue(value) ||
    isMatchingSearchedValue(getLabelAsString(label ?? ""));

  if (isOptionGroups(options)) {
    return options
      .map(({ label, options }) => ({
        label,
        // if optionGroup label matches return all of its options without filtering
        options: isMatchingSearchedValue(getLabelAsString(label))
          ? options
          : options?.filter(isOptionMatching),
      }))
      .filter((group) => group?.options?.length);
  } else {
    return options?.filter(isOptionMatching);
  }
}

/**
 * filter out all selected options from option list
 * @param options list of options/optionsGroup to filter from
 * @param selection list of selected options
 * @returns a new option list without selected options
 */
function filterOutSelectedOptions(
  options: TOptions | undefined,
  selection: IOption[]
) {
  const isOptionNotSelected = ({ value }: IOption) =>
    !selection.some((o) => o.value === value);
  return isOptionGroups(options)
    ? options
        .map((optionGroup) => ({
          ...optionGroup,
          options: optionGroup.options.filter(isOptionNotSelected),
        }))
        .filter((optionGroup) => optionGroup.options.length)
    : options?.filter(isOptionNotSelected) ?? [];
}

function getOptionFromValue(
  options: TOptions,
  value: TValue
): IOption | undefined {
  return (
    isOptionGroups(options)
      ? options.flatMap(({ options }) => options)
      : options
  ).find((option) => option.value === value);
}

/**
 * Rich Select Component that allows a user to select one or multiple options from a list or asynchronously loaded
 *
 * @component
 * @example
 * const onChange = (value, option) => alert(`You selected ${value} with label ${option?.label}`);
 * const options = [{value:true, label:"Yes"}, {value:false, label:"No"}]
 * return (
 *   <Select options={options} onChange={onChange} />
 * )
 * @param {Props} props
 *
 */

export const Select = React.forwardRef<HTMLInputElement, TSelectProps>(
  function Select(
    {
      onChange = () => {},
      onBlur,
      options,
      loadOptions,
      defaultValue,
      value,
      placeholder,
      formatOptionLabel,
      formatControlOption,
      formatControlOptions,
      prefixIcon,
      multi = false,
      className,
      autoFocus = false,
      clearable = false,
      searchable = false,
      searchFn = filteredBySearchValueOptions,
      iconColor,
      disabled = undefined,
      hideValueOnSearch = false,
      showSelectionInMenu = false,
      searchValueAsOption = false,
      formatSearchValueAsOption,
      closeMenuOnSelect = !multi,
      defaultOptions,
      charMin = 3,
      isInvalid = false,
      horizontal = false,
      compact = false,
      updateOnOptionsChange = false,
      onFocusOption,
    }: TSelectProps,
    ref
  ) {
    const { t } = useTranslation("Select");

    const [loading, setLoading] = useState(false);

    const [searchedValue, setSearchedValue] = useState("");

    const selectableOptions = useMemo(() => {
      if (isOptionGroups(options)) {
        return options.flatMap((option) => option.options);
      } else {
        return options;
      }
    }, [options]);

    const menuRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLSelectElement>(null);

    // if a fieldset parent is disabled, disable select
    disabled =
      disabled ?? selectRef.current?.closest("fieldset[disabled]") !== null;

    // asynchronously load Options objects associated to a list of values
    const getOptionsFromValuesAsync = useCallback(
      async (values: TValue[]) =>
        new Promise<IOption[]>((resolve, reject) => {
          if (options) {
            resolve(
              (values !== undefined && values !== null
                ? values
                    .map((value) => getOptionFromValue(options, value))
                    .filter((option) => option !== undefined)
                : []) as IOption[]
            );
          } else if (loadOptions) {
            if (values) {
              setLoading(true);
              Promise.all(
                values?.map((value) =>
                  loadOptions(value).then((options) => (options || []).shift())
                )
              ).then((selected) => {
                setLoading(false);
                resolve(selected.filter((option) => option) as IOption[]);
              });
            } else {
              resolve([]);
            }
          } else {
            reject(
              "no options prop were given, can't get Option without either"
            );
          }
        }),
      [loadOptions, options]
    );
    const getDefaultOptionsOrFromValuesAsync = useCallback(
      async (values: TValue[]) => {
        if (!defaultOptions || searchedValue) {
          return getOptionsFromValuesAsync(values);
        } else {
          return defaultOptions;
        }
      },
      [searchedValue, getOptionsFromValuesAsync, defaultOptions]
    );
    const [selection, selectionDispatch] = useSelect({
      onChange,
      getOptionsFromValuesAsync: getDefaultOptionsOrFromValuesAsync,
      multi,
      value,
      defaultValue,
    } as TUseSelectConfig);

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const clearValues = useCallback(() => {
      selectionDispatch({ type: ACTION_TYPES_ENUM.CLEAR });
      setSearchedValue("");
      setFilteredOptions([]);
    }, [selectionDispatch]);

    const [filteredOptions, setFilteredOptions] = useState<TOptions>([]);

    const handleSelect = useCallback(
      (option: IOption) => {
        selectionDispatch({ type: ACTION_TYPES_ENUM.SELECT, option });
        setSearchedValue("");
        loadOptions && setFilteredOptions([]);
        closeMenuOnSelect && setMenuIsOpen(false);
      },
      [closeMenuOnSelect, loadOptions, selectionDispatch]
    );
    const handleDeselect = useCallback(
      (option: IOption) => {
        selectionDispatch({ type: ACTION_TYPES_ENUM.DESELECT, option });
      },
      [selectionDispatch]
    );

    const searchMessage = useMemo(
      () =>
        loading ? (
          <Spinner className="ms-2" size="sm" />
        ) : loadOptions && searchedValue.length < charMin ? (
          t("CharMin", { charMin })
        ) : (
          t("noResult")
        ),
      [loading, loadOptions, searchedValue, t, charMin]
    );

    const searchedValueHasChanged = useHasChanged(searchedValue);
    const selectionHasChanged = useHasChanged(...selection);
    const menuIsOpenHasChanged = useHasChanged(menuIsOpen);
    const optionsHasChanged =
      useHasChanged(...(options ?? [])) && updateOnOptionsChange;
    // update filtered options on Sync Select
    if (
      searchedValueHasChanged ||
      selectionHasChanged ||
      (menuIsOpen && menuIsOpenHasChanged) ||
      optionsHasChanged
    ) {
      if (loadOptions && searchedValue.length >= charMin) {
        // Async method requested to search results
        setLoading(true);
        loadOptions(searchedValue).then((options) => {
          setLoading(false);
          setFilteredOptions(
            showSelectionInMenu
              ? filterOutSelectedOptions(options, selection)
              : options
          );
        });
      } else if (options) {
        // Sync filtering
        const optionsToFilterFrom = showSelectionInMenu
          ? filterOutSelectedOptions(options, selection)
          : options;

        // only filter options, when search value is set
        if (optionsToFilterFrom) {
          const filteredOptions = searchFn(optionsToFilterFrom, searchedValue);

          // add searched value as selectable option if feature is activated but searchValue isn't already present in filtered options
          if (
            searchValueAsOption &&
            searchedValue &&
            !getOptionFromValue(filteredOptions, searchedValue)
          ) {
            const searchValueOption: IOption = {
              value: searchedValue,
              label: formatSearchValueAsOption
                ? formatSearchValueAsOption(searchedValue)
                : searchedValue,
            };
            if (isOptionGroups(filteredOptions)) {
              filteredOptions.unshift({
                label: "",
                options: [searchValueOption],
              });
            } else {
              filteredOptions.unshift(searchValueOption);
            }
          }
          setFilteredOptions(filteredOptions);

          if (updateOnOptionsChange) {
            selectionDispatch({ type: ACTION_TYPES_ENUM.CLEAR });
            selection.forEach((selectedOption) => {
              const option = (
                isOptionGroups(options)
                  ? options.flatMap((o) => o.options)
                  : options
              ).find((o) => o.value === selectedOption.value);
              if (option) {
                selectionDispatch({
                  type: ACTION_TYPES_ENUM.SELECT,
                  option,
                });
              }
              // add back selected search option
              else if (!selectedOption.meta) {
                selectionDispatch({
                  type: ACTION_TYPES_ENUM.SELECT,
                  option: selectedOption,
                });
              }
            });
          }
        }
      }
    }

    useEffect(() => {
      const handleKeyDown = ({ key }: KeyboardEvent) => {
        key === "Escape" && setMenuIsOpen(false);
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    });

    // dispatch change event to trigger form handlers
    useEffect(() => {
      if (selectRef.current) {
        selectRef.current.dispatchEvent(new Event("change", { bubbles: true }));
      }
    }, [selection]);

    const focusCallback = useCallback(
      (v?: TValue) => {
        setMenuIsOpen((menuIsOpen) => {
          if (menuIsOpen) {
            onFocusOption?.(v);
          }
          return menuIsOpen;
        });
      },
      [onFocusOption]
    );

    return (
      <div
        className={classnames(
          "select",
          "position-relative",
          compact && "compact",
          className,
          {
            disabled,
          }
        )}
        data-test={ifTest("select")}
        aria-expanded={menuIsOpen}
        aria-disabled={disabled}
        aria-invalid={isInvalid}
        onBlur={() => {
          setMenuIsOpen(false);
        }}
      >
        {/* select to dispatch change event */}
        <select ref={selectRef} className="d-none" />
        <Control
          ref={ref}
          onClear={clearValues}
          placeholder={placeholder}
          selection={selection}
          searchedValue={searchedValue}
          onInput={setSearchedValue}
          onBlur={onBlur}
          showMenu={menuIsOpen}
          setShowMenu={setMenuIsOpen}
          formatControlOption={formatControlOption}
          formatControlOptions={formatControlOptions}
          prefixIcon={prefixIcon}
          onDeselect={handleDeselect}
          multi={multi}
          autoFocus={autoFocus}
          clearable={clearable}
          searchable={searchable || Boolean(loadOptions)}
          iconColor={iconColor}
          disabled={disabled}
          hideValueOnSearch={hideValueOnSearch}
          isInvalid={isInvalid}
          compact={compact}
        />
        <CSSTransition
          in={menuIsOpen}
          timeout={300}
          classNames="select-menu-fade"
          unmountOnExit
          mountOnEnter
          nodeRef={menuRef}
        >
          <Menu
            ref={menuRef}
            options={filteredOptions}
            selectableOptions={selectableOptions}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            formatOptionLabel={formatOptionLabel}
            searchMessage={searchMessage}
            multi={multi}
            selection={selection}
            showSelectionInMenu={showSelectionInMenu}
            onFocus={() => setMenuIsOpen(true)}
            horizontal={horizontal}
            onFocusOption={focusCallback}
          />
        </CSSTransition>
      </div>
    );
  }
);
