import { CellContext } from "@tanstack/react-table";
import Button from "react-bootstrap/Button";

import { TriangleDirections, TriangleIcon } from "../icons";
import { cancelEvent } from "../utils";

export function ExpandCell({
  cellContext,
  fill,
}: {
  cellContext: CellContext<any, any>;
  fill?: string;
}) {
  return (
    <>
      {cellContext.row.getCanExpand() && (
        <Button
          variant="text"
          className="p-0 position-absolute top-50 top-50 translate-middle"
          onClick={(event) => {
            cancelEvent(event);
            cellContext.row.toggleExpanded();
          }}
        >
          <TriangleIcon
            size={32}
            direction={
              cellContext.row.getIsExpanded()
                ? TriangleDirections.DOWN
                : TriangleDirections.RIGHT
            }
            fill={fill}
          />
        </Button>
      )}
    </>
  );
}
