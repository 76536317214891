import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";
import {
  IRowType,
  IRowTypeDTO,
  ROWTYPE_USAGE_ENUM,
  RowTypeDTOToRowTypeMapper,
  RowTypeToRowTypeDTOMapper,
} from "../shared/types/rowType.types";
import { ITenderDTO } from "../shared/types/tender.types";

import { HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE } from "./APIUtils";
import { FETCH_METHODS_ENUM, authRequestJSON } from "./fetch";

export interface IFetchTendersResult {
  results: ITenderDTO[];
}

export async function fetchRowTypes(
  allotment_type?: string,
  usage?: ROWTYPE_USAGE_ENUM,
  admin?: boolean
) {
  const query = `row-type/?${new URLSearchParams({
    page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
    ...(allotment_type && { allotment_type: allotment_type }),
    ...(usage && { usage: usage }),
    ...(admin && { admin: "true" }),
  })}`;
  const result = await authRequestJSON<IPaginatedQueryResults<IRowTypeDTO>>(
    query
  );

  return result.results.map(RowTypeDTOToRowTypeMapper);
}

export async function createRowType(rowType: IRowType) {
  const query = `row-type/`;
  const result = await authRequestJSON<IRowTypeDTO>(query, {
    method: FETCH_METHODS_ENUM.POST,
    body: JSON.stringify(RowTypeToRowTypeDTOMapper(rowType)),
  });

  return RowTypeDTOToRowTypeMapper(result);
}

export async function updateRowType(
  rowType: Partial<IRowType> & { id: string }
) {
  const query = `row-type/${rowType.id}/`;
  const result = await authRequestJSON<IRowType>(query, {
    method: FETCH_METHODS_ENUM.PATCH,
    body: JSON.stringify(RowTypeToRowTypeDTOMapper(rowType)),
  });

  return result;
}
