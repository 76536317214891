import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Accept } from "react-dropzone";

import { useTranslation } from "../../i18n";
import { ProgressCircleIcon } from "../../icons";
import { usePrevious } from "../../usePrevious";

import { FileCard } from "./FileCard";
import { ImportFiles } from "./ImportFiles";
import styles from "./ImportFilesArea.module.scss";

export interface IImportFilesAreaProps {
  multiple?: boolean;
  disabled?: boolean;
  onData: Function;
  className?: string;
  initialData?: File[];
  buttonText: string;
  areaText: string;
  enableFileEdit?: boolean;
  accept?: Accept;
  percentage?: number;
}

export function ImportFilesArea({
  multiple = false,
  disabled = false,
  onData,
  className,
  initialData,
  buttonText,
  areaText,
  enableFileEdit,
  accept,
  percentage,
}: IImportFilesAreaProps) {
  const { t } = useTranslation("ImportFilesArea");
  const [files, setFiles] = useState<File[] | undefined>(initialData || []);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [unsupportedFileTypeMessage, setUnsupportedFileTypeMessage] =
    useState<boolean>(false);

  const prevFiles = usePrevious(files);

  const removeFile = (file: File) => {
    setFiles((files) => files?.filter((f) => f !== file));
  };

  useEffect(() => {
    files !== initialData && files !== prevFiles && onData(files);
  }, [initialData, onData, files, prevFiles]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles?.length) {
        setFiles((files) =>
          multiple ? [...(files || []), ...acceptedFiles] : [acceptedFiles[0]]
        );
        setUnsupportedFileTypeMessage(false);
      } else {
        setUnsupportedFileTypeMessage(true);
      }
    },
    [multiple, setFiles]
  );

  return (
    <>
      <ImportFiles
        disabled={disabled}
        multiple={multiple}
        accept={accept}
        onDrop={onDrop}
        onDrag={setDragActive}
      >
        <div className={className}>
          <Alert
            variant="info"
            className={classNames(
              "text-center d-flex align-items-center",
              styles.dashed,
              styles.height,
              {
                [styles.animatedBorder]: dragActive,
              }
            )}
          >
            <Container>
              {percentage !== undefined ? (
                <>
                  <Row className="justify-content-center">
                    <Col>
                      <ProgressCircleIcon percentage={percentage} size={72} />
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-4">
                    <Col>
                      {t("downloadingFiles")} {Math.floor(percentage)}%
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="justify-content-center">
                    <Col>
                      <Button
                        variant="dark"
                        className={classNames(styles.dark, "rounded w-auto")}
                      >
                        {buttonText}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{areaText}</Col>
                  </Row>
                  <Row>
                    <Col>
                      {t("Supported File Formats", {
                        formats: Object.values(accept || {})
                          .flat()
                          .join(", "),
                      })}
                    </Col>
                  </Row>
                </>
              )}
              {unsupportedFileTypeMessage && (
                <div className="text-danger pt-3">
                  {t(`Unsupported File Type`)}
                </div>
              )}
            </Container>
          </Alert>
        </div>
      </ImportFiles>

      <Col>
        {files?.map((file, index) => (
          <FileCard
            file={file}
            key={file.name + index}
            onDelete={removeFile}
            canEdit={enableFileEdit}
          />
        ))}
      </Col>
    </>
  );
}
