import styles from "../_exports.module.scss";
import { TriangleDirections, TriangleIcon } from "../icons";

export enum TREND_ENUM {
  UP = "UP",
  DOWN = "DOWN",
}

export interface ITriangleTrend {
  direction: TREND_ENUM;
}

export function TriangleTrend({ direction }: ITriangleTrend) {
  const down = direction === TREND_ENUM.DOWN;
  return (
    <TriangleIcon
      fill={down ? styles.green1000 : styles.red600}
      direction={down ? TriangleDirections.DOWN : TriangleDirections.UP}
    />
  );
}
