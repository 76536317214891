export enum GEO_FEATURE_TYPES_ENUM {
  HOUSE = "house",
  STREET = "street",
  LOCALITY = "locality",
  DISTRICT = "district",
  CITY = "city",
  COUNTY = "county",
  STATE = "state",
  COUNTRY = "country",
}

/** see https://geojson.org/ and https://github.com/komoot/photon#results-as-geojson */
export interface IGeoFeature {
  geometry: IGeometry;
  type: "Feature";
  properties: TGeoFeatureProperties;
}

export type TGeoFeatureProperties =
  | IGeoFeatureCountryProperties
  | IGeoFeatureStateProperties
  | IGeoFeatureCountyProperties
  | IGeoFeatureCityProperties
  | IGeoFeatureDistrictProperties
  | IGeoFeatureLocalityProperties
  | IGeoFeatureStreetProperties
  | IGeoFeatureHouseProperties;

export interface IGeometry {
  coordinates: [number, number];
  type: string;
}

export interface IGeoFeatureCountryProperties {
  osm_id: number;
  name?: string;
  countrycode: string;
  country: string;
  extent?: [number, number, number, number];
  type: GEO_FEATURE_TYPES_ENUM;
}
export interface IGeoFeatureStateProperties
  extends IGeoFeatureCountryProperties {}

export interface IGeoFeatureCountyProperties
  extends IGeoFeatureStateProperties {
  state?: string;
  postcode?: string;
}

export interface IGeoFeatureCityProperties extends IGeoFeatureCountyProperties {
  county?: string;
}

export interface IGeoFeatureDistrictProperties
  extends IGeoFeatureCityProperties {
  city: string;
}

/** a locality can be as precise as having a city or just a country */
export interface IGeoFeatureLocalityProperties
  extends IGeoFeatureCityProperties {
  city?: string;
}

export interface IGeoFeatureStreetProperties
  extends IGeoFeatureDistrictProperties {
  district?: string;
}

export interface IGeoFeatureHouseProperties
  extends IGeoFeatureStreetProperties {
  housenumber: string;
  street: string;
}

// GeoFeature Typeguards
export function isCountry(
  geoFeatureProperties: TGeoFeatureProperties
): geoFeatureProperties is IGeoFeatureCountryProperties {
  return geoFeatureProperties.type === GEO_FEATURE_TYPES_ENUM.COUNTRY;
}
export function isState(
  geoFeatureProperties: TGeoFeatureProperties
): geoFeatureProperties is IGeoFeatureStateProperties {
  return geoFeatureProperties.type === GEO_FEATURE_TYPES_ENUM.STATE;
}
export function isCounty(
  geoFeatureProperties: TGeoFeatureProperties
): geoFeatureProperties is IGeoFeatureCountyProperties {
  return geoFeatureProperties.type === GEO_FEATURE_TYPES_ENUM.COUNTY;
}
export function isCity(
  geoFeatureProperties: TGeoFeatureProperties
): geoFeatureProperties is IGeoFeatureCityProperties {
  return geoFeatureProperties.type === GEO_FEATURE_TYPES_ENUM.CITY;
}
export function isDistrict(
  geoFeatureProperties: TGeoFeatureProperties
): geoFeatureProperties is IGeoFeatureDistrictProperties {
  return geoFeatureProperties.type === GEO_FEATURE_TYPES_ENUM.DISTRICT;
}
export function isLocality(
  geoFeatureProperties: TGeoFeatureProperties
): geoFeatureProperties is IGeoFeatureLocalityProperties {
  return geoFeatureProperties.type === GEO_FEATURE_TYPES_ENUM.LOCALITY;
}
export function isStreet(
  geoFeatureProperties: TGeoFeatureProperties
): geoFeatureProperties is IGeoFeatureStreetProperties {
  return geoFeatureProperties.type === GEO_FEATURE_TYPES_ENUM.STREET;
}
export function isHouse(
  geoFeatureProperties: TGeoFeatureProperties
): geoFeatureProperties is IGeoFeatureHouseProperties {
  return geoFeatureProperties.type === GEO_FEATURE_TYPES_ENUM.HOUSE;
}

export interface ILocation {
  location_osm_id?: number;
  location_name?: string;
  location_type: GEO_FEATURE_TYPES_ENUM;
  location_housenumber?: string;
  location_street?: string;
  location_postcode?: string;
  location_city?: string;
  location_district?: string;
  location_state?: string;
  location_country: string;
  location_country_code: string;
  location_geometry?: IGeometry;
}

export interface IPartialLocation {
  location_country: string;
  location_name?: string;
  location_postcode?: string;
  location_city?: string;
}

/** remove empty fields with null on PATCH */
export interface ILocationDTO {
  location_osm_id?: number;
  location_name: string;
  location_type: GEO_FEATURE_TYPES_ENUM;
  location_housenumber: string | null;
  location_street: string | null;
  location_postcode: string | null;
  location_city: string | null;
  location_district: string | null;
  location_state: string | null;
  location_country: string;
  location_country_code: string;
  location_geometry?: IGeometry;
}

export function locationToLocationDTOMapper(location: ILocation): ILocationDTO {
  const {
    location_osm_id,
    location_name,
    location_type,
    location_country,
    location_country_code,
    location_geometry,
    ...locationNullableFields
  } = location;
  return {
    location_osm_id,
    location_name,
    location_type,
    location_country,
    location_country_code,
    location_geometry,
    ...Object.entries(locationNullableFields).reduce(
      (fields, [key, value]) => ({ ...fields, [key]: value ?? null }),
      {}
    ),
  } as ILocationDTO;
}

export function locationDTOToLocationMapper(location: ILocationDTO): ILocation {
  const {
    location_osm_id,
    location_name,
    location_type,
    location_country,
    location_country_code,
    location_geometry,
    ...locationNullableFields
  } = location;
  return {
    location_osm_id,
    location_name,
    location_type,
    location_country,
    location_country_code,
    location_geometry,
    ...Object.entries(locationNullableFields).reduce(
      (fields, [key, value]) => ({ ...fields, [key]: value ?? undefined }),
      {}
    ),
  } as ILocation;
}

export const geoFeatureToLocationMapper = (feature: IGeoFeature): ILocation => {
  const geoFeatureProperties = feature.properties;
  const location: ILocation = {
    location_osm_id: geoFeatureProperties.osm_id,
    location_name: geoFeatureProperties.name,
    location_country: geoFeatureProperties.country,
    location_country_code: geoFeatureProperties.countrycode,
    location_geometry: feature.geometry,
    location_type: geoFeatureProperties.type,
  };
  if (
    isCounty(geoFeatureProperties) ||
    isCity(geoFeatureProperties) ||
    isDistrict(geoFeatureProperties) ||
    isLocality(geoFeatureProperties) ||
    isStreet(geoFeatureProperties) ||
    isHouse(geoFeatureProperties)
  ) {
    location.location_postcode = geoFeatureProperties.postcode;
    location.location_state = geoFeatureProperties.state;
  }
  if (
    isDistrict(geoFeatureProperties) ||
    isLocality(geoFeatureProperties) ||
    isStreet(geoFeatureProperties) ||
    isHouse(geoFeatureProperties)
  ) {
    location.location_city = geoFeatureProperties.city;
  }
  if (isStreet(geoFeatureProperties) || isHouse(geoFeatureProperties)) {
    location.location_district = geoFeatureProperties.district;
  }
  if (isHouse(geoFeatureProperties)) {
    location.location_housenumber = geoFeatureProperties.housenumber;
    location.location_street = geoFeatureProperties.street;
  }
  return location;
};

export const locationToGeoFeatureMapper = (
  location: ILocation
): { properties: TGeoFeatureProperties; geometry: IGeometry } => ({
  properties: {
    osm_id: location.location_osm_id!,
    name: location.location_name,
    housenumber: location.location_housenumber,
    street: location.location_street,
    postcode: location.location_postcode,
    city: location.location_city,
    district: location.location_district,
    state: location.location_state,
    country: location.location_country,
    countrycode: location.location_country_code,
    type: location.location_type,
  },
  geometry: location.location_geometry!,
});
