import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";

import { GuardedButton } from "../account/GuardedButton";
import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { useModal } from "../shared/DefaultModal";
import { useTranslation } from "../shared/i18n";
import { AddCircledIcon } from "../shared/icons";
import { ConfirmationModal } from "../shared/modals";
import { useCurrentLot, useTendersQuery } from "../shared/useSharedQueries";
import { ifTest } from "../shared/utils";

import { TenderCreationModal } from "./TenderCreationModal";
import { TendersTable } from "./TendersTable";

export function TendersPage() {
  const { trackPageView } = useMatomo();
  const { operationId, lotId } = useParams();

  useEffect(() => {
    trackPageView({
      documentTitle: "Tenders",
      customDimensions: [
        {
          id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
          value: String(operationId),
        },
        {
          id: CUSTOM_DIMENSIONS_ENUM.LOT_ID,
          value: String(lotId),
        },
      ],
    });
  }, [trackPageView, operationId, lotId]);
  const { t } = useTranslation("TendersPage");

  const { show, handleClose, handleShow } = useModal("TenderCreationModal");

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const lot = useCurrentLot();

  const tendersQuery = useTendersQuery(lotId);
  const companies = tendersQuery.data;
  const lowestEstimation =
    lot?.min_price_breakdowns?.find(Boolean)?.estimation_amount;

  return (
    <Container fluid className="pb-4 p-0">
      <Card className="mb-5 w-auto border-0 px-0 mx-md-4">
        <Card.Body className="px-3 p-md-4">
          {show && (
            <TenderCreationModal
              show
              handleClose={handleClose}
              lot={lotId!}
              operationId={operationId!}
            />
          )}
          {tendersQuery.isInitialLoading ? (
            <Row>
              <Col className="text-center">
                <Spinner />
              </Col>
            </Row>
          ) : companies?.length ? (
            <>
              <Row className="mb-4">
                <Col>
                  <h4>{t("analysis of tenders")}</h4>
                </Col>
                <Col className="text-end">
                  <GuardedButton
                    size="lg"
                    onClick={handleShow}
                    data-test={ifTest("openTenderCreationModal")}
                  >
                    <AddCircledIcon className="mb-1 me-1" /> {t("addOne")}
                  </GuardedButton>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TendersTable
                    companies={companies}
                    estimation={lot?.estimation_amount}
                    lowestEstimation={lowestEstimation}
                    isLoading={tendersQuery.isFetching}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Row className="align-content-center">
              <Col className="text-center mt-5">
                {tendersQuery.isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <div>{t("add tender")}</div>
                    <ConfirmationModal
                      title={t("confirm creation title ?")}
                      confirm={t("confirm creation")}
                      show={showConfirmationModal}
                      handleClose={(confirm) => {
                        if (confirm) {
                          handleShow();
                        }
                        setShowConfirmationModal(false);
                      }}
                    />
                    <GuardedButton
                      size="lg"
                      id={ifTest("openTenderCreationModal")}
                      onClick={() => setShowConfirmationModal(true)}
                      className="mt-4"
                    >
                      <AddCircledIcon className="mb-1 me-1" /> {t("addOne")}
                    </GuardedButton>
                  </>
                )}
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
