import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function WordIcon({
  className = "",
  fill = styles.blueword,
  size = 24,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M41.084 7.25h9.667a2.417 2.417 0 0 1 2.417 2.417v38.666a2.417 2.417 0 0 1-2.417 2.417h-9.667V7.25ZM6.91 6.953l30.378-4.338a1.208 1.208 0 0 1 1.38 1.196v50.378a1.207 1.207 0 0 1-1.378 1.196L6.908 51.047a2.416 2.416 0 0 1-2.076-2.392V9.345a2.417 2.417 0 0 1 2.076-2.392h.002Zm19.674 12.38V31.39l-4.833-4.807-4.81 4.834-.023-12.084h-4.834v19.334h4.834l4.833-4.834 4.833 4.834h4.834V19.333h-4.834Z"
        fill={fill}
      />
    </svg>
  );
}
