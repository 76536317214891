import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function GenericFileIcon({
  className = "",
  fill = styles.blue200,
  size = 24,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-8 0 56 64"
      className={className}
    >
      <path
        d="M8.954 29.783c-.528 0-1.034.234-1.407.65-.373.415-.583.98-.583 1.567v18.829c0 .588.21 1.152.583 1.568.373.415.88.649 1.407.649h30.092c.528 0 1.034-.234 1.407-.65.373-.415.583-.98.583-1.567v-18.83c0-.587-.21-1.151-.583-1.567a1.893 1.893 0 0 0-1.407-.649H8.954Zm13.056 4.434v4.981H10.944v-4.981H22.01Zm-11.066 9.415H22.01v4.981H10.944v-4.981Zm15.046 4.98v-4.98h11.066v4.981H25.99Zm11.066-9.414H25.99v-4.981h11.066v4.98Zm10.218-23.404L32.906.654a.266.266 0 0 0-.06-.055 2.183 2.183 0 0 0-.229-.211 1.784 1.784 0 0 0-.716-.343h-.02A1.48 1.48 0 0 0 31.503 0H7.96C5.85.004 3.827.94 2.335 2.6.843 4.264.003 6.518 0 8.868v46.266c.003 2.35.843 4.604 2.335 6.266S5.85 63.997 7.96 64h32.08c2.11-.004 4.133-.939 5.625-2.6 1.492-1.663 2.332-3.917 2.335-6.267v-37.63c0-.662-.266-1.29-.726-1.707v-.002ZM44.02 55.132c0 1.176-.42 2.303-1.166 3.135-.746.831-1.758 1.299-2.814 1.299H7.96c-1.055 0-2.067-.468-2.814-1.3-.746-.83-1.166-1.958-1.166-3.134V8.867c0-1.176.42-2.304 1.166-3.135.747-.832 1.76-1.299 2.815-1.299h21.552v13.068c.002.587.212 1.15.585 1.565a1.9 1.9 0 0 0 1.405.652H44.02v35.414Z"
        fill={fill}
      />
    </svg>
  );
}
