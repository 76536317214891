// eslint-disable-next-line no-restricted-imports
import { format } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import i18next from "i18next";

import { SUPPORTED_LANGUAGES_ENUM } from "../../host/i18n";
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from "../utils";

const locales = {
  [SUPPORTED_LANGUAGES_ENUM.FR]: fr,
  [SUPPORTED_LANGUAGES_ENUM.EN]: enUS,
  [SUPPORTED_LANGUAGES_ENUM.DEBUG]: fr,
};

export function formatTolerant(
  date: number | Date,
  formatString: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
) {
  if (date === undefined || date === null) {
    return DEFAULT_EMPTY_VALUE_PLACEHOLDER;
  }
  try {
    return format(date, formatString, {
      ...options,
      locale: locales[i18next.language as SUPPORTED_LANGUAGES_ENUM],
    });
  } catch (error) {
    console.error(error);
    return DEFAULT_EMPTY_VALUE_PLACEHOLDER;
  }
}

export function formatDateDDMMYYYY(date: Date, separator = "-") {
  return formatTolerant(date, `dd${separator}MM${separator}yyyy`);
}
