import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { OperationEstimationPieChart } from "./OperationEstimationPieChart";
import { OperationGeneralViewCharts } from "./OperationGeneralViewCharts";
import { OperationPersonalizedViewCharts } from "./OperationPersonalizedViewCharts";

export function OperationChartsPage() {
  return (
    <Container fluid>
      <Row className="mb-3">
        <Col>
          <OperationGeneralViewCharts />
        </Col>
      </Row>
      <Row>
        <Col xl={7} className="pe-0">
          <OperationPersonalizedViewCharts />
        </Col>
        <Col xl={5} className="ps-0">
          <OperationEstimationPieChart />
        </Col>
      </Row>
    </Container>
  );
}
