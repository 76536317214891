import React from "react";
import { UseTranslationOptions } from "react-i18next";

import { useEnumTranslation } from "./i18n";
import { ILot } from "./types/lot.types";

export function lotWithConventionalAllotmentsToString(
  lot: ILot,
  tEnum: (
    key: string,
    options?: UseTranslationOptions<undefined> | undefined
  ) => string
) {
  return `${lot.conventional_allotments
    ?.map((item) => tEnum(item))
    .join("/")} ${Boolean(lot.name) ? ` (${lot.name})` : ""}`;
}

export function LotWithConventionalAllotments({ lot }: { lot: ILot }) {
  const { tEnum } = useEnumTranslation();

  return <>{lotWithConventionalAllotmentsToString(lot, tEnum)}</>;
}
