import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function DotsIcon({ fill = styles?.blue, className = "", size = 24 }) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.864 3c-1.111 0-2.02.9-2.02 2s.909 2 2.02 2c1.11 0 2.02-.9 2.02-2s-.91-2-2.02-2Zm0 14c-1.111 0-2.02.9-2.02 2s.909 2 2.02 2c1.11 0 2.02-.9 2.02-2s-.91-2-2.02-2Zm0-7c-1.111 0-2.02.9-2.02 2s.909 2 2.02 2c1.11 0 2.02-.9 2.02-2s-.91-2-2.02-2Z"
        fill={fill}
      />
    </svg>
  );
}
