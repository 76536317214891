import React from "react";
import Button from "react-bootstrap/Button";

import { useTranslation } from "./i18n";
import { CloseCircledIcon } from "./icons";
import { ifTest } from "./utils";

export function ModalCancelButton({ onCancel }: { onCancel: () => void }) {
  const { t } = useTranslation("ModalCancelButton");
  return (
    <Button
      variant="link"
      className="text-black position-absolute top-0 start-0 mt-2 modal-cancel"
      onClick={onCancel}
      data-test={ifTest("modal-cancel")}
    >
      <CloseCircledIcon className="me-3" />
      <span className="text-decoration-underline">{t("cancel")}</span>
    </Button>
  );
}
