import {
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

/**
 * Hook to help make Overlays hoverable
 *
 * - Set the ref={target} on the trigger
 * - Set show={show} on the OverlayTrigger
 * - Spread the {...eventHandlers} on the trigger and the OverlayTrigger
 */
export function useHoverableTooltip<T>() {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const onMouseEnter: MouseEventHandler<T> = useCallback((e: MouseEvent<T>) => {
    setShow(true);
  }, []);

  const onMouseLeave: MouseEventHandler<T> = useCallback((e: MouseEvent<T>) => {
    setShow(false);
  }, []);

  const result = useMemo(
    () => ({
      show,
      target,
      eventHandlers: {
        onMouseEnter,
        onMouseLeave,
      },
    }),
    [onMouseEnter, onMouseLeave, show]
  );
  return result;
}
