import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useCallback } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";

import { deleteTender } from "../api/fetchTenders";
import { updateTender } from "../api/fetchTenders";
import { DefaultModal, useModal } from "../shared/DefaultModal";
import { useTranslation } from "../shared/i18n";
import { CheckedCircleIcon } from "../shared/icons";
import { ConfirmationModal } from "../shared/modals";
import { ITender } from "../shared/types/tender.types";
import { QUERY_KEYS_ENUM } from "../shared/useSharedQueries";

import {
  TVersionFormInputs,
  TenderAmountForm,
  schema,
} from "./TenderAmountForm";
import styles from "./TenderEditionModal.module.scss";

export interface ITenderVErsionEditionModalParameters {
  show: boolean;
  handleClose: (save: boolean | void) => void;
  tender: ITender;
  lotId: string;
}

export function TenderVersionEditionModal({
  show,
  handleClose,
  tender,
  lotId,
}: ITenderVErsionEditionModalParameters) {
  const { t } = useTranslation("TenderVersionEditionModal");
  const {
    show: showDeletion,
    handleClose: handleCloseDeletion,
    handleShow: handleShowDeletion,
  } = useModal("TenderDeletionModal");

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<TVersionFormInputs>({
    defaultValues: tender,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const queryClient = useQueryClient();

  const tenderEdition = useMutation({
    mutationFn: updateTender,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.TENDERS, lotId],
      });
      handleClose();
    },
  });

  const onSubmit = useCallback(
    ({ estimation_amount }: TVersionFormInputs) => {
      tenderEdition.mutate({
        id: tender.id,
        estimation_amount,
      });
    },
    [tenderEdition, tender.id]
  );

  const tenderDeletion = useMutation({
    mutationFn: deleteTender,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.TENDERS, lotId],
      });
      handleCloseDeletion();
      handleClose();
    },
  });

  return (
    <>
      <ConfirmationModal
        title={t("delete tender")}
        show={showDeletion}
        handleClose={(confirm: boolean | void) => {
          confirm ? tenderDeletion.mutate(tender!.id) : handleCloseDeletion();
        }}
        isSubmitting={tenderDeletion.isLoading}
      />
      <DefaultModal
        show={show}
        title={t("title", { company: tender.company_name })}
        handleClose={handleClose}
        staticBackdrop
        size="lg"
        footer={
          <>
            <Button
              size="lg"
              onClick={handleShowDeletion}
              className={classNames("rectangular ms-5", styles.gray)}
            >
              {t("delete tender")}
            </Button>
            <Button
              size="lg"
              variant="primary"
              onClick={() => onSubmit(getValues())}
              className="rectangular ms-4"
            >
              {t("save changes")}
              {tenderEdition.isLoading ? (
                <Spinner size="sm" className="ms-2" />
              ) : (
                <CheckedCircleIcon className="ms-2" />
              )}
            </Button>
          </>
        }
      >
        <div className="mx-5">
          <h5 className="mt-4 mb-4 pb-2">
            <u>{t("version", { version: tender.version })}</u>
          </h5>
          <TenderAmountForm
            handleSubmit={handleSubmit(onSubmit)}
            errors={errors}
            register={register}
          />
        </div>
      </DefaultModal>
    </>
  );
}
