import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ColumnDef } from "@tanstack/table-core";
import { useMemo, useState } from "react";
import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";

import { deleteComparison } from "../api/fetchComparison";
import colors from "../shared/_exports.module.scss";
import { useTranslation } from "../shared/i18n";
import { formatTolerant } from "../shared/i18n/dateFormatter";
import { PenIcon, TrashIcon } from "../shared/icons";
import { ConfirmationModal } from "../shared/modals";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { Table } from "../shared/table";
import { IComparisonDTO } from "../shared/types/comparison.types";
import {
  QUERY_KEYS_ENUM,
  useComparisonsQuery,
} from "../shared/useSharedQueries";
import { isDevelopmentEnv } from "../shared/utils";

import { setSearchParamsFromCompareDifferenceSettings } from "./CompareDifference/useCompareDifferenceSettings";
import { SavedComparisonEditModal } from "./SaveComparisonModal";
import { COLUMN_DISPLAY_SEARCH_PARAM } from "./useCompareColumnDisplaySettings";
import {
  ESTIMATE_SEARCH_PARAM,
  TENDERS_COMPANY_SORT_MODE_SEARCH_PARAM,
  TENDERS_SEARCH_PARAM,
} from "./useEstimateAndSortedTendersFromSearchParamsOrInit";
import { SAVED_COMPARISON_SEARCH_PARAM } from "./useSavedComparison";

const emptyData: IComparisonDTO[] = [];

export function SavedComparisonsTable() {
  const { t } = useTranslation("SavedComparisonsTable");
  const { lotId } = useParams();

  const [savedComparisonToEdit, setSavedComparisonToEdit] =
    useState<IComparisonDTO>();
  const [savedComparisonToDelete, setSavedComparisonToDelete] =
    useState<IComparisonDTO>();

  const comparisonsColumns: ColumnDef<IComparisonDTO>[] = useMemo(
    () => [
      {
        header: t("name"),
        accessorKey: "name",
      },
      {
        header: t("description"),
        accessorKey: "description",
      },
      {
        header: t("creation_date"),
        accessorKey: "creation_date",
        cell: (rowData) => {
          const formattedDate = formatTolerant(
            new Date(rowData.row.original.creation_date),
            "dd/MM/yyyy"
          );
          return (
            <>
              {formattedDate}
              <Button
                className="ms-4"
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  setSavedComparisonToEdit(rowData.row.original);
                }}
              >
                <PenIcon fill={colors.black} size={20} />
              </Button>
              <Button
                className="ms-4"
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  setSavedComparisonToDelete(rowData.row.original);
                }}
              >
                <TrashIcon size={20} />
              </Button>
            </>
          );
        },
      },
    ],
    [t]
  );

  const comparisonsQuery = useComparisonsQuery(lotId);

  const queryClient = useQueryClient();

  const comparisonDeletion = useMutation({
    mutationFn: deleteComparison,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.COMPARISONS, lotId],
      });
      setSavedComparisonToDelete(undefined);
    },
    onError: (e) => {
      console.error(e);
    },
  });

  const table = useReactTable<IComparisonDTO>({
    columns: comparisonsColumns,
    data: comparisonsQuery.data?.results ?? emptyData,
    filterFns: {},
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: isDevelopmentEnv,
  });

  const navigate = useNavigate();

  return (
    <Row>
      <SavedComparisonEditModal
        show={Boolean(savedComparisonToEdit)}
        comparison={savedComparisonToEdit}
        handleClose={() => setSavedComparisonToEdit(undefined)}
      />
      <ConfirmationModal
        title={t("delete saved comparison", {
          name: savedComparisonToDelete?.name,
        })}
        show={Boolean(savedComparisonToDelete)}
        handleClose={(confirm: boolean | void) => {
          confirm
            ? comparisonDeletion.mutate(savedComparisonToDelete!.id)
            : setSavedComparisonToDelete(undefined);
        }}
        isSubmitting={comparisonDeletion.isLoading}
      />
      <Table
        table={table}
        compact
        stickyHeaders
        responsive={false}
        onRowClick={({ original: comparison }) => {
          const {
            id,
            price_breakdowns,
            price_estimate,
            settings: { columns, difference, price_breakdowns_sort_mode },
          } = comparison;
          const params: Record<string, string> = {
            [SAVED_COMPARISON_SEARCH_PARAM]: id,
            [TENDERS_SEARCH_PARAM]: price_breakdowns.join(","),
            [COLUMN_DISPLAY_SEARCH_PARAM]: columns.join(","),
          };
          if (price_estimate) {
            params[ESTIMATE_SEARCH_PARAM] = price_estimate;
          }
          if (price_breakdowns_sort_mode) {
            params[TENDERS_COMPANY_SORT_MODE_SEARCH_PARAM] =
              price_breakdowns_sort_mode;
          }
          const searchParams = new URLSearchParams(params);
          setSearchParamsFromCompareDifferenceSettings(
            searchParams,
            difference
          );
          navigate(`../${PATH_NAMES_ENUM.COMPARE}?${searchParams}`);
        }}
        isLoading={
          comparisonsQuery.isLoading ||
          (comparisonsQuery.isFetching && !comparisonsQuery.isPreviousData)
        }
        footerClassName="mb-n5 bg-transparent position-absolute bottom-0 start-50 translate-middle-x"
      />
    </Row>
  );
}
