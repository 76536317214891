import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function CheckedCircleIcon({
  className = "",
  fill = styles.white,
  size = 24,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#CheckedCircleClipPath)">
        <path
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm-.997-6 7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="CheckedCircleClipPath">
          <path fill={fill} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
