import React, { ReactElement, createContext, useEffect } from "react";
import { Accept, useDropzone } from "react-dropzone";

import { ifTest } from "../../utils";

export interface IFilesContext {
  isProcessingFiles: boolean;
}

export const FilesContext = createContext<IFilesContext>({
  isProcessingFiles: false,
});

export const ACCEPT_EXCEL_FILE_FORMAT = {
  "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
};

export interface IImportFilesProps {
  multiple?: boolean;
  onDrag?: (data: boolean) => void;
  onDrop: (data: File[]) => void;
  onFileDialogCancel?: () => void;
  accept?: Accept;
  children?: ReactElement;
  disabled?: boolean;
}

export function ImportFiles({
  multiple = false,
  onDrag,
  onDrop,
  onFileDialogCancel,
  children,
  accept,
  disabled,
}: IImportFilesProps) {
  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple,
    disabled,
    onFileDialogCancel,
  });

  useEffect(() => {
    onDrag?.(isDragActive);
  }, [isDragActive, onDrag]);

  return (
    <div data-test={ifTest("import-file")} {...getRootProps()} tabIndex={-1}>
      <input type="file" {...getInputProps()} className="d-none" />
      {children}
    </div>
  );
}
