import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import colors from "../../shared/_exports.module.scss";
import { useTranslation } from "../../shared/i18n";
import { CloseCircledIcon } from "../../shared/icons";
import { INPUT_TYPES_ENUM } from "../../shared/inputs/input";
import { IFilterProps } from "../OperationsFilters";
import { IDateFilterValue } from "../OperationsTable";

export function DateFilter({ filterKey, onChange }: IFilterProps) {
  const [filterValue, setFilterValue] = useState<IDateFilterValue>({
    min: undefined,
    max: undefined,
  });
  const [showClearIcon, setShowClearIcon] = useState<boolean>(false);

  const { t } = useTranslation("DateFilter");

  const handleInputChange = (inputName: string, inputValue: string) => {
    setFilterValue((value) => ({ ...value, [inputName]: inputValue }));
  };

  const handleClear = () => {
    setFilterValue({ min: undefined, max: undefined });
    setShowClearIcon(false);
    onChange?.(filterKey, { min: undefined, max: undefined });
  };

  const handleOnBlur = () => {
    onChange?.(filterKey, filterValue);
    setShowClearIcon(
      filterValue.min !== undefined || filterValue.max !== undefined
    );
  };

  return (
    <InputGroup className="mb-3">
      {Object.entries(filterValue).map(([fieldName, value], index) => (
        <Fragment key={fieldName}>
          <InputGroup.Text className="bg-light">{t(fieldName)}</InputGroup.Text>
          <Form.Control
            type={INPUT_TYPES_ENUM.DATE}
            name={fieldName}
            value={value || ""}
            onChange={({ target: { value, name } }) => {
              handleInputChange(name, value);
              handleOnBlur();
            }}
            onBlur={handleOnBlur}
          />
          {
            // workaround to force InputGroup's border display between the two date inputs
            index < Object.entries(filterValue).length - 1 && (
              <InputGroup.Text className="p-0" />
            )
          }
        </Fragment>
      ))}
      {showClearIcon && (
        <Button size="sm" variant="outline-input" onClick={handleClear}>
          <CloseCircledIcon fill={colors.secondary} />
        </Button>
      )}
    </InputGroup>
  );
}
