import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";

/**
 * helper function to track matomo pageViews
 * @param title: title of the page to display in matomo
 * @param customDimensions: array of additional variables for more precise tracking
 * @param dependencies: dependencies to fire tracking againg, usually the operation_id or lot_id
 */
export function useTrackPageView(
  title: string,
  customDimensions?: any[],
  dependencies?: any[]
) {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: title,
      customDimensions: customDimensions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(dependencies || []), title]);
}
