import classNames from "classnames";
import { useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { useTranslation } from "../shared/i18n";
import { useHasChanged } from "../shared/useHasChanged";

import { ChartsCard } from "./ChartsCard";
import { HorizontalScrollButtons } from "./HorizontalScrollButtons";
import { LotsBarChart, LotsBarChartLegend } from "./LotsBarChart";
import { LotsChartDataFilterDropdown } from "./LotsChartDataFilterDropdown";
import styles from "./OperationGeneralViewCharts.module.scss";
import { ILotChartData } from "./chart.types";
import { useDynamicChartBarWidth } from "./useDynamicChartBarWidth";
import { useLotsChartMaxBarCount } from "./useLotsChartMaxBarCount";
import { useOperationLotsChartData } from "./useOperationLotsChartData";

function filterLots(data: ILotChartData[]): ILotChartData[] {
  return data.map((lot) => {
    const minAndBestTenderIds = [
      ...(lot.min_price_breakdowns?.map(({ id }) => id) ?? []),
      lot.best_price_breakdown?.id,
    ];
    return {
      ...lot,
      companies: lot.companies
        ?.filter((company) =>
          company.tenders.some(({ id }) => minAndBestTenderIds.includes(id))
        )
        // only keep best and min tenders
        .map((company) => ({
          ...company,
          tenders: company.tenders.filter(({ id }) =>
            minAndBestTenderIds.includes(id)
          ),
        })),
    };
  });
}

export function OperationPersonalizedViewCharts() {
  const { t } = useTranslation("OperationPersonalizedViewCharts");
  const barChartWrapperRef = useRef<HTMLDivElement>(null);

  const [visibleLotsChartData, setVisibleLotsChartData] = useState<
    ILotChartData[]
  >([]);
  const { lotsChartData, isLoading } = useOperationLotsChartData(filterLots);

  if (useHasChanged(lotsChartData)) setVisibleLotsChartData(lotsChartData);

  const maxBarCount = useLotsChartMaxBarCount(visibleLotsChartData);

  const barWidth = useDynamicChartBarWidth({
    maxBarCountPerTick: maxBarCount,
    tickCount: visibleLotsChartData.length,
    barChartWrapperRef,
  });

  return (
    <ChartsCard>
      <Row>
        <Col as="h6">{t("title")}</Col>
        <Col xs="auto" className="align-self-center">
          <LotsChartDataFilterDropdown
            lotsChartData={lotsChartData}
            setVisibleLotsChartData={setVisibleLotsChartData}
            visibleLotsChartData={visibleLotsChartData}
          />
        </Col>
        <Col xs="auto" className="align-self-center">
          <HorizontalScrollButtons
            scrollableElement={barChartWrapperRef.current}
            scrollStep={barWidth * maxBarCount}
          />
        </Col>
      </Row>

      <div
        className={classNames(styles["charts-wrapper"], "w-100 mb-3")}
        ref={barChartWrapperRef}
      >
        {isLoading && <Spinner />}
        <LotsBarChart
          data={visibleLotsChartData}
          maxBarCount={maxBarCount}
          barWidth={barWidth}
        />
      </div>
      <LotsBarChartLegend />
    </ChartsCard>
  );
}
