import React from "react";
import Form from "react-bootstrap/Form";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

export function Switch<T extends FieldValues>({
  control,
  name,
  label,
}: {
  control: Control<T>;
  name: Path<T>;
  label?: string;
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Form.Switch
          id={name}
          label={label}
          checked={value}
          onChange={onChange}
          ref={ref}
          onBlur={onBlur}
        />
      )}
    />
  );
}
