import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function EyeCrossedIcon({
  className = "",
  fill = styles.teal900,
  size = 25,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize((size / 25) * 24)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <path
        fill={fill}
        d="M5.02 5.986 1.893 2.86l1.415-1.415 19.8 19.8-1.416 1.414-3.31-3.31a10.949 10.949 0 0 1-5.882 1.703c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066Zm10.237 10.238-1.464-1.464a3 3 0 0 1-4-4L8.327 9.294a5 5 0 0 0 6.93 6.93ZM8.474 3.812a10.99 10.99 0 0 1 4.026-.76c5.392 0 9.878 3.88 10.82 9a10.949 10.949 0 0 1-2.013 4.592l-3.86-3.86a5 5 0 0 0-5.68-5.68l-3.293-3.29v-.002Z"
      />
    </svg>
  );
}

export function EyeIcon({ className = "", fill = styles.teal900 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(24)}
      height={applyFontRatioToPxSize(25)}
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <g clipPath="url(#eye-icon)">
        <path
          fill={fill}
          d="M1.181 12c.94-5.12 5.427-9 10.82-9 5.391 0 9.877 3.88 10.818 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9Zm10.82 5A5 5 0 1 0 12 7a5 5 0 0 0 0 10Zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
        />
      </g>
      <defs>
        <clipPath id="eye-icon">
          <path fill="transparent" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
