import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function ExcelIcon({
  className = "",
  fill = styles.green800,
  size = 24,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#excel-icon)">
        <path
          d="M2.86 2.87722L15.43 1.08222C15.5009 1.07204 15.5732 1.07723 15.642 1.09744C15.7108 1.11765 15.7744 1.1524 15.8285 1.19935C15.8827 1.24629 15.9261 1.30433 15.9559 1.36952C15.9856 1.43472 16.001 1.50555 16.001 1.57722V22.4232C16.001 22.4948 15.9856 22.5655 15.9559 22.6306C15.9262 22.6957 15.8829 22.7537 15.8288 22.8007C15.7748 22.8476 15.7113 22.8824 15.6427 22.9026C15.5741 22.9229 15.5019 22.9282 15.431 22.9182L2.859 21.1232C2.62062 21.0893 2.40249 20.9704 2.24469 20.7886C2.08689 20.6067 2 20.374 2 20.1332V3.86722C2 3.62643 2.08689 3.39373 2.24469 3.21186C2.40249 3.02999 2.62062 2.91117 2.859 2.87722H2.86ZM17.001 3.00022H21.001C21.2662 3.00022 21.5206 3.10557 21.7081 3.29311C21.8956 3.48064 22.001 3.735 22.001 4.00022V20.0002C22.001 20.2654 21.8956 20.5198 21.7081 20.7073C21.5206 20.8949 21.2662 21.0002 21.001 21.0002H17.001V3.00022ZM10.201 12.0002L13.001 8.00022H10.601L9.001 10.2862L7.401 8.00022H5.001L7.801 12.0002L5.001 16.0002H7.401L9.001 13.7142L10.601 16.0002H13.001L10.201 12.0002Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="excel-icon">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
