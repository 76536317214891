import classNames from "classnames";
import Badge from "react-bootstrap/Badge";

import { useEnumTranslation } from "./i18n";
import { STATUS_ENUM } from "./types/operation.types";

export function StatusBadge({
  status = STATUS_ENUM.IN_PROGRESS,
  className = "",
}) {
  const { tEnum } = useEnumTranslation();
  return (
    <Badge
      className={classNames("fs-7 px-3 py-2", className)}
      bg={status === STATUS_ENUM.ARCHIVED ? "yellow" : "success"}
    >
      {tEnum(status)}
    </Badge>
  );
}
