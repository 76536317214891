import Mexp from "math-expression-evaluator";
import { useCallback } from "react";

import { formatNumber } from "../../i18n";

import { Select } from "./Select";
import { ISelectMultiValueProps, onSelectMulti } from "./select.types";

const mexp = new Mexp();

/**
 * Select with possibility to write a math formula.
 * The formula is evaluated using [math-expression-evaluator](https://github.com/bugwheels94/math-expression-evaluator)
 * @param props
 * @returns
 */
export function SelectMath(
  props: Partial<ISelectMultiValueProps> & {
    metaKey: string;
  }
) {
  const { onChange, metaKey } = props;
  const onMathChange = useCallback<onSelectMulti>(
    (value, options) => {
      if (!onChange) {
        return;
      }
      const lastValue = value?.slice(-1)[0];
      const lastOption = options?.find((option) => option.value === lastValue);
      if (lastOption?.meta) {
        const firstValue = value?.slice(-1)[0];
        const firstOption = options?.find(
          (option) => option.value === firstValue
        );
        if (firstOption?.meta) {
          // none of the options are a formula, keep all of them
          onChange(value, options);
        }
      } else {
        if (lastValue !== undefined) {
          // replace previous selection
          onChange([lastValue], options);
        } else {
          // clear selection
          onChange([], options);
        }
      }
    },
    [onChange]
  );

  return (
    <Select
      searchable
      className="text-start"
      {...props}
      onChange={onMathChange}
      multi
      searchValueAsOption
      updateOnOptionsChange
      formatControlOptions={(v) => {
        if (v.length > 1) {
          return v.reduce(
            (previous, current) => previous + (current.meta?.[metaKey] || 0),
            0
          );
        } else {
          const value = v[0].value;
          if (v[0].meta) {
            return v[0].label || value;
          } else {
            try {
              const result = (mexp.eval as any)(
                (value as string).replace?.(",", ".")
              );
              return formatNumber(result);
            } catch (e) {
              console.warn(e);
              return v[0].label || value;
            }
          }
        }
      }}
    />
  );
}
