import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import * as yup from "yup";

import { createRowType } from "../api/fetchRowTypes";
import { useTranslation } from "../shared/i18n";
import { ImportFilesButton } from "../shared/inputs";
import {
  CONSTRUCTION_INDICATOR_VALUES,
  ROWTYPE_USAGE_ENUM,
  TCONSTRUCTION_INDICATOR,
} from "../shared/types/rowType.types";
import {
  QUERY_KEYS_ENUM,
  useRowTypeOrganizationQuery,
  useRowTypesQuery,
} from "../shared/useSharedQueries";

import { ROW_TYPE_RE } from "./RowTypeModal";

const ROW_TYPE_CREATION_DELAY_MS = 100;

const SEPARATOR = ";";
const USE_SEPARATOR = ",";
const LINE_SEPARATOR = "\n";

const schema = yup.object({
  ref: yup.string().required().matches(ROW_TYPE_RE),
  description: yup.string().required().min(1),
  unit: yup.string().optional(),
  usage: yup
    .array()
    .of(yup.string().required().oneOf(Object.values(ROWTYPE_USAGE_ENUM)))
    .required(),
  construction_index: yup
    .string()
    .optional()
    .oneOf([...CONSTRUCTION_INDICATOR_VALUES, ""]),
});

function parseCSVLine(line: string) {
  const CSV_LINE_REGEX = new RegExp(
    `(?:${SEPARATOR}|^)(?:"([^"]*(?:""[^"]*)*)"|([^"${SEPARATOR}]*))`,
    "g"
  );
  const matches = [];
  let match;
  do {
    match = CSV_LINE_REGEX.exec(line);
    if (match) {
      matches.push(match[1] || match[2] || "");
    }
  } while (match);
  return matches;
}

export function CSVRowType({ allotment_type }: { allotment_type?: string }) {
  const { t } = useTranslation("dev.CSVRowType");
  const [progress, setProgress] = useState(0);
  const [numberOfLines, setNumberOfLines] = useState(0);
  const [errors, setErrors] = useState<any[]>([]);
  const rowTypes = useRowTypesQuery(allotment_type, true);

  const queryClient = useQueryClient();

  const organization = useRowTypeOrganizationQuery();

  const rowTypeCreation = useMutation({
    mutationFn: createRowType,
    onError: console.error,
  });
  const processAndDump = useCallback(
    async (files?: File[]) => {
      if (!files?.length) {
        return;
      }
      const finish = () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS_ENUM.ROW_TYPES, allotment_type],
        });
      };
      setProgress(0);
      setErrors([]);
      const reader = new FileReader();
      reader.onload = async function () {
        const fileData = reader.result as Buffer;
        const rows = fileData
          .toString()
          .split(LINE_SEPARATOR)
          .map((row, index) => `${index + 1}${SEPARATOR}${row}`)
          .filter((row) => {
            const [
              row_number,
              ref,
              description,
              unit,
              usageString,
              construction_index,
            ] = parseCSVLine(row);
            const usage = usageString
              ?.split(USE_SEPARATOR)
              .map((u) => u.trim());
            const rowType = {
              ref,
              description,
              unit,
              usage,
              construction_index,
            };
            try {
              schema.validateSync(rowType, { abortEarly: false });
              return true;
            } catch (validationError: any) {
              console.warn(
                t("invalid line", {
                  ...rowType,
                  message: validationError.errors.join("\n"),
                })
              );
              setErrors((errors) => [
                ...errors,
                {
                  row_number,
                  ref,
                  description,
                  unit,
                  usage,
                  construction_index,
                  error: t("invalid line message", {
                    message: validationError.errors.join("\n"),
                  }),
                },
              ]);
              return false;
            }
          })
          .filter((row) => {
            const [
              row_number,
              ref,
              description,
              unit,
              usageString,
              construction_index,
            ] = parseCSVLine(row);
            const usage = usageString?.split(USE_SEPARATOR);
            if (rowTypes.data?.find((rowType) => rowType.ref === ref)) {
              console.warn(t("ref already existing", { row_number, ref }));
              setErrors((errors) => [
                ...errors,
                {
                  row_number,
                  ref,
                  description,
                  unit,
                  usage,
                  construction_index,
                  error: "ref already existing",
                },
              ]);
              return false;
            } else {
              return true;
            }
          });
        const numberOfLines = rows.length;
        setNumberOfLines(numberOfLines);
        rows.forEach((row, index) => {
          const [
            row_number,
            ref,
            description,
            unit,
            useString,
            construction_index,
          ] = parseCSVLine(row);
          const usage = useString?.split(USE_SEPARATOR) as ROWTYPE_USAGE_ENUM[];
          // space out requests to ease the load
          setTimeout(
            () =>
              rowTypeCreation
                .mutateAsync({
                  ref,
                  description,
                  unit: unit?.trim(),
                  organization: organization!,
                  creation_date: new Date(),
                  admin_approval: true,
                  allotment_type: allotment_type!,
                  usage,
                  construction_index:
                    (construction_index as TCONSTRUCTION_INDICATOR) ||
                    undefined,
                })
                .then(() => {
                  setProgress((progress) => {
                    console.debug(
                      `${Math.round(((progress + 1) / rows.length) * 100)}% ${t(
                        "line created",
                        { ref, description, unit }
                      )}`
                    );
                    return progress;
                  });
                })
                .catch((error) => {
                  setErrors((errors) => [
                    ...errors,
                    {
                      row_number,
                      ref,
                      description,
                      unit,
                      error:
                        error.errors?.map((e: any) => e.detail)?.join("\n") ??
                        error.toString?.() ??
                        JSON.stringify(error),
                    },
                  ]);
                  console.error(error);
                })
                .finally(() => {
                  setProgress((progress) => {
                    progress += 1;
                    if (numberOfLines <= progress) {
                      finish();
                    }
                    return progress;
                  });
                }),
            index * ROW_TYPE_CREATION_DELAY_MS
          );
        });
      };
      reader.onerror = finish;
      reader.onabort = finish;

      reader.readAsText(files[0], "utf-8");
    },
    [
      queryClient,
      t,
      rowTypes.data,
      rowTypeCreation,
      organization,
      allotment_type,
    ]
  );
  const percentage = Math.round(
    numberOfLines ? (progress / numberOfLines) * 100 : 100
  );
  return (
    <Container className="mb-3">
      <Row>
        <Col>
          <h3>{t("title")}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <ol>
            <li>{t("hints.1")}</li>
            <li>{t("hints.2")}</li>
            <li>{t("hints.3")}</li>
          </ol>
        </Col>
      </Row>
      <Row>
        <Col>
          <ImportFilesButton
            onData={processAndDump}
            buttonText={t("file to import")}
            accept={{ "text/plain": [".csv"] }}
            canEdit
            disabled={rowTypes.isLoading || !organization || !allotment_type}
          />
        </Col>
        <Col>{progress < numberOfLines && <Spinner />}</Col>
      </Row>
      {progress < numberOfLines && (
        <Row>
          <Col>
            <ProgressBar now={percentage} label={`${percentage}%`} />
          </Col>
        </Row>
      )}
      {Boolean(errors.length) && (
        <Row>
          <table>
            <thead>
              <tr>
                <th>{t("row_number")}</th>
                <th>{t("ref")}</th>
                <th>{t("description")}</th>
                <th>{t("unit")}</th>
                <th>{t("usage")}</th>
                <th>{t("construction_index")}</th>
                <th>{t("error")}</th>
              </tr>
            </thead>
            <tbody>
              {errors.map(
                ({
                  row_number,
                  ref,
                  description,
                  unit,
                  usage,
                  construction_index,
                  error,
                }) => (
                  <tr>
                    <td>{Number(row_number) + 1}</td>
                    <td>{ref}</td>
                    <td>{description}</td>
                    <td>{unit}</td>
                    <td>{usage?.join(USE_SEPARATOR)}</td>
                    <td>{construction_index}</td>
                    <td style={{ maxWidth: 500 }}>
                      <pre>{error}</pre>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </Row>
      )}
    </Container>
  );
}
