import { parseISO } from "date-fns";

import { IPartialLocation } from "./location.types";
import { LOT_CLOSURE_THERMAL_INSULATION_ENUM } from "./lot.types";
import {
  IAuthorizableObject,
  IAuthorizableObjectDTO,
  rightsDTOToRightMapper,
} from "./right.types";

export enum OFFICE_BUILDING_CATEGORY_ENUM {
  OFFICE__CORPORATE = "OFFICE.CORPORATE",
  OFFICE__ADMINISTRATIVE = "OFFICE.ADMINISTRATIVE",
  OFFICE__HEADQUARTERS = "OFFICE.HEADQUARTERS",
  OFFICE__OTHER = "OFFICE.OTHER",
}
export enum CULTURAL_BUILDING_CATEGORY_ENUM {
  CULTURAL__MUSEUM = "CULTURAL.MUSEUM",
  CULTURAL__CENTER = "CULTURAL.CENTER",
  CULTURAL__LIBRARY = "CULTURAL.LIBRARY",
  CULTURAL__AUDITORIUM = "CULTURAL.AUDITORIUM",
  CULTURAL__EXHIBITION = "CULTURAL.EXHIBITION",
  CULTURAL__OTHER = "CULTURAL.OTHER",
}
export enum INDUSTRIAL_BUILDING_CATEGORY_ENUM {
  INDUSTRIAL__LOGISTICS = "INDUSTRIAL.LOGISTICS",
  INDUSTRIAL__DATACENTER = "INDUSTRIAL.DATACENTER",
  INDUSTRIAL__OTHER = "INDUSTRIAL.OTHER",
}
export enum EDUCATIONAL_BUILDING_CATEGORY_ENUM {
  EDUCATIONAL__FIRST_DEGREE = "EDUCATIONAL.FIRST_DEGREE",
  EDUCATIONAL__SECOND_DEGREE = "EDUCATIONAL.SECOND_DEGREE",
  EDUCATIONAL__HIGHER_ESTABLISHMENT = "EDUCATIONAL.HIGHER_ESTABLISHMENT",
  EDUCATIONAL__LEARNING_CENTER = "EDUCATIONAL.LEARNING_CENTER",
  EDUCATIONAL__RESEARCH_INSTITUTION = "EDUCATIONAL.RESEARCH_INSTITUTION",
  EDUCATIONAL__OTHER = "EDUCATIONAL.OTHER",
}
export enum HEALTH_BUILDING_CATEGORY_ENUM {
  HEALTH__HOSPITAL = "HEALTH.HOSPITAL",
  HEALTH__CLINIC = "HEALTH.CLINIC",
  HEALTH__NURSING_HOME = "HEALTH.NURSING_HOME",
  HEALTH__OTHER = "HEALTH.OTHER",
}
export enum JUDICIAL_BUILDING_CATEGORY_ENUM {
  JUDICIAL__COURTHOUSE = "JUDICIAL.COURTHOUSE",
  JUDICIAL__JAILHOUSE = "JUDICIAL.JAILHOUSE",
  JUDICIAL__OTHER = "JUDICIAL.OTHER",
}
export enum DWELLING_BUILDING_CATEGORY_ENUM {
  DWELLING__COLLECTIVE = "DWELLING.COLLECTIVE",
  DWELLING__INDIVIDUAL = "DWELLING.INDIVIDUAL",
  DWELLING__ELDERLY = "DWELLING.ELDERLY",
  DWELLING__STUDENT = "DWELLING.STUDENT",
  DWELLING__OTHER = "DWELLING.OTHER",
}
export enum PARKING_BUILDING_CATEGORY_ENUM {
  PARKING__SUPERSTRUCTURE = "PARKING.SUPERSTRUCTURE",
  PARKING__INFRASTRUCTURE = "PARKING.INFRASTRUCTURE",
  PARKING__OTHER = "PARKING.OTHER",
}
export enum COMMERCIAL_BUILDING_CATEGORY_ENUM {
  COMMERCIAL__MALL = "COMMERCIAL.MALL",
  COMMERCIAL__HYPERMARKET = "COMMERCIAL.HYPERMARKET",
  COMMERCIAL__SUPERMARKET = "COMMERCIAL.SUPERMARKET",
  COMMERCIAL__OTHER = "COMMERCIAL.OTHER",
}
export enum SPORT_BUILDING_CATEGORY_ENUM {
  SPORT__HALL = "SPORT.HALL",
  SPORT__STADIUM = "SPORT.STADIUM",
  SPORT__SWIMMING_SKATING = "SPORT.SWIMMING_SKATING",
  SPORT__OTHER = "SPORT.OTHER",
}
export enum LEISURE_BUILDING_CATEGORY_ENUM {
  LEISURE__RECREATIONAL = "LEISURE.RECREATIONAL",
  LEISURE__HOTEL = "LEISURE.HOTEL",
  LEISURE__HOLIDAY = "LEISURE.HOLIDAY",
  LEISURE__RESTAURANT = "LEISURE.RESTAURANT",
  LEISURE__OTHER = "LEISURE.OTHER",
}
export enum OTHER_BUILDING_CATEGORY_ENUM {
  BUILDING__OTHER = "BUILDING.OTHER",
}
export type BUILDING_CATEGORY =
  | OFFICE_BUILDING_CATEGORY_ENUM
  | CULTURAL_BUILDING_CATEGORY_ENUM
  | INDUSTRIAL_BUILDING_CATEGORY_ENUM
  | EDUCATIONAL_BUILDING_CATEGORY_ENUM
  | HEALTH_BUILDING_CATEGORY_ENUM
  | JUDICIAL_BUILDING_CATEGORY_ENUM
  | DWELLING_BUILDING_CATEGORY_ENUM
  | PARKING_BUILDING_CATEGORY_ENUM
  | COMMERCIAL_BUILDING_CATEGORY_ENUM
  | SPORT_BUILDING_CATEGORY_ENUM
  | LEISURE_BUILDING_CATEGORY_ENUM
  | OTHER_BUILDING_CATEGORY_ENUM;

export enum CONTRACT_TYPE_ENUM {
  PRIVATE_CLASSICAL = "PRIVATE_CLASSICAL",
  PRIVATE_CONCEPTION_REALIZATION = "PRIVATE_CONCEPTION_REALIZATION",
  PUBLIC_CLASSICAL = "PUBLIC_CLASSICAL",
  PUBLIC_GLOBAL_ENERGY_PERFORMANCE = "PUBLIC_GLOBAL_ENERGY_PERFORMANCE",
  PUBLIC_ENERGY_PERFORANCE_PARTNERSHIP = "PUBLIC_ENERGY_PERFORANCE_PARTNERSHIP",
  PRIVATE_PUBLIC_PARTNERSHIP = "PRIVATE_PUBLIC_PARTNERSHIP",
  PUBLIC_CONTRACTS_WITH_ADAPTED_PROCEDURES = "PUBLIC_CONTRACTS_WITH_ADAPTED_PROCEDURES",
  PUBLIC_CONCEPTION_REALIZATION = "PUBLIC_CONCEPTION_REALIZATION",
}

export enum WORK_TYPE_ENUM {
  NEW_ON_AN_EMPTY_PLOT = "NEW_EMPTY_PLOT",
  NEW_ON_A_PLOT_WITH_BUILDING_TO_BE_DEMOLISHED = "NEW_DEMOLISHED_PLOT",
  RENOVATION = "RENOVATION",
  REHABILITATION = "REHABILITATION",
  RESTORATION = "RESTORATION",
  EXTENSION_OF_EXISTING_BUILDING = "EXTENSION_OF_EXISTING_BUILDING",
}

export enum STATUS_ENUM {
  IN_PROGRESS = "IN_PROGRESS",
  ARCHIVED = "ARCHIVED",
}

export enum LABEL_CERTIFICATION_ENUM {
  NO_LABEL = "NO_LABEL",
  BBC_EFFINERGIE = "BBC_EFFINERGIE",
  BBC_EFFINERGIE_RENOVATION = "BBC_EFFINERGIE_RENO",
  BBCA = "BBCA",
  BEPOS_EFFINERGIE = "BEPOS_EFFINERGIE",
  BIODIVERCITY = "BIODIVERCITY",
  BIOSOURCED = "BIOSOURCED",
  BREEAM = "BREEAM",
  EFFINATURE = "EFFINATURE",
  HQE_BD = "HQE_BD",
  IPMVP = "IPMVP",
  LEED = "LEED",
  NF_HABITAT_HQE = "NF_HABITAT_HQE",
  OSMOZ = "OSMOZ",
  PASSIVHAUS = "PASSIVHAUS",
}

export enum APPLIED_REGULATION_ENUM {
  RE_2020_2022 = "RE_2020_2022",
  RE_2020_2025 = "RE_2020_2025",
  RE_2020_2028 = "RE_2020_2028",
  RE_2020_2031 = "RE_2020_2031",
  RT_2012_10 = "RT_2012_10%",
  RT_2012_20 = "RT_2012_20%",
  RT_2012_40 = "RT_2012_40%",
}

export interface IOperation extends IAuthorizableObject {
  id: string;
  name: string;
  reference_code: string;
  permit_date?: Date;
  tender_date: Date;
  status: STATUS_ENUM;
  contract_type: CONTRACT_TYPE_ENUM;
  floor_area: number;
  original_author: string;
  work_type: WORK_TYPE_ENUM[];
  building_categories: BUILDING_CATEGORY[];
  building_category: BUILDING_CATEGORY;
  locations: IPartialLocation[];
  labels_and_certifications: (LABEL_CERTIFICATION_ENUM | string)[];
  applied_regulation: APPLIED_REGULATION_ENUM;
  thermal_insulation: LOT_CLOSURE_THERMAL_INSULATION_ENUM[];
  infrastructure_levels_number: number;
  superstructure_levels_number: number;
}

export interface IOperationDTO extends IAuthorizableObjectDTO {
  id: string;
  name: string;
  reference_code: string;
  permit_date?: string;
  tender_date: string;
  status: STATUS_ENUM;
  contract_type: CONTRACT_TYPE_ENUM;
  original_author: string;
  work_type: WORK_TYPE_ENUM[];
  building_categories: BUILDING_CATEGORY[];
  floor_area: string;
  labels_and_certifications: (LABEL_CERTIFICATION_ENUM | string)[];
  applied_regulation: APPLIED_REGULATION_ENUM;
  thermal_insulation: LOT_CLOSURE_THERMAL_INSULATION_ENUM[];
  infrastructure_levels_number: number;
  superstructure_levels_number: number;
}

export const operationDTOToOperationMapper = ({
  tender_date,
  permit_date,
  floor_area,
  rights,
  ...props
}: IOperationDTO) =>
  ({
    ...props,
    floor_area: Number(floor_area),
    tender_date: parseISO(tender_date),
    permit_date: permit_date ? parseISO(permit_date) : undefined,
    rights: rightsDTOToRightMapper(rights),
  } as IOperation);
