import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { Link, useSearchParams } from "react-router-dom";

import { LotWithConventionalAllotments } from "../shared/ConventionalAllotments";
import { useEnumTranslation, useTranslation } from "../shared/i18n";
import { FilterIcon } from "../shared/icons";
import { Select } from "../shared/inputs/select";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { IDocument, ORDER_ENUM, sorters } from "../shared/types/document.types";
import { useCurrentLot } from "../shared/useSharedQueries";

import { DocumentCard } from "./DocumentCard";
import styles from "./DocumentExplorer.module.scss";
import { FolderCard } from "./FolderCard";

const orderList = Object.values(ORDER_ENUM);

interface IDocumentExplorerProps {
  documents?: ((
    | { file_name: string; upload_date?: Date; id: string }
    | IDocument
  ) & { version?: number })[];
  isLoading?: boolean;
  onFolderClick?: (id: string, event: React.MouseEvent) => void;
  companyName?: string;
}

export function DocumentExplorer({
  onFolderClick,
  documents,
  isLoading,
  companyName,
}: IDocumentExplorerProps) {
  const { t } = useTranslation("DocumentsPage");

  const lot = useCurrentLot();

  const [searchParams, setSearchParams] = useSearchParams();

  const [order, setOrder] = useState<ORDER_ENUM>(
    (searchParams.get("order") as ORDER_ENUM) ?? ORDER_ENUM.ALPHABETICALLY
  );
  const [onlyLatestVersion, setOnlyLatestVersion] = useState(
    searchParams.get("onlyLatestVersion") !== "false"
  );

  const updateSearchParams = useCallback(
    (order: ORDER_ENUM, onlyLatestVersion: Boolean) => {
      setSearchParams(
        { order: String(order), onlyLatestVersion: String(onlyLatestVersion) },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const { tEnum } = useEnumTranslation();
  const orderOptions = useMemo(
    () => orderList.map((value) => ({ value, label: tEnum(value) })),
    [tEnum]
  );

  const sortedAndFilteredDocuments = useMemo(() => {
    const latestVersion = documents?.reduce((max, document) => {
      if (document.version !== undefined && document.version > max) {
        return document.version;
      }
      return max;
    }, 0);
    if (companyName === "MOE") {
      return documents;
    }
    return documents
      ?.sort(sorters[order])
      ?.filter(
        (document) =>
          !onlyLatestVersion ||
          document.version === undefined ||
          document.version === latestVersion
      );
  }, [documents, companyName, order, onlyLatestVersion]);

  return (
    <Container fluid className="m-0 m-md-4 px-md-4">
      <Row className="mb-4 mx-0">
        <Col sm="auto">
          <Select
            prefixIcon={
              <>
                <FilterIcon className={styles.filter} />
                <b className="mx-2">{t("sort by")} :</b>
              </>
            }
            placeholder=""
            options={orderOptions}
            onChange={(v) => {
              const order = v as unknown as ORDER_ENUM;
              setOrder(order);
              updateSearchParams(order, onlyLatestVersion);
            }}
            value={order}
            className={classNames(styles.select)}
          />
        </Col>
        {companyName !== "MOE" && companyName !== undefined && (
          <Col sm="auto">
            <div
              className={classNames(
                styles["switch-container"],
                "p-3 rounded lh-lg d-flex"
              )}
            >
              <b>{t("only display latest versions")}</b>
              <Form.Check
                type="switch"
                className={classNames("ms-3")}
                checked={onlyLatestVersion}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setOnlyLatestVersion(checked);
                  updateSearchParams(order, checked);
                }}
              />
            </div>
          </Col>
        )}
      </Row>
      <Row className="mx-0">
        <Col>
          <Breadcrumb className={styles.breadcrumb}>
            {lot && (
              <>
                <Breadcrumb.Item
                  linkAs={Link}
                  linkProps={{
                    to: {
                      pathname: `/${PATH_NAMES_ENUM.OPERATIONS}/${lot.project}/${PATH_NAMES_ENUM.DOCUMENTS}`,
                      search: searchParams.toString(),
                    },
                  }}
                >
                  {lot.project_name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  linkAs={Link}
                  linkProps={{
                    to: {
                      pathname: `/${PATH_NAMES_ENUM.OPERATIONS}/${lot.project}/${PATH_NAMES_ENUM.LOTS}/${lot.id}/${PATH_NAMES_ENUM.DOCUMENTS}`,
                      search: searchParams.toString(),
                    },
                  }}
                >
                  <LotWithConventionalAllotments lot={lot} />
                </Breadcrumb.Item>
              </>
            )}
            {companyName && (
              <Breadcrumb.Item linkAs={Link}>{companyName}</Breadcrumb.Item>
            )}
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="mx-0">
        {sortedAndFilteredDocuments?.length
          ? sortedAndFilteredDocuments?.map((document) => (
              <Col xs="auto" key={document.id} className="mb-3">
                {instanceOfDocument(document) ? (
                  <DocumentCard document={document} />
                ) : (
                  <FolderCard
                    file_name={document.file_name}
                    id={document.id}
                    onClick={onFolderClick}
                  />
                )}
              </Col>
            ))
          : !isLoading && <>{t("no documents")}</>}
      </Row>
      {isLoading && (
        <Row>
          <Col className="text-center">
            <Spinner />
          </Col>
        </Row>
      )}
    </Container>
  );
}

function instanceOfDocument(doc: any): doc is IDocument {
  return "upload_date" in doc;
}
