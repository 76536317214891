import React from "react";
import Form from "react-bootstrap/Form";
import { FieldError } from "react-hook-form";
import { UseTranslationOptions, useTranslation } from "react-i18next";

import { BACK_ERROR_PREFIX } from "./useBackFieldErrors";

export interface IMessageParams {
  message: string;
  params?: UseTranslationOptions<undefined> & Record<string, any>;
}
function isMessageParams(
  message: string | IMessageParams
): message is IMessageParams {
  return (message as IMessageParams)?.message !== undefined;
}

const maxCharRegex =
  /(Ensure this field has no more than )(\d+)( characters\.)/;

export function FormControlError({ error }: { error?: FieldError }) {
  const { t } = useTranslation();
  if (error !== undefined && error.message !== undefined) {
    const translateArgs: [
      string,
      UseTranslationOptions<undefined> & Record<string, any>
    ] = [error.message, {}];
    if (isMessageParams(error.message)) {
      // rich error message from Yup
      translateArgs[0] = error.message.message;
      translateArgs[1] = error.message.params ?? {};
    } else if (error.message?.startsWith(BACK_ERROR_PREFIX)) {
      // backend messages can be checked to add special parameters
      const matchMaxCharMessage = error.message.match(maxCharRegex);
      if (matchMaxCharMessage) {
        translateArgs[0] = `${BACK_ERROR_PREFIX}.${matchMaxCharMessage[1]}max${matchMaxCharMessage[3]}`;
        translateArgs[1] = { max: matchMaxCharMessage[2] };
      }
    }
    return (
      <Form.Control.Feedback type="invalid" className="d-block">
        {t(...translateArgs)}
      </Form.Control.Feedback>
    );
  }
  return null;
}
