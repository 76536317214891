import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { IBackErrors, RootError, useBackFieldErrors } from "../shared/errors";
import { useTranslation, yupLocale } from "../shared/i18n";
import {
  ControlledInput,
  EuroIconInputGroup,
  Input,
  getInputProps,
} from "../shared/inputs";
import {
  AllotmentSelect,
  CONVENTIONAL_ALLOTMENTS,
} from "../shared/inputs/AllotmentSelect";
import {
  getUndefinedSafeSelectOnChange,
  yupNumber,
} from "../shared/inputs/input";
import { CONVENTIONAL_ALLOTMENTS_ENUM } from "../shared/types/lot.types";
import { useHasChanged } from "../shared/useHasChanged";

export interface ILotsForm {
  id?: string;
  name?: string;
  conventional_allotments: CONVENTIONAL_ALLOTMENTS_ENUM[];
  reference_number: string;
  estimation_amount?: number | null;
  no_estimation_amount?: boolean;
}
export interface ILotsFormProps {
  defaultValues?: ILotsForm;
  onData: Function;
  onSubmit: SubmitHandler<ILotsForm>;
}

export function isFormValid(lotsForm?: ILotsForm) {
  return schema.isValidSync(lotsForm);
}

yup.setLocale(yupLocale);
const schema = yup.object({
  id: yup.string().optional(),
  name: yup.string().optional(),
  conventional_allotments: yup
    .array(
      yup
        .string()
        .required()
        .oneOf(CONVENTIONAL_ALLOTMENTS.flatMap(({ options }) => options))
    )
    .min(1)
    .required(),
  reference_number: yup.string().required(),
  no_estimation_amount: yup.bool(),
  estimation_amount: yupNumber()
    .positive()
    .nullable()
    .when("no_estimation_amount", {
      is: true,
      then: (estimation_amount) => estimation_amount.nullable(),
      otherwise: (estimation_amount) =>
        estimation_amount.nonNullable().required(),
    }),
});

export type TLotsFormInputs = yup.InferType<typeof schema>;

export function LotsForm({
  onData,
  onSubmit,
  defaultValues,
  backErrors,
}: ILotsFormProps & { backErrors?: IBackErrors<TLotsFormInputs> }) {
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TLotsFormInputs>({
    defaultValues: {
      id: defaultValues?.id,
      name: defaultValues?.name,
      reference_number: defaultValues?.reference_number,
      estimation_amount: defaultValues?.estimation_amount,
      conventional_allotments: defaultValues?.conventional_allotments,
      no_estimation_amount: defaultValues
        ? !defaultValues.estimation_amount
        : undefined,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const noEstimation = watch("no_estimation_amount");
  const estimationAmount = watch("estimation_amount");

  // we need to erase estimation_amount if no_estimation is set to true
  if (useHasChanged(noEstimation) && noEstimation && estimationAmount) {
    setValue("estimation_amount", null, {
      shouldDirty: true,
      shouldTouch: true,
    });
  }
  // then trigger a change event
  useEffect(() => {
    if (estimationAmount === null) {
      onData(getValues());
    }
  }, [estimationAmount, getValues, onData]);

  useBackFieldErrors(backErrors, setError, clearErrors);
  const { t } = useTranslation("LotsForm");

  return (
    <Form
      onChange={() => {
        onData(getValues());
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        {...getInputProps("id", register, schema, errors)}
        hidden
        readOnly
      />
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={7}
          className="col-10"
          label={t("conventional allotments")}
          name="conventional_allotments"
          schema={schema}
          control={control}
          render={({ field }) => (
            <AllotmentSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
        <Input
          as={Col}
          xs={5}
          {...getInputProps("name", register, schema, errors)}
          label={t("lot name")}
          placeholder={t("lot name example")}
        />
      </Row>
      <Row>
        <Input
          as={Col}
          md={3}
          {...getInputProps("reference_number", register, schema, errors)}
          label={t("lot number")}
          placeholder={t("lot number")}
          className="number-input-no-arrows"
        />
        <Input
          as={Col}
          md={6}
          {...getInputProps("estimation_amount", register, schema, errors)}
          label={t("lot amount")}
          placeholder={t("lot amount")}
          inputGroupAfter={<EuroIconInputGroup id="euro-icon" />}
          disabled={noEstimation}
        />
        <Input
          as={Col}
          md="auto"
          {...getInputProps("no_estimation_amount", register, schema, errors)}
          label={t("no estimation")}
        />
      </Row>
      <RootError errors={errors} />
    </Form>
  );
}
