import React from "react";
import { ControllerRenderProps } from "react-hook-form";

import { useEnumTranslation, useTranslation } from "../i18n";
import { LABEL_CERTIFICATION_ENUM } from "../types/operation.types";

import { Select } from "./";

const LabelCertifications = Object.values(LABEL_CERTIFICATION_ENUM);
export const LabelCertificationSelect = React.forwardRef<
  HTMLInputElement,
  ControllerRenderProps
>(function LabelCertificationSelect({ value, ...field }, ref) {
  const { tEnum } = useEnumTranslation();
  const { t } = useTranslation("LabelCertificationSelect");

  const options = [
    ...LabelCertifications.map((value) => ({
      value,
      label: tEnum(value),
    })),
    // add option for searchValueAsOption
    ...((value as LABEL_CERTIFICATION_ENUM[]) ?? [])
      .filter((v) => !LabelCertifications.includes(v))
      .map((value) => ({
        value,
        label: value,
      })),
  ];
  return (
    <Select
      // force Select to rerender on options change, otherwise new searchValueAsOption not displayed
      key={JSON.stringify(options)}
      {...field}
      value={value}
      ref={ref}
      options={options}
      searchable
      searchValueAsOption
      multi
      placeholder={t("select labels and certifications")}
    />
  );
});
