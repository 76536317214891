import { DIFFERENCE_DISPLAY_MODE_ENUM } from "../shared/visualization/Difference";

export enum COMPARE_DIFFERENCE_BASES_ENUM {
  ESTIMATE = "ESTIMATION",
  AVERAGE = "BREAKDOWNS_AVERAGE",
}

/** indicates the difference position from the compared value */
export enum DIFFERENCE_POSITION_ENUM {
  ABOVE = "Above",
  BELOW = "Below",
}

export interface ICompareNumberDifference extends IThresholds {
  active: boolean;
  mode: DIFFERENCE_DISPLAY_MODE_ENUM;
  /** can be 'BREAKDOWNS AVERAGE', 'ESTIMATION' or tender's UUID */
  base: COMPARE_DIFFERENCE_BASES_ENUM | string;
}

export interface IThresholds {
  /** range from 0 to 100% */
  positive_threshold: number;
  negative_threshold: number;
}

export interface ICompareDifferenceSettings {
  number_difference: ICompareNumberDifference;
  line_difference_active: boolean;
}
