import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

export function HorizontalScrollButtons({
  scrollableElement,
  scrollStep,
}: {
  scrollableElement: HTMLElement | null;
  scrollStep: number;
}) {
  const canScrollOnChart =
    scrollableElement &&
    scrollableElement.scrollWidth !== scrollableElement.clientWidth;

  const canScrollRightOnChart =
    canScrollOnChart &&
    scrollableElement.scrollLeft + scrollableElement.clientWidth <
      scrollableElement.scrollWidth;
  const canScrollLeftOnChart =
    canScrollOnChart && scrollableElement.scrollLeft > 0;

  const [, forceRender] = useState({});
  useEffect(() => {
    const forceRenderOnScrollEnd = () => forceRender({});
    scrollableElement?.addEventListener("scrollend", forceRenderOnScrollEnd);
    return () =>
      scrollableElement?.removeEventListener(
        "scrollend",
        forceRenderOnScrollEnd
      );
  }, [scrollableElement]);

  return (
    <>
      <Button
        size="sm"
        variant="outline-info"
        className="fs-5 py-1 px-2 me-2"
        onClick={() =>
          scrollableElement?.scrollBy({
            left: -scrollStep,
            behavior: "smooth",
          })
        }
        disabled={!canScrollLeftOnChart}
      >
        <MdChevronLeft />
      </Button>
      <Button
        variant="outline-info"
        size="sm"
        className="fs-5 py-1 px-2"
        onClick={() =>
          scrollableElement?.scrollBy({
            left: scrollStep,
            behavior: "smooth",
          })
        }
        disabled={!canScrollRightOnChart}
      >
        <MdChevronRight />
      </Button>
    </>
  );
}
