import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function CrossedCircleIcon({
  className = "",
  fill = styles.black,
  size = 20,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0Zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L14.9 16.31A7.902 7.902 0 0 1 10 18ZM5.1 3.69 16.31 14.9A7.902 7.902 0 0 0 18 10c0-4.42-3.58-8-8-8-1.85 0-3.55.63-4.9 1.69Z"
        fill={fill}
      />
    </svg>
  );
}
