import {
  Cell,
  CellContext,
  Column,
  ColumnDef,
  Row,
  Table,
} from "@tanstack/react-table";
import classNames from "classnames";

import {
  QUANTITY_COL_WIDTH,
  UNIT_PRICE_COL_WIDTH,
} from "../../shared/excel/ExportTableToExcel";
import { TTranslateFunction } from "../../shared/i18n";
import { IDocumentData } from "../../shared/types/document.types";
import { IPriceEstimate } from "../../shared/types/estimate.types";
import { ILogs, ILogsMap } from "../../shared/types/log.types";
import { IPriceNode } from "../../shared/types/priceNode.types";
import { ITender } from "../../shared/types/tender.types";
import { accessNested } from "../../shared/utils";
import { DIFFERENCE_DISPLAY_MODE_ENUM } from "../../shared/visualization/Difference";
import {
  COMPARE_DIFFERENCE_BASES_ENUM,
  DIFFERENCE_POSITION_ENUM,
  ICompareNumberDifference,
  IThresholds,
} from "../Compare.types";
import {
  MAX_SUPPORTED_DEPTH,
  TABLE_COLUMN_DATA_TYPE_ENUM,
} from "../Table.types";

import styles from "./CompareTable.module.scss";
import { ICompareRow } from "./CompareTable.types";
import {
  CommentCell,
  CommentHeaderCell,
  EstimateCell,
  LogsCell,
  TenderCell,
  getDifferenceColumn,
} from "./CompareTableCells";

export enum DOCUMENT_ID_TYPE_PREFIX {
  ESTIMATE = "estimate",
  TENDER = "tenders",
}

export const isCategoryRow = (row: Row<ICompareRow>): boolean =>
  Boolean(row.original.subRows?.length);

/** we get level from the count of parents to shade each category row accordingly
 */
export function getCategoryLevel(
  row: Row<ICompareRow>,
  maxDeepness: number
): number {
  return maxDeepness - row.getParentRows().length;
}

export function getFullNomenclature<T>(cellContext: CellContext<T, any>) {
  const cellNomenclature = cellContext.getValue();
  const allNomenclature = [
    ...cellContext.row
      .getParentRows()
      .map((row) => row.getValue("nomenclature")),
    cellNomenclature,
  ];
  return allNomenclature.some((nomenclature) => !nomenclature)
    ? ""
    : allNomenclature.join(".");
}

/** Get all root variant or option nodes by recursively checking children.
 *  Adjusts their nomenclatures.
 */
export function getRootVariantOrOptionNodes(
  node: IPriceNode,
  nodes: IPriceNode[] = [],
  nomenclature: string
) {
  nomenclature = nomenclature
    ? `${nomenclature}.${node.nomenclature}`
    : node.nomenclature ?? "";
  if (node.is_variant_or_option) {
    nodes.push({ ...node, nomenclature });
  } else {
    node.children?.forEach((child) => {
      getRootVariantOrOptionNodes(child, nodes, nomenclature);
    });
  }
  return nodes;
}

/** We have to first call this function on estimate nodes */
export function addPriceNodeToCompareRowsAndGetTotalPrice(
  node: IPriceNode,
  parentSubRows: ICompareRow[],
  nomenclatureDocumentId: string,
  tenderId?: string,
  /** we keep a buffer of unmatched/extra tender rows that we'll try to insert before the next matched one or manually at the end */
  extraTenderRowsBuffer?: ICompareRow[],
  shouldPrefixNomenclature?: boolean,
  keepVariantOrOption?: boolean
): number {
  const {
    id,
    nomenclature,
    designation,
    displayed_designation,
    is_variant_or_option,
    row_number,
    unit,
    total_price: totalPriceFromPriceNode,
    attributions,
    children,
    follows,
    logs,
    type,
    instructionType,
    quantity_std,
    price_std,
  } = node;
  const existingSubRowIndex = parentSubRows?.findIndex(
    (row) => row.id === node.id
  );
  const existingSubRow = parentSubRows?.[existingSubRowIndex];
  const shouldPrefixTenderNomenclatureWithDocumentId =
    tenderId && nomenclature && shouldPrefixNomenclature;
  const row: ICompareRow = existingSubRow ?? {
    id,
    nomenclature: shouldPrefixTenderNomenclatureWithDocumentId
      ? `${nomenclatureDocumentId}${nomenclature}`
      : nomenclature,
    designation,
    displayed_designation,
    is_variant_or_option,
    tenders: {},
  };
  if (!existingSubRow) {
    if (!extraTenderRowsBuffer) {
      // this is an estimate, we start creating our rows by it so add as it is
      parentSubRows.push(row);
    } else {
      const subRowToFollowIndex = Boolean(follows)
        ? parentSubRows?.findIndex((row) => row.id === follows)
        : -1;
      if (subRowToFollowIndex >= 0) {
        parentSubRows.splice(subRowToFollowIndex + 1, 0, row);
      } else if (follows === "-1") {
        parentSubRows.splice(0, 0, row);
      } else {
        extraTenderRowsBuffer.push(row);
      }
    }
  } else if (extraTenderRowsBuffer?.length) {
    // put "rows to insert" buffer right before the newly found row
    parentSubRows.splice(existingSubRowIndex, 0, ...extraTenderRowsBuffer);
    // empty buffer
    extraTenderRowsBuffer.length = 0;
  }

  // if this node has children we need to add subRows and calculate its total quantity
  row.subRows = row.subRows ?? (children ? [] : undefined);
  const extraTenderRowsBufferOrUndefinedIfEstimate = tenderId ? [] : undefined;
  const totalPriceFromChildren = children
    ?.filter(
      (child) =>
        keepVariantOrOption ||
        node.is_variant_or_option ||
        !child.is_variant_or_option
    )
    ?.map((child) => {
      const childTotal = addPriceNodeToCompareRowsAndGetTotalPrice(
        child,
        row.subRows!,
        nomenclatureDocumentId,
        tenderId,
        extraTenderRowsBufferOrUndefinedIfEstimate,
        shouldPrefixNomenclature,
        keepVariantOrOption
      );
      if (node.is_variant_or_option || !child.is_variant_or_option) {
        return childTotal;
      } else {
        return 0;
      }
    })
    ?.reduce((totalTotalPrice, totalPrice) => totalTotalPrice + totalPrice, 0);
  if (row.subRows && extraTenderRowsBufferOrUndefinedIfEstimate?.length) {
    // insert remaining non reconciliated subRows
    row.subRows.push(...extraTenderRowsBufferOrUndefinedIfEstimate);
  }
  const quantity = attributions?.reduce(
    (totalQuantity, { quantity }) => totalQuantity + (quantity ?? 0),
    0
  );
  const unit_price =
    attributions && attributions?.length > 0
      ? attributions.reduce(
          (totalUnit_price, { unit_price }) =>
            totalUnit_price + (unit_price ?? 0),
          0
        ) / attributions.length
      : undefined;
  const total_price =
    totalPriceFromPriceNode ??
    totalPriceFromChildren ??
    attributions?.reduce(
      (totalPrice, { total_price }) => totalPrice + (total_price ?? 0),
      0
    ) ??
    0;
  if (tenderId && extraTenderRowsBuffer) {
    row.tenders[tenderId] = {
      quantity,
      unit_price,
      total_price,
      designation,
      displayed_designation,
      is_variant_or_option,
      row_number,
      unit,
      nomenclatureDocumentId,
      logs,
      type,
      instructionType,
      quantity_std,
      price_std,
    };
    // set row nomenclature if empty, but not for total which is the only to have a nomenclature === ""
    if (!row.nomenclature && !row.estimate && row.nomenclature !== "") {
      row.nomenclature = `${nomenclatureDocumentId}${
        parentSubRows
          .concat(extraTenderRowsBuffer)
          .filter((row) => row.tenders[tenderId]?.hasGeneratedNomenclature)
          .length + 1
      }`;
      row.tenders[tenderId].hasGeneratedNomenclature = true;
    }
  } else {
    // this node is an estimate if no tenderId is present
    row.estimate = {
      quantity,
      unit_price,
      total_price,
      designation,
      displayed_designation,
      is_variant_or_option,
      row_number,
      unit,
      nomenclatureDocumentId,
      logs,
      type,
      instructionType,
      quantity_std,
      price_std,
    };

    if (!row.nomenclature) {
      row.nomenclature = `${nomenclatureDocumentId}${
        parentSubRows.filter((row) => row.estimate?.hasGeneratedNomenclature)
          .length + 1
      }`;
      row.estimate.hasGeneratedNomenclature = true;
    }
  }
  return total_price;
}

export function getLineDifferenceElementId(
  row: ICompareRow,
  tenderIds: string[]
): string | undefined {
  const rowTenderIds = Object.keys(row.tenders);
  // only estimate has data on this row, we want to highlight every tender rows
  if (row.estimate && rowTenderIds.length === 0) {
    return DOCUMENT_ID_TYPE_PREFIX.TENDER;
  }
  // only one tender has data on this row
  if (!row.estimate && rowTenderIds.length === 1) {
    return rowTenderIds[0];
  }
  // only one tender hasn't any data on this row
  if (row.estimate && rowTenderIds.length === tenderIds.length - 1) {
    return tenderIds.find((id) => !rowTenderIds.includes(id));
  }
  return undefined;
}

/** return the difference of cell value compared to number difference base, undefined if inactive or incomplete */
export function getValueNumberDifference(
  key:
    | TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY
    | TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE
    | TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
  cellContext: CellContext<ICompareRow, any>,
  numberDifferenceSettings?: ICompareNumberDifference
): number | undefined {
  const value = cellContext.getValue<number | undefined>();
  const baseValue = getBaseValue(cellContext, key);
  if (baseValue === undefined || value === undefined) {
    return undefined;
  }
  if (
    numberDifferenceSettings?.mode === DIFFERENCE_DISPLAY_MODE_ENUM.ABSOLUTE
  ) {
    return value - baseValue;
  } else {
    return value / baseValue - 1;
  }
}

export function getBaseValue(
  cellContext: CellContext<ICompareRow, any>,
  key:
    | TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY
    | TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE
    | TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE
) {
  const { active, base, positive_threshold, negative_threshold } =
    cellContext.table.options.meta?.compareDifferenceSettings
      ?.number_difference ?? {};
  if (
    !active ||
    positive_threshold === undefined ||
    negative_threshold === undefined ||
    base === undefined
  ) {
    return undefined;
  }
  let baseValue: number | undefined;
  switch (base) {
    case COMPARE_DIFFERENCE_BASES_ENUM.ESTIMATE:
      baseValue = accessNested(
        cellContext.row.original,
        `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${key}`
      );
      break;
    case COMPARE_DIFFERENCE_BASES_ENUM.AVERAGE:
      // we do an average of every tender's value
      const tenders = Object.values(cellContext.row.original.tenders)
        .map((tender) => tender[key])
        .filter((value) => value !== undefined) as number[];
      if (tenders.length === 0) {
        return undefined;
      }
      baseValue =
        tenders.reduce((sum, value) => sum + value, 0) / tenders.length;
      break;
    default:
      // base should be a tender id
      baseValue = accessNested(
        cellContext.row.original,
        `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${base}.${key}`
      );
  }
  return baseValue;
}

/** checks if the provided cell is a line-difference */
// TODO: remove any type
export function cellIsLineDifference(cell: Cell<any, any>, row: Row<any>) {
  const { table } = cell.getContext();
  return (
    table.options.meta?.compareDifferenceSettings?.line_difference_active &&
    // if the cell is part of the tender to highlight
    (cell.id.includes(row.original.meta?.lineDifferenceElementId!) ||
      // if there is no estimate, highlight the nomenclature, designation and unit columns
      (!row.original.estimate &&
        cell.column.columnDef.meta?.dataType &&
        [
          TABLE_COLUMN_DATA_TYPE_ENUM.NOMENCLATURE,
          TABLE_COLUMN_DATA_TYPE_ENUM.DESIGNATION,
          TABLE_COLUMN_DATA_TYPE_ENUM.UNIT,
        ].includes(cell.column.columnDef.meta?.dataType)))
  );
}

/** checks if the provided cell is part of a variant or option */
export function cellIsVariantOrOption(cell: Cell<any, any>) {
  const isEstimate = cell.column.id.includes("estimate");
  if (isEstimate) {
    return cell.row.original.estimate?.is_variant_or_option ?? false;
  } else {
    return (
      cell.row.original.tenders[cell.column.parent!.id]?.is_variant_or_option ??
      false
    );
  }
}

function applyLastCellStyleMetaOnLastColumn(columns: ColumnDef<ICompareRow>[]) {
  const lastColumnMeta = columns.slice(-1).pop()?.meta;
  if (lastColumnMeta) {
    lastColumnMeta.className = classNames(
      lastColumnMeta.className,
      styles["last-cell"]
    );
  }
}

/** Return if compared value is below or above base if it exceed the correspondingly set negative or positive threshold, undefined otherwise */
export function getNumberDifferencePositionFromThreshold(
  difference: number,
  thresholdSettings: IThresholds
) {
  const differenceInPercentage = difference * 100;
  // if the difference with baseValue is superior than threshold, we need to alert the user
  if (differenceInPercentage > thresholdSettings.positive_threshold) {
    return DIFFERENCE_POSITION_ENUM.ABOVE;
  } else if (differenceInPercentage < -thresholdSettings.negative_threshold) {
    return DIFFERENCE_POSITION_ENUM.BELOW;
  }

  return undefined;
}
const EXCEL_CURRENCY_NUMFMT_EURO = '#,##0.00"€";-#,##0.00"€"';

export interface IRowsDict<Data = ICompareRow> {
  [rowNumber: string]: Data;
}

export function getComparedEstimateColumns(
  estimate: IPriceEstimate,
  estimateRowsDict: IRowsDict,
  columnDisplaySettings: TABLE_COLUMN_DATA_TYPE_ENUM[],
  t: TTranslateFunction,
  additionalDocumentColumns?: ColumnDef<ICompareRow>[]
) {
  const estimateColumns: ColumnDef<ICompareRow>[] = [
    ...(additionalDocumentColumns ?? []),
  ].map(mapAdditionalEstimateColumns);
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY)) {
    estimateColumns.push({
      header: t("quantity"),
      accessorKey: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY}`,
      id: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY}`,
      cell: EstimateCell,
      meta: {
        className: classNames("text-center", styles["quantity"]),
        excel: { width: QUANTITY_COL_WIDTH },
        dataType: TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY,
      },
    });
  }
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE)) {
    estimateColumns.push({
      header: t("unit_price"),
      accessorKey: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE}`,
      id: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE}`,
      cell: EstimateCell,
      meta: {
        className: classNames("text-center", styles["unit-price"]),
        excel: {
          style: { numFmt: EXCEL_CURRENCY_NUMFMT_EURO },
          width: UNIT_PRICE_COL_WIDTH,
        },
        dataType: TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE,
      },
    });
  }
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE)) {
    estimateColumns.push({
      header: t("total_price"),
      accessorKey: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE}`,
      id: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE}`,
      cell: EstimateCell,
      meta: {
        className: "text-center",
        excel: { style: { numFmt: EXCEL_CURRENCY_NUMFMT_EURO } },
        dataType: TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
      },
    });
  }
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS)) {
    estimateColumns.push({
      header: CommentHeaderCell,
      accessorKey: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS}`,
      id: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS}`,
      cell: CommentCell,
      meta: {
        className: classNames("p-0", styles.comment),
        dataType: TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS,
        excel: {
          header: t("comments"),
        },
      },
    });
  }
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.LOGS)) {
    estimateColumns.push({
      header: () => (
        <LogsCell
          value={estimate.genericLogs}
          dict={estimateRowsDict}
          otherDict={estimateRowsDict}
        />
      ),
      accessorKey: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.LOGS}`,
      id: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${TABLE_COLUMN_DATA_TYPE_ENUM.LOGS}`,
      cell: (props: CellContext<ICompareRow, any>) => (
        <LogsCell
          value={props.getValue()}
          dict={estimateRowsDict}
          otherDict={estimateRowsDict}
        />
      ),
      meta: {
        className: "text-center",
        dataType: TABLE_COLUMN_DATA_TYPE_ENUM.LOGS,
        excel: { skip: true },
      },
    });
  }
  applyLastCellStyleMetaOnLastColumn(estimateColumns);
  return estimateColumns;
}

export function mapAdditionalEstimateColumns(
  columnDef: ColumnDef<ICompareRow>
) {
  return {
    ...columnDef,
    accessorFn: (originalRow: ICompareRow) => {
      return accessNested(
        originalRow,
        `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${
          (columnDef as { accessorKey: string }).accessorKey
        }`
      );
    },
    id: `${DOCUMENT_ID_TYPE_PREFIX.ESTIMATE}.${columnDef.id}`,
  };
}

export function mapDocumentRowsDict(
  data: ICompareRow | undefined,
  dict: { [id: string]: IRowsDict },
  parents: ICompareRow[]
) {
  const row = {
    ...data,
    getValue: () => data?.nomenclature,
    getParentRows: () => parents,
  } as ICompareRow;
  if (data?.estimate) {
    dict.estimate[data.estimate?.row_number] = row;
  }
  Object.entries(data?.tenders ?? {}).forEach(([id, tender]) => {
    dict[id][tender.row_number] = row;
  });
  data?.subRows?.forEach((data) =>
    mapDocumentRowsDict(data, dict, [...parents, row])
  );
  return dict;
}

export function getComparedTenderColumns(
  tender: IDocumentData,
  numberDifferenceSettings: ICompareNumberDifference,
  tenderRowsDict: IRowsDict,
  estimateRowsDict: IRowsDict,
  columnDisplaySettings: TABLE_COLUMN_DATA_TYPE_ENUM[],
  t: TTranslateFunction,
  additionalDocumentColumns?: ColumnDef<ICompareRow>[]
) {
  const tenderColumns: ColumnDef<ICompareRow>[] = [
    ...(additionalDocumentColumns ?? []),
  ].map((column) => mapAdditionalTenderColumns(column, tender));
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY)) {
    tenderColumns.push(
      {
        header: t("quantity"),
        accessorFn: (originalRow) =>
          originalRow.tenders?.[tender.id]?.[
            TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY
          ],
        id: `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${tender.id}.${TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY}`,
        cell: (props: CellContext<ICompareRow, any>) => (
          <TenderCell t={t} {...props} />
        ),
        meta: {
          className: classNames("text-center", styles["quantity"]),
          excel: { width: QUANTITY_COL_WIDTH },
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY,
        },
      },
      getDifferenceColumn(
        tender.id,
        TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY,
        numberDifferenceSettings,
        t
      )
    );
  }
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE)) {
    tenderColumns.push(
      {
        header: t("unit_price"),
        accessorFn: (originalRow) =>
          originalRow.tenders?.[tender.id]?.[
            TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE
          ],
        id: `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${tender.id}.${TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE}`,
        cell: (props: CellContext<ICompareRow, any>) => (
          <TenderCell t={t} {...props} />
        ),
        meta: {
          className: classNames("text-center", styles["unit-price"]),
          excel: {
            style: { numFmt: EXCEL_CURRENCY_NUMFMT_EURO },
            width: UNIT_PRICE_COL_WIDTH,
          },
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE,
        },
      },
      getDifferenceColumn(
        tender.id,
        TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE,
        numberDifferenceSettings,
        t
      )
    );
  }
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE)) {
    tenderColumns.push(
      {
        header: t("total_price"),
        accessorFn: (originalRow) =>
          originalRow.tenders?.[tender.id]?.[
            TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE
          ],
        id: `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${tender.id}.${TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE}`,
        cell: (props: CellContext<ICompareRow, any>) => (
          <TenderCell t={t} {...props} />
        ),
        meta: {
          className: "text-center",
          excel: { style: { numFmt: EXCEL_CURRENCY_NUMFMT_EURO } },
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
        },
      },
      getDifferenceColumn(
        tender.id,
        TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
        numberDifferenceSettings,
        t
      )
    );
  }
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS)) {
    tenderColumns.push({
      header: CommentHeaderCell,
      accessorKey: `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${tender.id}.${TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS}`,
      id: `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${tender.id}.${TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS}`,
      cell: CommentCell,
      meta: {
        className: classNames("p-0", styles.comment),
        dataType: TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS,
        excel: {
          header: t("comments"),
        },
      },
    });
  }
  if (columnDisplaySettings.includes(TABLE_COLUMN_DATA_TYPE_ENUM.LOGS)) {
    tenderColumns.push({
      header: () => (
        <LogsCell
          value={tender.genericLogs}
          dict={tenderRowsDict}
          otherDict={estimateRowsDict}
        />
      ),
      accessorKey: `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${tender.id}.${TABLE_COLUMN_DATA_TYPE_ENUM.LOGS}`,
      id: `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${tender.id}.${TABLE_COLUMN_DATA_TYPE_ENUM.LOGS}`,
      cell: (props: CellContext<ICompareRow, any>) => (
        <LogsCell
          value={props.getValue()}
          dict={tenderRowsDict}
          otherDict={estimateRowsDict}
        />
      ),
      meta: {
        className: "text-center",
        dataType: TABLE_COLUMN_DATA_TYPE_ENUM.LOGS,
        excel: { skip: true },
      },
    });
  }
  applyLastCellStyleMetaOnLastColumn(tenderColumns);
  return tenderColumns;
}

export function mapAdditionalTenderColumns(
  columnDef: ColumnDef<ICompareRow>,
  tender: IDocumentData
) {
  return {
    ...columnDef,
    accessorFn: (originalRow: ICompareRow) => {
      return accessNested(
        originalRow,
        `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${tender.id}.${
          (columnDef as { accessorKey: string }).accessorKey
        }`
      );
    },
    id: `${DOCUMENT_ID_TYPE_PREFIX.TENDER}.${tender.id}.${columnDef.id}`,
  };
}

export function getDocumentIdFromColumn(column: Column<ICompareRow, any>) {
  return column.parent?.id;
}

export function getWorkIdFromColumn(column: Column<ICompareRow, any>) {
  return column.parent?.columnDef.meta?.work_id;
}

export function getIsColumnExpanded(
  table: Table<ICompareRow>,
  documentId?: string
) {
  return (
    documentId &&
    table.options.meta?.expandedCommentColumnsByDocumentIds?.[documentId]
  );
}

export function getCompareRowFromCellContext(
  props: CellContext<ICompareRow, any>
) {
  return props.row.original;
}

export function getIsDataOnEstimate(
  compareRow: ICompareRow,
  colDocumentId: string
) {
  // a comment can currently only be on the tender where it was made
  // or on a row that doesn't exist on this tender but exist on estimate
  // so if the document id where the comment was made exist in available
  // row tenders then it is on the tender, otherwise on the estimate
  return !compareRow.tenders[colDocumentId];
}

export function getRowNumber(
  compareRow: ICompareRow,
  isEstimate: boolean,
  rowDocumentId: string
) {
  return isEstimate
    ? compareRow.estimate?.row_number
    : compareRow.tenders[rowDocumentId].row_number;
}

export function getCommentFromCellContextOrFalse(
  props: CellContext<ICompareRow, unknown>
) {
  const colDocumentId = getDocumentIdFromColumn(props.column);
  const commentByRowNumberByDocumentRowIdByDocumentColId =
    props.table.options.meta?.commentByRowNumberByRowDocumentIdByColDocumentId;

  if (
    !colDocumentId ||
    !commentByRowNumberByDocumentRowIdByDocumentColId ||
    !commentByRowNumberByDocumentRowIdByDocumentColId[colDocumentId]
  ) {
    // we can't access or edit a comment if we don't have access to the document id for this column or the comment dictionary
    return false;
  }

  const commentByRowNumberByDocumentRowId =
    commentByRowNumberByDocumentRowIdByDocumentColId[colDocumentId];
  const compareRow = getCompareRowFromCellContext(props);
  const estimateId = props.table.options.meta?.estimateId;
  const isCommentOnEstimate = getIsDataOnEstimate(compareRow, colDocumentId);
  const rowDocumentId = isCommentOnEstimate ? estimateId : colDocumentId;

  if (!rowDocumentId) {
    // this row is not editable for this column document id
    return false;
  }

  const row_number = getRowNumber(
    compareRow,
    isCommentOnEstimate,
    rowDocumentId
  );

  if (!row_number) {
    // can't edit a comment without a rowNumber
    return false;
  }

  const comment =
    commentByRowNumberByDocumentRowId?.[rowDocumentId]?.[row_number];
  return {
    row_number,
    price_estimate: isCommentOnEstimate ? estimateId : undefined,
    message: comment ?? "",
  };
}

/** apply logs to the corresponding IPriceNode recursively */
export function applyLogs<T extends IDocumentData>(
  document: T,
  logMap: ILogsMap
) {
  if (document.data) {
    document.data.logs = logMap[document.data.row_number];
    document.data.children?.forEach((child) =>
      applyLogs({ data: child } as ITender, logMap)
    );
  }
  return document;
}

/** apply generic logs to the root IPriceNode */
export function applyLogsGeneric<T extends IDocumentData>(
  document: T,
  genericLogs: ILogs
) {
  if (document) {
    document.genericLogs = genericLogs;
  }
  return document;
}

export function getRowDepth(table: Table<any>, row: Row<any>) {
  return (
    (table!.options.meta?.maxDepth ?? MAX_SUPPORTED_DEPTH) -
    (row.original.meta?.level ?? 0) +
    1
  );
}
