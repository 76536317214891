import { Cell, Table as ReactTable } from "@tanstack/react-table";
import classNames from "classnames";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import styles from "../compare/CompareTable/CompareTable.module.scss";
import { ICompareRow } from "../compare/CompareTable/CompareTable.types";
import {
  cellIsLineDifference,
  getRowDepth,
} from "../compare/CompareTable/CompareTableUtils";
import { useTranslation } from "../shared/i18n";
import { Table } from "../shared/table";

import {
  ROW_TYPE_COLUMN_ID,
  STANDARD_PRICE_COLUMN_ID,
  STANDARD_QUANTITY_COLUMN_ID,
} from "./MappingToolPage";
import mappingToolPageStyles from "./MappingToolPage.module.scss";

export function MappingTable({
  table,
  isLoading,
}: {
  table: ReactTable<ICompareRow>;
  isLoading?: boolean;
}) {
  // has data?
  const hasRows = table.getRowModel().rows.length > 0;

  return (
    <Table
      table={table}
      className={classNames(
        styles.table,
        styles["table-empty-first-header"],
        styles["border-first-line"],
        mappingToolPageStyles.table
      )}
      getColumnHeaderClassName={() => classNames(!hasRows && "d-none")}
      getRowClassName={(row) => {
        const isCategory = Boolean(row.subRows?.length);
        return classNames(
          isCategory && styles["category-row"],
          styles[`level-${row.original.meta?.level}`],
          styles[`depth-${getRowDepth(table, row)}`],
          `pricenode-${row.id}`
        );
      }}
      getCellClassName={(cell, columnIndex, row) => {
        return classNames(
          cellIsLineDifference(cell, row) && styles["line-difference"],
          cellIsSelect(cell) && "p-0",
          cellIsRowType(cell) && styles["row-type"],
          cellIsStandardQuantity(cell) && styles["standard-quantity"],
          cellIsStandardPrice(cell) && styles["standard-price"]
        );
      }}
      noPagination
      stickyHeaders
      isLoading={isLoading}
      disableColumnSizing
    />
  );
}

/** checks if the provided cell is a Select cell */
function cellIsSelect(cell: Cell<any, any>) {
  return (
    cellIsRowType(cell) ||
    cellIsStandardQuantity(cell) ||
    cellIsStandardPrice(cell)
  );
}

/** checks if the provided cell is a StandardQuality cell */
function cellIsStandardQuantity(cell: Cell<any, any>) {
  return cell.id.includes(STANDARD_QUANTITY_COLUMN_ID);
}

/** checks if the provided cell is a StandardQuality cell */
function cellIsStandardPrice(cell: Cell<any, any>) {
  return cell.id.includes(STANDARD_PRICE_COLUMN_ID);
}

/** checks if the provided cell is a RowType cell */
function cellIsRowType(cell: Cell<any, any>) {
  return cell.id.includes(ROW_TYPE_COLUMN_ID);
}

/**
 * Legend to help understand the different type select border styles
 */
export function MappingTableLegend() {
  const { t } = useTranslation("MappingTableLegend");
  return (
    <Container className="text-dark py-1 ps-5">
      <Row>
        <Col xs="auto">
          <div
            className={classNames(
              "text-decoration-underline border border-2 mb-1",
              mappingToolPageStyles.white
            )}
          >
            {t("title")}
          </div>
          <div className={classNames(styles.missing)}>
            <div className="select-control border-2 rounded">
              {t("missing")}
            </div>
          </div>
        </Col>
        <Col xs="auto">
          <div className={classNames(styles.manual, "mb-1")}>
            <div className="select-control border border-2 rounded">
              {t("normal")}
            </div>
          </div>
          <div className={classNames(styles.warning, "mb-1")}>
            <div className="select-control border border-2 rounded">
              {t("warning")}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
