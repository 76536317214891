import { yupResolver } from "@hookform/resolvers/yup";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React, { useContext, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import { API_BASE_URL } from "../api/fetch";
import { CUSTOM_ACTION_DIMENSIONS_ENUM } from "../matomo";
import { Brand } from "../shared/Brand";
import variables from "../shared/_exports.module.scss";
import { useTranslation, yupLocale } from "../shared/i18n";
import { EmailIcon } from "../shared/icons";
import { Input, getInputProps } from "../shared/inputs";
import { INPUT_TYPES_ENUM } from "../shared/inputs/input";
import { useTrackPageView } from "../shared/useTrackPageView";
import { ifTest } from "../shared/utils";

import { PasswordInput } from "./PasswordInput";
import { UserContext } from "./UserContext";
import { SEARCH_PARAM_REDIRECT } from "./UserContext";

function formatError(error: any) {
  const message: string = error.toString();

  if (message.endsWith("401")) {
    return "401";
  }

  return message;
}

export type TLogInFormInputs = yup.InferType<typeof schema>;

yup.setLocale(yupLocale);
const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export function LogInPage() {
  const [showLocalAccountLogin, setShowLocalAccountLogin] = useState(false);

  const { trackEvent } = useMatomo();

  useTrackPageView("Login");

  async function doLogin({ email, password }: FieldValues) {
    setErrorMessage(null);
    if (submitting || !email || !password) {
      return;
    }
    setSubmitting(true);
    try {
      await login(email, password);
      trackEvent({ category: "user", action: "login", name: "LoginSuccess" });
    } catch (error: any) {
      console.warn("Error while trying to login: ", error.toString());
      trackEvent({
        category: "user",
        action: "login-failure",
        customDimensions: [
          { id: CUSTOM_ACTION_DIMENSIONS_ENUM.DATA, value: error.toString() },
        ],
        name: "LoginFailure",
      });
      setErrorMessage(formatError(error));
    } finally {
      setSubmitting(false);
    }
  }
  function doSSO() {
    window.location.href = `${API_BASE_URL}/sso/login/?next=${window.location.href}`;
  }
  const { t } = useTranslation("LogInPage");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user, login, setToken } = useContext(UserContext);

  const tokenFromSSO = searchParams.get("token");
  const isComingFromSSO = Boolean(tokenFromSSO);

  // if user is already logged in we don't want to show this page
  useEffect(() => {
    if (tokenFromSSO) setToken(tokenFromSSO, searchParams.get("refresh"));
    if (user)
      navigate(
        decodeURIComponent(searchParams.get(SEARCH_PARAM_REDIRECT) ?? "/"),
        {
          replace: true,
        }
      );
  }, [user, navigate, tokenFromSSO, setToken, searchParams]);
  if (user) return null;

  return (
    <Container fluid className="bg-dark min-h-100">
      <Brand logoColor="white" nameColor={variables.info} className="pt-3" />
      <Card className="my-5 mx-auto card-md">
        <Card.Body className="py-5">
          <h1 className="text-center mb-4">{t("title")}</h1>
          <h5 className="text-center mb-4">{t("identify yourself")}</h5>
          <Row className="justify-content-md-center">
            {isComingFromSSO ? (
              <Col className="d-flex flex-column justify-content-center align-items-center">
                <Spinner />
              </Col>
            ) : (
              <Col sm="7" className="px-4 mx-auto">
                <Stack gap={3}>
                  <Button
                    onClick={doSSO}
                    size="lg"
                    variant={showLocalAccountLogin ? "outline-dark" : "dark"}
                    className="text-center mx-4"
                  >
                    {t("sso")}
                  </Button>
                  <div className="text-center ">{t("or")}</div>
                  {showLocalAccountLogin ? (
                    <Form onSubmit={handleSubmit(doLogin)}>
                      <fieldset disabled={submitting}>
                        <Input
                          className="mb-3"
                          label={t("email")}
                          autoFocus
                          autoComplete="username"
                          formControlProps={{
                            "aria-describedby": "email-icon",
                          }}
                          inputGroupBefore={
                            <InputGroup.Text id="email-icon">
                              <EmailIcon />
                            </InputGroup.Text>
                          }
                          {...getInputProps("email", register, schema, errors)}
                          type={INPUT_TYPES_ENUM.EMAIL}
                        />
                        <PasswordInput
                          className="mb-4"
                          {...getInputProps(
                            "password",
                            register,
                            schema,
                            errors
                          )}
                          label={t("password")}
                        />
                        {errorMessage && (
                          <Row>
                            <Col>
                              <Alert variant="danger">{t(errorMessage)}</Alert>
                            </Col>
                          </Row>
                        )}
                        <Row className="mx-0">
                          <Button
                            size="lg"
                            variant="dark"
                            type="submit"
                            className="text-center mx-4 col mb-4"
                            disabled={submitting}
                          >
                            {submitting ? (
                              <>
                                {t("connecting...")}
                                <Spinner size="sm" className="ms-2" />
                              </>
                            ) : (
                              <>{t("login")}</>
                            )}
                          </Button>
                        </Row>
                      </fieldset>
                    </Form>
                  ) : (
                    <Button
                      onClick={() => setShowLocalAccountLogin(true)}
                      size="lg"
                      variant="outline-primary"
                      className="text-center mx-4"
                      data-test={ifTest("local account login")}
                    >
                      {t("local account login")}
                    </Button>
                  )}
                </Stack>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
