import { useRef, useState } from "react";

import { useIsFirstRender } from "./useIsFirstRender";
import { doArraysHaveDifferentValues } from "./utils";

export function useHasChanged(...args: any[]) {
  const [previous, setPrevious] = useState(args);
  const isFirstRender = useIsFirstRender();
  if (isFirstRender || doArraysHaveDifferentValues(args, previous)) {
    setPrevious(args);
    return true;
  }
  return false;
}

export function useHasChangedRef(...args: any[]) {
  const previous = useRef(args);
  const isFirstRender = useIsFirstRender();
  if (isFirstRender || doArraysHaveDifferentValues(args, previous.current)) {
    previous.current = args;
    return true;
  }
  return false;
}
