import { IDocumentData } from "../shared/types/document.types";
import { IPriceNode } from "../shared/types/priceNode.types";
import {
  IRowTypeInstruction,
  ROW_TYPE_INSTRUCTIONS_ENUM,
} from "../shared/types/rowInstruction.types";

export function splitCellId(id: string) {
  return id.split("_").slice(1).join("_").split(".").slice(0, -1).join(".");
}

export function buildRowNumberDict(
  dict: { [row_number: string]: IPriceNode },
  row?: IPriceNode
) {
  if (row) {
    dict[row.row_number] = row;
    row.children?.forEach((row) => buildRowNumberDict(dict, row));
  }
}

export function applyRowTypeInstructions<T extends IDocumentData>(
  document: T,
  instructions?: IRowTypeInstruction[]
) {
  if (!instructions) {
    return document;
  }

  instructions = instructions.filter((instruction) =>
    [ROW_TYPE_INSTRUCTIONS_ENUM.SET_TYPE].includes(instruction.type)
  );
  if (!instructions.length) {
    return document;
  }
  const dict: { [row_number: string]: IPriceNode } = {};

  buildRowNumberDict(dict, document.data);

  instructions.forEach((instruction) => {
    instruction.subject_rows?.forEach((rowNumber) => {
      const priceNode = dict[rowNumber];
      if (!priceNode) {
        return;
      }
      // set type, null as fallback
      priceNode.type =
        instruction.detail?.type === null
          ? null
          : instruction.detail?.type ?? priceNode.type;
      // set instruction type
      priceNode.instructionType = instruction.type ?? priceNode.instructionType;
      // set quantity_std
      priceNode.quantity_std =
        instruction.detail?.quantity_std ?? priceNode.quantity_std;
      // set price_std
      priceNode.price_std =
        instruction.detail?.price_std ?? priceNode.price_std;
    });
  });
  return document;
}
