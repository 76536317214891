import { UseMutationResult } from "@tanstack/react-query";
import { ExpandedState, Row as RTRow } from "@tanstack/react-table";
import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import { IoIosLogOut } from "react-icons/io";
import { MdDeleteForever, MdLink, MdLinkOff, MdMoveUp } from "react-icons/md";

import colors from "../../shared/_exports.module.scss";
import { useTranslation } from "../../shared/i18n";
import { CheckedCircleIcon, CloseCircledIcon } from "../../shared/icons";
import { Table } from "../../shared/table";
import {
  IDocumentData,
  TDocumentDataWithTotal,
} from "../../shared/types/document.types";
import { IPriceEstimate } from "../../shared/types/estimate.types";
import {
  ILogs,
  ILogsMap,
  IMappingLog,
  LOG_LEVEL_ENUM,
  LOG_TYPE_ENUM,
} from "../../shared/types/log.types";
import {
  IRowInstruction,
  ROW_INSTRUCTIONS_ENUM,
} from "../../shared/types/rowInstruction.types";
import { ITender } from "../../shared/types/tender.types";
import {
  useDocumentDataQuery,
  useDocumentQuery,
} from "../../shared/useSharedQueries";
import { cancelEvent, ifTest, isDevelopmentEnv } from "../../shared/utils";
import { ICompareDifferenceSettings } from "../Compare.types";
import { defaultCompareDifferenceSettings } from "../CompareDifference";
import compareTableStyles from "../CompareTable/CompareTable.module.scss";
import { ICompareRow } from "../CompareTable/CompareTable.types";
import {
  applyLogs,
  applyLogsGeneric,
  getCategoryLevel,
  getRowDepth,
  isCategoryRow,
} from "../CompareTable/CompareTableUtils";
import {
  VARIANT_AND_OPTIONS_ID,
  VARIANT_AND_OPTIONS_SPACER_ID,
  isHeadingId,
  useCompareTable,
} from "../CompareTable/useCompareTable";
import { TABLE_COLUMN_DATA_TYPE_ENUM } from "../Table.types";

import { MoveActionContextMenu } from "./MoveActionContextMenu";
import { PriceNodeEditHistoryButton } from "./PriceNodeEditHistoryButton";
import styles from "./PriceNodeEditTable.module.scss";

enum PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM {
  SELECT_TENDER_ROW = "select tender row",
  MOVE = "MOVE",
}
type PriceNodeEditActions =
  | ROW_INSTRUCTIONS_ENUM
  | PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM;

const actionsThatNeedsToSelectRows = [
  PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.SELECT_TENDER_ROW,
  ROW_INSTRUCTIONS_ENUM.ASSOCIATE,
  PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.MOVE,
];

const actionsThatAreCompletedOnButtonClick: ROW_INSTRUCTIONS_ENUM[] =
  Object.values(ROW_INSTRUCTIONS_ENUM).filter(
    (action) => !actionsThatNeedsToSelectRows.includes(action)
  );
function isActionDoneOnButtonClick(
  action: ROW_INSTRUCTIONS_ENUM | PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM
): action is ROW_INSTRUCTIONS_ENUM {
  return actionsThatAreCompletedOnButtonClick.includes(
    action as ROW_INSTRUCTIONS_ENUM
  );
}

const tenderPriceNodeEditActionButtons = [
  {
    action: ROW_INSTRUCTIONS_ENUM.ASSOCIATE,
    variant: "outline-success",
    icon: <MdLink />,
  },
  {
    action: ROW_INSTRUCTIONS_ENUM.DISSOCIATE,
    variant: "outline-warning",
    icon: <MdLinkOff />,
  },
  {
    action: PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.MOVE,
    variant: "outline-dark",
    icon: <MdMoveUp />,
  },
  {
    action: ROW_INSTRUCTIONS_ENUM.UNPARENT,
    variant: "outline-warning",
    icon: <IoIosLogOut />,
  },
  {
    action: ROW_INSTRUCTIONS_ENUM.DELETE,
    variant: "outline-danger",
    icon: <MdDeleteForever />,
  },
];

const documentPriceNodeEditActionButtons = [
  {
    action: PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.MOVE,
    variant: "outline-dark",
    icon: <MdMoveUp />,
  },
  {
    action: ROW_INSTRUCTIONS_ENUM.DELETE,
    variant: "outline-danger",
    icon: <MdDeleteForever />,
  },
  {
    action: ROW_INSTRUCTIONS_ENUM.UNPARENT,
    variant: "outline-warning",
    icon: <IoIosLogOut />,
  },
];

const deactivatedDifferenceSettings: ICompareDifferenceSettings = {
  line_difference_active: false,
  number_difference: {
    ...defaultCompareDifferenceSettings.number_difference,
    active: false,
  },
};

const emptyTenderStableList: ITender[] = [];

/**
 * helper function to test if a rowId is amongst the descendants of the row for a given tenderId
 * @param rowId the id of the row to check
 * @param tenderId the id of the tender for which to check
 * @param row the row to check against
 * @returns
 */
function isThisRowIdAChildRow(
  rowId: string,
  tenderId: string,
  row: RTRow<ICompareRow>
): boolean {
  if (row.original.id === rowId) {
    return true;
  }
  if (!row.subRows || !row.original.tenders[tenderId]) {
    return false;
  }
  return row.subRows.some((subRow) =>
    isThisRowIdAChildRow(rowId, tenderId, subRow)
  );
}

const LOG_TYPES_TO_DISPLAY = [
  LOG_TYPE_ENUM.LINE_ERASED,
  LOG_TYPE_ENUM.CALCULATED_TOTAL_DIFFERS,
  LOG_TYPE_ENUM.MANUAL_TOTAL_DIFFERS,
  LOG_TYPE_ENUM.NOMENCLATURE_ALREADY_SET,
  LOG_TYPE_ENUM.NOMENCLATURE_ALREADY_SET_IGNORE,
  LOG_TYPE_ENUM.NOMENCLATURE_ALREADY_SET_MODIFY,
  LOG_TYPE_ENUM.COLUMN_TYPE,
];

/** map logs from array form to a map structure
 * used to help apply logs onto the correct IPriceNode */
export function mapLogs(document?: TDocumentDataWithTotal): [ILogsMap, ILogs] {
  const map: ILogsMap = {};
  const genericLogs: ILogs = { INFO: [], WARNING: [], ERROR: [], DEBUG: [] };
  if (!document) {
    return [map, genericLogs];
  }

  // generate warning to be displayed on total line if:
  // the user inputed estimation_amount is different from the computed total_price
  if (document.estimation_amount !== document.data?.total_price) {
    map[-1] = { INFO: [], WARNING: [], ERROR: [], DEBUG: [] };
    map[-1].WARNING.push({
      row_number: -1,
      level: LOG_LEVEL_ENUM.WARNING,
      type: LOG_TYPE_ENUM.MANUAL_TOTAL_DIFFERS,
      message: `The manual TOTAL differs from the computed one: ${document.estimation_amount} != ${document.data?.total_price}`,
      parameters: [
        String(document.estimation_amount),
        String(document.data?.total_price),
      ],
    });
  }

  [
    ...(document?.import_logs ?? []),
    ...(document?.instruction_logs ?? []),
    ...((document as unknown as { logs: IMappingLog[] })?.logs ?? []),
  ]
    .filter(
      // filter logs to display by type included in LOG_TYPES_TO_DISPLAY, unless overriden
      (log) =>
        (window as any).DISPLAY_ALL_LOGS ||
        isDevelopmentEnv ||
        LOG_TYPES_TO_DISPLAY.includes(log.type)
    )
    .filter(
      // filter logs based on parameters
      (log) => {
        switch (log.type) {
          // only display LINE_ERASED lines if there is a designation
          case LOG_TYPE_ENUM.LINE_ERASED:
            return String(log.parameters?.[1]).length > 0;
          default:
            return true;
        }
      }
    )
    .forEach((log) => {
      // handle generic logs or LINE_ERASED logs since they have no existing line anymore
      if (isNaN(log.row_number) || log.row_number === null) {
        genericLogs[log.level].push(log);
      } else if (log.type === LOG_TYPE_ENUM.LINE_ERASED) {
        // only display LINE_ERASED logs if they're about a total line to avoid spam
        if (!log.parameters?.[1]?.toLowerCase().includes("total")) {
          genericLogs[log.level].push(log);
        }
      } else {
        if (!map[log.row_number]) {
          map[log.row_number] = { INFO: [], WARNING: [], ERROR: [], DEBUG: [] };
        }
        map[log.row_number][log.level].push(log);
      }
    });
  return [map, genericLogs];
}

export function PriceNodeEditTable({
  base,
  documentId,
  isTender,
  compareColumnDisplaySettings,
  rowInstructionsMutation,
  onSave,
  initialExpanded,
}: {
  base?: IPriceEstimate;
  // pass in the id to be able to easily refetch the updated version
  documentId: string;
  isTender: boolean;
  compareColumnDisplaySettings: TABLE_COLUMN_DATA_TYPE_ENUM[];
  rowInstructionsMutation: UseMutationResult<
    IDocumentData,
    unknown,
    IRowInstruction[] | undefined,
    unknown
  >;
  onSave(save: boolean | void): void;
  initialExpanded: ExpandedState;
}) {
  const { t } = useTranslation("PriceNodeEditTable");
  const documentQuery = useDocumentQuery(documentId, isTender);
  const documentDataQuery = useDocumentDataQuery(documentId, isTender);

  const [logMap, genericLogs] = useMemo(
    () =>
      mapLogs({
        ...documentQuery.data,
        data: documentDataQuery.data,
      } as TDocumentDataWithTotal),
    [documentQuery.data, documentDataQuery.data]
  );

  const stableTenderList = useMemo(
    () =>
      documentQuery.data && documentDataQuery.data && logMap
        ? [
            applyLogs(
              { ...documentQuery.data, data: documentDataQuery.data },
              logMap
            ),
          ].map((document) => applyLogsGeneric(document, genericLogs))
        : emptyTenderStableList,
    [documentDataQuery.data, documentQuery.data, logMap, genericLogs]
  );
  const [editedDocument] = stableTenderList;

  const table = useCompareTable({
    baseEstimate: base,
    comparedDocuments: stableTenderList,
    compareDifferenceSettings: deactivatedDifferenceSettings,
    compareColumnDisplaySettings: [
      ...compareColumnDisplaySettings,
      TABLE_COLUMN_DATA_TYPE_ENUM.LOGS,
    ],
    initialExpanded,
    editEnabled: TABLE_COLUMN_DATA_TYPE_ENUM.TENDER,
    keepVariantOrOption: true,
  });

  const [currentAction, setCurrentAction] = useState<PriceNodeEditActions>(
    PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.SELECT_TENDER_ROW
  );

  const [selectedRows, setSelectedRows] = useState<RTRow<ICompareRow>[]>([]);
  const [moveActionTargetRow, setMoveActionTargetRow] =
    useState<RTRow<ICompareRow>>();

  const [
    showMoveActionContextMenuAtCoordinates,
    setShowMoveActionContextMenuAtCoordinates,
  ] = useState<{ x: number; y: number }>();

  const resetState = useCallback(() => {
    setSelectedRows([]);
    setCurrentAction(PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.SELECT_TENDER_ROW);
    setShowMoveActionContextMenuAtCoordinates(undefined);
    setMoveActionTargetRow(undefined);
  }, []);

  const canSelectRow = useCallback(
    (row: RTRow<ICompareRow>) => {
      if (isHeadingId(row.original.id)) {
        return false;
      }
      if (row.original.is_variant_or_option) {
        return false;
      }
      switch (currentAction) {
        case PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.SELECT_TENDER_ROW:
          // this row needs to have a tender PriceNode linked
          return row.original.tenders[documentId];
        case ROW_INSTRUCTIONS_ENUM.ASSOCIATE:
          // row needs to have an estimate and be free of tender PriceNode to be paired
          return row.original.estimate && !row.original.tenders[documentId];
        case PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.MOVE:
          // row can't be a child of a selectedRow
          return !selectedRows.some((selectedRow) =>
            isThisRowIdAChildRow(row.original.id, documentId, selectedRow)
          );
        case ROW_INSTRUCTIONS_ENUM.DELETE:
        case ROW_INSTRUCTIONS_ENUM.DISSOCIATE:
        case ROW_INSTRUCTIONS_ENUM.UNPARENT:
        // fallthrough
        default:
          // no new selection needed
          return false;
      }
    },
    [documentId, currentAction, selectedRows]
  );
  const toggleSourceRowSelection = useCallback(
    (toggledRow: RTRow<ICompareRow>) =>
      setSelectedRows((rows) =>
        rows.includes(toggledRow)
          ? rows.filter((row) => row !== toggledRow)
          : rows.concat(toggledRow)
      ),
    []
  );

  const isActionDisabled = useCallback(
    (action: PriceNodeEditActions): boolean => {
      if (
        ![
          PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.SELECT_TENDER_ROW,
          action,
        ].includes(currentAction)
      ) {
        // disable button if we're not in selection mode or action isn't the active one
        return true;
      }
      const [firstSelectedRow] = selectedRows;
      switch (action) {
        case ROW_INSTRUCTIONS_ENUM.ASSOCIATE:
          // we need one row that doesn't have an estimate attached to it
          return (
            selectedRows.length !== 1 ||
            firstSelectedRow.original.estimate !== undefined
          );
        case ROW_INSTRUCTIONS_ENUM.DISSOCIATE:
          // we need rows that have an estimate attached to it
          return (
            selectedRows.length < 1 ||
            selectedRows.some((row) => row.original.estimate === undefined)
          );
        case ROW_INSTRUCTIONS_ENUM.DELETE:
        case PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.MOVE:
          // we need at least one element to delete or move
          // we can't have an estimate line selected, DISSOCIATE is first required
          return (
            selectedRows.length < 1 ||
            selectedRows.some((row) => row.original.estimate !== undefined)
          );
        case ROW_INSTRUCTIONS_ENUM.UNPARENT:
          return (
            // we need at least one element to unparent
            // we can't have a document line without any subrows, or any row being the descendant of another selected row
            selectedRows.length < 1 ||
            selectedRows.some(
              (selectedRow, index) =>
                selectedRow.subRows.length < 1 ||
                selectedRows
                  .slice(index + 1)
                  .some((row) =>
                    isThisRowIdAChildRow(
                      row.original.id,
                      documentId,
                      selectedRow
                    )
                  )
            )
          );
        default:
          // no new selection needed
          return true;
      }
    },
    [selectedRows, currentAction, documentId]
  );

  const getRowClassName = useCallback(
    (row: RTRow<ICompareRow>) => {
      const isCategory = isCategoryRow(row);
      const level =
        table.options.meta?.maxDepth &&
        getCategoryLevel(row, table.options.meta.maxDepth);
      const isRowSelectable = canSelectRow(row);
      return classNames(
        compareTableStyles[`level-${level}`],
        styles[`depth-${getRowDepth(table, row)}`],
        {
          [compareTableStyles["category-row"]]: isCategory,
          [styles.hoverable]: isRowSelectable,
          [styles.disabled]:
            !isRowSelectable &&
            actionsThatNeedsToSelectRows.includes(currentAction),
          [styles.selected]: selectedRows.includes(row),
          [compareTableStyles["variants-and-options-header"]]:
            row.original.id === VARIANT_AND_OPTIONS_ID,
          [compareTableStyles["empty-row"]]:
            row.original.id === VARIANT_AND_OPTIONS_SPACER_ID,
        }
      );
    },
    [canSelectRow, currentAction, selectedRows, table]
  );

  const addRowInstruction = useCallback(
    (action: ROW_INSTRUCTIONS_ENUM, target_row?: number) => {
      if (editedDocument?.mapping !== undefined) {
        rowInstructionsMutation.mutate([
          ...(editedDocument.mapping.row_instructions ?? []),
          {
            type: action,
            subject_rows: selectedRows.map(
              (row) => row.original.tenders[documentId].row_number
            ),
            target_row,
            creation_date: new Date(),
          },
        ]);
        resetState();
      }
    },
    [
      editedDocument,
      rowInstructionsMutation,
      selectedRows,
      resetState,
      documentId,
    ]
  );

  const handleRowClick = useCallback(
    (
      row: RTRow<ICompareRow>,
      rowIndex: number,
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
    ) => {
      if (canSelectRow(row)) {
        cancelEvent(event);
        switch (currentAction) {
          case ROW_INSTRUCTIONS_ENUM.ASSOCIATE:
            addRowInstruction(
              ROW_INSTRUCTIONS_ENUM.ASSOCIATE,
              row.original.estimate?.row_number
            );
            break;
          case PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.MOVE:
            setShowMoveActionContextMenuAtCoordinates({
              x: event.clientX,
              y: event.clientY,
            });
            setMoveActionTargetRow(row);
            break;
          case PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.SELECT_TENDER_ROW:
            toggleSourceRowSelection(row);
        }
      }
    },
    [addRowInstruction, canSelectRow, currentAction, toggleSourceRowSelection]
  );

  const handleActionButtonClick = (
    action: ROW_INSTRUCTIONS_ENUM | PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM
  ) => {
    if (action !== currentAction) {
      if (isActionDoneOnButtonClick(action)) {
        addRowInstruction(action);
      } else {
        setCurrentAction(action);
      }
    }
  };

  const isFetching =
    rowInstructionsMutation.isLoading || documentDataQuery.isFetching;
  return (
    <>
      <h6 className="text-center mb-4">
        {t(
          `action messages.${isTender ? "tender" : "estimate"}.${currentAction}`
        )}
      </h6>
      <Button
        className="position-absolute top-0 end-0 mt-2 me-2 fs-7"
        onClick={() => onSave()}
      >
        <CheckedCircleIcon className="me-2" />
        {t("finish")}
      </Button>
      <div className="position-relative">
        <div
          className={classNames(styles.sticky, "ms-4 position-absolute top-0")}
          role="toolbar"
        >
          <PriceNodeEditHistoryButton
            instructions={editedDocument?.mapping?.row_instructions}
            rowInstructionsMutation={rowInstructionsMutation}
            resetState={resetState}
          />
          {(isTender
            ? tenderPriceNodeEditActionButtons
            : documentPriceNodeEditActionButtons
          ).map(({ action, variant, icon }) => {
            const disabled = isActionDisabled(action);
            const active = currentAction === action;
            return (
              <OverlayTrigger
                key={action}
                placement="bottom"
                overlay={<Tooltip>{t(`action tooltips.${action}`)}</Tooltip>}
              >
                <Button
                  data-test={ifTest(`price-node-${action}-button`)}
                  variant={variant}
                  onClick={() => handleActionButtonClick(action)}
                  className={classNames("rectangular fs-5 px-2 py-1 me-3", {
                    [styles["disabled-action"]]: disabled,
                    active,
                    disabled,
                  })}
                  size="lg"
                  disabled={disabled}
                  aria-pressed={active}
                >
                  {icon}
                </Button>
              </OverlayTrigger>
            );
          })}
          {(selectedRows.length > 0 ||
            currentAction !==
              PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.SELECT_TENDER_ROW) && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  {t(
                    `action tooltips.${
                      currentAction ===
                      PRICE_NODE_EDIT_EXTRA_ACTIONS_ENUM.SELECT_TENDER_ROW
                        ? "empty selection"
                        : "cancel"
                    }`
                  )}
                </Tooltip>
              }
            >
              <Button
                data-test={ifTest(`price-node-cancel-button`)}
                variant="text"
                onClick={resetState}
                className="p-2 me-3"
                size="lg"
              >
                <CloseCircledIcon fill={colors.dark} />
              </Button>
            </OverlayTrigger>
          )}
        </div>
        {moveActionTargetRow && (
          <MoveActionContextMenu
            coordinates={showMoveActionContextMenuAtCoordinates}
            onClose={() => setShowMoveActionContextMenuAtCoordinates(undefined)}
            targetRowHasTender={Boolean(
              moveActionTargetRow.original.tenders[documentId]
            )}
            onActionClick={(action) => {
              addRowInstruction(
                action,
                moveActionTargetRow.original.tenders[documentId]?.row_number ??
                  moveActionTargetRow.original.estimate?.row_number
              );
            }}
          />
        )}
        {isFetching && (
          <div className="position-absolute bottom-0 start-0 d-flex h-100 w-100 justify-content-center align-items-center bg-white bg-opacity-50 min-h-100 z-index-dropdown">
            <Spinner variant="dark" className="m-3" />
          </div>
        )}
        <div
          className={classNames(
            styles["table-wrapper"],
            "scroller mw-100 position-relative"
          )}
        >
          <Table
            table={table}
            className={classNames(
              compareTableStyles.table,
              compareTableStyles["table-empty-first-header"]
            )}
            getRowClassName={getRowClassName}
            onRowClick={handleRowClick}
            noPagination
            stickyHeaders
          />
        </div>
      </div>
    </>
  );
}
