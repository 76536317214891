import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function ArrowSmallRightIcon({
  className = "",
  fill = styles.white,
  size = 24,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
        fill={fill}
      />
    </svg>
  );
}
