import { Cell, Row as RowDef } from "@tanstack/react-table";

import { ITableRow } from "../../../compare/Table.types";
import colors from "../../_exports.module.scss";
import { TTranslateFunction, formatCurrency } from "../../i18n";
import { ICompany } from "../../types/company.types";
import { ITender } from "../../types/tender.types";
import {
  DIFFERENCE_MODE_ENUM,
  getDifference,
} from "../../visualization/Difference";

export function isCompany(company: ITender | ICompany): company is ICompany {
  return (company as ICompany).tenders !== undefined;
}

export function getRowSelectionFromSelectedTenderIds(
  rows: RowDef<ICompany | ITender>[],
  selectedTenderIds?: string[]
) {
  return (
    selectedTenderIds
      ?.map((tenderId) => rows.find((row) => row.original.id === tenderId)?.id)
      .reduce(
        (state, id) =>
          id !== undefined ? Object.assign(state, { [id]: true }) : state,
        {}
      ) ?? {}
  );
}

export function onRowClick(row: RowDef<any>) {
  if (row.getCanExpand()) {
    row.toggleExpanded();
  } else {
    row.toggleSelected();
  }
}

export enum TABLE_COLUMN_DATA_TYPE_ENUM {
  NAME = "NAME",
  DIFF_MOE = "DIFF_ESTIMATION",
  DIFF_LOWEST = "DIFF_LOWEST",
  TOTAL = "TOTAL",
}

export function mapCell(
  cell: Cell<ITableRow<unknown>, unknown>,
  t: TTranslateFunction,
  estimation?: number,
  lowestEstimation?: number
) {
  const original = cell.row.original as ITender | ICompany;
  switch (cell.column.columnDef.meta?.dataType) {
    case TABLE_COLUMN_DATA_TYPE_ENUM.NAME:
      if (isCompany(original)) {
        return original.name;
      } else {
        const isBest = original.estimation_amount === lowestEstimation;
        return `${isBest ? "⭐ " : "     "}${t("version", {
          version: original.version,
        })}`;
      }
    case TABLE_COLUMN_DATA_TYPE_ENUM.DIFF_MOE:
      if (isCompany(original) && estimation) {
        return getDifference(
          cell.getValue<number>(),
          estimation,
          DIFFERENCE_MODE_ENUM.PERCENT
        );
      } else {
        return "";
      }
    case TABLE_COLUMN_DATA_TYPE_ENUM.DIFF_LOWEST:
      if (isCompany(original) && lowestEstimation) {
        return getDifference(
          cell.getValue<number>(),
          lowestEstimation,
          DIFFERENCE_MODE_ENUM.EURO
        );
      } else {
        return "";
      }
    case TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL:
      return formatCurrency(cell.getValue<number>());
    default:
      return cell.getValue<any>()?.value ?? cell.getValue() ?? "";
  }
}

export function getCellColor(
  cell: Cell<ITableRow<unknown>, unknown>,
  estimation?: number,
  lowestEstimation?: number
): string {
  const original = cell.row.original as ITender | ICompany;
  const value = cell.getValue<number>();
  switch (cell.column.columnDef.meta?.dataType) {
    case TABLE_COLUMN_DATA_TYPE_ENUM.DIFF_MOE:
      if (isCompany(original) && estimation) {
        if (value > estimation) {
          return colors.red600;
        } else if (value < estimation) {
          return colors.green1000;
        }
      }
      return colors.black;
    case TABLE_COLUMN_DATA_TYPE_ENUM.DIFF_LOWEST:
      if (isCompany(original) && lowestEstimation) {
        if (value > lowestEstimation) {
          return colors.red600;
        } else if (value < lowestEstimation) {
          return colors.green1000;
        }
      }
      return colors.black;
    case TABLE_COLUMN_DATA_TYPE_ENUM.NAME:
    case TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL:
    default:
      return colors.black;
  }
}
