import React from "react";

import { useEnumTranslation, useTranslation } from "../shared/i18n";
import { Select } from "../shared/inputs";
import { ROWTYPE_USAGE_ENUM } from "../shared/types/rowType.types";

import { IMappingUsageSelectProps } from "./mapping.types";

export const MappingUsageSelect = React.forwardRef<
  HTMLInputElement,
  IMappingUsageSelectProps
>(function MappingUsageSelect(
  { onChange, onBlur, value, closeMenuOnSelect = false, className },
  ref
) {
  const { tEnum } = useEnumTranslation();
  const { t } = useTranslation("MappingUsageSelect");

  const useOptions = Object.values(ROWTYPE_USAGE_ENUM).map((value) => ({
    value: value,
    label: tEnum(value),
  }));

  return (
    <Select
      options={useOptions}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      ref={ref}
      searchable
      multi
      closeMenuOnSelect={closeMenuOnSelect}
      autoFocus
      placeholder={t("select usage")}
      className={className}
    />
  );
});
