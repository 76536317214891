import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { formatCurrency, useTranslation } from "../../shared/i18n";
import { formatTolerant } from "../../shared/i18n/dateFormatter";
import { Select } from "../../shared/inputs";
import { ICompany } from "../../shared/types/company.types";
import { ITender } from "../../shared/types/tender.types";
import { useTendersQuery } from "../../shared/useSharedQueries";

export function TenderSelect({
  tenders,
  onSelect,
  className,
}: {
  tenders?: ITender[];
  onSelect(tender?: ITender): void;
  className?: string;
}) {
  const { t } = useTranslation("TenderSelect");
  const { lotId } = useParams();
  const select = (companies: ICompany[]) =>
    companies.reduce(
      (tenderList, { tenders }) => tenderList.concat(tenders),
      [] as ITender[]
    );
  const tendersQuery = useTendersQuery(lotId, {
    select,
    enabled: Boolean(lotId && !tenders),
  });

  const options = useMemo(
    () =>
      (tenders ? tenders : tendersQuery.data ?? []).map((tender) => ({
        value: tender.id,
        label: (
          <>
            <b>
              {tender.company_name}{" "}
              {t("document version prefix", { version: tender.version })}
            </b>
            <span className="float-end">
              {formatTolerant(tender.upload_date, "Pp")} -{" "}
              {formatCurrency(tender.estimation_amount)}
            </span>
          </>
        ),
        tender,
      })),
    [tenders, tendersQuery.data, t]
  );
  return (
    <Select
      options={options}
      onChange={(tenderId, option) => onSelect((option as any).tender)}
      className={className}
    />
  );
}
