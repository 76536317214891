import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function AddCircledIcon({
  className = "",
  fill = styles.white,
  size = 21,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 21 21"
      fill="none"
      className={className}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M.5 10c0-5.52 4.48-10 10-10s10 4.48 10 10-4.48 10-10 10-10-4.48-10-10Zm11 1h4V9h-4V5h-2v4h-4v2h4v4h2v-4Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
