import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import variables from "../shared/_exports.module.scss";
import { DocumentsIcon } from "../shared/icons";
import { ifTest } from "../shared/utils";

import styles from "./DocumentCard.module.scss";

export interface IFolderCardProps {
  file_name: string;
  id: string;
  onClick?: (id: string, event: React.MouseEvent) => void;
}

export function FolderCard({ file_name, id, onClick }: IFolderCardProps) {
  return (
    <>
      <Container
        fluid
        className={styles.document}
        onClick={(event) => onClick?.(id, event)}
        role="button"
        data-test={ifTest("folder")}
      >
        <Row>
          <Col className="ps-0 text-center">
            <DocumentsIcon
              className="m-5 mb-4"
              size={64}
              fill={variables.blue200}
            />
          </Col>
          <hr className="mb-2" />
        </Row>
        <Row className="mb-1">
          <Col className="ps-3 my-1">
            <div className="fw-normal mb-3 mt-2">{file_name}</div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
