import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";

import { updateAllotment } from "../../api/fetchLots";
import { DefaultModal } from "../../shared/DefaultModal";
import { ILot } from "../../shared/types/lot.types";
import { QUERY_KEYS_ENUM } from "../../shared/useSharedQueries";

import { ConfirmClosure } from "./ConfirmClosure";
import { ConventionalAllotmentForm } from "./ConventionalAllotmentForm";
import { SelectCompanyForm } from "./SelectCompanyForm";

export interface ILotClosureModalProps {
  show: boolean;
  handleClose: (save: boolean | void) => void;
  defaultValues: ILot;
  readOnly: boolean;
  requiredSelectedCompany?: boolean;
}

export function LotClosureModal({
  show,
  handleClose,
  defaultValues,
  readOnly,
  requiredSelectedCompany,
}: ILotClosureModalProps) {
  const [lot, setLot] = useState<ILot>(
    JSON.parse(JSON.stringify(defaultValues))
  );
  const [step, setStep] = useState(0);

  const queryClient = useQueryClient();

  const priceEstimateEdition = useMutation({
    mutationFn: updateAllotment,
    onSuccess: (lot) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.LOT, lot.id],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.LOTS, lot.project],
      });
      handleClose();
    },
  });

  const onFormSubmit = useCallback(
    (value?: Partial<ILot>) => {
      if (value) {
        setLot((lot) => ({ ...lot, ...value }));
      }
      setStep((step) => {
        // in readOnly mode the modal gets closed after step 1 since the lot closure confirmation step isn't required
        if (readOnly && step === 1) {
          handleClose();
        }
        return step + 1;
      });
    },
    [handleClose, readOnly]
  );

  return (
    <DefaultModal
      show={show}
      handleClose={handleClose}
      size={step === 2 ? undefined : "lg"}
      staticBackdrop
    >
      {step === 0 && (
        <ConventionalAllotmentForm
          lot={lot}
          onSubmit={onFormSubmit}
          onClose={onFormSubmit}
          readOnly={readOnly}
        />
      )}
      {step === 1 && (
        <SelectCompanyForm
          lot={lot}
          onPrevious={() => setStep((step) => step - 1)}
          onSubmit={onFormSubmit}
          onClose={onFormSubmit}
          readOnly={readOnly}
          requiredSelectedCompany={requiredSelectedCompany}
        />
      )}
      {step === 2 && (
        <ConfirmClosure
          onSubmit={() => {
            priceEstimateEdition.mutate(lot);
          }}
          isLoading={priceEstimateEdition.isLoading}
        />
      )}
    </DefaultModal>
  );
}
