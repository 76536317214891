import { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { useHasChanged } from "../../useHasChanged";
import { usePrevious } from "../../usePrevious";

import {
  ACTION_TYPES_ENUM,
  IInitSelectionAction,
  IOption,
  ISelectState,
  TAction,
  TValue,
  isInitSelectionAction,
  isMultiOnChange,
  isMultiValue,
  isSelectAction,
  isSingleOnChange,
  onSelect,
  onSelectMulti,
} from "./select.types";

function selectionReducer(state: ISelectState, action: TAction) {
  // we only manipulate an array containing selection
  let selection = state.selection;
  const isOptionSelected = (option: IOption) =>
    selection?.some(({ value }) => value === option.value);
  switch (action.type) {
    case ACTION_TYPES_ENUM.SELECT:
      if (
        isSelectAction(action) &&
        action.option &&
        !isOptionSelected(action.option)
      ) {
        if (!state.multi) {
          selection = [action.option];
        } else {
          selection = selection.concat([action.option]);
        }
      }
      break;
    case ACTION_TYPES_ENUM.DESELECT:
      if (
        isSelectAction(action) &&
        action.option &&
        isOptionSelected(action.option)
      ) {
        selection = selection.filter(
          ({ value }) => value !== action.option.value
        );
      }
      break;
    case ACTION_TYPES_ENUM.CLEAR:
      if (selection.length > 0) {
        selection = [];
      }
      break;
    case ACTION_TYPES_ENUM.INIT_SELECTION:
      // we init selection after reducer was as async fetch may be necessary to get values
      return {
        ...state,
        selection: (action as IInitSelectionAction).newSelection,
      };
    case ACTION_TYPES_ENUM.UPDATE_SELECTION:
      if (isInitSelectionAction(action)) {
        // controlled component where its value is directly passed as prop
        selection = action.newSelection;
      }
      break;
    default:
      throw new Error(
        `dispatched action type ${action["type"]} isn't recognized`
      );
  }
  // only update on selection change
  if (selection !== state.selection) {
    return { ...state, selection };
  }
  return state;
}
interface IUseSelectBaseConfig {
  getOptionsFromValuesAsync: (values: TValue[]) => Promise<IOption[]>;
}
interface IUseSelectMultiValueConfig extends IUseSelectBaseConfig {
  multi: true;
  onChange: onSelectMulti;
  defaultValue?: TValue[];
  value?: TValue[];
}

interface IUseSelectSingleValueConfig extends IUseSelectBaseConfig {
  multi?: false;
  onChange: onSelect;
  defaultValue?: TValue;
  value?: TValue;
}
export type TUseSelectConfig =
  | IUseSelectMultiValueConfig
  | IUseSelectSingleValueConfig;
/**
 * Reducer hook that manage a selection
 */
export function useSelect({
  onChange,
  multi = false,
  value,
  defaultValue,
  getOptionsFromValuesAsync,
}: TUseSelectConfig): [IOption[], Dispatch<TAction>] {
  const [{ selection }, selectionDispatch] = useReducer(selectionReducer, {
    selection: [],
    multi,
  });
  const prevSelection = usePrevious(selection);

  // init state asynchronously
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted) {
      if (defaultValue !== undefined) {
        getOptionsFromValuesAsync(
          isMultiValue(multi, defaultValue) ? defaultValue : [defaultValue]
        ).then((newSelection) => {
          selectionDispatch({
            type: ACTION_TYPES_ENUM.INIT_SELECTION,
            newSelection,
          } as IInitSelectionAction);
          setMounted(true);
        });
      } else {
        setMounted(true);
      }
    }
  }, [multi, defaultValue, mounted, getOptionsFromValuesAsync]);

  const selectedValuesRef = useRef<TValue[]>([]);
  if (useHasChanged(selection)) {
    selectedValuesRef.current = selection.map(({ value }) => value);
  }

  if (useHasChanged(value)) {
    const isNewValueDifferentThanCurrent = isMultiValue(multi, value)
      ? selectedValuesRef.current !== value && // check if same ref
        (selectedValuesRef.current?.length !== value?.length ||
          value?.some((value) => !selectedValuesRef.current?.includes(value))) // [A,A] != [B,A]
      : !selectedValuesRef.current?.includes(value!);

    if (isNewValueDifferentThanCurrent) {
      if (value !== undefined) {
        getOptionsFromValuesAsync(
          isMultiValue(multi, value) ? value : [value]
        ).then((newSelection) =>
          selectionDispatch({
            type: ACTION_TYPES_ENUM.UPDATE_SELECTION,
            newSelection,
          })
        );
      } else {
        selectionDispatch({
          type: ACTION_TYPES_ENUM.UPDATE_SELECTION,
          newSelection: [],
        });
      }
    }
  }

  useEffect(() => {
    if (mounted && selection !== prevSelection && onChange !== undefined) {
      if (isMultiOnChange(multi, onChange)) {
        onChange(selectedValuesRef.current, selection);
      }
      if (isSingleOnChange(multi, onChange)) {
        // we return first value as we should only have and manage one
        onChange(
          selectedValuesRef.current.find(() => true),
          selection?.find(() => true)
        );
      }
    }
  }, [selection, prevSelection, multi, onChange, mounted]);

  return [selection, selectionDispatch];
}
