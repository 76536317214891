import React from "react";

import styles from "../_exports.module.scss";
import { ifTest } from "../utils";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function TrashIcon({ color = styles?.red, size = 24, className = "" }) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-test={ifTest("trash-icon")}
    >
      <path
        d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5ZM6 6v14h12V6H6Zm3 3h2v8H9V9Zm4 0h2v8h-2V9Z"
        fill={color}
      />
    </svg>
  );
}
