import { FocusEventHandler, ReactElement, ReactNode } from "react";
import { renderToStaticMarkup } from "react-dom/server";

// Base types
export type TValue = string | number;

export type TLabel = ReactElement | string;

export function getLabelAsString(label: TLabel): string {
  if (typeof label === "string") {
    return label;
  }
  return renderToStaticMarkup(label);
}

export interface IOption<T = any> {
  value: TValue;
  label?: TLabel;
  disabled?: boolean;
  /** anything more you'd like in this Option */
  meta?: T;
}

export interface IOptionGroup {
  disabled?: boolean;
  label: TLabel;
  options: IOption[];
}

export type TOptions = IOption[] | IOptionGroup[];

export type onSelect = (
  selection?: TValue | TValue[],
  selectionOptions?: IOption | IOption[]
) => void;

export type onSelectMulti = (
  selection?: TValue[],
  selectionOptions?: IOption[]
) => void;

// Select Reducer
export enum ACTION_TYPES_ENUM {
  SELECT = "select",
  DESELECT = "deselect",
  CLEAR = "clear",
  INIT_SELECTION = "init-selection",
  UPDATE_SELECTION = "update-selection",
}

export interface ISelectState {
  selection: IOption[];
  multi: boolean;
}

export interface IAction {
  type: ACTION_TYPES_ENUM;
}

export interface ISelectAction extends IAction {
  option: IOption;
}

export interface IInitSelectionAction extends IAction {
  type: ACTION_TYPES_ENUM.INIT_SELECTION | ACTION_TYPES_ENUM.UPDATE_SELECTION;
  newSelection: IOption[];
}

export type TAction = IAction | ISelectAction | IInitSelectionAction;

// Select props
interface ISelectBaseProps {
  onBlur?: FocusEventHandler<HTMLInputElement>;

  /**
   * an array of Option objects to be selected from
   * this put the Select in sync mode
   * Option should have at least a value as string or number and can have a label and any information you like
   * {value:0, label:"Yes"}
   * Values should be unique as they are used as option's identifier
   */
  options?: TOptions;

  /**
   * a method called on option search
   * it receives searched value as first argument and should return a Promise that will fulfill with selectable Options or OptionGroups
   * this put Select in Async mode and shouldn't be used with the options prop
   */
  loadOptions?(value: TValue): Promise<TOptions>;

  /**
   * placeholder in Control when selection's empty
   */
  placeholder?: ReactNode;

  /**
   * function called to render a selected Option's label in Control
   * get Option object as argument
   * return a node
   */
  formatControlOption?: (option: IOption) => ReactNode;

  /**
   * function called to render all selected Option's label in Control
   * get Options object as argument
   * return a node
   */
  formatControlOptions?: (options: IOption[]) => ReactNode;

  /**
   * function called to render an Option's label in Select's Menu
   * get Option object as argument
   * return a node
   */
  formatOptionLabel?: (option: IOption) => ReactNode;

  /**
   * A component that will be rendered left side of Control
   */
  prefixIcon?: ReactNode;

  /**
   * className given to top rendered Select element (div.select) */
  className?: string;

  /**
   * give Select focus on mount */
  autoFocus?: boolean;

  /**
   * Is selection clearable (by a button clear icon at Control's right)
   */
  clearable?: boolean;

  /**
   * Are Options searchable (can be filtered) on value or label
   * Add a text input in Control
   * Activated in case of Async (as we couldn't load Options if it wasn't)
   */
  searchable?: boolean;

  /**
   * Optional search function to filter/sort options
   */
  searchFn?(options: TOptions, searchedValue: string): TOptions;

  /**
   * Minimum number of characters for the search to trigger an API call
   */
  charMin?: number;

  /**
   * fill color value that should be use for Select icons */
  iconColor?: string;

  /**
   * Disable Select */
  disabled?: boolean;

  /**
   * Hide selected Options when user is searching,
   * useful on limited width Select */
  hideValueOnSearch?: boolean;

  /**
   * Show selected Options on top of Menu list */
  showSelectionInMenu?: boolean;

  /**
   * Enable to select the search value */
  searchValueAsOption?: boolean;

  /**
   * If searchValueAsOption is true, will be called to format searched string as a selectable Option
   */
  formatSearchValueAsOption?: (search: string) => TLabel;

  /**
   * Selecting an Option closes the Menu */
  closeMenuOnSelect?: boolean;

  /**
   * Default options to replace initial loadOptions
   */
  defaultOptions?: any[];

  /**
   * Show invalid state on Select
   */
  isInvalid?: boolean;

  /**
   * Callback on on option focus
   */
  onFocusOption?: (value?: TValue) => void;

  /**
   * Display the Select options horizontally
   */
  horizontal?: boolean;

  /**
   * Display the Select in a compact manner
   */
  compact?: boolean;

  /**
   * update select options when input options change
   */
  updateOnOptionsChange?: boolean;
}

export interface ISelectMultiValueProps extends ISelectBaseProps {
  /**
   * Is user allowed to select more than one Option?
   * If true onChange callback will receive values and options Array
   * and value or defaultValue should be Arrays */
  multi: true;

  /**
   * called on every selection change
   * with the selected value string or undefined as first argument (or an array of value or undefined if multi is set to true)
   * and selected option object or undefined as second argument (or an array if multi is set to true)
   */
  onChange?: onSelectMulti;

  /**
   * value(s) that should be pre selected when component mount,
   * Only use on uncontrolled mode (without giving a value prop),
   * Select manage its state and you only listen to its changes,
   * a value is option.value identifier
   */
  defaultValue?: TValue[];

  /**
   * selected value(s),
   * Only use on controlled mode (without having a defaultValue),
   * You control Select selection state,
   * a value is option.value identifier
   */
  value?: TValue[];
}

export interface ISelectSingleValueProps extends ISelectBaseProps {
  /**
   * Is user allowed to select more than one Option?
   * If true onChange callback will receive values and options Array
   * and value or defaultValue should be Arrays */
  multi?: false;

  /**
   * called on every selection change
   * with the selected value string or undefined as first argument (or an array of value or undefined if multi is set to true)
   * and selected option object or undefined as second argument (or an array if multi is set to true)
   */
  onChange?: onSelect;

  /**
   * value(s) that should be pre selected when component mount,
   * Only use on uncontrolled mode (without giving a value prop),
   * Select manage its state and you only listen to its changes,
   * a value is option.value identifier
   */
  defaultValue?: TValue;

  /**
   * selected value(s),
   * Only use on controlled mode (without having a defaultValue),
   * You control Select selection state,
   * a value is option.value identifier
   */
  value?: TValue;
}

export type TSelectProps = ISelectMultiValueProps | ISelectSingleValueProps;

// Type Guards
export function isSelectAction(action: IAction): action is ISelectAction {
  return ["select", "deselect"].includes(action.type);
}

export function isInitSelectionAction(
  action: IAction
): action is IInitSelectionAction {
  return ["init-selection", "update-selection"].includes(action.type);
}

export function isMultiValue(
  multi: boolean,
  value?: TValue | TValue[]
): value is TValue[] {
  return multi;
}

export function isMultiOnChange(
  multi: boolean,
  onChange: onSelect | onSelectMulti
): onChange is onSelectMulti {
  return multi;
}

export function isSingleOnChange(
  multi: boolean,
  onChange: onSelect | onSelectMulti
): onChange is onSelect {
  return !multi;
}

export function isOptionGroup(
  option: IOption | IOptionGroup
): option is IOptionGroup {
  return Boolean((option as IOptionGroup).options);
}

export function isOptionGroups(options?: TOptions): options is IOptionGroup[] {
  return Boolean(options?.some(isOptionGroup));
}
