import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function FilterIcon({ className = "", fill = styles.black, size = 24 }) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M21 4.5v2h-1l-6 9v7h-4v-7l-6-9H3v-2h18Z" fill={fill} />
    </svg>
  );
}
