import classNames from "classnames";
import React from "react";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";

import version from "../../package.json";
import { useOrganizationNameRights } from "../account/UserOrganizationGuard";

import brandStyles from "./Brand.module.scss";
import styles from "./_exports.module.scss";
import { useTranslation } from "./i18n";
import { Logo } from "./icons/Logo";
import { PATH_NAMES_ENUM } from "./pathNames";

interface IApp {
  to: string;
  label: string;
  /**
   * Can the user access the app?
   * If undefined, the app is considered accessible by every user
   * Is a hook which gets called in the same order each time by the Component.
   */
  usePermissionCallback?: () => boolean;
}

const apps: IApp[] = [
  {
    to: `/${PATH_NAMES_ENUM.OPERATIONS}`,
    label: "price'estim",
  },
  {
    to: `/${PATH_NAMES_ENUM.PRICE_AVERAGE}`,
    label: "price average",
    // For now hide app for standard users until the app is ready to be public.
    // Note: access is still possible by entering the correct URL.
    usePermissionCallback: () => {
      const { invalid, isLoading } = useOrganizationNameRights(
        window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION
      );
      return !isLoading && !invalid;
    },
  },
  {
    to: `/${PATH_NAMES_ENUM.MAPPING}`,
    label: "mapping",
    usePermissionCallback: () => {
      const { invalid, isLoading } = useOrganizationNameRights(
        window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION
      );
      return !isLoading && !invalid;
    },
  },
];
// prevent changing the list of apps to avoid side effects when using hooks with usePermissionCallback
Object.freeze(apps);

export function Brand({
  logoColor = styles?.primary,
  nameColor,
  small = false,
  className,
  variant,
}: {
  nameColor?: string;
  small?: boolean;
  logoColor?: string;
  className?: string;
  variant?: string;
}) {
  nameColor = nameColor ?? logoColor;
  const { t } = useTranslation("Brand");
  const isVariantDark = variant === "dark";

  const authorizedApps = apps.filter(
    (app) => !app.usePermissionCallback || app.usePermissionCallback()
  );

  const location = useLocation();
  const currentApp =
    authorizedApps.find(
      (app) =>
        location.pathname.startsWith(app.to) ||
        location.search.includes(app.to.slice(1))
    ) ?? authorizedApps[0];

  const currentAppLabel = (
    <span
      style={{ color: nameColor, fontSize: "22px" }}
      className="d-md-block ms-2"
    >
      {t(currentApp.label)}
    </span>
  );

  return (
    <div
      className={classNames(
        "d-flex justify-content-start align-items-center ps-0 ps-md-4",
        className
      )}
    >
      <Collapse in={!small} dimension="width" className="d-none d-md-block">
        <Link to={currentApp.to}>
          <Logo fill={logoColor} />
        </Link>
      </Collapse>
      <Row>
        <Col>
          <Nav className={brandStyles.dropdown}>
            {authorizedApps.length < 2 ? (
              currentAppLabel
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  className={classNames(
                    "mt-0 py-0 d-flex align-items-center justify-content-center",
                    { "text-white": isVariantDark }
                  )}
                  style={{ height: "14px" }}
                  variant="text"
                >
                  {currentAppLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={classNames(
                    "dropdown-menu",
                    isVariantDark ? "text-bg-dark" : "bg-light"
                  )}
                >
                  {authorizedApps
                    .filter((app) => app !== currentApp)
                    .map((app) => (
                      <React.Fragment key={app.label}>
                        <Dropdown.Item className="px-3" as={Link} to={app.to}>
                          <span
                            style={{ color: nameColor, fontSize: "18px" }}
                            className="d-md-block mx-2 fw-bold"
                          >
                            {t(app.label)}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Divider className="mx-3 my-0" />
                      </React.Fragment>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
          <Link to={currentApp.to}>
            <div className="ms-2" style={{ color: nameColor }}>
              {t("v.", { version: version.version })}
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
}
