import classNames from "classnames";
import React, { useMemo, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { GuardedButton } from "../account/GuardedButton";
import { PriceNodeEditModal } from "../compare/PriceNodeEditModal";
import { defaultCompareColumnDisplaySettings } from "../compare/useCompareColumnDisplaySettings";
import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { useModal } from "../shared/DefaultModal";
import { useTranslation } from "../shared/i18n";
import { AddCircledIcon, CrownIcon, ListCircleIcon } from "../shared/icons";
import { IPriceEstimate } from "../shared/types/estimate.types";
import { useLotsQuery } from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";
import { ifTest } from "../shared/utils";

import { LotMappingModal } from "./LotMappingModal";
import styles from "./LotsPage.module.scss";
import { OngoingLotsTable } from "./OngoingLotsTable";
import { SelectedLotsTable } from "./SelectedLotsTable";

export function LotsPage() {
  const { operationId } = useParams();
  const navigate = useNavigate();

  useTrackPageView(
    "Lots",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
    ],
    [operationId]
  );

  const { t } = useTranslation("LotsPage");
  const [priceEstimateToEdit, setPriceEstimateToEdit] =
    useState<IPriceEstimate>();
  const [newLotId, setNewLotId] = useState<string>();
  const { show, handleClose, handleShow } = useModal("LotCreationModal");
  const {
    show: showEditModal,
    handleClose: handleCloseEditModal,
    handleShow: handleShowEditModal,
  } = useModal("MoePriceNodeEditModal");
  const [searchParams, setSearchParams] = useSearchParams();

  const activeKey = searchParams.get("tab") ?? "ongoing";

  const lotsQuery = useLotsQuery(operationId);

  const lots = lotsQuery?.data;

  const isLoading =
    lotsQuery.isLoading || (lotsQuery.isFetching && !lotsQuery.isPreviousData);

  const selectedLots = useMemo(
    () => lots?.filter((lot) => lot.selected_company_name),
    [lots]
  );

  return (
    <Tab.Container
      id="lots-tabs"
      activeKey={activeKey}
      onSelect={(eventKey) => {
        if (eventKey) {
          setSearchParams({ tab: eventKey });
        }
      }}
    >
      <LotMappingModal
        show={show}
        handleClose={(save) => {
          handleClose();
          if (save) {
            setPriceEstimateToEdit(() => save.estimate);
            setNewLotId(() => save.lotId);
            handleShowEditModal();
          }
        }}
        operationId={operationId!}
        key={String(show)}
      />
      <PriceNodeEditModal
        show={showEditModal}
        initialExpanded={{}}
        onClose={() => {
          handleCloseEditModal();
          priceEstimateToEdit && navigate(`./${newLotId}`);
        }}
        documents={[
          {
            ...priceEstimateToEdit!,
            estimation_amount: lots?.find(
              (lot) => lot.id === priceEstimateToEdit?.allotment
            )?.estimation_amount!,
          },
        ]}
        compareColumnDisplaySettings={defaultCompareColumnDisplaySettings}
      />
      <Container fluid>
        <Card className="mx-md-4 w-auto border-0">
          <Card.Body className="p-0 p-md-4">
            <Nav as={Row} className="mb-3 align-items-end">
              <Nav.Item
                as={Col}
                className={classNames(
                  { [styles.border]: activeKey === "ongoing" },
                  styles.borderHover,
                  "d-flex justify-content-center align-items-center text-center p-1 fw-bold"
                )}
              >
                <Nav.Link eventKey="ongoing" className="w-100">
                  <ListCircleIcon className="mx-3" />
                  {t("list of ongoing lots")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                as={Col}
                className={classNames(
                  { [styles.border]: activeKey === "selected" },
                  styles.borderHover,
                  "d-flex justify-content-center align-items-center text-center pb-2"
                )}
              >
                <Nav.Link eventKey="selected" className="w-100">
                  <CrownIcon className="mx-1" />
                  {t("only display selected companies")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="ongoing">
                <OngoingLotsTable
                  lots={lots}
                  isLoading={isLoading}
                  operationId={operationId!}
                />
                <div className="text-center">
                  <GuardedButton
                    size="lg"
                    onClick={handleShow}
                    data-test={ifTest("openLotCreationModal")}
                  >
                    <AddCircledIcon className="mb-1 me-1" /> {t("addOne")}
                  </GuardedButton>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="selected">
                <SelectedLotsTable
                  lots={selectedLots}
                  isLoading={isLoading}
                  operationId={operationId!}
                />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Card>
      </Container>
    </Tab.Container>
  );
}

//TODO: style lots rows
