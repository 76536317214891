import { ReactElement } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Outlet, useNavigate } from "react-router-dom";

import { IRights } from "../shared/types/right.types";
import { useOrganizationsQuery } from "../shared/useSharedQueries";

/**
 * User Guard based on the rights on a given Organization
 * @param organizationRights a list of any permission allowing access
 */
export function UserOrganizationGuard({
  children,
  organizationName,
  organizationRights,
}: {
  children?: ReactElement;
  organizationName: string;
  organizationRights?: Partial<IRights>;
}) {
  const navigate = useNavigate();

  const { invalid, organization } = useOrganizationNameRights(
    organizationName,
    organizationRights
  );

  if (invalid) {
    console.warn("unauthorized user");
    navigate(-1);
  }

  return !organization || invalid ? (
    <Spinner className="position-absolute top-50 start-50" />
  ) : (
    <>
      {children}
      <Outlet />
    </>
  );
}

export function useOrganizationNameRights(
  organizationName: string,
  organizationRights?: Partial<IRights>
) {
  const organizationQuery = useOrganizationsQuery({
    select: (organizations) =>
      organizations?.find(
        ({ name, rights }) =>
          name === organizationName &&
          (!organizationRights ||
            !Object.entries(organizationRights).some(
              ([right, value]) =>
                value > rights[right as "can_read" | "can_write" | "can_admin"]
            ))
      ),
  });
  const organization = organizationQuery.data;

  const invalid = !organizationQuery.isLoading && !organization;

  return { invalid, organization, isLoading: organizationQuery.isLoading };
}
