import { useCallback, useState } from "react";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { loadExcel } from "../excel/loader";
import { useTranslation } from "../i18n";
import { ACCEPT_EXCEL_FILE_FORMAT, ImportFilesButton } from "../inputs";

export function DumpRawExcel() {
  const { t } = useTranslation("dev.DumpRawExcel");
  const [isProcessingFile, setIsProcessingFile] = useState(false);

  const processAndDUmp = useCallback(async (files?: File[]) => {
    if (!files?.length) {
      return;
    }
    setIsProcessingFile(true);
    const reader = new FileReader();
    reader.onload = async function () {
      const fileData = reader.result as Buffer;
      const { rawSheets } = await loadExcel(fileData);
      console.debug(`%c------ ${files[0].name} ------`, "color:green");
      console.debug(
        Math.round(
          new TextEncoder().encode(JSON.stringify(rawSheets)).length / 1024
        ),
        "KB"
      );
      console.debug(rawSheets);
      Object.entries(rawSheets).forEach(([key, value]) => {
        console.debug(`%c------ ${key} ------`, "color:darkgreen");
        console.debug(value());
        console.debug(JSON.stringify(value()));
      });
      setIsProcessingFile(false);
    };
    reader.onerror = () => setIsProcessingFile(false);
    reader.onabort = () => setIsProcessingFile(false);

    reader.readAsBinaryString(files[0]);
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <h3>{t("title")}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <ol>
            <li>{t("hints.1")}</li>
            <li>{t("hints.2")}</li>
            <li>{t("hints.3")}</li>
          </ol>
        </Col>
      </Row>
      <Row>
        <Col>
          <ImportFilesButton
            onData={processAndDUmp}
            buttonText={t("file to dump")}
            accept={ACCEPT_EXCEL_FILE_FORMAT}
            canEdit
          />
        </Col>
        <Col>{isProcessingFile && <Spinner />}</Col>
      </Row>
    </Container>
  );
}
