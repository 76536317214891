import React from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  RectangleProps,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";

import colors from "../shared/_exports.module.scss";
import { useTranslation } from "../shared/i18n";
import { useHoverableTooltip } from "../shared/useHoverableTooltip";

import styles from "./BoxPlot.module.scss";

export type TBoxPlotData = {
  min: number;
  bottomWhisker: number;
  bottomBox: number;
  middleBox: number;
  topBox: number;
  topWhisker: number;
  dots: { value: number; label: string }[];
  dotSize: number;
};

// Original data of boxplot graph
export type TBoxPlot = {
  min: number;
  lowerQuartile: number;
  median: number;
  upperQuartile: number;
  max: number;
  average: number;
  dataSet: { value: number; label: string }[];
};

const HorizontalBar = ({
  x,
  y,
  width,
  height,
  fill,
  strokeWidth,
  lineRef,
  onMouseEnter,
  onMouseLeave,
}: RectangleProps & { fill?: string; strokeWidth?: number; lineRef?: any }) => {
  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      x1={x}
      y1={y}
      x2={x + width}
      y2={y}
      stroke={fill ?? colors.blue}
      strokeWidth={strokeWidth ?? 2}
      ref={lineRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

const VerticalBar = ({
  x,
  y,
  width,
  height,
  fill,
  strokeWidth,
}: RectangleProps & { fill?: string; strokeWidth?: number }) => {
  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      x1={x + width / 2}
      y1={y + height}
      x2={x + width / 2}
      y2={y}
      stroke={fill ?? colors.blue}
      strokeWidth={strokeWidth ?? 2}
    />
  );
};

const AVERAGE_BAR_COLOR = colors.green;
const MEDIAN_BAR_COLOR = colors.blue400;
const TOP_BOX_COLOR = colors.teal100;
const BOTTOM_BOX_COLOR = colors.teal300;
const POINT_FILL_COLOR = colors.blue300;
const POINT_STROKE_COLOR = colors.white;

/** group bars into single stack for rechart */
const BAR_STACK = "a";
const BAR_STROKE_WIDTH = 5;
const DOT_SIZE = 100;

/**
 * BoxPlotChart component using Rechart
 */
// Custom component since Rechart doesn't provide one, see:
// - https://github.com/recharts/recharts/issues/2302
// - https://github.com/recharts/recharts/issues/369
export function BoxPlotChart({
  boxPlot: { min, median, average, max, upperQuartile, lowerQuartile, dataSet },
  minimumNumberOfDatapoints = 3,
}: {
  boxPlot: TBoxPlot;
  minimumNumberOfDatapoints?: number;
}) {
  const { t } = useTranslation("BoxPlotChart");
  const isAverageAboveMedian = average !== undefined ? average > median : false;
  // we use the difference (distance) between each elements to stack them into one chart
  const data: TBoxPlotData = {
    min,
    bottomWhisker: lowerQuartile - min,
    bottomBox: (isAverageAboveMedian ? median : average) - lowerQuartile,
    middleBox: Math.abs(median - average),
    topBox: upperQuartile - (isAverageAboveMedian ? average : median),
    topWhisker: max - upperQuartile,
    dots: dataSet,
    dotSize: DOT_SIZE,
  };

  const {
    target: targetAverage,
    show: showAverage,
    eventHandlers: eventHandlersAverage,
  } = useHoverableTooltip();
  const {
    target: targetMedian,
    show: showMedian,
    eventHandlers: eventHandlersMedian,
  } = useHoverableTooltip();

  if (dataSet.length < minimumNumberOfDatapoints) {
    return <div className="mb-4 ms-4">{t("too few data points")}</div>;
  }

  return (
    <span className={styles["recharts-surface-overflow-visible"]}>
      <Overlay target={targetAverage} show={showAverage} placement="right">
        <Tooltip>{t("average")}</Tooltip>
      </Overlay>
      <Overlay target={targetMedian} show={showMedian} placement="right">
        <Tooltip>{t("median")}</Tooltip>
      </Overlay>
      <ResponsiveContainer minHeight={400} minWidth={200}>
        <ComposedChart data={[data]}>
          <CartesianGrid strokeDasharray="3 3" />
          <Bar stackId={BAR_STACK} dataKey="min" fill="none" />
          <Bar stackId={BAR_STACK} dataKey="bar" shape={<HorizontalBar />} />
          <Bar
            stackId={BAR_STACK}
            dataKey="bottomWhisker"
            shape={<VerticalBar />}
          />
          <Bar
            stackId={BAR_STACK}
            dataKey="bottomBox"
            fill={BOTTOM_BOX_COLOR}
          />
          <Bar
            stackId={BAR_STACK}
            dataKey="bar"
            shape={
              <HorizontalBar
                fill={
                  isAverageAboveMedian ? MEDIAN_BAR_COLOR : AVERAGE_BAR_COLOR
                }
                strokeWidth={BAR_STROKE_WIDTH}
                lineRef={isAverageAboveMedian ? targetMedian : targetAverage}
                {...(isAverageAboveMedian
                  ? eventHandlersMedian
                  : eventHandlersAverage)}
              />
            }
          />
          <Bar
            stackId={BAR_STACK}
            dataKey="middleBox"
            fill={isAverageAboveMedian ? TOP_BOX_COLOR : BOTTOM_BOX_COLOR}
          />
          <Bar
            stackId={BAR_STACK}
            dataKey="bar"
            shape={
              <HorizontalBar
                fill={
                  isAverageAboveMedian ? AVERAGE_BAR_COLOR : MEDIAN_BAR_COLOR
                }
                strokeWidth={BAR_STROKE_WIDTH}
                lineRef={isAverageAboveMedian ? targetAverage : targetMedian}
                {...(isAverageAboveMedian
                  ? eventHandlersAverage
                  : eventHandlersMedian)}
              />
            }
          />
          <Bar stackId={BAR_STACK} dataKey="topBox" fill={TOP_BOX_COLOR} />
          <Bar
            stackId={BAR_STACK}
            dataKey="topWhisker"
            shape={<VerticalBar />}
          />
          <Bar stackId={BAR_STACK} dataKey="bar" shape={<HorizontalBar />} />
          <ZAxis type="number" dataKey="dotSize" range={[0, data.dotSize]} />
          {dataSet.map((dot, index) => (
            <Scatter
              key={index}
              dataKey={`dots.${index}.value`}
              fill={POINT_FILL_COLOR}
              stroke={POINT_STROKE_COLOR}
            >
              <LabelList
                dataKey={`dots.${index}.label`}
                position="right"
                className={styles.dot}
                // move left over the point so hover covers it too
                dx={-20}
              />
            </Scatter>
          ))}
          <XAxis hide />
          <YAxis unit="€" />
        </ComposedChart>
      </ResponsiveContainer>
    </span>
  );
}
