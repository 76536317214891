import { CellContext } from "@tanstack/react-table";
import Mexp from "math-expression-evaluator";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";

import {
  ICompareRow,
  TDesignation,
} from "../compare/CompareTable/CompareTable.types";
import { isHeadingId } from "../compare/CompareTable/useCompareTable";
import { formatNumber, useTranslation } from "../shared/i18n";
import { INPUT_TYPES_ENUM } from "../shared/inputs/input";
import { IOption, TLabel, onSelectMulti } from "../shared/inputs/select";
import { SelectMath } from "../shared/inputs/select/SelectMath";
import { IAttribution } from "../shared/types/priceNode.types";
import {
  IRowTypeInstruction,
  ROW_TYPE_INSTRUCTIONS_ENUM,
} from "../shared/types/rowInstruction.types";
import {
  DEFAULT_EMPTY_VALUE_PLACEHOLDER,
  accessNested,
  cancelEvent,
  setNested,
} from "../shared/utils";

import {
  IInstructions,
  IMappingPriceNode,
  IRowTypeObject,
  IStdPriceMeta,
  IStdQuantityMeta,
} from "./mapping.types";

const mexp = new Mexp();
type AccessorKeyStd = "quantity_std" | "price_std";

export function StandardCell({
  props,
  work_id,
  placeholder,
  accessorKeyStd,
  setInstructions,
  stdValue,
  baseValue,
  onChange,
  cellRow_number,
  options,
  instructionAccessor,
  tooltipMessageOnEmptyPlaceholder,
}: {
  props: CellContext<ICompareRow, IAttribution>;
  work_id: string;
  placeholder: string;
  accessorKeyStd: AccessorKeyStd;
  setInstructions?: Dispatch<SetStateAction<IInstructions>>;
  stdValue?: any[];
  baseValue?: any;
  onChange: (value: any) => void;
  cellRow_number: number;
  options: any[];
  instructionAccessor: string;
  tooltipMessageOnEmptyPlaceholder?: string;
}) {
  const { t } = useTranslation("MappingToolPage");
  const [initialValue, setInitialValue] = useState(stdValue ?? baseValue);
  const formula = stdValue?.[0]?.formula;

  const changeValueAndCreateInstruction = useCallback(
    (value?: number, rows?: number[], formula?: string) => {
      const newValueStd = {
        work_id,
        [accessorKeyStd]: value!,
        row_numbers: rows,
        formula,
      };
      setInitialValue(stdValue);
      onChange([newValueStd]);
      setInstructions?.((instructions) => {
        const dict: { [key: string]: IRowTypeInstruction } =
          accessNested(instructions, instructionAccessor) ?? {};
        const subject_row = cellRow_number;
        const isClassificationRow =
          (props.row.original as unknown as IMappingPriceNode).childs ===
          undefined;
        dict[subject_row] = {
          ...dict[subject_row],
          subject_rows: isClassificationRow ? [] : [subject_row],
          type: isClassificationRow
            ? ROW_TYPE_INSTRUCTIONS_ENUM.SET_CLASSIFICATION_QUANTITY
            : ROW_TYPE_INSTRUCTIONS_ENUM.SET_TYPE,
          detail: {
            ...dict[subject_row]?.detail,
            [accessorKeyStd]: value === undefined ? [] : [newValueStd],
            type: isClassificationRow
              ? (props.row.original as unknown as IRowTypeObject).ref
              : dict[subject_row]?.detail?.type,
          },
          creation_date: new Date(),
        };
        setNested(
          instructions,
          instructionAccessor,
          isClassificationRow ? dict[subject_row] : dict
        );
        return { ...instructions };
      });
    },
    [
      work_id,
      accessorKeyStd,
      stdValue,
      onChange,
      setInstructions,
      instructionAccessor,
      cellRow_number,
      props.row.original,
    ]
  );

  const onInputChange = useCallback<onSelectMulti>(
    (values, options) => {
      if (!isNotArray<IOption<IStdQuantityMeta | IStdPriceMeta>>(options)) {
        if (values?.length === 1) {
          const formula = values[0] as string;
          const option = options?.find((opt) => opt.value === formula);
          //formula
          if (!option?.meta) {
            try {
              const result = (mexp.eval as any)(formula.replace?.(",", "."));
              if (formula !== initialValue?.[0]?.formula) {
                changeValueAndCreateInstruction(result, undefined, formula);
              }
              return;
            } catch (e) {
              console.warn(e);
            }
          }
        }
        // filter out formula options
        const childOptions = options?.filter((option) => Boolean(option?.meta));
        const reducedValue = childOptions?.length
          ? childOptions.reduce(
              (previous, option) =>
                previous + (option.meta?.[accessorKeyStd] ?? 0),
              0
            )
          : undefined;
        if (reducedValue !== initialValue?.[0]?.[accessorKeyStd]) {
          changeValueAndCreateInstruction(
            reducedValue,
            childOptions?.map((option) => option.value as number)
          );
        }
      } else {
        console.error("should be options array");
      }
    },
    [accessorKeyStd, changeValueAndCreateInstruction, initialValue]
  );

  if (
    isHeadingId(props.row.original.id) ||
    (props.row.original as unknown as IMappingPriceNode).unavailable ||
    (!baseValue && !stdValue && !options.length)
  ) {
    return tooltipMessageOnEmptyPlaceholder ? (
      <OverlayTrigger
        overlay={<Tooltip>{tooltipMessageOnEmptyPlaceholder}</Tooltip>}
      >
        <div>{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</div>
      </OverlayTrigger>
    ) : (
      <>{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</>
    );
  }

  // display input if no options are provided
  if (options.length < 1) {
    return (
      <Row className="m-0">
        <Col className="px-0">
          <input
            type={INPUT_TYPES_ENUM.TEXT}
            value={
              stdValue?.[0]?.formula ??
              stdValue?.[0]?.[accessorKeyStd] ??
              baseValue
            }
            onChange={(e) => {
              onChange([{ [accessorKeyStd]: e.target.value }]);
            }}
            onBlur={(e) => {
              try {
                const formula = e.target.value;
                const value = (mexp.eval as any)(formula.replace?.(",", "."));
                if (value !== initialValue) {
                  changeValueAndCreateInstruction(
                    value,
                    undefined,
                    formula !== value ? formula : undefined
                  );
                }
              } catch (error) {
                console.warn(error);
              }
            }}
            className="text-center"
          />
        </Col>
        {stdValue?.[0]?.formula && isNaN(stdValue?.[0]?.formula) && (
          <Col xs="auto" className="align-self-center px-0">
            = {formatNumber(stdValue?.[0]?.[accessorKeyStd])}
          </Col>
        )}
      </Row>
    );
  }

  const value = stdValue?.[0]?.row_numbers ?? [
    formula ?? stdValue?.[0]?.[accessorKeyStd],
  ];

  return (
    <div onClick={cancelEvent}>
      <SelectMath
        metaKey={accessorKeyStd}
        placeholder={t(placeholder)}
        value={value}
        onChange={onInputChange}
        options={options}
        compact
      />
    </div>
  );
}

export function cellRowToStandardOption(
  row: ICompareRow,
  accessorKeyStd: AccessorKeyStd,
  cellAccessorKeyStd: any[] | undefined,
  cellAccessorKeyBase: any,
  cellRow_number: number
): IOption<any> {
  const valueStd = cellAccessorKeyStd;
  let value = valueStd?.reduce
    ? valueStd.reduce(
        (total: number, value_std) => total + value_std[accessorKeyStd],
        0
      )
    : cellAccessorKeyBase;
  value = Math.round(value * 100) / 100;
  return {
    value: cellRow_number,
    label: valueOptionLabel({
      nomenclature: row.nomenclature,
      designation: row.designation,
      value: value!,
    }),
    meta: {
      [accessorKeyStd]: value!,
    },
  };
}

function valueOptionLabel({
  nomenclature,
  designation,
  value,
}: {
  nomenclature?: string;
  designation: TDesignation;
  value: number;
}): TLabel | undefined {
  return (
    <span>
      <>
        {nomenclature} {designation} : <b>{value}</b>
      </>
    </span>
  );
}

function isNotArray<T>(e?: T | T[]): e is T {
  return (e as Array<T>)?.length === undefined;
}
