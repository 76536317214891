import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Control } from "react-hook-form";
import * as yup from "yup";

import { useTranslation } from "../../../shared/i18n";
import { ControlledInput } from "../../../shared/inputs";
import {
  AllotmentSelect,
  CONVENTIONAL_ALLOTMENTS,
} from "../../../shared/inputs/AllotmentSelect";
import { getUndefinedSafeSelectOnChange } from "../../../shared/inputs/input";

export const schema = yup.object({
  conventional_allotments: yup
    .array(
      yup
        .string()
        .required()
        .oneOf(CONVENTIONAL_ALLOTMENTS.flatMap(({ options }) => options))
    )
    .min(1)
    .required(),
});

type TFormInputs = yup.InferType<typeof schema>;

export function CommonAllotment({
  control,
}: {
  control: Control<TFormInputs>;
}) {
  const { t } = useTranslation("LotClosureModal");

  return (
    <>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          label={t("conventional allotments")}
          name="conventional_allotments"
          schema={schema}
          control={control}
          render={({ field }) => (
            <AllotmentSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
      </Row>
    </>
  );
}
