export interface ITableRowMeta {
  /** nested level of the row */
  level?: number;
  /** precomputed id to highlight rows which are different from the estimate */
  lineDifferenceElementId?: string;
}

export interface ITableRow<T> {
  subRows?: T[];
  meta?: {
    /** nested level of the row */
    level?: number;
    /** precomputed id to highlight rows which are different from the estimate */
    lineDifferenceElementId?: string;
  };
}

export const MAX_SUPPORTED_DEPTH = 20;

export enum TABLE_COLUMN_DATA_TYPE_ENUM {
  ESTIMATE = "estimate",
  TENDER = "tender",
  NOMENCLATURE = "nomenclature",
  DESIGNATION = "designation",
  UNIT = "unit",
  QUANTITY = "quantity",
  STANDARD_QUANTITY = "quantity_std",
  STANDARD_PRICE = "price_std",
  ROW_TYPE = "type",
  UNIT_PRICE = "unit_price",
  TOTAL_PRICE = "total_price",
  COMMENTS = "comments",
  DIFFERENCE = "difference",
  LOGS = "logs",
  REFERENCE = "reference",
  PRICE_AVERAGE = "price_average",
  TENDER_PRICE_AVERAGE = "tender_price_average",
}
