import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

import { getFileSize } from "../api/fetchDocuments";
import {
  deleteAllotment,
  deleteAllotmentAttachment,
  updateAllotment,
} from "../api/fetchLots";
import { createAllotmentAttachment } from "../api/fetchLots";
import { PriceNodeEditModal } from "../compare/PriceNodeEditModal";
import { defaultCompareColumnDisplaySettings } from "../compare/useCompareColumnDisplaySettings";
import { DefaultModal, useModal } from "../shared/DefaultModal";
import colors from "../shared/_exports.module.scss";
import { IBackErrors } from "../shared/errors";
import { useTranslation } from "../shared/i18n";
import { CheckedCircleIcon, PenIcon } from "../shared/icons";
import { ACCEPT_EXCEL_FILE_FORMAT, ImportFilesButton } from "../shared/inputs";
import { ConfirmationModal } from "../shared/modals";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { IDocument } from "../shared/types/document.types";
import { IPriceEstimate, isMOE } from "../shared/types/estimate.types";
import { ILot } from "../shared/types/lot.types";
import { useHasChanged } from "../shared/useHasChanged";
import {
  QUERY_KEYS_ENUM,
  useAttachmentsQuery,
  useEstimatesQuery,
  useTendersQuery,
} from "../shared/useSharedQueries";

import styles from "./LotEditionModal.module.scss";
import { LotMappingModal } from "./LotMappingModal";
import { ILotsForm, LotsForm, TLotsFormInputs, isFormValid } from "./LotsForm";

const editStepsToSkip = [0, 1, 3];
export interface ILotEditionModalProps {
  show: boolean;
  handleClose: (save: boolean | void) => void;
  defaultValues: ILot & { id: string; project: string };
}

export function LotEditionModal({
  show,
  handleClose,
  defaultValues,
}: ILotEditionModalProps) {
  const { t } = useTranslation("LotEditionModal");
  const [lotForm, setLotForm] = useState<
    (ILotsForm & { id: string }) | undefined
  >(defaultValues);
  const [frame, setFrame] = useState<File[]>();
  const [newFrame, setNewFrame] = useState<File[]>();
  const [estimate, setEstimate] = useState<File[]>();
  const [priceEstimateToEdit, setPriceEstimateToEdit] =
    useState<IPriceEstimate>();
  const [newEstimate, setNewEstimate] = useState<File[]>();
  const [documents, setDocuments] = useState<File[]>();
  const [percentage, setPercentage] = useState<number | undefined>(undefined);
  const navigate = useNavigate();
  const {
    show: showEditModal,
    handleClose: handleCloseEditModal,
    handleShow: handleShowEditModal,
  } = useModal("MoePriceNodeEditModal");

  const {
    show: showDeletionConfirmation,
    handleClose: handleCloseDeletionConfirmation,
    handleShow: handleShowDeletionConfirmation,
  } = useModal("LotEditionModal");

  const estimatesQuery = useEstimatesQuery(lotForm?.id);
  const tendersQuery = useTendersQuery(lotForm?.id);

  const allotmentDeletion = useMutation({
    mutationFn: deleteAllotment,
    onSuccess: () => {
      // remove deleted lot from the lots for the parent project
      queryClient.setQueryData(
        [QUERY_KEYS_ENUM.LOTS, defaultValues.project],
        (old: ILot[] | undefined) =>
          old?.filter((o) => o.id !== defaultValues.id)
      );
      // navigate back to the project
      navigate(`/${PATH_NAMES_ENUM.OPERATIONS}/${defaultValues.project}`);
      handleClose();
    },
    onError: (e) => {
      console.error(e);
    },
  });

  const queryClient = useQueryClient();

  const priceEstimatesQuery = useEstimatesQuery(defaultValues.id);
  const docs = priceEstimatesQuery.data?.results;
  const docsHasChanged = useHasChanged(docs);
  if (docsHasChanged && docs !== undefined) {
    // setFrame or setEstimate based on file_type
    setFrame([]);
    setEstimate([]);
    docs.forEach(async (doc) => {
      const file = { name: doc.file_name, id: doc.id } as {
        name: string;
        size: number | undefined;
        id: string;
      };
      const setEstimateOrFrame = () =>
        (isMOE(doc) ? setEstimate : setFrame)([file as unknown as File]);
      setEstimateOrFrame();
      if (doc.file_name) {
        file.size = Number(await getFileSize(doc));
        // to refresh display
        setEstimateOrFrame();
      }
    });
  }

  const allotmentAttachmentsQuery = useAttachmentsQuery(defaultValues.id);
  const attachments = allotmentAttachmentsQuery.data;
  const attachmentsHasChanged = useHasChanged(attachments);
  // map attachments and get sizes
  if (attachmentsHasChanged && attachments !== undefined) {
    Promise.all(
      attachments.map(async (attachment) => {
        const file = {
          name: attachment.file_name,
          id: attachment.id,
        } as any;
        if (attachment.file_name) {
          const size = await getFileSize(attachment);
          file.size = Number(size);
        }
        return file;
      })
    ).then(setDocuments);
  }

  const allotmentAttachmentCreation = useMutation({
    mutationFn: ({
      document,
      file,
    }: {
      document: Partial<IDocument>;
      file: File;
    }) => createAllotmentAttachment(document, file, setPercentage),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.ATTACHMENTS, defaultValues.id],
      });
      setPercentage(undefined);
    },
    onError: () => {
      setPercentage(undefined);
    },
  });

  const priceEstimateEdition = useMutation({
    mutationFn: updateAllotment,
    onSuccess: (lot) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.LOT, lot.id],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.LOTS, lot.project],
      });
      handleClose();
    },
  });

  const onSubmit = useCallback(() => {
    priceEstimateEdition.mutate(lotForm!);
  }, [lotForm, priceEstimateEdition]);

  const showFrameModal = Boolean(newFrame?.length);
  const showEstimationModal = Boolean(newEstimate?.length);

  const isSubmitting = priceEstimateEdition.isLoading;

  return (
    <>
      <ConfirmationModal
        title={t("delete lot name", { name: lotForm?.name })}
        show={showDeletionConfirmation}
        handleClose={(confirm: boolean | void) => {
          confirm
            ? allotmentDeletion.mutate(lotForm!.id)
            : handleCloseDeletionConfirmation();
        }}
        isSubmitting={allotmentDeletion.isLoading}
      />
      {showFrameModal && (
        <LotMappingModal
          show
          handleClose={(save) => {
            save && setFrame(newFrame);
            setNewFrame(undefined);
          }}
          operationId={defaultValues.project}
          lotId={defaultValues.id}
          estimation={defaultValues.estimation_amount}
          isFrame
          defaultFiles={newFrame}
          stepsToSkip={editStepsToSkip}
          priceEstimateId={((frame?.[0] || estimate?.[0]) as any)?.id}
          isEditing
        />
      )}
      {showEstimationModal && (
        <LotMappingModal
          show
          handleClose={(save) => {
            save && setEstimate(newEstimate);
            setNewEstimate(undefined);
          }}
          operationId={defaultValues.project}
          lotId={defaultValues.id}
          estimation={defaultValues.estimation_amount}
          isFrame={false}
          defaultFiles={newEstimate}
          stepsToSkip={editStepsToSkip}
          priceEstimateId={((estimate?.[0] || frame?.[0]) as any)?.id}
          isEditing
        />
      )}
      <PriceNodeEditModal
        show={showEditModal}
        initialExpanded={{}}
        onClose={(v) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS_ENUM.ESTIMATES, lotForm!.id],
          });
          setPriceEstimateToEdit(undefined);
          handleCloseEditModal();
        }}
        documents={[
          {
            ...priceEstimateToEdit!,
            estimation_amount: lotForm?.estimation_amount!,
          },
        ]}
        compareColumnDisplaySettings={defaultCompareColumnDisplaySettings}
      />
      <DefaultModal
        show={show && !showFrameModal && !showEstimationModal}
        title={t("title")}
        handleClose={handleClose}
        staticBackdrop
        size="lg"
        footer={
          <>
            <Button
              size="lg"
              onClick={handleShowDeletionConfirmation}
              className={classNames("rectangular ms-5", styles.gray)}
            >
              {t("delete lot")}
            </Button>
            <Button
              size="lg"
              variant="primary"
              onClick={onSubmit}
              className="rectangular ms-4"
              disabled={!isFormValid(lotForm) || isSubmitting}
            >
              {t("save changes")}
              {isSubmitting ? (
                <Spinner size="sm" className="ms-2" />
              ) : (
                <CheckedCircleIcon className="ms-2" />
              )}
            </Button>
          </>
        }
      >
        <div className="mx-5">
          <h5 className="mb-4 pb-2">
            <u>{t("lot informations")}</u>
          </h5>
          <LotsForm
            onData={setLotForm}
            defaultValues={lotForm}
            onSubmit={onSubmit}
            backErrors={
              priceEstimateEdition.error as IBackErrors<TLotsFormInputs>
            }
          />
          <Row className="mt-5 mb-4 pb-2">
            <Col>
              <h5>
                <u>{t("MOE")}</u>
              </h5>
            </Col>
            <Col>
              <Button
                variant="outline-secondary"
                className="rounded"
                onClick={async () => {
                  setPriceEstimateToEdit(estimatesQuery.data?.results[0]);
                  handleShowEditModal();
                }}
                disabled={
                  estimatesQuery.isLoading ||
                  tendersQuery.isLoading ||
                  Boolean(tendersQuery.data?.length)
                }
              >
                {estimatesQuery.isLoading || tendersQuery.isLoading ? (
                  <Spinner size="sm" className="me-2" />
                ) : (
                  <PenIcon fill={colors.black} size={20} className="me-2" />
                )}
                {t("edit MOE")}
              </Button>
            </Col>
          </Row>
          <Row className="mx-0 gap-5">
            {!Boolean(estimate?.length) && (
              <Col className="px-0">
                <h6>{t("frame MOE")}</h6>
                {!priceEstimatesQuery.isLoading && frame ? (
                  <ImportFilesButton
                    initialData={frame}
                    onData={setNewFrame}
                    buttonText={t("add frame")}
                    accept={ACCEPT_EXCEL_FILE_FORMAT}
                    disabled={Boolean(frame.length)}
                  />
                ) : (
                  <Spinner />
                )}
              </Col>
            )}
            <Col className="px-0">
              <h6>{t("estimate MOE")}</h6>
              {!priceEstimatesQuery.isLoading && estimate ? (
                <ImportFilesButton
                  initialData={estimate}
                  onData={setNewEstimate}
                  buttonText={t("add estimate")}
                  accept={ACCEPT_EXCEL_FILE_FORMAT}
                  disabled={Boolean(estimate.length)}
                />
              ) : (
                <Spinner />
              )}
            </Col>
          </Row>
          <Row className="mt-4 mx-0 gap-3">
            <Col className="px-0">
              <h6>{t("documents")}</h6>
              {!allotmentAttachmentsQuery.isLoading && documents ? (
                <ImportFilesButton
                  multiple
                  initialData={documents}
                  onDrop={(files: File[]) => {
                    if (files) {
                      setPercentage((percentage) => percentage ?? 0);
                    }
                    return Promise.all(
                      files.map((file) =>
                        allotmentAttachmentCreation
                          .mutateAsync({
                            document: {
                              allotment: defaultValues.id,
                              upload_date: new Date(),
                            },
                            file,
                          })
                          .then((attachment) => ({
                            name: attachment.file_name,
                            id: attachment.id,
                            size: file.size,
                          }))
                      )
                    );
                  }}
                  onRemove={(file: any) =>
                    file.id &&
                    deleteAllotmentAttachment(file.id).then(() =>
                      queryClient.invalidateQueries({
                        queryKey: [
                          QUERY_KEYS_ENUM.ATTACHMENTS,
                          defaultValues.id,
                        ],
                      })
                    )
                  }
                  buttonText={t("add documents")}
                  className={styles.maxHeight}
                  disabled={percentage !== undefined && percentage < 100}
                  percentage={percentage! < 100 ? percentage : undefined}
                />
              ) : (
                <Spinner />
              )}
            </Col>
            <Col></Col>
          </Row>
        </div>
      </DefaultModal>
    </>
  );
}
