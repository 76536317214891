import { ICompanyDTO } from "../shared/types/company.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";

import { HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE } from "./APIUtils";
import { FETCH_METHODS_ENUM, authRequestJSON } from "./fetch";

export async function fetchCompanies() {
  const result = await authRequestJSON<IPaginatedQueryResults<ICompanyDTO>>(
    `company/?${new URLSearchParams({
      page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
    })}`
  );
  return result.results;
}

export async function fetchCompany({ id }: { id: string }) {
  const result = await authRequestJSON<ICompanyDTO>(`company/${id}/`);
  return result;
}

export async function createCompany({
  location_osm_id,
  location_name,
  location_housenumber,
  location_street,
  location_postcode,
  location_city,
  location_district,
  location_state,
  location_country,
  location_country_code,
  location_geometry,
  name,
}: Partial<ICompanyDTO>) {
  const result = await authRequestJSON<ICompanyDTO>(`company/`, {
    method: FETCH_METHODS_ENUM.POST,
    body: JSON.stringify({
      location_osm_id,
      location_name,
      location_housenumber,
      location_street,
      location_postcode,
      location_city,
      location_district,
      location_state,
      location_country,
      location_country_code,
      location_geometry,
      name,
    }),
  });

  return result;
}
