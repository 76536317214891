import { useMatomo } from "@jonkoops/matomo-tracker-react";
import classNames from "classnames";
import React, { ReactNode, useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";

import styles from "./DefaultModal.module.scss";
import { ModalCancelButton } from "./ModalCancelButton";
import { ifTest } from "./utils";

export interface IDefaultModalParams {
  title?: ReactNode;
  show: boolean;
  handleClose: (save: boolean | void) => void;
  children?: any;
  footer?: any;
  className?: string;
  staticBackdrop?: boolean;
  size?: "sm" | "lg" | "xl";
  unCancelable?: boolean;
  onKeyUp?: (event: KeyboardEvent) => void;
  onExited?(node: HTMLElement): any;
}

export function DefaultModal({
  title,
  handleClose,
  show,
  children,
  footer,
  className,
  staticBackdrop,
  size,
  unCancelable = false,
  onKeyUp,
  onExited,
}: IDefaultModalParams) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className={className}
        backdrop={staticBackdrop ? "static" : undefined}
        size={size}
        onKeyUp={onKeyUp}
        tabIndex={-1}
        onExited={onExited}
        data-test={ifTest("modal")}
      >
        {title && (
          <Modal.Header
            className={classNames(
              "border-bottom-0 px-4 mx-2 pb-4 justify-content-md-center",
              unCancelable && "pt-5"
            )}
            data-test={ifTest("modal-header")}
          >
            {!unCancelable && (
              <ModalCancelButton onCancel={() => handleClose(false)} />
            )}
            <Modal.Title
              className={classNames(
                "text-center w-100",
                styles["wrap-balance"]
              )}
            >
              {title}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body data-test={ifTest("modal-body")} className="px-4 mx-2 py-0">
          {title ? (
            children
          ) : (
            <>
              <ModalCancelButton onCancel={() => handleClose(false)} />
              {children}
            </>
          )}
        </Modal.Body>
        {footer && (
          <Modal.Footer
            data-test={ifTest("modal-footer")}
            className="justify-content-center border-top-0 px-4 mx-2 py-3"
          >
            {footer}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export function useModal(modalName?: string) {
  const [show, setShow] = useState(false);

  const { trackEvent } = useMatomo();

  const handleClose = useCallback(() => {
    setShow(false);
    if (modalName) {
      trackEvent({
        category: "modal",
        action: `close-${modalName}`,
        name: `${modalName}Close`,
      });
    }
  }, [trackEvent, modalName]);
  const handleShow = useCallback(() => {
    setShow(true);
    if (modalName) {
      trackEvent({
        category: "modal",
        action: `open-${modalName}`,
        name: `${modalName}Open`,
      });
    }
  }, [trackEvent, modalName]);
  return { show, handleClose, handleShow };
}
