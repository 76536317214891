import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function LogoutIcon({
  fill = styles?.primary,
  className = "",
  size = 24,
}) {
  return (
    <svg
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5a2 2 0 0 1-2-2v-4h2v4h14V5H5v4H3V5a2 2 0 0 1 2-2Zm6.5 14-1.41-1.41L12.67 13H3v-2h9.67l-2.58-2.59L11.5 7l5 5-5 5Z"
        fill={fill}
      />
    </svg>
  );
}
