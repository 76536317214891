import { UseMutationResult } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import { MdManageHistory } from "react-icons/md";

import { useTranslation } from "../../shared/i18n";
import { formatTolerant } from "../../shared/i18n/dateFormatter";
import { IDocumentData } from "../../shared/types/document.types";
import { IRowInstruction } from "../../shared/types/rowInstruction.types";
import { useHasChanged } from "../../shared/useHasChanged";
import { doArraysHaveDifferentValues } from "../../shared/utils";

import styles from "./PriceNodeEditHistoryButton.module.scss";

/**
 *
 * @param newInstructions last available instructions from prop
 * @param previousAllNavigableInstructions all navigable instructions (instructions + possibleRedoInstructions) from previous render
 * @returns boolean to indicate if a new instruction was added, indicating that we need to flush possibleRedoInstructions
 */
function wasANewRowInstructionAdded(
  newInstructions: IRowInstruction[],
  previousAllNavigableInstructions: IRowInstruction[]
) {
  return newInstructions.some((instruction, index) => {
    const matchingPreviousInstruction = previousAllNavigableInstructions[index];
    const instructionsAreMatching =
      matchingPreviousInstruction &&
      instruction.type === matchingPreviousInstruction.type &&
      instruction.target_row === matchingPreviousInstruction.target_row &&
      !doArraysHaveDifferentValues(
        instruction.subject_rows,
        matchingPreviousInstruction.subject_rows
      );
    return !instructionsAreMatching;
  });
}

export function PriceNodeEditHistoryButton({
  instructions,
  rowInstructionsMutation,
  resetState,
}: {
  instructions?: IRowInstruction[];
  rowInstructionsMutation: UseMutationResult<
    IDocumentData,
    unknown,
    IRowInstruction[] | undefined,
    unknown
  >;
  resetState(): void;
}) {
  const [possibleRedoInstructions, setPossibleRedoInstructions] = useState<
    IRowInstruction[]
  >([]);
  const [allNavigableInstructions, setAllNavigableInstructions] = useState<
    IRowInstruction[]
  >(instructions ?? []);

  const haveInstructionsChanged = useHasChanged(instructions);
  if (haveInstructionsChanged) {
    if (
      instructions &&
      wasANewRowInstructionAdded(instructions, allNavigableInstructions)
    ) {
      // we need to flush possibleRedoInstruction when an instruction was added outside of this component
      setAllNavigableInstructions(instructions);
      setPossibleRedoInstructions([]);
    } else {
      // otherwise we propose previously canceled instructions to the user so he can choose to redo them
      setAllNavigableInstructions([
        ...(instructions ?? []),
        ...possibleRedoInstructions,
      ]);
    }
  }
  const { t } = useTranslation("PriceNodeEditHistoryButton");
  return (
    <>
      <Dropdown className="d-inline-block">
        <Dropdown.Toggle
          id="price-node-edit-history-button"
          variant="outline-secondary"
          className="p-2 fs-6 me-3"
          disabled={allNavigableInstructions.length === 0}
        >
          <MdManageHistory />
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={classNames(styles["dropdown-menu"], "overflow-auto")}
        >
          <Dropdown.Item
            as={Button}
            variant="text"
            className="px-3"
            onClick={() => {
              resetState();
              rowInstructionsMutation.mutate([]);
              setPossibleRedoInstructions([...allNavigableInstructions]);
            }}
          >
            {t("reset")}
          </Dropdown.Item>
          <Dropdown.Divider />
          <ListGroup as="ol" numbered variant="flush" className="mt-n2">
            {allNavigableInstructions.map((instruction, index) => {
              const isCurrentActiveInstruction =
                instruction === instructions?.at(-1);
              return (
                <ListGroup.Item
                  key={`${index}.${instruction.type}`}
                  as="li"
                  action={!isCurrentActiveInstruction}
                  role={isCurrentActiveInstruction ? undefined : "button"}
                  active={isCurrentActiveInstruction}
                  className={classNames({
                    "text-muted":
                      possibleRedoInstructions.includes(instruction),
                  })}
                  onClick={
                    isCurrentActiveInstruction
                      ? undefined
                      : () => {
                          const instructionsToThisOne =
                            allNavigableInstructions.slice(0, index + 1);
                          const instructionsAfterThisOne =
                            allNavigableInstructions.slice(index + 1);
                          resetState();
                          rowInstructionsMutation.mutate(instructionsToThisOne);
                          setPossibleRedoInstructions(instructionsAfterThisOne);
                        }
                  }
                >
                  <strong>{t(instruction.type)}</strong>
                  {instruction.creation_date && (
                    <small className="ms-1">
                      {formatTolerant(instruction.creation_date, "Pp")}
                    </small>
                  )}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
