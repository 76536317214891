import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";

import { Header } from "../shared/header";
import { useTranslation } from "../shared/i18n";
import { FrontArrowIcon } from "../shared/icons";
import { CONVENTIONAL_ALLOTMENTS_ENUM } from "../shared/types/lot.types";
import { useTrackPageView } from "../shared/useTrackPageView";

const LOTS = Object.values(CONVENTIONAL_ALLOTMENTS_ENUM);

export function MappingLotsPage() {
  useTrackPageView("MappingLots");

  const { t } = useTranslation("MappingLotsPage");
  const { t: tEnum } = useTranslation("enum");

  return (
    <>
      <Header variant="dark" />
      <Container>
        <h2>{t("title")}</h2>
        <h4>{t("list of lots")}</h4>
        <Nav>
          <Row className="w-100">
            <Col>
              {LOTS.map((lot) => (
                <Row className="border-bottom">
                  <Col>
                    <Nav.Link as={NavLink} to={lot}>
                      {tEnum(lot)}
                    </Nav.Link>
                  </Col>
                  <Col xs="auto" className="align-content-center">
                    <FrontArrowIcon />
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Nav>
      </Container>
    </>
  );
}
