import React, { useMemo } from "react";
import { Noop } from "react-hook-form";

import { useEnumTranslation, useTranslation } from "../i18n";
import {
  BUILDING_CATEGORY,
  COMMERCIAL_BUILDING_CATEGORY_ENUM,
  CULTURAL_BUILDING_CATEGORY_ENUM,
  DWELLING_BUILDING_CATEGORY_ENUM,
  EDUCATIONAL_BUILDING_CATEGORY_ENUM,
  HEALTH_BUILDING_CATEGORY_ENUM,
  INDUSTRIAL_BUILDING_CATEGORY_ENUM,
  JUDICIAL_BUILDING_CATEGORY_ENUM,
  LEISURE_BUILDING_CATEGORY_ENUM,
  OFFICE_BUILDING_CATEGORY_ENUM,
  OTHER_BUILDING_CATEGORY_ENUM,
  PARKING_BUILDING_CATEGORY_ENUM,
  SPORT_BUILDING_CATEGORY_ENUM,
} from "../types/operation.types";

import { Select, TValue } from "./select";

const buildingCategoryOptions = [
  {
    label: "OFFICE",
    options: Object.values(OFFICE_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "CULTURAL",
    options: Object.values(CULTURAL_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "INDUSTRIAL",
    options: Object.values(INDUSTRIAL_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "EDUCATIONAL",
    options: Object.values(EDUCATIONAL_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "HEALTH",
    options: Object.values(HEALTH_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "JUDICIAL",
    options: Object.values(JUDICIAL_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "DWELLING",
    options: Object.values(DWELLING_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "PARKING",
    options: Object.values(PARKING_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "COMMERCIAL",
    options: Object.values(COMMERCIAL_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "SPORT",
    options: Object.values(SPORT_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "LEISURE",
    options: Object.values(LEISURE_BUILDING_CATEGORY_ENUM),
  },
  {
    label: "BUILDING",
    options: Object.values(OTHER_BUILDING_CATEGORY_ENUM),
  },
];
export interface IBuildingCategorySelectProps {
  onChange?(value?: TValue | TValue[]): void;
  onBlur?: Noop;
  value?: BUILDING_CATEGORY;
  clearable?: boolean;
}

export const BuildingCategorySelect = React.forwardRef<
  HTMLInputElement,
  IBuildingCategorySelectProps
>(function BuildingCategorySelect({ onChange, onBlur, value, clearable }, ref) {
  const { tEnum } = useEnumTranslation();
  const { t } = useTranslation("BuildingCategorySelect");
  const categoryOptions = useMemo(
    () =>
      buildingCategoryOptions.map(({ label, options }) => ({
        label: t(label),
        options: options.map((value) => ({ value, label: tEnum(value) })),
      })),
    [tEnum, t]
  );
  return (
    <Select
      options={categoryOptions}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      ref={ref}
      clearable={clearable}
      searchable
      hideValueOnSearch
    />
  );
});
