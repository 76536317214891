import { useMatomo } from "@jonkoops/matomo-tracker-react";
import {
  CellContext,
  ColumnDef,
  PaginationState,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { AiOutlineEye } from "react-icons/ai";
import {
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { CUSTOM_ACTION_DIMENSIONS_ENUM } from "../matomo";
import { EnumCategoryListView, EnumListView } from "../shared/EnumListView";
import { StatusBadge } from "../shared/StatusBadge";
import { useTranslation } from "../shared/i18n";
import { formatTolerant } from "../shared/i18n/dateFormatter";
import { SearchIcon } from "../shared/icons";
import { CheckBox } from "../shared/inputs";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { Table } from "../shared/table";
import { IPartialLocation } from "../shared/types/location.types";
import { IOperation, STATUS_ENUM } from "../shared/types/operation.types";
import { useHasChanged } from "../shared/useHasChanged";
import { useOperationsQuery } from "../shared/useSharedQueries";
import {
  DEFAULT_EMPTY_VALUE_PLACEHOLDER,
  cancelEvent,
  isDevelopmentEnv,
} from "../shared/utils";

import {
  AREA_SEARCH_PARAM,
  OperationsColumnFilters,
} from "./OperationsFilters";
import styles from "./OperationsTable.module.scss";

const EnumListCell = (props: CellContext<IOperation, unknown>) => {
  const enumList = props.getValue<string[]>();
  return <EnumListView list={enumList} />;
};

const EnumCategoryListCell = (props: CellContext<IOperation, unknown>) => {
  const enumList = props.getValue<string[]>();
  return (
    <EnumCategoryListView
      list={enumList}
      translationKey={"BuildingCategorySelect"}
    />
  );
};

const StatusCell = (props: CellContext<IOperation, unknown>) => {
  const status = props.getValue<STATUS_ENUM>();
  if (!status) {
    return "";
  }
  return <StatusBadge status={status} />;
};

const LocationCell = (props: CellContext<IOperation, unknown>) => {
  const { t } = useTranslation("LocationCell");
  // we stringify locations to remove duplicate values and have them ready to display
  const locations = [
    ...new Set(
      props
        .getValue<IPartialLocation[]>()
        ?.map(
          ({
            location_city,
            location_country,
            location_name,
            location_postcode,
          }) => {
            if (location_city || location_name) {
              // city can also be stored in name
              return `${location_city ?? location_name}, ${location_country}`;
            }
            if (location_postcode) {
              return `${location_postcode}, ${location_country}`;
            }
            return location_country;
          }
        )
    ),
  ].filter(Boolean);
  if (locations.length === 0) return DEFAULT_EMPTY_VALUE_PLACEHOLDER;
  if (locations.length === 1) return locations[0];
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          {locations.map((locationString, index) => (
            <div key={locationString} className="text-start mb-1">
              {locationString}
              {index !== locations.length - 1 && <hr className="m-0" />}
            </div>
          ))}
        </Tooltip>
      }
    >
      <span>{t("multiple")}</span>
    </OverlayTrigger>
  );
};

const emptyData: IOperation[] = [];

export const SORTING_SEARCH_PARAM = "sorting";
export const SORTING_DESC_SEARCH_PARAM = "sortingDesc";
export const PAGE_SIZE_SEARCH_PARAM = "pageSize";
export const PAGE_INDEX_SEARCH_PARAM = "pageIndex";
export const GLOBAL_FILTER_SEARCH_PARAM = "globalFilter";

export interface IAreaFilterValue {
  min?: number;
  max?: number;
}

export interface IDateFilterValue {
  min?: string;
  max?: string;
}

export interface IColumnFilters {
  [key: string]:
    | string
    | string[]
    | IAreaFilterValue
    | IDateFilterValue
    | undefined;
}

export function isMinMaxValues(
  value?: IAreaFilterValue | IDateFilterValue | string | string[]
): value is IAreaFilterValue | IDateFilterValue {
  return typeof value === "object" && !Array.isArray(value);
}

export function OperationsTable() {
  const { trackEvent } = useMatomo();
  const { t } = useTranslation("OperationsTable");

  const [searchParams, setSearchParams] = useSearchParams();

  const [columnFilters, setColumnFilters] = useState<IColumnFilters>({});

  const operationsColumns: ColumnDef<IOperation>[] = useMemo(
    () => [
      {
        header: t("name"),
        accessorKey: "name",
        id: "name",
      },
      {
        header: t("reference_code"),
        accessorKey: "reference_code",
        id: "reference_code",
      },
      {
        header: t("floor_area"),
        accessorKey: "floor_area",
        id: "floor_area",
        cell: (props) => `${props.getValue()} m²`,
      },
      {
        header: t("building_category"),
        accessorKey: "building_categories",
        id: "building_categories",
        cell: EnumCategoryListCell,
        enableSorting: false,
      },
      {
        header: t("locations"),
        accessorKey: "locations",
        id: "locations",
        cell: LocationCell,
        enableSorting: false,
      },
      {
        header: t("work_type"),
        accessorKey: "work_type",
        id: "work_type",
        cell: EnumListCell,
        enableSorting: false,
      },
      {
        header: t("tender_date"),
        accessorKey: "tender_date",
        id: "tender_date",
        cell: (props) => formatTolerant(props.getValue<Date>(), "yyyy"),
      },
      {
        header: t("status"),
        accessorKey: "status",
        id: "status",
        cell: StatusCell,
        enableSorting: false,
      },
    ],
    [t]
  );
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: searchParams.get(SORTING_SEARCH_PARAM) ?? "tender_date",
      desc: Boolean(searchParams.get(SORTING_DESC_SEARCH_PARAM)),
    },
  ]);

  const [globalFilter, setGlobalFilter] = useState(
    searchParams.get(GLOBAL_FILTER_SEARCH_PARAM)
  );
  const [globalFilterInputValue, setGlobalFilterInputValue] = useState(
    searchParams.get(GLOBAL_FILTER_SEARCH_PARAM)
  );

  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: Number(searchParams.get(PAGE_INDEX_SEARCH_PARAM) ?? 0),
    pageSize: Number(searchParams.get(PAGE_SIZE_SEARCH_PARAM) ?? 15),
  });
  const filtersHaveChanged = useHasChanged(globalFilter, columnFilters);
  if (filtersHaveChanged) {
    // reset pageIndex on filter change
    setPagination(({ pageSize }) => ({ pageSize, pageIndex: 0 }));
  }

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const fetchOperationsOptions = useMemo(() => {
    return {
      pageSize,
      pageIndex,
      sorting,
      globalFilter,
      columnFilters,
    };
  }, [pageSize, pageIndex, globalFilter, sorting, columnFilters]);

  useEffect(() => {
    const { pageSize, pageIndex, sorting, globalFilter, columnFilters } =
      fetchOperationsOptions;
    const params: URLSearchParamsInit = {
      pageSize: String(pageSize),
      pageIndex: String(pageIndex),
    };
    if (globalFilter) {
      params.globalFilter = globalFilter;
    }
    if (sorting?.length > 0) {
      const [{ id, desc }] = sorting;
      params.sorting = id;
      if (desc) {
        params.sortingDesc = "true";
      }
    }

    Object.entries(columnFilters).forEach(([key, value]) => {
      if (isMinMaxValues(value)) {
        if (value.min !== undefined) {
          params[`${key}_gte`] =
            key === AREA_SEARCH_PARAM
              ? String(value.min)
              : String(new Date(value.min).toISOString());
        }
        if (value.max !== undefined) {
          params[`${key}_lte`] =
            key === AREA_SEARCH_PARAM
              ? String(value.max)
              : String(new Date(value.max).toISOString());
        }
      } else {
        if (value) {
          params[key] = value;
        }
      }
    });
    setSearchParams(params, { replace: true });
  }, [fetchOperationsOptions, setSearchParams]);

  const operationQuery = useOperationsQuery(
    fetchOperationsOptions,
    [...searchParams.entries()].join(",")
  );

  useEffect(() => {
    trackEvent({
      category: "OperationsTable",
      action: "search",
      customDimensions: [
        {
          id: CUSTOM_ACTION_DIMENSIONS_ENUM.DATA,
          value: JSON.stringify(fetchOperationsOptions),
        },
      ],
      name: "OperationsTableSearch",
    });
  }, [fetchOperationsOptions, trackEvent]);

  const pageCount = operationQuery.data?.num_pages ?? -1;

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const table = useReactTable<IOperation>({
    columns: operationsColumns,
    data: operationQuery.data?.results ?? emptyData,
    filterFns: {},
    state: {
      sorting,
      pagination,
      columnVisibility,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    debugTable: isDevelopmentEnv,
    pageCount: pageCount ?? -1,
    manualPagination: true,
    manualSorting: true,
  });

  const navigate = useNavigate();

  return (
    <>
      <Row className="align-items-center">
        <Col xs={11} md={6}>
          <Form
            className="row"
            onSubmit={(event) => {
              cancelEvent(event);
              setGlobalFilter(globalFilterInputValue);
            }}
          >
            <Form.Group
              as={Col}
              lg={10}
              md={8}
              xs={"auto"}
              controlId="global-filter"
            >
              <Form.Control
                onChange={(event) =>
                  setGlobalFilterInputValue(event.target.value)
                }
                value={globalFilterInputValue ?? ""}
                placeholder={t("reference number, etc")}
                className={classNames("shadow-sm border-0", {
                  "border-bottom border-info border-2":
                    globalFilterInputValue &&
                    globalFilterInputValue === globalFilter,
                })}
              />
            </Form.Group>
            <Col size="auto" className="ps-0">
              <Button size="sm" type="submit">
                <SearchIcon size={38} />
              </Button>
            </Col>
          </Form>
        </Col>
        <Col xs="auto" className="ms-auto">
          <Dropdown
            className="d-inline-block me-3"
            autoClose="outside"
            id="operations-table-column-visibility-dropdown"
          >
            <Dropdown.Toggle variant="text" className="fw-bold align-top">
              <AiOutlineEye className="me-2 fs-5" />
              {t("column display settings")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {table.getAllLeafColumns().map((column) => (
                <Dropdown.Item
                  key={column.id}
                  as={Button}
                  variant="text"
                  onClick={() => {
                    setColumnVisibility((columns) => ({
                      ...columns,
                      [column.id]: !columns[column.id],
                    }));
                  }}
                  className="px-3 py-2"
                >
                  <CheckBox
                    checked={column.getIsVisible()}
                    className="me-2 d-inline-block"
                  />
                  {column.columnDef.header as string}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <div
            className={classNames(
              styles["switch-container"],
              "p-4 rounded d-inline-flex justify-content-center"
            )}
          >
            {t("moreFilters")}
            <Form.Switch
              className="ms-3"
              onChange={(e) => {
                const checked = e.target.checked;
                setShowMoreFilters(checked);
              }}
              aria-expanded={showMoreFilters}
            />
          </div>
        </Col>
      </Row>

      <Collapse in={showMoreFilters}>
        <OperationsColumnFilters onFilterChange={setColumnFilters} />
      </Collapse>

      <div className="overflow-auto mt-3">
        <Table
          table={table}
          hover
          stickyHeaders
          defaultTheme
          className="mb-0"
          onRowClick={(row, _, event) =>
            event.ctrlKey
              ? window.open(`/${PATH_NAMES_ENUM.OPERATIONS}/${row.original.id}`)
              : navigate(`/${PATH_NAMES_ENUM.OPERATIONS}/${row.original.id}`, {
                  state: { operation: row.original },
                })
          }
          isLoading={
            operationQuery.isLoading ||
            (operationQuery.isFetching && !operationQuery.isPreviousData)
          }
          footerClassName="mb-n6 bg-transparent position-absolute bottom-0 start-50 translate-middle-x"
        />
      </div>
    </>
  );
}
