import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getSetSearchParamsCallbackWithLastUpdatedSearchParams } from "../api/APIUtils";

import { TABLE_COLUMN_DATA_TYPE_ENUM } from "./Table.types";

export const COLUMN_DISPLAY_SEARCH_PARAM = "display_column";

/** only some ColumnDataTypes can have their visibility toggled: */
export const compareColumnHidableDataTypes = Object.freeze([
  TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE,
  TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY,
  TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE,
  TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
  TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS,
  TABLE_COLUMN_DATA_TYPE_ENUM.DIFFERENCE,
  TABLE_COLUMN_DATA_TYPE_ENUM.LOGS,
]);

export const defaultCompareColumnDisplaySettings = [
  TABLE_COLUMN_DATA_TYPE_ENUM.ESTIMATE,
  TABLE_COLUMN_DATA_TYPE_ENUM.QUANTITY,
  TABLE_COLUMN_DATA_TYPE_ENUM.UNIT_PRICE,
  TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL_PRICE,
  TABLE_COLUMN_DATA_TYPE_ENUM.COMMENTS,
  TABLE_COLUMN_DATA_TYPE_ENUM.LOGS,
];

export function useCompareColumnDisplaySettings(
  initialCompareColumnDisplaySettings = defaultCompareColumnDisplaySettings
): [
  TABLE_COLUMN_DATA_TYPE_ENUM[],
  (columnDisplaySettings: TABLE_COLUMN_DATA_TYPE_ENUM[]) => void
] {
  const [searchParams, setSearchParams] = useSearchParams();
  const columnDisplaySearchParam = searchParams.get(
    COLUMN_DISPLAY_SEARCH_PARAM
  );
  const columnDisplaySettings = useMemo(
    () =>
      columnDisplaySearchParam
        ?.split(",")
        .filter((columnDataType) =>
          compareColumnHidableDataTypes.includes(
            columnDataType as TABLE_COLUMN_DATA_TYPE_ENUM
          )
        ) as TABLE_COLUMN_DATA_TYPE_ENUM[],
    [columnDisplaySearchParam]
  );

  const setColumnDisplaySettings = useCallback(
    (columnDisplaySettings: TABLE_COLUMN_DATA_TYPE_ENUM[]) =>
      setSearchParams(
        getSetSearchParamsCallbackWithLastUpdatedSearchParams(
          (searchParams) => {
            if (columnDisplaySettings.length === 0) {
              throw new Error(
                "at least one compare column is needed to display"
              );
            }
            searchParams.set(
              COLUMN_DISPLAY_SEARCH_PARAM,
              [
                ...new Set(
                  columnDisplaySettings.filter((columnDataType) =>
                    compareColumnHidableDataTypes.includes(columnDataType)
                  )
                ),
              ].join(",")
            );
            return searchParams;
          }
        ),
        { replace: true }
      ),
    [setSearchParams]
  );

  // initialize with every column visible if searchParam is missing
  useEffect(() => {
    if (!columnDisplaySearchParam) {
      setColumnDisplaySettings(initialCompareColumnDisplaySettings);
    }
  }, [
    columnDisplaySearchParam,
    initialCompareColumnDisplaySettings,
    setColumnDisplaySettings,
  ]);

  return [columnDisplaySettings, setColumnDisplaySettings];
}
