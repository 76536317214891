import format from "date-fns/format";

import { IClassification } from "../Mapping/mapping.types";
import { TConstructionIndicatorMultipliers } from "../shared/types/averagePrice.types";
import { IRowType } from "../shared/types/rowType.types";
import { IMappedTender } from "../shared/types/tender.types";
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER, accessNested } from "../shared/utils";

/**
 * Applies construction indicator multipliers to a tender in order to readjust prices
 * @param tender
 * @param constructionIndicatorMultipliers
 * @param classification
 */
export function applyConstructionIndicatorMultipliers(
  tender: IMappedTender,
  constructionIndicatorMultipliers: TConstructionIndicatorMultipliers,
  classification: IClassification
) {
  const originalDateIndex = getDateIndex(
    tender.closure_date ?? new Date(),
    constructionIndicatorMultipliers
  );
  const currentDateIndex = getDateIndex(
    new Date(),
    constructionIndicatorMultipliers
  );

  const mappedPriceAverages = tender.mapped_price_averages?.map(
    (price_average) => {
      // get the constructionIndicator (aka. indice BT) from the classification
      const constructionIndicator = accessNested<IRowType>(
        classification,
        price_average.ref
          .split(".")
          .map((ref) => `children.${ref}`)
          .join(".")
      )?.construction_index;
      const constructionIndicatorRow =
        constructionIndicatorMultipliers[constructionIndicator];
      if (!constructionIndicatorRow) {
        console.warn(
          "Multiplier not found for this construction indicator: ",
          constructionIndicator,
          price_average.ref
        );
        return price_average;
      }

      // get the original multiplier when the price was paid
      const originalMultiplier =
        getClosestMultiplier(constructionIndicatorRow, originalDateIndex) ??
        100;
      // get the current multiplier with today's multiplier
      const currentMultiplier =
        getClosestMultiplier(constructionIndicatorRow, currentDateIndex) ??
        originalMultiplier;

      // ratio between the multipliers to readjust the price
      const multipliersRatio = currentMultiplier / originalMultiplier;

      // tender price_multiplier is applied to get the actual original tender price
      const originalPrice =
        price_average.price * (tender.price_multiplier ?? 1);

      // compute readjusted price by applying the multipliersRatio
      const readjustedPrice = originalPrice * multipliersRatio;

      // round to 2 decimals
      const price = Number(readjustedPrice.toFixed(2));

      return {
        ...price_average,
        price,
      };
    }
  );

  return {
    ...tender,
    mapped_price_averages: mappedPriceAverages,
  };
}

/**
 * Search the constructionIndicatorMultiplier at the specified index, if no value is found at that index increase it until one is found
 * @param constructionIndicatorRow
 * @param startIndex
 * @returns first index with a value found
 */
function getClosestMultiplier(
  constructionIndicatorRow: (string | number | undefined)[],
  startIndex: number
) {
  let value = undefined;
  let index = startIndex;
  while (value === undefined && index < constructionIndicatorRow.length) {
    value = constructionIndicatorRow[index] as number;
    index++;
  }
  if (index > startIndex + 1) {
    console.warn(
      `Had to get multiplier ${index - startIndex - 1} months later`
    );
  }
  if (value === undefined) {
    console.warn(
      "No multiplier found on this constructionIndicatorRow: ",
      constructionIndicatorRow
    );
  }
  return value;
}

/**
 * Returns the constructionIndicatorMultipliers index for a given date. If no match is found, either returns the first or last available date.
 * @param date
 * @param constructionIndicatorMultipliers
 * @returns
 */
function getDateIndex(
  date: Date,
  constructionIndicatorMultipliers: TConstructionIndicatorMultipliers
) {
  let originalDateIndex = constructionIndicatorMultipliers.dates?.findIndex(
    (v) => v === formatConstructionIndicatorDate(date)
  );
  if (originalDateIndex !== undefined && originalDateIndex !== -1) {
    return originalDateIndex;
  } else {
    console.warn("date not found in constructionIndicatorMultipliers: ", date);
    const lastDateIndex =
      constructionIndicatorMultipliers.dates?.findLastIndex(Boolean);
    if (
      lastDateIndex &&
      new Date(
        constructionIndicatorMultipliers.dates?.[lastDateIndex] as string
      ) < date
    ) {
      return lastDateIndex;
    } else {
      return 0;
    }
  }
}

/**
 * Formats date to yyyy-mm
 * @param date: Date
 * @returns ex: 2021-01
 */
function formatConstructionIndicatorDate(date: Date) {
  try {
    return format(date, "yyyy-MM");
  } catch (error) {
    console.error(error);
    return DEFAULT_EMPTY_VALUE_PLACEHOLDER;
  }
}
