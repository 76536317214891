import { ExcelIcon, GenericFileIcon, PDFIcon, WordIcon } from ".";
import React from "react";

export interface IFileIconProps {
  fileName: string;
  className?: string;
  size?: number;
}

export function FileIcon({
  fileName,
  className = "",
  size = 24,
}: IFileIconProps) {
  const extension = fileName.split(".").pop();
  switch (extension?.toLowerCase()) {
    case "xlsx":
    case "xls":
    case "xlsm":
    case "xlsb":
      return <ExcelIcon className={className} size={size} />;
    case "pdf":
      return <PDFIcon className={className} size={size} />;
    case "doc":
    case "docm":
    case "docx":
    case "dot":
    case "dotm":
    case "dotx":
      return <WordIcon className={className} size={size} />;
    default:
      return <GenericFileIcon className={className} size={size} />;
  }
}
