import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import { MatomoInstance } from "@jonkoops/matomo-tracker-react/lib/types";
import React, { ReactElement } from "react";

import { isDevelopmentEnv, isProductionEnv } from "./shared/utils";

export enum CUSTOM_DIMENSIONS_ENUM {
  ERROR = 1,
  OPERATION_ID = 2,
  LOT_ID = 3,
  COMPANY_ID = 4,
}

export enum CUSTOM_ACTION_DIMENSIONS_ENUM {
  DATA = 1,
}

/** override typing to accept children */
const MatomoProviderChildren = MatomoProvider as unknown as React.FC<{
  value: MatomoInstance;
  children: ReactElement | ReactElement[];
}>;

let matomoDisabled = !isProductionEnv && !isDevelopmentEnv;
let matomoUrl = window.REACT_APP_MATOMO_URL;
//do not cause a failure to the user if tracking isn't active
if (!matomoUrl) {
  console.error("missing matomo url");
  matomoUrl = " ";
  matomoDisabled = true;
}

const instance = createInstance({
  urlBase: matomoUrl,
  siteId: 1,
  // userId: controlled by UserContext, set to user_id
  // trackerUrl: optional, default value: `${urlBase}matomo.php`
  // srcUrl: optional, default value: `${urlBase}matomo.js`
  disabled: matomoDisabled, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 15, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: isDevelopmentEnv ? false : true,
    setRequestMethod: "POST",
  },
});

export function MatomoProviderInstance({
  children,
}: {
  children: ReactElement | ReactElement[];
}) {
  return (
    <MatomoProviderChildren value={instance}>{children}</MatomoProviderChildren>
  );
}
