import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { DefaultModal } from "../DefaultModal";
import { IBackErrors, RootError, useBackFieldErrors } from "../errors";
import { yupLocale } from "../i18n";
import { Input, getInputProps } from "../inputs";

export interface IRenameModal {
  show?: boolean;
  title: string;
  buttonText: string;
  value?: string;
  handleClose: (value?: string) => void;
  schema?: yup.ObjectSchema<any>;
  isSubmitting?: boolean;
}

yup.setLocale(yupLocale);
const defaultSchema = yup.object({
  value: yup.string().max(128).required(),
});

export function RenameModal({
  show = true,
  handleClose,
  title,
  buttonText,
  value,
  schema = defaultSchema,
  isSubmitting = false,
  backErrors,
}: IRenameModal & { backErrors?: IBackErrors<{ value: string | undefined }> }) {
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: { value },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  useBackFieldErrors(backErrors, setError, clearErrors);

  return (
    <DefaultModal
      title={title}
      show={show}
      handleClose={() => handleClose(undefined)}
      footer={
        <Button
          disabled={isSubmitting}
          onClick={() => handleClose(getValues().value)}
        >
          {buttonText}
          {isSubmitting && <Spinner size="sm" />}
        </Button>
      }
    >
      <Form
        onSubmit={handleSubmit(
          (values) => !isSubmitting && handleClose(values.value)
        )}
      >
        <Row>
          <Input
            as={Col}
            disabled={isSubmitting}
            autoFocus
            {...getInputProps("value", register, schema, errors)}
          />
        </Row>
        <RootError errors={errors} />
      </Form>
    </DefaultModal>
  );
}
