import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ExpandedState,
  Table as ReactTable,
  RowSelectionState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { MutableRefObject, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useOrganizationNameRights } from "../../../account/UserOrganizationGuard";
import { updateAllotment } from "../../../api/fetchLots";
import { ICompany } from "../../types/company.types";
import { ITender } from "../../types/tender.types";
import { useHasChanged } from "../../useHasChanged";
import { QUERY_KEYS_ENUM, useCurrentLot } from "../../useSharedQueries";
import { doArraysHaveDifferentValues, isDevelopmentEnv } from "../../utils";

import { useTendersTableColums } from "./useTendersTableColumns";
import {
  getRowSelectionFromSelectedTenderIds,
  onRowClick,
} from "./useTendersTableUtils";

const emptyData: ICompany[] = [];
export function useTendersTable({
  companies,
  estimation,
  disableExport = false,
  lowestEstimation,
  canEdit,
  onEditTenderVersion,
  onEditTender,
  onDeleteTender,
  onAddNewTender,
  controlledSelectedTenderIds,
  defaultSelectedTenderIds,
}: {
  companies: ICompany[];
  estimation?: number;
  disableExport?: boolean;
  lowestEstimation?: number;
  canEdit?: boolean;
  onEditTenderVersion?: (tender: ITender) => void;
  onEditTender?: (company: ICompany) => void;
  onDeleteTender?: (tender: ITender) => void;
  onAddNewTender?: (company: ICompany) => void;
  controlledSelectedTenderIds?: string[];
  defaultSelectedTenderIds?: string[];
}) {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { operationId, lotId } = useParams();

  const queryClient = useQueryClient();

  const bestTenderMutation = useMutation({
    mutationFn: updateAllotment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.LOT, lotId],
      });
    },
  });
  const lot = useCurrentLot();
  const bestLotPrice = lot?.best_price_breakdown;

  const { organization } = useOrganizationNameRights(
    window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION
  );

  let table: MutableRefObject<ReactTable<ICompany | ITender> | undefined> =
    useRef();

  const tendersColumns = useTendersTableColums(
    estimation,
    lowestEstimation,
    table.current,
    operationId,
    canEdit,
    organization,
    lotId,
    bestLotPrice,
    bestTenderMutation,
    disableExport,
    onEditTenderVersion,
    onEditTender,
    onDeleteTender,
    onAddNewTender
  );

  table.current = useReactTable<ICompany | ITender>({
    enableSorting: false,
    initialState: { pagination: { pageSize: 100 } },
    state: {
      expanded,
      rowSelection,
    },
    onExpandedChange: setExpanded,
    onRowSelectionChange: setRowSelection,
    columns: tendersColumns,
    data: (companies ?? emptyData) as (ICompany | ITender)[],
    debugTable: isDevelopmentEnv,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row: any) => row.tenders,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableRowSelection: true,
  });

  const tableCoreRowModel = table.current.getCoreRowModel();
  const rowsById = tableCoreRowModel.rowsById;
  const selectedTenderIds = Object.keys(rowSelection).map(
    (rowId) => rowsById[rowId]?.original.id
  );

  const [hasSetDefaults, setHasSetDefaults] = useState(false);
  if (!hasSetDefaults && defaultSelectedTenderIds) {
    // select defaults
    setHasSetDefaults(true);
    const rowSelection = getRowSelectionFromSelectedTenderIds(
      tableCoreRowModel.flatRows,
      defaultSelectedTenderIds
    );
    setRowSelection(rowSelection);
  }

  const controlledSelectedTenderIdsHasChanged = useHasChanged(
    ...(controlledSelectedTenderIds ?? [])
  );
  const companiesHasChanged = useHasChanged(companies);
  if (
    (controlledSelectedTenderIdsHasChanged || companiesHasChanged) &&
    doArraysHaveDifferentValues(
      selectedTenderIds,
      controlledSelectedTenderIds ?? []
    )
  ) {
    const rowSelection = getRowSelectionFromSelectedTenderIds(
      tableCoreRowModel.flatRows,
      controlledSelectedTenderIds
    );
    setRowSelection(rowSelection);
    setExpanded((expanded) => {
      const parentRowIdsOfSelectedRows = Object.keys(rowSelection).map(
        (rowId) => rowsById[rowId].getParentRow()?.id
      );
      const expandedStateFromParentRowIdsOfSelectedRows =
        parentRowIdsOfSelectedRows.reduce(
          (state, parentRowId) =>
            parentRowId !== undefined
              ? Object.assign(state, { [parentRowId]: true })
              : state,
          {}
        );
      return typeof expanded === "boolean"
        ? true
        : { ...expanded, ...expandedStateFromParentRowIdsOfSelectedRows };
    });
  }

  return { table: table.current, onRowClick, selectedTenderIds };
}
