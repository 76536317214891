import {
  IPriceAveragePanelDTO,
  TConstructionIndicatorMultipliersDTO,
  constructionIndicatorDTOtoConstructionIndicatorMapper,
  priceAveragePanelDTOToPriceAveragePanelMapper,
} from "../shared/types/averagePrice.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";

import { HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE } from "./APIUtils";
import { CONSTRUCTION_INDICATOR_MULTIPLIERS } from "./constructionIndicatorMultipliers";
import { FETCH_METHODS_ENUM, authRequest, authRequestJSON } from "./fetch";

const PRICE_AVERAGE_PANEL_BASE_URL = "price-average-panel";
const CONSTRUCTION_INDICATOR_MULTIPLIERS_BASE_URL =
  "construction-index-multipliers";

export async function createOrUpdatePriceAveragePanel({
  id,
  name,
  description,
  settings,
  price_breakdowns,
  ignored_classifications,
  ...nonUpdatableKeys
}: Partial<IPriceAveragePanelDTO>) {
  const shouldUpdatePriceAveragePanel = id !== undefined;
  const priceAveragePanelToSend = {
    name,
    description,
    settings,
    price_breakdowns,
    ignored_classifications,
    ...(shouldUpdatePriceAveragePanel ? {} : nonUpdatableKeys),
  };
  return await authRequestJSON<IPriceAveragePanelDTO>(
    `${PRICE_AVERAGE_PANEL_BASE_URL}/${
      shouldUpdatePriceAveragePanel ? `${id}/` : ""
    }`,
    {
      method: shouldUpdatePriceAveragePanel
        ? FETCH_METHODS_ENUM.PATCH
        : FETCH_METHODS_ENUM.POST,
      body: JSON.stringify(priceAveragePanelToSend),
    }
  );
}

export interface IPriceAveragePanelFilters {
  original_author?: string;
}
export async function fetchPriceAveragePanels(
  filters?: IPriceAveragePanelFilters
) {
  const query = new URLSearchParams({
    page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
    ...(filters ?? {}),
  });
  const result = await authRequestJSON<
    IPaginatedQueryResults<IPriceAveragePanelDTO>
  >(`${PRICE_AVERAGE_PANEL_BASE_URL}/?${query}`);
  return result.results.map(priceAveragePanelDTOToPriceAveragePanelMapper);
}

export async function fetchPriceAveragePanel(id: string) {
  const result = await authRequestJSON<IPriceAveragePanelDTO>(
    `${PRICE_AVERAGE_PANEL_BASE_URL}/${id}/`
  );
  return priceAveragePanelDTOToPriceAveragePanelMapper(result);
}

export async function deletePriceAveragePanel(id: string) {
  await authRequest(`${PRICE_AVERAGE_PANEL_BASE_URL}/${id}/`, {
    method: FETCH_METHODS_ENUM.DELETE,
  });
}

export async function fetchConstructionIndicatorMultipliers() {
  const mock = true;
  if (mock) {
    return constructionIndicatorDTOtoConstructionIndicatorMapper(
      CONSTRUCTION_INDICATOR_MULTIPLIERS
    );
  }
  const result = await authRequestJSON<TConstructionIndicatorMultipliersDTO>(
    `${CONSTRUCTION_INDICATOR_MULTIPLIERS_BASE_URL}/`
  );
  return constructionIndicatorDTOtoConstructionIndicatorMapper(result);
}
