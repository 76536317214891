import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";
import {
  IWork,
  IWorkDTO,
  workDTOtoWorkMapper,
} from "../shared/types/work.types";

import { HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE } from "./APIUtils";
import { authRequestJSON } from "./fetch";

export async function fetchWorks({
  operationId,
}: {
  operationId: string;
}): Promise<IWork[]> {
  return (
    await authRequestJSON<IPaginatedQueryResults<IWorkDTO>>(
      `work/?${new URLSearchParams({
        project: operationId,
        page_size: HUGE_PAGE_SIZE_FOR_ONE_PAGE_ONLY_RESPONSE,
      })}`
    )
  ).results.map(workDTOtoWorkMapper);
}
