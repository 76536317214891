import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useMemo } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import styles from "../../shared/DefaultModal.module.scss";
import { useTranslation, yupLocale } from "../../shared/i18n";
import { ArrowSmallLeftIcon, CheckedCircleIcon } from "../../shared/icons";
import { EuroIconInputGroup, Select } from "../../shared/inputs";
import {
  ControlledInput,
  Input,
  getInputProps,
  yupNumber,
  yupRequiredWhenIncludes,
} from "../../shared/inputs/input";
import { ILot } from "../../shared/types/lot.types";
import { useHasChanged } from "../../shared/useHasChanged";
import { useTendersQuery } from "../../shared/useSharedQueries";
import { cancelEvent, ifTest } from "../../shared/utils";

yup.setLocale(yupLocale);

function yupRequiredWhenRequiredSelectedCompanyIsTrue(schema: yup.Schema) {
  return yupRequiredWhenIncludes(schema, { required_selected_company: true });
}

const schema = yup.object({
  required_selected_company: yup.array().nullable(),
  selected: yupRequiredWhenRequiredSelectedCompanyIsTrue(yup.string()),
  selected_company: yupRequiredWhenRequiredSelectedCompanyIsTrue(yup.string()),
  estimation_amount: yupNumber().positive().required(),
  selected_price_amount: yupNumber().positive().required(),
  discount_amount: yupNumber().min(0).nullable(),
  selected_discounted_price_amount: yupNumber().positive().nullable(),
  complementary_informations: yup.string(),
});

export type TSelectCompanyFormInputs = yup.InferType<typeof schema>;

export function SelectCompanyForm({
  lot: defaultValues,
  onSubmit,
  onClose,
  onPrevious,
  readOnly,
  requiredSelectedCompany,
}: {
  lot: ILot;
  onSubmit: (lot: Partial<ILot>) => void;
  onClose: () => void;
  onPrevious: () => void;
  readOnly?: boolean;
  requiredSelectedCompany?: boolean;
}) {
  const { t } = useTranslation("LotClosureModal");
  const tendersQuery = useTendersQuery(defaultValues.id);

  const initial_selected_company = tendersQuery.data?.find((company) =>
    company.tenders.some((tender) => tender.id === defaultValues.selected)
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
    trigger,
  } = useForm<TSelectCompanyFormInputs>({
    defaultValues: {
      ...defaultValues,
      discount_amount:
        (defaultValues.selected_price_amount ?? 0) -
        (defaultValues.selected_discounted_price_amount ?? 0),
      selected_company: initial_selected_company?.id,
      // cast to an array to be able to easily reuse yupRequiredWhenIncludes
      required_selected_company: [requiredSelectedCompany],
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const companyOptions = useMemo(
    () =>
      tendersQuery.data?.map((company) => ({
        value: company.id,
        label: company.name,
      })),
    [tendersQuery.data]
  );

  // compute selected_discounted_price_amount
  const discount_amount = watch("discount_amount");
  const selected_price_amount = watch("selected_price_amount");
  if (useHasChanged(discount_amount, selected_price_amount)) {
    if (selected_price_amount) {
      setValue(
        "selected_discounted_price_amount",
        selected_price_amount - (discount_amount ?? 0)
      );
      trigger();
    }
  }

  // set the latest tender amount on selected company change
  const selected_company = watch("selected_company");
  if (useHasChanged(selected_company, tendersQuery.data)) {
    const selectedCompanyLatestTender = tendersQuery.data?.find(
      (company) => company.id === selected_company
    )?.tenders[0];
    if (selectedCompanyLatestTender) {
      setValue("selected", selectedCompanyLatestTender.id);
      setValue(
        "selected_price_amount",
        selectedCompanyLatestTender.estimation_amount
      );
      trigger();
    }
  }

  const onFormSubmit = readOnly
    ? (e: any) => {
        cancelEvent(e);
        onClose();
      }
    : handleSubmit((data) => {
        const {
          required_selected_company,
          selected_company,
          selected,
          ...real_data
        } = data;
        onSubmit({
          ...real_data,
          // send null to backend in order to delete this field if no selection has been made here
          selected: selected_company ? selected : null,
        });
      });

  return (
    <>
      <Modal.Header className="border-bottom-0 px-4 mx-2 pb-4 justify-content-md-center">
        <Modal.Title
          className={classNames("text-center", styles["wrap-balance"])}
        >
          {t("close title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body as={Form} onSubmit={onFormSubmit}>
        <fieldset disabled={readOnly}>
          <Row className="mt-4 mb-3">
            <Col ms="auto" className="align-self-center">
              <ControlledInput
                name="selected_company"
                label={t("selected company")}
                schema={schema}
                control={control}
                render={({ field }) =>
                  tendersQuery.isLoading ? (
                    <Spinner className="mx-auto d-block" />
                  ) : (
                    <Select {...field} options={companyOptions} />
                  )
                }
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Input
              as={Col}
              md={6}
              {...getInputProps("estimation_amount", register, schema, errors)}
              label={t("lot amount")}
              placeholder={t("lot amount")}
              inputGroupAfter={<EuroIconInputGroup />}
            />
            <Input
              as={Col}
              md={6}
              {...getInputProps(
                "selected_price_amount",
                register,
                schema,
                errors
              )}
              label={t("selected price amount")}
              placeholder={t("selected price amount")}
              inputGroupAfter={<EuroIconInputGroup />}
            />
          </Row>
          <Row className="mb-3">
            <Input
              as={Col}
              md={6}
              {...getInputProps("discount_amount", register, schema, errors)}
              label={t("discount amount")}
              placeholder={t("discount amount")}
              inputGroupAfter={<EuroIconInputGroup />}
            />
            <Input
              disabled
              as={Col}
              md={6}
              {...getInputProps(
                "selected_discounted_price_amount",
                register,
                schema,
                errors
              )}
              label={t("selected discounted price amount")}
              placeholder={t("selected discounted price amount")}
              inputGroupAfter={<EuroIconInputGroup />}
            />
          </Row>
          <Row className="mb-3">
            <Col>
              <ControlledInput
                name="complementary_informations"
                label={t("complementary informations")}
                schema={schema}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    as="textarea"
                    placeholder={t("complementary informations details")}
                    rows={3}
                  />
                )}
              />
            </Col>
          </Row>
        </fieldset>
        <Row className="justify-content-center mt-4 mb-5">
          <Col sm="auto">
            <Button
              data-test={ifTest("previous-button")}
              size="lg"
              variant="light"
              onClick={onPrevious}
              className="rectangular transparent"
            >
              <ArrowSmallLeftIcon className="me-2" />
              {t("previous")}
            </Button>
          </Col>
          <Col sm="auto">
            <Button
              type="submit"
              size="lg"
              variant="primary"
              disabled={!readOnly && !isValid}
              className="rectangular ms-4"
            >
              <CheckedCircleIcon className="me-2" />
              {t(readOnly ? "close" : "validate")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
}
