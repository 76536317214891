import { useState } from "react";

import { useHasChanged } from "../shared/useHasChanged";

import { ILotChartData } from "./chart.types";

export function useLotsChartMaxBarCount(data: ILotChartData[]) {
  const [maxBarCount, setMaxBarCount] = useState(2);
  if (useHasChanged(...data.map(({ companies }) => companies?.length))) {
    // we take the max count of companies between all allotments and add 2 for the estimate and average bars
    setMaxBarCount(
      2 +
        data
          .map(({ companies }) => companies?.length ?? 0)
          .reduce((max, length) => Math.max(max, length), 0)
    );
  }
  return maxBarCount;
}
