import { UseMutationResult } from "@tanstack/react-query";
import {
  ColumnDef,
  Table as ReactTable,
  createColumnHelper,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useMemo } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { GrSort } from "react-icons/gr";
import { NavLink } from "react-router-dom";

import { GuardedButton } from "../../../account/GuardedButton";
import { TUpdateLot } from "../../../api/fetchLots";
import colors from "../../_exports.module.scss";
import { ExportTableToExcel } from "../../excel/ExportTableToExcel";
import { formatCurrency, useTranslation } from "../../i18n";
import { formatDateDDMMYYYY } from "../../i18n/dateFormatter";
import { PenIcon, StarIcon, TrashIcon } from "../../icons";
import { CheckBox, DropDownCheckBoxButton } from "../../inputs";
import { PATH_NAMES_ENUM } from "../../pathNames";
import { ICompany } from "../../types/company.types";
import {
  ILot,
  ILotPrice,
  getIsBestPriceBreakdown,
} from "../../types/lot.types";
import { IOrganization } from "../../types/organization.types";
import { ITender } from "../../types/tender.types";
import { cancelEvent, ifTest } from "../../utils";
import {
  DIFFERENCE_MODE_ENUM,
  Difference,
} from "../../visualization/Difference";

import styles from "./useTendersTable.module.scss";
import {
  TABLE_COLUMN_DATA_TYPE_ENUM,
  getCellColor,
  isCompany,
  mapCell,
} from "./useTendersTableUtils";

const DIFF_LOWEST_COL_WIDTH = 24;
const COMPANY_NAME_COL_WIDTH = 36;

const columnHelper = createColumnHelper<ICompany | ITender>();

export function useTendersTableColums(
  estimation: number | undefined,
  lowestEstimation: number | undefined,
  table: ReactTable<ICompany | ITender> | undefined,
  operationId: string | undefined,
  canEdit: boolean | undefined,
  organization: IOrganization | undefined,
  lotId: string | undefined,
  bestLotPrice: ILotPrice | undefined,
  bestTenderMutation: UseMutationResult<ILot, unknown, TUpdateLot, unknown>,
  disableExport: boolean,
  onEditTenderVersion?: (tender: ITender) => void,
  onEditTender?: (company: ICompany) => void,
  onDeleteTender?: (tender: ITender) => void,
  onAddNewTender?: (company: ICompany) => void
) {
  const { t } = useTranslation("useTendersTable");

  const tendersColumns = useMemo(() => {
    const columns: ColumnDef<ICompany | ITender, any>[] = [
      columnHelper.accessor("name", {
        header: () => (
          <>
            <span className="me-5">{t("company name")}</span>
            {!disableExport && (
              <ExportTableToExcel
                table={table as any}
                isLoading={false}
                fileName={`${t("title")}_${operationId}`}
                sheetName={t("title")}
                mapCell={(cell) =>
                  mapCell(cell, t, estimation, lowestEstimation)
                }
                getCellColor={() => colors.white}
                getFontColor={(cell) =>
                  getCellColor(cell, estimation, lowestEstimation)
                }
                fontSize={10}
                headerFontSize={10}
                rowHeight={18}
              />
            )}
          </>
        ),
        size: 240,
        cell: (props) => {
          const original = props.row.original;
          if (isCompany(original)) {
            const isBest = getIsBestPriceBreakdown(bestLotPrice, original);
            return (
              <div className="d-flex flex-row flex-nowrap">
                {canEdit && (
                  <GuardedButton
                    variant="text"
                    className="me-3 ms-n2 align-self-start p-3"
                    onClick={(e) => {
                      cancelEvent(e);
                      bestTenderMutation.mutate({
                        id: lotId!,
                        best: isBest
                          ? null
                          : original.tenders.find((tender) => tender)?.id,
                      });
                    }}
                  >
                    <StarIcon
                      fill={isBest ? colors.blue300 : ""}
                      stroke={colors.blue300}
                      className="align-top d-inline-block"
                    />
                  </GuardedButton>
                )}
                <div>
                  <b>{props.getValue<string>()}</b>
                  {canEdit && (
                    <>
                      <GuardedButton
                        variant="text"
                        className={classNames("px-0 pb-0", styles.button)}
                        onClick={(e) => {
                          cancelEvent(e);
                          onEditTender?.(original);
                        }}
                      >
                        <PenIcon className="ms-2" />
                      </GuardedButton>
                      <br />
                      <GuardedButton
                        variant="text"
                        className={classNames("px-0 pb-0 mt-2", styles.button)}
                        onClick={(e) => {
                          cancelEvent(e);
                          onAddNewTender?.(original);
                        }}
                      >
                        <u>{t("add new version")}</u>
                      </GuardedButton>
                    </>
                  )}
                </div>
              </div>
            );
          }
          return (
            <Row>
              <Col xs={4} className="text-end">
                <CheckBox
                  id={`${original.id}-checkbox`}
                  onChange={props.row.getToggleSelectedHandler()}
                  onClick={props.row.getToggleSelectedHandler()}
                  checked={props.row.getIsSelected()}
                  className={styles.checkbox}
                />
              </Col>
              <Col>
                <div className="mb-2">
                  <label htmlFor={`${original.id}-checkbox`} role="button">
                    <b>{t("version", { version: original.version })}</b>
                  </label>
                  {canEdit && (
                    <>
                      <GuardedButton
                        variant="text"
                        className="mx-3"
                        onClick={(e) => {
                          onEditTenderVersion?.(original);
                          cancelEvent(e);
                        }}
                      >
                        <PenIcon />
                      </GuardedButton>
                      <GuardedButton
                        variant="text"
                        onClick={(e) => {
                          onDeleteTender?.(original);
                          cancelEvent(e);
                        }}
                      >
                        <TrashIcon />
                      </GuardedButton>
                      {organization && (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{t("mapping")}</Tooltip>}
                        >
                          <Nav className="d-inline-block">
                            <Nav.Link
                              as={NavLink}
                              to={{
                                pathname: `/${PATH_NAMES_ENUM.MAPPING}/${PATH_NAMES_ENUM.OPERATIONS}/${operationId}/${PATH_NAMES_ENUM.LOTS}/${lotId}`,
                                search: `?tenders=${original.id}`,
                              }}
                              data-test={ifTest("mapping-link")}
                            >
                              <GrSort />
                            </Nav.Link>
                          </Nav>
                        </OverlayTrigger>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {t("creation date")}
                  {formatDateDDMMYYYY(original.upload_date)}
                </div>
              </Col>
            </Row>
          );
        },
        meta: {
          excel: {
            header: t("company name"),
            width: COMPANY_NAME_COL_WIDTH,
          },
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.NAME,
        },
      }),
      columnHelper.accessor("estimation_amount", {
        header: t("total amount"),
        id: "total amount",
        cell: (props) => (
          <span className="ms-3">
            {formatCurrency(props.getValue<number>())}
          </span>
        ),
        meta: {
          dataType: TABLE_COLUMN_DATA_TYPE_ENUM.TOTAL,
        },
      }),
      columnHelper.accessor("estimation_amount", {
        header: "",
        id: "arrow",
        size: 20,
        meta: {
          className: styles["dropdown-col"],
          excel: {
            skip: true,
          },
        },
        cell: (props) =>
          isCompany(props.row.original) && (
            <div className="text-center">
              <DropDownCheckBoxButton
                checked={props.row.getIsExpanded()}
                onChange={(e) => {
                  props.row.toggleExpanded();
                  cancelEvent(e);
                }}
              />
            </div>
          ),
      }),
    ];
    if (estimation !== undefined) {
      columns.push(
        columnHelper.accessor("estimation_amount", {
          header: t("MOE difference"),
          id: "MOE difference",
          cell: (props) =>
            isCompany(props.row.original) && (
              <Difference
                comparee={props.getValue<number>()}
                comparator={estimation}
                mode={DIFFERENCE_MODE_ENUM.PERCENT}
                className="ps-5"
                showTrend
              />
            ),
          meta: {
            dataType: TABLE_COLUMN_DATA_TYPE_ENUM.DIFF_MOE,
          },
        })
      );
    }
    if (lowestEstimation !== undefined) {
      columns.push(
        columnHelper.accessor("estimation_amount", {
          header: t("difference lowest"),
          id: "difference lowest",
          cell: (props) =>
            isCompany(props.row.original) && (
              <Difference
                comparee={Number(props.getValue<string>())}
                comparator={lowestEstimation}
                mode={DIFFERENCE_MODE_ENUM.EURO}
                className="ps-5"
                showTrend
              />
            ),
          meta: {
            dataType: TABLE_COLUMN_DATA_TYPE_ENUM.DIFF_LOWEST,
            excel: { width: DIFF_LOWEST_COL_WIDTH },
          },
        })
      );
    }
    return columns;
  }, [
    t,
    estimation,
    lowestEstimation,
    table,
    operationId,
    canEdit,
    organization,
    lotId,
    bestLotPrice,
    bestTenderMutation,
    disableExport,
    onEditTender,
    onAddNewTender,
    onEditTenderVersion,
    onDeleteTender,
  ]);
  return tendersColumns;
}
