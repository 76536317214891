import { SortingState } from "@tanstack/react-table";
import { isExpired } from "react-jwt";

import { STORAGE_KEY_ACCESS } from "../account/UserContext";
import { ErrorCause } from "../shared/errors";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";

export enum FETCH_METHODS_ENUM {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE",
  PUT = "PUT",
  HEAD = "HEAD",
}
export const API_BASE_URL = `${window.REACT_APP_API_URL}`;
async function fetchWithErrorManagement(
  input: string,
  init?: RequestInit
): Promise<Response> {
  const response = await fetch(
    input,
    [
      FETCH_METHODS_ENUM.POST,
      FETCH_METHODS_ENUM.PATCH,
      FETCH_METHODS_ENUM.PUT,
    ].includes(init?.method as FETCH_METHODS_ENUM)
      ? {
          ...init,
          headers: {
            "Content-Type": "application/json",
            ...init?.headers,
          },
        }
      : init
  );

  if (!response.ok) {
    if (response.status === 400) {
      // 400: validation error from back, parse the errors to be displayed in Form
      throw await response.json();
    }
    throw new Error(String(response.status));
  }

  return response;
}

export async function request(input: string, init?: RequestInit) {
  return await fetchWithErrorManagement(input, init);
}

/**
 * requests JSON and parses it
 *
 * If a 204 is returned by the backend, undefined is returned instead.
 * This can happen if a specific field is queried, so handling undefined in those cases is required.
 * @param input
 * @param init
 * @returns T | undefined
 */
export async function requestJSON<T>(
  input: string,
  init?: RequestInit
): Promise<T> {
  const response = await fetchWithErrorManagement(input, {
    ...init,
    headers: {
      "Content-Type": [
        FETCH_METHODS_ENUM.POST,
        FETCH_METHODS_ENUM.PATCH,
        FETCH_METHODS_ENUM.PUT,
      ].includes(init?.method as FETCH_METHODS_ENUM)
        ? "application/json"
        : "",
      accept: "application/json",
      ...init?.headers,
    },
  });
  if (
    (!init?.method || init.method === FETCH_METHODS_ENUM.GET) &&
    response.status === 204
  ) {
    return undefined as T;
  }
  return response.json();
}

export async function requestBlob(input: string, init?: RequestInit) {
  const response = await fetchWithErrorManagement(input, init);
  return response.blob();
}
function addAuthTokenToRequestInit(init?: RequestInit) {
  const token = localStorage.getItem(STORAGE_KEY_ACCESS);
  if (token) {
    if (isExpired(token)) {
      throw new Error("Token has expired, aborting request", {
        cause: ErrorCause.TOKEN_EXPIRED,
      });
    }
    return {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }
}

export async function authRequest(input: string, init?: RequestInit) {
  return request(`${API_BASE_URL}/${input}`, addAuthTokenToRequestInit(init));
}

export async function authRequestJSON<T>(
  input: string,
  init?: RequestInit
): Promise<T> {
  return requestJSON(
    `${API_BASE_URL}/${input}`,
    addAuthTokenToRequestInit(init)
  );
}

export interface IPaginatedParams {
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}

export async function authRequestPaginatedJSON<T>(
  { pageIndex, pageSize, sorting, ...rest }: IPaginatedParams,
  baseUrl: string,
  mapper: any
): Promise<IPaginatedQueryResults<T>> {
  const searchParams: Record<string, string | string[]> = {
    page_size: pageSize.toString(),
    page: (pageIndex + 1).toString(), // Backend start indexing at 1
    ...rest,
  };
  const sortBy = sorting.find(() => true);
  if (sortBy !== undefined) {
    searchParams.ordering = `${sortBy.desc ? "-" : ""}${sortBy.id}`;
  }
  const result = await authRequestJSON<IPaginatedQueryResults<T>>(
    `${baseUrl}/?${new URLSearchParams(searchParams as Record<string, string>)}`
  );
  if (mapper) {
    return {
      ...result,
      results: result.results.map(mapper),
    };
  } else {
    return result;
  }
}
