import {
  ColumnDef,
  VisibilityState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { getExcelTypeHeader } from "../shared/excel/utils";
import { useTranslation } from "../shared/i18n";
import { Table } from "../shared/table";
import { TCell } from "../shared/types/lot.types";
import { useElementAvailableHeightOnScreen } from "../shared/utils";

import styles from "./ExcelImportTable.module.scss";

export interface ILotsTableProps {
  sheet: TCell[][];
  onData: (data: number[] | undefined) => void;
  instructions: string;
  optionalStepVariant: boolean;
  columnMode?: boolean;
  reverseMode?: boolean;
  selection?: number[];
  allowMultipleSelections?: boolean;
  tableContainerRef?: React.RefObject<HTMLDivElement>;
  isFrame?: Boolean;
}

const MAX_ROWS_TO_DISPLAY_AT_ONCE = 250;

export function ExcelImportTable({
  sheet,
  onData,
  instructions,
  optionalStepVariant,
  columnMode = false,
  reverseMode = false,
  selection,
  allowMultipleSelections,
  isFrame,
}: ILotsTableProps) {
  const { t } = useTranslation("ExcelImportTable");
  const [selectedColumns, setSelectedColumns] = useState<number[]>();
  const [selectedRows, setSelectedRows] = useState<number[]>();
  const [hoveredColumn, setHoveredColumn] = useState<number>();

  useEffect(() => {
    setSelectedColumns(columnMode ? selection : undefined);
    setSelectedRows(columnMode ? undefined : selection);
  }, [selection, columnMode, setSelectedColumns, setSelectedRows]);

  const tableColumns: ColumnDef<any>[] = useMemo(() => {
    if (!sheet[0]) {
      return [];
    }
    return sheet[0].map((s, index) => ({
      header: getExcelTypeHeader(index),
      accessorKey: String(index),
      id: String(index),
      enableSorting: false,
    }));
  }, [sheet]);

  const [rowCountToDisplay, setRowCountToDisplay] = useState(
    MAX_ROWS_TO_DISPLAY_AT_ONCE
  );
  const addMoreRowsToDisplay = useCallback(() => {
    setRowCountToDisplay((rowCount) => rowCount + MAX_ROWS_TO_DISPLAY_AT_ONCE);
  }, []);
  const data = useMemo(
    () =>
      reverseMode
        ? sheet.slice(-rowCountToDisplay)
        : sheet.slice(0, rowCountToDisplay),
    [rowCountToDisplay, sheet, reverseMode]
  );
  const rowCountStillAvailableToDisplay = sheet.length - data.length;

  const columnVisibility = useMemo(() => {
    // transpose sheet row to col
    const transposedSheet = sheet[0]?.map((_, colIndex) =>
      sheet.map((row) => row[colIndex])
    );
    // hide empty columns from table
    return tableColumns.reduce(
      (columnVisibility, columnDef, colIndex) => ({
        ...columnVisibility,
        [columnDef.id!]: transposedSheet?.[colIndex].some(
          (cellContent) =>
            cellContent !== undefined &&
            cellContent !== null &&
            cellContent !== ""
        ),
      }),
      {} as VisibilityState
    );
  }, [sheet, tableColumns]);

  const table = useReactTable<any>({
    columns: tableColumns,
    pageCount: 1,
    data,
    getCoreRowModel: getCoreRowModel(),
    initialState: { columnVisibility, pagination: { pageSize: 100 } },
  });
  const handleSelect = (
    previousSelection: number[] | undefined,
    index: number
  ) => {
    const newSelection = transformSelection(
      previousSelection,
      index,
      allowMultipleSelections
    );
    columnMode
      ? setSelectedColumns(newSelection)
      : setSelectedRows(newSelection);
    onData(newSelection);
  };

  const displayMoreLines = useMemo(
    () => (
      <Row>
        <Col sm="auto" className="mx-auto">
          <Button
            onClick={addMoreRowsToDisplay}
            variant="link"
            size="sm"
            className="rectangular"
          >
            {t("display more rows", {
              rows: Math.min(
                rowCountStillAvailableToDisplay,
                MAX_ROWS_TO_DISPLAY_AT_ONCE
              ),
            })}
          </Button>
        </Col>
      </Row>
    ),
    [t, addMoreRowsToDisplay, rowCountStillAvailableToDisplay]
  );

  const tableContainerRef = useRef<HTMLTableElement>(null);
  const tableContainerHeight = useElementAvailableHeightOnScreen(
    tableContainerRef.current,
    130
  );

  return (
    <Col
      className={classNames(
        "d-flex flex-column align-items-center justify-content-center"
      )}
    >
      <span className="fw-bold mb-4 mt-2">
        {t(`${instructions}.1`)}
        <span className="text-info">{t(`${instructions}.2`)}</span>
        {isFrame && optionalStepVariant
          ? t(`${instructions}.4`)
          : t(`${instructions}.3`)}
      </span>
      {rowCountStillAvailableToDisplay > 0 && reverseMode && displayMoreLines}
      <div
        className={classNames("w-100 overflow-auto scroller mw-100")}
        ref={tableContainerRef}
        style={{
          height: tableContainerHeight,
        }}
      >
        <Table
          className={styles.selectable}
          table={table}
          hover={!columnMode}
          lineCountColumn
          lineCountOffset={
            reverseMode ? Math.max(sheet.length - rowCountToDisplay, 0) : 0
          }
          verticalBorders
          compact
          defaultTheme
          stickyHeaders
          onRowClick={(row, index) =>
            !columnMode &&
            handleSelect(
              selectedRows,
              reverseMode ? index + rowCountStillAvailableToDisplay : index
            )
          }
          onCellClick={(cell) =>
            columnMode && handleSelect(selectedColumns, Number(cell.column.id))
          }
          onColumnHeaderClick={(header) =>
            columnMode && handleSelect(selectedColumns, Number(header.id))
          }
          onColumnHeaderMouseOver={(header) =>
            columnMode && setHoveredColumn(Number(header.id))
          }
          onCellMouseOver={(cell) =>
            columnMode && setHoveredColumn(Number(cell.column.id))
          }
          getRowClassName={(row, rowIndex) =>
            classNames({
              [styles.selected]: selectedRows?.includes(
                reverseMode
                  ? rowIndex + rowCountStillAvailableToDisplay
                  : rowIndex
              ),
            })
          }
          getCellClassName={(cell) =>
            classNames({
              [styles.selected]: selectedColumns?.includes(
                Number(cell.column.id)
              ),
              [styles.hovered]:
                columnMode && hoveredColumn === Number(cell.column.id),
            })
          }
          getColumnHeaderClassName={(header) =>
            classNames({
              [styles.selected]: selectedColumns?.includes(Number(header.id)),
              [styles.hovered]:
                columnMode && hoveredColumn === Number(header.id),
            })
          }
          onMouseLeave={() => setHoveredColumn(undefined)}
        />
      </div>
      {rowCountStillAvailableToDisplay > 0 && !reverseMode && displayMoreLines}
    </Col>
  );
}

/**
 * Given a selection array and an item, either add or remove it from the current selection
 * if the item is undefined, returns an empty selection
 */
function transformSelection(
  selection?: number[],
  item?: number,
  allowMultiple?: boolean
) {
  if (item === undefined) {
    return [];
  }
  if (!selection) {
    selection = [];
  }
  const index = selection.findIndex((s) => s === item);
  if (index > -1) {
    if (!allowMultiple) {
      return [];
    }
    const newSelection = [...selection];
    // remove the item from the new selection array
    newSelection.splice(index, 1);
    return newSelection;
  } else {
    if (!allowMultiple) {
      return [item];
    }
    return [...(selection ?? []), item];
  }
}
