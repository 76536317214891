import { useQueryClient } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";

import { GuardedButton } from "../account/GuardedButton";
import { CUSTOM_DIMENSIONS_ENUM } from "../matomo";
import { useModal } from "../shared/DefaultModal";
import colors from "../shared/_exports.module.scss";
import { ExportTableToExcel } from "../shared/excel/ExportTableToExcel";
import { useTranslation } from "../shared/i18n";
import { CogIcon, PenIcon, SaveIcon } from "../shared/icons";
import { useHasChanged } from "../shared/useHasChanged";
import { QUERY_KEYS_ENUM, useCurrentLot } from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";
import { ifTest, useElementAvailableHeightOnScreen } from "../shared/utils";

import { useCompareDifferenceSettings } from "./CompareDifference";
import { CompareSettingsModal } from "./CompareSettingsModal";
import { CompareTable } from "./CompareTable";
import { ICompareRow } from "./CompareTable/CompareTable.types";
import { useCompareTable } from "./CompareTable/useCompareTable";
import {
  getCellAlignment,
  getCellColor,
  getTenderCellFontColor,
  mapCell,
} from "./ExportComparisonToExcel";
import { PriceNodeEditModal } from "./PriceNodeEditModal";
import { SaveComparisonModal } from "./SaveComparisonModal";
import { useCompareColumnDisplaySettings } from "./useCompareColumnDisplaySettings";
import { useComparedEstimateAndTenders } from "./useComparedEstimateAndTenders";
import { useSavedComparison } from "./useSavedComparison";

export function ComparePage() {
  const { t } = useTranslation("ComparePage");
  const { operationId, lotId } = useParams();
  const lot = useCurrentLot();

  const queryClient = useQueryClient();

  useTrackPageView(
    "ComparePage",
    [
      {
        id: CUSTOM_DIMENSIONS_ENUM.OPERATION_ID,
        value: String(operationId),
      },
      {
        id: CUSTOM_DIMENSIONS_ENUM.LOT_ID,
        value: String(lotId),
      },
    ],
    [operationId, lotId]
  );

  const {
    tenders,
    tendersCompanySortMode,
    estimate,
    isLoading,
    isInitialLoading,
    setTenders,
    setTendersCompanySortMode,
  } = useComparedEstimateAndTenders(lotId);

  const [compareDifferenceSettings, setCompareDifferenceSettings] =
    useCompareDifferenceSettings(isInitialLoading, estimate);

  const [compareColumnDisplaySettings, setCompareColumnDisplaySettings] =
    useCompareColumnDisplaySettings();

  const [
    isCompareSettingsModifiedSinceComparisonSave,
    setIsCompareSettingsModifiedSinceComparisonSave,
  ] = useState(true);
  const {
    savedComparison,
    setSavedComparison,
    isLoading: isSavedComparisonLoading,
  } = useSavedComparison();
  const hasSavedComparisonChanged = useHasChanged(savedComparison);
  if (hasSavedComparisonChanged) {
    setIsCompareSettingsModifiedSinceComparisonSave(!savedComparison);
  }

  const {
    show: showSettingsModal,
    handleClose: handleCloseSettingsModal,
    handleShow: handleShowSettingsModal,
  } = useModal("CompareSettingsModal");

  const {
    show: showSaveModal,
    handleClose: handleCloseSaveModal,
    handleShow: handleShowSaveModal,
  } = useModal("ComparisonSaveModal");

  const {
    show: showTenderPriceNodeEditModal,
    handleClose: handleClosePriceNodeEditModal,
    handleShow: handleShowPriceNodeEditModal,
  } = useModal("PriceNodeEditModal");

  const table = useCompareTable({
    baseEstimate: estimate,
    comparedDocuments: tenders,
    compareDifferenceSettings,
    compareColumnDisplaySettings,
    editEnabled: true,
    keepVariantOrOption: true,
  });

  const compareTableWrapperRef = useRef<HTMLDivElement>(null);
  const tableWrapperHeight = useElementAvailableHeightOnScreen(
    compareTableWrapperRef.current
  );
  return (
    <Container fluid className="pb-0 p-1 px-sm-2 px-md-3 px-lg-4 pt-md-4">
      <Row className="mb-4 m-0">
        <Col xs="auto" className="d-flex align-items-center px-0">
          <Button
            variant="outline-secondary"
            className="rounded me-3"
            onClick={handleShowSettingsModal}
            disabled={
              isInitialLoading ||
              !compareDifferenceSettings ||
              !compareColumnDisplaySettings
            }
          >
            <CogIcon className="me-2" /> {t("settings")}
          </Button>
          <CompareSettingsModal
            show={showSettingsModal}
            handleClose={handleCloseSettingsModal}
            tenders={tenders}
            tendersCompanySortMode={tendersCompanySortMode}
            estimate={estimate}
            differenceSettings={compareDifferenceSettings}
            columnDisplaySettings={compareColumnDisplaySettings}
            onSubmit={({
              display: { tenders, tendersCompanySortMode, columns },
              difference,
            }) => {
              setCompareColumnDisplaySettings(columns);
              setCompareDifferenceSettings(difference);
              setTenders(tenders);
              setTendersCompanySortMode(tendersCompanySortMode);
              setIsCompareSettingsModifiedSinceComparisonSave(true);
              handleCloseSettingsModal();
            }}
          />
          <GuardedButton
            variant="outline-secondary"
            className="rounded"
            onClick={handleShowPriceNodeEditModal}
            disabled={isLoading}
          >
            <PenIcon fill={colors.black} size={20} className="me-2" />
            {t("edit tender")}
          </GuardedButton>
          <PriceNodeEditModal
            show={showTenderPriceNodeEditModal}
            onClose={() => {
              handleClosePriceNodeEditModal();
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS_ENUM.TENDERS, lotId],
              });
            }}
            documents={tenders}
            base={estimate}
            initialExpanded={table.getState().expanded}
            compareColumnDisplaySettings={compareColumnDisplaySettings}
          />
        </Col>
        <Col xs="auto" className="ms-auto d-flex align-items-center">
          <GuardedButton
            onClick={handleShowSaveModal}
            disabled={
              !isCompareSettingsModifiedSinceComparisonSave ||
              isSavedComparisonLoading ||
              isLoading
            }
            size="lg"
            className="rounded"
            data-test={ifTest("save-comparison-button")}
          >
            <SaveIcon className="me-2" />
            {t("save")}
            {isSavedComparisonLoading && <Spinner className="ms-2" size="sm" />}
          </GuardedButton>
          <SaveComparisonModal
            show={showSaveModal}
            handleClose={(comparison) => {
              if (comparison !== undefined) {
                setIsCompareSettingsModifiedSinceComparisonSave(false);
                setSavedComparison(comparison.id);
              }
              handleCloseSaveModal();
            }}
            tenders={tenders}
            estimate={estimate}
            comparison={savedComparison}
            differenceSettings={compareDifferenceSettings}
            tendersCompanySortMode={tendersCompanySortMode}
            columnDisplaySettings={compareColumnDisplaySettings}
          />
        </Col>
        <Col xs="auto">
          <ExportTableToExcel<ICompareRow>
            table={table}
            isLoading={isLoading || !lot}
            fileName={`${t("comparison")}_${lot!.project_name}_${lot!.name}`}
            sheetName={t("comparison")}
            mapCell={mapCell}
            getCellAlignment={getCellAlignment}
            getCellColor={getCellColor}
            getFontColor={getTenderCellFontColor}
          />
        </Col>
      </Row>
      {isInitialLoading ? (
        <Row>
          <Col className="text-center">
            <Spinner />
          </Col>
        </Row>
      ) : (
        <div
          className="overflow-auto"
          ref={compareTableWrapperRef}
          style={{
            height: tableWrapperHeight,
          }}
        >
          <CompareTable table={table} isLoading={isLoading} />
        </div>
      )}
    </Container>
  );
}
