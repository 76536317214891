import { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import {
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import Nav from "react-bootstrap/Nav";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  NavLink,
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  PAGE_INDEX_SEARCH_PARAM,
  PAGE_SIZE_SEARCH_PARAM,
  SORTING_DESC_SEARCH_PARAM,
  SORTING_SEARCH_PARAM,
} from "../operations/OperationsTable";
import { formatPercentage, useTranslation } from "../shared/i18n";
import { formatTolerant } from "../shared/i18n/dateFormatter";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { Table } from "../shared/table";
import { CONVENTIONAL_ALLOTMENTS_ENUM } from "../shared/types/lot.types";
import { IMappingLotTenderPercentage } from "../shared/types/mapping.types";
import { IPaginatedQueryResults } from "../shared/types/paginatedQueryResult.types";
import { isDevelopmentEnv } from "../shared/utils";
import { Percentage } from "../shared/visualization/Percentage";

const emptyData: IMappingLotTenderPercentage[] = [];

function mapSearchParamsToBackendFieldName(
  searchParams: { sorting: SortingState },
  mapSearchParamsDict: { [key: string]: string }
) {
  return {
    ...searchParams,
    sorting: searchParams.sorting.map((sorting) => ({
      ...sorting,
      id: mapSearchParamsDict[sorting.id],
    })),
  };
}

export function MappingTendersTable<TData>({
  query,
  select,
  mapSearchParamsToBackendFieldNameDict,
  conventionalAllotment,
}: {
  query: (
    filters: any,
    options: UseQueryOptions<
      IPaginatedQueryResults<TData>,
      any,
      IPaginatedQueryResults<IMappingLotTenderPercentage>,
      any
    >
  ) => UseQueryResult<IPaginatedQueryResults<IMappingLotTenderPercentage>>;
  select: (
    results: IPaginatedQueryResults<TData>
  ) => IPaginatedQueryResults<IMappingLotTenderPercentage>;
  mapSearchParamsToBackendFieldNameDict: { [key: string]: string };
  conventionalAllotment: CONVENTIONAL_ALLOTMENTS_ENUM;
}) {
  const { t } = useTranslation("MappingTendersTable");

  const [searchParams, setSearchParams] = useSearchParams();

  const columnHelper = createColumnHelper<IMappingLotTenderPercentage>();

  const tendersColumns = useMemo(
    () => [
      columnHelper.accessor("project_name", {
        header: t("project_name"),
        cell: (context) => (
          <Nav.Link
            as={NavLink}
            to={`/${PATH_NAMES_ENUM.OPERATIONS}/${context.row.original.project}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {context.getValue()}
          </Nav.Link>
        ),
      }),
      columnHelper.accessor("allotment_name", {
        header: t("allotment_name"),
        cell: (context) => (
          <Nav.Link
            as={NavLink}
            to={`/${PATH_NAMES_ENUM.OPERATIONS}/${context.row.original.project}/${PATH_NAMES_ENUM.LOTS}/${context.row.original.allotment}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {context.getValue()}
          </Nav.Link>
        ),
      }),
      columnHelper.accessor("company_name", {
        header: t("company_name"),
        cell: (context) => (
          <Nav.Link
            as={NavLink}
            to={`/${PATH_NAMES_ENUM.OPERATIONS}/${context.row.original.project}/${PATH_NAMES_ENUM.LOTS}/${context.row.original.allotment}/${PATH_NAMES_ENUM.OFFERS}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {context.getValue()}
          </Nav.Link>
        ),
      }),
      columnHelper.accessor("closure_date", {
        header: t("closure_date"),
        cell: (props) => formatTolerant(props.getValue<Date>(), "yyyy-MM-dd"),
      }),
      columnHelper.accessor("percentage", {
        header: t("row_type_mapping_percentage"),
        cell: (context) => (
          <ProgressBar
            // convert to percentage, and shift values to always see the label
            now={(context.getValue() ?? 0) * 90 + 10}
            label={
              <Percentage
                percentage={context.getValue() ?? 0}
                tooltip={t("% of lines have been mapped", {
                  percentage: formatPercentage(
                    context.getValue() ?? 0,
                    "never"
                  ),
                })}
              />
            }
          />
        ),
      }),
    ],
    [t, columnHelper]
  );

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: searchParams.get(SORTING_SEARCH_PARAM) ?? "percentage",
      desc: Boolean(searchParams.get(SORTING_DESC_SEARCH_PARAM)),
    },
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: Number(searchParams.get(PAGE_INDEX_SEARCH_PARAM) ?? 0),
    pageSize: Number(searchParams.get(PAGE_SIZE_SEARCH_PARAM) ?? 15),
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const fetchOperationsOptions = useMemo(() => {
    return {
      pageSize,
      pageIndex,
      sorting,
      conventional_allotments: conventionalAllotment,
    };
  }, [pageSize, pageIndex, sorting, conventionalAllotment]);

  useEffect(() => {
    const { pageSize, pageIndex, sorting } = fetchOperationsOptions;
    const params: URLSearchParamsInit = {
      pageSize: String(pageSize),
      pageIndex: String(pageIndex),
    };
    if (sorting?.length > 0) {
      const [{ id, desc }] = sorting;
      params.sorting = id;
      if (desc) {
        params.sortingDesc = "true";
      }
    }
    setSearchParams(params, { replace: true });
  }, [fetchOperationsOptions, setSearchParams]);

  const lotsQuery = query(
    mapSearchParamsToBackendFieldName(
      fetchOperationsOptions,
      mapSearchParamsToBackendFieldNameDict
    ),
    { select }
  );

  const lots = lotsQuery.data?.results;

  const pageCount = lotsQuery.data?.num_pages ?? -1;

  const table = useReactTable<IMappingLotTenderPercentage>({
    columns: tendersColumns,
    data: lots ?? emptyData,
    filterFns: {},
    state: {
      sorting,
      pagination,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    debugTable: isDevelopmentEnv,
    pageCount: pageCount ?? -1,
    manualPagination: true,
    manualSorting: true,
  });

  const navigate = useNavigate();

  return (
    <>
      <div className="overflow-auto mt-3">
        <Table
          table={table}
          hover
          stickyHeaders
          defaultTheme
          className="mb-0"
          onRowClick={(row, _, event) => {
            const path = `/${PATH_NAMES_ENUM.MAPPING}/${PATH_NAMES_ENUM.OPERATIONS}/${row.original.project}/${PATH_NAMES_ENUM.LOTS}/${row.original.allotment}`;
            event.ctrlKey
              ? window.open(path)
              : navigate(path, {
                  state: { operation: row.original },
                });
          }}
          isLoading={
            lotsQuery.isLoading ||
            (lotsQuery.isFetching && !lotsQuery.isPreviousData)
          }
          footerClassName="mb-n6 bg-transparent position-absolute bottom-0 start-50 translate-middle-x"
        />
      </div>
    </>
  );
}
