import { useQueries } from "@tanstack/react-query";
import { useMemo, useState } from "react";

import { fetchTenderData } from "../api/fetchTenders";
import { TPriceEstimateWithTotal } from "../shared/types/estimate.types";
import { ITender } from "../shared/types/tender.types";
import { useHasChanged } from "../shared/useHasChanged";
import {
  QUERY_KEYS_ENUM,
  useCurrentLot,
  useEstimateDataQuery,
} from "../shared/useSharedQueries";

import { applyLogs, applyLogsGeneric } from "./CompareTable/CompareTableUtils";
import { mapLogs } from "./PriceNodeEditModal/PriceNodeEditTable";
import { useEstimateAndSortedTendersFromSearchParamsOrInit } from "./useEstimateAndSortedTendersFromSearchParamsOrInit";

export function useComparedEstimateAndTenders(lotId?: string) {
  const {
    estimate,
    tenders,
    tendersCompanySortMode,
    isLoading: isEstimateOrTendersLoading,
    isInitialLoading: isEstimateOrTendersInitialLoading,
    setEstimate,
    setTenders,
    setTendersCompanySortMode,
  } = useEstimateAndSortedTendersFromSearchParamsOrInit(lotId);

  const estimateId = estimate?.id;

  const [comparedEstimate, setComparedEstimate] =
    useState<TPriceEstimateWithTotal>();

  const estimateDataQuery = useEstimateDataQuery(estimateId);
  const lot = useCurrentLot();

  const estimateDataHasChanged = useHasChanged(estimateDataQuery.data);
  if (
    estimate !== undefined &&
    estimateDataQuery.isFetched &&
    estimateDataHasChanged
  ) {
    setComparedEstimate({
      ...estimate,
      data: estimateDataQuery.data ?? estimate.data,
      estimation_amount: lot?.estimation_amount!,
    });
  }

  const tenderIds = tenders.map((tender) => tender?.id);
  const [comparedTenders, setComparedTenders] = useState<ITender[]>([]);
  const tendersDataQueries = useQueries({
    queries:
      tenderIds?.map((id) => ({
        queryKey: [QUERY_KEYS_ENUM.TENDER_DATA, id],
        queryFn: () => fetchTenderData(id!),
        enabled: Boolean(id),
      })) ?? [],
  });
  const isTendersDataFetching = tendersDataQueries.some(
    (query) => query.isFetching
  );
  const tendersDataFetchingStatusHasChanged = useHasChanged(
    isTendersDataFetching
  );
  const tendersHasChanged = useHasChanged(...tenders);

  if (
    !isTendersDataFetching &&
    (tendersDataFetchingStatusHasChanged || tendersHasChanged) &&
    tenders
  ) {
    setComparedTenders(
      tenders
        .map((tender, index) =>
          tender
            ? {
                ...tender,
                data: tendersDataQueries[index].data ?? tender.data,
              }
            : undefined
        )
        .filter((tender) => tender !== undefined) as ITender[]
    );
  }

  const isLoading =
    isEstimateOrTendersLoading ||
    estimateDataQuery.isLoading ||
    tendersDataQueries.some((query) => query.isLoading);
  const isInitialLoading =
    isEstimateOrTendersInitialLoading ||
    estimateDataQuery.isInitialLoading ||
    tendersDataQueries.some((query) => query.isInitialLoading);

  const comparedEstimateWithLogs = useMemo(() => {
    if (comparedEstimate) {
      const [logMap, genericLogs] = mapLogs(comparedEstimate);

      return applyLogsGeneric(applyLogs(comparedEstimate, logMap), genericLogs);
    } else {
      return comparedEstimate;
    }
  }, [comparedEstimate]);

  const comparedTendersWithLogs = useMemo(() => {
    return comparedTenders.map((tender) => {
      const [logMap, genericLogs] = mapLogs(tender);

      return applyLogsGeneric(applyLogs(tender, logMap), genericLogs);
    });
  }, [comparedTenders]);

  return {
    estimate: comparedEstimateWithLogs,
    tenders: comparedTendersWithLogs,
    tendersCompanySortMode,
    isLoading,
    isInitialLoading,
    setEstimate,
    setTenders,
    setTendersCompanySortMode,
  };
}
