import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Control } from "react-hook-form";
import * as yup from "yup";

import { useTranslation } from "../../../shared/i18n";
import { ControlledInput, PartitionLiningSelect } from "../../../shared/inputs";
import { getUndefinedSafeSelectOnChange } from "../../../shared/inputs/input";
import {
  CONVENTIONAL_ALLOTMENTS_ENUM,
  LOT_CLOSURE_PARTITION_WALLS_INSULATION_ENUM,
  LOT_CLOSURE_THERMAL_INSULATION_ENUM,
} from "../../../shared/types/lot.types";

export const schema = yup.object({
  partition_walls_insulation: yup
    .string()
    .oneOf(Object.values(LOT_CLOSURE_PARTITION_WALLS_INSULATION_ENUM))
    .when(["conventional_allotments", "thermal_insulation"], {
      is: (
        conventional_allotments?: CONVENTIONAL_ALLOTMENTS_ENUM[],
        thermal_insulation?: LOT_CLOSURE_THERMAL_INSULATION_ENUM
      ) =>
        conventional_allotments?.includes(
          CONVENTIONAL_ALLOTMENTS_ENUM.PARTITIONS_LININGS
        ) &&
        thermal_insulation?.includes(
          LOT_CLOSURE_THERMAL_INSULATION_ENUM.interior_insulation
        ),
      then: (field) => field.nonNullable().required(),
      otherwise: (field) => field.nullable(),
    }),
});

export type TFormInputs = yup.InferType<typeof schema>;

export function PartitionLiningAllotment({
  control,
  partition_walls_insulation_required,
}: {
  control: Control<TFormInputs>;
  partition_walls_insulation_required: boolean | undefined;
}) {
  const { t } = useTranslation("LotClosureModal");

  return (
    <>
      <h6 className="my-4">{t("partition lining title")}</h6>
      <Row className="mb-3">
        <ControlledInput
          as={Col}
          md={6}
          required={partition_walls_insulation_required}
          label={t("partition walls insulation")}
          name="partition_walls_insulation"
          schema={schema}
          control={control}
          render={({ field }) => (
            <PartitionLiningSelect
              {...field}
              onChange={getUndefinedSafeSelectOnChange(field)}
            />
          )}
        />
      </Row>
    </>
  );
}
