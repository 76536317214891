import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function SaveIcon({ fill = styles.white, className = "", size = 18 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      height={applyFontRatioToPxSize(size)}
      width={applyFontRatioToPxSize(size)}
      viewBox="0 0 18 18"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M14 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V4l-4-4ZM9 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3ZM2 6h10V2H2v4Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
