import classnames from "classnames";
import React, {
  FocusEventHandler,
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import { INPUT_TYPES_ENUM } from "../input";

export interface ISelectTextInputProps {
  value: string;
  onChange: (value: string) => void;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onFocus: FocusEventHandler<HTMLInputElement>;
  className: string;
  disabled: boolean;
  id?: string;
}

export const SelectTextInput = React.forwardRef<
  HTMLInputElement,
  ISelectTextInputProps
>(({ value, onChange, className, onKeyDown, onBlur, disabled, id }, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputWidth, setInputWidth] = useState(1);
  useEffect(() => {
    inputRef.current && value && setInputWidth(value.length + 1);
  }, [value]);
  return (
    <input
      id={id}
      ref={(el) => {
        inputRef.current = el;
        if (ref) {
          (ref as MutableRefObject<HTMLInputElement | null>).current = el;
        }
      }}
      autoCapitalize="none"
      autoComplete="off"
      autoCorrect="off"
      className={classnames("select-text-input", className)}
      spellCheck="false"
      tabIndex={0}
      type={INPUT_TYPES_ENUM.TEXT}
      aria-autocomplete="list"
      value={value}
      onClick={(event) => event.stopPropagation()}
      onChange={({ target: { value } }) => {
        onChange(value);
      }}
      style={{ width: inputWidth + "ch" }}
      {...{ onKeyDown, onBlur, disabled }}
    />
  );
});
