import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function CompareIcon({
  fill = styles.white,
  className = "",
  size = 37,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize((size / 37) * 26)}
      viewBox="0 0 37 26"
      fill="none"
      className={className}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M23.878 9.334v5.5L16.562 7.5 23.878.167v5.5h12.851v3.667H23.878ZM.062 16.667h12.852v-5.5L20.23 18.5l-7.315 7.334v-5.5H.063v-3.667Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
