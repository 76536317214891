import React from "react";

import styles from "../_exports.module.scss";

import { applyFontRatioToPxSize } from "./iconSizeUtil";

export function EmailIcon({
  className = "",
  fill = styles.teal900,
  size = 25,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={applyFontRatioToPxSize(size)}
      height={applyFontRatioToPxSize(size)}
      viewBox="0 0 25 25"
      fill="none"
      className={className}
    >
      <g clipPath="url(#email-icon)">
        <path
          fill={fill}
          d="M3.5 3.053h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-18a1 1 0 0 1-1-1v-16a1 1 0 0 1 1-1Zm9.06 8.683L6.148 6.29 4.853 7.815l7.72 6.555 7.581-6.56-1.308-1.513-6.285 5.439h-.001Z"
        />
      </g>
      <defs>
        <clipPath id="email-icon">
          <path fill="#fff" d="M.5.053h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
