import React from "react";
import { Noop } from "react-hook-form";

import { useEnumTranslation, useTranslation } from "../i18n";
import { CONVENTIONAL_ALLOTMENTS_ENUM } from "../types/lot.types";

import { Select, TValue } from "./select";

export interface IAllotmentSelectProps {
  onChange?: (value?: TValue | TValue[]) => void;
  onBlur?: Noop;
  value?: string[] | string;
  multi?: boolean;
  closeMenuOnSelect?: boolean;
}

export const CONVENTIONAL_ALLOTMENTS = [
  {
    value: "STRUCTURAL_WORK_TITLE",
    options: [
      CONVENTIONAL_ALLOTMENTS_ENUM.STRUCTURAL_WORK,
      CONVENTIONAL_ALLOTMENTS_ENUM.DEMOLITION,
      CONVENTIONAL_ALLOTMENTS_ENUM.CLEANING_WORK,
      CONVENTIONAL_ALLOTMENTS_ENUM.ASBESTOS_REMOVAL,
      CONVENTIONAL_ALLOTMENTS_ENUM.EARTHWORK,
      CONVENTIONAL_ALLOTMENTS_ENUM.FOUNDATIONS,
      CONVENTIONAL_ALLOTMENTS_ENUM.SITE_INSTALLATION,
      CONVENTIONAL_ALLOTMENTS_ENUM.ROADS_MISCELLANEOUS_NETWORKS,
      CONVENTIONAL_ALLOTMENTS_ENUM.GREEN_SPACES,
    ],
  },
  {
    value: "CLOSED_AND_COVERED",
    options: [
      CONVENTIONAL_ALLOTMENTS_ENUM.FACADES,
      CONVENTIONAL_ALLOTMENTS_ENUM.SEALING,
      CONVENTIONAL_ALLOTMENTS_ENUM.WOOD_FRAME,
      CONVENTIONAL_ALLOTMENTS_ENUM.METAL_FRAME,
      CONVENTIONAL_ALLOTMENTS_ENUM.BLANKET,
      CONVENTIONAL_ALLOTMENTS_ENUM.EXTERIOR_WOOD_FURNISHINGS,
    ],
  },
  {
    value: "TECHNICAL_TRADES",
    options: [
      CONVENTIONAL_ALLOTMENTS_ENUM.HVAC,
      CONVENTIONAL_ALLOTMENTS_ENUM.PLUMBING,
      CONVENTIONAL_ALLOTMENTS_ENUM.HIGH_VOLTAGE_ELECTRICAL_SYSTEMS,
      CONVENTIONAL_ALLOTMENTS_ENUM.LOW_VOLTAGE_ELECTRICAL_SYSTEMS,
      CONVENTIONAL_ALLOTMENTS_ENUM.FIRE_SAFETY_SYSTEM,
      CONVENTIONAL_ALLOTMENTS_ENUM.TECHNICAL_BUILDING_MANAGEMENT,
      CONVENTIONAL_ALLOTMENTS_ENUM.ELEVATING_DEVICES,
      CONVENTIONAL_ALLOTMENTS_ENUM.GEOTHERMAL,
      CONVENTIONAL_ALLOTMENTS_ENUM.SPRINKLER,
      CONVENTIONAL_ALLOTMENTS_ENUM.TRANSFORMER,
      CONVENTIONAL_ALLOTMENTS_ENUM.DOCK_EQUIPMENT,
      CONVENTIONAL_ALLOTMENTS_ENUM.KITCHEN,
      CONVENTIONAL_ALLOTMENTS_ENUM.INDUSTRIAL_DOORS,
    ],
  },
  {
    value: "ARCHITECTURAL_TRADES",
    options: [
      CONVENTIONAL_ALLOTMENTS_ENUM.LOCKSMITH_METALWORK,
      CONVENTIONAL_ALLOTMENTS_ENUM.PARTITIONS_LININGS,
      CONVENTIONAL_ALLOTMENTS_ENUM.FALSE_CEILINGS,
      CONVENTIONAL_ALLOTMENTS_ENUM.INTERIOR_JOINERY,
      CONVENTIONAL_ALLOTMENTS_ENUM.HARD_FLOOR_COVERINGS,
      CONVENTIONAL_ALLOTMENTS_ENUM.FLEXIBLE_FLOOR_COVERINGS,
      CONVENTIONAL_ALLOTMENTS_ENUM.PAINT,
      CONVENTIONAL_ALLOTMENTS_ENUM.LAYOUT,
      CONVENTIONAL_ALLOTMENTS_ENUM.CLEANING,
      CONVENTIONAL_ALLOTMENTS_ENUM.FLOOR_RESIN,
    ],
  },
];

export const AllotmentSelect = React.forwardRef<
  HTMLInputElement,
  IAllotmentSelectProps
>(function AllotmentSelect(
  { onChange, onBlur, value, multi = true, closeMenuOnSelect = false },
  ref
) {
  const { tEnum } = useEnumTranslation();
  const { t } = useTranslation("AllotmentSelect");

  const allotmentOptions = Object.values(CONVENTIONAL_ALLOTMENTS).map(
    ({ value, options }) => ({
      option: value,
      label: tEnum(value),
      options: options.map((value) => ({ value, label: tEnum(value) })),
    })
  );

  return (
    <Select
      options={allotmentOptions}
      onChange={onChange}
      onBlur={onBlur}
      value={value as any}
      ref={ref}
      searchable
      multi={multi as any}
      closeMenuOnSelect={closeMenuOnSelect}
      autoFocus
      placeholder={t("select allotment")}
    />
  );
});
