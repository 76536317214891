import classNames from "classnames";
import React, { useMemo, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tooltip from "react-bootstrap/Tooltip";
import { GrSort } from "react-icons/gr";
import { useParams } from "react-router-dom";

import styles from "../lots/LotsPage.module.scss";
import { Header, INav } from "../shared/header";
import { useTranslation } from "../shared/i18n";
import { BackArrowIcon, ListCircleIcon } from "../shared/icons";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { CONVENTIONAL_ALLOTMENTS_ENUM } from "../shared/types/lot.types";
import { useTrackPageView } from "../shared/useTrackPageView";

import { MappingLotsSelectedTable } from "./MappingLotsSelectedTable";
import { MappingTendersMappedTable } from "./MappingTendersMappedTable";

enum TAB_ENUM {
  SELECTED = "selected",
  MAPPED = "mapped",
}

export function MappingTendersPage() {
  useTrackPageView("MappingTenders");

  const { t } = useTranslation("MappingTendersPage");

  const conventionalAllotment = useParams()
    .conventionalAllotment as CONVENTIONAL_ALLOTMENTS_ENUM;

  const [activeKey, setActiveKey] = useState(TAB_ENUM.SELECTED);

  const lotScopeNavList: INav[] = useMemo(
    () => [
      {
        Icon: BackArrowIcon,
        path: `/${PATH_NAMES_ENUM.MAPPING}`,
        back: true,
        overlay: <Tooltip>{t("returnMapping")}</Tooltip>,
        id: "navReturnMapping",
      },
    ],
    [t]
  );

  return (
    <div className="min-h-100 d-flex flex-column">
      <Header variant="dark" nav={lotScopeNavList} />
      <Container fluid className="bg-light flex-grow-1 pb-1">
        <h2>{t("title")}</h2>
        <Tab.Container
          id="lots-tabs"
          activeKey={activeKey}
          onSelect={(eventKey) => {
            if (eventKey) {
              setActiveKey(eventKey as TAB_ENUM);
            }
          }}
        >
          <Container fluid>
            <Card className="mx-md-4 w-auto border-0">
              <Card.Body className="p-0 p-md-4">
                <Nav as={Row} className="mb-3 align-items-end">
                  <Nav.Item
                    as={Col}
                    className={classNames(
                      { [styles.border]: activeKey === TAB_ENUM.SELECTED },
                      styles.borderHover,
                      "d-flex justify-content-center align-items-center text-center p-1 fw-bold"
                    )}
                  >
                    <Nav.Link eventKey={TAB_ENUM.SELECTED} className="w-100">
                      <ListCircleIcon className="mx-3" />
                      {t("list of tenders", { conventionalAllotment })}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    as={Col}
                    className={classNames(
                      { [styles.border]: activeKey === TAB_ENUM.MAPPED },
                      styles.borderHover,
                      "d-flex justify-content-center align-items-center text-center pb-2"
                    )}
                  >
                    <Nav.Link eventKey={TAB_ENUM.MAPPED} className="w-100">
                      <GrSort className="mx-1" />
                      {t("list of mapped tenders", { conventionalAllotment })}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey={TAB_ENUM.SELECTED} mountOnEnter>
                    <MappingLotsSelectedTable
                      conventionalAllotment={conventionalAllotment!}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={TAB_ENUM.MAPPED} mountOnEnter>
                    <MappingTendersMappedTable
                      conventionalAllotment={conventionalAllotment!}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Container>
        </Tab.Container>
      </Container>
    </div>
  );
}
