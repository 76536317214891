import { CellContext, Row } from "@tanstack/react-table";
import classNames from "classnames";
import { distance } from "fastest-levenshtein";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import styles from "../compare/CompareTable/CompareTable.module.scss";
import { ICompareRow } from "../compare/CompareTable/CompareTable.types";
import { isHeadingId } from "../compare/CompareTable/useCompareTable";
import colors from "../shared/_exports.module.scss";
import { useTranslation } from "../shared/i18n";
import { Select } from "../shared/inputs";
import { IOption, TValue, getLabelAsString } from "../shared/inputs/select";
import { IRowType } from "../shared/types/rowType.types";
import {
  DEFAULT_EMPTY_VALUE_PLACEHOLDER,
  accessNested,
  cancelEvent,
} from "../shared/utils";

import standardDocumentStyles from "./MappingToolPage.module.scss";
import { splitCellId } from "./MappingUtils";
import { IMappingPriceNode, IStdUnitMeta } from "./mapping.types";

export type TSetInstructionType = (
  subject_row: number,
  accessor: string,
  type: string | null
) => void;

export function RowTypeCell({
  props,
  typeOptions,
  setInstructionType,
  setActiveRow,
  setActiveOption,
  rowTypes,
}: {
  props: CellContext<ICompareRow, any>;
  typeOptions?: IOption<IStdUnitMeta>[];
  setInstructionType: TSetInstructionType;
  setActiveRow: (
    activeRow: { accessor: string; row_number: number } | undefined
  ) => void;
  setActiveOption: Dispatch<SetStateAction<TValue | undefined>>;
  rowTypes?: IRowType[];
}) {
  const { t } = useTranslation("MappingToolPage");
  const accessor = splitCellId(props.cell.id);
  const documentRow = accessNested(props.row.original, accessor);

  const value = props.getValue();
  const [initialValue] = useState(value);
  const options = useMemo(() => {
    const options: IOption<IStdUnitMeta>[] = [];
    if (typeOptions) {
      options.push(...typeOptions);
    }
    if (isValueMissing(initialValue, typeOptions)) {
      options.push({ label: initialValue, value: initialValue });
    }
    return options;
  }, [typeOptions, initialValue]);

  const rowType = useMemo(
    () =>
      rowTypes?.find(
        (rowType) => rowType.id === value || rowType.ref === value
      ),
    [rowTypes, value]
  );

  const color = useMemo(() => {
    const documentRow: IMappingPriceNode = accessNested(
      props.row.original,
      accessor
    );

    return getColor(rowType, documentRow);
  }, [accessor, props.row, rowType]);

  const changeValue = useCallback(
    (newValue: any) => {
      setInstruction(
        props.row,
        accessor,
        newValue,
        setInstructionType,
        rowTypes
      );
    },
    [props.row, accessor, setInstructionType, rowTypes]
  );

  if (isHeadingId(props.row.original.id) || !documentRow) {
    return <>{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</>;
  }

  return (
    <div
      onClick={(e) => {
        cancelEvent(e);
        setActiveRow({ accessor, row_number: documentRow.row_number });
        setActiveOption(documentRow.type);
      }}
      onBlur={(e) => {
        // make row and option inactive onBlur
        // the delay is required to enable the selection by the click on a classification row
        setTimeout(() => {
          cancelEvent(e);
          setActiveRow(undefined);
          setActiveOption(undefined);
        }, 500);
      }}
    >
      <Select
        searchable
        searchFn={(options, value) => {
          const parent = props.row.getParentRow()?.original;
          return sortFn(
            options as IOption[],
            value || (props.row.original.designation as string),
            props.row.original,
            parent && accessNested(parent, accessor)?.type
          );
        }}
        onFocusOption={setActiveOption}
        placeholder={t("row-type")}
        options={options ?? []}
        iconColor={color}
        className={classNames(
          color === colors.warning && styles.warning,
          color === colors.green200 && styles.manual,
          isValueMissing(value, typeOptions) && styles.missing,
          standardDocumentStyles["select-width"],
          "text-start"
        )}
        value={props.getValue()}
        onChange={changeValue}
        compact
      />
    </div>
  );
}

function setInstruction(
  row: Row<ICompareRow>,
  accessor: string,
  newValue: string,
  setInstructionType: TSetInstructionType,
  rowTypes: IRowType[] | undefined
) {
  const documentRow = accessNested(row.original, accessor);
  if (!documentRow || newValue === documentRow?.type) {
    return;
  }
  const rowType = rowTypes?.find(
    (rowType) => rowType.id === newValue || rowType.ref === newValue
  );
  // replace undefined value by null to delete type
  const type = rowType?.ref ?? newValue ?? null;
  setInstructionType(documentRow.row_number, accessor, type);
}

function getColor(rowType: IRowType | undefined, cell: IMappingPriceNode) {
  return rowType
    ? cell?.unit && standardizeUnit(cell.unit) !== standardizeUnit(rowType.unit)
      ? colors.warning
      : colors.green200
    : undefined;
}

function isValueMissing(value?: string, typeOptions?: IOption<IStdUnitMeta>[]) {
  return (
    value &&
    (!typeOptions ||
      typeOptions.findIndex((option) => option.value === value) === -1)
  );
}

/**
 *
 * @param unit
 * @returns lowerCase and trimmed unit if truthy
 */
function standardizeUnit(unit?: string) {
  return unit?.toLowerCase().trim();
}

interface IDistance {
  _distance: number;
}

function sortFn(
  options: IOption[],
  value: string,
  current: ICompareRow,
  parentType?: string
): IOption[] {
  options.forEach(
    (option) =>
      ((option as unknown as IDistance)._distance = computeDistance(
        option,
        value?.toLowerCase(),
        current,
        parentType
      ))
  );

  const sorted_options = options.sort(
    (a, b) =>
      (a as unknown as IDistance)._distance -
      (b as unknown as IDistance)._distance
  );
  return sorted_options;
}

const PARENT_TYPE_WEIGHT = 1;
const CHILD_TYPE_WEIGHT = 0.1;
const TYPE_WEIGHT = 100;
const SEARCH_WEIGHT = 10;
const DESIGNATION_WEIGHT = 1;
const UNIT_WEIGHT = 0.1;
function computeDistance(
  option: IOption<IStdUnitMeta>,
  value: string,
  current: ICompareRow,
  parentType?: string
): number {
  const parentTypeDistance =
    computeTypeDistance(option, parentType) * TYPE_WEIGHT;
  const searchDistance =
    computeTypeDistance(option, value) * TYPE_WEIGHT +
    computeStringDistance(option, value) * SEARCH_WEIGHT +
    computeLevenshteinDistance(option, value) * SEARCH_WEIGHT;
  const designationStringDistance =
    computeStringDistance(option, current.designation as string) *
    DESIGNATION_WEIGHT;
  const designationLevenshteinDistance =
    computeLevenshteinDistance(option, current.designation as string) *
    DESIGNATION_WEIGHT;
  const unitDistance = computeUnitDistance(current, option) * UNIT_WEIGHT;
  return (
    parentTypeDistance +
    searchDistance +
    designationStringDistance +
    designationLevenshteinDistance +
    unitDistance
  );
}

function computeUnitDistance(
  current: ICompareRow,
  option: IOption<IStdUnitMeta>
) {
  return Number(
    Boolean(current.unit) &&
      current.unit!.toLowerCase() !== option.meta!.unit?.toLowerCase()
  );
}

function computeStringDistance(option: IOption<IStdUnitMeta>, value: string) {
  return (
    Number(
      option.value?.toString().toLowerCase().includes(value) ||
        getLabelAsString(option.label ?? "")
          .toLowerCase()
          .includes(value) ||
        option.meta?.unit?.includes(value)
    ) * -10
  );
}

const SIGMOID = 50;
const LEVENSHTEIN_WEIGHT = 10;
function computeLevenshteinDistance(
  option: IOption<IStdUnitMeta>,
  value: string
) {
  const label = getLabelAsString(option.label ?? "").toLowerCase();
  let dist =
    distance(label, value?.toLowerCase() ?? "") -
    Math.abs(label.length - (value ?? "").length);

  dist = (dist / (dist + SIGMOID)) * LEVENSHTEIN_WEIGHT;
  return dist;
}

export const PARTIAL_ROW_TYPE_RE = /^([^.]{3})(\.[^.]{3})*(\.[^.]{0,3})?$/;

function computeTypeDistance(
  option: IOption<IStdUnitMeta>,
  otherType?: string
): number {
  if (!PARTIAL_ROW_TYPE_RE.test(otherType as string)) {
    return 0;
  }
  const otherTypes = (otherType ?? "").toLowerCase().split(".");
  const optionTypes = (option.value as string).toLowerCase().split(".");
  let typeDistance =
    otherTypes.length * PARENT_TYPE_WEIGHT +
    optionTypes.length * CHILD_TYPE_WEIGHT;
  for (
    let i = 0;
    i < Math.min(otherTypes?.length ?? 0, optionTypes.length);
    i++
  ) {
    if (optionTypes[i] === otherTypes[i]) {
      typeDistance -= PARENT_TYPE_WEIGHT + CHILD_TYPE_WEIGHT;
    } else {
      break;
    }
  }
  // handicap identical row_type
  if ((option.value as string) === otherType) {
    return CHILD_TYPE_WEIGHT * 9;
  }
  return typeDistance;
}
