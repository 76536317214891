import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { FieldError, Path, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { UserContext } from "../account/UserContext";
import { createOperation, updateOperation } from "../api/fetchOperations";
import { PATH_NAMES_ENUM } from "../shared/pathNames";

import { ModalCancelButton } from "./ModalCancelButton";
import {
  FormControlError,
  IBackErrors,
  RootError,
  useBackFieldErrors,
} from "./errors";
import { useTranslation, yupLocale } from "./i18n";
import { formatTolerant } from "./i18n/dateFormatter";
import {
  Input,
  RequiredAsterisk,
  WorkTypeSelect,
  getInputProps,
} from "./inputs";
import { AddressSelect } from "./inputs/AddressSelect";
import { BuildingCategorySelect } from "./inputs/BuildingCategorySelect";
import {
  ControlledInput,
  getUndefinedSafeSelectOnChange,
} from "./inputs/input";
import { ConfirmationModal } from "./modals";
import {
  GEO_FEATURE_TYPES_ENUM,
  ILocation,
  geoFeatureToLocationMapper,
  locationToGeoFeatureMapper,
} from "./types/location.types";
import {
  BUILDING_CATEGORY,
  CONTRACT_TYPE_ENUM,
  IOperation,
  OFFICE_BUILDING_CATEGORY_ENUM,
  STATUS_ENUM,
  WORK_TYPE_ENUM,
} from "./types/operation.types";
import {
  QUERY_KEYS_ENUM,
  useOrganizationsQuery,
  useWorksQuery,
} from "./useSharedQueries";

yup.setLocale(yupLocale);

const locationSchema = yup.object({
  location_osm_id: yup.number().required(),
  location_name: yup.string().optional().nullable(),
  location_type: yup
    .mixed<GEO_FEATURE_TYPES_ENUM>()
    .oneOf(Object.values(GEO_FEATURE_TYPES_ENUM))
    .required(),
  location_housenumber: yup.string().optional(),
  location_state: yup.string().optional(),
  location_country_code: yup.string().required(),
  location_street: yup.string().optional(),
  location_postcode: yup.string().optional(),
  location_city: yup.string().optional(),
  location_district: yup.string().optional(),
  location_country: yup.string().required(),
  location_geometry: yup.mixed().optional(),
});

const schema = locationSchema
  .concat(
    yup.object({
      id: yup.string().nullable(),
      name: yup.string().required(),
      reference_code: yup.string().required(),
      permit_date: yup
        .date()
        .nullable()
        .transform((v) => (!isNaN(v) ? v : null)),
      tender_date: yup.date().required(),

      status: yup
        .mixed<STATUS_ENUM>()
        .oneOf(Object.values(STATUS_ENUM))
        .required(),
      contract_type: yup
        .mixed<CONTRACT_TYPE_ENUM>()
        .oneOf(Object.values(CONTRACT_TYPE_ENUM))
        .required(),
      floor_area: yup.number().positive().integer().required(),
      work_type: yup
        .array()
        .of(
          yup
            .mixed<WORK_TYPE_ENUM>()
            .oneOf(Object.values(WORK_TYPE_ENUM))
            .required()
        )
        .min(1)
        .required(),
      building_category: yup.mixed<BUILDING_CATEGORY>().required(),
      original_author: yup.string().required(),
    })
  )
  .required();

export type TOperationCreationLocationFormInputs = yup.InferType<
  typeof locationSchema
>;
export type TOperationCreationFormInputs = yup.InferType<typeof schema>;

const locationFieldNames: Path<TOperationCreationLocationFormInputs>[] = [
  "location_osm_id",
  "location_name",
  "location_housenumber",
  "location_street",
  "location_postcode",
  "location_city",
  "location_district",
  "location_state",
  "location_country",
  "location_country_code",
  "location_geometry",
  "location_type",
];

export function OperationModal({
  show,
  onHide,
  operation,
}: {
  show: boolean;
  onHide: () => void;
  /** edit operation if present, create new otherwise */
  operation?: IOperation;
}) {
  const { t } = useTranslation("OperationCreationModal");
  const { user } = useContext(UserContext);
  const [showConfirmationDiscardModal, setShowConfirmationDiscardModal] =
    useState(false);

  const {
    register,
    control,
    setValue,
    resetField,
    handleSubmit,
    setError,
    clearErrors,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, isDirty, dirtyFields }, // dirtyFields required to update isDirty
  } = useForm<TOperationCreationFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: operation
      ? {
          ...operation,
          building_category: operation.building_categories[0],
          work_type: operation.work_type,
          permit_date: operation.permit_date
            ? (formatTolerant(
                operation.permit_date,
                "yyyy-MM-dd"
              ) as unknown as Date)
            : undefined,
          tender_date: formatTolerant(
            operation.tender_date,
            "yyyy-MM-dd"
          ) as unknown as Date,
        }
      : {
          name: "",
          reference_code: "",
          permit_date: null,
          tender_date: formatTolerant(
            new Date(),
            "yyyy-MM-dd"
          ) as unknown as Date,
          status: STATUS_ENUM.IN_PROGRESS,
          contract_type: CONTRACT_TYPE_ENUM.PRIVATE_CLASSICAL,
          floor_area: 0,
          work_type: [WORK_TYPE_ENUM.NEW_ON_AN_EMPTY_PLOT],
          building_category:
            OFFICE_BUILDING_CATEGORY_ENUM.OFFICE__ADMINISTRATIVE,
          original_author: user?.id,
        },
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const operationCreation = useMutation({
    mutationFn: createOperation,
    onSuccess: (operationId) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.OPERATIONS] });
      onHide();
      navigate(`/${PATH_NAMES_ENUM.OPERATIONS}/${operationId}`);
    },
  });
  const operationUpdate = useMutation({
    mutationFn: updateOperation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.OPERATION, operation!.id],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.OPERATIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.WORKS, operation!.id],
      });
      onHide();
    },
  });

  useBackFieldErrors(
    (operationUpdate.error ??
      operationCreation.error) as IBackErrors<TOperationCreationFormInputs>,
    setError,
    clearErrors
  );

  const isLoading = operationCreation.isLoading || operationUpdate.isLoading;
  const handleClose = () =>
    operation && isDirty ? setShowConfirmationDiscardModal(true) : onHide();
  const firstLocationError = locationFieldNames
    .map((fieldName) =>
      errors[fieldName]
        ? {
            type: "error",
            message: t(`invalid address`),
          }
        : undefined
    )
    .find(Boolean);
  const organizationQuery = useOrganizationsQuery({
    // only keep organizations for which the user has can_write permission
    select: (organizations) =>
      organizations?.find(({ rights: { can_write } }) => can_write)?.id,
  });
  const organizationId = organizationQuery.data;

  const worksQuery = useWorksQuery(operation?.id);
  const [work] = worksQuery.data ?? [];

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <ConfirmationModal
        title={t("discard changes")}
        show={showConfirmationDiscardModal}
        handleClose={(confirm) => {
          setShowConfirmationDiscardModal(false);
          confirm && onHide();
        }}
      />
      <Form
        onSubmit={handleSubmit((formInputs) => {
          if (organizationId) {
            operation
              ? operationUpdate.mutate(formInputs)
              : operationCreation.mutate({
                  ...formInputs,
                  organization: organizationId,
                });
          }
        })}
      >
        <Modal.Header className="justify-content-md-center">
          <ModalCancelButton onCancel={handleClose} />
          <Modal.Title as="h5">
            {t(operation ? "title modification" : "title creation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form.Control
            hidden
            {...register("original_author", { value: user?.id })}
          />
          <Row className="mb-3">
            <Input
              as={Col}
              label={t("referenceLabel")}
              {...getInputProps("reference_code", register, schema, errors)}
            />
            <Input
              as={Col}
              {...getInputProps("name", register, schema, errors)}
              label={t("nameLabel")}
            />
          </Row>
          <Row className="mb-3">
            <ControlledInput
              as={Col}
              name="building_category"
              label={t("categoryLabel")}
              schema={schema}
              control={control}
              render={({ field }) => (
                <BuildingCategorySelect
                  {...field}
                  onChange={getUndefinedSafeSelectOnChange(field)}
                />
              )}
            />
            <Input
              as={Col}
              {...getInputProps("permit_date", register, schema, errors)}
              label={t("permitDateLabel")}
              placeholder={t("optional")}
            />
          </Row>
          <Row className="mb-3">
            <Input
              as={Col}
              {...getInputProps("floor_area", register, schema, errors)}
              label={t("floorAreaLabel")}
              placeholder="m²"
            />
            <Input
              as={Col}
              {...getInputProps("tender_date", register, schema, errors)}
              label={t("tenderDateLabel")}
            />
            <ControlledInput
              as={Col}
              name="work_type"
              label={t("workTypeLabel")}
              schema={schema}
              control={control}
              render={({ field }) => (
                <WorkTypeSelect
                  {...field}
                  onChange={getUndefinedSafeSelectOnChange(field)}
                  multi
                />
              )}
            />
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="operationCreationWorkType">
              <Form.Label>
                {t("addressLabel")}
                <RequiredAsterisk />
              </Form.Label>
              {!operation || work ? (
                <AddressSelect
                  layers={[
                    GEO_FEATURE_TYPES_ENUM.CITY,
                    GEO_FEATURE_TYPES_ENUM.STREET,
                    GEO_FEATURE_TYPES_ENUM.HOUSE,
                  ]}
                  defaultValue={
                    work ? locationToGeoFeatureMapper(work) : undefined
                  }
                  onChange={(feature) => {
                    if (feature) {
                      const options = {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      };

                      const location = geoFeatureToLocationMapper(feature);
                      locationFieldNames.forEach((fieldName) =>
                        setValue(
                          fieldName,
                          location[fieldName as keyof ILocation],
                          options
                        )
                      );
                    } else {
                      locationFieldNames.forEach((fieldName) =>
                        resetField(fieldName, {
                          keepDirty: true,
                          keepTouched: true,
                        })
                      );
                    }
                  }}
                />
              ) : (
                <Spinner className="d-block" />
              )}
              {firstLocationError && (
                <FormControlError error={firstLocationError as FieldError} />
              )}
            </Form.Group>
            {locationFieldNames.map((fieldName) => (
              <Input
                {...getInputProps(fieldName, register, schema, errors)}
                hidden
                key={fieldName}
              />
            ))}
          </Row>
          <RootError errors={errors} />
        </Modal.Body>
        <Modal.Footer className="justify-content-md-center">
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t(operation ? "modify" : "create")
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
