import { parseISO } from "date-fns";

import { groupBy } from "../utils";

import { IComment } from "./comment.types";
import { FILE_TYPE_ENUM } from "./estimate.types";
import { ILogs, IMappingLog } from "./log.types";
import {
  IMapping,
  IMappingDTO,
  mappingDTOToMappingMapper,
} from "./mapping.types";
import { IPriceNode } from "./priceNode.types";

export interface IDocumentDTO {
  id: string;
  allotment: string;
  allotment_name: string;
  upload_date: string;
  file_name: string;
  mapping?: IMappingDTO;
  import_logs?: IMappingLog[];
  instruction_logs?: IMappingLog[];
  comments?: IComment[];
  price_breakdown?: string;
  version?: number;
  project: string;
  project_string: string;
}

export interface IDocument {
  id: string;
  file_name: string;
  upload_date: Date;
  version?: number;
  allotment: string;
  price_breakdown?: string;
  project: string;
  project_string: string;
}

export interface IDocumentData extends IDocument {
  data?: IPriceNode;
  raw_data?: any[][];
  file_type?: FILE_TYPE_ENUM;
  import_logs?: IMappingLog[];
  instruction_logs?: IMappingLog[];
  genericLogs?: ILogs;
  allotment_name: string;
  comments?: IComment[];
  mapping?: IMapping;
}

export type TDocumentDataWithTotal = IDocumentData & {
  estimation_amount: number;
};

export enum ATTACHMENT_TYPE_ENUM {
  PRICE_BREAKDOWN = "price-breakdown",
  ALLOTMENT = "allotment",
}

export enum ORDER_ENUM {
  ALPHABETICALLY = "ORDER_ALPHABETICALLY",
  REVERSED_ALPHABETICALLY = "ORDER_REVERSED_ALPHABETICALLY",
  NEWEST = "ORDER_NEWEST",
  OLDEST = "ORDER_OLDEST",
}

export function documentDTOToDocumentMapper(document: IDocumentDTO): IDocument {
  return {
    ...document,
    upload_date: parseISO(document.upload_date),
  };
}

export function documentDTOToDocumentDataMapper(
  document: IDocumentDTO
): IDocumentData {
  return {
    ...document,
    ...documentDTOToDocumentMapper(document),
    mapping: mappingDTOToMappingMapper(document.mapping),
  };
}

interface IFileName {
  file_name: string;
  id: string;
}
interface IUploadDate {
  upload_date?: Date;
  id?: string;
}

export const sorters = {
  [ORDER_ENUM.ALPHABETICALLY]: (a: IFileName, b: IFileName) =>
    checkMOE(a, b) || fileNameOrder(a, b) ? -1 : 1,
  [ORDER_ENUM.REVERSED_ALPHABETICALLY]: (a: IFileName, b: IFileName) =>
    checkMOE(b, a) || fileNameOrder(b, a) ? 1 : -1,
  [ORDER_ENUM.NEWEST]: (a: IUploadDate, b: IUploadDate) =>
    checkMOE(a, b) || dateOrder(a, b),
  [ORDER_ENUM.OLDEST]: (a: IUploadDate, b: IUploadDate) =>
    checkMOE(b, a) || dateOrder(b, a),
};

/** MOE comes always first */
function checkMOE(a: { id?: string }, b: { id?: string }) {
  return a.id === "MOE" ? -1 : b.id === "MOE" ? 1 : 0;
}

function fileNameOrder(a: IFileName, b: IFileName) {
  return a.file_name.toLowerCase() < b.file_name.toLowerCase();
}

function dateOrder(a: IUploadDate, b: IUploadDate) {
  return (b.upload_date?.getTime() || 0) - (a.upload_date?.getTime() || 0);
}

export function getVersions(documents?: IDocument[] | null) {
  const fileNames = groupBy(documents, (document) => document.file_name);
  Object.values(fileNames).forEach((documents) => {
    documents?.sort(sorters.ORDER_NEWEST);
    documents?.forEach((document, index) => (document.version = index + 1));
  });
  return Object.values(fileNames).flat();
}
