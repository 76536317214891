import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { NavLink, useSearchParams } from "react-router-dom";

import { useOrganizationNameRights } from "../account/UserOrganizationGuard";
import { getSetSearchParamsCallbackWithLastUpdatedSearchParams } from "../api/APIUtils";
import { Header } from "../shared/header";
import { useTranslation } from "../shared/i18n";
import { AllotmentSelect } from "../shared/inputs/AllotmentSelect";
import { PATH_NAMES_ENUM } from "../shared/pathNames";
import { ROWTYPE_USAGE_ENUM } from "../shared/types/rowType.types";
import { useRowTypesQuery } from "../shared/useSharedQueries";
import { useTrackPageView } from "../shared/useTrackPageView";

import { ClassificationTable } from "./ClassificationTable";
import { ALLOTMENT_TYPE_SEARCH_PARAM } from "./MappingToolPage";

export function ClassificationPage() {
  useTrackPageView("Classification");
  const { t } = useTranslation("MappingToolPage");

  const [searchParams, setSearchParams] = useSearchParams();

  const [hideHeader, setHideHeader] = useState<boolean>(
    searchParams.get("hide_header") === "true"
  );

  const [allotment_type, setAllotment_type] = useState<string | undefined>(
    searchParams.get(ALLOTMENT_TYPE_SEARCH_PARAM) ?? undefined
  );

  const rowTypes = useRowTypesQuery(
    allotment_type,
    true,
    ROWTYPE_USAGE_ENUM.DPGF
  );

  const { organization: organizationAdmin } = useOrganizationNameRights(
    window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION,
    { can_admin: true }
  );

  return (
    <>
      <Header />
      <Container fluid>
        {!hideHeader && (
          <Row className="my-2">
            <Col>
              <AllotmentSelect
                multi={false}
                value={allotment_type}
                onChange={(value) => {
                  setAllotment_type(value as string);
                  setSearchParams(
                    getSetSearchParamsCallbackWithLastUpdatedSearchParams(
                      (searchParams) => {
                        searchParams.set(
                          ALLOTMENT_TYPE_SEARCH_PARAM,
                          allotment_type!
                        );
                        return searchParams;
                      }
                    ),
                    { replace: true }
                  );
                }}
                closeMenuOnSelect
              />
            </Col>

            {organizationAdmin && (
              <Col xs="auto">
                <Nav>
                  <Nav.Link
                    as={NavLink}
                    to={`/${PATH_NAMES_ENUM.MAPPING}/${PATH_NAMES_ENUM.ADMIN_ROW_TYPE}?${ALLOTMENT_TYPE_SEARCH_PARAM}=${allotment_type}`}
                  >
                    {t("admin RowTypes")}
                  </Nav.Link>
                </Nav>
              </Col>
            )}
            <Col xs="auto">
              <Button
                onClick={() => {
                  setHideHeader(true);
                  setSearchParams(
                    getSetSearchParamsCallbackWithLastUpdatedSearchParams(
                      (searchParams) => {
                        searchParams.set("hide_header", "true");
                        return searchParams;
                      }
                    ),
                    { replace: true }
                  );
                }}
              >
                {t("hide header")}
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <ClassificationTable
            isLoading={rowTypes.isLoading}
            row_types={rowTypes.data}
            allotment_type={allotment_type}
            enableDownload
          />
        </Row>
      </Container>
    </>
  );
}
