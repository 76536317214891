import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { GuardedButton } from "../account/GuardedButton";
import {
  downloadBlob,
  getDocumentFullBlobAPIUrl,
  updateAttachment,
} from "../api/fetchDocuments";
import { CUSTOM_ACTION_DIMENSIONS_ENUM } from "../matomo";
import globalStyles from "../shared/_exports.module.scss";
import { IBackErrors } from "../shared/errors";
import { useTranslation } from "../shared/i18n";
import { formatDateDDMMYYYY } from "../shared/i18n/dateFormatter";
import { DotsIcon, DownloadIcon, PenIcon } from "../shared/icons";
import { FileIcon } from "../shared/icons/File";
import { RenameModal } from "../shared/modals";
import { IDocument } from "../shared/types/document.types";
import { QUERY_KEYS_ENUM } from "../shared/useSharedQueries";
import { cancelEvent } from "../shared/utils";

import styles from "./DocumentCard.module.scss";

export interface IDocumentCardProps {
  document: IDocument;
}

export function DocumentCard({ document }: IDocumentCardProps) {
  const { t } = useTranslation("DocumentCard");
  const [rename, setRename] = useState<string>();
  const [isDownloading, setIsDownloading] = useState(false);

  const { trackEvent } = useMatomo();

  const queryClient = useQueryClient();
  const documentRenaming = useMutation({
    mutationFn: updateAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.ATTACHMENTS, document.allotment],
      });
      setRename(undefined);
    },
  });

  const handleRename = useCallback(
    async (value?: string) => {
      if (value) {
        await documentRenaming.mutateAsync({ ...document, file_name: value });
        setRename(undefined);
      } else {
        setRename(undefined);
      }
    },
    [documentRenaming, document]
  );

  const isSubmitting = documentRenaming.isLoading;

  return (
    <>
      {rename !== undefined && (
        <RenameModal
          show
          handleClose={handleRename}
          title={t("rename document")}
          buttonText={t("rename")}
          value={rename}
          isSubmitting={isSubmitting}
          backErrors={
            documentRenaming.error as IBackErrors<{
              value: string | undefined;
            }>
          }
        />
      )}
      <Container fluid className={styles.document}>
        <Dropdown>
          <Dropdown.Toggle
            className={classNames(styles.dots, "p-0 pt-3")}
            variant="text"
          >
            <DotsIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu className={classNames("py-0", styles.menu)}>
            <Dropdown.Item
              className="px-3 py-2 rounded-0"
              as={GuardedButton}
              onClick={() => setRename(document.file_name)}
              variant="text"
            >
              <PenIcon fill={globalStyles.dark} size={16} />
              <span className="mx-2 text-dark">{t("rename")}</span>
            </Dropdown.Item>
            <Dropdown.Divider className=" my-0" />
            <Dropdown.Item
              className="px-3 py-2 rounded-0"
              as="a"
              href={getDocumentFullBlobAPIUrl(document)}
              download={document.file_name}
              onClick={async (e) => {
                cancelEvent(e);
                setIsDownloading(true);
                try {
                  await downloadBlob(document);
                  trackEvent({
                    category: "documents",
                    action: "download",
                    name: "DownloadDocument",
                    customDimensions: [
                      {
                        id: CUSTOM_ACTION_DIMENSIONS_ENUM.DATA,
                        value: document.file_name,
                      },
                    ],
                  });
                } finally {
                  setTimeout(() => setIsDownloading(false), 2000);
                }
              }}
            >
              <DownloadIcon fill={globalStyles.dark} size={16} />
              <span className="mx-2 text-dark">{t("download")}</span>
              {isDownloading && <Spinner size="sm" className="ms-2" />}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Row>
          <Col className="ps-0 text-center">
            <FileIcon
              fileName={document.file_name}
              className="m-5 mb-4"
              size={64}
            />
          </Col>
          <hr className="mb-2" />
        </Row>
        <Row className="mb-3">
          <Col className="ps-3">
            <div className="fw-bolder">
              {document.file_name}
              <u className="text-info ms-1 fw-normal">
                {t("document version prefix", { version: document.version })}
              </u>
            </div>
            <div className="text-primary">
              {formatDateDDMMYYYY(document.upload_date, "/")}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
