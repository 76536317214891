import React, { useCallback } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useTranslation } from "../shared/i18n";

import {
  IAreaFilterValue,
  IColumnFilters,
  IDateFilterValue,
} from "./OperationsTable";
import { AreaFilter } from "./filters/AreaFilter";
import { BuildingCategoryFilter } from "./filters/BuildingCategoryFilter";
import { DateFilter } from "./filters/DateFilter";
import { StatusFilter } from "./filters/StatusFilter";
import { WorkTypeFilter } from "./filters/WorkTypeFilter";

export interface IFilterProps {
  filterKey?: string;
  onChange?: (
    filterKey?: string,
    value?: string | string[] | IDateFilterValue | IAreaFilterValue
  ) => void;
  clearable?: boolean;
}

export interface IFilter {
  labelKey: string;
  filterKey: string;
  FilterComponent: React.FunctionComponent<IFilterProps>;
  md: number | "auto";
}

export const BUILDING_CATEGORY_SEARCH_PARAM = "building_category";
export const STATUS_SEARCH_PARAM = "status";
export const WORK_TYPE_SEARCH_PARAM = "work_type";
export const DATE_SEARCH_PARAM = "tender_date";
export const AREA_SEARCH_PARAM = "floor_area";

const filters: IFilter[] = [
  {
    labelKey: "categoryLabel",
    filterKey: BUILDING_CATEGORY_SEARCH_PARAM,
    FilterComponent: BuildingCategoryFilter,
    md: 4,
  },
  {
    labelKey: "workLabel",
    filterKey: WORK_TYPE_SEARCH_PARAM,
    FilterComponent: WorkTypeFilter,
    md: 4,
  },
  {
    labelKey: "status",
    filterKey: STATUS_SEARCH_PARAM,
    FilterComponent: StatusFilter,
    md: 4,
  },
  {
    labelKey: "areas",
    filterKey: AREA_SEARCH_PARAM,
    FilterComponent: AreaFilter,
    md: "auto",
  },
  {
    labelKey: "dates",
    filterKey: DATE_SEARCH_PARAM,
    FilterComponent: DateFilter,
    md: "auto",
  },
];

export const OperationsColumnFilters = React.forwardRef(
  function OperationsColumnFilters(
    {
      onFilterChange,
      className,
      style,
    }: {
      onFilterChange: React.Dispatch<React.SetStateAction<IColumnFilters>>;
      className?: string;
      style?: React.CSSProperties;
    },
    ref
  ) {
    const { t } = useTranslation("OperationsFilters");

    const handleFilterChange = useCallback(
      (
        filterKey?: string,
        value?: string | string[] | IDateFilterValue | IAreaFilterValue
      ) => {
        filterKey &&
          onFilterChange((prevFilters: IColumnFilters) => ({
            ...prevFilters,
            [filterKey]: value,
          }));
      },
      [onFilterChange]
    );

    return (
      <Row className={className} ref={ref} style={style}>
        {filters.map(({ filterKey, labelKey, FilterComponent, md }, index) => (
          <Col key={index} md={md} className="mt-2">
            <Form.Label>{t(labelKey)}</Form.Label>
            <FilterComponent
              filterKey={filterKey}
              onChange={handleFilterChange}
            />
          </Col>
        ))}
      </Row>
    );
  }
);
