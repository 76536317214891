import { BuildingCategorySelect } from "../../shared/inputs/BuildingCategorySelect";

import { IFilterProps } from "./../OperationsFilters";

export function BuildingCategoryFilter({ filterKey, onChange }: IFilterProps) {
  const handleChange = (selectedValue: string) => {
    onChange?.(filterKey, selectedValue);
  };
  return <BuildingCategorySelect clearable onChange={handleChange} />;
}
