import { MAPPING_COLUMNS_ENUM } from "./mapping.types";

export enum LOG_LEVEL_ENUM {
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}
export enum LOG_TYPE_ENUM {
  NOMENCLATURE_ALREADY_SET = "NOMENCLATURE_ALREADY_SET",
  NOMENCLATURE_ALREADY_SET_IGNORE = "NOMENCLATURE_ALREADY_SET_IGNORE",
  NOMENCLATURE_ALREADY_SET_MODIFY = "NOMENCLATURE_ALREADY_SET_MODIFY",
  INVALID_NOMENCLATURE = "INVALID_NOMENCLATURE",
  MAPPING_EXCEPTION = "MAPPING_EXCEPTION",
  LINE_RULES_EXCEPTION = "LINE_RULES_EXCEPTION",
  INSTRUCTION_EXCEPTION = "INSTRUCTION_EXCEPTION",
  INSTRUCTION_MISSING_ESTIMATE_EXCEPTION = "INSTRUCTION_MISSING_ESTIMATE_EXCEPTION",
  MISSING_WORK_ID = "MISSING_WORK_ID",
  INSTRUCTION_MOVE_RELATIVE_CHILD_EXCEPTION = "INSTRUCTION_MOVE_RELATIVE_CHILD_EXCEPTION",
  NOMENCLATURE_NOT_INCREASING = "NOMENCLATURE_NOT_INCREASING",
  DUPLICATE_LINE = "DUPLICATE_LINE",
  NODE_TOO_DEEP = "NODE_TOO_DEEP",
  LINE_ERASED = "LINE_ERASED",
  UNITS_DIFFERENT = "UNITS_DIFFERENT",
  UNMATCHED_BREAKDOWN_NODE = "UNMATCHED_BREAKDOWN_NODE",
  UNMATCHED_ESTIMATE_NODE = "UNMATCHED_ESTIMATE_NODE",
  NODE_ADOPTION = "NODE_ADOPTION",
  VIRTUAL_NODE_ADOPTION = "VIRTUAL_NODE_ADOPTION",
  DUPLICATE_ID = "DUPLICATE_ID",
  DUPLICATE_DESIGNATION = "DUPLICATE_DESIGNATION",
  POTENTIAL_MATCH = "POTENTIAL_MATCH",
  POTENTIAL_MATCH_NUMBER = "POTENTIAL_MATCH_NUMBER",
  CALCULATED_TOTAL_DIFFERS = "CALCULATED_TOTAL_DIFFERS",
  MANUAL_TOTAL_DIFFERS = "MANUAL_TOTAL_DIFFERS",
  COLUMN_TYPE = "COLUMN_TYPE",
  COLUMN_TYPE_INVALID = "COLUMN_TYPE_INVALID",
  COLUMN_TYPE_EMPTY = "COLUMN_TYPE_EMPTY",
}

export interface ILogs {
  [LOG_LEVEL_ENUM.INFO]: IMappingLog[];
  [LOG_LEVEL_ENUM.WARNING]: IMappingLog[];
  [LOG_LEVEL_ENUM.ERROR]: IMappingLog[];
  [LOG_LEVEL_ENUM.DEBUG]: IMappingLog[];
}
export interface ILogsMap {
  [key: string]: ILogs;
}

export interface IMappingLog {
  row_number: number;
  column?: MAPPING_COLUMNS_ENUM;
  level: LOG_LEVEL_ENUM;
  type: LOG_TYPE_ENUM;
  message: string;
  parameters?: string[];
  breakdown_id?: string;
  estimate_id?: string;
}

export const LogTypeSimilarities: Partial<{
  [key in LOG_TYPE_ENUM]: { code: LOG_TYPE_ENUM; priority: number };
}> = {
  [LOG_TYPE_ENUM.DUPLICATE_LINE]: {
    code: LOG_TYPE_ENUM.DUPLICATE_LINE,
    priority: 0,
  },
  [LOG_TYPE_ENUM.DUPLICATE_DESIGNATION]: {
    code: LOG_TYPE_ENUM.DUPLICATE_LINE,
    priority: 1,
  },
  [LOG_TYPE_ENUM.DUPLICATE_ID]: {
    code: LOG_TYPE_ENUM.DUPLICATE_LINE,
    priority: 2,
  },
};

/** deduplicate logs based on LogTypeSimilarities */
export function deduplicateLogs(logs: IMappingLog[]) {
  const deduplicatedLogs: IMappingLog[] = [];
  const dict: Partial<{
    [key in LOG_TYPE_ENUM]: { priority: number; index: number };
  }> = {};
  logs.forEach((log) => {
    let logTypeSimilarities = LogTypeSimilarities[log.type];
    let previous;
    // override previous similar log
    if (
      logTypeSimilarities &&
      (previous = dict[logTypeSimilarities.code]) &&
      logTypeSimilarities.priority < previous.priority
    ) {
      deduplicatedLogs[previous.index] = log;
      previous.priority = logTypeSimilarities.priority;
    }
    // add log
    else if (!previous) {
      deduplicatedLogs.push(log);
      if (logTypeSimilarities) {
        dict[logTypeSimilarities.code] = {
          priority: logTypeSimilarities.priority,
          index: deduplicatedLogs.length - 1,
        };
      }
    }
  });
  return deduplicatedLogs;
}
